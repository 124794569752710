const HexagonalOuter = ({ className, border }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="current"
    viewBox="0 0 91 102"
    stroke={border}
    className={`h-4 w-4 ${className}`}
  >
    <path
      d="M33.75 6.36122C36.6866 4.66575 38.8583 3.41261 40.6967 2.5798C42.5251 1.75154 43.9883 1.35641 45.5 1.35641C47.0117 1.35641 48.4749 1.75154 50.3033 2.5798C52.1417 3.41261 54.3134 4.66575 57.25 6.36122L78.2833 18.5048C81.2199 20.2003 83.391 21.4544 85.0315 22.6301C86.663 23.7994 87.7367 24.869 88.4926 26.1782C89.2485 27.4874 89.6379 28.9521 89.8348 30.9497C90.0328 32.9582 90.0333 35.4655 90.0333 38.8564V63.1436C90.0333 66.5345 90.0328 69.0418 89.8348 71.0503C89.6379 73.0479 89.2485 74.5126 88.4926 75.8218C87.7367 77.131 86.663 78.2006 85.0315 79.3699C83.391 80.5456 81.2199 81.7997 78.2833 83.4952L57.25 95.6388C54.3134 97.3342 52.1417 98.5874 50.3033 99.4202C48.4749 100.248 47.0117 100.644 45.5 100.644C43.9883 100.644 42.5251 100.248 40.6967 99.4202C38.8583 98.5874 36.6866 97.3342 33.75 95.6388L12.7167 83.4952C9.78005 81.7997 7.60898 80.5456 5.96853 79.3699C4.33703 78.2006 3.26326 77.131 2.50739 75.8218C1.75152 74.5126 1.36214 73.0479 1.16523 71.0503C0.967238 69.0418 0.966679 66.5345 0.966679 63.1436V38.8564C0.966679 35.4655 0.967238 32.9582 1.16523 30.9497C1.36214 28.9521 1.75152 27.4874 2.50739 26.1782C3.26326 24.869 4.33703 23.7994 5.96853 22.6301C7.60898 21.4544 9.78005 20.2003 12.7167 18.5048L33.75 6.36122Z"
      fill="current"
    />
  </svg>
)

export default HexagonalOuter
