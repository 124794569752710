import React from 'react'

const CaptionIcon = ({ className }) => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fill="currentColor"
      d="m18 11h-1.5v-.5h-2v3h2v-.5h1.5v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1-1v-4a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1m-7 1h-1.5v-.5h-2v3h2v-.5h1.5v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1-1v-4a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1m8-6h-14c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-12c0-1.11-.9-2-2-2z"
    />
  </svg>
)

export default CaptionIcon
