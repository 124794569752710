import * as HoverCard from '@radix-ui/react-hover-card'

const HoverablePopover = ({
  children,
  html,
  open,
  side,
  arrowClass,
  onOpenChange,
  ...props
}) => {
  return (
    <HoverCard.Root
      {...props}
      openDelay={0}
      delayDuration={0}
      onOpenChange={onOpenChange && onOpenChange}
      open={open && open}
    >
      <HoverCard.Trigger asChild>{children}</HoverCard.Trigger>
      <HoverCard.Content
        {...props}
        className={`bg-gray-900 dark:bg-dark-400 text-white max-w-sm text-sm px-4 py-2.5 rounded-md ${
          props.className && props.className
        }`}
        sideOffset={5}
      >
        {html}
        <HoverCard.Arrow
          className={`${arrowClass} fill-current text-gray-900 dark:text-dark-400`}
        />
      </HoverCard.Content>
    </HoverCard.Root>
  )
}

export default HoverablePopover
