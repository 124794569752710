const SettingsModal = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 12 12"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.33333 1.16663C6.87309 1.16663 6.5 1.53972 6.5 1.99996C6.5 2.4602 6.87309 2.83329 7.33333 2.83329C7.79357 2.83329 8.16667 2.4602 8.16667 1.99996C8.16667 1.53972 7.79357 1.16663 7.33333 1.16663ZM9.09765 1.49996C8.88001 0.730458 8.17252 0.166626 7.33333 0.166626C6.49415 0.166626 5.78665 0.730458 5.56901 1.49996H0.666666C0.390524 1.49996 0.166666 1.72382 0.166666 1.99996C0.166666 2.2761 0.390524 2.49996 0.666666 2.49996H5.56901C5.78665 3.26946 6.49415 3.83329 7.33333 3.83329C8.17252 3.83329 8.88001 3.26946 9.09765 2.49996H11.3333C11.6095 2.49996 11.8333 2.2761 11.8333 1.99996C11.8333 1.72382 11.6095 1.49996 11.3333 1.49996H9.09765ZM3.33333 5.16663C2.8731 5.16663 2.5 5.53972 2.5 5.99996C2.5 6.4602 2.8731 6.83329 3.33333 6.83329C3.79357 6.83329 4.16667 6.4602 4.16667 5.99996C4.16667 5.53972 3.79357 5.16663 3.33333 5.16663ZM3.33333 4.16663C4.17252 4.16663 4.88001 4.73046 5.09765 5.49996H11.3333C11.6095 5.49996 11.8333 5.72382 11.8333 5.99996C11.8333 6.2761 11.6095 6.49996 11.3333 6.49996H5.09765C4.88001 7.26946 4.17252 7.83329 3.33333 7.83329C2.49415 7.83329 1.78665 7.26946 1.56901 6.49996H0.666666C0.390524 6.49996 0.166666 6.2761 0.166666 5.99996C0.166666 5.72382 0.390524 5.49996 0.666666 5.49996H1.56901C1.78665 4.73046 2.49415 4.16663 3.33333 4.16663ZM8.5 9.99996C8.5 9.53972 8.87309 9.16663 9.33333 9.16663C9.79357 9.16663 10.1667 9.53972 10.1667 9.99996C10.1667 10.4602 9.79357 10.8333 9.33333 10.8333C8.87309 10.8333 8.5 10.4602 8.5 9.99996ZM11.0977 9.49996C10.88 8.73046 10.1725 8.16663 9.33333 8.16663C8.49415 8.16663 7.78665 8.73046 7.56901 9.49996H0.666666C0.390524 9.49996 0.166666 9.72382 0.166666 9.99996C0.166666 10.2761 0.390524 10.5 0.666666 10.5H7.56901C7.78665 11.2695 8.49415 11.8333 9.33333 11.8333C10.1725 11.8333 10.88 11.2695 11.0977 10.5H11.3333C11.6095 10.5 11.8333 10.2761 11.8333 9.99996C11.8333 9.72382 11.6095 9.49996 11.3333 9.49996H11.0977Z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default SettingsModal
