import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

export const getNotesData = createAsyncThunk(
  'notes/getNotesData',
  async (data) => {
    return await axiosInterceptors.get(`bookmark/notes`, {
      signal: data?.abortController?.signal,
      params: {
        [data.noteSlug]: data.id,
        sort_by: data.sortBy,
        page: data.page,
      },
    })
  },
)

export const createNote = createAsyncThunk(
  `notes/createNote`,
  async ({ data, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(`bookmark/note`, data)

      onSuccess && onSuccess()
      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const updateNote = createAsyncThunk(
  `notes/updateNote`,
  async ({ data, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.patch(
        `bookmark/note/${data.noteId}`,
        {
          content: data.content,
          type: data.type,
          transcript_start_time: data?.transcript_start_time,
          transcript_end_time: data?.transcript_start_time,
          transcript_text: data?.transcript_start_time,
        },
      )

      onSuccess && onSuccess()
      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const deleteNote = createAsyncThunk('notes/deleteNote', async (data) => {
  return await axiosInterceptors.delete(`bookmark/note/${data.noteId}`, {
    type_id: data.typeId,
  })
})

const notesSlice = createSlice({
  name: 'notes',
  initialState: {
    notesData: { isLoading: false, data: [] },
    selectedRecent: { id: 1, name: 'Sort by Most Recent', sortBy: 'recent' },
    createNoteLoader: false,
    deleteNoteLoader: false,
    updateNoteLoader: false,
    deleteNotePopup: {
      isLoading: false,
      show: false,
      id: null,
      loadingSeeMore: false,
    },
    notesPageNumber: 1,
  },
  extraReducers: {
    [getNotesData.pending]: (state, action) => {
      if (state.notesPageNumber === 1) {
        state.notesData.isLoading = true
      } else {
        state.notesData.loadingSeeMore = true
      }
    },
    [getNotesData.fulfilled]: (state, action) => {
      if (state.notesPageNumber !== 1) {
        state.notesData = {
          isLoading: false,
          loadingSeeMore: false,
          data: {
            notes: [
              ...state.notesData.data.notes,
              ...action.payload.data.notes,
            ],
          },
          pagination: action.payload.pagination,
        }
      } else {
        state.notesData = {
          isLoading: false,
          data: action.payload.data,
          pagination: action.payload.pagination,
        }
      }
    },
    [getNotesData.rejected]: (state, action) => {
      state.notesData.isLoading = false
      state.notesData.loadingSeeMore = false
    },
    [createNote.pending]: (state, action) => {
      state.createNoteLoader = true
    },
    [createNote.fulfilled]: (state, { payload }) => {
      state.notesData.data.notes = [
        payload.data.note,
        ...state.notesData.data.notes,
      ]
      state.createNoteLoader = false
    },
    [createNote.rejected]: (state, action) => {
      state.createNoteLoader = false
    },
    [deleteNote.pending]: (state) => {
      state.deleteNoteLoader = true
    },
    [deleteNote.fulfilled]: (state, { meta, payload }) => {
      state.notesData.data.notes = state.notesData.data.notes.filter(
        (item) => item.id !== meta.arg.noteId,
      )
      state.deleteNotePopup = {
        id: null,
        show: false,
      }
      state.deleteNoteLoader = false
    },
    [deleteNote.rejected]: (state) => {
      state.deleteNoteLoader = false
    },
    [updateNote.pending]: (state) => {
      state.updateNoteLoader = true
    },
    [updateNote.fulfilled]: (state, { meta, payload }) => {
      const updatedNoteIndex = findIndex(state.notesData.data.notes, {
        id: meta.arg.data.noteId,
      })
      state.notesData.data.notes[updatedNoteIndex] = payload.data.note
      state.updateNoteLoader = false
    },
    [updateNote.rejected]: (state) => {
      state.updateNoteLoader = false
    },
  },

  reducers: {
    setNoteDeleteId: (state, { payload }) => {
      state.deleteNotePopup = {
        show: payload.show,
        id: payload.id,
      }
    },
    updatePageNumber: (state, { payload }) => {
      state.notesPageNumber = payload
    },
    setSelectedRecent: (state, { payload }) => {
      state.selectedRecent = payload
    },
  },
})

export const { setNoteDeleteId, updatePageNumber, setSelectedRecent } =
  notesSlice.actions

export default notesSlice.reducer
