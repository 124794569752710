const Googlecalendar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path fill="#fff" d="M24.39 7.565H7.714v16.813H24.39V7.565z"></path>
    <path
      fill="#557EBF"
      d="M20.02 13.37l-1.59 1.156-.793-1.214 2.861-2.073h1.093v9.773H20.03l-.01-7.643zm-8.885 7.236a3.25 3.25 0 01-1.3-1.865l1.446-.6c.1.453.341.863.687 1.172.33.279.752.426 1.183.414.446.01.88-.144 1.22-.435a1.39 1.39 0 00.52-1.107 1.383 1.383 0 00-.538-1.123 2.07 2.07 0 00-1.347-.435h-.835v-1.443h.76c.42.014.833-.12 1.167-.377a1.247 1.247 0 00.476-1.037 1.153 1.153 0 00-.424-.936 1.638 1.638 0 00-1.077-.351c-.368-.02-.73.103-1.012.343-.25.229-.439.519-.546.842l-1.431-.6a3.204 3.204 0 011.04-1.435c.561-.431 1.253-.65 1.957-.622a3.543 3.543 0 011.58.344c.444.21.824.538 1.097.95.264.404.402.879.396 1.363.015.466-.114.924-.37 1.312a2.55 2.55 0 01-.908.82v.087c.464.19.867.508 1.163.916.309.426.469.943.455 1.471a2.77 2.77 0 01-.431 1.53c-.296.45-.707.813-1.19 1.049a3.727 3.727 0 01-1.706.385 3.513 3.513 0 01-2.03-.635l-.002.003z"
    ></path>
    <path fill="#36A852" d="M24.39 24.378H7.714v7.564H24.39v-7.564z"></path>
    <path
      fill="#557EBF"
      d="M24.377 0H2.711A2.49 2.49 0 00.943.738a2.531 2.531 0 00-.732 1.783v21.844h7.516V7.578h16.65V0z"
    ></path>
    <path
      fill="#1C8140"
      d="M.21 24.365v5.044c0 .668.264 1.31.733 1.782a2.49 2.49 0 001.768.738h5.016v-7.564H.21z"
    ></path>
    <path fill="#F9BC15" d="M31.893 7.565H24.39v16.813h7.503V7.565z"></path>
    <path
      fill="#3968B2"
      d="M31.893 7.578V2.52c0-.669-.263-1.31-.732-1.783A2.49 2.49 0 0029.393 0h-5.016v7.578h7.516z"
    ></path>
    <path fill="#EA4535" d="M24.39 31.942l7.503-7.564H24.39v7.564z"></path>
  </svg>
)
export default Googlecalendar
