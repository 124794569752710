import { RadioGroup } from '@headlessui/react'
import { Pause } from 'components/icons'
import HTMLRenderer from 'components/shared/helper/HTMLRenderer'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const BookingQuizReview = ({
  recoursesQuestion,
  optionsArray,
  questionSubmission,
}) => {
  const { isFullBookScreen } = useSelector((state) => ({
    isFullBookScreen: state.resource.isFullBookScreen,
  }))

  const tabletView = window.matchMedia('(max-width: 768px)')
  const mobileView = window.matchMedia('(max-width: 640px)')

  const [mobileMatches, setMobileMatches] = useState(mobileView.matches)
  useEffect(() => {
    const handler = (e) => setMobileMatches(e.matches)
    mobileView.addListener(handler)
    return () => mobileView.removeListener(handler)
  })

  const [matches, setMatches] = useState(tabletView.matches)

  const [landscapMode, setLandscapMode] = useState(false)

  useEffect(() => {
    window.addEventListener(
      'orientationchange',
      function () {
        if (window.orientation === 90 || window.orientation === -90) {
          setLandscapMode(true)
        } else {
          setLandscapMode(false)
        }
      },
      false,
    )
  }, [])

  useEffect(() => {
    if (window.orientation === 90 || window.orientation === -90) {
      setLandscapMode(true)
    } else {
      setLandscapMode(false)
    }
  }, [])

  const isHoverableDevice = window.matchMedia(
    '(hover: hover) and (pointer: fine)',
  )
  const [touchable, setTouchable] = useState(isHoverableDevice?.matches)
  useEffect(() => {
    const handler = (e) => setTouchable(e.matches)
    isHoverableDevice.addListener(handler)
    return () => isHoverableDevice.removeListener(handler)
  }, [isHoverableDevice])

  //
  const [multipleChoiceWidth, setMultiplechoiceWidth] = useState('')
  const mouseMoveHandler = function (e, x, y, leftWidth) {
    const resizer = document.getElementById('dragMe')

    const leftArea = document.getElementById('left-area')

    const dx = e.clientX - x
    const newLeftWidth =
      ((leftWidth + dx) * 100) /
      resizer.parentNode.getBoundingClientRect().width
    leftArea.style.width =
      newLeftWidth >= 40 && newLeftWidth <= 70 && `${newLeftWidth}%`
    setMultiplechoiceWidth(leftArea.style.width)
  }
  const stopResize = (e) => {
    document.onmousemove = null
    document.onmouseup = null
  }
  const mouseDown = (e) => {
    const leftSide = document.getElementById('left-area')
    let x = e.clientX
    let y = e.clientY
    let leftWidth = leftSide.getBoundingClientRect().width
    document.onmousemove = (e) => mouseMoveHandler(e, x, y, leftWidth)
    // document.addEventListener('mousemove', mouseMoveHandler())
    document.onmouseup = stopResize
  }

  useEffect(() => {
    const leftSide = document.getElementById('left-area')
    if (leftSide?.style?.height || leftSide?.style?.width) {
      leftSide.style.width = multipleChoiceWidth
    }
  }, [multipleChoiceWidth])

  return (
    <div
      style={{
        height: mobileMatches
          ? landscapMode
            ? 'calc(100vh - 120px)'
            : isFullBookScreen
            ? 'calc(100vh - 190px)'
            : 'calc(100vh - 378px)'
          : matches
          ? landscapMode || isFullBookScreen
            ? 'calc(100vh - 120px)'
            : 'calc(100vh - 303px)'
          : landscapMode || isFullBookScreen
          ? 'calc(100vh - 112px)'
          : 'calc(100vh - 369px)',
      }}
      className="w-full overflow-auto  sm:flex sm:items-stretch sm:min-h-full"
    >
      <div
        id="left-area"
        className={` ${
          !matches ? 'overflow-auto' : ''
        } px-6 py-2 custom-scroll ${matches ? 'w-full' : 'w-full md:w-3/5 '}`}
      >
        <div className="html-renderer-container relative">
          <HTMLRenderer
            preventInteraction={true}
            html={recoursesQuestion?.question_html}
          />
        </div>
      </div>
      {(touchable || !matches) && (
        <div
          id="dragMe"
          className={`${!matches && touchable && 'tour-slider'}`}
        >
          <div
            className={`h-full relative items-center border-l-2 dark:border-dark-500 hidden md:flex flex-col`}
          >
            <button
              style={{ cursor: 'col-resize' }}
              onMouseDown={mouseDown}
              className={`absolute mt-52  ${!touchable && 'hidden'}
                `}
            >
              <Pause className="h-6 w-6 text-gray-600 dark:text-dark-500" />
            </button>
          </div>
        </div>
      )}

      <div
        id="right-area"
        className={` ${!matches ? 'overflow-auto' : ''}  custom-scroll ${
          !touchable ? 'w-full' : 'w-full md:w-2/5 flex-auto'
        }`}
      >
        <div>
          {recoursesQuestion?.stem_html ? (
            <div className="html-renderer-container relative px-6 py-2 border-b">
              <HTMLRenderer
                preventInteraction={true}
                html={recoursesQuestion?.stem_html}
              />
            </div>
          ) : (
            <></>
          )}
          <RadioGroup className="px-6">
            {optionsArray?.map((data, index) => (
              <RadioGroup.Option key={index} value={data.id}>
                {({ checked }) => (
                  <div
                    className={`py-3 flex flex-wrap gap-2 justify-between items-center ${
                      optionsArray?.length !== index + 1
                        ? 'border-b'
                        : 'border-b'
                    } `}
                  >
                    <div className="flex flex-col items-start gap-2">
                      <div className="flex items-center gap-2 text-gray-900 dark:text-white text-base font-normal">
                        <div
                          style={{ minWidth: '20px' }}
                          className={`border-gray-500 border-2 dark:border-white rounded-full h-5 w-5 flex 
                          justify-center items-center mt-0.5`}
                        >
                          {questionSubmission?.input === data?.inputKey ? (
                            <div
                              style={{ minWidth: '4px', marginTop: '1px' }}
                              className="bg-gray-400 h-1.5 w-1.5 rounded-full"
                            ></div>
                          ) : (
                            ''
                          )}
                        </div>
                        <span className="mt-0.5">
                          {String.fromCharCode(64 + (index + 1))}.
                        </span>
                        <span className="mt-0.5">
                          <HTMLRenderer
                            preventInteraction={true}
                            html={data?.html}
                          />
                        </span>
                      </div>
                      <div className="text-white text-xs flex text-center">
                        {questionSubmission?.input === data?.inputKey &&
                        questionSubmission?.input ===
                          recoursesQuestion?.correct_option ? (
                          <span className="px-2 py-1 rounded-lg bg-green-700 ">
                            Your Answer is Correct
                          </span>
                        ) : questionSubmission?.input === data?.inputKey ? (
                          <span className="px-2 py-1 rounded-lg bg-red-500">
                            Your Answer
                          </span>
                        ) : data?.inputKey ===
                          recoursesQuestion?.correct_option ? (
                          <span className="px-2 py-1 rounded-lg bg-green-700">
                            Correct Answer
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div
                        style={{ minWidth: '40px' }}
                        className={
                          'overflow-hidden w-full text-xs flex items-center rounded-md flex-grow bg-gray-100 dark:bg-dark-400'
                        }
                      >
                        <div
                          style={{
                            width: `${data?.percentageChosen}%`,
                          }}
                          className="relative shadow-none h-0.5 flex flex-col pr-2  rounded-r-md whitespace-nowrap text-white justify-center bg-red-500"
                        ></div>
                      </div>
                      <span className="text-gray-900 text-sm dark:text-white w-10">
                        {Math.round(data?.percentageChosen * 100) / 100}%
                      </span>
                    </div>
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        {/* Explanation */}
        <div className="my-4 px-6">
          <div>
            <h4 className="text-base font-semibold text-gray-900 dark:text-white">
              Answer Explanation
            </h4>
            {recoursesQuestion?.explanation_html && (
              <div className="disable-copy mt-4 text-base text-gray-900 dark:text-white font-normal html-renderer-container">
                <HTMLRenderer
                  preventInteraction={true}
                  html={recoursesQuestion?.explanation_html}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookingQuizReview
