const Settings = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.37 2.996c.16-.661 1.1-.661 1.26 0a1.65 1.65 0 002.462 1.02c.581-.354 1.247.31.893.893a1.649 1.649 0 001.019 2.46c.661.16.661 1.101 0 1.262a1.65 1.65 0 00-1.02 2.461c.354.581-.31 1.247-.893.893a1.648 1.648 0 00-2.46 1.019c-.16.661-1.101.661-1.262 0a1.65 1.65 0 00-2.461-1.02c-.581.354-1.247-.31-.893-.893a1.649 1.649 0 00-1.019-2.46c-.661-.16-.661-1.101 0-1.262a1.65 1.65 0 001.02-2.461c-.354-.581.31-1.247.893-.893a1.648 1.648 0 002.46-1.019zm2.233-.236c-.408-1.68-2.798-1.68-3.206 0a.648.648 0 01-.969.4c-1.476-.898-3.166.792-2.266 2.268a.65.65 0 01-.402.97c-1.68.407-1.68 2.797 0 3.205a.65.65 0 01.401.968c-.9 1.477.79 3.167 2.267 2.267a.65.65 0 01.97.402c.407 1.68 2.797 1.68 3.205 0a.65.65 0 01.968-.401c1.477.9 3.167-.79 2.267-2.267a.65.65 0 01.402-.97c1.68-.407 1.68-2.797 0-3.205a.65.65 0 01-.401-.968c.9-1.477-.79-3.167-2.267-2.267a.65.65 0 01-.97-.402zM6.5 8a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM8 5.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Settings
