const Search = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.5 6.667a4.167 4.167 0 118.333 0 4.167 4.167 0 01-8.333 0zM6.667 1.5a5.167 5.167 0 103.282 9.157l3.697 3.697a.5.5 0 00.708-.708L10.657 9.95a5.167 5.167 0 00-3.99-8.45z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Search
