import { Disclosure } from '@headlessui/react'
import { modifiedAnchorTag } from 'components/shared/helper'
import HTMLRenderer from 'components/shared/helper/HTMLRenderer'
import React from 'react'

const BookingActivity = ({ activity, activityTabData }) => {
  return (
    <div className="px-6 html-renderer-container">
      <div>
        <HTMLRenderer
          preventInteraction={true}
          componentOverrides={{
            video: (Comp) => (props) =>
              (
                <Comp
                  {...props}
                  controlsList="nodownload"
                  onContextMenu={(e) => e.preventDefault()}
                />
              ),
            a: modifiedAnchorTag,
          }}
          html={activity?.content
            .replace(/(\r)/gm, '')
            ?.replace(/\{%tabs:\}(.*?)\{%endtabs\}/gs, '')}
        />
      </div>
      {/* tab wise html data coming from BE */}
      {activityTabData ? <div> {activityTabData}</div> : <></>}

      {activity?.question && activity?.answer && (
        <div>
          <Disclosure>
            {({ open }) => (
              <>
                <div className="bg-gray-50 dark:bg-dark-500 -mx-6 px-6">
                  <div className="pt-2">
                    <HTMLRenderer
                      preventInteraction={true}
                      html={activity?.question}
                    />
                  </div>
                  <Disclosure.Button className={`w-full ${open ? '' : 'pb-2'}`}>
                    <div className="flex items-center justify-center h-full">
                      {activity?.answer && (
                        <div className="text-base font-semibold text-blue-500 h-full">
                          {open ? 'Hide Answer' : 'See Answer'}
                        </div>
                      )}
                    </div>
                  </Disclosure.Button>
                  {activity?.answer && (
                    <div className="bg-gray-50 dark:bg-dark-500 -mx-6 px-6">
                      <Disclosure.Panel className="pb-2">
                        <HTMLRenderer
                          preventInteraction={true}
                          html={activity?.answer}
                        />
                      </Disclosure.Panel>
                    </div>
                  )}
                </div>
              </>
            )}
          </Disclosure>
        </div>
      )}
    </div>
  )
}

export default BookingActivity
