const Menu = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.167 4a.5.5 0 01.5-.5h10.666a.5.5 0 010 1H2.667a.5.5 0 01-.5-.5zm0 4a.5.5 0 01.5-.5h10.666a.5.5 0 010 1H2.667a.5.5 0 01-.5-.5zm.5 3.5a.5.5 0 000 1h10.666a.5.5 0 000-1H2.667z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Menu
