import { Fragment } from 'react'
import { Loading } from 'components/icons'
import { Transition, Dialog } from '@headlessui/react'
import { Button } from 'components/shared'

const SuccessModal = ({
  open,
  title,
  content,
  closeButtonText,
  onClose,
  preventOutsideClick,
  initialLoading,
}) => {
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="bg-gray-700 bg-opacity-80 fixed max-w-full max-h-screen h-screen w-full inset-0 z-50  overflow-y-auto"
          onClose={() => {
            if (!preventOutsideClick && onClose) onClose()
          }}
        >
          <div className="min-h-screen h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-top"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                style={{ maxWidth: '462px' }}
                className="inline-block overflow-y-hidden max-w-full md:max-w-md  md:h-auto w-full p-5 mt-16 md:mt-20 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-dark-600 shadow-xl rounded-md  "
              >
                <div
                  className={`px-2 py-4 flex flex-col justify-between items-start ${
                    initialLoading ? 'opacity-25' : ''
                  }`}
                >
                  <Dialog.Title
                    as="h2"
                    className="text-2xl font-semibold leading-6 text-gray-900 dark:text-white"
                  >
                    <span className="flex items-center gap-2">
                      {title}
                      {initialLoading && (
                        <span className="text-red-500">
                          <Loading />
                        </span>
                      )}
                    </span>
                  </Dialog.Title>
                  <div className="dark:text-white w-full">{content}</div>
                  <div className="w-full flex justify-center items-center mt-4">
                    <Button
                      className="font-semibold tracking-widest py-3 px-6"
                      outlined
                      onClick={() => onClose && onClose()}
                    >
                      {closeButtonText || 'CLOSE'}
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
export default SuccessModal
