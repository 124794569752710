import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

export const getFullExamReviewData = createAsyncThunk(
  'fullExamReview/getFullExamReviewData',
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`history/review/${id}`)
      onSuccess && onSuccess()
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)
export const getExamReportCardData = createAsyncThunk(
  'fullExamReview/getExamReportCardData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `history/review/exam-report-card/${data.id}`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err)
    }
  },
)
export const fullExamReviewSlice = createSlice({
  name: 'fullExamReview',
  initialState: {
    fullExamReviewData: { isLoading: false, data: {}, error: null },
    scoreGraphIndex: 0,
    timeGraphIndex: 0,
    scoreGraphData: null,
    resultOverviewTable: [],
    openReportCardDialog: false,
    examReportCardData: [],
    reportTable: [],
    showGraphImage: false,
    graphImage: '',
    openUntimedModal: false,
    percentilesIndex: 0,
    isRawGrader: false,
    guessGraphIndex: 0,
  },

  extraReducers: {
    [getFullExamReviewData.pending]: (state, action) => {
      state.fullExamReviewData.isLoading = true
    },
    [getFullExamReviewData.fulfilled]: (state, action) => {
      state.fullExamReviewData.isLoading = false
      state.fullExamReviewData.data = action.payload?.data?.result_info
      state.scoreGraphData = {
        grade: action.payload.data?.result_info.grade,
        grade_out_of: action.payload?.data?.result_info?.grade_out_of,
        percentile: action.payload?.data.result_info?.overall_percentile,
        scaled_score: action.payload?.data?.result_info?.overall_scaled_score,
      }
      state.resultOverviewTable = [
        {
          grade: action.payload.data.result_info.grade,
          grade_out_of: action.payload.data.result_info.grade_out_of,
          percentile: action.payload.data.result_info.overall_percentile,
          percentile_textual:
            action.payload.data.result_info.overall_percentile_textual,
          scaled_score: action.payload.data.result_info.overall_scaled_score,
          section: { display_name: 'Overall' },
        },
        ...(action.payload?.data?.result_info?.quiz_results_info
          ?.section_scores || []),
      ]
    },
    [getFullExamReviewData.rejected]: (state, action) => {
      state.fullExamReviewData.isLoading = false
      state.fullExamReviewData.isRejected = false
      state.fullExamReviewData.error = action?.payload?.data?.message
    },
    [getExamReportCardData.pending]: (state, action) => {
      state.examReportCardData = { isLoading: true }
    },
    [getExamReportCardData.fulfilled]: (state, { payload }) => {
      state.examReportCardData = { isLoading: false, data: payload.data }
      state.reportTable = payload?.data?.section_scores
    },
    [getExamReportCardData.rejected]: (state, { payload }) => {
      state.examReportCardData.errorMessage = payload.message
      state.examReportCardData.isLoading = false
      state.examReportCardData.isRejected = true
    },
  },
  reducers: {
    updateGraphImage: (state, { payload }) => {
      state.graphImage = payload
    },
    updateIsShowGraphImage: (state, { payload }) => {
      state.showGraphImage = payload
    },
    updateExamIndex: (state, { payload }) => {
      state.scoreGraphIndex =
        payload === 0
          ? 0
          : findIndex(
              state.fullExamReviewData.data.quiz_results_info.quiz_score_graphs
                .section_score_graphs,
              {
                section: { id: payload },
              },
            )
      const postIndex = findIndex(
        state.fullExamReviewData.data.quiz_results_info.section_scores,
        {
          section: { id: payload },
        },
      )
      state.scoreGraphData =
        payload === 0
          ? {
              grade: state.fullExamReviewData.data.grade,
              grade_out_of: state.fullExamReviewData.data.grade_out_of,
              percentile: state.fullExamReviewData.data.overall_percentile,
              scaled_score: state.fullExamReviewData.data.overall_scaled_score,
            }
          : state.fullExamReviewData.data.quiz_results_info.section_scores[
              postIndex
            ]
    },
    setPercentilesIndex: (state, { payload }) => {
      state.percentilesIndex = payload
    },
    updateExamTimeIndex: (state, { payload }) => {
      state.timeGraphIndex = findIndex(
        state.fullExamReviewData.data.question_timing_graphs
          .section_question_timing,
        {
          section: { id: payload },
        },
      )
    },
    updateGuessIndex: (state, { payload }) => {
      state.guessGraphIndex = findIndex(
        state.fullExamReviewData.data.quiz_results_info.section_scores,
        {
          section: { id: payload },
        },
      )
    },
    setTimeGraphIndex: (state, { payload }) => {
      state.timeGraphIndex = payload
    },
    setResultOverviewTable: (state, { payload }) => {
      state.openReportCardDialog = payload
    },
    setOpenUntimedModal: (state, { payload }) => {
      state.openUntimedModal = payload
    },
    setIsRawGrader: (state, { payload }) => {
      state.isRawGrader = payload
    },
  },
})

export const {
  setIsRawGrader,
  setPercentilesIndex,
  updateGuessIndex,
  updateExamIndex,
  updateExamTimeIndex,
  setResultOverviewTable,
  updateIsShowGraphImage,
  updateGraphImage,
  setTimeGraphIndex,
  setOpenUntimedModal,
} = fullExamReviewSlice.actions

export default fullExamReviewSlice.reducer
