import Parser, { domToReact } from 'html-react-parser'
import style from 'style-to-object'

function HTMLRenderer({
  html,
  imageStyleProps,
  darkImageStyleProps,
  hideVideoDownload,
  preventInteraction,
}) {
  const userData = JSON.parse(localStorage.getItem('user-data'))
  const otherBaseUrl = userData?.other_cohort_base_urls || []

  const handleEvent = (e) => {
    e.preventDefault()
  }

  try {
    return Parser(html, {
      replace: (domNode) => {
        if (domNode?.name === 'img') {
          if (imageStyleProps) {
            domNode.attribs.style = imageStyleProps
            if (domNode?.attribs?.src?.search('.png') && darkImageStyleProps) {
              domNode.attribs.style = domNode.attribs.style =
                darkImageStyleProps
            }
          }
          if (preventInteraction) {
            domNode.attribs.onContextMenu = handleEvent
            domNode.attribs.onDragStart = handleEvent
          }
        }

        if (hideVideoDownload && domNode?.name === 'video') {
          domNode.attribs.controlsList = 'nodownload'
        }
        if (
          domNode?.attribs?.href &&
          !domNode?.attribs?.href.includes('http://') &&
          !domNode?.attribs?.href.includes('https://')
        ) {
          domNode.attribs.href = 'https://' + domNode?.attribs?.href
        }
        if (domNode?.attribs?.href) {
          let matchingString = ''
          const containsPart = otherBaseUrl.some((item) => {
            if (domNode?.attribs?.href.includes(item)) {
              matchingString = item
              return true
            }
            return false
          })

          if (containsPart && matchingString && userData?.cohort_base_url) {
            domNode.attribs.href = domNode?.attribs?.href?.replace(
              matchingString,
              userData?.cohort_base_url,
            )
          }
        }
        if (domNode.attribs && domNode.attribs.style) {
          try {
            style(domNode.attribs.style)
          } catch (error) {
            delete domNode.attribs.style
            return domToReact(domNode)
          }
        }
      },
    })
  } catch (error) {
    return <div>Something went wrong</div>
  }
}

export default HTMLRenderer
