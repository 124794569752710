import { Arrowleft, Loading } from 'components/icons'
import { ThumbUp, Chat } from 'components/icons'
import { Button, Spinner, SmallLogo } from 'components/shared'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {
  createResourceAnswer,
  getResourceAnswer,
  setSubscriptionResource,
  setUpVoteResource,
  updateAnswerPageNumber,
  markResourceAgreement,
  closeAgreementModal,
} from 'redux/learn/resourceQuestionsSlice'
import toast from 'react-hot-toast'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { MTextarea } from 'components/form'
import moment from 'moment'
import { CommunityTermsModal } from 'components/dashboard'

const resourceAnswerSchema = Yup.object({
  answerText: Yup.string()
    .required('Please enter at least 20 characters')
    .test({
      message: 'Please enter at least 20 characters',
      test: function (value) {
        return value && value.trim().length >= 20
      },
    })
    .max(
      2000,
      `The answer text may not be greater than 2000 characters.
    `,
    ),
})
const TutorReply = ({ setOpenReplyPage }) => {
  const dispatch = useDispatch()
  const {
    selectedQuestion,
    resourceAnswerData,
    createAnswerLoader,
    upVoteLoadingId,
    answerPageNumber,
    subscriptionLoader,
    resourceAgreementData,
  } = useSelector((state) => ({
    selectedQuestion: state.resourceQuestions.selectedQuestion,
    resourceAnswerData: state.resourceQuestions.resourceAnswerData,
    createAnswerLoader: state.resourceQuestions.createAnswerLoader,
    upVoteLoadingId: state.resourceQuestions.upVoteLoadingId,
    answerPageNumber: state.resourceQuestions.answerPageNumber,
    subscriptionLoader: state.resourceQuestions.subscriptionLoader,
    resourceAgreementData: state.resourceQuestions.resourceAgreementData,
  }))

  useEffect(() => {
    return () => {
      dispatch(updateAnswerPageNumber(1))
    }
  }, [dispatch])

  return (
    selectedQuestion && (
      <div>
        {/* <CommunityTermsModal
          open={resourceAgreementData?.data?.discussion_terms_agreed === false}
          agreement={resourceAgreementData?.data}
          isLoading={resourceAgreementData?.isLoading}
          onSubmit={() => dispatch(markResourceAgreement())}
          onClose={() => dispatch(closeAgreementModal())}
        /> */}
        <button className="flex mt-7" onClick={() => setOpenReplyPage(false)}>
          <div className="mr-4 mt-0.5">
            <Arrowleft className="dark:text-white" />
          </div>

          <div>
            <p className="text-xs text-gray-900 dark:text-white font-semibold">
              BACK TO ALL QUESTIONS
            </p>
          </div>
        </button>
        <div className=" border-gray-100 mt-4 sm:mt-6" />
        <div className="">
          <div className="pt-6 pb-3.5 sm:py-6 flex ">
            <div>
              {selectedQuestion?.user?.avatar_url ? (
                <img
                  style={{ minHeight: '40px', minWidth: '40px' }}
                  className="h-10 w-10 rounded-full"
                  src={selectedQuestion?.user?.avatar_url}
                  alt={selectedQuestion?.user?.first_name}
                />
              ) : (
                <SmallLogo className="h-8 w-8 " />
              )}
            </div>
            <div className="ml-3 text-gray-900 dark:text-white w-full">
              <p className="font-semibold leading-5 text-base">
                {selectedQuestion.question_subject}
              </p>
              <div className="font-noraml mt-2 leading-6 text-gray-900 dark:text-white text-base break-all">
                {selectedQuestion.question_asked}
              </div>
              <span className="mt-4 sm:flex leading-5 items-center justify-between text-gray-500 text-sm font-normal">
                {selectedQuestion?.user?.first_name}
                {selectedQuestion?.user?.first_name && ' / '}
                {selectedQuestion?.type_breadcrumbs
                  .toString()
                  .replace(/,/g, ', ')}
                {selectedQuestion.type_breadcrumbs && ' / '}
                {moment.utc(selectedQuestion.created_at).fromNow()}
                <div className="mt-3.5 sm:mt-0 justify-end flex items-center text-gray-900">
                  <div
                    className={`flex items-center cursor-pointer
                    ${
                      upVoteLoadingId === selectedQuestion.id &&
                      'opacity-25 pointer-events-none'
                    }
                    `}
                    onClick={() =>
                      dispatch(
                        setUpVoteResource({
                          data: {
                            questionsType: 'question',
                            state: selectedQuestion.is_user_upvoted ? 0 : 1,
                            questionId: selectedQuestion.id,
                          },
                          onError: (errorsMassage) => {
                            toast.error(errorsMassage.message, {
                              duration: 3000,
                              position: 'top-center',
                              style: {
                                backdropFilter: 'blur(4px)',
                                marginTop: '20px',
                                width: '350px',
                              },
                            })
                          },
                        }),
                      )
                    }
                  >
                    <p className="text-sm leading-5">
                      {selectedQuestion.num_upvotes
                        ? selectedQuestion.num_upvotes
                        : 0}
                    </p>
                    <ThumbUp
                      className={`ml-2
                    ${
                      selectedQuestion.is_user_upvoted
                        ? 'text-red-600'
                        : 'dark:text-white'
                    }
                    `}
                    />
                  </div>
                  <div className="flex items-center ml-6">
                    <p className="text-sm leading-5">
                      {selectedQuestion.num_answers}
                    </p>
                    <button>
                      <Chat className="ml-2 dark:text-white" />
                    </button>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-between mt-4">
            <p className="text-gray-900 text-xl font-semibold dark:text-white">
              {selectedQuestion.discussion_notes ? 'Reply' : '0 Replies'}
            </p>
          </div>
          <div className=" border-t border-gray-100 mt-4 sm:mt-6" />

          {selectedQuestion.discussion_notes && (
            <div className="border-b">
              <div className="pt-6 pb-3.5 sm:py-6 flex">
                <div>
                  <SmallLogo className="h-8 w-8 " />
                </div>
                <div className="ml-3 text-gray-900 dark:text-white w-full">
                  <p className="leading-5 text-base">
                    {selectedQuestion.discussion_notes}
                  </p>
                  <p className="font-noraml mt-2 leading-6 text-gray-900 dark:text-white text-base">
                    {selectedQuestion.answer_description}
                  </p>
                  <div className="mt-4 sm:flex items-center justify-between text-gray-500 text-sm font-normal">
                    <div className=" w-full">
                      {selectedQuestion?.user?.first_name}{' '}
                      {selectedQuestion?.user?.first_name && '/'}{' '}
                      {selectedQuestion?.discussed_at &&
                        moment(selectedQuestion?.discussed_at).fromNow()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  )
}
export default TutorReply
