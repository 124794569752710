const Delete = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="14"
    fill="currentColor"
    viewBox="0 0 12 14"
    className={`${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.549 1.549a.167.167 0 01.118-.049h2.666a.167.167 0 01.167.167v1.5h-3v-1.5c0-.045.018-.087.049-.118zM3.5 3.167v-1.5A1.167 1.167 0 014.667.5h2.666A1.167 1.167 0 018.5 1.667v1.5h2.833a.5.5 0 010 1h-.206l-.627 7.522A1.833 1.833 0 018.667 13.5H3.333A1.833 1.833 0 011.5 11.69L.873 4.166H.667a.5.5 0 010-1H3.5zm4.5 1H1.877l.621 7.458a.503.503 0 01.002.042.833.833 0 00.833.833h5.334a.833.833 0 00.833-.833c0-.014 0-.028.002-.042l.621-7.458H8zM4.667 5.833a.5.5 0 01.5.5v4a.5.5 0 11-1 0v-4a.5.5 0 01.5-.5zm3.166.5a.5.5 0 00-1 0v4a.5.5 0 101 0v-4z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default Delete
