export const steps = [
  // home
  {
    selector: '.tour-sidebar',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Home
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          This is your main navigation on the platfrom. Click on the module to
          quickly access the content.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-nav-search',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Home
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can search for resources throughout the platform.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-nav-recent',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Home
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can see the list of the recently viewed pages.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-nav-notifications',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Home
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can see the list of your notifications.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-nav-profile-menu',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Home
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can acces your profile and settings.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-myjourney',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Home
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          This is your journey thoutgh UCAT preparation. Click on "See Full
          Journey" to view more.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-continue-where-left',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Home
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can continue working on the last learning module or practice
          attempt you've tried.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-achievements',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Home
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          This is a place to be proud of - your Achievements. Click on "See All
          Badges" to view more.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-myprogress',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Home
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can see your overall and specific modules' performance. Click
          on "See Analytics" to view more comprehensive report.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-mytasks',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Home
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          This is a snippet of your plans. Click on "Go to Calendar" to create
          your learning plan.
        </p>
      </>
    ),
  },
]
export const myJourneySteps = [
  {
    selector: '.tour-myjourney',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          My Journey
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          This is the score you need to be aiming at based on the universities
          you want to apply to. Click on "Select the Universities" to adjust it.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-myjourney-step',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          My Journey
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          This is the step in your journey. Click on it to be redirected to the
          relevant module.
        </p>
      </>
    ),
  },
]
export const eventsSteps = [
  {
    selector: '.tour-bookings',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Live Events
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          This tab shows you the available and upcoming bookings.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-workshop-resources',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Live Events
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          This tab shows you the resources to the workshop.
        </p>
      </>
    ),
  },
]
export const analyticsSteps = [
  {
    selector: '.tour-analytics-dropdown',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Analytics
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can apply a relevant filter to see your performance based on
          the needed time-frame.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-analytics-bars',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Analytics
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click on the question type to see the performance by category.
        </p>
      </>
    ),
  },
]
export const learnSteps = [
  {
    selector: '.tour-learn-sidebar',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Learn
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          These are the chapters of the chosen guide. You can easily navigate
          through the material by clicking on the specific chapter.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-learn-bookmark',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Learn
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          By clicking on this icon the resource will be added to your bookmarks
          list.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-notes',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Learn
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can leave your notes about this specific resource. You can
          later find all your notes in the Bookmarks.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-q&a',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Learn
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can see all the questions and discussions related to this
          resource.
        </p>
      </>
    ),
  },
]
export const qbankSteps = [
  {
    selector: '.tour-subtest',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          QBank
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can pick the subtest you'd like to practice.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-category',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          QBank
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can pick the category you'd like to practice.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-timed',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          QBank
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can pick the time you'd like to spend for this practice
          attempt.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-recommended',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          QBank
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click here to practice the recommended questions by MedEntry.
        </p>
      </>
    ),
  },
]
export const universityAdmissionsSteps = [
  {
    selector: '.tour-checkbox',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          University Admissions
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click here to pick a university you want to apply to.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-more-info',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          University Admissions
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click here to view more information about university.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-show-picked',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          University Admissions
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click here to view only picked universities.
        </p>
      </>
    ),
  },
]
export const discussionSteps = [
  {
    selector: '.tour-topic',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Community
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click here to pick a university you want to apply to.
        </p>
      </>
    ),
  },
]
export const discussionDescriptionSteps = [
  {
    selector: '.tour-discussion-description',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Community
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click here to follow this discussion.
        </p>
      </>
    ),
  },
]
export const questionReviewSteps = [
  {
    selector: '.tour-ucat-view',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Question Review
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click on this toggle to switch to a UCAT simulated view.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-filter-question',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Question Review
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Filter your questions here by Incorrect, Flagged or All (for QBank &
          Subtest Mocks). For Full Mocks, you can skip to the relevant Subtest
          here.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-calculator',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Question Review
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Bring up the calculator by clicking here, or using the usual UCAT
          shortcut of Alt-C. Note: Other shortcuts such as Alt-N (next question)
          and Alt-P (previous question) will also work.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-bookmark',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Question Review
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Bookmark questions here so that you can come back to it in the future.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-question-descussion',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Question Review
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click on this icon to request that this question be discussed in the
          weekly / intensive classes.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-notes-descussion',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Question Review
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can write notes on the question or discuss it with other
          students.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-error-log',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Question Review
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          By clicking on Error Log, you can choose why you think you made a
          mistake in this question.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-report',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Question Review
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can report a problem or a mistake in the question.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-slider',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Question Review
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click and drag this icon to make the stimulus and question windows
          bigger or smaller.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-questions',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Question Review
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Use this panel to navigate between questions. Colours indicate whether
          you answered the question correctly or incorrectly.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-question-review-questions',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Analytics
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can easily navigate through the questions within the attempt.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-question-review-solution',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Analytics
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click here to see the solution to the question.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-question-review-reason',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Analytics
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Pick the reason you've made a mistake to later access it in the error
          log.
        </p>
      </>
    ),
  },
]
export const skillTrainerSteps = [
  {
    selector: '.tour-instruction',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Skill Trainers
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click here to see the instructions to the trainer.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-performance',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Skill Trainers
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click here to see your performance over time.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-leaderboard',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Skill Trainers
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click here to see your place in the leaderboard.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-ucat-view',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Skill Trainers
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click here if you want the text to resemble UCAT.
        </p>
      </>
    ),
  },
]
export const syllogismsTrainerSteps = [
  {
    selector: '.tour-syllogisms-provide-diagram',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Skill Trainers
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Click here if you want to upload or draw your own diagram.
        </p>
      </>
    ),
  },
]
export const calendarSteps = [
  {
    selector: '.tour-calendar-sidebar',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Calendar
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          Here you can see the list of all resources available on the platform.
          Drag and drop them onto calendar to plan your time.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-calendar-add-event',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Calendar
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          By clicking on this button you can create a personal event for better
          planning.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-calendar-sync-events',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Calendar
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          By clicking on this button you can sync MedEntry calendar with your
          personal to always be in sync with your plans.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-calendar-schedule-event',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Calendar
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          By clicking on this button you can get a recommended schedule created
          by MedEntry that is tailored to your performance.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-calendar-event',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Calendar
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          By clicking on the resource you can go directly to it.
        </p>
      </>
    ),
  },
  {
    selector: '.tour-clear-calendar',
    content: (
      <>
        <p className="text-sm font-normal text-gray-700 dark:text-dark-300">
          Calendar
        </p>
        <p className="text-xl text-gray-900 dark:text-white font-normal mt-2">
          By clicking on this button you can clear your calendar.
        </p>
      </>
    ),
  },
]
