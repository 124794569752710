const Filter = ({ className }) => (
  <svg
    className={`h-4 w-4 ${className}`}
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.50114 0.861672C1.55432 0.843022 1.61026 0.833496 1.66662 0.833496H10.3333C10.3896 0.833496 10.4456 0.843022 10.4988 0.861672C10.6668 0.920598 10.8191 1.0172 10.944 1.14408C11.0689 1.27096 11.1631 1.42475 11.2194 1.59366C11.2758 1.76258 11.2926 1.94214 11.2688 2.1186C11.245 2.29505 11.1812 2.46372 11.0821 2.61167C11.0684 2.63215 11.0532 2.65159 11.0366 2.66983L7.83328 6.19347V10.6668C7.83328 10.8562 7.72628 11.0293 7.55689 11.114C7.3875 11.1987 7.18479 11.1805 7.03328 11.0668L4.36662 9.06683C4.24071 8.9724 4.16662 8.82421 4.16662 8.66683V6.19347L0.963313 2.66983C0.946732 2.65159 0.931523 2.63215 0.917809 2.61167C0.818748 2.46372 0.754867 2.29505 0.731058 2.1186C0.70725 1.94214 0.724145 1.76258 0.780451 1.59366C0.836756 1.42475 0.930978 1.27096 1.0559 1.14408C1.18082 1.0172 1.33312 0.920598 1.50114 0.861672ZM1.78184 1.8335C1.77718 1.8373 1.77272 1.84136 1.76848 1.84566C1.75064 1.86379 1.73718 1.88576 1.72913 1.90989C1.72109 1.93402 1.71868 1.95967 1.72208 1.98488C1.72437 2.00188 1.72927 2.01837 1.73655 2.03379L5.03659 5.66383C5.12026 5.75586 5.16662 5.87578 5.16662 6.00016V8.41683L6.83328 9.66683V6.00016C6.83328 5.87578 6.87964 5.75586 6.96331 5.66383L10.2633 2.03379C10.2706 2.01837 10.2755 2.00188 10.2778 1.98488C10.2812 1.95968 10.2788 1.93402 10.2708 1.90989C10.2627 1.88576 10.2493 1.86379 10.2314 1.84566C10.2272 1.84136 10.2227 1.8373 10.2181 1.8335H1.78184Z"
      fill="currentColor"
    />
  </svg>
)

export default Filter
