import { Shape, Flag, QuestionMark } from 'components/icons'
import Badge from './Badge'
import HTMLRenderer from 'components/shared/helper/HTMLRenderer'
import PercentageBadge from './PercentageBadge'
const Step = (props) => {
  return (
    <div
      style={{ maxWidth: '4rem' }}
      className={`z-20 bg-white dark:bg-dark-600 dark:text-white relative flex flex-col items-center justify-center ${props.className}`}
    >
      <div
        className={`w-16 h-16 flex items-center justify-between p-5   relative border   ${
          props?.status === 'partial-complete' || props?.status === 'completed'
            ? 'bg-red-100 border-red-500 dark:bg-red-500 dark:bg-opacity-10'
            : 'bg-gray-100 border-gray-300 dark:bg-dark-400'
        } ${props?.roundedSmall === 1 ? 'rounded-xl' : 'rounded-full'}`}
      >
        {props?.status === 'completed' && (
          <div className="absolute -top-2.5 right-0 bg-green-700 dark:bg-green-700 dark:border-dark-600 h-5 w-5 rounded-full border-2 border-white flex items-center justify-center">
            <Shape className="w-2 h-2 fill-current text-white dark:text-dark-600" />
          </div>
        )}
        {props?.flagged && (
          <div className="absolute -top-2 right-0 bg-red-100 dark:bg-red-100 dark:border-dark-600 h-6 w-6 rounded-full border-2 border-white flex items-center justify-center">
            <Flag className="w-2 h-2 fill-current text-red-500 dark:text-dark-600" />
          </div>
        )}
        {props?.percent ? <PercentageBadge {...props} /> : ''}
        {props?.tooltip && <Badge {...props} />}
        {props?.icon || (
          <div className="flex items-center justify-center w-full">
            <QuestionMark className="dark:text-white" />
          </div>
        )}
      </div>
      <p
        className={`text-center max-w-xs  mt-2 ${
          !(props && props.status) ? 'text-gray-700' : 'text-gray-900'
        } dark:text-white text-sm font-normal ${
          props?.titleBold &&
          `max-h-6  text-gray-900 dark:text-white text-base font-semibold ${
            props.titleWidth ? '' : 'w-32'
          }`
        } ${props.titleWidth ? `${props.titleWidth}` : ''}`}
        style={{ width: props.titleWidthStyle ? props.titleWidthStyle : '' }}
      >
        {props?.title}
        <br />
        {props?.subTitle && (
          <span
            className={`${
              !/\n/.test(props?.subTitle) && 'whitespace-nowrap'
            } mt-4  text-sm text-red-500 font-normal`}
          >
            {props?.subTitle && (
              <HTMLRenderer html={props?.subTitle?.replace(/\n/, '<br />')} />
            )}
          </span>
        )}
      </p>
    </div>
  )
}
export default Step
