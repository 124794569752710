import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

export const getCommunityData = createAsyncThunk(
  'community/getCommunityData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `community/forum/discussions-list`,
        {
          params: {
            limit: 4,
          },
        },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const likePostFromCommunity = createAsyncThunk(
  'community/likePostFromCommunity',
  async (data) => {
    return await axiosInterceptors.put(
      `community/forum/upvote`,
      {
        state: !data.upvoteByUser,
      },
      {
        params: {
          type: data.type,
          type_id: data.id,
        },
      },
    )
  },
)

export const getCurrentQuestion = createAsyncThunk(
  'community/getCurrentQuestion',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`community/current-items`)
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

const CommunitySlice = createSlice({
  name: 'community',
  initialState: {
    communityData: {
      isLoading: false,
      likeLodingId: null,
      data: [],
      isLocked: false,
    },
    isOpen: false,
    currentQuestionData: { isLoading: false, isLocked: false },
  },
  extraReducers: {
    [getCommunityData.pending]: (state, action) => {
      state.communityData.isLoading = true
    },
    [getCommunityData.fulfilled]: (state, action) => {
      state.communityData.isLoading = false
      state.communityData.isLocked = false
      state.communityData.data = action.payload.data
    },
    [getCommunityData.rejected]: (state, { payload }) => {
      state.communityData.isLoading = false
      if (payload?.status === 403) {
        state.communityData.isLocked = true
      }
    },
    [likePostFromCommunity.pending]: (state, action) => {
      state.communityData.likeLoadingId = action.meta.arg.id
    },

    [likePostFromCommunity.fulfilled]: (state, action) => {
      const postIndex = findIndex(state.communityData.data.discussions, {
        id: action.meta.arg.id,
      })
      state.communityData.data.discussions[postIndex].num_upvotes =
        action.payload.data.num_upvotes
      state.communityData.data.discussions[postIndex].upvoted_by_user =
        action.payload.data.upvote_state
      state.communityData.likeLoadingId = null
    },
    [likePostFromCommunity.rejected]: (state, action) => {},
    [getCurrentQuestion.pending]: (state, action) => {
      state.currentQuestionData.isLoading = true
    },
    [getCurrentQuestion.fulfilled]: (state, { payload }) => {
      state.currentQuestionData = payload.data
      state.currentQuestionData.isLoading = false
      state.currentQuestionData.isLocked = false
    },
    [getCurrentQuestion.rejected]: (state, { payload }) => {
      if (payload?.status === 403) {
        state.currentQuestionData.isLocked = true
      }
      state.currentQuestionData.isLoading = false
    },
  },
  reducers: {
    setIsOpen: (state, action) => {
      state.isOpen = action.payload
    },
  },
})

export const { setIsOpen } = CommunitySlice.actions

export default CommunitySlice.reducer
