import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import VideoPollMainArea from './VideoPollMainArea'
import Calculator from 'components/exam-portal/Calculator'
import {
  setDisabledCalculator,
  updateOptionClickable,
  updateShowCalculator,
} from 'redux/exam-portal/examPortalSlice'

const VideoPoll = ({
  setIsPollModalOpen,
  setModalClosedId,
  chapterId,
  onClosePoll,
}) => {
  const dispatch = useDispatch()
  const { pollQuestionIndex, selectedPoll, showCalculator, popOut, volume } =
    useSelector((state) => ({
      pollQuestionIndex: state.resource.pollQuestionIndex,
      selectedPoll: state.resource.selectedPoll,
      popOut: state.resource.popOut,
      showCalculator: state.examPortal.showCalculator,
      volume: state.resource.volume,
    }))
  const [playAudio, setPlayAudio] = useState(false)

  const [selectedAnswerId, setSelectedAnswerId] = useState(null)
  const [viewAnswer, setViewAnswer] = useState(false)
  const selectedQuestion = selectedPoll?.questions[pollQuestionIndex]

  const answerUserResponse = selectedQuestion?.answers?.find(
    (data) => data?.user_response,
  )
  const showRetry = (answerUserResponse && !viewAnswer) || false

  useEffect(() => {
    if (selectedPoll?.id && !showRetry && !playAudio && !viewAnswer) {
      const audio =
        selectedPoll?.audio?.url && new Audio(selectedPoll?.audio?.url)
      if (audio) {
        audio.volume = volume
      }
      audio?.play()
      setPlayAudio(true)
    }
  }, [
    playAudio,
    selectedPoll?.audio?.url,
    selectedPoll?.id,
    showRetry,
    viewAnswer,
    volume,
  ])
  const closeCalculator = () => {
    dispatch(updateShowCalculator(false))
    dispatch(setDisabledCalculator(true))
    dispatch(updateOptionClickable(true))
  }

  return (
    <div>
      {popOut ? (
        <Transition appear show={popOut} as={Fragment}>
          <Dialog
            as="div"
            className="bg-gray-700 bg-opacity-80 fixed max-w-full max-h-screen h-screen w-full inset-0 z-50  overflow-y-auto"
            onClose={() => {
              //   if (!preventOutsideClick && onClose) onClose()
            }}
          >
            {showCalculator && <Calculator closeCalculator={closeCalculator} />}
            <div className="min-h-screen h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>
              <span
                className="inline-block h-screen align-top"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div
                  style={{ maxWidth: '1080px' }}
                  className="inline-block overflow-y-hidden max-w-full md:max-w-md  md:h-auto w-full p-5 mt-16 md:mt-12 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-dark-600 shadow-xl rounded-md  "
                >
                  <div className=" dark:text-white rounded">
                    <VideoPollMainArea
                      chapterId={chapterId}
                      setIsPollModalOpen={setIsPollModalOpen}
                      setModalClosedId={setModalClosedId}
                      onClosePoll={onClosePoll}
                      setPlayAudio={setPlayAudio}
                      viewAnswer={viewAnswer}
                      setViewAnswer={setViewAnswer}
                      selectedAnswerId={selectedAnswerId}
                      setSelectedAnswerId={setSelectedAnswerId}
                    />
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      ) : (
        <div
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 w-full sm:w-4/5 h-full sm:h-4/5 bg-white dark:bg-dark-600 rounded`}
        >
          <VideoPollMainArea
            chapterId={chapterId}
            setIsPollModalOpen={setIsPollModalOpen}
            setModalClosedId={setModalClosedId}
            onClosePoll={onClosePoll}
            setPlayAudio={setPlayAudio}
            viewAnswer={viewAnswer}
            setViewAnswer={setViewAnswer}
            selectedAnswerId={selectedAnswerId}
            setSelectedAnswerId={setSelectedAnswerId}
          />
        </div>
      )}
    </div>
  )
}

export default VideoPoll
