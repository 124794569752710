import { Fragment, useEffect, useState } from 'react'
import { Close, StarFilled } from 'components/icons'
import { Transition, Dialog } from '@headlessui/react'
import { Button, Hexagon, RoundedBadge } from 'components/shared'
import {
  badgesColorByArea,
  badgeSmallColors,
  communityBadgesColors,
  rankColors,
} from 'components/shared/helper'
const StartTourDialog = ({ data }) => {
  const [open, setOpen] = useState(true)
  useEffect(() => {
    if (data) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [data])
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="bg-gray-700 bg-opacity-80 fixed max-w-full max-h-screen h-screen w-full inset-0 z-50  overflow-y-auto"
          onClose={() => {}}
        >
          <div className="min-h-screen h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-top"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                style={{ maxWidth: '762px' }}
                className="inline-block overflow-y-hidden max-w-full md:max-w-md h-full md:h-auto w-full p-5 mt-16 md:mt-12 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-dark-600 shadow-xl rounded-md  "
              >
                <span
                  className="cursor-pointer float-right"
                  onClick={() => setOpen(false)}
                >
                  <Close className="fill-current dark:text-white" />
                </span>
                <div className="px-1 py-11 sm:p-11  flex flex-col justify-between items-center">
                  <Dialog.Title
                    as="h2"
                    className="mt-8 text-2xl  font-semibold leading-6 text-gray-900 dark:text-white"
                  >
                    New Rank Achieved
                  </Dialog.Title>
                  <div className="flex h-full items-center justify-center">
                    <div className="relative h-full">
                      <StarFilled className="text-green-300 absolute -top-2 right-16 h-3 w-3" />
                      <StarFilled className="text-green-300 absolute top-7 right-6 h-3 w-3" />
                      <StarFilled className="text-green-300 absolute right-0 -top-4 h-5 w-5" />
                    </div>
                    <div>
                      {data.shape === 'hexagon' ? (
                        <Hexagon
                          className="mt-10"
                          color={
                            data.area === 'Community'
                              ? communityBadgesColors[data.slug] || 'gray'
                              : badgesColorByArea[data.area] || 'green'
                          }
                          smallIconColor={
                            badgeSmallColors[data.area_slug]
                              ? badgeSmallColors[data.area_slug]
                              : 'green'
                          }
                          smallIcon={data.small_icon}
                          icon={
                            data &&
                            data.icon && (
                              <img src={data.icon.url} alt={data.icon.name} />
                            )
                          }
                          tooltipText={
                            <div className="html-renderer-container">
                              <span className="text-white font-semibold text-base ">
                                {data && data.name}
                              </span>
                              <br />
                              <span className="mt-1 text-gray-500 text-sm font-normal">
                                {data && data.area}
                              </span>
                              <div className="mt-2">
                                {data &&
                                  data.objectives &&
                                  data.objectives.map((data, index) => (
                                    <li key={index}>{data}</li>
                                  ))}
                              </div>
                            </div>
                          }
                        />
                      ) : (
                        <div className="mt-10">
                          <RoundedBadge
                            color={rankColors[data.slug.split('-')[0]]}
                            smallIconColor="green"
                            smallIcon={data.small_icon}
                            tooltipText={
                              <div className="html-renderer-container">
                                <span className="text-white font-semibold text-base ">
                                  {data && data.name}
                                </span>
                                <br />
                                <span className="mt-1 text-gray-500 text-sm font-normal">
                                  {data && data.area}
                                </span>
                                <div className="mt-2">
                                  {data &&
                                    data.objectives &&
                                    data.objectives.map((data, index) => (
                                      <li key={index}>{data}</li>
                                    ))}
                                </div>
                              </div>
                            }
                            icon={
                              data &&
                              data.icon && (
                                <img src={data.icon.url} alt={data.icon.name} />
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div className="relative h-full">
                      <StarFilled className="text-green-300 absolute -top-2 left-16 h-3 w-3" />
                      <StarFilled className="text-green-300 absolute top-7 left-6 h-5 w-5" />
                      <StarFilled className="text-green-300 absolute left-0 -top-4 h-3 w-3" />
                    </div>
                  </div>

                  <p className="font-semibold text-gray-900 dark:text-white mt-4  text-center w-full">
                    {data.name}
                  </p>
                  <div className="flex">
                    <Button
                      className="mt-10 font-semibold tracking-widest py-4 px-6"
                      outlined
                      onClick={() => setOpen(false)}
                    >
                      CLOSE
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default StartTourDialog
