import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'

export const getUpcomingBooking = createAsyncThunk(
  'booking/getUpcomingBooking',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/bookings/get-upcoming-bookings`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const getStreamBooking = createAsyncThunk(
  'booking/getStreamBooking',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/bookings/get-stream-bookings`,
        {},
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const getFutureEvent = createAsyncThunk(
  'booking/getFutureEvent',
  async ({ website, eventId, onSuccess, university }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/bookings/get-upcoming-events`,
        {},
        {
          params: {
            website: website || 'AU',
            event_type_id: eventId && eventId,
            exclude_full: true,
            filter_university: university ? university : undefined,
          },
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const getAvailableBooking = createAsyncThunk(
  'booking/getAvailableBooking',
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/bookings/get-unused-bookings`,
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const changeBooking = createAsyncThunk(
  'booking/changeBooking',
  async (
    { bookingSlug, eventId, bookingId, streamId, onSuccess },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/bookings/${bookingSlug}`,
        {},
        {
          params: {
            to_event_id: parseInt(eventId),
            booking_id: bookingId ? parseInt(bookingId) : undefined,
            to_stream_id: streamId ? parseInt(streamId) : undefined,
          },
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const getClassStream = createAsyncThunk(
  'booking/getClassStream',
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/bookings/get-available-streams`,
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const makeBooking = createAsyncThunk(
  'booking/makeBooking',
  async (
    { bookingSlug, eventId, orderId, onSuccess, streamId },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/bookings/${bookingSlug}`,
        {},
        {
          params: {
            event_id: eventId,
            order_id: orderId,
            stream_id: streamId,
          },
        },
      )
      onSuccess && onSuccess(res)
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getUpcomingEvent = createAsyncThunk(
  'booking/getUpcomingEvent',
  async ({ website, eventId, onSuccess, university }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/bookings/get-upcoming-events`,
        {},
        {
          params: {
            website: website || 'AU',
            event_type_id: eventId && eventId,
            exclude_full: true,
            filter_university: university ? university : undefined,
          },
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

// new my-booking api
export const getMyBooking = createAsyncThunk(
  'booking/getMyBooking',
  async ({ slug }, { rejectWithValue }) => {
    const bookingSlug = slug || 'get-my-bookings'
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/bookings/${bookingSlug}`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getMoreEvents = createAsyncThunk(
  'booking/getMoreEvents',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/bookings/get-more-events`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getUniversitiesFilters = createAsyncThunk(
  'booking/getUniversitiesFilters',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/bookings/university-names-for-filter`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const downloadCertificate = createAsyncThunk(
  'booking/downloadCertificate',
  async () => {
    return await axiosInterceptors
      .get(`/work-experience/certificate`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        try {
          var newBlob = new Blob([response], {
            type: 'application/octet-stream',
          })

          const url = window.URL.createObjectURL(newBlob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Experience_Certificate.png')
          document.body.appendChild(link)
          link.click()
        } catch (error) {}
      })
  },
)

export const checkRecordingAccess = createAsyncThunk(
  'booking/checkRecordingAccess',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `work-experience/check-recording-access`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

const bookingSlice = createSlice({
  name: 'booking',
  initialState: {
    myBooking: {
      isLoading: false,
      data: null,
    },
    moreEvents: {
      isLoading: false,
      data: null,
    },
    makeBookingLoader: false,
    changeBookingLoader: false,
    selectedBooking: null,
    selectedBookingId: null,
    selectedBookingMove: {},
    upcomingBooking: {
      isLoading: false,
      data: {},
    },
    streamBooking: {
      isLoading: false,
      data: {},
    },
    availableBooking: {
      isLoading: false,
      data: {},
    },
    futureEvent: { loadingId: null, isLoading: false, data: [] },
    classStream: {
      isLoading: false,
      data: {},
    },
    selectedEventMove: { id: '0001', name: 'Select Event' },
    upcomingEvent: { data: null, isLoading: false, id: null },
    selectedBookingEvent: null,
    bookingError: null,
    bookingId: null,
    moveBookingError: null,
    bookingTimeZone: '',
    personalisedBookingsData: { isLoading: false, data: null },
    universitiesFilters: { isLoading: false, data: [] },
    bookingForUniversity: null,
    downloadLoader: false,
    recordingAccess: { isLoading: false, data: null },
    moveBookingData: null,
  },
  extraReducers: {
    [checkRecordingAccess.pending]: (state) => {
      state.recordingAccess.isLoading = true
    },
    [checkRecordingAccess.fulfilled]: (state, { payload }) => {
      state.recordingAccess.isLoading = false
      state.recordingAccess.data = payload?.data
    },
    [checkRecordingAccess.rejected]: (state) => {
      state.recordingAccess.isLoading = false
    },
    [downloadCertificate.pending]: (state) => {
      state.downloadLoader = true
    },
    [downloadCertificate.fulfilled]: (state) => {
      state.downloadLoader = false
    },
    [downloadCertificate.rejected]: (state, { payload }) => {
      state.downloadLoader = false
      toast.error(payload?.message || 'Something went wrong', {
        duration: 3000,
        style: { maxWidth: 'auto' },
      })
    },

    [getUniversitiesFilters.pending]: (state, action) => {
      state.universitiesFilters.isLoading = true
    },
    [getUniversitiesFilters.fulfilled]: (state, { payload }) => {
      state.universitiesFilters.data = payload?.data || []
      state.universitiesFilters.isLoading = false
    },
    [getUniversitiesFilters.rejected]: (state, action) => {
      state.universitiesFilters.isLoading = false
    },
    [getMoreEvents.pending]: (state, action) => {
      state.moreEvents.isLoading = true
    },
    [getMoreEvents.fulfilled]: (state, { payload }) => {
      state.moreEvents.isLoading = false
      state.moreEvents.data = payload.data
    },
    [getMoreEvents.rejected]: (state, action) => {
      state.moreEvents.isLoading = false
    },
    [getMyBooking.pending]: (state, action) => {
      state.myBooking.isLoading = true
    },
    [getMyBooking.fulfilled]: (state, { payload }) => {
      state.myBooking.isLoading = false
      state.myBooking.data = payload.data
      if (payload?.data['workshop-cta']) {
        state.myBooking.data.workshop_cta = payload?.data['workshop-cta'] || []
      }
      if (payload?.data['show-workshop-cta']) {
        state.myBooking.data.show_workshop_cta =
          payload?.data['show-workshop-cta'] || false
      }
    },
    [getMyBooking.rejected]: (state, action) => {
      state.myBooking.isLoading = false
    },
    [getUpcomingEvent.pending]: (state, { meta }) => {
      if (meta.arg.eventId) {
        state.upcomingEvent.isLoading = true
        state.upcomingEvent.id = meta.arg.eventId
      } else {
      }
    },
    [getUpcomingEvent.fulfilled]: (state, { payload }) => {
      state.upcomingEvent.data = payload.data
      state.upcomingEvent.isLoading = false
    },
    [getUpcomingEvent.rejected]: (state, action) => {
      state.upcomingEvent.isLoading = false
    },
    [getUpcomingBooking.pending]: (state, action) => {
      state.upcomingBooking.isLoading = true
    },
    [getUpcomingBooking.fulfilled]: (state, { payload }) => {
      state.upcomingBooking.isLoading = false
      state.upcomingBooking.data = payload.data.map((booking) => ({
        ...booking,
        type: 'upcoming_booking',
      }))
    },
    [getUpcomingBooking.rejected]: (state, action) => {
      state.upcomingBooking.isLoading = false
    },
    [getFutureEvent.pending]: (state, action) => {
      if (action.meta.arg.loadingId) {
        state.futureEvent.isLoading = true
        state.futureEvent.loadingId = action.meta.arg.loadingId
      }
    },
    [getFutureEvent.fulfilled]: (state, { payload, meta }) => {
      state.futureEvent.data = payload.data.map((booking) => ({
        ...booking,
        type: 'future_event',
      }))
      state.futureEvent.loadingId = null
      state.futureEvent.isLoading = false
    },
    [getFutureEvent.rejected]: (state, action) => {
      state.futureEvent.loadingId = null
      state.futureEvent.isLoading = false
    },
    [getStreamBooking.pending]: (state, action) => {
      state.streamBooking.isLoading = true
    },
    [getStreamBooking.fulfilled]: (state, { payload }) => {
      state.streamBooking.isLoading = false
      state.streamBooking.data = payload.data.map((booking) => ({
        ...booking,
        type: 'stream booking',
      }))
    },
    [getStreamBooking.rejected]: (state, action) => {
      state.streamBooking.isLoading = false
    },

    [getAvailableBooking.pending]: (state, action) => {
      if (action.meta.arg.selectedBookingId) {
        state.selectedBookingId = action.meta.arg.selectedBookingId
        state.availableBooking.isLoading = true
      } else {
        state.futureEvent.isLoading = true
      }
    },
    [getAvailableBooking.fulfilled]: (state, { payload }) => {
      state.availableBooking.isLoading = false
      state.futureEvent.isLoading = false
      state.availableBooking.data = payload.data
    },
    [getAvailableBooking.rejected]: (state, action) => {
      state.availableBooking.isLoading = false
      state.futureEvent.isLoading = false
    },
    [getClassStream.pending]: (state, { meta }) => {
      if (meta.arg.eventId) {
        state.upcomingEvent.isLoading = true
        state.upcomingEvent.id = meta.arg.eventId
      }
      state.classStream.isLoading = true
    },
    [getClassStream.fulfilled]: (state, { payload }) => {
      state.classStream.isLoading = false
      state.upcomingEvent.id = null
      state.upcomingEvent.isLoading = false

      state.classStream.data = payload.data.map((booking) => ({
        ...booking,
        type: 'class_stream',
      }))
    },
    [getClassStream.rejected]: (state, action) => {
      state.classStream.isLoading = false
      state.upcomingEvent.id = null
      state.upcomingEvent.isLoading = false
    },
    [changeBooking.pending]: (state, action) => {
      state.changeBookingLoader = true
    },
    [changeBooking.fulfilled]: (state, { payload }) => {
      state.changeBookingLoader = false
    },
    [changeBooking.rejected]: (state, { payload }) => {
      state.changeBookingLoader = false
      state.moveBookingError = payload.error
    },
    [makeBooking.pending]: (state, action) => {
      state.makeBookingLoader = true
    },
    [makeBooking.fulfilled]: (state, { payload }) => {
      state.makeBookingLoader = false
      if (payload?.error) {
        state.bookingError = payload?.message
          ? [payload?.message]
          : ['Unable to make booking.']
      }
    },
    [makeBooking.rejected]: (state, { payload }) => {
      state.makeBookingLoader = false
      state.bookingError = payload.error
    },
  },
  reducers: {
    setMoveBookingData: (state, { payload }) => {
      state.moveBookingData = payload
    },
    setBookingTimeZone: (state, { payload }) => {
      state.bookingTimeZone = payload
    },
    clearBookingData: (state, { payload }) => {
      state.bookingTimeZone = ''
      state.upcomingBooking = {
        isLoading: false,
        data: {},
      }
      state.streamBooking = {
        isLoading: false,
        data: {},
      }
      state.availableBooking = {
        isLoading: false,
        data: {},
      }
      state.futureEvent = { loadingId: null, isLoading: false, data: [] }
      state.classStream = {
        isLoading: false,
        data: {},
      }
      state.upcomingEvent = { data: null, isLoading: false, id: null }
      state.selectedBookingEvent = null
      state.bookingError = null
      state.bookingId = null
      state.moveBookingError = null
    },
    setMoveBookingError: (state, { payload }) => {
      state.moveBookingError = payload
    },
    setBookingId: (state, { payload }) => {
      state.bookingId = payload
    },
    setSelectedBookingEvent: (state, { payload }) => {
      state.selectedBookingEvent = payload
    },
    setBookingForUniversity: (state, { payload }) => {
      state.bookingForUniversity = payload
    },
    setSelectedBookingMove: (state, { payload }) => {
      state.selectedBookingMove = payload
    },
    setSelectedBooking: (state, { payload }) => {
      state.selectedBooking = payload
      state.bookingError = null
    },
    setSelectedEventMove: (state, { payload }) => {
      state.selectedEventMove = payload
    },
  },
})

export const {
  setMoveBookingData,
  setBookingForUniversity,
  setBookingTimeZone,
  clearBookingData,
  setMoveBookingError,
  setBookingId,
  setSelectedBookingEvent,
  setSelectedBookingMove,
  setSelectedBooking,
  setSelectedEventMove,
} = bookingSlice.actions

export default bookingSlice.reducer
