const Note = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.75 5c0-.69.56-1.25 1.25-1.25h10c.69 0 1.25.56 1.25 1.25v14c0 .69-.56 1.25-1.25 1.25H7c-.69 0-1.25-.56-1.25-1.25V5zM7 2.25A2.75 2.75 0 004.25 5v14A2.75 2.75 0 007 21.75h10A2.75 2.75 0 0019.75 19V5A2.75 2.75 0 0017 2.25H7zm2 4a.75.75 0 000 1.5h6a.75.75 0 000-1.5H9zM8.25 11a.75.75 0 01.75-.75h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM9 14.25a.75.75 0 000 1.5h4a.75.75 0 000-1.5H9z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Note
