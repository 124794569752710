import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getAvailableAttempt = createAsyncThunk(
  'resetAttempt/getAvailableAttempt',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptors.get(`resets/available-resets`)
      return response
    } catch (error) {
      return rejectWithValue(error.response)
    }
  },
)

export const resetAttempts = createAsyncThunk(
  'resetAttempt/resetAttempts',
  async ({ areas, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(`resets/reset-attempts`, {
        areas,
      })
      onSuccess && onSuccess()
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)
const resetAttemptSlice = createSlice({
  name: 'resetAttempt',
  initialState: {
    availableAttemptData: { isLoading: false, data: [] },
    resetAttemptModel: false,
    settingLoaderIndex: null,
    settingError: null,
    resetAttemptData: null,
    resetError: false,
    saveAvailableAttempt: [],
  },
  extraReducers: {
    [getAvailableAttempt.pending]: (state, action) => {
      state.availableAttemptData.isLoading = true
    },
    [getAvailableAttempt.fulfilled]: (state, { payload }) => {
      state.availableAttemptData.data = payload.data
      state.availableAttemptData.isLoading = false
    },
    [getAvailableAttempt.rejected]: (state, action) => {
      state.availableAttemptData.isLoading = false
    },
    [resetAttempts.pending]: (state, action) => {
      state.resetAttemptLoading = true
      state.resetSuccessFully = false
      state.resetError = false
    },
    [resetAttempts.fulfilled]: (state, { payload }) => {
      state.resetAttemptData = payload.data
      state.resetSuccessFully = true
      state.resetAttemptLoading = false
    },
    [resetAttempts.rejected]: (state, action) => {
      state.resetAttemptLoading = false
      state.resetSuccessFully = false
      state.resetError = true
    },
  },
  reducers: {
    showResetAttempt: (state, { payload }) => {
      state.resetAttemptModel = payload
    },
    saveResetAttemptData: (state, { payload }) => {
      state.saveAvailableAttempt = [...state.saveAvailableAttempt, payload]
    },
    removeSaveResetAttemptData: (state, { payload }) => {
      state.saveAvailableAttempt = state.saveAvailableAttempt.filter(
        (data) => data !== payload,
      )
    },
    storeAvailableData: (state, { payload }) => {
      state.availableResets = payload
    },
    clearSaveResetAttemptData: (state, { payload }) => {
      state.saveAvailableAttempt = []
    },
    clearResetAttemptData: (state, { payload }) => {
      state.resetAttemptData = null
      state.availableResets = null
    },
    setResetError: (state, { payload }) => {
      state.resetError = payload
    },
  },
})

export const {
  showResetAttempt,
  saveResetAttemptData,
  removeSaveResetAttemptData,
  clearSaveResetAttemptData,
  clearResetAttemptData,
  storeAvailableData,
  setResetError,
} = resetAttemptSlice.actions

export default resetAttemptSlice.reducer
