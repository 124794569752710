import React from 'react'

const RewindIcon = ({ className }) => (
  <svg
    width="14"
    height="7"
    viewBox="0 0 14 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
  >
    <path
      d="M13.5 6.49999C13.5 6.6326 13.4473 6.75978 13.3536 6.85355C13.2598 6.94731 13.1326 6.99999 13 6.99999C12.8674 6.99999 12.7402 6.94731 12.6464 6.85355C12.5527 6.75978 12.5 6.6326 12.5 6.49999C12.4999 5.41229 12.1772 4.34906 11.5729 3.44471C10.9685 2.54036 10.1096 1.83552 9.10467 1.41929C8.09976 1.00307 6.994 0.894148 5.92719 1.10631C4.86038 1.31847 3.88044 1.84219 3.11125 2.61124L1.7125 3.99999H4.5C4.63261 3.99999 4.75979 4.05267 4.85355 4.14644C4.94732 4.24021 5 4.36738 5 4.49999C5 4.6326 4.94732 4.75978 4.85355 4.85355C4.75979 4.94731 4.63261 4.99999 4.5 4.99999H0.5C0.367392 4.99999 0.240215 4.94731 0.146447 4.85355C0.0526784 4.75978 0 4.6326 0 4.49999V0.499993C0 0.367385 0.0526784 0.240208 0.146447 0.14644C0.240215 0.0526714 0.367392 -7.14054e-06 0.5 -7.14054e-06C0.632608 -7.14054e-06 0.759785 0.0526714 0.853553 0.14644C0.947322 0.240208 1 0.367385 1 0.499993V3.29812L2.405 1.90249C3.31418 0.993783 4.47239 0.375051 5.73318 0.124524C6.99398 -0.126003 8.30075 0.00292579 9.48827 0.495008C10.6758 0.987091 11.6908 1.82023 12.4048 2.8891C13.1189 3.95796 13.5 5.21455 13.5 6.49999Z"
      fill="currentColor"
    />
  </svg>
)

export default RewindIcon
