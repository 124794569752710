const Help = ({ className }) => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.93934 1.60603C2.22064 1.32472 2.60218 1.16669 3 1.16669H11C11.3978 1.16669 11.7794 1.32472 12.0607 1.60603C12.342 1.88733 12.5 2.26886 12.5 2.66669V8.00002C12.5 8.39785 12.342 8.77938 12.0607 9.06068C11.7794 9.34198 11.3978 9.50002 11 9.50002H7.66667C7.57605 9.50002 7.48713 9.52465 7.40942 9.57127L4.83333 11.1169V10C4.83333 9.72388 4.60948 9.50002 4.33333 9.50002H3C2.60218 9.50002 2.22064 9.34198 1.93934 9.06068C1.65804 8.77938 1.5 8.39784 1.5 8.00002V2.66669C1.5 2.26886 1.65804 1.88733 1.93934 1.60603ZM3 0.166687C2.33696 0.166687 1.70107 0.430079 1.23223 0.89892C0.763392 1.36776 0.5 2.00365 0.5 2.66669V8.00002C0.5 8.66306 0.763392 9.29895 1.23223 9.76779C1.70107 10.2366 2.33696 10.5 3 10.5H3.83333V12C3.83333 12.1802 3.93023 12.3464 4.08698 12.4351C4.24374 12.5239 4.43612 12.5214 4.59058 12.4288L7.80516 10.5H11C11.663 10.5 12.2989 10.2366 12.7678 9.76779C13.2366 9.29895 13.5 8.66306 13.5 8.00002V2.66669C13.5 2.00365 13.2366 1.36776 12.7678 0.89892C12.2989 0.430079 11.663 0.166687 11 0.166687H3ZM3.83333 4.00002C3.83333 3.72388 4.05719 3.50002 4.33333 3.50002H9.66667C9.94281 3.50002 10.1667 3.72388 10.1667 4.00002C10.1667 4.27616 9.94281 4.50002 9.66667 4.50002H4.33333C4.05719 4.50002 3.83333 4.27616 3.83333 4.00002ZM3.83333 6.66669C3.83333 6.39054 4.05719 6.16669 4.33333 6.16669H8.33333C8.60948 6.16669 8.83333 6.39054 8.83333 6.66669C8.83333 6.94283 8.60948 7.16669 8.33333 7.16669H4.33333C4.05719 7.16669 3.83333 6.94283 3.83333 6.66669Z"
      fill="currentColor"
    />
  </svg>
)

export default Help
