import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'

import { axiosInterceptors } from 'utils/router/api'

export const getContactReasonData = createAsyncThunk(
  'contactReason/getContactReasonData',
  async (data) => {
    return await axiosInterceptors.get(`contact/reasons/${data.reasons}`)
  },
)

export const submitLeaveFeedback = createAsyncThunk(
  'contactReason/submitLeaveFeedback',
  async (data, { rejectWithValue }) => {
    const { values, reportableId, onSuccess, onError, browser, os, device } =
      data
    const formData = new FormData()
    formData.append('message', values.message)
    reportableId && formData.append('reportable_id', reportableId)
    formData.append('browser', browser && browser)
    formData.append('os', os && os)
    formData.append('device', device && device)
    values?.attachment?.length > 0 &&
      formData.append('attachment', values?.attachment[0])

    try {
      const res = await axiosInterceptors.post(
        `contact/submit-query/${values.subject.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      onSuccess && onSuccess(res)
      return res
    } catch (err) {
      const errorType = rejectWithValue(err?.response?.data)
      onError && onError(errorType?.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)
const contactReasonSlice = createSlice({
  name: 'contactReason',
  initialState: {
    contactReasonData: { isLoading: false, reasons: [] },
    selectedReason: {},
    toggleErrorReport: false,
    isSendLoading: false,
    successMessage: null,
    queryAttachment: [],
    contactReasonError: null,
  },
  extraReducers: {
    [submitLeaveFeedback.pending]: (state) => {
      state.isSendLoading = true
    },
    [submitLeaveFeedback.fulfilled]: (state, { payload }) => {
      state.isSendLoading = false
      state.successMessage = payload.message
      toast.success('Your message has been sent to the MedEntry team', {
        duration: 4000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '350px',
        },
      })
    },
    [submitLeaveFeedback.rejected]: (state, { payload }) => {
      state.isSendLoading = false
      state.contactReasonError = payload
    },
    [getContactReasonData.pending]: (state, action) => {
      state.contactReasonData.isLoading = true
    },
    [getContactReasonData.fulfilled]: (state, { payload }) => {
      state.contactReasonData.reasons = payload?.data?.reasons
      if (payload?.data?.reasons?.length === 1) {
        state.selectedReason = {
          ...payload?.data?.reasons[0],
          name: payload?.data?.reasons[0]?.title,
        }
      }
      state.contactReasonData.isLoading = false
    },
    [getContactReasonData.rejected]: (state, action) => {
      state.contactReasonData.isLoading = false
    },
  },
  reducers: {
    setQueryAttachment: (state, { payload }) => {
      state.queryAttachment = payload
      state.contactReasonError = null
    },
    setSelectedReason: (state, { payload }) => {
      state.selectedReason = payload
    },
    setToggleErrorReport: (state, { payload }) => {
      state.toggleErrorReport = payload
    },
    clearData: (state) => {
      state.contactReasonData = { isLoading: false, reasons: [] }
      state.selectedReason = {}
      state.successMessage = null
      state.contactReasonError = null
    },
    clearSuccessMessage: (state) => {
      state.successMessage = null
    },
  },
})

export const {
  setQueryAttachment,
  setSelectedReason,
  setToggleErrorReport,
  clearData,
  clearSuccessMessage,
} = contactReasonSlice.actions

export default contactReasonSlice.reducer
