import { Success } from 'components/icons'
import { isUcatPath } from 'components/shared/helper'
import { Link, useParams } from 'react-router-dom'
const Summary = ({ bookIndex, bookModule, resourceData }) => {
  const { moduleArea, resourceType, moduleId, bookId } = useParams()
  const nextResource =
    resourceData &&
    resourceData.data &&
    resourceData.data.next &&
    resourceData.data.next.find((data) => data.type === 'resource')

  const nextSubmodule =
    resourceData &&
    resourceData.data &&
    resourceData.data.next &&
    resourceData.data.next.find((data) => data.type === 'submodule')

  const nextModule =
    resourceData &&
    resourceData.data &&
    resourceData.data.next &&
    resourceData.data.next.find((data) => data.type === 'module')
  return (
    <div className="w-full mb-6 pb-10 px-6">
      <div>
        <div className="flex justify-center text-center">
          <Success />
        </div>
        <div className="flex justify-center mt-4 text-xl text-gray-900 dark:text-white font-semibold text-center capitalize">
          You have successfully completed:{' '}
          {resourceData &&
            resourceData.data &&
            resourceData.data.book &&
            resourceData.data.book.name}
        </div>
        {/* Link to submodule and module */}
        {nextSubmodule && !nextResource ? (
          <Link
            to={`${isUcatPath}/${moduleArea}/module/${nextSubmodule?.data?.module_id}`}
          >
            <div className="w-full flex justify-center">
              <div className="mt-9 w-max px-4 text-sm py-4 text-white bg-red-500 font-semibold items-center rounded-md">
                Go to next module
              </div>
            </div>
          </Link>
        ) : (
          nextModule &&
          !nextResource && (
            <Link
              to={`${isUcatPath}/${nextModule?.data?.area}/module/${nextModule?.id}`}
            >
              <div className="w-full flex justify-center">
                <div className="mt-9 w-max px-4 text-sm py-4 text-white bg-red-500 font-semibold items-center rounded-md">
                  Go to next module
                </div>
              </div>
            </Link>
          )
        )}
        {nextResource && (
          <div className="flex justify-center mt-7 text-center">
            <Link
              to={`${isUcatPath}/${moduleArea}/${nextResource?.data?.slug}/1`}
              className="btn-primary px-6 py-4 tracking-widest font-semibold"
            >
              {nextResource && nextResource.name}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
export default Summary
