import React from 'react'
import SubModuleContainer from './SubModuleContainer'
import { Link } from 'react-router-dom'
import { isUcatPath } from 'components/shared/helper'

const SubModuleAndResources = ({
  subModule,
  resources,
  markBookStarted,
  id,
  typeOfBook,
  isHPAT,
}) => {
  return (
    <div className="w-full mt-4">
      <div className="px-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 dark:text-white">
          {resources &&
            resources.map((qna, qnaIndex) => (
              <div key={qnaIndex}>
                <SubModuleContainer
                  resource={qna}
                  typeOfBook={typeOfBook}
                  id={id}
                  moduleId={qna.id}
                />
              </div>
            ))}
        </div>
      </div>
      <div className="pb-6">
        {subModule &&
          subModule?.map((tab, tabIndex) => {
            const isAdditionalResources = tab.name === 'Additional Resources'

            return isAdditionalResources ? (
              <div>
                <div className="flex item-center justify-between mx-6 px-2 my-3.5  border-l-4 border-red-500 ">
                  <div className="font-semibold text-lg text-gray-900 dark:text-white text-left">
                    {tab.name}
                  </div>
                </div>
                <div className="mx-6 px-2">
                  {tab?.resources?.map((qna, qnaIndex) => (
                    <div key={qnaIndex} className="w-full ">
                      <div
                        className={`flex  px-6 py-3.5 border-b dark:border-dark-500 w-full ${
                          qna.user_cannot_access
                            ? 'pointer-events-none opacity-25'
                            : ''
                        }`}
                      >
                        <div className="rounded-full ">
                          <input
                            type="checkbox"
                            readOnly
                            className="checkbox checkbox-secondary text-red-600 rounded-full"
                            checked={qna?.user_completed}
                          />
                        </div>
                        <Link
                          className="w-full flex justify-between items-center"
                          to={
                            qna.user_cannot_access
                              ? '#'
                              : `${isUcatPath}/${typeOfBook}/${qna.slug}/${1}`
                          }
                        >
                          <h1 className=" text-sm ml-3 mt-1 text-gray-900 dark:text-white font-normal cursor-pointer">
                            {qna.name}
                          </h1>
                          <div
                            className={`${
                              qna?.user_completion_percentage !== 0
                                ? 'text-green-700'
                                : 'text-gray-400'
                            }  text-sm`}
                          >
                            {qna?.user_completion_percentage !== null &&
                            qna?.user_completion_percentage !== undefined
                              ? qna?.user_completion_percentage + ' %'
                              : ''}
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div key={tabIndex} className="w-full">
                <div className="flex item-center justify-between mx-6 px-2 my-3.5  border-l-4 border-red-500 ">
                  <div className="font-semibold text-lg text-gray-900 dark:text-white text-left">
                    {tab.name}
                  </div>
                </div>
                <div className="mb-8">
                  <div
                    className={`grid gap-4 dark:text-white px-6 mt-4 ${
                      isAdditionalResources && isHPAT
                        ? 'grid-cols-1 w-1/3'
                        : 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'
                    }`}
                  >
                    {tab.resources &&
                      tab.resources.map((qna, qnaIndex) => (
                        <div key={qnaIndex} className="">
                          <SubModuleContainer
                            resource={qna}
                            moduleId={tab.id}
                            id={id}
                            typeOfBook={typeOfBook}
                            isAdditionalResources={
                              isAdditionalResources && isHPAT
                            }
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default SubModuleAndResources
