const ChevronUp = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.02 9.687a.5.5 0 01-.707 0L8 7.374 5.687 9.687a.5.5 0 01-.707-.707l2.666-2.667a.5.5 0 01.707 0L11.02 8.98a.5.5 0 010 .707z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default ChevronUp
