const Community = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="current"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="current"
      fillRule="evenodd"
      d="M8.052 2.5a5.63 5.63 0 00-.104 0c-.552.938-.958 1.95-1.208 3h2.52a10.833 10.833 0 00-1.208-3zm1.396 4H6.552a10.833 10.833 0 000 3h2.896a10.833 10.833 0 000-3zm1.008 3a11.825 11.825 0 000-3h2.837a5.506 5.506 0 010 3h-2.837zm-1.197 1H6.74c.25 1.05.656 2.062 1.208 3h.104c.552-.938.958-1.95 1.207-3zm.013 2.852c.46-.905.8-1.864 1.013-2.852H12.9a5.51 5.51 0 01-3.628 2.852zm-1.011 1.143a.5.5 0 00.198-.011 6.5 6.5 0 000-12.968.501.501 0 00-.198-.01 6.608 6.608 0 00-.522 0 .501.501 0 00-.198.01 6.5 6.5 0 000 12.968.5.5 0 00.198.01 6.65 6.65 0 00.522 0zM9.272 2.648A5.51 5.51 0 0112.9 5.5h-2.615a11.834 11.834 0 00-1.013-2.852zM6.728 13.352A11.833 11.833 0 015.715 10.5H3.1a5.51 5.51 0 003.628 2.852zM5.448 8c0-.503.032-1.004.096-1.5H2.707c-.135.477-.207.98-.207 1.5s.072 1.023.207 1.5h2.837A11.832 11.832 0 015.448 8zm.267-2.5c.214-.988.554-1.947 1.013-2.852A5.51 5.51 0 003.1 5.5h2.615z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Community
