import { Dialog, Transition } from '@headlessui/react'
import { HeadlessSelect, MTextarea } from 'components/form'
import { Close, Loading, SadEmoji, ThumbUp } from 'components/icons'
import { Button, Spinner } from 'components/shared'
import { Form, Formik } from 'formik'
import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  clearData,
  clearSuccessMessage,
  getContactReasonData,
  setQueryAttachment,
  setSelectedReason,
  submitLeaveFeedback,
} from 'redux/contact-reason/contactReasonSlice'
import * as Yup from 'yup'
import {
  isMobile,
  isTablet,
  isDesktop,
  isChrome,
  isSafari,
  isEdge,
  isFirefox,
  osName,
  browserVersion,
  osVersion,
} from 'react-device-detect'
import toast from 'react-hot-toast'
import QueryAttachment from './QueryAttachment'

const contactReasonsSchema = Yup.object({
  subject: Yup.object().required('Subject is required'),
  message: Yup.string()
    .required('Message is required')
    .test({
      message: 'The Message must be at least 3 characters',
      test: function (value) {
        return value && value.trim().length >= 3
      },
    }),
})
const ContactReasonsDialog = ({
  reasons,
  showDialog,
  closeDialog,
  title,
  currentQuestionId,
  selectedQuestionId,
  isFromExamPortal,
  isFromWeeklyReview,
  browserName,
  reportFromOutside,
}) => {
  const dispatch = useDispatch()
  const {
    contactReasonData,
    selectedReason,
    isSendLoading,
    successMessage,
    queryAttachment,
    contactReasonError,
  } = useSelector((state) => ({
    contactReasonData: state.contactReason.contactReasonData,
    selectedReason: state.contactReason.selectedReason,
    isSendLoading: state.contactReason.isSendLoading,
    successMessage: state.contactReason.successMessage,
    queryAttachment: state.contactReason.queryAttachment,
    contactReasonError: state.contactReason.contactReasonError,
  }))
  useEffect(() => {
    reasons && dispatch(getContactReasonData({ reasons: reasons }))
    return () => {
      dispatch(clearData())
    }
  }, [dispatch, reasons])
  useEffect(() => {
    if (
      reasons === 'question,weeklyclasses' &&
      contactReasonData?.reasons &&
      isFromExamPortal
    ) {
      const contactReasons = contactReasonData?.reasons?.filter((data) =>
        currentQuestionId
          ? data?.reportable_type === 'QuestionAttempt'
          : data?.reportable_type !== 'QuestionAttempt',
      )
      if (contactReasons?.length === 1)
        dispatch(
          setSelectedReason({
            ...contactReasons[0],
            name: contactReasons[0]?.title,
          }),
        )
    }
    return () => (isFromExamPortal ? dispatch(setSelectedReason({})) : '')
  }, [
    contactReasonData?.reasons,
    dispatch,
    currentQuestionId,
    reasons,
    isFromExamPortal,
  ])
  useEffect(() => {
    if (
      reasons === 'weeklyclasses' &&
      contactReasonData?.reasons &&
      isFromWeeklyReview
    ) {
      const contactReasons = contactReasonData?.reasons?.filter((data) =>
        currentQuestionId
          ? data?.reportable_type === 'QuestionAttempt'
          : data?.reportable_type !== 'QuestionAttempt',
      )
      if (contactReasons?.length === 1)
        dispatch(
          setSelectedReason({
            ...contactReasons[0],
            name: contactReasons[0]?.title,
          }),
        )
    }
    return () => (isFromExamPortal ? dispatch(setSelectedReason({})) : '')
  }, [
    contactReasonData?.reasons,
    currentQuestionId,
    dispatch,
    isFromExamPortal,
    isFromWeeklyReview,
    reasons,
  ])

  const device = () => {
    if (isMobile) {
      return 'mobile'
    } else if (isTablet) {
      return 'tablet'
    } else if (isDesktop) {
      return 'desktop'
    }
  }

  const browser = () => {
    if (browserName) {
      return browserName
    } else if (isChrome) {
      return 'Chrome'
    } else if (isSafari) {
      return 'Safari'
    } else if (isEdge) {
      return 'Edge'
    } else if (isFirefox) {
      return 'Firefox'
    }
  }

  function getResolution() {
    const realWidth = window.screen.width
    const realHeight = window.screen.height
    return `${realWidth}x${realHeight}`
  }

  return (
    <div>
      <Transition appear show={showDialog} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto bg-gray-700 bg-opacity-80"
          onClose={() => {}}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                style={{ maxWidth: '762px' }}
                className="inline-block w-4/5 max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-dark-600 shadow-xl rounded-md"
              >
                <button
                  onClick={() => {
                    closeDialog()
                    dispatch(clearSuccessMessage())
                    dispatch(setQueryAttachment([]))
                  }}
                  className={`absolute right-6 ${
                    isSendLoading ? 'pointer-events-none opacity-25' : ''
                  }`}
                  disabled={isSendLoading}
                >
                  <Close className="fill-current dark:text-white" />
                </button>
                <Dialog.Title
                  as="h3"
                  className="text-xl font-semibold leading-6 text-text-800 mb-8 dark:text-white"
                >
                  {title}
                </Dialog.Title>
                {contactReasonData && contactReasonData.isLoading ? (
                  <Spinner />
                ) : contactReasonData.reasons &&
                  ((contactReasonData.reasons.length > 0 && selectedReason) ||
                    reportFromOutside) ? (
                  <>
                    <Formik
                      initialValues={{
                        subject: selectedReason?.id ? selectedReason : '',
                        message: '',
                        attachment: [],
                      }}
                      validationSchema={contactReasonsSchema}
                      onSubmit={(values, { resetForm, setFieldError }) => {
                        values = {
                          ...values,
                          subject: selectedReason,
                        }
                        dispatch(
                          submitLeaveFeedback({
                            values: values,
                            reportableId: selectedQuestionId,
                            browser: `${browser()} ${browserVersion}`,
                            os: `${osName} ${osVersion}`,
                            device: `${device()} ${getResolution()}`,
                            onSuccess: (data) => {},
                            onError: (errorsMassage) => {
                              errorsMassage?.errors &&
                                Object.keys(errorsMassage.errors).forEach(
                                  (error) =>
                                    error !== 'message' &&
                                    setFieldError(
                                      error,
                                      errorsMassage.errors[error][0] ||
                                        errorsMassage.message,
                                    ),
                                )

                              toast.error(
                                errorsMassage?.errors?.message?.length > 0
                                  ? errorsMassage?.errors?.message[0]
                                  : errorsMassage?.message,
                                {
                                  duration: 5000,
                                  position: 'top-center',
                                  icon: (
                                    <div className="mt-2 bg-red-500 text-white rounded-full p-1">
                                      <Close className="fill-current text-white" />
                                    </div>
                                  ),
                                  className: 'flex items-start',
                                  style: {
                                    backdropFilter: 'blur(4px)',
                                    marginTop: '20px',
                                    width: '550px',
                                  },
                                },
                              )
                            },
                          }),
                        )
                      }}
                    >
                      {({ handleSubmit, setFieldValue }) => (
                        <Form onSubmit={handleSubmit}>
                          <>
                            {successMessage ? (
                              <div className="flex flex-col justify-center text-gray-900 dark:text-white items-center mt-4">
                                <div className="h-12 w-12 rounded-full bg-blue-200 flex justify-center items-center">
                                  <ThumbUp className="w-8 h-8 text-blue-500" />
                                </div>
                                <div className="mt-4">
                                  {successMessage
                                    .split(' ')
                                    .map((text, index) => {
                                      return text === 'package' ? (
                                        <Link
                                          on
                                          to="/profile/my-purchases"
                                          className="text-blue-500"
                                          key={index}
                                        >
                                          <span
                                            onClick={() => {
                                              closeDialog()
                                              dispatch(clearSuccessMessage())
                                            }}
                                          >
                                            package{' '}
                                          </span>
                                        </Link>
                                      ) : (
                                        <span key={index}>
                                          {selectedReason?.success_message ||
                                            text}
                                        </span>
                                      )
                                    })}
                                </div>
                              </div>
                            ) : (
                              <div className="mt-2 w-full">
                                {!reportFromOutside && (
                                  <HeadlessSelect
                                    disabled={isSendLoading}
                                    textPostion="text-left"
                                    firstCapital={true}
                                    name="subject"
                                    label="Subject"
                                    options={
                                      reasons === 'question,weeklyclasses' ||
                                      reasons === 'question'
                                        ? contactReasonData?.reasons
                                            ?.filter((data) =>
                                              isFromExamPortal
                                                ? !currentQuestionId
                                                  ? data?.reportable_type !==
                                                    'QuestionAttempt'
                                                  : data?.reportable_type ===
                                                    'QuestionAttempt'
                                                : data?.reportable_type ===
                                                  'QuestionAttempt',
                                            )
                                            ?.map((reason) => {
                                              return {
                                                ...reason,
                                                name: reason.title,
                                              }
                                            })
                                        : contactReasonData?.reasons?.map(
                                            (reason) => {
                                              return {
                                                ...reason,
                                                name: reason.title,
                                              }
                                            },
                                          )
                                    }
                                    selectedValue={
                                      (selectedReason?.id &&
                                        selectedReason) || {
                                        name: 'Please select option',
                                        id: -1,
                                      }
                                    }
                                    optionLabelField="id"
                                    setSelectedValue={(value) =>
                                      dispatch(setSelectedReason(value))
                                    }
                                    dropdownHeight="max-h-40"
                                  />
                                )}
                                <MTextarea
                                  label={
                                    <>
                                      Message{' '}
                                      <span className="text-red-500">*</span>
                                    </>
                                  }
                                  name="message"
                                  rows="4"
                                  cols="40"
                                  placeholder={
                                    selectedReason?.placeholder_text
                                      ? selectedReason?.placeholder_text
                                      : ''
                                  }
                                  className={`mt-1 border-gray-500 dark:border-dark-300 w-full border border-1 rounded-md ${
                                    isSendLoading
                                      ? 'pointer-events-none opacity-25'
                                      : ''
                                  }`}
                                />
                                {selectedReason?.supports_attachment && (
                                  <div className="mt-2">
                                    <span className="text-sm text-gray-700 dark:text-dark-100">
                                      Attachment (e.g. a screenshot or photo of
                                      the problem)
                                    </span>
                                    <div
                                      className={`${
                                        isSendLoading
                                          ? 'pointer-events-none opacity-25'
                                          : ''
                                      }`}
                                    >
                                      <QueryAttachment
                                        padding="py-4"
                                        name="attachment"
                                        acceptType="image/jpeg, image/jpg, image/png, image/gif"
                                        files={queryAttachment}
                                        fileTitle="Drag and drop file here"
                                        fileDescription="Accepted formats: jpg/png, Max file size: 2.5 MB"
                                        clearFile={() =>
                                          dispatch(setQueryAttachment([]))
                                        }
                                        setFiles={(file) => {
                                          const image = file[0]
                                          if (!image) {
                                            return
                                          }
                                          if (image.size > 2500 * 1024) {
                                            return toast.error(
                                              'File size must be less than 2500KB',
                                              {
                                                duration: 3000,
                                                position: 'top-center',
                                                style: {
                                                  backdropFilter: 'blur(4px)',
                                                  marginTop: '20px',
                                                  width: '450px',
                                                },
                                              },
                                            )
                                          }
                                          setFieldValue('attachment', file)
                                          dispatch(setQueryAttachment(file))
                                        }}
                                      />
                                    </div>
                                    {contactReasonError?.errors?.attachment
                                      ?.length > 0 && (
                                      <span className="mt-2 font-semibold text-sm text-red-500">
                                        {
                                          contactReasonError?.errors
                                            ?.attachment[0]
                                        }
                                      </span>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            <div className="flex mt-8 space-x-2">
                              {!successMessage && (
                                <Button
                                  disabled={isSendLoading}
                                  type="submit"
                                  className={`py-4 px-6 font-semibold tracking-widest ${
                                    isSendLoading
                                      ? 'pointer-events-none opacity-25'
                                      : ''
                                  }`}
                                >
                                  {isSendLoading ? (
                                    <div className="flex items-center justify-center ">
                                      Loading
                                      <Loading className="ml-4" />
                                    </div>
                                  ) : (
                                    'Send'
                                  )}
                                </Button>
                              )}
                              <Button
                                type="button"
                                disabled={isSendLoading}
                                className={`py-4 px-6 font-semibold tracking-widest ${
                                  isSendLoading
                                    ? 'pointer-events-none opacity-25'
                                    : ''
                                }`}
                                onClick={() => {
                                  closeDialog()
                                  dispatch(clearSuccessMessage())
                                  dispatch(setQueryAttachment([]))
                                }}
                                outlined
                              >
                                Close
                              </Button>
                            </div>
                          </>{' '}
                        </Form>
                      )}
                    </Formik>
                  </>
                ) : (
                  !reportFromOutside && (
                    <div className="text-black dark:text-white font-semibold flex flex-col items-center gap-2 w-full justify-center">
                      <div className="bg-gray-200 dark:bg-dark-500 h-10 w-10 rounded-full text-center flex justify-center items-center">
                        <SadEmoji className="h-6 w-6" />
                      </div>
                      <span className="text-lg">
                        This contact form is currently unavailable
                      </span>
                    </div>
                  )
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default ContactReasonsDialog
