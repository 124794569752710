const Instagram = ({ className, ...props }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={`h-4 w-4 ${className}`}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33341 1.16666C2.1368 1.16666 1.16675 2.13671 1.16675 3.33332V8.66666C1.16675 9.86327 2.1368 10.8333 3.33341 10.8333H8.66675C9.86336 10.8333 10.8334 9.86327 10.8334 8.66666V3.33332C10.8334 2.13671 9.86336 1.16666 8.66675 1.16666H3.33341ZM0.166748 3.33332C0.166748 1.58442 1.58451 0.166656 3.33341 0.166656H8.66675C10.4156 0.166656 11.8334 1.58442 11.8334 3.33332V8.66666C11.8334 10.4156 10.4156 11.8333 8.66675 11.8333H3.33341C1.58451 11.8333 0.166748 10.4156 0.166748 8.66666V3.33332ZM6.00008 4.49999C5.17165 4.49999 4.50008 5.17156 4.50008 5.99999C4.50008 6.82842 5.17165 7.49999 6.00008 7.49999C6.82851 7.49999 7.50008 6.82842 7.50008 5.99999C7.50008 5.17156 6.82851 4.49999 6.00008 4.49999ZM3.50008 5.99999C3.50008 4.61928 4.61937 3.49999 6.00008 3.49999C7.38079 3.49999 8.50008 4.61928 8.50008 5.99999C8.50008 7.3807 7.38079 8.49999 6.00008 8.49999C4.61937 8.49999 3.50008 7.3807 3.50008 5.99999ZM9.50008 2.99999C9.50008 2.72385 9.27622 2.49999 9.00008 2.49999C8.72394 2.49999 8.50008 2.72385 8.50008 2.99999V3.00066C8.50008 3.2768 8.72394 3.50066 9.00008 3.50066C9.27622 3.50066 9.50008 3.2768 9.50008 3.00066V2.99999Z"
      fill="currentColor"
    />
  </svg>
)

export default Instagram
