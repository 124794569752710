import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getLearnData = createAsyncThunk('learn/getLearnData', async () => {
  return await axiosInterceptors.get('/resource/modules/learn')
})

export const getBookData = createAsyncThunk('learn/getBookData', async () => {
  return await axiosInterceptors.get('/resource/modules/uncategorised')
})

export const markBookStarted = createAsyncThunk(
  'learn/markBookStarted',
  async (data) => {
    return await axiosInterceptors.post(
      `/resource/book/${data.bookId}/mark-started`,
    )
  },
)
export const markBookChapterOpened = createAsyncThunk(
  'learn/markBookChapterOpened',
  async (data) => {
    return await axiosInterceptors.post(
      `/resource/book/chapter/${data.chapterId}/mark-opened`,
    )
  },
)

const learnSlice = createSlice({
  name: 'learn',
  initialState: {
    learnData: [],
    learnDataLoader: false,
    bookResourceData: [],
  },
  extraReducers: {
    [getLearnData.pending]: (state, action) => {
      state.learnDataLoader = true
    },
    [getLearnData.fulfilled]: (state, action) => {
      state.learnData = action.payload.data
      state.learnDataLoader = false
    },
    [getLearnData.rejected]: (state, action) => {
      state.learnDataLoader = false
    },
    [getBookData.pending]: (state, action) => {},
    [getBookData.fulfilled]: (state, { payload }) => {
      state.bookResourceData = payload?.data
    },
    [getBookData.rejected]: (state, action) => {},
  },
})

export default learnSlice.reducer
