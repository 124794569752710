import { Warning } from 'components/icons'
import { Tooltip } from 'components/form'
const Badge = (props) => {
  return (
    <Tooltip text={props?.tooltip}>
      <div className="group absolute -top-2 left-0 bg-orange-500 dark:border-dark-600 z-20  dark:bg-opacity-10 h-6 w-6 rounded-full border border-white flex items-center justify-center">
        <Warning className="h-3 w-3 fill-current text-orange-700" />
      </div>
    </Tooltip>
  )
}
export default Badge
