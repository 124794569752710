import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

export const getErrorReviewData = createAsyncThunk(
  'errorReview/getErrorReviewData',
  async ({ page, sectionId, reasonId }) => {
    return await axiosInterceptors.get(`history/review/attempted-questions`, {
      params: {
        filter_by: !reasonId ? 'no-error-log-reason' : undefined,
        page: page,
        section_id: sectionId,
        error_log_reason_id: reasonId ? reasonId : undefined,
      },
    })
  },
)

export const setErrorReviewBookmark = createAsyncThunk(
  'errorReview/setErrorReviewBookmark',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(`bookmark`, {
        type: 'question',
        type_id: data.questionSetAttemptId,
      })
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const deleteErrorReviewBookmark = createAsyncThunk(
  'errorReview/deleteErrorReviewBookmark',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.delete(
        `bookmark/${data.bookmarkId}`,
        {
          type_id: data.typeId,
        },
      )
      return res
    } catch (error) {
      throw rejectWithValue(error)
    }
  },
)

export const setErrorReviewRating = createAsyncThunk(
  'errorReview/setErrorReviewRating',
  async (data) => {
    return await axiosInterceptors.put(`question/save-rating/${data.id}`, {
      user_rating: data.rating,
    })
  },
)
export const getErrorReviewBySection = createAsyncThunk(
  'errorReview/getErrorReviewBySection',
  async (data) => {
    return await axiosInterceptors.get(`history/error-log/${data.id}`)
  },
)

export const setErrorReviewReason = createAsyncThunk(
  'errorReview/setErrorReviewReason',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(
        `history/error-log-reason/${data.id}`,
        {
          error_log_reason_id: data.reasonId,
        },
      )
      return res
    } catch (error) {
      throw rejectWithValue(error)
    }
  },
)
export const errorReviewSlice = createSlice({
  name: 'errorReview',
  initialState: {
    errorReviewData: { isLoading: false, isLoadingMore: false, data: [] },
    selectedQuestion: '',
    bookmarkLoading: false,
    disableRating: false,
    errorReasonLoading: false,
    errorReasonError: false,
  },
  extraReducers: {
    [setErrorReviewReason.pending]: (state, action) => {
      state.errorReasonLoading = true
      state.errorReasonError = false
    },
    [setErrorReviewReason.fulfilled]: (state, { payload, meta }) => {
      const postIndex = findIndex(state.errorReviewData.data, {
        id: meta.arg.id,
      })
      if (postIndex !== -1) {
        state.errorReviewData.data[postIndex].error_type = {
          ...state?.selectedQuestion?.error_type,
          ...meta?.arg?.reason,
        }
      }
      state.selectedQuestion = {
        ...state.selectedQuestion,
        error_type: meta?.arg?.reason,
      }
      state.errorReasonLoading = false
    },
    [setErrorReviewReason.rejected]: (state, { payload }) => {
      state.errorReasonLoading = false
      state.errorReasonError = true
    },
    [getErrorReviewData.pending]: (state, action) => {
      if (action.meta.arg.page === 1) {
        state.errorReviewData.data = []
        state.selectedQuestion = {}
        state.errorReviewData.isLoading = true
      } else {
        state.errorReviewData.isLoadingMore = true
      }
    },
    [getErrorReviewData.fulfilled]: (state, action) => {
      state.errorReviewData = {
        isLoading: false,
        data: [
          ...state.errorReviewData.data,
          ...action.payload.data.question_attempts,
        ],
        active_error_log_reason: action.payload.data?.active_error_log_reason,
        pagination: action.payload.pagination,
      }
      state.errorReviewData.isLoadingMore = false

      if (
        action.meta.arg.page === 1 &&
        action.payload.data.question_attempts.length > 0
      ) {
        state.selectedQuestion = {
          index: 0,
          ...action.payload.data.question_attempts[0],
        }
        // state.errorTypeSection =
        //   action.payload.data.question_attempts[0].error_type
        // state.selectedOption = {
        //   id: action.payload.data.question_attempts[0].error_type.id,
        //   name: action.payload.data.question_attempts[0].error_type.reason,
        // }
      }
    },
    [getErrorReviewData.rejected]: (state, action) => {
      state.errorReviewData.isLoading = false
      state.errorReviewData.isLoadingMore = false
    },
    [setErrorReviewBookmark.pending]: (state) => {
      state.bookmarkLoading = true
    },
    [setErrorReviewBookmark.fulfilled]: (state, { payload, meta }) => {
      const postIndex = findIndex(state.errorReviewData.data, {
        question_id: meta.arg.questionSetAttemptId,
      })
      if (payload.data.bookmark) {
        state.errorReviewData.data[postIndex].user_bookmark =
          payload.data.bookmark

        state.selectedQuestion.user_bookmark = payload.data.bookmark
      } else {
        state.errorReviewData.data[postIndex].user_bookmark = null
        state.selectedQuestion.user_bookmark = null
      }
      state.bookmarkLoading = false
    },

    [setErrorReviewBookmark.rejected]: (state, { payload }) => {
      state.bookmarkLoading = false
    },
    [setErrorReviewRating.pending]: (state) => {
      state.disableRating = true
    },
    [setErrorReviewRating.fulfilled]: (state, { payload, meta }) => {
      const postIndex = findIndex(state.errorReviewData.data, {
        question_id: meta.arg.id,
      })
      state.errorReviewData.data[postIndex].user_solution_rating =
        payload.data.solution_ratings
      state.selectedQuestion.user_solution_rating =
        payload.data.solution_ratings
      state.disableRating = false
    },

    [setErrorReviewRating.rejected]: (state, { payload }) => {
      state.disableRating = false
    },
    [getErrorReviewBySection.pending]: (state, action) => {},
    [getErrorReviewBySection.fulfilled]: (state, action) => {
      state.errorTypeQuestions = {
        data: action.payload.data.reasons.map((errorType) => {
          return {
            id: errorType.id,
            reason: errorType.reason,
          }
        }),
        num_unspecified: action.payload.data.num_unspecified,
      }
      if (action.meta.arg.sectionId) {
        const postIndex = findIndex(action.payload.data.reasons, {
          id: action.meta.arg.sectionId,
        })
        state.selectedOption = {
          id: action.payload.data.reasons[postIndex].id,
          reason: action.payload.data.reasons[postIndex].reason,
        }
      }
    },
    [getErrorReviewBySection.rejected]: (state, action) => {},
    [deleteErrorReviewBookmark.pending]: (state) => {
      state.bookmarkLoading = true
    },
    [deleteErrorReviewBookmark.fulfilled]: (state, { payload, meta }) => {
      const postIndex = findIndex(state.errorReviewData.data, {
        question_id: meta.arg.typeId,
      })

      state.errorReviewData.data[postIndex].user_bookmark = null
      state.selectedQuestion.user_bookmark = null

      state.bookmarkLoading = false
    },
    [deleteErrorReviewBookmark.rejected]: (state, { payload }) => {
      state.bookmarkLoading = false
    },
  },
  reducers: {
    resetErrorReasonError: (state, { payload }) => {
      state.errorReasonError = false
    },
    resetErrorTypeSection: (state, { payload }) => {
      state.errorTypeSection = {}
      state.errorTypeQuestions = null
    },
    updateSelectedQuestion: (state, { payload }) => {
      state.selectedQuestion = payload
    },
    toggleErrorReviewSidebar: (state, { payload }) => {
      state.errorReviewSidebar = payload
    },
    updateSelectedOption: (state, { payload }) => {
      state.selectedOption = payload
    },
  },
})
export const {
  resetErrorReasonError,
  updateSelectedQuestion,
  toggleErrorReviewSidebar,
  updateSelectedOption,
  resetErrorTypeSection,
} = errorReviewSlice.actions
export default errorReviewSlice.reducer
