const FailedEmoji = ({ className }) => (
  <svg
    className={`h-6 w-6 ${className}`}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12C20.25 16.5563 16.5563 20.25 12 20.25C7.44365 20.25 3.75 16.5563 3.75 12ZM12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM9 9.25C8.58579 9.25 8.25 9.58579 8.25 10C8.25 10.4142 8.58579 10.75 9 10.75H9.01C9.42421 10.75 9.76 10.4142 9.76 10C9.76 9.58579 9.42421 9.25 9.01 9.25H9ZM14.25 10C14.25 9.58579 14.5858 9.25 15 9.25H15.01C15.4242 9.25 15.76 9.58579 15.76 10C15.76 10.4142 15.4242 10.75 15.01 10.75H15C14.5858 10.75 14.25 10.4142 14.25 10ZM10.9347 15.1642C11.272 15.0225 11.6341 14.9495 12 14.9495C12.3659 14.9495 12.728 15.0225 13.0653 15.1642C13.4026 15.306 13.7082 15.5136 13.9643 15.7749C14.2542 16.0708 14.729 16.0756 15.0249 15.7857C15.3208 15.4958 15.3256 15.021 15.0357 14.7251C14.64 14.3212 14.1677 14.0004 13.6464 13.7814C13.1252 13.5623 12.5654 13.4495 12 13.4495C11.4346 13.4495 10.8748 13.5623 10.3536 13.7814C9.83232 14.0004 9.36 14.3212 8.96429 14.7251C8.6744 15.021 8.67924 15.4958 8.97511 15.7857C9.27098 16.0756 9.74583 16.0708 10.0357 15.7749C10.2918 15.5136 10.5974 15.306 10.9347 15.1642Z"
      fill="#FF9102"
    />
  </svg>
)

export default FailedEmoji
