import { useField } from 'formik'
import PropTypes from 'prop-types'

const MInput = ({
  hideLabel,
  name,
  label,
  helpText,
  focus,
  className,
  icon,
  ...props
}) => {
  const [inputProps, { error, touched }, { setValue }] = useField(name)
  return (
    <>
      <div className="mb-6">
        {!hideLabel && (
          <div className="text-sm text-gray-700 dark:text-dark-100 mb-1">
            {label}
          </div>
        )}
        <div className="relative">
          <input
            className={`px-4 py-3 border rounded-md border-gray-500 dark:border-dark-400 hover:border-gray-700 outline-none text-gray-900 dark:text-white dark:bg-dark-500 w-full ${
              error && touched && 'border-red-600 '
            } ${className}`}
            onChange={(event) => setValue(event.target.value)}
            {...inputProps}
            {...props}
          />
          <div className="absolute top-4 right-3 text-gray-900 dark:text-white">
            {icon && icon}
          </div>
        </div>
        {helpText && (
          <div className="mt-1 text-sm text-gray-700 dark:text-dark-100">
            {helpText}
          </div>
        )}
        {error && touched && (
          <div>
            <span className="text-xs font-semibold text-red-500">{error}</span>
          </div>
        )}
      </div>
    </>
  )
}

MInput.propTypes = {
  hideLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helpText: PropTypes.string,
  error: PropTypes.string,
}

export default MInput
