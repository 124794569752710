import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

export const startArithmeticAttempt = createAsyncThunk(
  'arithmetic/startArithmeticAttempt',
  async ({ data, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `trainer/arithmetic/start-attempt`,
        {},
        {
          params: {
            operator: data.configuratorAccess ? data.operator : null,
          },
        },
      )

      onSuccess && onSuccess()
      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload.errors)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getArithmeticElementaryLevel = createAsyncThunk(
  'arithmetic/getArithmeticElementaryLevel',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `trainer/arithmetic/current-level`,
      )

      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const submitArithmeticAttempt = createAsyncThunk(
  'arithmetic/submitArithmetucAttempt',
  async ({ data, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `trainer/arithmetic/${data.attemptId}/submit-attempt`,
        { attempt_questions: data.attempt_questions },
        { headers: { notToRedirect404: true } },
      )

      onSuccess && onSuccess()
      return res
    } catch (error) {
      const errorType = rejectWithValue(error.response.data)
      onError && onError(errorType.payload.errors)
      throw rejectWithValue(error.response.data)
    }
  },
)

const arithmeticSlice = createSlice({
  name: 'arithmetic',
  initialState: {
    arithmeticData: {
      isLoading: false,
      rejected: false,
      data: {},
    },
    currentQuestionIndex: 1,
    currentQuestionAnswer: '',
    currentQuestionTime: 0,
    wrongFlagCount: 0,
    showArithmeticConfigurator: false,
    startArithmeticTraining: false,
    submitArithmeticTraining: false,
    configuratorFilter: ['+', '-'],
    selectedConfiguratorFilter: [],
    recommendedSeconds: 0,
    extraSeconds: 0,
    configuratorAccess: false,
    submitAttemptLoader: false,
    elementary: {},
    arithmeticResult: null,
  },
  extraReducers: {
    [getArithmeticElementaryLevel.pending]: (state, { payload }) => {},
    [getArithmeticElementaryLevel.fulfilled]: (state, { payload }) => {
      state.configuratorAccess = payload.data.configurator_access
      state.elementary = payload.data.elementary
    },

    [getArithmeticElementaryLevel.rejected]: (state, { payload }) => {},
    [startArithmeticAttempt.pending]: (state, { payload }) => {
      state.arithmeticData.isLoading = true
    },
    [startArithmeticAttempt.fulfilled]: (state, { payload }) => {
      state.arithmeticResult = null
      state.arithmeticData = { isLoading: false, data: payload.data }
      state.startArithmeticTraining = true
      state.recommendedSeconds = payload.data.recommended_seconds
      state.ansOfArithmetic = {
        answers: payload.data.questions.map((question) => ({
          question_id: question.question_id,
          time_taken: 0,
          wrong_flag_count: 0,
          is_answered_correctly: null,
        })),
        totalTimeTaken: 0,
        correctAnswers: 0,
        totalQuestion: payload.data.questions.length,
      }
    },
    [startArithmeticAttempt.rejected]: (state, { payload }) => {
      state.arithmeticData.isLoading = false
      state.arithmeticData.rejected = true
    },
    [submitArithmeticAttempt.pending]: (state, { payload }) => {
      state.submitAttemptLoader = true
      state.arithmeticData.rejected = false
    },
    [submitArithmeticAttempt.fulfilled]: (state, { payload }) => {
      state.arithmeticResult = payload.data
      state.submitAttemptLoader = false
    },
    [submitArithmeticAttempt.rejected]: (state, { payload }) => {
      state.submitAttemptLoader = false
      state.arithmeticData.rejected = true
    },
  },
  reducers: {
    setSelectedConfiguratorFilter: (state, { payload }) => {
      state.selectedConfiguratorFilter = state.configuratorFilter
    },
    applySelectedConfiguratorFilter: (state, { payload }) => {
      state.configuratorFilter = state.selectedConfiguratorFilter
    },
    setAnsOfArithmetic: (state, { payload }) => {
      const questionIndex = findIndex(state.arithmeticData.data.questions, {
        question_id: payload.questionId,
      })
      state.ansOfArithmetic.answers[questionIndex].time_taken = payload.time
      state.ansOfArithmetic.answers[questionIndex].is_answered_correctly =
        payload.rightAns ? 1 : null
      state.ansOfArithmetic.answers[questionIndex].wrong_flag_count =
        payload.rightAns
          ? payload.wrong_flag_count - payload.rightAns.toString().length
          : payload.wrong_flag_count
      state.ansOfArithmetic.totalTimeTaken = payload.totalTimeTaken
        ? payload.totalTimeTaken
        : state.ansOfArithmetic.totalTimeTaken
      state.ansOfArithmetic.correctAnswers = payload.rightAns
        ? state.ansOfArithmetic.correctAnswers + 1
        : state.ansOfArithmetic.correctAnswers
    },
    resetCurrentQuestionTime: (state, { payload }) => {
      state.currentQuestionTime = 0
      state.wrongFlagCount = 0
      state.currentQuestionAnswer = ''
    },
    updateWrongFlagCount: (state, { payload }) => {
      state.wrongFlagCount += 1
    },
    updateCurrentQuestionTime: (state, { payload }) => {
      state.currentQuestionTime += 1
      if (state.recommendedSeconds > 0) {
        state.recommendedSeconds -= 1
      } else {
        state.extraSeconds += 1
      }
    },
    toggleArithmeticConfigurator: (state, { payload }) => {
      state.showArithmeticConfigurator = payload
    },
    setStartArithmeticTraining: (state, { payload }) => {
      state.startArithmeticTraining = payload
    },
    setSubmitArithmeticTraining: (state, { payload }) => {
      state.submitArithmeticTraining = payload
    },
    tryAgainArithmeticTraining: (state, { payload }) => {
      state.arithmeticData.rejected = false
      state.currentQuestionIndex = 1
      state.startArithmeticTraining = false
      state.submitArithmeticTraining = false
      state.recommendedSeconds = 10
      state.extraSeconds = 0
      state.configuratorFilter = ['+', '-']
      state.arithmeticResult = null
    },
    setConfiguratorFilter: (state, { payload }) => {
      state.selectedConfiguratorFilter = [
        ...state.selectedConfiguratorFilter,
        payload,
      ]
    },
    removeConfiguratorFilter: (state, { payload }) => {
      state.selectedConfiguratorFilter =
        state.selectedConfiguratorFilter.filter((data) => data !== payload)
      if (state.selectedConfiguratorFilter[0] === null) {
        state.selectedConfiguratorFilter.splice(0, 1)
      }
    },
    selectAllConfiguratorFilter: (state, { payload }) => {
      state.selectedConfiguratorFilter = [...new Set(payload.data)]
    },
    setConfiguratorFilterSidebar: (state, { payload }) => {
      state.configuratorFilter = [...state.selectedConfiguratorFilter, payload]
    },
    removeConfiguratorFilterSidebar: (state, { payload }) => {
      state.configuratorFilter = state.configuratorFilter.filter(
        (data) => data !== payload,
      )
      if (state.configuratorFilter[0] === null) {
        state.configuratorFilter.splice(0, 1)
      }
    },
    selectAllConfiguratorFilterSidebar: (state, { payload }) => {
      state.configuratorFilter = [...new Set(payload.data)]
    },
    updateCurrentQuestionIndex: (state, { payload }) => {
      state.currentQuestionIndex = payload
    },
    updateCurrentQuestionAnswer: (state, { payload }) => {
      state.currentQuestionAnswer = payload
    },
  },
})

export const {
  applySelectedConfiguratorFilter,
  setSelectedConfiguratorFilter,
  updateWrongFlagCount,
  setAnsOfArithmetic,
  resetCurrentQuestionTime,
  updateCurrentQuestionTime,
  toggleArithmeticConfigurator,
  setStartArithmeticTraining,
  setSubmitArithmeticTraining,
  tryAgainArithmeticTraining,
  setConfiguratorFilter,
  removeConfiguratorFilter,
  selectAllConfiguratorFilter,
  updateCurrentQuestionIndex,
  updateCurrentQuestionAnswer,
  selectAllConfiguratorFilterSidebar,
  removeConfiguratorFilterSidebar,
  setConfiguratorFilterSidebar,
} = arithmeticSlice.actions

export default arithmeticSlice.reducer
