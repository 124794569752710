const Undo = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 10"
    fill="current"
    // stroke="currentColor"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.3542 0.979943C4.54947 1.1752 4.54947 1.49179 4.3542 1.68705L2.54109 3.50016H8.66732C9.50717 3.50016 10.3126 3.83379 10.9065 4.42766C11.5004 5.02152 11.834 5.82698 11.834 6.66683C11.834 7.50668 11.5004 8.31214 10.9065 8.906C10.3126 9.49987 9.50717 9.8335 8.66732 9.8335H8.00065C7.72451 9.8335 7.50065 9.60964 7.50065 9.3335C7.50065 9.05735 7.72451 8.8335 8.00065 8.8335H8.66732C9.24195 8.8335 9.79305 8.60522 10.1994 8.19889C10.6057 7.79257 10.834 7.24147 10.834 6.66683C10.834 6.09219 10.6057 5.54109 10.1994 5.13476C9.79305 4.72844 9.24195 4.50016 8.66732 4.50016H2.54109L4.3542 6.31328C4.54947 6.50854 4.54947 6.82512 4.3542 7.02038C4.15894 7.21565 3.84236 7.21565 3.6471 7.02038L0.980431 4.35372C0.785169 4.15845 0.785169 3.84187 0.980431 3.64661L3.6471 0.979943C3.84236 0.784681 4.15894 0.784681 4.3542 0.979943Z"
      fill="current"
    />
  </svg>
)

export default Undo
