import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getQuestionHistoryData = createAsyncThunk(
  'weeklyQuestion/getQuestionHistoryData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `community/weekly-question/history`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const getWeeklyQuestion = createAsyncThunk(
  'weeklyQuestion/getWeeklyQuestion',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`community/weekly-question`)
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const getQuestionDescription = createAsyncThunk(
  'weeklyQuestion/getQuestionDescription',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `community/weekly-question/${data.id}`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const submitQuestion = createAsyncThunk(
  'weeklyQuestion/submitQuestion',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `/community/weekly-question/attempt`,
        data,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const addComment = createAsyncThunk(
  'weeklyQuestion/addComment',
  async ({ questionId, comment, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `/community/weekly-question/${questionId}/comment`,
        {
          comment,
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload)
      throw rejectWithValue(err.response)
    }
  },
)

export const upvoteComment = createAsyncThunk(
  'weeklyQuestion/upvoteComment',
  async ({ commentId, state }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(
        `/community/weekly-question/comment/${commentId}/set-upvote-state`,
        {
          state,
        },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const markDiscussionAgreement = createAsyncThunk(
  'weeklyQuestion/markDiscussionAgreement',

  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        '/terms-conditions/accept?area=discussions',
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
const weeklyQuestionSlice = createSlice({
  name: 'weeklyQuestion',
  initialState: {
    upvoteCommentData: { isLoading: false, data: [], loadingCommentId: null },
    weeklyQuestionCommentTermsData: { isLoading: false, data: null },
    questionHistoryData: {
      isLoading: false,
      isLocked: false,
      data: [],
      loadingAfterSubmit: false,
      addCommentLoading: false,
      commentText: '',
      isOpenAddComment: false,
      addedCommentLoader: false,
    },
    questionDescription: {
      isLoadingComment: false,
      isLoading: false,
      data: [],
      isLocked: false,
    },
    weeklyQuestion: {
      isLoading: false,
      data: [],
      isLocked: false,
      isLoadingComment: false,
    },

    isOpen: false,
  },
  extraReducers: {
    [upvoteComment.pending]: (state, action) => {
      state.upvoteCommentData.isLoading = true
      state.upvoteCommentData.loadingCommentId = action.meta.arg.commentId
    },
    [upvoteComment.fulfilled]: (state, action) => {
      state.upvoteCommentData.isLoading = false
      state.upvoteCommentData.loadingCommentId = null
      if (action.meta.arg.type === 'current') {
        const updateDataIndex =
          state.weeklyQuestion.data.current_question.comments.findIndex(
            (data) => data.id === action.meta.arg.commentId,
          )
        if (action.payload.data.state) {
          state.weeklyQuestion.data.current_question.comments[
            updateDataIndex
          ].num_upvotes += 1
        } else {
          state.weeklyQuestion.data.current_question.comments[
            updateDataIndex
          ].num_upvotes -= 1
        }
        state.weeklyQuestion.data.current_question.comments[
          updateDataIndex
        ].upvoted_by_user = action.payload.data.state
      } else if (action.meta.arg.type === 'history') {
        const updateHistoryDataIndex =
          state.questionDescription?.data?.question?.comments.findIndex(
            (data) => data.id === action.meta.arg.commentId,
          )
        if (action.payload.data.state) {
          state.questionDescription.data.question.comments[
            updateHistoryDataIndex
          ].num_upvotes += 1
        } else {
          state.questionDescription.data.question.comments[
            updateHistoryDataIndex
          ].num_upvotes -= 1
        }
        state.questionDescription.data.question.comments[
          updateHistoryDataIndex
        ].upvoted_by_user = action.payload.data.state
      }
    },
    [upvoteComment.rejected]: (state) => {
      state.upvoteCommentData.isLoading = false
      state.upvoteCommentData.loadingCommentId = null
    },
    [addComment.pending]: (state) => {
      state.addCommentLoading = true
    },
    [addComment.fulfilled]: (state) => {
      state.addCommentLoading = false
      state.isOpenAddComment = false
    },
    [addComment.rejected]: (state, { payload }) => {
      state.addCommentLoading = false
      if (payload?.status === 403) {
        state.weeklyQuestionCommentTermsData.data = payload.data.data
      }
    },
    [markDiscussionAgreement.pending]: (state, action) => {
      state.weeklyQuestionCommentTermsData.isLoading = true
    },
    [markDiscussionAgreement.fulfilled]: (state, action) => {
      state.weeklyQuestionCommentTermsData.isLoading = false
      state.weeklyQuestionCommentTermsData.data.discussion_terms_agreed = true
    },
    [markDiscussionAgreement.rejected]: (state, { payload }) => {
      state.weeklyQuestionCommentTermsData.isLoading = false
    },
    [getQuestionHistoryData.pending]: (state, { meta }) => {
      state.questionHistoryData.isLoading = true
    },
    [getQuestionHistoryData.fulfilled]: (state, action) => {
      state.questionHistoryData = {
        isLoading: false,
        data: action.payload.data,
        isLocked: false,
      }
    },
    [getQuestionHistoryData.rejected]: (state, { payload }) => {
      state.questionHistoryData.isLoading = false
      if (payload?.status === 403) {
        state.questionHistoryData.isLocked = true
      }
    },
    [getQuestionDescription.pending]: (state, { meta }) => {
      if (meta.arg.addedCommentLoader) {
        state.questionDescription.isLoadingComment = true
      } else {
        state.questionDescription.isLoading = true
      }
    },
    [getQuestionDescription.fulfilled]: (state, action) => {
      state.questionDescription = {
        isLoading: false,
        data: action.payload.data,
        isLocked: false,
        isLoadingComment: false,
      }
    },
    [getQuestionDescription.rejected]: (state, { payload }) => {
      state.questionDescription.isLoading = false
      state.questionDescription.isLoadingComment = false
      if (payload?.status === 403) {
        state.questionDescription.isLocked = true
      }
    },
    [getWeeklyQuestion.pending]: (state, { meta }) => {
      if (meta.arg.addedCommentLoader) {
        state.weeklyQuestion.isLoadingComment = true
      } else {
        state.weeklyQuestion.isLoading = true
      }
    },
    [getWeeklyQuestion.fulfilled]: (state, action) => {
      state.weeklyQuestion = {
        isLoading: false,
        data: action.payload.data,
        isLocked: false,
        isLoadingComment: false,
      }
    },
    [getWeeklyQuestion.rejected]: (state, { payload }) => {
      state.weeklyQuestion.isLoading = false
      state.weeklyQuestion.isLoadingComment = false
      if (payload?.status === 403) {
        state.weeklyQuestion.isLocked = true
      }
    },
    [submitQuestion.pending]: (state, action) => {
      state.weeklyQuestion.loadingAfterSubmit = true
    },
    [submitQuestion.fulfilled]: (state, { payload }) => {
      state.weeklyQuestion = {
        loadingAfterSubmit: false,
        data: {
          current_question: payload.data.weekly_question,
        },
      }
    },
    [submitQuestion.rejected]: (state, action) => {
      state.weeklyQuestion.loadingAfterSubmit = false
    },
  },
  reducers: {
    setIsOpenAddComment: (state, { payload }) => {
      state.isOpenAddComment = payload
    },
  },
})

export const { setIsOpenAddComment } = weeklyQuestionSlice.actions

export default weeklyQuestionSlice.reducer
