const Plus = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.50016 1.33337C6.50016 1.05723 6.27631 0.833374 6.00016 0.833374C5.72402 0.833374 5.50016 1.05723 5.50016 1.33337V5.50004H1.3335C1.05735 5.50004 0.833496 5.7239 0.833496 6.00004C0.833496 6.27618 1.05735 6.50004 1.3335 6.50004H5.50016V10.6667C5.50016 10.9429 5.72402 11.1667 6.00016 11.1667C6.27631 11.1667 6.50016 10.9429 6.50016 10.6667V6.50004H10.6668C10.943 6.50004 11.1668 6.27618 11.1668 6.00004C11.1668 5.7239 10.943 5.50004 10.6668 5.50004H6.50016V1.33337Z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default Plus
