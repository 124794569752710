import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'
export const getExtraExperiencesCategories = createAsyncThunk(
  'extraExperiences/getExtraExperiencesCategories',
  async (data) => {
    return await axiosInterceptors.get(`extra-experience/get-categories`)
  },
)

export const createExtraExperiences = createAsyncThunk(
  'extraExperiences/createExtraExperiences',
  async ({ data, onSuccess, onError }, { rejectWithValue }) => {
    const formData = new FormData()

    data.extra_experience.forEach((value, index) => {
      formData.append(
        `extra_experience[${index}][category_id]`,
        parseInt(value.category_id.id, 10), // Convert category_id to an integer using parseInt
      )
      formData.append(
        `extra_experience[${index}][description]`,
        value.description,
      )
      formData.append(`extra_experience[${index}][title]`, value.title)
      value.image.length > 0 &&
        formData.append(`extra_experience[${index}][image]`, value.image[0])
    })

    try {
      const res = await axiosInterceptors.post(
        `extra-experience/store`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      onSuccess && onSuccess()
      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload.errors)
    }
  },
)

export const editExtraExperiences = createAsyncThunk(
  'extraExperiences/editExtraExperiences',
  async ({ data, id, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const formData = new FormData()
      formData.append('description', data.description)
      formData.append('category_id', data.category_id)
      formData.append('title', data.title)
      data.remove_image &&
        formData.append('remove_image', data.remove_image ? 1 : 0)
      data.image?.length > 0 && formData.append('image', data.image[0])

      const res = await axiosInterceptors.post(
        `extra-experience/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      onSuccess && onSuccess()
      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload.errors)
    }
  },
)

export const deleteExtraExperiences = createAsyncThunk(
  'extraExperiences/deleteExtraExperiences',
  async ({ id, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.delete(`extra-experience/${id}`)

      onSuccess && onSuccess()
      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload.errors)
    }
  },
)
export const getExtraExperiences = createAsyncThunk(
  'extraExperiences/getExtraExperiences',
  async (data) => {
    return await axiosInterceptors.get(`extra-experience`)
  },
)

export const getExtraExperiencesPDF = createAsyncThunk(
  'extraExperiences/getExtraExperiencesPDF',
  async (data) => {
    return await axiosInterceptors
      .get(`extra-experience/pdf`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        try {
          var newBlob = new Blob([response], {
            type: 'application/octet-stream',
          })

          data = window.URL.createObjectURL(newBlob)
          const link = document.createElement('a')
          link.href = data
          link.setAttribute('download', 'Extra-Experience.pdf')
          document.body.appendChild(link)
          link.click()
        } catch (error) {}
      })
  },
)
export const extraExperiencesSlice = createSlice({
  name: 'extraExperiences',
  initialState: {
    extraExperiencesData: { isLoading: true, data: [] },
    categories: [],
    numberOfWorkExperience: 1,
    isLoading: false,
    editExperience: null,
    experienceLoader: false,
    deleteExperienceModel: { isLoading: false, openModal: false, id: null },
    extraExperienceImage: [],
  },
  extraReducers: {
    [deleteExtraExperiences.pending]: (state, action) => {
      state.deleteExperienceModel.isLoading = true
    },
    [deleteExtraExperiences.fulfilled]: (state, { payload }) => {
      state.deleteExperienceModel.isLoading = false
    },
    [deleteExtraExperiences.rejected]: (state, action) => {
      state.deleteExperienceModel.isLoading = false
    },
    [getExtraExperiencesCategories.pending]: (state, action) => {},
    [getExtraExperiencesCategories.fulfilled]: (state, { payload }) => {
      state.categories = payload.data
    },
    [getExtraExperiencesCategories.rejected]: (state, action) => {},
    [getExtraExperiences.pending]: (state, action) => {
      state.extraExperiencesData.isLoading = true
    },
    [getExtraExperiences.fulfilled]: (state, { payload }) => {
      const data = [
        ...new Set(
          payload.data.extra_experiences.map((item) => item.category_title),
        ),
      ]
      state.extraExperiencesData = {
        isLoading: false,
        data: data.map((data) => ({
          category: data,
          subData: payload.data.extra_experiences.filter(
            (subData) => subData.category_title === data,
          ),
        })),
      }
    },
    [getExtraExperiences.rejected]: (state, action) => {
      state.extraExperiencesData.isLoading = false
    },
    [getExtraExperiencesPDF.pending]: (state, action) => {
      state.pdfLoader = true
    },
    [getExtraExperiencesPDF.fulfilled]: (state, { payload }) => {
      state.pdfLoader = false
    },
    [getExtraExperiencesPDF.rejected]: (state, action) => {
      state.pdfLoader = false
    },
    [createExtraExperiences.pending]: (state, action) => {
      state.experienceLoader = true
    },
    [createExtraExperiences.fulfilled]: (state, { payload }) => {
      state.experienceLoader = false
    },
    [createExtraExperiences.rejected]: (state, action) => {
      state.experienceLoader = false
    },
    [editExtraExperiences.pending]: (state, action) => {
      state.experienceLoader = true
    },
    [editExtraExperiences.fulfilled]: (state, { payload }) => {
      state.experienceLoader = false
    },
    [editExtraExperiences.rejected]: (state, action) => {
      state.experienceLoader = false
    },
  },
  reducers: {
    clearExperienceData: (state) => {
      state.extraExperienceImage = []
    },
    setExtraExperienceImage: (state, { payload }) => {
      state.extraExperienceImage = payload
    },
    updateWorkExperience: (state, { payload }) => {
      state.numberOfWorkExperience += 1
    },
    setEditExperience: (state, { payload }) => {
      state.editExperience = payload
    },
    setShowEdit: (state, { payload }) => {
      state.showEdit = payload
    },
    setDeleteExperienceModel: (state, { payload }) => {
      state.deleteExperienceModel.openModal = payload.openModal
      state.deleteExperienceModel.id = payload.id
    },
  },
})

export const {
  clearExperienceData,
  setExtraExperienceImage,
  setShowEdit,
  updateWorkExperience,
  setEditExperience,
  setDeleteExperienceModel,
} = extraExperiencesSlice.actions

export default extraExperiencesSlice.reducer
