import React, { useEffect, useState } from 'react'
import { Close } from './icons'
import { isMobile, isIOS } from 'react-device-detect'

const DownloadApp = () => {
  const [show, setShow] = useState(false)

  const mobileView = window.matchMedia('(max-width: 639px)')
  const [matches, setMatches] = useState(mobileView.matches)
  useEffect(() => {
    const handler = (e) => setMatches(e.matches)
    mobileView.addListener(handler)
    return () => mobileView.removeListener(handler)
  })

  useEffect(() => {
    if (isMobile && matches) {
      const lastInteraction = localStorage.getItem('downloadAppLastInteraction')
      const currentTime = new Date().getTime()
      const twentyFourHours = 24 * 60 * 60 * 1000 // 24 hours in milliseconds

      if (!lastInteraction) {
        setShow(true)
      } else {
        const timeDifference = currentTime - JSON.parse(lastInteraction)
        setShow(timeDifference > twentyFourHours)
      }
    } else {
      setShow(false)
    }
  }, [matches])

  const handleCloseDownload = () => {
    setShow(false)
    localStorage.setItem(
      'downloadAppLastInteraction',
      new Date().getTime().toString(),
    )
  }

  if (!isMobile && matches) {
    return <></>
  }

  return show ? (
    <div
      className="sticky flex items-center h-16 z-30 top-14 dark:bg-dark-600 bg-gray-50 px-2 py-2 dark:text-white border-t border-b dark:border-dark-400
      transition duration-100 ease-out "
    >
      <div
        className={`w-full flex items-center ${
          !matches ? 'justify-end gap-4' : 'justify-between'
        } `}
      >
        <div className="text-sm">
          {isIOS ? (
            <>
              <div className="font-semibold">
                MedEntry is better on the iOS App.
              </div>
              <div>Get it from the AppStore now.</div>
            </>
          ) : (
            <>
              <div className="font-semibold">
                MedEntry is better on the Android App.
              </div>
              <div className="text-gray-600 dark:text-dark-200">
                Get it from Google Play now.
              </div>
            </>
          )}
        </div>
        <div className="flex items-center gap-2">
          <div
            onClick={handleCloseDownload}
            className="text-white bg-red-500 py-1 px-2 rounded text-xs font-semibold"
          >
            <a
              href={
                isIOS
                  ? 'https://apps.apple.com/in/app/medentry/id1439388560'
                  : 'https://play.google.com/store/apps/details?id=com.medentry.app'
              }
              target="_blank"
              rel="noreferrer"
            >
              Install
            </a>
          </div>
          <div className="flex items-start" onClick={handleCloseDownload}>
            <button>
              <Close />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default DownloadApp
