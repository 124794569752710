import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getOrientationVideos = createAsyncThunk(
  'walkthrough/getTutorData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await await axiosInterceptors.get(`orientation/videos`)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const saveVideoViewed = createAsyncThunk(
  'walkthrough/saveVideoViewed',
  async ({ videoId }, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptors.post(
        `orientation/videos/${videoId}/save-view`,
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const walkthroughSlice = createSlice({
  name: 'walkthrough',
  initialState: {
    orientationVideosData: { isLoading: true, data: null },
    orientationVideosError: null,
    isRequestTutor: false,
    requestLoader: false,
    selectedVideoId: null,
    videosLoaded: [],
  },
  extraReducers: {
    [getOrientationVideos.pending]: (state, { payload }) => {
      state.orientationVideosData.isLoading = true
      state.orientationVideosData.data = null
      state.orientationVideosError = null
    },
    [getOrientationVideos.fulfilled]: (state, { payload }) => {
      state.orientationVideosData.isLoading = false
      state.orientationVideosData.data = payload?.data
      state.selectedVideoId = null
      state.videosLoaded = Array(payload?.data?.videos?.length).fill(false)
    },
    [getOrientationVideos.rejected]: (state, { payload }) => {
      state.orientationVideosError = payload
      state.orientationVideosData.isLoading = false
    },
    [saveVideoViewed.pending]: (state, { payload }) => {},
    [saveVideoViewed.fulfilled]: (state, { payload, meta }) => {
      if (!meta?.arg?.isTourVideo) {
        const videoIndex = state.orientationVideosData?.data?.videos?.findIndex(
          (video) => video?.id === payload?.data?.video_id,
        )
        if (videoIndex !== -1) {
          state.orientationVideosData.data.videos[
            videoIndex
          ].user_view_count += 1
        }
      }
    },
    [saveVideoViewed.rejected]: (state, { payload }) => {},
  },
  reducers: {
    setSelectedVideoId: (state, { payload }) => {
      state.selectedVideoId = payload
    },
    setVideosLoaded: (state, { payload }) => {
      state.videosLoaded = payload
    },
  },
})

export const { setSelectedVideoId, setVideosLoaded } = walkthroughSlice.actions

export default walkthroughSlice.reducer
