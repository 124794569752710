import axios from 'axios'
import toast from 'react-hot-toast'

let isToastShowing = false
const TOAST_COOLDOWN = 3000

const showToastWithDebounce = () => {
  if (!isToastShowing) {
    isToastShowing = true
    toast.error(
      'Your progress could not be saved. Please check your internet connection',
      {
        duration: 4000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '350px',
        },
      },
    )

    // Reset the flag after cooldown period
    setTimeout(() => {
      isToastShowing = false
    }, TOAST_COOLDOWN + 500)
  }
}
const axiosInterceptors = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 35000,
  headers: {
    Accept: 'application/json',
    Authorization: 'Bearer',
  },
})

axiosInterceptors.interceptors.request.use(function (config) {
  axios.defaults.timeout = 35000
  const token = localStorage.getItem('bearer_token')
  config.headers.Authorization = 'Bearer '.concat(token)

  return config
})

axiosInterceptors.interceptors.response.use(
  function onSuccess(response) {
    if (response?.data?.celebration) {
      localStorage.setItem(
        'celebration',
        JSON.stringify(response?.data?.celebration),
      )
    }
    return response.data
  },
  function onError(error) {
    const profileApiUrl = error?.response?.config?.url?.split('/api')
    if (
      error?.config?.method !== 'get' &&
      (!navigator.onLine || !error.response) &&
      error?.message !== 'canceled'
    ) {
      showToastWithDebounce()
    }
    if (error && error.response && error.response.status) {
      if (
        error.response.status === 401 &&
        profileApiUrl.length > 0 &&
        profileApiUrl[0] !==
          process.env.REACT_APP_PROFILE_API_URL.split('/api')[0]
      ) {
        localStorage.removeItem('bearer_token')
        localStorage.removeItem('expires_at')
        return (window.location.href = '/login')
      } else if (error.response.status === 404) {
        const notToRedirect404 =
          error.response.config?.headers?.notToRedirect404
        if (
          // error.response.config.method !== 'post' &&
          // error.response.config.method !== 'put' &&
          error.response.config.url.search(/mark-viewed/) === -1 &&
          error.response.config.url.search(/mark-actioned/) === -1 &&
          error.response.config.url.search(/tour/) === -1 &&
          error.response.config.url.search(/class-recordings/) === -1 &&
          error.response.config.url.search(/exam-report-card/) === -1 &&
          !notToRedirect404 &&
          !window.location.href?.includes('/404')
        ) {
          return (window.location.href = '/404')
        }
      } else if (error.response.status === 500) {
      }
    }

    return Promise.reject(error)
  },
)

export { axiosInterceptors }
