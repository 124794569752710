const CalendarAdd = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 14 14"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0.5C9.27614 0.5 9.5 0.723858 9.5 1V1.83333H10.3333C10.8196 1.83333 11.2859 2.02649 11.6297 2.3703C11.9735 2.71412 12.1667 3.18044 12.1667 3.66667V6.33333C12.1667 6.60948 11.9428 6.83333 11.6667 6.83333H1.5V11.6667C1.5 11.8877 1.5878 12.0996 1.74408 12.2559C1.90036 12.4122 2.11232 12.5 2.33333 12.5H6.86333C7.13948 12.5 7.36333 12.7239 7.36333 13C7.36333 13.2761 7.13948 13.5 6.86333 13.5H2.33333C1.8471 13.5 1.38079 13.3068 1.03697 12.963C0.693154 12.6192 0.5 12.1529 0.5 11.6667V3.66667C0.5 3.18044 0.693154 2.71412 1.03697 2.3703C1.38079 2.02649 1.8471 1.83333 2.33333 1.83333H3.16667V1C3.16667 0.723858 3.39052 0.5 3.66667 0.5C3.94281 0.5 4.16667 0.723858 4.16667 1V1.83333H8.5V1C8.5 0.723858 8.72386 0.5 9 0.5ZM11.1667 3.66667V5.83333H1.5V3.66667C1.5 3.44565 1.5878 3.23369 1.74408 3.07741C1.90036 2.92113 2.11232 2.83333 2.33333 2.83333H3.16667V3.66667C3.16667 3.94281 3.39052 4.16667 3.66667 4.16667C3.94281 4.16667 4.16667 3.94281 4.16667 3.66667V2.83333H8.5V3.66667C8.5 3.94281 8.72386 4.16667 9 4.16667C9.27614 4.16667 9.5 3.94281 9.5 3.66667V2.83333H10.3333C10.5543 2.83333 10.7663 2.92113 10.9226 3.07741C11.0789 3.23369 11.1667 3.44565 11.1667 3.66667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3333 8.83333C11.3333 8.55719 11.1095 8.33333 10.8333 8.33333C10.5572 8.33333 10.3333 8.55719 10.3333 8.83333V10.3333H8.83333C8.55719 10.3333 8.33333 10.5572 8.33333 10.8333C8.33333 11.1095 8.55719 11.3333 8.83333 11.3333H10.3333V12.8333C10.3333 13.1095 10.5572 13.3333 10.8333 13.3333C11.1095 13.3333 11.3333 13.1095 11.3333 12.8333V11.3333H12.8333C13.1095 11.3333 13.3333 11.1095 13.3333 10.8333C13.3333 10.5572 13.1095 10.3333 12.8333 10.3333H11.3333V8.83333Z"
      fill="currentColor"
    />
  </svg>
)
export default CalendarAdd
