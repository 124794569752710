import { Arrowleft, Loading, ThumbUp } from 'components/icons'
import { MInput, MTextarea } from 'components/form'
import { Button } from 'components/shared'
import { useDispatch, useSelector } from 'react-redux'
import {
  createResourceQuestion,
  markResourceAgreement,
  closeAgreementModal,
} from 'redux/learn/resourceQuestionsSlice'
import toast from 'react-hot-toast'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { CommunityTermsModal } from 'components/dashboard'
import {
  clearSuccessMessage,
  getMyTutorResource,
  submitTutorDiscussion,
} from 'redux/tutor/tutorSlice'
import HTMLRenderer from 'components/shared/helper/HTMLRenderer'
import { useEffect } from 'react'
const resourceQuestionSchema = Yup.object({
  question: Yup.string()
    .required('Description is required')
    .max(
      2000,
      `The subject must be less than 2000 character.
    `,
    ),
})

const TutorAskQuestion = ({ setOpenAskQuestion, tutorType, tutorId }) => {
  const dispatch = useDispatch()
  const { resourceAgreementData, isSendLoading, bookDetails, successMessage } =
    useSelector((state) => ({
      resourceAgreementData: state.resourceQuestions.resourceAgreementData,
      isSendLoading: state.tutor.isSendLoading,
      bookDetails: state.resource.bookDetails,
      successMessage: state.tutor.successMessage,
    }))
  const tutoringDiscussionAccessCheck = bookDetails?.discussable_with_tutor

  useEffect(() => {
    return () => {
      dispatch(clearSuccessMessage())
    }
  }, [dispatch])

  return (
    <div>
      <CommunityTermsModal
        open={resourceAgreementData?.data?.discussion_terms_agreed === false}
        agreement={resourceAgreementData?.data}
        isLoading={resourceAgreementData?.isLoading}
        onSubmit={() => dispatch(markResourceAgreement())}
        onClose={() => dispatch(closeAgreementModal())}
      />
      <button
        className="flex mt-7"
        onClick={() => {
          setOpenAskQuestion(false)
          dispatch(clearSuccessMessage())
        }}
      >
        <div className="mr-4 mt-0.5">
          <Arrowleft className="fill-current dark:text-white" />
        </div>
        <div>
          <p className="text-xs text-gray-900 dark:text-white font-semibold">
            BACK TO ALL QUESTIONS
          </p>
        </div>
      </button>
      <Formik
        initialValues={{
          question: '',
        }}
        validationSchema={resourceQuestionSchema}
        onSubmit={(values, { resetForm, setFieldError }) => {
          dispatch(
            submitTutorDiscussion({
              resource_type:
                tutoringDiscussionAccessCheck?.discussion_resource_type_short ||
                tutorType,
              resource_id: tutorId,
              question: values.question,
              onSuccess: () => {
                resetForm()
                // setOpenAskQuestion(false)
                dispatch(getMyTutorResource({ type: tutorType, id: tutorId }))
              },
              onError: (errorsMassage) => {
                toast.error(errorsMassage.message, {
                  duration: 3000,
                  position: 'top-center',
                  style: {
                    backdropFilter: 'blur(4px)',
                    marginTop: '20px',
                    width: '350px',
                  },
                })
                Object.keys(errorsMassage.errors).forEach((error) =>
                  setFieldError(
                    error,
                    errorsMassage.errors[error][0] || errorsMassage.message,
                  ),
                )
              },
            }),
          )
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div>
              <p className="text-gray-900 dark:text-white text-2xl font-semibold mt-6">
                {tutoringDiscussionAccessCheck?.discussion_request_title}
              </p>
              {successMessage ? (
                <div className="flex flex-col justify-center text-gray-900 dark:text-white items-center mt-4">
                  <div className="h-12 w-12 rounded-full bg-blue-200 flex justify-center items-center">
                    <ThumbUp className="w-8 h-8 text-blue-500" />
                  </div>
                  <div className="mt-4">
                    <HTMLRenderer html={successMessage} />
                  </div>
                </div>
              ) : (
                <div className="mt-4">
                  <MTextarea
                    label={
                      tutoringDiscussionAccessCheck?.discussion_request_instructions
                    }
                    name="question"
                    type="text"
                    rows="4"
                    cols="40"
                    className="mt-1 w-full  border rounded-md px-3 py-4 border-gray-500 dark:border-dark-400 dark:bg-dark-500 dark:text-white"
                    //   placeholder="e.g. Example goes here"
                  ></MTextarea>
                  <div className="my-6">
                    <Button
                      type="submit"
                      className={`py-4 px-6 uppercase font-semibold tracking-widest ${
                        isSendLoading && 'pointer-events-none opacity-25'
                      }`}
                    >
                      {isSendLoading ? (
                        <Loading className="text-white" />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </div>{' '}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default TutorAskQuestion
