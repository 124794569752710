import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getBadgesData = createAsyncThunk(
  'badges/getBadgesData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`account/badges`)
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
const badgesSlice = createSlice({
  name: 'badges',
  initialState: {
    getBadges: { isLoading: false, data: [], isLocked: false },
    profileBadges: [],
  },
  extraReducers: {
    [getBadgesData.pending]: (state, action) => {
      state.getBadges.isLoading = true
    },
    [getBadgesData.fulfilled]: (state, { payload }) => {
      state.getBadges = {
        isLoading: false,
        data: payload.data,
        isLocked: false,
      }
      const { Community, Engagement } = payload.data.badges
      state.profileBadges =
        payload.data.badges &&
        payload.data.badges['Profile'] &&
        payload.data.badges['Profile'].sort((a, b) => {
          return a.level - b.level
        })

      state.communityBadges =
        Community &&
        Community.sort((a, b) => {
          return a.level - b.level
        })
      state.engagementBadges =
        Engagement &&
        Engagement.sort((a, b) => {
          return a.level - b.level
        })
      state.speedReadingBadges = payload.data.badges['Speed Reading Trainer']
      state.arithmeticBadges = payload.data.badges['Arithmetic Trainer']
      state.graphTableBadges = payload.data.badges['Graph Table Trainer']
      state.keywordScanningBadges =
        payload.data.badges['Keyword Scanning Trainer']
      state.numericKeypadBadges = payload.data.badges['Numeric Keypad Trainer']
      state.syllogismsBadges = payload.data.badges['Syllogisms Trainer']
      state.vennDiagramBadges = payload.data.badges['Venn Diagram Trainer']
      state.eurekaBadges = payload.data.badges['Eureka Trainer']
      state.wordGameBadges = payload.data.badges['Word Game Trainer']
    },
    [getBadgesData.rejected]: (state, { payload }) => {
      state.getBadges.isLoading = false
      if (payload && payload.status === 403) {
        state.getBadges.isLocked = true
      }
    },
  },
  reducers: {},
})

export default badgesSlice.reducer
