import { Fragment } from 'react'
// import PropTypes from 'prop-types'
import { Listbox, Transition } from '@headlessui/react'
import { Drop, Shape } from 'components/icons'
import { useField } from 'formik'

const HeadlessSelect = ({
  hideLabel,
  name,
  label,
  helpText,
  selectedValue,
  setSelectedValue,
  options,
  heightOfDropdown,
  selectedField,
  textPostion,
  disabled = false,
  ...props
}) => {
  const [inputProps, { error, touched }, { setValue }] = useField(name)
  return (
    <div className="mb-4">
      {!hideLabel && (
        <div className="text-sm text-gray-700 dark:text-dark-100 mb-1">
          {label}
        </div>
      )}
      <Listbox
        {...inputProps}
        disabled={disabled}
        value={selectedValue}
        onChange={(e) => {
          setSelectedValue && setSelectedValue(e)
          setValue(e)
        }}
      >
        {({ open }) => (
          <div className="relative mt-1">
            <Listbox.Button
              className={`px-4 py-3 w-full rounded-md border ${
                open ? 'border-red-500' : 'border-gray-500 dark:border-dark-400'
              } ${disabled ? 'pointer-events-none opacity-25' : ''}`}
            >
              <div className="flex items-center justify-between dark:text-white">
                <span className={`${textPostion && textPostion}`}>
                  {selectedValue?.name}
                </span>
                <Drop className={open ? 'transform rotate-180' : ''} />
              </div>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`bg-white  w-full  dark:bg-dark-700 z-20 absolute py-1 mt-1 overflow-auto border border-gray-100 dark:border-dark-400 shadow-sm rounded-md ${heightOfDropdown}`}
              >
                {options.map((data, id) => (
                  <Listbox.Option
                    key={id}
                    value={data}
                    disabled={data?.unavailable}
                    className={
                      selectedField
                        ? data[selectedField] === selectedValue[selectedField]
                          ? 'cursor-pointer text-gray-900 dark:text-white bg-gray-100 dark:bg-dark-500 w-full pr-4 pl-6 py-2'
                          : 'cursor-pointer text-gray-700 dark:text-dark-100 w-full pr-4 pl-6 py-2'
                        : data?.name === selectedValue?.name
                        ? 'cursor-pointer text-gray-900 dark:text-white bg-gray-100 dark:bg-dark-500 w-full pr-4 pl-6 py-2'
                        : 'cursor-pointer text-gray-700 dark:text-dark-100 w-full pr-4 pl-6 py-2'
                    }
                  >
                    <div className="flex items-center justify-between  ">
                      {' '}
                      <p>{data?.name}</p>
                      {selectedField ? (
                        data[selectedField] ===
                          selectedValue[selectedField] && (
                          <Shape className="fill-current text-red-500 dark:text-red-500" />
                        )
                      ) : data?.name === selectedValue?.name ? (
                        <Shape className="fill-current text-red-500 dark:text-red-500" />
                      ) : (
                        ''
                      )}
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
      {helpText && (
        <div className="mt-1 text-sm text-gray-700 dark:text-dark-100">
          {helpText}
        </div>
      )}

      {error && touched && (
        <span className="text-xs font-semibold text-red-500">{error}</span>
      )}
    </div>
  )
}

export default HeadlessSelect
