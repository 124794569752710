import { Close, Loading } from 'components/icons'
import { Transition, Dialog } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/shared'
const DeletePopUp = ({
  deleteMessage,
  deleteNotePopup,
  deleteNote,
  closeModel,
  deleteNoteLoader,
}) => {
  return (
    <Transition appear show={deleteNotePopup.show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto bg-gray-700 bg-opacity-80 z-50"
        onClose={() => {}}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen " aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              style={{ maxWidth: '628px' }}
              className="inline-block w-full max-w-full align-top mt-16 transition-all transform bg-white dark:bg-dark-600 shadow-xl "
            >
              <div className="float-right mt-4 mr-4">
                <button onClick={() => closeModel()}>
                  <Close className="fill-current dark:text-white" />
                </button>
              </div>
              <div className="">
                <div className=" px-16 py-14 text-left ">
                  <p className="text-2xl font-semibold w-full dark:text-white">
                    {deleteMessage
                      ? deleteMessage
                      : 'Do you really want delete this note?'}
                  </p>

                  <div className=" flex mt-10">
                    <Button
                      className={`py-2 px-6 inline-flex justify-center mr-4 sm:px-6 sm:py-4 tracking-widest uppercase font-semibold ${
                        deleteNoteLoader && 'pointer-events-none'
                      }`}
                      onClick={() => deleteNote()}
                    >
                      {deleteNoteLoader ? <Loading /> : 'Yes, Delete'}
                    </Button>
                    <Button
                      className="py-2 px-6 inline-flex justify-center sm:px-6 sm:py-4 tracking-widest font-semibold"
                      onClick={() => closeModel()}
                      outlined
                    >
                      CANCEL
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
export default DeletePopUp
