const Recent = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.94 2.442A5.5 5.5 0 113.134 10.5h2.233a.5.5 0 000-1H2.378a.5.5 0 00-.023 0h-.322a.5.5 0 00-.5.5v3.333a.5.5 0 101 0v-1.879a6.5 6.5 0 10-.997-4.171.5.5 0 00.995.101A5.5 5.5 0 017.94 2.442zm.56 2.891a.5.5 0 00-1 0V8a.5.5 0 00.146.354L8.98 9.687a.5.5 0 00.707-.707L8.5 7.793v-2.46z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Recent
