import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { axiosInterceptors } from 'utils/router/api'

export const getErrorLogData = createAsyncThunk(
  'errorLog/getErrorLogData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`history/error-log-all-reasons`)
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)
export const getErrorLogErrorsOverTime = createAsyncThunk(
  'errorLog/getErrorLogErrorsOverTime',
  async ({ data, abortController }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `history/error-log-errors-over-time`,
        { signal: abortController?.signal, params: data },
      )
      return res
    } catch (error) {
      throw rejectWithValue(error)
    }
  },
)

export const getErrorLogBySection = createAsyncThunk(
  'errorLog/getErrorBySection',
  async (
    { id, filter_by, start_date, end_date, abortController },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.get(`history/error-log`, {
        signal: abortController?.signal,
        params: {
          section_id: id,
          filter_by,
          start_date,
          end_date,
        },
      })
      return res
    } catch (error) {
      throw rejectWithValue(error)
    }
  },
)

export const createErrorLogReason = createAsyncThunk(
  'errorLog/createErrorLogReason',
  async ({ sectionId, reason, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `history/error-log-reason/${sectionId}`,
        { reason },
      )
      onSuccess && onSuccess(res)
      return res
    } catch (error) {
      throw rejectWithValue(error.response?.data)
    }
  },
)

export const updateErrorLogReason = createAsyncThunk(
  'errorLog/updateErrorLogReason',
  async ({ errorLogId, reason, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(
        `history/error-log-reason/update/${errorLogId}`,
        { reason },
      )
      onSuccess && onSuccess()
      return res
    } catch (error) {
      throw rejectWithValue(error.response?.data)
    }
  },
)

export const deleteReason = createAsyncThunk(
  'learn/deleteReason',
  async ({ deleteId, onSuccess }) => {
    try {
      const res = await axiosInterceptors.delete(
        `history/error-log-reason/${deleteId}`,
      )
      onSuccess && onSuccess()
      return res
    } catch (error) {}
  },
)

export const errorLogSlice = createSlice({
  name: 'errorLog',
  initialState: {
    errorLogData: { isLoading: false, data: [], isLocked: false },
    errorLogBySection: { isLoading: false, data: [] },
    howToFixDisplayData: '',
    selectedSubtest: [],
    subtestOptions: [],
    selectedOption: [],
    errorLogDataLoading: false,
    createReasonModal: false,
    createReasonLoader: false,
    createReasonError: null,
    errorSection: null,
    deleteReasonModal: false,
    deleteReasonLoader: false,
    selectedYear: { name: moment().year() },
    errorLogErrorsOverTime: { isLoading: false, data: null },
    reasonList: [],
    selectedReason: null,
    selectedErrorFilter: {
      name: 'All Time',
      type: 'all_time',
    },
  },
  reducers: {
    setSelectedErrorFilter: (state, { payload }) => {
      state.selectedErrorFilter = payload
    },
    setSelectedYear: (state, { payload }) => {
      state.selectedYear = payload
    },
    clearErrorLogData: (state) => {
      state.errorLogData = { isLoading: false, data: [], isLocked: false }
      state.errorLogBySection = { isLoading: false, data: [] }
    },
    toggleReasonDelete: (state, { payload }) => {
      state.deleteReasonModal = payload
    },
    setErrorSection: (state, { payload }) => {
      state.errorSection = payload
    },
    setCreateReasonModal: (state, { payload }) => {
      state.createReasonModal = payload
      if (payload === false) {
        state.createReasonError = null
        state.errorSection = null
      }
    },
    updateSelectedSubtest: (state, { payload }) => {
      state.selectedSubtest = payload
      state.subtestOptions = state.errorLogData.data.filter(
        (errorLog) => errorLog.section.id === payload.id,
      )
      state.selectedOption = {
        id: state.subtestOptions[0]?.id,
        name: state.subtestOptions[0]?.reason,
        howToFix: state.subtestOptions[0]?.how_to_fix,
        is_mine: state.subtestOptions[0]?.is_mine,
        num_user_attempts: state.subtestOptions[0]?.num_user_attempts,
      }
    },
    updateSelectedOption: (state, { payload }) => {
      if (payload) {
        state.selectedOption = payload
      } else {
        state.selectedOption = {
          id: state.subtestOptions[0].id,
          name: state.subtestOptions[0].reason,
          howToFix: state.subtestOptions[0].how_to_fix,
          is_mine: state.subtestOptions[0]?.is_mine,
          num_user_attempts: state.subtestOptions[0]?.num_user_attempts,
        }
      }
    },
    setSelectedErrorReason: (state, { payload }) => {
      state.selectedErrorReason = payload
    },
    clearCreateReasonError: (state, { payload }) => {
      state.createReasonError = null
    },
  },
  extraReducers: {
    [getErrorLogErrorsOverTime.pending]: (state) => {
      state.errorLogErrorsOverTime.isLoading = true
    },
    [getErrorLogErrorsOverTime.fulfilled]: (state, { payload }) => {
      state.errorLogErrorsOverTime.isLoading = false
      state.errorLogErrorsOverTime.data = payload?.data
    },
    [getErrorLogErrorsOverTime.rejected]: (state, { payload }) => {
      if (payload?.message !== 'canceled') {
        state.errorLogBySection.isLoading = false
      }
    },

    [deleteReason.pending]: (state, action) => {
      state.deleteReasonLoader = true
    },
    [deleteReason.fulfilled]: (state, action) => {
      state.deleteReasonLoader = false
      state.deleteReasonModal = false
    },
    [deleteReason.rejected]: (state, { payload }) => {
      state.deleteReasonLoader = false
    },
    [createErrorLogReason.pending]: (state, action) => {
      state.createReasonLoader = true
    },
    [createErrorLogReason.fulfilled]: (state, action) => {
      state.createReasonLoader = false
      state.createReasonModal = false
    },
    [createErrorLogReason.rejected]: (state, { payload }) => {
      state.createReasonError = payload?.message
      state.createReasonLoader = false
    },
    [updateErrorLogReason.pending]: (state, action) => {
      state.createReasonLoader = true
    },
    [updateErrorLogReason.fulfilled]: (state, action) => {
      state.createReasonLoader = false
      state.createReasonModal = false
    },
    [updateErrorLogReason.rejected]: (state, { payload }) => {
      state.createReasonError = payload?.message
      state.createReasonLoader = false
    },
    [getErrorLogData.pending]: (state, action) => {
      state.errorLogDataLoading = true
    },
    [getErrorLogData.fulfilled]: (state, action) => {
      state.errorLogDataLoading = false
      state.errorLogData.isLocked = false
      state.errorLogData.data = action.payload.data
      if (!state.selectedSubtest.id) {
        state.selectedSubtest = {
          id: state.errorLogData.data.slice().sort((a, b) => {
            return (
              a.section.number - b.section.number ||
              b.section.number - a.section.number
            )
          })[0].section.id,
          name: state.errorLogData.data.slice().sort((a, b) => {
            return (
              a.section.number - b.section.number ||
              b.section.number - a.section.number
            )
          })[0].section.display_name,
        }
      }
    },
    [getErrorLogData.rejected]: (state, { payload }) => {
      if (payload?.status === 403) {
        state.errorLogData.isLocked = true
      }
      state.errorLogDataLoading = false
    },
    [getErrorLogBySection.pending]: (state, action) => {
      state.errorLogBySection.isLoading = true
    },
    [getErrorLogBySection.fulfilled]: (state, { payload }) => {
      state.errorLogBySection.isLoading = false
      state.errorLogBySection = {
        data: [
          ...payload.data.reasons,
          {
            reason: 'Not specified',
            num_user_attempts: payload.data.num_unspecified,
            num_user_bookmarked_attempts: 0,
            type: 'unspecified',
          },
        ],
        num_unspecified: payload?.data?.num_unspecified,
        minimum_user_year: payload?.data?.minimum_user_year,
        max_custom_error_log_reasons_per_subtest:
          payload?.data?.max_custom_error_log_reasons_per_subtest,
        ...payload?.data,
      }
      const reasons = payload.data.reasons?.sort(
        (a, b) => b.num_user_attempts - a.num_user_attempts,
      )
      state.selectedErrorReason = {
        name: `${reasons[0]?.reason} (${reasons[0]?.num_user_attempts})`,
        id: reasons[0]?.id,
      }
      state.selectedErrorReasonList = reasons.map((reason) => ({
        name: `${reason?.reason} (${reason?.num_user_attempts})`,
        id: reason?.id,
        reason: reason?.reason,
      }))
    },
    [getErrorLogBySection.rejected]: (state, { payload }) => {
      if (payload?.message !== 'canceled') {
        state.errorLogBySection.isLoading = false
      }
    },
  },
})
export const {
  setSelectedErrorFilter,
  clearCreateReasonError,
  setSelectedYear,
  toggleReasonDelete,
  setErrorSection,
  setCreateReasonModal,
  updateSelectedSubtest,
  updateSubtestOptions,
  updateSelectedOption,
  clearErrorLogData,
  setSelectedErrorReason,
} = errorLogSlice.actions
export default errorLogSlice.reducer
