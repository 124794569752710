const Weighing = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={`h-4 w-4 ${className}`}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3V4.36465L5.88215 5.2593C5.86543 5.26195 5.84886 5.26517 5.83246 5.26893C5.67464 5.30494 5.53881 5.38916 5.43811 5.5032C5.41001 5.53498 5.38444 5.56933 5.3618 5.60602C5.34839 5.62772 5.33608 5.65012 5.32491 5.67312L2.32918 11.6646C2.27711 11.7687 2.25 11.8836 2.25 12C2.25 12.9946 2.64509 13.9484 3.34835 14.6517C4.05161 15.3549 5.00544 15.75 6 15.75C6.99456 15.75 7.94839 15.3549 8.65165 14.6517C9.35491 13.9484 9.75 12.9946 9.75 12C9.75 11.8836 9.72289 11.7687 9.67082 11.6646L7.12495 6.57285L11.25 5.88535V19.25H7C6.58579 19.25 6.25 19.5858 6.25 20C6.25 20.4142 6.58579 20.75 7 20.75H12H17C17.4142 20.75 17.75 20.4142 17.75 20C17.75 19.5858 17.4142 19.25 17 19.25H12.75V5.88535L16.875 6.57285L14.3292 11.6646C14.2771 11.7687 14.25 11.8836 14.25 12C14.25 12.9946 14.6451 13.9484 15.3483 14.6517C16.0516 15.3549 17.0054 15.75 18 15.75C18.9946 15.75 19.9484 15.3549 20.6517 14.6517C21.3549 13.9484 21.75 12.9946 21.75 12C21.75 11.8836 21.7229 11.7687 21.6708 11.6646L18.6751 5.67312C18.6639 5.65012 18.6516 5.62772 18.6382 5.60602C18.6156 5.56933 18.59 5.53498 18.5619 5.5032C18.4612 5.38916 18.3254 5.30494 18.1675 5.26893C18.1511 5.26517 18.1346 5.26196 18.1179 5.2593L12.75 4.36465V3ZM3.75605 12.165C3.79546 12.7014 4.0261 13.2081 4.40901 13.591C4.83097 14.0129 5.40326 14.25 6 14.25C6.59674 14.25 7.16903 14.0129 7.59099 13.591C7.9739 13.2081 8.20454 12.7014 8.24395 12.165L6 7.67705L3.75605 12.165ZM16.409 13.591C16.0261 13.2081 15.7955 12.7014 15.7561 12.165L18 7.67705L20.2439 12.165C20.2045 12.7014 19.9739 13.2081 19.591 13.591C19.169 14.0129 18.5967 14.25 18 14.25C17.4033 14.25 16.831 14.0129 16.409 13.591Z"
      fill="currentColor"
    />
  </svg>
)

export default Weighing
