import { Play } from 'components/icons'
import AddNote from 'components/icons/AddNote'
import ForwardIcon from 'components/icons/ForwardIcon'
import FullScreenIcon from 'components/icons/FullScreenIcon'
import PauseIcon from 'components/icons/PauseIcon'
import PipIcon from 'components/icons/PipIcon'
import RewindIcon from 'components/icons/RewindIcon'
import VolumeIcon from 'components/icons/VolumeIcon'
import VolumeOffIcon from 'components/icons/VolumeOffIcon'
import PollVideoQualityList from 'components/shared/chapter/module2/PollVideoQualityList'
import PollVideoSetting from 'components/shared/chapter/module2/PollVideoSetting'
import VideoQualityList from 'components/shared/chapter/module2/VideoQualityList'
import VideoSetting from 'components/shared/chapter/module2/VideoSetting'
import { formatTimestamp } from 'components/shared/helper'
import Slider from 'rc-slider'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPlayedSeconds } from 'redux/learn/resourceSlice'

const VideoSlider = ({
  playerRef,
  marks,
  handleTogglePIP,
  isFullScreen,
  duration,
  handlePlayPause,
  isPlaying,
  volume,
  handleToggleVolume,
  handleVolumeChange,
  handleToggleFullScreen,
  handleToggleSubtitles,
  playbackSpeed,
  subtitlesEnabled,
  setPlaybackSpeed,
  handleAddNote,
  transcriptArray,
  currentSubtitleIndex,
  mediaArray,
  isPIPActive,
  isMuted,
  playedSeconds,
  updatePlayedSeconds,
  isFromPollExplanationVideo,
  pollPlayedSeconds,
}) => {
  const dispatch = useDispatch()
  const { isQualityOpen, isPollQualityOpen } = useSelector((state) => ({
    isQualityOpen: state.resource.isQualityOpen,
    isPollQualityOpen: state.resource.isPollQualityOpen,
  }))
  //
  const handleProgressBarChange = (event) => {
    // const timestampRange = 5
    // const currentPoll = chapterableVideo?.polls.find((poll) =>
    //   poll.questions.some(
    //     (question) =>
    //       Math.abs(playedSeconds - poll.video_timestamp) <= timestampRange,
    //   ),
    // )

    // if (currentPoll) {
    //   setSelectedPoll(currentPoll)
    //   setIsPlaying(false)
    //   setIsPollModalOpen(true)
    // } else {
    //   setIsPollModalOpen(false)
    // }

    const clickedTime = parseFloat(event)
    updatePlayedSeconds
      ? updatePlayedSeconds(clickedTime)
      : dispatch(setPlayedSeconds(clickedTime))

    playerRef.current.seekTo(parseFloat(clickedTime))
  }

  const handleForward = () => {
    const currentTime = playerRef.current.getCurrentTime()
    if (duration > currentTime) {
      if (currentTime + 15 >= duration) {
        playerRef.current.seekTo(duration - 1)
      } else {
        playerRef.current.seekTo(currentTime + 15)
      }
    } else {
      playerRef.current && playerRef?.current.pause()
    }
  }

  const handleBackward = () => {
    const currentTime = playerRef.current.getCurrentTime()
    playerRef.current.seekTo(currentTime - 15)
  }

  return (
    playerRef && (
      <div
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        className={`${
          isFullScreen ? 'absolute bottom-0 left-0 w-full z-50' : 'relative'
        } w-full ${isFullScreen ? 'z-50' : ''}`}
      >
        <div className="relative">
          <Slider
            min={0}
            max={duration}
            step={1}
            value={playedSeconds || 0}
            onChange={handleProgressBarChange}
            trackStyle={{
              backgroundColor: 'red',
              cursor: 'pointer',
            }}
            marks={marks ? marks : undefined}
            handleStyle={{
              borderColor: 'gray',
              backgroundColor: 'white',
              cursor: 'pointer',
            }}
            tooltipVisible={false}
            className="hide-slider-value p-0 h-auto"
          />
        </div>
        <div className="flex justify-between items-center px-4 gap-2 py-2 bg-gray-900">
          <div className="flex space-x-4">
            <div className="flex items-center space-x-2">
              <button onClick={handleBackward}>
                <RewindIcon
                  className={`${
                    isPIPActive ? 'h-3 w-3' : 'h-3 w-3 sm:w-4 sm:h-4'
                  } cursor-pointer text-white`}
                />
              </button>
              <button onClick={handlePlayPause}>
                {isPlaying ? (
                  <PauseIcon
                    className={`${
                      isPIPActive ? 'h-3 w-3' : 'h-3 w-3 sm:w-4 sm:h-4'
                    } cursor-pointer text-white`}
                  />
                ) : (
                  <Play
                    className={`${
                      isPIPActive ? 'h-3 w-3' : 'h-3 w-3 sm:w-4 sm:h-4'
                    } cursor-pointer text-white`}
                  />
                )}
              </button>
              <button
                onClick={() => {
                  playedSeconds < duration && handleForward()
                }}
              >
                <ForwardIcon
                  className={`${
                    isPIPActive ? 'h-3 w-3' : 'h-3 w-3 sm:w-4 sm:h-4'
                  } cursor-pointer text-white`}
                />
              </button>
            </div>

            <div
              className={`${
                isPIPActive ? 'text-xs' : 'hidden sm:block md:hidden lg:block'
              }`}
            >
              <span className="text-white">
                {formatTimestamp(playedSeconds)} / {formatTimestamp(duration)}
              </span>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            {transcriptArray &&
            currentSubtitleIndex &&
            transcriptArray?.length > 0 &&
            transcriptArray[currentSubtitleIndex]?.text &&
            playedSeconds !== 0 &&
            handleAddNote ? (
              <button
                onClick={() => {
                  handleAddNote && handleAddNote()
                }}
              >
                <AddNote className="h-3 w-3 sm:w-4 sm:h-4 text-white" />
              </button>
            ) : (
              <></>
            )}
            <div className="flex space-x-2.5 items-center">
              <button onClick={handleToggleVolume}>
                {isMuted ? (
                  <VolumeOffIcon
                    className={`${
                      isPIPActive ? 'h-3 w-3' : 'h-3 w-3 sm:w-4 sm:h-4'
                    } `}
                  />
                ) : (
                  <VolumeIcon
                    className={`${
                      isPIPActive ? 'h-3 w-3' : 'h-3 w-3 sm:w-4 sm:h-4'
                    } `}
                  />
                )}
              </button>
              <div className="w-10 sm:w-12">
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  value={isMuted ? 0 : volume}
                  onChange={handleVolumeChange}
                  handleStyle={{
                    borderColor: volume === 0 ? 'gray' : 'white',
                    backgroundColor: volume === 0 ? 'gray' : 'white',
                    cursor: 'pointer',
                  }}
                  className="volume-slider"
                />
              </div>
            </div>
            {isFromPollExplanationVideo ? (
              <>
                {isPollQualityOpen ? (
                  <span>
                    <PollVideoQualityList
                      playerRef={playerRef}
                      mediaArray={mediaArray}
                      isPIPActive={isPIPActive}
                      pollPlayedSeconds={pollPlayedSeconds}
                    />
                  </span>
                ) : (
                  <span>
                    <PollVideoSetting
                      subtitlesEnabled={subtitlesEnabled}
                      handleToggleSubtitles={handleToggleSubtitles}
                      playbackSpeed={playbackSpeed}
                      setPlaybackSpeed={setPlaybackSpeed}
                      mediaArray={mediaArray}
                      isPIPActive={isPIPActive}
                    />
                  </span>
                )}
              </>
            ) : (
              <>
                {isQualityOpen ? (
                  <span>
                    <VideoQualityList
                      playerRef={playerRef}
                      mediaArray={mediaArray}
                      isPIPActive={isPIPActive}
                    />
                  </span>
                ) : (
                  <span>
                    <VideoSetting
                      subtitlesEnabled={subtitlesEnabled}
                      handleToggleSubtitles={handleToggleSubtitles}
                      playbackSpeed={playbackSpeed}
                      setPlaybackSpeed={setPlaybackSpeed}
                      mediaArray={mediaArray}
                      isPIPActive={isPIPActive}
                    />
                  </span>
                )}
              </>
            )}

            {!isPIPActive && (
              <button onClick={handleToggleFullScreen}>
                <FullScreenIcon className="h-3 w-3 sm:w-4 sm:h-4" />
              </button>
            )}

            {handleTogglePIP && (
              <button
                className="w-5 h-5 cursor-pointer"
                onClick={() => {
                  handleTogglePIP && handleTogglePIP()
                }}
              >
                <PipIcon className="text-white" />
              </button>
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default VideoSlider
