import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

export const getMemes = createAsyncThunk(
  'memes/getMemes',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`/memes`)
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const storeUpvotes = createAsyncThunk(
  'memes/storeUpvotes',
  async (data) => {
    return await axiosInterceptors.post(`/memes/store-entry-upvote`, {
      meme_entry_id: data.entryId,
      meme_id: data.memeId,
      down_vote: 0,
    })
  },
)
export const memesHistory = createAsyncThunk(
  'memes/memesHistory',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('/memes/history')
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const storeHistoryUpvotes = createAsyncThunk(
  'memes/storeHistoryUpvotes',
  async (data) => {
    return await axiosInterceptors.post(`/memes/store-entry-upvote`, {
      meme_entry_id: data.entryId,
      meme_id: data.memeId,
      down_vote: 0,
    })
  },
)
export const storeMemeEntry = createAsyncThunk(
  'memes/storeMemeEntry',
  async ({ memeImage, memeId, onSuccess }, { rejectWithValue }) => {
    const formData = new FormData()
    formData.append('image', memeImage)
    formData.append('meme_topic_id', memeId)
    try {
      const res = await axiosInterceptors.post(`/memes/store-entry`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
const memeSlice = createSlice({
  name: 'memes',
  initialState: {
    memeImage: [],
    memesData: { isLoading: false, data: [], isLocked: false },
    memesHistoryData: { isLoading: false, data: [], isLocked: false },
    memeImageUpload: { isLoading: false },
    memeImageData: null,
    upvoteLoading: false,
    showMemeUploadSuccessMessage: false,
    maxImageMb: null,
  },
  extraReducers: {
    [getMemes.pending]: (state, action) => {
      state.memesData.isLoading = true
    },
    [getMemes.fulfilled]: (state, { payload }) => {
      state.memesData.isLoading = false
      state.memesData.data = payload.data && payload.data.current_memes_data
      state.maxImageMb = payload?.data?.max_image_mb
      state.memesData.isLocked = false
    },
    [getMemes.rejected]: (state, { payload }) => {
      state.memesData.isLoading = false
      if (payload?.status === 403) {
        state.memesData.isLocked = true
      }
    },
    [storeMemeEntry.pending]: (state, action) => {
      state.memeImageUpload = { isLoading: true }
    },
    [storeMemeEntry.fulfilled]: (state, { payload }) => {
      state.memeImageUpload = {
        isLoading: false,
      }
      state.memeImage = []
      state.memeImageData = null
      state.showMemeUploadSuccessMessage = true
    },
    [storeMemeEntry.rejected]: (state, action) => {
      state.memeImageUpload = { isLoading: false }
    },
    [storeUpvotes.pending]: (state) => {
      state.upvoteLoading = true
    },
    [storeUpvotes.fulfilled]: (state, action) => {
      const updateIndex = findIndex(state.memesData.data, {
        id: action.meta.arg.memeId,
      })
      const entryUpdateIndex = findIndex(
        state.memesData.data[updateIndex].meme_entries,
        { meme_entry_id: action.meta.arg.entryId },
      )
      if (action.payload.data.meme_upvote) {
        if (action.payload.data.meme_upvote.meme_entry_id) {
          state.memesData.data[updateIndex].meme_entries[
            entryUpdateIndex
          ].num_upvotes += 1
          state.memesData.data[updateIndex].meme_entries[
            entryUpdateIndex
          ].user_upvote = true
        } else {
          state.memesData.data[updateIndex].meme_entries[
            entryUpdateIndex
          ].num_upvotes -= 1
          state.memesData.data[updateIndex].meme_entries[
            entryUpdateIndex
          ].user_upvote = null
        }
      } else {
        state.memesData.data[updateIndex].meme_entries[
          entryUpdateIndex
        ].num_upvotes -= 1
        state.memesData.data[updateIndex].meme_entries[
          entryUpdateIndex
        ].user_upvote = null
      }
      state.upvoteLoading = false
    },
    [storeUpvotes.rejected]: (state) => {
      state.upvoteLoading = false
    },
    [storeHistoryUpvotes.fulfilled]: (state, action) => {
      const updateIndex = findIndex(state.memesHistoryData.data, {
        id: action.meta.arg.memeId,
      })
      if (action.payload.data.meme_upvote) {
        if (action.payload.data.meme_upvote.meme_entry_id) {
          state.memesHistoryData.data[
            updateIndex
          ].winning_meme_entry.num_upvotes += 1
          state.memesHistoryData.data[
            updateIndex
          ].winning_meme_entry.user_upvote = true
        } else {
          state.memesHistoryData.data[
            updateIndex
          ].winning_meme_entry.num_upvotes -= 1
          state.memesHistoryData.data[
            updateIndex
          ].winning_meme_entry.user_upvote = null
        }
      } else {
        state.memesHistoryData.data[
          updateIndex
        ].winning_meme_entry.num_upvotes -= 1
        state.memesHistoryData.data[
          updateIndex
        ].winning_meme_entry.user_upvote = null
      }
      state.upvoteLoading = false
    },
    [memesHistory.pending]: (state) => {
      state.memesHistoryData.isLoading = true
    },
    [memesHistory.fulfilled]: (state, { payload }) => {
      state.memesHistoryData.data =
        payload.data && payload.data.memes_history_data
      state.memesHistoryData.isLoading = false
      state.memesHistoryData.isLocked = false
    },
    [memesHistory.rejected]: (state, { payload }) => {
      state.memesHistoryData.isLoading = false
      if (payload?.status === 403) {
        state.memesHistoryData.isLocked = true
      }
    },
  },
  reducers: {
    updateMemeImageData: (state, { payload }) => {
      state.memeImageData = payload.file
    },
    setMemeImage: (state, { payload }) => {
      state.memeImage = payload
    },
    setShowMemeUploadSuccess: (state, { payload }) => {
      state.showMemeUploadSuccessMessage = payload
    },
  },
})

export const { updateMemeImageData, setMemeImage, setShowMemeUploadSuccess } =
  memeSlice.actions

export default memeSlice.reducer
