import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

export const startKeywordScanning = createAsyncThunk(
  'keywordScanning/startKeywordScanning',
  async (data) => {
    return await axiosInterceptors.post(
      '/trainer/keyword-scanning/start-attempt',
    )
  },
)
export const submitKeywordScanning = createAsyncThunk(
  'keywordScanning/submitKeywordScanning',
  async (data, { rejectWithValue }) => {
    try {
      return await axiosInterceptors.post(
        `trainer/keyword-scanning/${data.id}/submit-attempt`,
        {
          keyword_scanning_trainer_attempt_id: data.id,
          total_seconds: parseInt(data.totalSeconds),
          ucat_view_used: data.ucatViewUsed,
          keywords: data.keywordsFound.map((data) => ({
            num_found: data.numFound,
            keyword: data.keyword,
          })),
        },
        { headers: { notToRedirect404: true } },
      )
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

const keywordScanningSlice = createSlice({
  name: 'keywordScanning',
  initialState: {
    keywordData: [],
    keywordScanningLoading: false,
    keywordsFound: [],
    keywordResult: [],
    totalKeyword: [],
    keywordHighLight: [],
    totalKeywordsCount: null,
    totalKeywordsSelected: null,
    keywordExamTime: null,
    timerInterval: null,
    isOpenReviewPassage: false,
    disableKeywordSideBar: false,
    startKeywordTraining: {
      startSimpleView: false,
      startUcatView: false,
      viewStatistics: false,
    },
    keywordParagraph: '',
    reviewKeywordParagraph: '',
    keywordDefaultTime: 30,
    keywordExtraTime: 0,
  },
  extraReducers: {
    [startKeywordScanning.pending]: (state, action) => {
      state.keywordData = []
      state.keywordsFound = []
      state.keywordHighLight = []
      state.keywordExamTime = null
      state.totalKeywordsSelected = null
      state.keywordParagraph = ''
      state.reviewKeywordParagraph = ''
      state.keywordScanningLoading = true
    },
    [startKeywordScanning.fulfilled]: (state, action) => {
      state.keywordExamTime =
        action.payload.data.attempt.recommended_seconds ||
        state.keywordDefaultTime
      state.keywordDefaultTime = action.payload.data.attempt.recommended_seconds
      state.keywordData = action.payload.data
      action.payload.data.attempt.passage.forEach((keyword, index) => {
        if (keyword.contains_html) {
          state.keywordParagraph =
            state.keywordParagraph + keyword.html + '&nbsp;'
        } else {
          state.keywordParagraph =
            state.keywordParagraph +
            `<span ${
              keyword.is_keyword
                ? `data-keyword="${keyword.relevant_keyword}" data-index="${index}"`
                : ''
            } 
            data-content="${keyword.html.replace(/"/g, '&quot;')}"
             class="keyword-text  ${
               keyword.is_keyword ? `actual-keyword ` : ''
             }"></span>&nbsp;`
        }
      })

      state.keywordsFound = action.payload.data.attempt.keywords.map(
        (keyword) => ({
          keyword: keyword,
          numFound: 0,
        }),
      )
      state.keywordScanningLoading = false
    },
    [startKeywordScanning.rejected]: (state, action) => {
      state.keywordScanningLoading = false
      state.keywordDataRejected = true
    },
    [submitKeywordScanning.pending]: (state, action) => {
      state.keywordSubmitLoader = true
    },
    [submitKeywordScanning.fulfilled]: (state, action) => {
      state.keywordSubmitLoader = false
      if (state.totalKeywordsSelected === null) {
        state.totalKeywordsSelected = 0
      }
      state.startKeywordTraining = {
        startSimpleView: false,
        startUcatView: false,
        viewStatistics: true,
      }
      state.keywordResult = action.payload.data
      state.disableKeywordSideBar = false
      state.keywordData.attempt.passage.forEach((keyword, index) => {
        if (keyword.contains_html) {
          state.reviewKeywordParagraph =
            state.reviewKeywordParagraph + keyword.html + '&nbsp;'
        } else {
          state.reviewKeywordParagraph =
            state.reviewKeywordParagraph +
            `<span ${
              keyword.is_keyword
                ? `data-keyword=${keyword.relevant_keyword} data-index=${index}`
                : ''
            } 
            data-content="${keyword.html.replace(/"/g, '&quot;')}"
             class="keyword-text  ${
               keyword.is_keyword ? `actual-review-keyword` : ''
             } ${
              findIndex(state.keywordHighLight, {
                id: index.toString(),
              }) > -1
                ? 'bg-green-200 border border-red-400 rounded-md font-bold px-1'
                : `${
                    keyword.is_keyword &&
                    ' border border-red-400 rounded-md px-1'
                  }`
            }"></span>&nbsp;`
        }
      })
    },
    [submitKeywordScanning.rejected]: (state, action) => {
      state.keywordSubmitLoader = false
      state.keywordDataRejected = true
    },
  },
  reducers: {
    setSimpleView: (state, { payload }) => {
      state.startKeywordTraining = {
        startSimpleView: true,
        startUcatView: false,
      }
    },
    setStartUcatView: (state, { payload }) => {
      state.startKeywordTraining = {
        startSimpleView: false,
        startUcatView: true,
      }
    },
    setViewStatistics: (state, { payload }) => {
      state.startKeywordTraining = {
        startSimpleView: false,
        startUcatView: false,
        viewStatistics: true,
      }
    },
    setTryAgainView: (state) => {
      state.startKeywordTraining = {
        startSimpleView: false,
        startUcatView: false,
        viewStatistics: false,
      }
      state.keywordDataRejected = false
      state.keywordData = []
      state.keywordsFound = []
      state.keywordHighLight = []
      state.keywordExamTime = null
      state.totalKeywordsSelected = null
      state.keywordParagraph = ''
      state.reviewKeywordParagraph = ''
      state.keywordExtraTime = 0
    },

    setKeywordFound: (state, { payload }) => {
      state.keywordsFound.forEach((value, i) => {
        if (value.keyword === payload.relevantKeyword) {
          state.keywordsFound[i].numFound += 1
        }
      })
      state.totalKeywordsSelected = state.keywordsFound.reduce(
        (a, b) => a + b.numFound,
        0,
      )
    },
    setTotalKeyword: (state, { payload }) => {
      state.totalKeyword = payload
      state.totalKeywordsCount = Object.values(state.totalKeyword).reduce(
        (a, b) => a + b,
        0,
      )
    },
    setKeywordExamTime: (state, { payload }) => {
      state.keywordExamTime -= 1
    },
    setKeywordExamExtraTime: (state, { payload }) => {
      state.keywordExtraTime += 1
    },
    setKeywordHighlight: (state, { payload }) => {
      state.keywordHighLight = [payload, ...state.keywordHighLight]
    },
    toggleReviewPassage: (state) => {
      state.isOpenReviewPassage = !state.isOpenReviewPassage
    },
    setDisableKeywordSideBar: (state, { payload }) => {
      state.disableKeywordSideBar = payload
    },
  },
})

export const {
  setKeywordExamExtraTime,
  setSimpleView,
  setStartUcatView,
  setKeywordFound,
  setTryAgainView,
  setTotalKeyword,
  setKeywordExamTime,
  setKeywordHighlight,
  toggleReviewPassage,
  setDisableKeywordSideBar,
} = keywordScanningSlice.actions

export default keywordScanningSlice.reducer
