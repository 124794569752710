import { Fragment } from 'react'
import { Close } from 'components/icons'
import { Transition, Dialog } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  markTourSkipped,
  skipTourData,
  toggleShowStartTourDialog,
} from 'redux/tour-steps/tourStepsSlice'
import { useTour } from '@reactour/tour'
import { Button, SmallLogo } from 'components/shared'

const StartTourDialog = () => {
  const dispatch = useDispatch()
  const { tourStepsData, showStartTourDialog, userAccessArea } = useSelector(
    (state) => ({
      showStartTourDialog: state.tourSteps.showStartTourDialog,
      tourStepsData: state.tourSteps.tourStepsData,
      userAccessArea: state.dashboard.userAccessArea,
    }),
  )

  const isFreeTrial =
    userAccessArea?.data?.includes('free-trial') &&
    !userAccessArea?.data?.includes('current-student')

  const { setIsOpen, setSteps, setCurrentStep } = useTour()

  return (
    <>
      <Transition appear show={showStartTourDialog} as={Fragment}>
        <Dialog
          as="div"
          className="bg-gray-700 bg-opacity-80 fixed max-w-full max-h-screen h-screen w-full inset-0 z-50  overflow-y-auto"
          onClose={() => {}}
        >
          <div className="min-h-screen h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-top"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                style={{ maxWidth: '762px' }}
                className="inline-block overflow-y-hidden max-w-full md:max-w-md  md:h-auto w-full p-5 mt-16 md:mt-12 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-dark-600 shadow-xl rounded-md  "
              >
                <span
                  className="cursor-pointer float-right"
                  onClick={() => {
                    localStorage.setItem('started-tour', 'true')
                    dispatch(toggleShowStartTourDialog(false))
                    dispatch(
                      markTourSkipped({
                        page: 'home',
                        onSuccess: () => {
                          dispatch(skipTourData())
                        },
                      }),
                    )
                  }}
                >
                  <Close className="fill-current dark:text-white" />
                </span>
                <div className="px-1 py-11 sm:p-11  flex flex-col justify-between items-center">
                  <SmallLogo className="h-20 w-20" />
                  <Dialog.Title
                    as="h2"
                    className="mt-8 text-2xl  font-semibold leading-6 text-gray-900 dark:text-white"
                  >
                    {isFreeTrial
                      ? 'Welcome to the MedEntry Free Trial!'
                      : 'Welcome to MedEntry!'}
                  </Dialog.Title>
                  <p className="mt-4 italic mx-16 text-base text-center font-normal text-gray-900 dark:text-white  ">
                    Would you like to take a quick tour of the online learning
                    platform (recommended)? You can take a tour later by
                    visiting the Profile section.
                  </p>
                  <div className="flex">
                    <Button
                      className="mt-10 font-semibold tracking-widest py-4 px-6"
                      onClick={() => {
                        localStorage.setItem('started-tour', 'true')
                        dispatch(toggleShowStartTourDialog(false))
                        setSteps(
                          tourStepsData.data &&
                            tourStepsData.data.tour &&
                            tourStepsData.data.tour.steps,
                        )
                        setIsOpen(true)
                        setCurrentStep(0)
                      }}
                    >
                      Start Tour
                    </Button>
                    <Button
                      className="mt-10 ml-4 font-semibold tracking-widest py-4 px-6"
                      outlined
                      onClick={() => {
                        localStorage.setItem('started-tour', 'true')
                        dispatch(toggleShowStartTourDialog(false))
                        dispatch(
                          markTourSkipped({
                            page: 'home',
                            onSuccess: () => {
                              dispatch(skipTourData())
                            },
                          }),
                        )
                      }}
                    >
                      CLOSE
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default StartTourDialog
