const Erase = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    fill="current"
    // stroke="currentColor"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.68568 1.74817L8.68599 1.74787C8.71719 1.71702 8.7593 1.69971 8.80318 1.69971C8.84707 1.69971 8.88918 1.71702 8.92038 1.74787L8.92068 1.74817L12.2519 5.0794L12.2523 5.07976C12.2831 5.11095 12.3004 5.15304 12.3004 5.1969C12.3004 5.24077 12.2831 5.28285 12.2523 5.31405L12.2519 5.3144L11.0365 6.52982L7.47027 2.96359L8.68568 1.74817ZM6.76316 3.6707L2.02112 8.41274L2.02082 8.41304C1.98997 8.44424 1.97266 8.48635 1.97266 8.53023C1.97266 8.57412 1.98997 8.61623 2.02082 8.64743L2.02112 8.64773L4.54029 11.1669H6.39941L10.3294 7.23692L6.76316 3.6707ZM12.9601 6.02046L7.81362 11.1669H11.6665C11.9427 11.1669 12.1665 11.3908 12.1665 11.6669C12.1665 11.943 11.9427 12.1669 11.6665 12.1669H6.60652H4.33318C4.20058 12.1669 4.0734 12.1142 3.97963 12.0205L1.31296 9.35379L1.31191 9.35274C1.09462 9.13415 0.972656 8.83845 0.972656 8.53023C0.972656 8.22202 1.09462 7.92632 1.31191 7.70773L1.31296 7.70668L7.97963 1.04002L7.98068 1.03897C8.19927 0.821672 8.49497 0.699707 8.80318 0.699707C9.1114 0.699707 9.40709 0.821672 9.62568 1.03897L9.62674 1.04001L12.9601 4.37335L12.9611 4.3744C13.1784 4.59299 13.3004 4.88869 13.3004 5.1969C13.3004 5.50512 13.1784 5.80081 12.9611 6.0194L12.9601 6.02046Z"
      fill="current"
    />
  </svg>
)

export default Erase
