import { Modal } from 'components/form'
import HTMLRenderer from 'components/shared/helper/HTMLRenderer'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowIntroductionModal } from 'redux/learn/resourceSlice'

function QuizIntroductionModal() {
  const dispatch = useDispatch()
  const { showIntroductionModal, bookDetails } = useSelector((state) => ({
    showIntroductionModal: state.resource.showIntroductionModal,
    bookDetails: state.resource.bookDetails,
  }))
  const onClose = () => {
    dispatch(setShowIntroductionModal(false))
  }

  return (
    <>
      <Modal
        open={showIntroductionModal}
        hideSuccessButton={true}
        title="Introduction"
        onClose={onClose}
        content={
          <div className="mt-6 text-base font-normal text-gray-900 dark:text-white html-renderer-container">
            <HTMLRenderer
              html={bookDetails?.chapterable?.intro_html}
              imageStyleProps="background: white"
            />
          </div>
        }
      />
    </>
  )
}

export default QuizIntroductionModal
