const Outlook = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="37"
    fill="none"
    viewBox="0 0 38 37"
  >
    <path
      fill="#0364B8"
      d="M32.776 2.313H13.202c-.424 0-.83.17-1.13.475-.3.304-.468.717-.468 1.148V5.78L22.636 9.25l11.739-3.469V3.936c0-.43-.168-.844-.468-1.148a1.586 1.586 0 00-1.13-.475z"
    ></path>
    <path
      fill="#0A2767"
      d="M36.254 20.125c.166-.533.3-1.075.398-1.625a.778.778 0 00-.379-.666l-.015-.01-.004-.002-12.34-7.138a1.686 1.686 0 00-1.849-.001l-12.34 7.14-.005.003-.013.008a.765.765 0 00-.38.666c.098.55.231 1.092.398 1.625l13.084 9.718 13.445-9.718z"
    ></path>
    <path
      fill="#28A8EA"
      d="M27.544 5.781h-7.97L17.273 9.25l2.3 3.469 7.97 6.937h6.832V12.72L27.544 5.78z"
    ></path>
    <path fill="#0078D4" d="M11.604 5.781h7.97v6.938h-7.97V5.78z"></path>
    <path fill="#50D9FF" d="M27.544 5.781h6.831v6.938h-6.831V5.78z"></path>
    <path
      fill="#0364B8"
      d="M27.544 19.656l-7.97-6.937h-7.97v6.937l7.97 6.938 12.333 2.044-4.363-8.982z"
    ></path>
    <path fill="#0078D4" d="M19.573 12.719h7.97v6.937h-7.97V12.72z"></path>
    <path fill="#064A8C" d="M11.604 19.656h7.97v6.938h-7.97v-6.938z"></path>
    <path fill="#0078D4" d="M27.544 19.656h6.831v6.938h-6.831v-6.938z"></path>
    <path
      fill="#0A2767"
      d="M23.206 29.158L9.779 19.215l.563-1.006s12.234 7.076 12.42 7.182a.593.593 0 00.48-.014l12.447-7.203.565 1.005-13.048 9.98z"
      opacity="0.5"
    ></path>
    <path
      fill="#1490DF"
      d="M36.273 19.167l-.016.01-.003.002-12.34 7.138a1.688 1.688 0 01-1.663.106l4.297 5.851 9.398 2.078v.005c.219-.16.397-.372.52-.616.122-.244.186-.514.186-.788V18.5a.78.78 0 01-.379.667z"
    ></path>
    <path
      fill="#000"
      d="M36.652 32.953V32.1l-11.366-6.577-1.372.794a1.688 1.688 0 01-1.663.106l4.297 5.851 9.398 2.078v.005c.219-.16.397-.372.52-.616.122-.244.186-.514.186-.788z"
      opacity="0.05"
    ></path>
    <path
      fill="#000"
      d="M36.595 33.396l-12.46-7.207-.221.127a1.686 1.686 0 01-1.663.107l4.297 5.851 9.398 2.078v.005c.319-.235.548-.574.65-.96v-.001z"
      opacity="0.1"
    ></path>
    <path
      fill="#28A8EA"
      d="M9.725 19.182v-.011h-.011l-.035-.023a.746.746 0 01-.353-.648v14.453a1.756 1.756 0 00.5 1.227 1.704 1.704 0 001.208.508h23.91c.143-.002.284-.021.422-.058a.717.717 0 00.205-.07.16.16 0 00.068-.023 1.19 1.19 0 00.262-.15c.023-.012.034-.012.045-.035L9.725 19.182z"
    ></path>
    <path
      fill="#000"
      d="M20.712 28.521V9.635a1.558 1.558 0 00-.446-1.088 1.51 1.51 0 00-1.072-.453h-7.556v8.62l-1.913 1.108-.006.003-.013.008a.765.765 0 00-.38.667v.006-.006 11.563h9.868a1.51 1.51 0 001.072-.453c.285-.29.445-.68.446-1.089z"
      opacity="0.1"
    ></path>
    <path
      fill="#000"
      d="M19.574 29.677V10.791a1.558 1.558 0 00-.446-1.088 1.51 1.51 0 00-1.072-.453h-6.418v7.465l-1.913 1.107-.006.003-.013.008a.765.765 0 00-.38.667v.006-.006 12.719h8.73a1.51 1.51 0 001.072-.453c.284-.289.444-.68.446-1.088z"
      opacity="0.2"
    ></path>
    <path
      fill="#000"
      d="M19.574 27.365V10.791a1.558 1.558 0 00-.446-1.088 1.51 1.51 0 00-1.072-.453h-6.418v7.465l-1.913 1.107-.006.003-.013.008a.765.765 0 00-.38.667v.006-.006 10.406h8.73a1.51 1.51 0 001.072-.453c.284-.288.444-.68.446-1.088z"
      opacity="0.2"
    ></path>
    <path
      fill="#000"
      d="M18.435 27.365V10.791a1.558 1.558 0 00-.446-1.088 1.51 1.51 0 00-1.072-.453h-5.28v7.465l-1.912 1.107-.006.003-.013.008a.765.765 0 00-.38.667v.006-.006 10.406h7.591a1.51 1.51 0 001.072-.453c.284-.288.445-.68.446-1.088z"
      opacity="0.2"
    ></path>
    <path
      fill="#0078D4"
      d="M1.735 9.25h15.182c.403 0 .789.162 1.073.451.285.29.445.681.445 1.09V26.21c0 .409-.16.8-.445 1.09-.284.289-.67.451-1.073.451H1.735c-.402 0-.788-.162-1.073-.451a1.554 1.554 0 01-.444-1.09V10.79c0-.409.16-.8.444-1.09.285-.289.671-.451 1.073-.451z"
    ></path>
    <path
      fill="#fff"
      d="M4.62 15.572a4.808 4.808 0 011.87-2.097 5.584 5.584 0 012.972-.756 5.193 5.193 0 012.747.717 4.76 4.76 0 011.82 2.003c.438.918.657 1.929.637 2.949a6.912 6.912 0 01-.657 3.082 4.874 4.874 0 01-1.873 2.075 5.4 5.4 0 01-2.852.736 5.32 5.32 0 01-2.81-.725 4.832 4.832 0 01-1.842-2.007 6.393 6.393 0 01-.645-2.912 7.094 7.094 0 01.634-3.065zm1.993 4.924c.215.552.58 1.03 1.051 1.38.48.342 1.057.518 1.643.503a2.852 2.852 0 001.755-.519c.466-.35.821-.831 1.02-1.384a5.423 5.423 0 00.327-1.925 5.938 5.938 0 00-.308-1.95 3.086 3.086 0 00-.986-1.433 2.69 2.69 0 00-1.738-.547 2.807 2.807 0 00-1.682.507c-.481.351-.854.833-1.075 1.391a5.602 5.602 0 00-.008 3.978l.001-.001z"
    ></path>
  </svg>
)
export default Outlook
