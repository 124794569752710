const Light = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.5 1C7.5 0.723858 7.27614 0.5 7 0.5C6.72386 0.5 6.5 0.723858 6.5 1V1.66667C6.5 1.94281 6.72386 2.16667 7 2.16667C7.27614 2.16667 7.5 1.94281 7.5 1.66667V1ZM3.08689 2.37978C2.89162 2.18452 2.57504 2.18452 2.37978 2.37978C2.18452 2.57504 2.18452 2.89162 2.37978 3.08689L2.84645 3.55355C3.04171 3.74882 3.35829 3.74882 3.55355 3.55355C3.74882 3.35829 3.74882 3.04171 3.55355 2.84645L3.08689 2.37978ZM11.6202 3.08689C11.8155 2.89162 11.8155 2.57504 11.6202 2.37978C11.425 2.18452 11.1084 2.18452 10.9131 2.37978L10.4464 2.84645C10.2512 3.04171 10.2512 3.35829 10.4464 3.55355C10.6417 3.74882 10.9583 3.74882 11.1536 3.55355L11.6202 3.08689ZM1 6.5C0.723858 6.5 0.5 6.72386 0.5 7C0.5 7.27614 0.723858 7.5 1 7.5H1.66667C1.94281 7.5 2.16667 7.27614 2.16667 7C2.16667 6.72386 1.94281 6.5 1.66667 6.5H1ZM12.3333 6.5C12.0572 6.5 11.8333 6.72386 11.8333 7C11.8333 7.27614 12.0572 7.5 12.3333 7.5H13C13.2761 7.5 13.5 7.27614 13.5 7C13.5 6.72386 13.2761 6.5 13 6.5H12.3333ZM3.55355 11.1536C3.74882 10.9583 3.74882 10.6417 3.55355 10.4464C3.35829 10.2512 3.04171 10.2512 2.84645 10.4464L2.37978 10.9131C2.18452 11.1084 2.18452 11.425 2.37978 11.6202C2.57504 11.8155 2.89162 11.8155 3.08689 11.6202L3.55355 11.1536ZM11.1536 10.4464C10.9583 10.2512 10.6417 10.2512 10.4464 10.4464C10.2512 10.6417 10.2512 10.9583 10.4464 11.1536L10.9131 11.6202C11.1084 11.8155 11.425 11.8155 11.6202 11.6202C11.8155 11.425 11.8155 11.1084 11.6202 10.9131L11.1536 10.4464ZM7.5 12.3333C7.5 12.0572 7.27614 11.8333 7 11.8333C6.72386 11.8333 6.5 12.0572 6.5 12.3333V13C6.5 13.2761 6.72386 13.5 7 13.5C7.27614 13.5 7.5 13.2761 7.5 13V12.3333ZM4.83333 7C4.83333 5.80338 5.80338 4.83333 7 4.83333C8.19662 4.83333 9.16667 5.80338 9.16667 7C9.16667 8.19662 8.19662 9.16667 7 9.16667C5.80338 9.16667 4.83333 8.19662 4.83333 7ZM7 3.83333C5.2511 3.83333 3.83333 5.2511 3.83333 7C3.83333 8.7489 5.2511 10.1667 7 10.1667C8.7489 10.1667 10.1667 8.7489 10.1667 7C10.1667 5.2511 8.7489 3.83333 7 3.83333Z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Light
