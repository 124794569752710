import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setDisabledCalculator } from 'redux/exam-portal/examPortalSlice'
// import '../../styles/assets/simulated-platform-ucat/calculator.scss'
import buttonGrip from '../../styles/assets/button-grip.png'
import '../../styles/assets/fonts/d7mono.ttf'
import Draggable from './Draggable'

const Calculator = ({
  closeCalculator,
  isOutsideExam,
  positionStart,
  divXWidth,
  divYHeight,
  eventType = 'keydown',
}) => {
  const calculatorRef = useRef(null)
  const { currentQuestionAttempt, showCalculator, disabledCalculator } =
    useSelector((state) => ({
      currentQuestionAttempt:
        state.examPortal.stageSubdata.currentQuestionAttempt,
      showCalculator: state.examPortal.showCalculator,
      disabledCalculator: state.examPortal.disabledCalculator,
    }))
  const prevId = currentQuestionAttempt && currentQuestionAttempt.id
  const minDimensions = { width: 288, height: 505 } // 20% increase from width: 240, height: 436

  const draggableIcon =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAATAQMAAAB1DMXpAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAAxJREFUeNpjYCATAAAAOQABEESVYQAAAABJRU5ErkJggg=='
  const dispatch = useDispatch()
  const [accessaryDisplaySymbol, setAccessaryDisplaySymbol] = useState('')
  const [prevVal, setPrevVal] = useState(null)
  const [pressedOp, setPressedOp] = useState(null)
  const [newInputToggle, setNewInputToggle] = useState(true)
  const [display, setDisplay] = useState('')
  const [storedOp, setStoredOp] = useState(null)
  const displayLength = 10
  const [mem, setMem] = useState(0)
  const [calcActive, setCalcActive] = useState(null)
  const [memIndicator, setMemIndicator] = useState('')
  const [isMrcClicked, setIsMrcClicked] = useState(false)

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        const activeElement = document.activeElement
        if (
          calculatorRef.current &&
          calculatorRef.current.contains(activeElement)
        ) {
          // Allow enter key inside the calculator
          return
        }
        event.preventDefault()
      }
    }

    window.addEventListener(eventType, handleKeyPress)

    return () => {
      window.removeEventListener(eventType, handleKeyPress)
    }
  }, [])

  const doTheMath = (op, a, b) => {
    switch (op) {
      case '+':
        return a + b
      case '-':
        return a - b
      case '÷':
        return a / b
      case '*':
        return a * b
      case '/':
        return a / b
      case '%':
        if (!a) return b
        if (storedOp === '+') return a + (a * b) / 100
        if (storedOp === '*') return (a * b) / 100
        if (storedOp === '÷') return (a / b) * 100
        if (storedOp === '-') return a - (a * b) / 100
        return (a * b) / 100
      case '=':
        return b
      default:
        return b
    }
  }

  const slashHandler = (e) => {
    if (e.code === 'Slash' || e.code === 'NumpadDivide') {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  useEffect(() => {
    if (showCalculator) {
      document.addEventListener(eventType, slashHandler)
    }
    return () => document.removeEventListener(eventType, slashHandler)
  }, [showCalculator])

  function truncateAns(num) {
    if (num === Infinity) {
      return 'CAN NOT PERFORM'
    } else if (num > Math.pow(10, displayLength - 1)) {
      return num
        .toExponential(displayLength - 5)
        .toString()
        .replace(/\.0+e/, 'e')
    } else if (num?.toString()?.length >= displayLength) {
      return parseFloat(
        num
          // .toFixed(displayLength - Math.round(num).toString().length - 1) commented code for now
          .toString()
          .replace(/\.0+e/, 'e'),
      )
    } else {
      return num
    }
  }
  const handleClickNumber = (e) => {
    let newInputToggle1 = newInputToggle
    let display1 = display
    if (newInputToggle) {
      display1 = ''
      newInputToggle1 = false
      setCalcActive(true)
      setStoredOp(pressedOp)
    }
    if (
      display1?.toString()?.length < displayLength &&
      (e !== '.' || (display1?.toString()?.match(/\./g) || []).length < 1)
    ) {
      display1 = (display1?.toString().replace(/^0+/, '') + e)?.replace(
        /^0*\./,
        '0.',
      )
    }
    setDisplay(display1)
    setNewInputToggle(newInputToggle1)
  }
  const handleCalculateEquation = (callback) => {
    let pressedOp1 = pressedOp
    let newInputToggle1 = newInputToggle
    let prevVal1 = prevVal
    let display1 = display
    let calcActive1 = calcActive
    newInputToggle1 = true
    if (!prevVal1) {
      if (storedOp) {
        prevVal1 = doTheMath(storedOp, 0, parseFloat(display1, 10))
        display1 = prevVal1
      } else {
        prevVal1 = parseFloat(display1, 10)
      }
      calcActive1 = false
    } else if (calcActive1) {
      if (display === '0' && pressedOp === '÷') {
        display1 = 'CANNOT DIV'
      } else {
        let matchRes = doTheMath(storedOp, prevVal1, parseFloat(display1, 10))
        display1 = truncateAns(Math.round(matchRes * 1e12) / 1e12)
      }
      calcActive1 = false
      if (pressedOp === '=') {
        prevVal1 = null
      } else {
        prevVal1 = parseFloat(display1, 10)
      }
    }
    setPressedOp(null)
    setNewInputToggle(newInputToggle1)
    setPrevVal(prevVal1)
    setDisplay(display1)
    setCalcActive(calcActive1)
    if (display1) {
      callback && callback(display1)
    }
  }
  const operatorClicked = (e) => {
    let pressedOp1 = pressedOp
    let newInputToggle1 = newInputToggle
    let prevVal1 = prevVal
    let display1 = display
    let calcActive1 = calcActive
    pressedOp1 = e
    newInputToggle1 = true
    if (!prevVal1) {
      if (storedOp) {
        prevVal1 = doTheMath(storedOp, 0, parseFloat(display1, 10))
        display1 = prevVal1
      } else {
        prevVal1 = parseFloat(display1, 10)
      }
      calcActive1 = false
    } else if (calcActive1) {
      if (display === '0' && pressedOp === '÷') {
        display1 = 'CANNOT DIV'
      } else {
        let matchRes = doTheMath(
          e === '%' ? '%' : storedOp,
          prevVal1,
          parseFloat(display1, 10),
        )
        display1 = truncateAns(Math.round(matchRes * 1e12) / 1e12)
      }
      calcActive1 = false
      if (pressedOp === '=') {
        prevVal1 = null
      } else {
        prevVal1 = parseFloat(display1, 10)
      }
    }
    setPressedOp(pressedOp1)
    setNewInputToggle(newInputToggle1)
    setPrevVal(prevVal1)
    setDisplay(display1)
    setCalcActive(calcActive1)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnKeyboardPress = (e) => {
    const key = e.key.toString()
    const backgroundColor = 'calcRedBtn'
    const isAltC = e.altKey && key.toLowerCase() === 'c'

    if (showCalculator && !disabledCalculator) {
      if (key === ' ') {
        e.preventDefault()
        return
      }
      if (isAltC) {
        handleClear('ON/C', backgroundColor)
      } else if (isNaN(key)) {
        switch (key) {
          case '/':
            handleKeyPress('÷', true, backgroundColor)
            break
          case '*':
            handleKeyPress('*', true, backgroundColor)
            break
          case '-':
            handleKeyPress(key, true, backgroundColor)
            break
          case '+':
            handleKeyPress(key, true, backgroundColor)
            break
          case '%':
            handleKeyPress(key, true, backgroundColor)
            break
          case '.':
            handleKeyPress(key, true, null)
            break
          case 'm':
            handleKeyPress(key, true, backgroundColor)
            break
          case 'p':
            handleKeyPress(key, true, backgroundColor)
            break
          case 'c':
            handleKeyPress(key, true, backgroundColor)
            break
          case 'x':
            handleSquareRoot(key, true, backgroundColor)
            break
          case '_':
            handleKeyPress(key, true, backgroundColor)
            break
          case 'Backspace':
            handleClear('ON/C', backgroundColor)
            break
          case 'Enter':
            handleCalculate('=', backgroundColor)
            break
          default:
            break
        }
      } else {
        handleClickNumber(e?.key)
        handleKeyPress(key, true)
      }
    }
  }
  const prevItemIdRef = useRef()
  useEffect(() => {
    prevItemIdRef.current = prevId
  })
  const prevItemId = prevItemIdRef.current

  useEffect(() => {
    if (prevItemId !== prevId) {
      setDisplay('')
      setPrevVal(null)
      setStoredOp(null)
      setNewInputToggle(true)
    }
    document.addEventListener(eventType, handleOnKeyboardPress)
    return () => {
      document.removeEventListener(eventType, handleOnKeyboardPress)
    }
  }, [showCalculator, handleOnKeyboardPress])
  const ChangeColorOnclick = (e, backgroundColor) => {
    const element = document.getElementById(`btn-${e}`)
    if (element) {
      element.classList.remove(backgroundColor)
      element.classList.add('calcOrangeBtn')

      setTimeout(() => {
        element.classList.add(backgroundColor)
        element.classList.remove('calcOrangeBtn')
      }, 150)
    }
  }
  const handleKeyPress = (e, isKeyboardPress, backgroundColor) => {
    ChangeColorOnclick(e, backgroundColor)
    let input
    let key = e
    if (isKeyboardPress && !disabledCalculator) {
      input = key
    } else {
      input =
        e.target?.children[0]?.value ||
        e.target?.offsetParent?.children[0].value ||
        e.target?.value
      if (typeof input === 'undefined') {
        input = ''
      }
    }
    if (!(input === 'c' || input === 'MRC') && isMrcClicked) {
      setIsMrcClicked(false)
    }
    if (input && isNaN(input)) {
      switch (input) {
        case '÷':
          operatorClicked('÷')
          break
        case 'x':
          operatorClicked('*')
          break
        case '*':
          operatorClicked('*')
          break

        case '+':
          operatorClicked('+')
          break

        case '-':
          operatorClicked('-')
          break
        case '%':
          operatorClicked('%')
          break
        case '.':
          handleClickNumber('.')
          break
        case '_':
          handleTogglePositivity('_')
          break
        case 'm':
          subtractFromMemory()
          break
        case 'p':
          addToMemory()
          break
        case 'c':
          handleMRC()
          break
        case ' ':
          return false
        case '':
          return false
        default:
          break
      }
    } else {
      if (!isNaN(input) && input !== '') {
        handleClickNumber(input)
      }
      if (input === ' ') {
        return false
      }
    }
  }
  const handleClear = (e, backgroundColor) => {
    setPrevVal(null)
    setStoredOp(null)
    setPressedOp(null)
    setNewInputToggle(true)
    setDisplay('')
    ChangeColorOnclick('Backspace', backgroundColor)
  }

  const handleClearBtn = (e, backgroundColor) => {
    setPrevVal(null)
    setStoredOp(null)
    setPressedOp(null)
    setNewInputToggle(true)
    setDisplay('')
    ChangeColorOnclick(e, backgroundColor)
    e.target.blur()
  }
  const handleCalculate = (e, backgroundColor) => {
    ChangeColorOnclick(e, backgroundColor)
    operatorClicked(e.key)
  }

  const handleSquareRoot = (e) => {
    e?.stopPropagation && e.stopPropagation()
    ChangeColorOnclick(e, 'calcRedBtn')
    setNewInputToggle(true)
    setDisplay(truncateAns(Math?.sqrt(display)))
    if (newInputToggle && prevVal) {
      setPrevVal(truncateAns(Math?.sqrt(display)))
    }
  }
  const handleTogglePositivity = (e, backgroundColor) => {
    e?.stopPropagation && e?.stopPropagation()
    ChangeColorOnclick(e, backgroundColor)
    setDisplay(0 - display)
    if (newInputToggle && prevVal) {
      setPrevVal(0 - prevVal)
    }
  }
  const handlePercentage = (e) => {
    e.stopPropagation()
    operatorClicked('%')
  }
  const addToMemory = (e) => {
    e?.stopPropagation && e.stopPropagation()
    handleCalculateEquation((display1) => {
      setMem((mem || 0) + parseFloat(display1, 10))
    })
    setNewInputToggle(true)
  }
  const subtractFromMemory = (e) => {
    e?.stopPropagation && e.stopPropagation()
    handleCalculateEquation((display1) => {
      setMem((mem || 0) - parseFloat(display1, 10))
    })
    setNewInputToggle(true)
  }
  const handleMRC = (e) => {
    e?.stopPropagation && e.stopPropagation()
    if (isMrcClicked) {
      setMem(0)
      setIsMrcClicked(false)
    } else {
      setIsMrcClicked(true)
      if (mem) {
        setDisplay(mem)
      }
      setNewInputToggle(true)
      setCalcActive(true)
      setStoredOp(pressedOp)
    }
  }
  useEffect(() => {
    if (
      display &&
      (display < 0 || display?.toString()?.charAt(0) === '-') &&
      accessaryDisplaySymbol === ''
    ) {
      setAccessaryDisplaySymbol('—')
    } else if (accessaryDisplaySymbol === '—' && display >= 0) {
      setAccessaryDisplaySymbol('')
    }
  }, [accessaryDisplaySymbol, display])
  useEffect(() => {
    if (mem) {
      setMemIndicator('M')
    } else {
      setMemIndicator('')
    }
  }, [mem])
  const handleResetState = () => {
    setPrevVal(null)
    setStoredOp(null)
    setPressedOp(null)
    setNewInputToggle(true)
    setDisplay('')
  }
  return (
    <div
      ref={calculatorRef}
      style={{ backgroundColor: '#006DAA' }}
      onClick={() => dispatch(setDisabledCalculator(false))}
    >
      <Draggable
        disabled={disabledCalculator}
        closeOnOutsideClick={true}
        closeOnOutsideHandler={() => dispatch(setDisabledCalculator(true))}
        isOutsideExam={isOutsideExam}
        onHide={(e) => {
          e.preventDefault()
          e.stopPropagation()
          handleResetState()
          closeCalculator && closeCalculator()
        }}
        show={showCalculator}
        title="Calculator"
        draggableIcon={draggableIcon}
        minDimensions={minDimensions}
        positionStart={positionStart}
        divXWidth={divXWidth}
        divYHeight={divYHeight}
      >
        <div className="calculator-container">
          <hr style={{ marginBottom: '1px' }} className="border-gray-700" />
          <div className="display-border border border-gray-700">
            <div className="display-screen text-black">
              <div className="flex flex-col font-semibold items-start justify-start ">
                <span className="h-3 w-3.5 -mt-1 text-sm ml-0.5">
                  {memIndicator}
                </span>
                <span className="h-3 w-3.5 text-3xl leading-6">
                  {accessaryDisplaySymbol}
                </span>
              </div>
              <input
                type="text"
                id="result"
                className="calcDisplay"
                readOnly
                maxLength={11}
                disabled
                value={
                  (display < 0
                    ? display?.toString()?.slice(1)
                    : display === 'CANNOT DIV'
                    ? display
                    : display?.toString()?.slice(0, 10) || '0') +
                  (display?.toString().includes('.') ? '' : '.')
                }
              />
            </div>
          </div>
          <hr style={{ marginTop: '1px' }} className="border-gray-700" />
          <div className="company-info">
            <div className="company-name">TEXAS INSTRUMENTS</div>
            <div className="model">TI-108</div>
          </div>
          {/* <div className="compensation-margin"></div> */}
          <div className="keypad-external-outliner">
            <table id="calcTable">
              <tbody onMouseDown={handleKeyPress}>
                <tr>
                  <td>
                    <div
                      id="btn-_"
                      className="input-container calcRedBtn"
                      onClick={handleTogglePositivity}
                    >
                      <input
                        className="calcRedBtn"
                        type="button"
                        defaultValue={'+/-'}
                      />{' '}
                      <img src={buttonGrip} />
                    </div>
                  </td>
                  <td>
                    <div
                      id="btn-x"
                      className="input-container calcRedBtn"
                      onClick={handleSquareRoot}
                    >
                      <input
                        className="calcRedBtn"
                        type="button"
                        defaultValue={'√'}
                      />{' '}
                      <img src={buttonGrip} />
                    </div>
                  </td>
                  <td>
                    <div
                      id="btn-%"
                      className="input-container calcRedBtn"
                      onClick={handlePercentage}
                    >
                      <input
                        className="calcRedBtn"
                        type="button"
                        defaultValue={'%'}
                      />{' '}
                      <img src={buttonGrip} />
                    </div>
                  </td>
                  <td>
                    <div id="btn-÷" className="input-container calcRedBtn">
                      <input
                        className="calcRedBtn"
                        type="button"
                        defaultValue="÷"
                      />
                      <img src={buttonGrip} />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div
                      id="btn-c"
                      className="input-container calcRedBtn"
                      onClick={handleMRC}
                    >
                      <input
                        className="redBtns"
                        type="button"
                        defaultValue={'MRC'}
                      />
                      <img src={buttonGrip} />{' '}
                    </div>
                  </td>
                  <td>
                    <div
                      id="btn-m"
                      className="input-container calcRedBtn"
                      onClick={subtractFromMemory}
                    >
                      <input
                        className="redBtns"
                        type="button"
                        defaultValue={'M —'}
                      />
                      <img src={buttonGrip} />{' '}
                    </div>
                  </td>
                  <td>
                    <div
                      id="btn-p"
                      className="input-container calcRedBtn"
                      onClick={addToMemory}
                    >
                      <input
                        className="redBtns"
                        type="button"
                        defaultValue={'M+'}
                      />
                      <img src={buttonGrip} />{' '}
                    </div>
                  </td>
                  <td>
                    <div id="btn-*" className="input-container calcRedBtn">
                      <input
                        className="calcRedBtn"
                        type="button"
                        defaultValue="x"
                      />
                      <img src={buttonGrip} />{' '}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div id="btn-7" className="input-container">
                      <input type="button" defaultValue={7} />
                      <img src={buttonGrip} />
                    </div>
                  </td>
                  <td>
                    <div id="btn-8" className="input-container">
                      <input type="button" defaultValue={8} />
                      <img src={buttonGrip} />
                    </div>
                  </td>
                  <td>
                    <div id="btn-9" className="input-container">
                      <input type="button" defaultValue={9} />{' '}
                      <img src={buttonGrip} />{' '}
                    </div>
                  </td>
                  <td>
                    <div id="btn--" className="input-container calcRedBtn">
                      <input
                        className="calcRedBtn"
                        type="button"
                        defaultValue="-"
                      />{' '}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div id="btn-4" className="input-container">
                      <input type="button" defaultValue={4} />
                      <img src={buttonGrip} />
                    </div>
                  </td>
                  <td>
                    <div id="btn-5" className="input-container">
                      <input type="button" defaultValue={5} />
                      <img src={buttonGrip} />
                    </div>
                  </td>
                  <td>
                    <div id="btn-6" className="input-container">
                      <input type="button" defaultValue={6} />
                      <img src={buttonGrip} />
                    </div>
                  </td>

                  <td>
                    <div id="btn-+" className="input-container calcRedBtn">
                      <input
                        className="calcRedBtn"
                        type="button"
                        defaultValue="+"
                      />{' '}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div id="btn-1" className="input-container">
                      <input type="button" defaultValue={1} />
                      <img src={buttonGrip} />
                    </div>
                  </td>
                  <td>
                    <div id="btn-2" className="input-container">
                      <input type="button" defaultValue={2} />{' '}
                      <img src={buttonGrip} />
                    </div>
                  </td>
                  <td>
                    <div id="btn-3" className="input-container">
                      <input type="button" defaultValue={3} />
                      <img src={buttonGrip} />
                    </div>
                  </td>
                  <td rowSpan="2">
                    <div
                      id="btn-="
                      className="input-container calcRedBtn equalSignContainer"
                      onClick={handleCalculate}
                    >
                      <input
                        type="button"
                        className="equalSign calcRedBtn"
                        defaultValue="="
                      />
                      <img src={buttonGrip} />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div
                      id="btn-Backspace"
                      className="input-container calcRedBtn"
                      onClick={handleClearBtn}
                    >
                      <input
                        className="calcRedBtn"
                        type="button"
                        defaultValue="ON/C"
                      />
                      <img src={buttonGrip} />
                    </div>
                  </td>
                  <td>
                    <div id="btn-0" className="input-container">
                      <input type="button" defaultValue={0} />{' '}
                      <img src={buttonGrip} />
                    </div>
                  </td>
                  <td>
                    <div id="btn-." className="input-container">
                      <input type="button" defaultValue="." />
                      <img src={buttonGrip} />
                    </div>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Draggable>
    </div>
  )
}

export default Calculator

const closeBtn = (
  <img
    className="sprite-icon sprite-over-white sprite-close-modal"
    alt="Close dialogue"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAAxJREFUeNpjYCANAAAAMAABKHRJfQAAAABJRU5ErkJggg=="
  />
)
