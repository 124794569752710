import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

export const getResourceQuestions = createAsyncThunk(
  'resourceQuestions/getResourceQuestions',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `interaction/resource-questions`,
        {
          signal: data?.abortController?.signal,
          params: {
            [data.questionTypeId]: data.bookChapterId,
            page: data.page,
            sort_by: data.sortBy,
            filter_resource_questions: data.filterResourceQuestions,
            resource_questions_search_text: data.resourceQuestionsSearchText
              ? data.resourceQuestionsSearchText
              : null,
          },
        },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getResourceAnswer = createAsyncThunk(
  'resourceQuestions/getResourceAnswer',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `interaction/resource-question/${data.questionId}/answers`,
        {
          params: {
            page: data.page,
          },
        },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const createResourceQuestion = createAsyncThunk(
  `resourceQuestions/createResourceQuestion`,
  async ({ data, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `interaction/resource-question`,
        data,
      )

      onSuccess && onSuccess(res?.data)
      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload)
      throw rejectWithValue(err.response)
    }
  },
)

export const createResourceAnswer = createAsyncThunk(
  `resourceQuestions/createResourceAnswer`,
  async ({ data, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `interaction/resource-question/${data.questionId}/answer`,
        { answer_text: data.answer_text },
      )

      onSuccess && onSuccess()
      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload)
      throw rejectWithValue(err.response)
    }
  },
)

export const setUpVoteResource = createAsyncThunk(
  `resourceQuestions/setUpVoteResource`,
  async ({ data, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `interaction/set-upvote-state`,
        {},
        {
          params: {
            state: data.state,
            type_id: data.questionId,
            type: data.questionsType,
          },
        },
      )

      onSuccess && onSuccess()
      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const setSubscriptionResource = createAsyncThunk(
  `resourceQuestions/setSubscriptionResource`,
  async ({ data, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `interaction/resource-question/${data.questionId}/set-subscription-state`,
        {},
        {
          params: {
            state: data.state,
          },
        },
      )

      onSuccess && onSuccess()
      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)
export const markResourceAgreement = createAsyncThunk(
  'discussion/markResourceAgreement',

  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        '/terms-conditions/accept?area=discussions',
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
const resourceQuestionsSlice = createSlice({
  name: 'resourceQuestions',
  initialState: {
    resourceQuestionsData: {
      isLoading: false,
      loadingSeeMore: false,
      data: null,
    },
    questionPageNumber: 1,
    answerPageNumber: 1,
    resourceAnswerData: { isLoading: false, loadingSeeMore: false, data: [] },
    createQuestionLoader: false,
    createAnswerLoader: false,
    selectedQuestion: {},
    upVoteLoadingId: null,
    subscriptionLoader: false,
    searchResourceQuestion: null,
    resourceDebounce: null,
    selectedSortBy: {
      id: 1,
      name: 'Recommended',
      sortByType: 'recommended',
    },
    selectedFilterQuestions: { id: 1, name: 'All', filterQuestion: null },
    resourceAgreementData: { isLoading: false, data: null },
    isDiscussionSubmission: false,
  },
  extraReducers: {
    [getResourceQuestions.pending]: (state, action) => {
      if (state.questionPageNumber === 1) {
        state.resourceQuestionsData.isLoading = true
      } else {
        state.resourceQuestionsData.loadingSeeMore = true
      }
    },
    [getResourceQuestions.fulfilled]: (state, action) => {
      if (state.questionPageNumber === 1) {
        state.resourceQuestionsData = {
          isLoading: false,
          data: action.payload.data,
          pagination: action.payload.pagination,
        }
      } else {
        state.resourceQuestionsData.data.questions = [
          ...state.resourceQuestionsData.data.questions,
          ...action.payload.data.questions,
        ]
        state.resourceQuestionsData.isLoading = false
        state.resourceQuestionsData.loadingSeeMore = false
        state.resourceQuestionsData.pagination = action.payload.pagination
      }
    },
    [getResourceQuestions.rejected]: (state, action) => {
      state.resourceQuestionsData.isLoading = false
      state.resourceQuestionsData.loadingSeeMore = false
    },

    [setUpVoteResource.pending]: (state, action) => {
      state.upVoteLoadingId = action.meta.arg.data.questionId
    },
    [setUpVoteResource.fulfilled]: (state, { meta, payload }) => {
      if (meta.arg.data.questionsType === 'question') {
        const questionIndex = findIndex(
          state.resourceQuestionsData.data.questions,
          { id: meta.arg.data.questionId },
        )
        if (questionIndex !== -1) {
          state.resourceQuestionsData.data.questions[
            questionIndex
          ].is_user_upvoted = payload?.data?.user_upvoted

          state.resourceQuestionsData.data.questions[
            questionIndex
          ].num_upvotes = payload?.data?.num_upvotes
        }

        if (
          state.selectedQuestion.id ===
          state.resourceQuestionsData.data.questions[questionIndex].id
        ) {
          state.selectedQuestion.is_user_upvoted = payload.data.user_upvoted
          state.selectedQuestion.num_upvotes = payload.data.num_upvotes
        }
      } else {
        const answerIndex = findIndex(state.resourceAnswerData.data.answers, {
          id: meta.arg.data.questionId,
        })
        state.resourceAnswerData.data.answers[answerIndex].is_user_upvoted =
          payload.data.user_upvoted
        state.resourceAnswerData.data.answers[answerIndex].num_upvotes =
          payload.data.num_upvotes
      }
      state.upVoteLoadingId = null
    },
    [setUpVoteResource.rejected]: (state, action) => {
      state.upVoteLoadingId = null
    },
    [setSubscriptionResource.pending]: (state, action) => {
      state.subscriptionLoader = true
    },
    [setSubscriptionResource.fulfilled]: (state, { meta, payload }) => {
      state.selectedQuestion.is_user_subscribed = payload.data.subscribed
      state.subscriptionLoader = false
    },
    [setSubscriptionResource.rejected]: (state, action) => {
      state.subscriptionLoader = false
    },
    [createResourceQuestion.pending]: (state, action) => {
      state.createQuestionLoader = true
    },
    [createResourceQuestion.fulfilled]: (state, { payload }) => {
      state.resourceQuestionsData.data.questions = [
        payload?.data?.question,
        ...state.resourceQuestionsData?.data?.questions,
      ]
      state.resourceQuestionsData.pagination.total += 1
      state.createQuestionLoader = false
      state.resourceDebounce = null
    },
    [createResourceQuestion.rejected]: (state, { payload }) => {
      state.createQuestionLoader = false
      if (payload?.status === 403) {
        state.resourceAgreementData.data = payload.data.data
      }
    },
    [getResourceAnswer.pending]: (state, action) => {
      if (state.answerPageNumber === 1) {
        state.resourceAnswerData.isLoading = true
      } else {
        state.resourceAnswerData.loadingSeeMore = true
      }
    },
    [getResourceAnswer.fulfilled]: (state, action) => {
      if (state.answerPageNumber === 1) {
        state.resourceAnswerData = {
          isLoading: false,
          data: action.payload.data,
          pagination: action.payload.pagination,
        }
      } else {
        state.resourceAnswerData.data.answers = [
          ...state.resourceAnswerData.data.answers,
          ...action.payload.data.answers,
        ]
        state.resourceAnswerData.isLoading = false
        state.resourceAnswerData.loadingSeeMore = false
        state.resourceAnswerData.pagination = action.payload.pagination
      }
    },
    [getResourceAnswer.rejected]: (state, action) => {
      state.resourceAnswerData.isLoading = false
      state.resourceAnswerData.loadingSeeMore = false
    },
    [createResourceAnswer.pending]: (state, action) => {
      state.createAnswerLoader = true
    },
    [createResourceAnswer.fulfilled]: (state, { payload }) => {
      state.createAnswerLoader = false
      state.resourceAnswerData.data.answers = [
        payload.data.answer,
        ...state.resourceAnswerData.data.answers,
      ]
      const answerIndex = findIndex(
        state.resourceQuestionsData.data.questions,
        {
          id: state.selectedQuestion.id,
        },
      )
      if (answerIndex !== -1) {
        state.resourceQuestionsData.data.questions[answerIndex].num_answers += 1
      }
      state.selectedQuestion.num_answers += 1
      state.resourceAnswerData.pagination.total += 1
    },
    [createResourceAnswer.rejected]: (state, { payload }) => {
      state.createAnswerLoader = false
      if (payload?.status === 403) {
        state.resourceAgreementData.data = payload.data.data
      }
    },
    [markResourceAgreement.pending]: (state, action) => {
      state.resourceAgreementData.isLoading = true
    },
    [markResourceAgreement.fulfilled]: (state, action) => {
      state.resourceAgreementData.isLoading = false
      state.resourceAgreementData.data.discussion_terms_agreed = true
    },
    [markResourceAgreement.rejected]: (state, { payload }) => {
      state.resourceAgreementData.isLoading = false
    },
  },

  reducers: {
    clearResourceQuestionsData: (state) => {
      state.resourceQuestionsData = {
        isLoading: false,
        loadingSeeMore: false,
        data: [],
      }
    },
    setDiscussionSubmission: (state, { payload }) => {
      state.isDiscussionSubmission = payload
    },
    setSelectedQuestion: (state, { payload }) => {
      state.selectedQuestion = payload
    },
    updateQuestionPageNumber: (state, { payload }) => {
      state.questionPageNumber = payload
    },
    updateAnswerPageNumber: (state, { payload }) => {
      state.answerPageNumber = payload
    },
    updateSearchResourceQuestion: (state, { payload }) => {
      state.searchResourceQuestion = payload
    },
    updateResourceDebounce: (state, { payload }) => {
      state.resourceDebounce = payload
    },
    updateSearchLoader: (state, { payload }) => {
      state.resourceQuestionsData.isLoading = payload
    },
    setSelectedSortBy: (state, { payload }) => {
      state.selectedSortBy = payload
    },
    setSelectedFilterQuestions: (state, { payload }) => {
      state.selectedFilterQuestions = payload
    },
    closeAgreementModal: (state) => {
      if (
        state?.resourceAgreementData?.data?.discussion_terms_agreed === false
      ) {
        state.resourceAgreementData.data.discussion_terms_agreed = true
      }
    },
  },
})

export const {
  setDiscussionSubmission,
  setSelectedQuestion,
  updateQuestionPageNumber,
  updateAnswerPageNumber,
  updateSearchResourceQuestion,
  updateResourceDebounce,
  updateSearchLoader,
  setSelectedSortBy,
  setSelectedFilterQuestions,
  closeAgreementModal,
  clearResourceQuestionsData,
} = resourceQuestionsSlice.actions

export default resourceQuestionsSlice.reducer
