const RatingStar = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99524 0.834717C8.18556 0.834717 8.35938 0.942767 8.44361 1.11344L10.3846 5.04627L14.7243 5.67522C14.9127 5.70253 15.0693 5.83451 15.1281 6.01562C15.1869 6.19673 15.1378 6.39552 15.0013 6.52834L11.8565 9.58949L12.598 13.9122C12.6302 14.0999 12.553 14.2895 12.3989 14.4014C12.2448 14.5132 12.0406 14.5278 11.8721 14.4391L7.99964 12.3984L4.11792 14.4393C3.94946 14.5278 3.74532 14.5131 3.59135 14.4012C3.43737 14.2894 3.36026 14.0998 3.39244 13.9122L4.13395 9.58949L0.989149 6.52834C0.852701 6.39552 0.803539 6.19673 0.862352 6.01562C0.921165 5.83451 1.07774 5.70253 1.26619 5.67522L5.60592 5.04627L7.54687 1.11344C7.6311 0.942767 7.80492 0.834717 7.99524 0.834717Z"
      fill="currentColor"
    />
  </svg>
)

export default RatingStar
