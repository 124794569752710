const Line = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 2"
    fill="current"
    // stroke="currentColor"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0.500002L7.33333 0.5H7.5H13C13.2761 0.5 13.5 0.723858 13.5 1C13.5 1.27614 13.2761 1.5 13 1.5H7.5H7.33333H7H6.5H1C0.723858 1.5 0.5 1.27614 0.5 1C0.5 0.723858 0.723858 0.5 1 0.5H6.5H6.70959L7 0.500002Z"
      fill="current"
    />
  </svg>
)

export default Line
