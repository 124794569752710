const ChatIcon = ({ className, ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className={`${className}`}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.71155 3.94461C6.77507 2.48181 9.74923 2.60524 11.6474 4.22317L4.71155 3.94461ZM11.6474 4.22317C13.5229 5.82249 13.7636 8.37577 12.2422 10.221C10.7277 12.0576 7.90525 12.6595 5.59678 11.6103C5.41984 11.5299 5.21545 11.5017 5.01281 11.5448L2.99275 11.9744L3.48518 10.4979C3.57709 10.2223 3.5296 9.93288 3.38946 9.70487C2.20684 7.78066 2.72562 5.35308 4.71148 3.94467M4.16026 3.16711C6.5742 1.45583 10.0305 1.59259 12.2657 3.4978L12.2657 3.49785C14.5234 5.42295 14.8414 8.56684 12.9775 10.8273C11.1687 13.021 7.87426 13.6916 5.20376 12.4787L3.13668 12.9182C2.43979 13.0664 1.8456 12.4014 2.07102 11.7255L2.57811 10.2051C2.57792 10.2047 2.5777 10.2043 2.57745 10.2039C1.11356 7.82211 1.79394 4.8453 4.16018 3.16716L4.16026 3.16711Z"
      fill="currentColor"
    />
  </svg>
)

export default ChatIcon
