import React from 'react'

const ForwardIcon = ({ className }) => (
  <svg
    width="14"
    height="7"
    viewBox="0 0 14 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
  >
    <path
      d="M14 0.499993V4.49999C14 4.6326 13.9473 4.75978 13.8536 4.85355C13.7598 4.94731 13.6326 4.99999 13.5 4.99999H9.5C9.36739 4.99999 9.24021 4.94731 9.14645 4.85355C9.05268 4.75978 9 4.6326 9 4.49999C9 4.36738 9.05268 4.24021 9.14645 4.14644C9.24021 4.05267 9.36739 3.99999 9.5 3.99999H12.2875L10.89 2.61187C10.1209 1.84242 9.14092 1.31834 8.07396 1.10591C7.007 0.893485 5.901 1.00225 4.89586 1.41845C3.89072 1.83465 3.03158 2.53959 2.42712 3.44411C1.82265 4.34863 1.50001 5.41209 1.5 6.49999C1.5 6.6326 1.44732 6.75978 1.35355 6.85355C1.25979 6.94731 1.13261 6.99999 1 6.99999C0.867392 6.99999 0.740215 6.94731 0.646447 6.85355C0.552678 6.75978 0.5 6.6326 0.5 6.49999C0.499978 5.2144 0.881184 3.95767 1.59541 2.88873C2.30964 1.81979 3.32481 0.986649 4.51254 0.49467C5.70028 0.00269157 7.00723 -0.126031 8.26812 0.124783C9.52901 0.375596 10.6872 0.994679 11.5962 1.90374L13 3.29812V0.499993C13 0.367385 13.0527 0.240208 13.1464 0.14644C13.2402 0.0526714 13.3674 -7.15145e-06 13.5 -7.15145e-06C13.6326 -7.15145e-06 13.7598 0.0526714 13.8536 0.14644C13.9473 0.240208 14 0.367385 14 0.499993Z"
      fill="currentColor"
    />
  </svg>
)

export default ForwardIcon
