import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getUniversitySpecificData = createAsyncThunk(
  'universitySpecific/getUniversitySpecificData',
  async () => {
    return await axiosInterceptors.get(
      'interviews/university-specific-resources',
    )
  },
)

const universitySpecificSlice = createSlice({
  name: 'universitySpecific',
  initialState: {
    universityData: { isLoading: false, data: [] },
  },
  extraReducers: {
    [getUniversitySpecificData.pending]: (state) => {
      state.universityData.isLoading = true
    },
    [getUniversitySpecificData.fulfilled]: (state, { payload }) => {
      state.universityData.isLoading = false
      state.universityData.data = payload.data.resources
    },

    [getUniversitySpecificData.rejected]: (state) => {
      state.universityData.isLoading = false
    },
  },
  reducers: {},
})

export default universitySpecificSlice.reducer
