const RoundedBadgeOuter = ({ className, border }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="current"
    viewBox="0 0 88 88"
    stroke={border}
    className={`h-4 w-4 ${className}`}
  >
    <circle cx="44" cy="44" r="43.5" fill="current" />
  </svg>
)

export default RoundedBadgeOuter
