const Video = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.5 5.333c0-.46.373-.833.833-.833h5.334c.46 0 .833.373.833.833v5.334c0 .46-.373.833-.833.833H3.333a.833.833 0 01-.833-.833V5.333zm8 4.81v.524c0 1.012-.82 1.833-1.833 1.833H3.333A1.833 1.833 0 011.5 10.667V5.333c0-1.012.82-1.833 1.833-1.833h5.334c1.012 0 1.833.82 1.833 1.833v.525l2.312-1.156A1.167 1.167 0 0114.5 5.745v4.51a1.166 1.166 0 01-1.688 1.043L10.5 10.142zm0-1.119l2.759 1.38a.166.166 0 00.241-.15V5.746a.167.167 0 00-.241-.15L10.5 6.977v2.048z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default Video
