import * as PropTypes from 'prop-types'

const Button = ({
  children,
  outlined,
  outlineNone,
  className,
  disabled,
  ...props
}) => {
  return (
    <button
      className={`btn-primary ${outlined ? 'btn-outlined' : ''} ${
        outlineNone ? 'btn-outline-none' : ''
      } ${className}`}
      {...props}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  outlined: PropTypes.bool,
}

export default Button
