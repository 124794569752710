import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'
import { fetchToken } from '../../firebase'
export const getPersonalInfo = createAsyncThunk(
  'personalInfo/getPersonalInfo',
  async (data) => {
    return await axiosInterceptors.get(`account/personal-info`)
  },
)
export const updateFirebase = createAsyncThunk(
  'auth/updateFirebase',
  async ({ token, decodedToken }, thunkAPI) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/app-utilities/firebase-key/${token}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${decodedToken}`,
            Accept: 'application/json',
          },
        },
      )
      return response
    } catch (err) {
      throw thunkAPI.rejectWithValue(err.response.data)
    }
  },
)
export const logoutUser = createAsyncThunk(
  'auth/logout',
  async ({ onSuccess, onError }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(
        `${process.env.REACT_APP_API_URL}/logout`,
      )
      onSuccess && onSuccess()
      return response
    } catch (e) {
      onError && onError()
      throw thunkAPI.rejectWithValue(e.response.data)
    }
  },
)
export const loginUser = createAsyncThunk(
  'personalInfo/login',
  async ({ email, password, browserInfo }, thunkAPI) => {
    const token =
      'Notification' in window && Notification.permission === 'granted'
        ? await fetchToken()
        : null
    const cohort = process.env.REACT_APP_USER_COHORT || undefined
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          email,
          password,
          firebase_key: token || null,
          from_website_cohort: cohort,
          device: browserInfo,
        },
      )

      return response?.data
    } catch (e) {
      throw thunkAPI.rejectWithValue(e.response.data)
    }
  },
)
export const uploadProfileImage = createAsyncThunk(
  'personalInfo/uploadProfileImage',
  async ({ avatarImage, setFieldValue, onError }, { rejectWithValue }) => {
    const formData = new FormData()
    formData.append('avatar_image', avatarImage)
    try {
      const res = await axiosInterceptors.post(
        `account/avatar-image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      setFieldValue && setFieldValue('avatar_id', res.data.id)
      setFieldValue && setFieldValue('avatar_url', res.data.url)

      return res
    } catch (err) {
      const profileInfoError = rejectWithValue(err.response.data)
      onError && onError(profileInfoError.payload.errors)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const saveProfileInfo = createAsyncThunk(
  'personalInfo/saveProfileInfo',
  async ({ data, onSuccess, onError }, { rejectWithValue }) => {
    const profileInfo = {
      ...data,
      country: data.country.name,
      state: data.state.name,
    }
    try {
      const res = await axiosInterceptors.put(
        `account/update-personal-info`,
        profileInfo,
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      const profileInfoError = rejectWithValue(err.response.data)
      onError && onError(profileInfoError.payload.errors)
      throw rejectWithValue(err.response.data)
    }
  },
)
const personalInfoSlice = createSlice({
  name: 'personalInfo',
  initialState: {
    personalInformation: { isLoading: false, data: [] },
    updatedProfileInfo: {},
    errorMessage: null,
    myAvatar: { isLoading: false, data: null },
    saveProfileLoading: false,
    toggleProfileMenu: false,
    saveProfile: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    userData: JSON?.parse(localStorage.getItem('user-data')) || {},
    olpPath: process.env.REACT_APP_OLP_TYPE || 'ucat',
    logoutLoading: false,
    wrongUrlError: false,
    showCelebrationPopup: false,
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.isFetching = true
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      if (payload?.data?.user) {
        localStorage.setItem('bearer_token', payload?.data?.user?.bearer_token)
        localStorage.setItem('user-data', JSON.stringify(payload?.data?.user))
      }
      state.userData = payload?.data?.user
      state.email = payload?.email
      state.password = payload?.password
      state.isFetching = false
      state.isSuccess = true
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.isError = true
      state.errorMessage = payload
    },
    [logoutUser.pending]: (state) => {
      state.logoutLoading = true
    },
    [logoutUser.fulfilled]: (state) => {
      state.logoutLoading = false
    },
    [logoutUser.rejected]: (state, { payload }) => {
      state.logoutLoading = false
      toast.error(payload?.message || 'Something went wrong', {
        duration: 3000,
        style: { maxWidth: 'auto' },
      })
    },
    [getPersonalInfo.pending]: (state, action) => {
      state.personalInformation.isLoading = true
    },
    [getPersonalInfo.fulfilled]: (state, { payload }) => {
      state.personalInformation.data = payload.data
      state.personalInformation.isLoading = false
    },
    [getPersonalInfo.rejected]: (state, action) => {
      state.personalInformation.isLoading = false
    },
    [uploadProfileImage.pending]: (state, action) => {
      state.myAvatar.isLoading = true
    },
    [uploadProfileImage.fulfilled]: (state, { payload }) => {
      state.myAvatar.data = payload.data
      state.updatedProfileInfo['avatar_id'] = payload.data.id
      state.updatedProfileInfo['avatar_url'] = payload.data.url
    },
    [uploadProfileImage.rejected]: (state, action) => {
      state.myAvatar.isLoading = false
    },
    [saveProfileInfo.pending]: (state, action) => {
      state.saveProfileLoading = true
    },
    [saveProfileInfo.fulfilled]: (state, { payload }) => {
      state.saveProfileLoading = false
      state.personalInformation.data.info = payload.data.info
      const updatedUserData = {
        ...state.userData,
        first_name: payload.data.info.first_name,
        last_name: payload.data.info.last_name,
        name: `${payload.data.info.first_name} ${payload.data.info.last_name}`,
        avatar_url: payload.data.info.avatar_url,
      }
      state.userData = updatedUserData
      localStorage.setItem('user-data', JSON.stringify(updatedUserData))
    },
    [saveProfileInfo.rejected]: (state, action) => {
      state.saveProfileLoading = false
      state.myAvatar.isLoading = false
    },
  },
  reducers: {
    setShowCelebrationPopup: (state, { payload }) => {
      state.showCelebrationPopup = payload
    },
    setWrongUrlError: (state, { payload }) => {
      state.wrongUrlError = payload
    },
    updatePersonalInfo: (state, { payload }) => {
      state.userData = payload
    },
    setMyAvatar: (state, { payload }) => {
      state.myAvatar.data = payload
    },

    setSaveProfile: (state, { payload }) => {
      state.saveProfile = payload
    },
    setUpdatedProfileInfo: (state, { payload }) => {
      state.updatedProfileInfo[payload.key] = payload.value
    },
    clearUpdatedProfileInfo: (state, { payload }) => {
      state.updatedProfileInfo = {}
    },
    setToggleProfileMenu: (state, { payload }) => {
      state.toggleProfileMenu = payload
    },
    clearError: (state) => {
      state.errorMessage = null
      state.wrongUrlError = false
    },
    setLoginErrorMessage: (state, { payload }) => {
      state.errorMessage = payload
    },
    clearState: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isFetching = false

      return state
    },
  },
})

export const {
  setShowCelebrationPopup,
  setLoginErrorMessage,
  setWrongUrlError,
  setMyAvatar,
  setSaveProfile,
  setToggleProfileMenu,
  setUpdatedProfileInfo,
  clearUpdatedProfileInfo,
  updatePersonalInfo,
  clearState,
  clearError,
} = personalInfoSlice.actions

export default personalInfoSlice.reducer
