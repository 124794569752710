const Veendaigram = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="current"
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      fill="current"
      fillRule="evenodd"
      d="M7.252 9.375c.403-.082.82-.125 1.248-.125a6.22 6.22 0 013.5 1.071 6.22 6.22 0 013.5-1.071c.428 0 .845.043 1.248.125a4.75 4.75 0 00-9.496 0zm3.57 1.98c-.564.637-1 1.389-1.269 2.217a4.762 4.762 0 01-2.103-2.705 4.765 4.765 0 013.372.488zM12 12.29c.478.52.842 1.15 1.05 1.844a4.765 4.765 0 01-2.1 0A4.743 4.743 0 0112 12.29zm2.447 1.283a6.244 6.244 0 00-1.269-2.217 4.765 4.765 0 013.372-.489 4.762 4.762 0 01-2.103 2.706zm-1.199 2.053a6.277 6.277 0 01-2.496 0A4.732 4.732 0 0012 18.711a4.732 4.732 0 001.248-3.086zm-.07 4.02a6.227 6.227 0 001.56-4.525 6.268 6.268 0 003.209-3.692 4.75 4.75 0 01-4.769 8.217zM12 20.679A6.25 6.25 0 1018.239 9.88a6.25 6.25 0 10-12.477 0A6.25 6.25 0 1012 20.678zm-1.178-1.034a4.75 4.75 0 01-4.769-8.217 6.268 6.268 0 003.208 3.692 6.227 6.227 0 001.56 4.525z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default Veendaigram
