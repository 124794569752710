const Rectangle = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 12"
    fill="current"
    // stroke="currentColor"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.33333 1.8335C1.8731 1.8335 1.5 2.20659 1.5 2.66683V9.3335C1.5 9.79373 1.8731 10.1668 2.33333 10.1668H11.6667C12.1269 10.1668 12.5 9.79373 12.5 9.3335V2.66683C12.5 2.20659 12.1269 1.8335 11.6667 1.8335H2.33333ZM0.5 2.66683C0.5 1.65431 1.32081 0.833496 2.33333 0.833496H11.6667C12.6792 0.833496 13.5 1.65431 13.5 2.66683V9.3335C13.5 10.346 12.6792 11.1668 11.6667 11.1668H2.33333C1.32081 11.1668 0.5 10.346 0.5 9.3335V2.66683Z"
      fill="current"
    />
  </svg>
)

export default Rectangle
