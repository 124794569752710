import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'

export const getGroupStatus = createAsyncThunk(
  'group/getGroupStatus',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/group/get-group-status`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const createGroup = createAsyncThunk(
  'group/createGroup',
  async ({ emails, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/group/create-new-group`,
        {},
        {
          params: {
            invited: emails,
          },
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const joinGroup = createAsyncThunk(
  'group/joinGroup',
  async ({ groupNumber, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/group/join-existing-group`,
        {},
        {
          params: {
            group_number: groupNumber,
          },
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const leaveGroup = createAsyncThunk(
  'group/leaveGroup',
  async ({ groupNumber, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/group/leave-group`,
        {},
        {
          params: {
            group_number: groupNumber,
          },
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const cancelInvitation = createAsyncThunk(
  'group/cancelInvitation',
  async ({ groupNumber, invitedEmail, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/group/cancel-invitation`,
        {},
        {
          params: {
            group_number: groupNumber,
            invited_email: invitedEmail,
          },
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const inviteFriend = createAsyncThunk(
  'group/inviteFriend',
  async ({ groupNumber, emails, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/group/invite-friends`,
        {},
        {
          params: {
            group_number: groupNumber,
            invited: emails,
          },
        },
      )
      onSuccess && onSuccess()

      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

const groupSlice = createSlice({
  name: 'group',
  initialState: {
    createGroupPopup: false,
    joinGroupPopup: false,
    groupStatus: {
      isLoading: false,
      data: {},
    },
    creatingGroup: { isLoading: false, isRejected: false, error: '' },
    joiningGroup: { isLoading: false, isRejected: false, error: '' },
    leavingGroup: { isLoading: false, isRejected: false, error: '' },
    cancellingInvitation: {
      email: null,
      isLoading: false,
      isRejected: false,
      error: '',
    },
    invitingFriend: { isLoading: false, isRejected: false, error: '' },
  },
  extraReducers: {
    [cancelInvitation.pending]: (state, action) => {
      state.cancellingInvitation.email = action.meta.arg.invitedEmail
      state.cancellingInvitation.isLoading = true
    },
    [cancelInvitation.fulfilled]: (state, { meta, payload }) => {
      state.cancellingInvitation.isLoading = false
      state.groupStatus.data.group.invited =
        state.groupStatus.data.group.invited.filter(
          (email) => email !== meta.arg.invitedEmail,
        )
      toast.success(payload.data[0], {
        duration: 4000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '350px',
        },
      })
    },
    [cancelInvitation.rejected]: (state, { payload }) => {
      state.cancellingInvitation.error = payload.error
      toast.error(payload.error[0], {
        duration: 4000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '350px',
        },
      })
      state.cancellingInvitation.isLoading = false
    },
    [getGroupStatus.pending]: (state, action) => {
      state.groupStatus.isLoading = true
    },
    [getGroupStatus.fulfilled]: (state, { payload }) => {
      state.groupStatus = {
        isLoading: false,
        data: payload.data,
      }
    },
    [getGroupStatus.rejected]: (state, action) => {
      state.groupStatus.isLoading = false
    },
    [createGroup.pending]: (state, action) => {
      state.creatingGroup.isLoading = true
    },
    [createGroup.fulfilled]: (state, { payload }) => {
      state.creatingGroup.isLoading = false
      if (payload.data && payload.data.error) {
        state.creatingGroup.error = payload.data.error
      } else {
        state.createGroupPopup = false
      }
    },
    [createGroup.rejected]: (state, action) => {
      state.creatingGroup.error = ['Group can not be created']
      state.creatingGroup.isLoading = false
    },
    [inviteFriend.pending]: (state, action) => {
      state.invitingFriend.isLoading = true
    },
    [inviteFriend.fulfilled]: (state, { payload }) => {
      state.invitingFriend.isLoading = false
      if (payload.data && payload.data.error) {
        state.invitingFriend.error = payload.data.error
      } else {
        state.createGroupPopup = false
      }
    },
    [inviteFriend.rejected]: (state, action) => {
      state.invitingFriend.error = ['Can not be invited']
      state.isRejected = true
      state.invitingFriend.isLoading = false
    },
    [joinGroup.pending]: (state, action) => {
      state.joiningGroup.isLoading = true
    },
    [joinGroup.fulfilled]: (state, { payload }) => {
      state.joiningGroup.isLoading = false
      if (payload.data && payload.data.error) {
        state.joiningGroup.error = payload.data.error
      } else {
        state.joinGroupPopup = false
      }
    },
    [joinGroup.rejected]: (state, { payload }) => {
      state.joiningGroup.isLoading = false
      state.joiningGroup.error = payload.error
    },
    [leaveGroup.pending]: (state, action) => {
      state.leavingGroup.isLoading = true
    },
    [leaveGroup.fulfilled]: (state, { payload }) => {
      state.leavingGroup.isLoading = false
      toast.success(payload.data, {
        duration: 4000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '350px',
        },
      })
    },
    [leaveGroup.rejected]: (state, { payload }) => {
      state.leavingGroup.error = payload.error
      toast.error(payload.error[0] || payload.message, {
        duration: 4000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '350px',
        },
      })
      state.leavingGroup.isLoading = false
    },
  },
  reducers: {
    clearCreateGroupError: (state) => {
      state.creatingGroup.error = ''
      state.creatingGroup.error = ''
    },
    clearJoinGroupError: (state) => {
      state.joiningGroup.error = ''
    },
    updateCreateGroupPopup: (state, { payload }) => {
      state.createGroupPopup = payload
    },
    updateJoinGroupPopup: (state, { payload }) => {
      state.joinGroupPopup = payload
    },
  },
})

export const {
  clearCreateGroupError,
  clearJoinGroupError,
  updateCreateGroupPopup,
  updateJoinGroupPopup,
} = groupSlice.actions

export default groupSlice.reducer
