import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'

export const getPersonalisedBooking = createAsyncThunk(
  'personalisedBooking/getPersonalisedBookings',
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/bookings/get-my-pmit-bookings`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err?.response?.data)
    }
  },
)

export const submitPersonaliseBooking = createAsyncThunk(
  'personalisedBooking/submitPersonaliseBooking',
  async ({ params, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/bookings/save-personal-interview-preferences`,
        {},
        { params },
      )
      return res
    } catch (err) {
      const errorType = rejectWithValue(err?.response?.data)
      onError && onError(errorType?.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

const bookingSlice = createSlice({
  name: 'personalisedBooking',
  initialState: {
    personalisedBookingsData: { isLoading: false, data: null, error: null },
    selectedBooking: null,
    bookingLoader: false,
  },
  extraReducers: {
    [getPersonalisedBooking.pending]: (state) => {
      state.personalisedBookingsData.isLoading = true
    },
    [getPersonalisedBooking.fulfilled]: (state, { payload }) => {
      state.personalisedBookingsData.isLoading = false

      state.personalisedBookingsData.data = payload.data
    },
    [getPersonalisedBooking.rejected]: (state, { payload }) => {
      state.personalisedBookingsData.isLoading = false
      state.personalisedBookingsData.error = payload
    },
    [submitPersonaliseBooking.pending]: (state, action) => {
      state.bookingLoader = true
    },
    [submitPersonaliseBooking.fulfilled]: (state, { payload }) => {
      state.selectedBooking = null
      state.bookingLoader = false
      toast.success(payload?.message, {
        duration: 4000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '350px',
        },
      })
    },
    [submitPersonaliseBooking.rejected]: (state, { payload }) => {
      toast.error(
        payload?.message || 'Something went wrong, Please try again later',
        {
          duration: 3000,
          style: { maxWidth: 'auto' },
        },
      )
      state.bookingLoader = false
    },
  },
  reducers: {
    clearPersonalisedBooking: (state) => {
      state.personalisedBookingsData = {
        isLoading: false,
        data: null,
        error: null,
      }
      state.selectedBooking = null
      state.bookingLoader = false
    },
    setSelectedBooking: (state, { payload }) => {
      state.selectedBooking = payload
    },
  },
})

export const { setSelectedBooking, clearPersonalisedBooking } =
  bookingSlice.actions

export default bookingSlice.reducer
