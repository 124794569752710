const Trophy = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 14 14"
    className={`h-3.5 w-3.5 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.16667 0.666656C3.16667 0.390514 3.39052 0.166656 3.66667 0.166656H10.3333C10.6095 0.166656 10.8333 0.390514 10.8333 0.666656V2.36656C11.0834 2.23875 11.3666 2.16666 11.6667 2.16666C12.6792 2.16666 13.5 2.98747 13.5 3.99999C13.5 5.01251 12.6792 5.83332 11.6667 5.83332C11.3666 5.83332 11.0834 5.76123 10.8333 5.63342V5.99999C10.8333 7.01665 10.4295 7.99168 9.71058 8.71057C9.11057 9.31057 8.33214 9.69113 7.5 9.80059V11.5H9.66667C9.94281 11.5 10.1667 11.7238 10.1667 12C10.1667 12.2761 9.94281 12.5 9.66667 12.5H7H4.33333C4.05719 12.5 3.83333 12.2761 3.83333 12C3.83333 11.7238 4.05719 11.5 4.33333 11.5H6.5V9.80059C5.66786 9.69113 4.88943 9.31057 4.28942 8.71057C3.57053 7.99168 3.16667 7.01665 3.16667 5.99999V5.63342C2.91664 5.76123 2.63341 5.83332 2.33333 5.83332C1.32081 5.83332 0.5 5.01251 0.5 3.99999C0.5 2.98747 1.32081 2.16666 2.33333 2.16666C2.63341 2.16666 2.91664 2.23875 3.16667 2.36656V0.666656ZM9.83333 3.99999V1.16666H4.16667V3.99999V5.99999C4.16667 6.75144 4.46518 7.47211 4.99653 8.00346C5.52788 8.53481 6.24855 8.83332 7 8.83332C7.75145 8.83332 8.47212 8.53481 9.00347 8.00346C9.53482 7.47211 9.83333 6.75144 9.83333 5.99999V3.99999ZM10.8333 3.99999C10.8333 4.46023 11.2064 4.83332 11.6667 4.83332C12.1269 4.83332 12.5 4.46023 12.5 3.99999C12.5 3.53975 12.1269 3.16666 11.6667 3.16666C11.2064 3.16666 10.8333 3.53975 10.8333 3.99999ZM2.33333 3.16666C2.79357 3.16666 3.16667 3.53975 3.16667 3.99999C3.16667 4.46023 2.79357 4.83332 2.33333 4.83332C1.8731 4.83332 1.5 4.46023 1.5 3.99999C1.5 3.53975 1.8731 3.16666 2.33333 3.16666Z"
      fill="currentColor"
    />
  </svg>
)
export default Trophy
