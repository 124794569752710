const Shape = ({ className }) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6869 0.313195C11.8822 0.508457 11.8822 0.825039 11.6869 1.0203L5.02026 7.68697C4.825 7.88223 4.50842 7.88223 4.31315 7.68697L0.979821 4.35363C0.784558 4.15837 0.784558 3.84179 0.979821 3.64653C1.17508 3.45127 1.49167 3.45127 1.68693 3.64653L4.66671 6.62631L10.9798 0.313195C11.1751 0.117933 11.4917 0.117933 11.6869 0.313195Z"
      fill="currentColor"
    />
  </svg>
)

export default Shape
