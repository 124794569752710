const Alarm = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="19"
    fill="current"
    viewBox="0 0 16 19"
    className={`${className}`}
  >
    <path
      fill="current"
      fillRule="evenodd"
      d="M5 1.25A.75.75 0 015.75.5h4.5a.75.75 0 010 1.5H9v1.25a.76.76 0 01-.003.064c1.48.19 2.83.798 3.927 1.701l.546-.545a.75.75 0 111.06 1.06l-.545.546A7.75 7.75 0 117.5 3.266V2H5.75A.75.75 0 015 1.25zm3 3.5a6.25 6.25 0 100 12.5 6.25 6.25 0 000-12.5zm0 2.5a.75.75 0 01.75.75v2.25H10a.75.75 0 010 1.5H8a.75.75 0 01-.75-.75V8A.75.75 0 018 7.25z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default Alarm
