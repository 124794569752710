const Edit = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 2.614c-.633 0-1.24.252-1.687.7l-.666.666-6.334 6.333a.5.5 0 00-.146.354v2.666a.5.5 0 00.5.5h2.666a.5.5 0 00.354-.146l6.332-6.332.001-.001.001-.001.666-.666A2.386 2.386 0 0011 2.614zm.667 3.679l.313-.313a1.386 1.386 0 00-1.96-1.96l-.313.313 1.96 1.96zM9 5.04l-5.833 5.834v1.96h1.96L10.96 7 9 5.04z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default Edit
