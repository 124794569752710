const Time = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="currentColor"
    viewBox="0 0 15 15"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 .5a.5.5 0 01.5.5v.833h.833a1.833 1.833 0 011.834 1.834v2.666a.5.5 0 01-.5.5H1.5v4.834a.833.833 0 00.833.833h4.53a.5.5 0 010 1h-4.53A1.833 1.833 0 01.5 11.667v-8a1.833 1.833 0 011.833-1.834h.834V1a.5.5 0 111 0v.833H8.5V1A.5.5 0 019 .5zm2.167 3.167v2.166H1.5V3.667a.833.833 0 01.833-.834h.834v.834a.5.5 0 101 0v-.834H8.5v.834a.5.5 0 001 0v-.834h.833a.833.833 0 01.834.834zM8.833 11a2.167 2.167 0 114.334 0 2.167 2.167 0 01-4.334 0zM11 7.833a3.167 3.167 0 100 6.334 3.167 3.167 0 000-6.334zm.5 2.164a.5.5 0 00-1 0V11a.5.5 0 00.146.354l.667.666a.5.5 0 10.707-.707l-.52-.52v-.796z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default Time
