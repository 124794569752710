const Popup = ({ className }) => (
  <svg
    className={`h-4 w-4 ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 509 511.54"
    fill="currentColor"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 2.614c-.633 0-1.24.252-1.687.7l-.666.666-6.334 6.333a.5.5 0 00-.146.354v2.666a.5.5 0 00.5.5h2.666a.5.5 0 00.354-.146l6.332-6.332.001-.001.001-.001.666-.666A2.386 2.386 0 0011 2.614zm.667 3.679l.313-.313a1.386 1.386 0 00-1.96-1.96l-.313.313 1.96 1.96zM9 5.04l-5.833 5.834v1.96h1.96L10.96 7 9 5.04z"
      clipRule="evenodd"
    ></path>

    <path
      fillRule="nonzero"
      d="M447.19 347.03c0-17.06 13.85-30.91 30.91-30.91 17.05 0 30.9 13.85 30.9 30.91v87.82c0 21.08-8.63 40.29-22.51 54.18-13.88 13.88-33.09 22.51-54.18 22.51H76.69c-21.09 0-40.3-8.63-54.18-22.51C8.63 475.14 0 455.93 0 434.85V76.69c0-21.09 8.63-40.3 22.51-54.18C36.39 8.63 55.6 0 76.69 0h86.98c17.06 0 30.9 13.85 30.9 30.9 0 17.06-13.84 30.91-30.9 30.91H76.69c-4.07 0-7.82 1.69-10.51 4.37-2.68 2.69-4.37 6.44-4.37 10.51v358.16c0 4.06 1.69 7.82 4.37 10.5 2.69 2.68 6.44 4.38 10.51 4.38h355.62c4.07 0 7.82-1.7 10.51-4.38 2.68-2.68 4.37-6.44 4.37-10.5v-87.82zm0-243.56L308.15 244.28c-11.91 12.12-31.45 12.28-43.56.37-12.11-11.91-12.28-31.45-.37-43.56L401.77 61.81H309.7c-17.06 0-30.9-13.85-30.9-30.91 0-17.05 13.84-30.9 30.9-30.9h168.4C495.15 0 509 13.85 509 30.9v165.04c0 17.06-13.85 30.9-30.9 30.9-17.06 0-30.91-13.84-30.91-30.9v-92.47z"
    />
  </svg>
)
export default Popup
