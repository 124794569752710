import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getSubtestMocksData = createAsyncThunk(
  'subtestMocks/getSubtestMocksData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`history/subtest-mocks`)
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const subtestMocksSlice = createSlice({
  name: 'subtestMocks',
  initialState: {
    subtestIndex: null,
    subtestMocksData: { isLoading: false, data: [], isLocked: false },
  },

  extraReducers: {
    [getSubtestMocksData.pending]: (state, action) => {
      state.subtestMocksData = { isLoading: true }
    },
    [getSubtestMocksData.fulfilled]: (state, action) => {
      state.subtestIndex = action.payload.data[0].subtest_mocks.map((item) => 0)
      state.subtestMocksData = { isLoading: false, data: action.payload.data }
      state.subtestMocksData.isLocked = false
    },
    [getSubtestMocksData.rejected]: (state, { payload }) => {
      state.subtestMocksData = { isLoading: false }
      if (payload?.status === 403) {
        state.subtestMocksData.isLocked = true
      }
    },
  },
  reducers: {
    updateSubtestIndex: (state, { payload }) => {
      state.subtestIndex[payload.index] = payload.number
    },
    changeTabSubtestQue: (state, { payload }) => {
      state.subtestIndex = state.subtestMocksData.data[
        payload
      ].subtest_mocks.map((item) => 0)
    },
  },
})

export const { updateSubtestIndex, changeTabSubtestQue } =
  subtestMocksSlice.actions

export default subtestMocksSlice.reducer
