import PropTypes from 'prop-types'

const Checkbox = ({ name, label, hideLabel, ...props }) => {
  return (
    <div className="flex items-start">
      <div className="flex items-center h-5">
        <input
          type="checkbox"
          className="checkbox checkbox-secondary focus:outline-none text-red-600 rounded-full "
          // checked={tab.user_read}
          {...props}
        />
      </div>
      {!hideLabel && (
        <div className="ml-3 text-sm">
          <label for="offers" className="font-medium text-gray-700">
            {label}
          </label>
        </div>
      )}
    </div>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
}

export default Checkbox
