const People = ({ className }) => (
  <svg
    viewBox="0 0 48 48"
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
    className={`h-4 w-4 ${className}`}
  >
    {/* <circle cx="24" cy="24" r="24" fill="#F2F2F2" /> */}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.75 19C17.75 17.2051 19.2051 15.75 21 15.75C22.7949 15.75 24.25 17.2051 24.25 19C24.25 20.7949 22.7949 22.25 21 22.25C19.2051 22.25 17.75 20.7949 17.75 19ZM21 14.25C18.3766 14.25 16.25 16.3766 16.25 19C16.25 21.6234 18.3766 23.75 21 23.75C23.6234 23.75 25.75 21.6234 25.75 19C25.75 16.3766 23.6234 14.25 21 14.25ZM16.7019 28.7019C17.3114 28.0924 18.138 27.75 19 27.75H23C23.862 27.75 24.6886 28.0924 25.2981 28.7019C25.9076 29.3114 26.25 30.138 26.25 31V33C26.25 33.4142 26.5858 33.75 27 33.75C27.4142 33.75 27.75 33.4142 27.75 33V31C27.75 29.7402 27.2496 28.532 26.3588 27.6412C25.468 26.7504 24.2598 26.25 23 26.25H19C17.7402 26.25 16.532 26.7504 15.6412 27.6412C14.7504 28.532 14.25 29.7402 14.25 31V33C14.25 33.4142 14.5858 33.75 15 33.75C15.4142 33.75 15.75 33.4142 15.75 33V31C15.75 30.138 16.0924 29.3114 16.7019 28.7019ZM27.2734 14.944C27.3762 14.5427 27.7848 14.3007 28.186 14.4034C29.2078 14.665 30.1134 15.2593 30.7601 16.0924C31.4068 16.9256 31.7578 17.9503 31.7578 19.005C31.7578 20.0597 31.4068 21.0844 30.7601 21.9176C30.1134 22.7507 29.2078 23.345 28.186 23.6066C27.7848 23.7093 27.3762 23.4673 27.2734 23.066C27.1707 22.6648 27.4127 22.2562 27.814 22.1534C28.5131 21.9744 29.1327 21.5679 29.5752 20.9978C30.0177 20.4278 30.2578 19.7266 30.2578 19.005C30.2578 18.2834 30.0177 17.5823 29.5752 17.0122C29.1327 16.4421 28.5131 16.0356 27.814 15.8566C27.4127 15.7538 27.1707 15.3452 27.2734 14.944ZM30.1875 26.4238C29.7864 26.3203 29.3774 26.5614 29.2738 26.9625C29.1703 27.3636 29.4115 27.7726 29.8125 27.8762C30.5071 28.0555 31.1228 28.4596 31.5637 29.0254C32.0041 29.5907 32.2454 30.2857 32.25 31.0023V33C32.25 33.4142 32.5858 33.75 33 33.75C33.4142 33.75 33.75 33.4142 33.75 33L33.75 31L33.75 30.9957C33.744 29.9473 33.3913 28.9304 32.7469 28.1034C32.1025 27.2764 31.2026 26.6859 30.1875 26.4238Z"
      fill="current"
    />
  </svg>
)

export default People
