import { axiosInterceptors } from 'utils/router/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getOrientationData = createAsyncThunk(
  'orientation/getOrientaionData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/orientation/get-page-access`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err)
    }
  },
)
export const getOrientationPage = createAsyncThunk(
  'orientation/getOrientationPage',
  async ({ pageSlug }, { rejectWithValue }) => {
    try {
      const res = axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/orientation/get-orientation-page`,
        {
          params: {
            page_slug: pageSlug,
          },
        },
      )
      return res
    } catch (error) {
      throw rejectWithValue(error)
    }
  },
)

const orientationSlice = createSlice({
  name: 'orientation',
  initialState: {
    orientationData: { isLoading: false, data: [] },
    orientationPageData: { isLoading: false, data: null },
  },
  extraReducers: {
    [getOrientationData.pending]: (state, action) => {
      state.orientationData.isLoading = true
    },
    [getOrientationData.fulfilled]: (state, action) => {
      state.orientationData.data = action?.payload?.data?.filter(
        (orientation) => orientation?.show_panel === 1,
      )
      state.orientationData.isLoading = false
    },
    [getOrientationData.rejected]: (state, action) => {
      state.orientationData.isLoading = false
    },
    [getOrientationPage.pending]: (state, action) => {
      state.orientationPageData.isLoading = true
    },
    [getOrientationPage.fulfilled]: (state, action) => {
      state.orientationPageData.data = action.payload.data
      state.orientationPageData.isLoading = false
    },
    [getOrientationPage.rejected]: (state, action) => {
      state.orientationPageData.isLoading = false
    },
  },
})

export default orientationSlice.reducer
