const Bookmarked = ({ className, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 12 14"
    className={`${size ? size : 'h-4 w-4'} ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.37031 1.03697C1.71413 0.693154 2.18045 0.5 2.66668 0.5H9.33334C9.81957 0.5 10.2859 0.693154 10.6297 1.03697C10.9735 1.38079 11.1667 1.8471 11.1667 2.33333V13C11.1667 13.1873 11.062 13.3589 10.8955 13.4446C10.7289 13.5303 10.5285 13.5157 10.3761 13.4069L6.00001 10.2811L1.62396 13.4069C1.47156 13.5157 1.27109 13.5303 1.10455 13.4446C0.938017 13.3589 0.833344 13.1873 0.833344 13V2.33333C0.833344 1.8471 1.0265 1.38079 1.37031 1.03697Z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Bookmarked
