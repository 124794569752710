const Bell = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 .833a4.5 4.5 0 00-4.5 4.5c0 2.26-.483 3.676-.939 4.51a4.12 4.12 0 01-.611.865 2.168 2.168 0 01-.226.208l-.006.004a.5.5 0 00.282.913h12a.5.5 0 00.282-.913l-.006-.004a2.169 2.169 0 01-.226-.208 4.12 4.12 0 01-.611-.864c-.455-.835-.939-2.251-.939-4.51A4.5 4.5 0 008 .833zm4.561 9.49c.105.192.21.361.313.51H3.127c.102-.149.207-.318.312-.51C3.984 9.324 4.5 7.74 4.5 5.333a3.5 3.5 0 017 0c0 2.407.517 3.991 1.061 4.99zM7.28 13.749a.5.5 0 00-.865.502 1.833 1.833 0 003.172 0 .5.5 0 00-.865-.502.833.833 0 01-1.442 0z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Bell
