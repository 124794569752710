import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getQbankSubtestData = createAsyncThunk(
  'qbankSubtest/getQbankSubtestData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`quiz/subtest-mocks`)
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const qbankSubtestSlice = createSlice({
  name: 'qbankSubtest',
  initialState: {
    subtestIndex: 0,
    qbankSubtestData: { isLoading: false, data: [] },
  },
  extraReducers: {
    [getQbankSubtestData.pending]: (state, action) => {
      state.qbankSubtestData.isLoading = true
    },
    [getQbankSubtestData.fulfilled]: (state, { payload }) => {
      state.qbankSubtestData = { isLoading: false, data: payload.data }
    },
    [getQbankSubtestData.rejected]: (state, action) => {
      state.qbankSubtestData.isLoading = false
    },
  },
  reducers: {
    updateSubtestIndex: (state, { payload }) => {
      state.subtestIndex = payload.index
    },
  },
})

export const { updateSubtestIndex } = qbankSubtestSlice.actions

export default qbankSubtestSlice.reducer
