const Logout = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.333 2.167A1.833 1.833 0 001.5 4v8a1.833 1.833 0 001.833 1.833H8A1.833 1.833 0 009.833 12v-1.333a.5.5 0 10-1 0V12a.833.833 0 01-.833.833H3.333A.833.833 0 012.5 12V4a.833.833 0 01.833-.833H8A.833.833 0 018.833 4v1.333a.5.5 0 101 0V4A1.833 1.833 0 008 2.167H3.333zm9.02 3.48a.5.5 0 00-.707.707L12.793 7.5H4.667a.5.5 0 100 1h8.126l-1.147 1.146a.5.5 0 00.708.708l2-2a.5.5 0 000-.708l-2-2z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Logout
