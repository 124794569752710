const HorizontalDottedLine = ({ className }) => (
  <svg
    width="1"
    height="14"
    viewBox="0 0 1 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
  >
    <line
      x1="0.5"
      y1="0.5"
      x2="0.5"
      y2="15.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeDasharray="2 3.5"
    />
  </svg>
)
export default HorizontalDottedLine
