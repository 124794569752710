const SadEmoji = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10ZM10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM7 7.25C6.58579 7.25 6.25 7.58579 6.25 8C6.25 8.41421 6.58579 8.75 7 8.75H7.01C7.42421 8.75 7.76 8.41421 7.76 8C7.76 7.58579 7.42421 7.25 7.01 7.25H7ZM12.25 8C12.25 7.58579 12.5858 7.25 13 7.25H13.01C13.4242 7.25 13.76 7.58579 13.76 8C13.76 8.41421 13.4242 8.75 13.01 8.75H13C12.5858 8.75 12.25 8.41421 12.25 8ZM7 12.25C6.58579 12.25 6.25 12.5858 6.25 13C6.25 13.4142 6.58579 13.75 7 13.75H13C13.4142 13.75 13.75 13.4142 13.75 13C13.75 12.5858 13.4142 12.25 13 12.25H7Z"
      fill="currentColor"
    />
  </svg>
)

export default SadEmoji
