import { Listbox, Transition } from '@headlessui/react'
import { ChevronDown, Shape } from 'components/icons'
import { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const Dropdown = ({
  hideLabel,
  name,
  label,
  helpText,
  options,
  value,
  icon,
  onChange,
  optionLabelField,
  className,
  dropdownHeight,
  optionId,
  direction,
  firstCapital,
  optionalStyle,
  buttonStyle,
  isError,
  isBreakWords,
}) => {
  const [selectedOption, setSelectedOption] = useState(options[0])
  useEffect(() => {
    setSelectedOption(options[0])
  }, [options])
  return (
    <div className={`${className || 'mb-6'}`}>
      {!hideLabel && (
        <div className="text-sm text-gray-700 dark:text-dark-100">{label}</div>
      )}
      <Listbox
        value={value ? value : selectedOption}
        onChange={onChange ? onChange : setSelectedOption}
      >
        <div className="relative">
          <Listbox.Button
            className={`flex justify-between items-center gap-1 relative w-full border py-2.5 px-4 rounded-md text-gray-900 dark:text-white ${buttonStyle} ${
              isError
                ? 'border-red-500 dark:border-red-500'
                : 'border-gray-500 dark:border-dark-400'
            }`}
          >
            <span
              className={`block truncate text-left ${
                firstCapital ? 'normal-case' : 'capitalize'
              }`}
            >
              {optionLabelField && optionLabelField !== 'id'
                ? value
                  ? value[optionLabelField]
                  : selectedOption && selectedOption[optionLabelField]
                : value
                ? value?.name
                : selectedOption?.name}
            </span>
            <span className="pointer-events-none text-xl">
              {icon ? icon : <ChevronDown />}
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              style={optionalStyle}
              className={`bg-white ring-0 focus:ring-0 focus:outline-none dark:bg-dark-700 z-20 absolute  w-full py-1 mt-1 overflow-auto border border-gray-100 dark:border-dark-400 shadow-sm rounded-md ${dropdownHeight} ${
                direction === 'up' ? '-top-44' : ''
              }`}
            >
              {options?.map((type, id) => (
                <Listbox.Option
                  key={id}
                  value={type}
                  className={({ active, selected }) =>
                    `${
                      selected ||
                      (optionLabelField &&
                        type[optionLabelField] === value[optionLabelField])
                        ? 'text-gray-900 bg-gray-100 dark:bg-dark-600 dark:text-white cursor-default select-none'
                        : 'text-gray-900 dark:text-dark-100 cursor-pointer'
                    }
                    ${active && 'bg-gray-50 dark:bg-dark-500'}
                    relative py-2 pl-6 pr-4 `
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={`${
                          selectedOption ? 'font-medium' : 'font-normal'
                        } block ${isBreakWords ? 'break-words' : 'truncate'}`}
                      >
                        {optionLabelField && optionLabelField !== 'id'
                          ? type[optionLabelField]
                          : type.name}
                      </span>

                      {selected ||
                      (optionLabelField &&
                        type[optionLabelField] === value[optionLabelField]) ? (
                        <span
                          className={`${
                            active ? 'text-gray-600' : 'text-gray-600'
                          }
                                absolute inset-y-0 right-4 flex items-center pl-3`}
                        >
                          <Shape
                            className="w-4 h-4 fill-current text-red-500"
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {helpText && <div className="mt-1 text-sm text-gray-700">{helpText}</div>}
    </div>
  )
}

Dropdown.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
    PropTypes.func,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
    PropTypes.func,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func,
  optionLabelField: PropTypes.string,
  hideLabel: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  helpText: PropTypes.string,
  buttonStyle: PropTypes.string,
  isError: PropTypes.bool,
}

export default Dropdown
