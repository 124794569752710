const VerySadEmoji = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10ZM10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM7 7.25C6.58579 7.25 6.25 7.58579 6.25 8C6.25 8.41421 6.58579 8.75 7 8.75H7.01C7.42421 8.75 7.76 8.41421 7.76 8C7.76 7.58579 7.42421 7.25 7.01 7.25H7ZM12.25 8C12.25 7.58579 12.5858 7.25 13 7.25H13.01C13.4242 7.25 13.76 7.58579 13.76 8C13.76 8.41421 13.4242 8.75 13.01 8.75H13C12.5858 8.75 12.25 8.41421 12.25 8ZM8.93467 13.1642C9.27196 13.0225 9.63414 12.9495 10 12.9495C10.3659 12.9495 10.728 13.0225 11.0653 13.1642C11.4026 13.306 11.7082 13.5136 11.9643 13.7749C12.2542 14.0708 12.729 14.0756 13.0249 13.7857C13.3208 13.4958 13.3256 13.021 13.0357 12.7251C12.64 12.3212 12.1677 12.0004 11.6464 11.7814C11.1252 11.5623 10.5654 11.4495 10 11.4495C9.43458 11.4495 8.87485 11.5623 8.35358 11.7814C7.83232 12.0004 7.36 12.3212 6.96429 12.7251C6.6744 13.021 6.67924 13.4958 6.97511 13.7857C7.27098 14.0756 7.74583 14.0708 8.03571 13.7749C8.29176 13.5136 8.59738 13.306 8.93467 13.1642Z"
      fill="currentColor"
    />
  </svg>
)

export default VerySadEmoji
