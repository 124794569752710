const HexagonalInner = ({ className, border }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="current"
    viewBox="0 0 49 54"
    className={`h-4 w-4 ${className}`}
  >
    <path
      d="M12.5 5.9282C18.3564 2.54701 21.2846 0.856407 24.5 0.856407C27.7154 0.856407 30.6436 2.54701 36.5 5.9282L36.7487 6.0718C42.6051 9.45299 45.5333 11.1436 47.141 13.9282C48.7487 16.7128 48.7487 20.094 48.7487 26.8564V27.1436C48.7487 33.906 48.7487 37.2872 47.141 40.0718C45.5333 42.8564 42.6051 44.547 36.7487 47.9282L36.5 48.0718C30.6436 51.453 27.7154 53.1436 24.5 53.1436C21.2846 53.1436 18.3564 51.453 12.5 48.0718L12.2513 47.9282C6.39488 44.547 3.46668 42.8564 1.85898 40.0718C0.251289 37.2872 0.251289 33.906 0.251289 27.1436V26.8564C0.251289 20.094 0.251289 16.7128 1.85898 13.9282C3.46668 11.1436 6.39488 9.45299 12.2513 6.0718L12.5 5.9282Z"
      fill="current"
    />
  </svg>
)

export default HexagonalInner
