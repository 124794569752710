import { createSlice } from '@reduxjs/toolkit'

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    showProfileMenu: false,
    activePackage: 'Online',
    tabs: [
      {
        id: 'my-purchases',
        title: 'Shop',
        path: '/profile/my-purchases',
      },
      {
        id: 'my-group',
        title: 'My Group',
        path: '/profile/my-group',
      },
      {
        id: 'personal-info',
        title: 'Personal Info',
        path: '/profile/personal-info',
      },
      {
        id: 'change-password',
        title: 'Change Password',
        path: '/profile/change-password',
      },
      {
        id: 'settings',
        title: 'Settings',
        path: '/profile/my-purchases',
      },
      {
        id: 'logout',
        title: 'Logout',
        path: '/logout',
      },
    ],
    activeTab: 'my-purchases',
    feedbackModal: false,
    email: '',
    password: '',
    errorMessage: '',
  },
  reducers: {
    toggleProfileMenu: (state, { payload }) => {
      state.showProfileMenu = !state.showProfileMenu
    },
    switchPackage: (state, action) => {
      state.activePackage = action.payload
    },
    openFeedbackModal: (state) => {
      state.feedbackModal = true
    },
    closeFeedbackModal: (state) => {
      state.feedbackModal = false
    },
  },
})

export const {
  toggleProfileMenu,
  switchPackage,
  openFeedbackModal,
  closeFeedbackModal,
} = profileSlice.actions

export default profileSlice.reducer
