import React from 'react'

const PauseIcon = ({ className }) => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 2.00001C1.5 1.90796 1.57462 1.83334 1.66667 1.83334H3C3.09205 1.83334 3.16667 1.90796 3.16667 2.00001V10C3.16667 10.0921 3.09205 10.1667 3 10.1667H1.66667C1.57462 10.1667 1.5 10.0921 1.5 10V2.00001ZM1.66667 0.833344C1.02233 0.833344 0.5 1.35568 0.5 2.00001V10C0.5 10.6443 1.02233 11.1667 1.66667 11.1667H3C3.64433 11.1667 4.16667 10.6443 4.16667 10V2.00001C4.16667 1.35568 3.64433 0.833344 3 0.833344H1.66667ZM6.83333 2.00001C6.83333 1.90796 6.90795 1.83334 7 1.83334H8.33333C8.42538 1.83334 8.5 1.90796 8.5 2.00001V10C8.5 10.0921 8.42538 10.1667 8.33333 10.1667H7C6.90795 10.1667 6.83333 10.0921 6.83333 10V2.00001ZM7 0.833344C6.35567 0.833344 5.83333 1.35568 5.83333 2.00001V10C5.83333 10.6443 6.35567 11.1667 7 11.1667H8.33333C8.97767 11.1667 9.5 10.6443 9.5 10V2.00001C9.5 1.35568 8.97767 0.833344 8.33333 0.833344H7Z"
      fill="currentColor"
    />
  </svg>
)

export default PauseIcon
