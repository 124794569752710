import { Tab } from '@headlessui/react'
import {
  ChevronLeft,
  Arrowleft,
  Bookmark,
  Bookmarked,
  Loading,
  Warning,
} from 'components/icons'
import { DeletePopUp, Notes, QuestionsAns } from 'components/shared'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'
import { Spinner } from 'components/shared'
import {
  clearResourceData,
  getResourceData,
  createBookmark,
  deleteBookmark,
} from 'redux/learn/resourceSlice'
import HTMLRenderer from 'components/shared/helper/HTMLRenderer'
import { find, findIndex } from 'lodash'
import { getLearnData } from 'redux/learn/learnSlice'
import { getInterviewsData } from 'redux/interviews/interviewGuideSlice'
import { NoRecord } from 'components/no-record'
import TutorQuestion from './TutorQuestion'
import { isUcatPath } from 'components/shared/helper'

const Presentation = () => {
  const { moduleArea, resourceType, resourceSlug, moduleId, subModuleId } =
    useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const loacation = useLocation()
  const {
    resourceData,
    learnData,
    interviewsData,
    bookmarkLoading,
    userData,
    userAccessArea,
  } = useSelector((state) => ({
    resourceData: state.resource.resourceData,
    learnData: state.learn.learnData,
    interviewsData: state.interviews.interviewsData,
    bookmarkLoading: state.resource.bookmarkLoading,
    userData: state.personalInfo.userData,
    userAccessArea: state.dashboard.userAccessArea,
  }))

  const [openAskQuestion, setOpenAskQuestion] = useState(false)
  const [openReplyPage, setOpenReplyPage] = useState(false)
  const [deleteNote, setDeleteNote] = useState(false)
  const learnDataModule = find(learnData, { id: Number(moduleId) })
  const interviewsDataModule = find(interviewsData, { id: Number(moduleId) })
  const API_TOKEN = localStorage.getItem('bearer_token')
  const lastNameFromScorm = loacation.pathname.split('/')
  const scormName = lastNameFromScorm[lastNameFromScorm.length - 1]
    .split('-')
    .join(' ')

  const isAccessTutor = userAccessArea?.data?.includes('tutoring')
  const tabData = [
    { title: 'Discussion', className: 'tour-qa', key: 'discussion' },
    { title: 'My Notes', className: 'tour-notes', key: 'notes' },
    {
      title: 'Discuss this resource with my tutor',
      className: '',
      key: 'tutor',
      hidden: !isAccessTutor,
    },
  ]

  useEffect(() => {
    moduleArea === 'learn'
      ? dispatch(getLearnData())
      : dispatch(
          getInterviewsData({
            interviewSlug:
              userData?.cohort === 'UK' ? 'interviewsuk' : 'interviews',
          }),
        )
    dispatch(
      getResourceData({
        resourceType: resourceType.toLowerCase(),
        resourceSlug: resourceSlug,
      }),
    )
    return () => {
      dispatch(clearResourceData())
    }
  }, [dispatch, moduleArea, resourceSlug, resourceType, userData?.cohort])

  const subModuleIndex =
    learnDataModule &&
    learnDataModule.submodules &&
    findIndex(learnDataModule?.submodules, {
      id: parseInt(subModuleId),
    })
  const scormModule =
    learnDataModule &&
    learnDataModule.submodules &&
    learnDataModule.submodules[subModuleIndex] &&
    learnDataModule.submodules[subModuleIndex].resources &&
    learnDataModule.submodules[subModuleIndex].resources.filter(
      (resource) => resource.resourceable_type_short.toLowerCase() === 'scorm',
    )

  const scormIndex = findIndex(scormModule, { slug: resourceSlug })

  // const postindex = findIndex
  if (deleteNote === true) {
    return <DeletePopUp setDeleteNote={setDeleteNote} Deletenote={deleteNote} />
  } else {
    return resourceType !== 'scorm' ? (
      <div className="mt-10 flex-1 h-full">
        <NoRecord
          icon={<Warning className="h-5 w-5 text-gray-500" />}
          title="Page not found"
        />
      </div>
    ) : (
      <div className="bg-white dark:bg-dark-600 rounded-sm relative min-h-screen  ">
        {resourceData && resourceData.isLoading ? (
          <div className="flex px-6 sm:pt-6 pt-4">
            <Spinner />
          </div>
        ) : (
          resourceData &&
          resourceData.data && (
            <>
              <div className="flex px-6 sm:pt-6 pt-4">
                {moduleArea === 'learn' ? (
                  <>
                    <Link to={`/${moduleArea}`} className="flex ">
                      <div className="sm:hidden mt-0.5 mr-3 dark:text-white">
                        <ChevronLeft />
                      </div>
                      <p className=" hidden sm:block text-sm dark:text-white space-x-1">
                        <span>Learn</span>
                        <span>/</span>
                      </p>
                    </Link>
                    {learnDataModule && (
                      <Link
                        to={`${isUcatPath}/learn/module/${learnDataModule.id}`}
                      >
                        <p className=" text-sm dark:text-white sm:ml-1">
                          Module {learnDataModule.number}:{' '}
                          {learnDataModule.name}
                        </p>
                      </Link>
                    )}
                  </>
                ) : (
                  <>
                    <Link to={`/interviews`} className="flex ">
                      <p className=" hidden sm:block text-sm dark:text-white">
                        Interviews&nbsp;/&nbsp;
                      </p>
                    </Link>
                    <Link to={`/interviews/interview-guide`} className="flex ">
                      <div className="sm:hidden mt-0.5 mr-3">
                        <ChevronLeft className="dark:text-white" />
                      </div>
                      <p className=" hidden sm:block text-sm dark:text-white">
                        Interview Guide&nbsp;/
                      </p>
                    </Link>
                    <Link
                      to={`/${interviewsDataModule?.area}/module/${interviewsDataModule?.id}`}
                    >
                      <p className=" text-sm  dark:text-white">
                        &nbsp;Module {interviewsDataModule?.number}:{' '}
                        {interviewsDataModule?.name}
                        &nbsp;
                      </p>
                    </Link>
                  </>
                )}
                <p className="hidden ml-1 capitalize sm:block text-sm text-gray-400 dark:text-dark-300 mx-1 space-x-1">
                  <span>/</span>
                  <span>{scormName && scormName}</span>
                </p>
              </div>
              {resourceData && resourceData.data && resourceData.data.scorm && (
                <div>
                  <div className="md:flex px-6 xl:px-40">
                    <div className="w-full mt-6">
                      <div>
                        <div>
                          <div
                            onClick={() => {
                              if (
                                resourceData.data.scorm.user_bookmark &&
                                resourceData.data.scorm.user_bookmark.id
                              ) {
                                dispatch(
                                  deleteBookmark({
                                    bookmarkId:
                                      resourceData.data.scorm.user_bookmark.id,
                                    typeId: resourceData.data.scorm.id,
                                    type: resourceType,
                                  }),
                                )
                              } else {
                                dispatch(
                                  createBookmark({
                                    type: resourceType,
                                    typeId: resourceData.data.scorm.id,
                                  }),
                                )
                              }
                            }}
                            className={`flex items-center cursor-pointer ${
                              bookmarkLoading && 'pointer-events-none'
                            }`}
                          >
                            <h3 className=" md:block font-semibold text-xl text-gray-900 dark:text-white  capitalize">
                              {scormName && scormName}
                            </h3>

                            <div className="px-2.5 py-1 ml-4 flex items-center justify-center">
                              {bookmarkLoading ? (
                                <Loading className={'dark:text-white'} />
                              ) : resourceData.data.scorm.user_bookmark &&
                                resourceData.data.scorm.user_bookmark.id ? (
                                <Bookmarked className="text-red-500" />
                              ) : (
                                <Bookmark className={'dark:text-white'} />
                              )}
                            </div>
                          </div>

                          <div className="mt-4 disable-copy text-gray-900 dark:text-white">
                            {resourceData?.data?.scorm?.intro_html && (
                              <HTMLRenderer
                                html={resourceData?.data?.scorm?.intro_html}
                              />
                            )}
                          </div>
                          <div
                            style={{ height: '31rem' }}
                            className="mt-4 border-2 overflow-auto w-full dark:text-white"
                          >
                            <iframe
                              className="w-full border-none h-full"
                              src={`${
                                resourceData?.data?.scorm?.use_direct_url
                                  ? resourceData?.data?.scorm?.use_direct_url
                                  : `${process.env.REACT_APP_API_URL}/resource/scorm-content/${resourceData.data.scorm.id}/accessToken/${API_TOKEN}/content/${resourceData.data.scorm.index_file}`
                              }`}
                              title="Presentation"
                              allowFullScreen
                            ></iframe>
                          </div>
                          <div
                            className={`flex justify-between items-center mt-10 mb-12 text-xs
                              ${
                                scormIndex !== 0
                                  ? 'justify-between'
                                  : 'justify-end'
                              }`}
                          >
                            {scormIndex > 0 && (
                              <button
                                className="tracking-widest flex justify-center items-center font-bold dark:text-white uppercase"
                                onClick={() => {
                                  window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth',
                                  })
                                  history.push(
                                    `${isUcatPath}/module/${moduleArea}/${
                                      learnDataModule.id
                                    }/${resourceType}/${subModuleId}/${
                                      scormModule[scormIndex - 1]?.slug
                                    }`,
                                  )
                                }}
                              >
                                <span className="pr-4">
                                  <Arrowleft className="dark:text-white" />
                                </span>
                                Previous Chapter
                              </button>
                            )}
                            {scormModule &&
                              scormModule.length > 0 &&
                              scormIndex < scormModule.length - 1 && (
                                <button
                                  className="tracking-widest flex justify-center items-center font-bold dark:text-white uppercase"
                                  onClick={() => {
                                    window.scrollTo({
                                      top: 0,
                                      behavior: 'smooth',
                                    })
                                    history.push(
                                      `${isUcatPath}/module/${moduleArea}/${
                                        learnDataModule.id
                                      }/${resourceType}/${subModuleId}/${
                                        scormModule[scormIndex + 1]?.slug
                                      }`,
                                    )
                                  }}
                                >
                                  Next Chapter
                                  <span className="ml-4 ">
                                    <Arrowleft className="dark:text-white transform  rotate-180" />
                                  </span>
                                </button>
                              )}
                          </div>
                        </div>
                        <Tab.Group>
                          <Tab.List className="border-b border-gray-100 dark:border-dark-500">
                            {tabData.map(
                              (tab, tabIndex) =>
                                !tab.hidden && (
                                  <Tab key={tabIndex} as={Fragment}>
                                    {({ selected }) => (
                                      <button
                                        className={`pb-2 mr-4 border-b border-transparent text-sm
                                          ${
                                            selected
                                              ? 'text-gray-900 dark:text-white border-red-500'
                                              : 'text-gray-700 dark:text-dark-100'
                                          } ${tab.className}`}
                                      >
                                        {tab.title}
                                      </button>
                                    )}
                                  </Tab>
                                ),
                            )}
                          </Tab.List>
                          <Tab.Panels className="w-full ">
                            {tabData &&
                              tabData.map((tab, index) => (
                                <Tab.Panel key={index} className=" ">
                                  {tab.key === 'discussion' ? (
                                    <QuestionsAns
                                      openAskQuestionPage={openAskQuestion}
                                      setOpenAskQuestion={setOpenAskQuestion}
                                      openReplyPage={openReplyPage}
                                      setOpenReplyPage={setOpenReplyPage}
                                      bookChapterId={resourceData.data.scorm.id}
                                      resourceType="scorm"
                                      questionTypeId="scorm_id"
                                    />
                                  ) : tab.key === 'notes' ? (
                                    <div>
                                      <Notes
                                        Deletenote={deleteNote}
                                        setDeleteNote={setDeleteNote}
                                        noteType={resourceType}
                                        noteSlug="scorm_id"
                                        noteId={resourceData.data.scorm.id}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <TutorQuestion
                                        openAskQuestionPage={openAskQuestion}
                                        setOpenAskQuestion={setOpenAskQuestion}
                                        openReplyPage={openReplyPage}
                                        setOpenReplyPage={setOpenReplyPage}
                                        noteType={resourceType}
                                        noteSlug="scorm_id"
                                        noteId={resourceData.data.scorm.id}
                                        tutorType={resourceType}
                                        tutorSlug="scorm_id"
                                        tutorId={resourceData.data.scorm.id}
                                      />
                                    </div>
                                  )}
                                </Tab.Panel>
                              ))}
                          </Tab.Panels>
                        </Tab.Group>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )
        )}
      </div>
    )
  }
}
export default Presentation
