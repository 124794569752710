import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'
import toast from 'react-hot-toast'
import { Shape } from 'components/icons'

export const restartMyJourney = createAsyncThunk(
  'myJourney/restartMyJourney',
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `/user-journey/reset-registration-steps`,
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const getJourneyData = createAsyncThunk(
  'myJourney/getJourneyData',
  async () => {
    return await axiosInterceptors.get('/user-journey')
  },
)
export const completeManualActivity = createAsyncThunk(
  'myJourney/completeManualActivity',
  async ({ slug }, { rejectWithValue }) => {
    try {
      const res = axiosInterceptors.post(
        '/user-journey/complete-manual-activity',
        {
          activity_slug: slug,
        },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const myJourneySlice = createSlice({
  name: 'myJourney',
  initialState: {
    restartLoading: false,
    journeyData: { isLoading: false, data: [] },
    tagetScore: 0,
    openMarkAsReadDialog: false,
    manualCompleteData: { isLoading: false, data: [] },
    selectedMarkAsReadStep: { name: null, slug: null },
    showJourneyModal: false,
  },
  extraReducers: {
    [getJourneyData.pending]: (state) => {
      state.journeyData.isLoading = true
    },
    [getJourneyData.rejected]: (state) => {
      state.journeyData.isLoading = false
    },
    [getJourneyData.fulfilled]: (state, { payload }) => {
      state.journeyData.isLoading = false
      state.journeyData.data = payload.data.user_journey
    },
    [completeManualActivity.pending]: (state) => {
      state.manualCompleteData.isLoading = true
    },
    [completeManualActivity.fulfilled]: (state, { payload }) => {
      state.manualCompleteData.data = payload
      state.openMarkAsReadDialog = false
      state.selectedMarkAsReadStep = { name: null, slug: null }
      const updateIndex = state.journeyData.data.findIndex(
        (journey) =>
          journey.activity.slug === payload.data.user_progress.activity.slug,
      )
      state.journeyData.data[updateIndex] = payload.data.user_progress
      state.manualCompleteData.isLoading = false
    },
    [completeManualActivity.rejected]: (state) => {
      state.manualCompleteData.isLoading = false
    },
    [restartMyJourney.pending]: (state) => {
      state.restartLoading = true
    },
    [restartMyJourney.rejected]: (state) => {
      state.restartLoading = false
    },
    [restartMyJourney.fulfilled]: (state, { payload }) => {
      state.restartLoading = false
      toast.custom(
        (t) => (
          <div
            className={`mt-5 filter drop-shadow-md md:drop-shadow-xl bg-gray-100 dark:bg-dark-700 text-gray-900 dark:text-white px-6 py-4 shadow-md rounded-md ${
              t.visible ? 'animate-enter' : 'animate-leave'
            }`}
          >
            <div className="flex items-center justify-start">
              <div className="h-5 w-5 mr-4 bg-green-700 rounded-full flex items-center justify-center">
                <Shape className="text-white" />
              </div>
              <p className="flex-grow">Journey successfully reset</p>
            </div>
          </div>
        ),
        {
          duration: 4000,
          // position: 'top-center',
          // style: {
          //   backdropFilter: 'blur(4px)',
          //   marginTop: '20px',
          //   width: '350px',
          // },
        },
      )
    },
  },
  reducers: {
    setShowJourneyModal: (state, { payload }) => {
      state.showJourneyModal = payload
    },
    setOpenMarkAsReadDialog: (state, { payload }) => {
      state.openMarkAsReadDialog = payload.open || !state.openMarkAsReadDialog
      state.selectedMarkAsReadStep = payload.stepData
    },
  },
})

export const { setOpenMarkAsReadDialog, setShowJourneyModal } =
  myJourneySlice.actions

export default myJourneySlice.reducer
