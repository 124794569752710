import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'
// import toast from 'react-hot-toast'
export const startAbstractReasoning = createAsyncThunk(
  'abstractReasoning/startAbstractReasoning',
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `trainer/abstract-reasoning/start-attempt`,
      )
      onSuccess && onSuccess()
      return res
    } catch (error) {
      throw rejectWithValue(error.response.data)
    }
  },
)

export const submitAbstractReasoningAns = createAsyncThunk(
  'abstractReasoning/submitAbstractReasoningAns',
  async (data) => {
    return await axiosInterceptors.post(
      `trainer/abstract-reasoning/${data.attemptId}/submit-attempt`,
      {
        time_taken_observation: 0,
        time_taken_questions: data.timeTaken,
        responses: data.questionAttempts,
      },
      { headers: { notToRedirect404: true } },
    )
  },
)

const abstractReasoningSlice = createSlice({
  name: 'abstractReasoning',
  initialState: {
    abstractReasoningData: { isRejected: false, errorMessage: '' },
    selectedQue: {},
    ansOfAbstractReasoning: [],
    curQuestion: 0,
    abstractReasoningResult: [],
    scoreEvolutionGraphData: [],
    userLevelInformation: [],
    displaySideQuestion: false,
    disableAbstractReasoningSideBar: false,
    abstractReasoningLoading: false,
    timeTaken: 0,
    countDownTime: 3,
    correctAnsCount: 0,
    abstractReasoningSubmitLoading: false,
    abstractReasoningExamTime: null,
    abstractReasoningDefaultTime: 30,
    recommendedSecondsObservation: null,
    hideAfterObservation: false,
    abstractExtraTime: 0,
  },
  extraReducers: {
    [startAbstractReasoning.pending]: (state, action) => {
      state.abstractReasoningLoading = true
      state.abstractReasoningData.isRejected = false
    },
    [startAbstractReasoning.fulfilled]: (state, { payload }) => {
      state.abstractReasoningData = payload.data
      state.selectedQue = payload.data.attempt.questions[0]
      state.recommendedSecondsObservation =
        payload.data.attempt.recommended_seconds_observation || 0
      state.hideAfterObservation =
        payload.data.attempt.hide_after_observation || false
      state.abstractReasoningExamTime =
        payload.data.attempt.recommended_seconds_questions ||
        state.abstractReasoningDefaultTime
      state.ansOfAbstractReasoning = payload.data.attempt.questions.map(
        (question) => null,
      )
    },
    [startAbstractReasoning.rejected]: (state, action) => {
      state.abstractReasoningData.isRejected = true
      state.isStartedAbstractReasoningTraining = false
      state.abstractReasoningLoading = false
    },
    [submitAbstractReasoningAns.pending]: (state) => {
      state.abstractReasoningSubmitLoading = true
      state.abstractReasoningData.isRejected = false
    },
    [submitAbstractReasoningAns.fulfilled]: (state, { payload }) => {
      state.abstractReasoningResult = payload.data

      state.abstractReasoningScore = payload.data.score
      state.scoreEvolutionGraphData = payload.data.score_evolution_graph
      state.userLevelInformation = payload.data.user_level_information
      state.abstractReasoningExamTime = null
      state.abstractReasoningSubmitLoading = false
      state.disableAbstractReasoningSideBar = false
    },
    [submitAbstractReasoningAns.rejected]: (state) => {
      state.abstractReasoningSubmitLoading = false
      state.abstractReasoningData.isRejected = true
    },
  },
  reducers: {
    setSelectedQue: (state, { payload }) => {
      state.selectedQue = payload
    },
    updateCountDownTime: (state) => {
      if (state.countDownTime === 0) {
        state.abstractReasoningLoading = false
      } else {
        state.countDownTime = state.countDownTime - 1
      }
    },
    setCurQuestion: (state, { payload }) => {
      state.curQuestion += 1
    },

    setDisplaySideQuestion: (state, { payload }) => {
      state.displaySideQuestion = payload
    },
    setAbstractReasoningExamTime: (state, { payload }) => {
      state.abstractReasoningExamTime -= 1
    },
    setObservationExamTime: (state) => {
      if (state.recommendedSecondsObservation > 0)
        state.recommendedSecondsObservation -= 1
    },
    setTryAgainAbstractReasoning: (state, { payload }) => {
      state.abstractReasoningData = {}
      state.ansOfAbstractReasoning = []
      state.curQuestion = 0
      state.abstractReasoningResult = []
      state.abstractReasoningPassage = null
      state.displaySideQuestion = false
      state.abstractReasoningLoading = false
      state.countDownTime = 3
      state.abstractExtraTime = 0
    },
    setAbstractExtraTime: (state, { payload }) => {
      state.abstractExtraTime += 1
    },
    updateTimeTaken: (state, { payload }) => {
      state.timeTaken += 1
    },
    resetTimeTaken: (state, { payload }) => {
      state.timeTaken = 0
    },
    setAnswerOfAbstractReasoning: (state, { payload }) => {
      state.ansOfAbstractReasoning[state.curQuestion] = payload.response
    },
    setDisableAbstractReasoningSideBar: (state, { payload }) => {
      state.disableAbstractReasoningSideBar = payload
    },
    skipCurQuestion: (state, { payload }) => {
      const postIndex = findIndex(state.ansOfAbstractReasoning, {
        questionId: payload.questionId,
      })
      state.ansOfAbstractReasoning[postIndex].response = ''
      state.ansOfAbstractReasoning[postIndex].time = payload.time
      state.curQuestion += 1
    },
  },
})

export const {
  setAbstractExtraTime,
  setSelectedQue,
  setCurQuestion,
  setAnswerOfAbstractReasoning,
  setDisplaySideQuestion,
  setTryAgainAbstractReasoning,
  setAbstractReasoningExamTime,
  setObservationExamTime,
  skipCurQuestion,
  updateTimeTaken,
  resetTimeTaken,
  updateCountDownTime,
  setDisableAbstractReasoningSideBar,
} = abstractReasoningSlice.actions

export default abstractReasoningSlice.reducer
