import { Tooltip } from 'components/form'
import { Book, CheckedList } from 'components/icons'
import { Spinner } from 'components/shared'
import { isUcatPath } from 'components/shared/helper'
import { useEffect } from 'react'
import { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getContinueWhereData } from 'redux/dashboard/dashboardSlice'

const Practice = ({ disabled }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { continueWhereData, olpPath } = useSelector((state) => ({
    continueWhereData: state.dashboard.continueWhereData,
    olpPath: state.personalInfo.olpPath,
  }))
  const getRedirectUrl = (data) => {
    const module = data.location_breadcrumbs.find(
      (data) => data.type === 'module',
    )
    const subModule = data.location_breadcrumbs.find(
      (data) => data.type === 'submodule',
    )
    const resource = data.location_breadcrumbs.find(
      (data) => data.type === 'resource',
    )
    // const chapter = data.location_breadcrumbs.find(
    //   (data) => data.type === 'book',
    // )

    const link =
      module && resource
        ? `${isUcatPath}/${module?.data?.area}/${resource.data.slug}/${
            resource.data.ordering || 1
          }`
        : '#'
    return link
  }
  useEffect(() => {
    dispatch(getContinueWhereData({}))
  }, [])
  return (
    <>
      {continueWhereData.isLoading ? (
        <div className="py-3">
          <Spinner />
        </div>
      ) : (
        <>
          <p className="text-sm font-normal text-gray-700 dark:text-dark-100 mt-2">
            Get back to the last learning module or practice.
          </p>
          <div className="flex items-center mt-6 space-x-6">
            <Tooltip
              className={`break-words max-w-xs ${
                continueWhereData.data && continueWhereData.data.learn_activity
                  ? 'hidden'
                  : ''
              }`}
              text="This will be become available after you begin working through the resources"
            >
              <button
                onClick={() =>
                  continueWhereData.data &&
                  continueWhereData.data.learn_activity &&
                  history.push(
                    getRedirectUrl(continueWhereData.data.learn_activity),
                  )
                }
                style={{ height: '100px' }}
                className={`w-full ${
                  disabled ||
                  !(
                    continueWhereData.data &&
                    continueWhereData.data.learn_activity
                  )
                    ? 'bg-gray-100 dark:bg-dark-500 text-gray-500 border-gray-100'
                    : 'bg-red-100 text-red-500 border-red-500 dark:bg-opacity-10'
                } rounded-xl border   text-xs tracking-widest font-semibold`}
              >
                <center>
                  <Book className="h-6 w-6 fill-current " />
                </center>
                <p
                  className={`mt-4 ${
                    disabled ? 'text-gray-500' : 'text-gray-900 dark:text-white'
                  }`}
                >
                  LEARN
                </p>
              </button>
            </Tooltip>
            <p className="text-sm font-normal text-gray-500 dark:text-dark-300">
              or
            </p>
            <Tooltip
              className={`break-words max-w-xs ${
                continueWhereData.data &&
                continueWhereData.data.recent_attempt &&
                continueWhereData.data.recent_attempt.id
                  ? 'hidden'
                  : ''
              }`}
              text="This link will be enabled if you have any incomplete practice or mock attempts."
            >
              <button
                style={{ height: '100px' }}
                onClick={() => {
                  if (
                    continueWhereData.data &&
                    continueWhereData?.data?.recent_attempt?.id
                  ) {
                    if (olpPath === 'hpat') {
                      history.push(
                        `/qbank/exam/${continueWhereData?.data?.recent_attempt?.id}`,
                      )
                    } else {
                      history.push(
                        `/attempt/${continueWhereData?.data?.recent_attempt?.id}`,
                      )
                    }
                  }
                }}
                className={`w-full 
            ${
              disabled ||
              !(continueWhereData.data && continueWhereData.data.recent_attempt)
                ? 'bg-gray-100 dark:bg-dark-500 text-gray-500 border-gray-100'
                : 'bg-orange-500 text-orange-700 border-orange-700 dark:bg-opacity-10'
            }
            rounded-xl border  text-xs tracking-widest font-semibold`}
              >
                <center>
                  <CheckedList className="h-4 w-5 fill-current" />
                </center>
                <p
                  className={`mt-6 ${
                    disabled
                      ? 'text-gray-500 '
                      : 'text-gray-900 dark:text-white'
                  } break-all`}
                >
                  PRACTICE
                </p>
              </button>
            </Tooltip>
          </div>
        </>
      )}
    </>
  )
}
export default Practice
