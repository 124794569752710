const Minimize = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L3.79289 4.5H2.33333C2.05719 4.5 1.83333 4.72386 1.83333 5C1.83333 5.27614 2.05719 5.5 2.33333 5.5H5C5.0678 5.5 5.13244 5.48651 5.19139 5.46206C5.25036 5.43766 5.30562 5.40149 5.35355 5.35355C5.40149 5.30562 5.43766 5.25036 5.46206 5.19139C5.48651 5.13244 5.5 5.0678 5.5 5V2.33333C5.5 2.05719 5.27614 1.83333 5 1.83333C4.72386 1.83333 4.5 2.05719 4.5 2.33333V3.79289L1.35355 0.646447ZM2.33333 9.5C2.05719 9.5 1.83333 9.27614 1.83333 9C1.83333 8.72386 2.05719 8.5 2.33333 8.5H5C5.13753 8.5 5.26209 8.55553 5.35249 8.64539L5.35355 8.64645L5.35461 8.64751C5.40202 8.6952 5.43784 8.75007 5.46206 8.80861C5.48651 8.86756 5.5 8.93221 5.5 9V11.6667C5.5 11.9428 5.27614 12.1667 5 12.1667C4.72386 12.1667 4.5 11.9428 4.5 11.6667V10.2071L1.35355 13.3536C1.15829 13.5488 0.841709 13.5488 0.646447 13.3536C0.451184 13.1583 0.451184 12.8417 0.646447 12.6464L3.79289 9.5H2.33333ZM8.5 2.33333C8.5 2.05719 8.72386 1.83333 9 1.83333C9.27614 1.83333 9.5 2.05719 9.5 2.33333V3.79289L12.6464 0.646447C12.8417 0.451184 13.1583 0.451184 13.3536 0.646447C13.5488 0.841709 13.5488 1.15829 13.3536 1.35355L10.2071 4.5H11.6667C11.9428 4.5 12.1667 4.72386 12.1667 5C12.1667 5.27614 11.9428 5.5 11.6667 5.5H9C8.86247 5.5 8.73791 5.44447 8.64751 5.35461L8.64645 5.35355L8.64539 5.35249C8.59798 5.3048 8.56216 5.24993 8.53794 5.19139C8.51349 5.13244 8.5 5.0678 8.5 5V2.33333ZM9 8.5H11.6667C11.9428 8.5 12.1667 8.72386 12.1667 9C12.1667 9.27614 11.9428 9.5 11.6667 9.5H10.2071L13.3536 12.6464C13.5488 12.8417 13.5488 13.1583 13.3536 13.3536C13.1583 13.5488 12.8417 13.5488 12.6464 13.3536L9.5 10.2071V11.6667C9.5 11.9428 9.27614 12.1667 9 12.1667C8.72386 12.1667 8.5 11.9428 8.5 11.6667V9C8.5 8.86359 8.55463 8.73994 8.6432 8.64973C8.64535 8.64753 8.64753 8.64535 8.64973 8.6432C8.6969 8.59687 8.75098 8.56179 8.80861 8.53794C8.86756 8.51349 8.93221 8.5 9 8.5Z"
      fill="currentColor"
    />
  </svg>
)

export default Minimize
