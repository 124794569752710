const Chart = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 12 12"
    className={`h-3 w-3 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.02023 0.646448C8.91967 0.545882 8.78103 0.492901 8.63903 0.500766C8.49702 0.508631 8.36509 0.576597 8.27624 0.687653L5.86208 3.70535L3.55695 2.55279C3.33141 2.44002 3.05719 2.51284 2.91732 2.72265L0.250654 6.72265C0.0974777 6.95242 0.159565 7.26285 0.389329 7.41603C0.619093 7.5692 0.929528 7.50712 1.0827 7.27735L3.50453 3.64461L5.77641 4.78055C5.98728 4.88599 6.24316 4.82979 6.39045 4.64568L8.70814 1.74857L10.9798 4.02022C11.1751 4.21548 11.4916 4.21548 11.6869 4.02022C11.8822 3.82496 11.8822 3.50838 11.6869 3.31311L9.02023 0.646448ZM0.666679 9.16667C0.390537 9.16667 0.166679 9.39053 0.166679 9.66667C0.166679 9.94281 0.390537 10.1667 0.666679 10.1667H11.3333C11.6095 10.1667 11.8333 9.94281 11.8333 9.66667C11.8333 9.39053 11.6095 9.16667 11.3333 9.16667H0.666679Z"
      fill="currentColor"
    />
  </svg>
)
export default Chart
