import { Search } from 'components/icons'
const NoRecords = ({
  title,
  subTitle,
  icon,
  subTitleWidth,
  disableTitleMargin,
  marginTitle,
}) => {
  return (
    <div className="h-full flex flex-col justify-center items-center">
      <div className="rounded-full p-4 flex items-center justify-center bg-gray-200 dark:bg-dark-500 dark:text-white">
        {icon ? icon : <Search className="w-6 h-6" />}
      </div>
      {title && (
        <p className={`font-bold text-xl text-gray-900 dark:text-white mt-4`}>
          {title}
        </p>
      )}
      <p
        className={`w-60 sm:2/3 ${subTitleWidth ? subTitleWidth : 'md:w-2/3'} ${
          marginTitle ? marginTitle : 'mt-4'
        } text-center text-base dark:text-white break-words`}
      >
        {subTitle}
      </p>
    </div>
  )
}
export default NoRecords
