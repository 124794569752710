import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'

function QueryAttachment({
  files,
  setFiles,
  uploadFile,
  fileUploadError,
  uploadingLoader,
  padding,
  acceptType,
  fileDescription,
  fileTitle,
  clearFile,
  name,
}) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptType ? acceptType : 'application/pdf',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      )
    },
  })

  const thumbs =
    files &&
    files.length > 0 &&
    files.map((file) => (
      <div className="flex rounded-md border  w-full" key={file.name}>
        <div className="flex overflow-hidden w-full ">
          {acceptType ? (
            <img src={file.preview} className="block " alt={file.name} />
          ) : (
            <embed
              type="application/pdf"
              className="w-full"
              src={file.preview}
              height="375"
            ></embed>
          )}
        </div>
      </div>
    ))

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (files && files.length > 0)
        files.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [files],
  )
  const clearState = () => {
    setFiles([])
    clearFile && clearFile()
  }
  return (
    <div>
      <section
        className={`${
          files && files.length === 0
            ? `rounded-md border border-dashed border-gray-500 dark:border-dark-400 ${padding}`
            : 'border  border-gray-500 dark:border-dark-400'
        } container text-center`}
      >
        <div {...getRootProps({ className: 'dropzone ' })}>
          <input name={name} {...getInputProps()} />
          {files && files.length === 0 && (
            <div>
              <h1 className="text-base text-gray-900 dark:text-white font-semibold">
                {fileTitle ? fileTitle : 'Drag and Drop Photo Here'}
              </h1>
              <p className="text-gray-500 dark:text-dark-300 text-sm">
                {fileDescription
                  ? fileDescription
                  : 'Accepted formats: .pdf, Max file size: 3 MB'}
              </p>
              <div className="py-2 text-gray-900 dark:text-white">
                <h1>or</h1>{' '}
              </div>
              <button
                type="button"
                className="py-4 uppercase font-semibold text-xs px-6 border border-red-500 rounded-md tracking-widest text-gray-900 dark:text-white"
              >
                Browse
              </button>
            </div>
          )}
        </div>
        {files.length > 0 && (
          <aside className="flex flex-wrap w-full ">{thumbs}</aside>
        )}
      </section>
      {fileUploadError && (
        <span className="text-xs flex justify-start mt-2 font-semibold text-red-500">
          {fileUploadError.toString()}
        </span>
      )}
      {files && files.length !== 0 && (
        <div className="text-right">
          <button
            type="button"
            onClick={() => clearState()}
            className="text-red-500 text-xs uppercase"
          >
            clear
          </button>
        </div>
      )}
    </div>
  )
}
export default QueryAttachment
