import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

export const getDiscussionDescription = createAsyncThunk(
  'discussionDesc/getDiscussionDescription',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `community/forum/discussion/${data.id}`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const likeDescriptionPost = createAsyncThunk(
  'discussionDesc/likeDescriptionPost',
  async (data) => {
    return await axiosInterceptors.put(
      `community/forum/upvote`,
      {
        state: !data.upvoteByUser,
      },
      {
        params: {
          type: data.type,
          type_id: data.id,
        },
      },
    )
  },
)

export const subscribeDiscussion = createAsyncThunk(
  'discussionDesc/subscribeDiscussion',
  async (data) => {
    return await axiosInterceptors.put(`community/forum/subscribe/${data.id}`, {
      state: !data.subscribedByUser,
    })
  },
)
export const createDiscussionPost = createAsyncThunk(
  'discussionDesc/createDiscussionPost',
  async ({ discussionId, values, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `community/forum/post`,
        {},
        {
          params: {
            discussion_id: discussionId,
            body: values.post,
          },
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const getDiscussionReply = createAsyncThunk(
  'discussionDesc/getDiscussionReply',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `community/forum/discussion-replies/${data.id}?offset=${data.replyOffset}&limit=10`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const markDiscussionDescriptionAgreement = createAsyncThunk(
  'discussionDesc/markDiscussionDescriptionAgreement',

  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        '/terms-conditions/accept?area=discussions',
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
const DiscussionDesc = createSlice({
  name: 'discussionDesc',
  initialState: {
    descriptionData: {
      isLoading: false,
      isLocked: false,
      isLoadingAfterUpdate: false,
      data: [],
    },
    replyOffset: 10,
    loadingLikePost: false,
    loadingSubscribe: false,
    loadingSeeMore: false,
    createDiscussionLoader: false,
    discussionDescriptionAgreement: { isLoading: false, data: null },
  },
  extraReducers: {
    [createDiscussionPost.pending]: (state, action) => {
      state.createDiscussionLoader = true
    },
    [createDiscussionPost.fulfilled]: (state, action) => {
      state.createDiscussionLoader = false
    },
    [createDiscussionPost.rejected]: (state, { payload }) => {
      state.createDiscussionLoader = false
      if (payload?.status === 403) {
        state.discussionDescriptionAgreement.data = payload.data.data
      }
    },
    [getDiscussionDescription.pending]: (state, action) => {
      const { isRequestAfterUpdate } = action.meta.arg
      state.descriptionData.isLoading = !isRequestAfterUpdate
      state.descriptionData.isLoadingAfterUpdate = isRequestAfterUpdate
    },
    [getDiscussionDescription.fulfilled]: (state, action) => {
      state.descriptionData.isLoading = false
      state.descriptionData.data = action.payload.data
      state.replyOffset = 10
      state.descriptionData.isLoadingAfterUpdate = false
    },
    [getDiscussionDescription.rejected]: (state, { payload }) => {
      state.descriptionData.isLoadingAfterUpdate = false
      state.descriptionData.isLoading = false
      if (payload?.status === 403) {
        state.descriptionData.isLocked = true
      }
    },
    [getDiscussionReply.pending]: (state, action) => {
      state.loadingSeeMore = true
    },
    [getDiscussionReply.fulfilled]: (state, action) => {
      state.loadingSeeMore = false
      state.descriptionData.data.discussion.posts = [
        ...state.descriptionData.data.discussion.posts,
        ...action.payload.data.replies,
      ]
      state.descriptionData.isLocked = false
      state.status = 'success'
    },
    [getDiscussionReply.rejected]: (state, { payload }) => {
      if (payload?.status === 403) {
        state.descriptionData.isLocked = true
      }
    },
    [likeDescriptionPost.pending]: (state, action) => {
      state.loadingLikePost = true
    },
    [likeDescriptionPost.fulfilled]: (state, action) => {
      if (action.meta.arg.type === 'discussion') {
        state.descriptionData.data.discussion.num_upvotes =
          action.payload.data.num_upvotes
        state.descriptionData.data.discussion.upvoted_by_user =
          action.payload.data.upvote_state
      } else {
        const postIndex = findIndex(
          state.descriptionData.data.discussion.posts,
          { id: action.meta.arg.id },
        )
        state.descriptionData.data.discussion.posts[postIndex].num_upvotes =
          action.payload.data.num_upvotes
        state.descriptionData.data.discussion.posts[postIndex].upvoted_by_user =
          action.payload.data.upvote_state
      }
      state.loadingLikePost = false
    },
    [likeDescriptionPost.rejected]: (state, action) => {},
    [subscribeDiscussion.pending]: (state, action) => {
      state.loadingSubscribe = true
    },
    [subscribeDiscussion.fulfilled]: (state, action) => {
      state.descriptionData.data.discussion.subscribed_by_user =
        action.payload.data.subscription_state
      state.loadingSubscribe = false
    },
    [subscribeDiscussion.rejected]: (state, action) => {},
    [markDiscussionDescriptionAgreement.pending]: (state, action) => {
      state.discussionDescriptionAgreement.isLoading = true
    },
    [markDiscussionDescriptionAgreement.fulfilled]: (state, action) => {
      state.discussionDescriptionAgreement.isLoading = false
      state.discussionDescriptionAgreement.data.discussion_terms_agreed = true
    },
    [markDiscussionDescriptionAgreement.rejected]: (state, { payload }) => {
      state.discussionDescriptionAgreement.isLoading = false
    },
  },
  reducers: {
    setReplyOffset: (state, action) => {
      state.replyOffset += 10
    },
    closeAgreementModal: (state) => {
      if (
        state?.discussionDescriptionAgreement?.data?.discussion_terms_agreed ===
        false
      )
        state.discussionDescriptionAgreement.data.discussion_terms_agreed = true
    },
  },
})

export const { setReplyOffset, closeAgreementModal } = DiscussionDesc.actions

export default DiscussionDesc.reducer
