import { Fragment } from 'react'
import { Close, Loading } from 'components/icons'
import { Transition, Dialog } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
// import { useTour } from '@reactour/tour'
import { Button } from 'components/shared'
import {
  setOpenMarkAsReadDialog,
  completeManualActivity,
} from 'redux/my-journey/myJourneySlice'
import HTMLRenderer from 'components/shared/helper/HTMLRenderer'

const MarkAsCompleteDialog = ({ isFromDashboard }) => {
  const dispatch = useDispatch()
  const { selectedMarkAsReadStep, openMarkAsReadDialog, manualCompleteData } =
    useSelector((state) => ({
      selectedMarkAsReadStep: state.myJourney.selectedMarkAsReadStep,
      openMarkAsReadDialog: state.myJourney.openMarkAsReadDialog,
      manualCompleteData: state.myJourney.manualCompleteData,
    }))
  return (
    <>
      <Transition appear show={openMarkAsReadDialog} as={Fragment}>
        <Dialog
          as="div"
          className="bg-gray-700 bg-opacity-80 fixed max-w-full max-h-screen h-screen w-full inset-0 z-50  overflow-y-auto"
          onClose={() => {}}
        >
          <div className="min-h-screen h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-top"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                style={{ maxWidth: '762px' }}
                className="inline-block overflow-y-hidden max-w-full md:max-w-md  md:h-auto w-full p-5 mt-16 md:mt-12 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-dark-600 shadow-xl rounded-md  "
              >
                <span
                  className="cursor-pointer float-right"
                  onClick={() =>
                    dispatch(
                      setOpenMarkAsReadDialog({
                        open: false,
                        stepData: { name: null, slug: null, html: null },
                      }),
                    )
                  }
                >
                  <Close className="fill-current dark:text-white" />
                </span>
                <div className="px-1 py-11 sm:p-11  flex flex-col justify-between items-start">
                  <Dialog.Title
                    as="h2"
                    className="text-2xl  font-semibold leading-6 text-gray-900 dark:text-white"
                  >
                    {selectedMarkAsReadStep.name}
                  </Dialog.Title>
                  {selectedMarkAsReadStep?.html && (
                    <div className="mt-6 html-renderer-container text-gray-900 dark:text-white">
                      <HTMLRenderer html={selectedMarkAsReadStep?.html} />
                    </div>
                  )}
                  <div className="flex items-center mt-10">
                    <Button
                      className={`font-semibold tracking-widest py-4 px-6 ${
                        selectedMarkAsReadStep?.isCompleted
                          ? 'pointer-events-none opacity-50 cursor-not-allowed'
                          : ''
                      }`}
                      disabled={
                        !selectedMarkAsReadStep.slug ||
                        selectedMarkAsReadStep?.isCompleted
                      }
                      onClick={() => {
                        if (isFromDashboard) {
                          isFromDashboard()
                        }
                        if (selectedMarkAsReadStep.slug) {
                          dispatch(
                            completeManualActivity({
                              slug: selectedMarkAsReadStep.slug,
                            }),
                          )
                        }
                      }}
                    >
                      {manualCompleteData.isLoading ? (
                        <Loading />
                      ) : selectedMarkAsReadStep?.isCompleted ? (
                        'Already complete'
                      ) : (
                        'Mark as complete'
                      )}
                    </Button>
                    <Button
                      className="ml-4 font-semibold tracking-widest py-4 px-6"
                      outlined
                      onClick={() =>
                        dispatch(
                          setOpenMarkAsReadDialog({
                            open: false,
                            stepData: { name: null, slug: null, html: null },
                          }),
                        )
                      }
                    >
                      CLOSE
                    </Button>
                  </div>
                  {/* )} */}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default MarkAsCompleteDialog
