const StarFilled = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 22 21"
    className={`h-4 w-4 ${className}`}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.9938 0.252014C11.2793 0.252014 11.54 0.41409 11.6664 0.670093L14.5778 6.56935L21.0874 7.51277C21.3701 7.55374 21.6049 7.75171 21.6932 8.02337C21.7814 8.29503 21.7076 8.59322 21.503 8.79244L16.7858 13.3842L17.898 19.8682C17.9463 20.1497 17.8305 20.4342 17.5994 20.602C17.3682 20.7698 17.0619 20.7917 16.8092 20.6585L11.0004 17.5976L5.17786 20.6589C4.92516 20.7917 4.61896 20.7696 4.38799 20.6018C4.15703 20.434 4.04136 20.1496 4.08963 19.8682L5.20191 13.3842L0.4847 8.79244C0.280028 8.59322 0.206285 8.29503 0.294504 8.02337C0.382724 7.75171 0.617589 7.55374 0.900263 7.51277L7.40986 6.56935L10.3213 0.670093C10.4476 0.41409 10.7084 0.252014 10.9938 0.252014Z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default StarFilled
