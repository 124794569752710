import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

export const getOrderHistory = createAsyncThunk(
  'purchases/getOrderHistory',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/orders/get-student-orders`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getActiveOrder = createAsyncThunk(
  'purchases/getActiveOrder',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/orders/get-package-detail`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getAvailableProducts = createAsyncThunk(
  'purchases/getAvailableProducts',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/shop/get-available-products`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getShopPackages = createAsyncThunk(
  'purchases/getShopPackages',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/shop/shop-packages`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getActivePackages = createAsyncThunk(
  'purchases/getActivePackages',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/shop/purchase-history`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const getAuthorization = createAsyncThunk(
  'purchases/getAuthorization',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/subscription/get-authorisation`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getProductDetailById = createAsyncThunk(
  'purchases/getProductDetailById',
  async ({ productId }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/shop/get-product-detail-by-id`,
        {
          params: {
            product_id: productId,
          },
        },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

const purchasesSlice = createSlice({
  name: 'purchases',
  initialState: {
    orderHistory: { isLoading: false, data: [] },
    activeOrder: { isLoading: false, data: [] },
    profileBadges: [],
    activePackageIndex: null,
    showPaymentModel: false,
    selectedProduct: null,
    authorizationData: null,
    openOnlinePackage: false,
    selectedPackageOptions: null,
    shopPackages: { isLoading: false, data: null },
    availableProducts: { isLoading: false, data: null },
    activePackages: { isLoading: false, data: null },
  },
  extraReducers: {
    [getActivePackages.pending]: (state, { payload, meta }) => {
      state.activePackages.isLoading = true
    },
    [getActivePackages.fulfilled]: (state, { payload, meta }) => {
      state.activePackages.isLoading = false
      state.activePackages.data = payload?.data
    },
    [getActivePackages.rejected]: (state, { payload, meta }) => {
      state.activePackages.isLoading = false
    },
    [getProductDetailById.pending]: (state, { meta }) => {
      state.productIdLoader = meta.arg.product_id
    },
    [getProductDetailById.fulfilled]: (state, { payload, meta }) => {
      if (
        state.shopPackages?.data?.upgrade &&
        state.shopPackages?.data?.packages?.length > 0
      ) {
        const packageIndex = state.shopPackages?.data?.packages?.findIndex(
          (data) => data?.id === payload?.data?.product_id,
        )
        if (packageIndex !== -1) {
          state.shopPackages.data.packages[packageIndex] = {
            ...state.shopPackages.data.packages[packageIndex],
            ...payload.data,
          }
        }
      } else {
        const updatedShopPackages =
          state.shopPackages?.data?.length > 0 &&
          state.shopPackages?.data?.map((packageGroup) =>
            packageGroup?.map((packageItem) => {
              // Check if this package contains the product ID
              if (
                Object.keys(packageItem.menuOptions).includes(
                  String(payload?.data?.product_id),
                )
              ) {
                return {
                  ...packageItem,
                  ...payload.data,
                  id: payload?.data?.product_id,
                  selectedOption: meta?.arg.productId,
                }
              }
              return packageItem
            }),
          )

        state.shopPackages.data = updatedShopPackages
      }
      state.productIdLoader = false
    },
    [getProductDetailById.rejected]: (state) => {
      state.productIdLoader = null
    },

    [getAvailableProducts.pending]: (state) => {
      state.availableProducts.isLoading = true
    },
    [getAvailableProducts.fulfilled]: (state, { payload }) => {
      state.availableProducts.isLoading = false
      state.availableProducts.data = payload?.data
    },
    [getAvailableProducts.rejected]: (state) => {
      state.availableProducts.isLoading = false
    },
    [getShopPackages.pending]: (state, { meta }) => {
      if (!meta.arg.disableLoading) {
        state.shopPackages.isLoading = true
      }
    },
    [getShopPackages.fulfilled]: (state, { payload }) => {
      state.shopPackages.isLoading = false
      state.shopPackages.data = payload?.data
    },
    [getShopPackages.rejected]: (state) => {
      state.shopPackages.isLoading = false
    },
    [getAuthorization.pending]: (state) => {},
    [getAuthorization.fulfilled]: (state, { payload }) => {
      state.authorizationData = payload?.data
    },
    [getAuthorization.rejected]: (state) => {},

    [getOrderHistory.pending]: (state, action) => {
      state.orderHistory.isLoading = true
    },
    [getOrderHistory.fulfilled]: (state, { payload }) => {
      state.orderHistory = {
        isLoading: false,
        data: [
          ...payload?.data?.activeOrders?.map((active) => ({
            ...active,
            products: active?.products
              ?.filter((product) => product?.description)
              ?.map((product) => product?.description)
              ?.join(', '),
          })),
          ...payload?.data?.expiredOrders.map((active) => ({
            ...active,
            products: active?.products
              ?.filter((product) => product?.description)
              ?.map((product) => product?.description)
              ?.join(', '),
          })),
        ],
      }
    },
    [getOrderHistory.rejected]: (state, action) => {
      state.orderHistory.isLoading = false
    },
    [getActiveOrder.pending]: (state, action) => {
      if (!action.meta.arg.disableLoading) {
        state.activeOrder.isLoading = true
      }
    },
    [getActiveOrder.fulfilled]: (state, { payload }) => {
      state.activeOrder = {
        isLoading: false,
        data: payload.data,
      }
      state.activePackageIndex = findIndex(payload?.data?.packages, {
        status: 'Active',
      })
    },
    [getActiveOrder.rejected]: (state, action) => {
      state.activeOrder.isLoading = false
    },
  },
  reducers: {
    setSelectedPackageOptions: (state, { payload }) => {
      state.selectedPackageOptions = payload
    },
    setOpenOnlinePackage: (state, { payload }) => {
      state.openOnlinePackage = payload
    },
    setSelectedProduct: (state, { payload }) => {
      state.selectedProduct = payload
    },
    updateActivePackageIndex: (state, { payload }) => {
      state.activePackageIndex = payload.index
    },
    togglePaymentModel: (state, { payload }) => {
      state.showPaymentModel = payload
    },
  },
})

export const {
  setSelectedPackageOptions,
  setOpenOnlinePackage,
  setSelectedProduct,
  updateActivePackageIndex,
  togglePaymentModel,
} = purchasesSlice.actions

export default purchasesSlice.reducer
