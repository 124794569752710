import { createSlice } from '@reduxjs/toolkit'

export const myFavoriteSlice = createSlice({
  name: 'myFavorite',
  initialState: {
    showBookmarkFilters: false,
    deleteModal: false,
  },
  reducers: {
    toogleBookmarkFilters: (state) => {
      state.showBookmarkFilters = !state.showBookmarkFilters
    },
    toggleDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal
    },
  },
})

export const { toogleBookmarkFilters, toggleDeleteModal } =
  myFavoriteSlice.actions

export default myFavoriteSlice.reducer
