import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'
export const loginUser = createAsyncThunk(
  'auth/login',
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post('/login', {
        email,
        password,
      })

      return response
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data)
    }
  },
)
export const getProfileDetails = createAsyncThunk(
  'auth/getProfileDetails',
  async ({ token, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/account/personal-info`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        },
      )
      onSuccess && onSuccess({ userInfo: res?.data?.data?.info })
      return res
    } catch (error) {
      onError && onError()
      throw rejectWithValue(error.response)
    }
  },
)
export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    email: '',
    password: '',
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    userData: JSON.parse(localStorage.getItem('user-data')),
    redirectLogin: {
      isLoading: false,
      data: null,
      isRejected: false,
    },
  },
  reducers: {
    clearRedirectLoginData: (state) => {
      state.redirectLogin = {
        isLoading: false,
        data: null,
        isRejected: false,
      }
    },
    clearState: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isFetching = false

      return state
    },
  },
  extraReducers: {
    [getProfileDetails.pending]: (state) => {
      state.redirectLogin.isLoading = true
      state.redirectLogin.isRejected = false
    },
    [getProfileDetails.fulfilled]: (state, action) => {
      state.userData = action?.payload?.data?.data?.info
      localStorage.setItem('bearer_token', action.meta.arg.token)
      localStorage.setItem(
        'user-data',
        JSON.stringify(action?.payload?.data?.data?.info),
      )
      state.redirectLogin.isLoading = false
      state.redirectLogin.isRejected = false
    },
    [getProfileDetails.rejected]: (state, { payload }) => {
      state.redirectLogin.isLoading = false
      state.redirectLogin.isRejected = true

      const errorMassage = payload?.data?.message
        ? payload?.data?.message
        : payload?.data?.errors?.length > 0
        ? payload?.data?.errors[0]
        : 'Something went wrong'
      if (errorMassage) {
        toast.error(errorMassage, {
          duration: 3000,
          position: 'top-center',
          style: {
            backdropFilter: 'blur(4px)',
            marginTop: '20px',
            width: '350px',
          },
        })
      }
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      localStorage.setItem('bearer_token', payload.data.user.bearer_token)
      localStorage.setItem('user-data', JSON.stringify(payload.data.user))
      state.email = payload.email
      state.password = payload.password
      state.isFetching = false
      state.isSuccess = true
      return state
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.isError = true
      state.errorMessage = payload.message
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true
    },
  },
})
export const { clearState, clearRedirectLoginData } = authSlice.actions
