import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'

export const getInterviewsData = createAsyncThunk(
  'interviews/getInterviewsData',
  async ({ interviewSlug }, { rejectWithValue }) => {
    try {
      return await axiosInterceptors.get(`/resource/modules/${interviewSlug}`)
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)
export const getInterviewDashboardData = createAsyncThunk(
  'interviews/getInterviewDashboardData',
  async () => {
    return await axiosInterceptors.get('/interviews')
  },
)
export const getInterviewBankData = createAsyncThunk(
  'interviews/getInterviewBankData',
  async () => {
    return await axiosInterceptors.get('/interviews/bank')
  },
)
export const getInterviewVideos = createAsyncThunk(
  'interviews/getInterviewVideos',
  async (
    { themeId, startDate, endDate, sortBy, page, owner },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.get(
        '/interviews/bank/video-answers',
        {
          params: {
            theme_id: themeId,
            end_date: endDate,
            start_date: startDate,
            sort_by: sortBy,
            page,
            owner,
          },
        },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err?.response)
    }
  },
)

export const markInterviewBookStarted = createAsyncThunk(
  'interviews/markInterviewBookStarted',
  async (data) => {
    return await axiosInterceptors.post(
      `/resource/book/${data.bookId}/mark-started`,
    )
  },
)
export const startAttempt = createAsyncThunk(
  'interviews/startAttempt',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        '/interviews/bank/start-attempt',
        {
          random: data.random,
          theme_id: data.themeId,
        },
      )
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)
export const submitVideoAnswer = createAsyncThunk(
  'interviews/submitVideoAnswer',
  async ({ id, video, setUploaded, abortController }, { rejectWithValue }) => {
    try {
      const videoElement = document.createElement('video')
      videoElement.src = URL.createObjectURL(video)

      await new Promise((resolve) => {
        videoElement.addEventListener('loadedmetadata', resolve)
      })
      const duration = videoElement.duration
      const formData = new FormData()
      formData.append('video', video, 'interview_bank.mp4')
      formData.append('duration_seconds', duration || 0)

      const res = await axiosInterceptors.post(
        `interviews/bank/scenario/${id}/video-response`,
        formData,
        {
          onUploadProgress: (data) => {
            setUploaded &&
              setUploaded(Math.round((data.loaded / data.total) * 100))
          },
          signal: abortController?.signal,
          headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
          },
          timeout: 180000,
        },
      )
      return res
    } catch (error) {
      throw rejectWithValue(error)
    }
  },
)
export const publishVideo = createAsyncThunk(
  'interviews/publishVideo',

  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(
        `/interviews/bank/set-video-published/${data.id}`,
        {
          state:
            data.state !== undefined || data.state !== null ? data.state : true,
        },
      )
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const deleteVideo = createAsyncThunk(
  'interviews/deleteVideo',

  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.delete(
        `interviews/bank/video-response/${id}`,
      )
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const setUpVoteScenario = createAsyncThunk(
  `interviews/setUpVoteScenario`,
  async ({ upvoteId, state, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(
        `interviews/bank/set-upvote-state/${upvoteId}`,
        { state },
      )

      onSuccess && onSuccess()

      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const fetchVideosOfScenarios = createAsyncThunk(
  'interviews/fetchVideosOfScenarios',
  async ({ scenarioId }, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptors.get(
        `/interviews/bank/scenario-video-answers/${scenarioId}`,
      )
      return response
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const setUpVoteVideoForScenario = createAsyncThunk(
  `interviews/setUpVoteVideoForScenario`,
  async ({ upvoteId, state, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(
        `interviews/bank/set-upvote-state/${upvoteId}`,
        { state },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const contentReport = createAsyncThunk(
  'contactReason/contentReport',
  async ({ reason, type_id, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(`content-report`, {
        reason,
        type: 'video_response',
        type_id,
      })
      onSuccess && onSuccess(res)
      return res
    } catch (err) {
      const errorType = rejectWithValue(err?.response?.data)
      onError && onError(errorType?.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getReviewScenario = createAsyncThunk(
  'interviews/getReviewScenario',
  async ({ scenarioId }, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptors.get(
        `/interviews/bank/review-scenario/${scenarioId}`,
        { headers: { notToRedirect404: true } },
      )
      return response
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

const interviewGuideSlice = createSlice({
  name: 'interviews',
  initialState: {
    isLoading: false,
    interviewsData: [],
    interviewVideoData: {
      isLoading: false,
      data: [],
      isError: false,
      errorMessage: null,
      isLocked: false,
      pagination: null,
    },
    interviewBankData: { isLoading: false, data: [] },
    interviewDashboardData: { isLoading: false, data: [] },
    interviewAttemptData: {
      isLoading: false,
      data: [],
      isRejected: false,
      errorMessage: null,
    },
    selectedTheme: null,
    selectedSubTheme: null,
    randomInterviewBank: true,
    isStartedInterviewAttempt: false,
    isFinishedInterviewAttempt: false,
    curQuestion: 0,
    examTime: null,
    disableSidebar: false,
    openRecordVideoPopup: false,
    showVideoRecordSection: false,
    displaySideQuestion: false,
    isVideoRecordMode: false,
    isVideoStopped: false,
    publishVideoData: { id: null, isLoading: false, data: [] },
    savedVideoAnswerData: {
      isLoading: false,
      data: [],
      error: null,
      isError: false,
    },
    displaySidebar: false,
    openVideoAnswers: false,
    showUploadVideoAnswers: false,
    showPublishModal: false,
    videoPauseCountdown: 0,
    finishedInterviewQuestions: false,
    videoTiming: 0,
    deleteVideoId: null,
    upvoteId: null,
    videosPage: 1,
    videoLoadMore: false,
    videoType: '',
    showVideoSolution: false,
    videosOfScenarios: {
      isLoading: false,
      videos: [],
      isError: false,
      errorMessage: null,
      isLocked: false,
    },
    showReport: false,
    reportId: false,
    isReportLoading: false,
    successMessage: null,
    reviewScenarioLoader: false,
  },
  extraReducers: {
    [contentReport.pending]: (state) => {
      state.isReportLoading = true
    },
    [contentReport.fulfilled]: (state, { payload }) => {
      state.isReportLoading = false
      state.successMessage = payload.message
      toast.success('Your message has been sent to the MedEntry team', {
        duration: 4000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '350px',
        },
      })
    },
    [contentReport.rejected]: (state, { payload }) => {
      state.isReportLoading = false
    },
    //
    [fetchVideosOfScenarios.pending]: (state) => {
      state.videosOfScenarios.isLoading = true
      state.videosOfScenarios.isError = false
      state.videosOfScenarios.errorMessage = null
      state.videosOfScenarios.isLocked = false
    },
    [fetchVideosOfScenarios.fulfilled]: (state, { payload }) => {
      state.videosOfScenarios.isLoading = false
      state.videosOfScenarios.videos = payload?.data?.videos || []
    },
    [fetchVideosOfScenarios.rejected]: (state, { payload }) => {
      state.videosOfScenarios.isLoading = false
      state.videosOfScenarios.isError = true
      if (payload?.data?.message) {
        state.videosOfScenarios.errorMessage = payload.data.message
      }
      if (payload?.status === 403) {
        state.videosOfScenarios.isLocked = true
      }
    },
    [setUpVoteVideoForScenario.pending]: (state, { meta }) => {
      state.upvoteId = meta.arg.upvoteId
    },
    [setUpVoteVideoForScenario.fulfilled]: (state, { payload, meta }) => {
      const videoIndex = findIndex(state.videosOfScenarios?.videos, {
        id: meta.arg.upvoteId,
      })
      if (videoIndex !== -1) {
        state.videosOfScenarios.videos[videoIndex].num_upvotes =
          payload.data.num_upvotes
        state.videosOfScenarios.videos[videoIndex].user_upvoted =
          payload.data.user_upvoted
        state.upvoteId = null
      }
    },
    [setUpVoteVideoForScenario.rejected]: (state) => {
      state.upvoteId = null
    },
    [setUpVoteScenario.pending]: (state, { meta }) => {
      state.upvoteId = meta.arg.upvoteId
    },
    [setUpVoteScenario.fulfilled]: (state, { payload, meta }) => {
      const postIndex = findIndex(state.interviewVideoData?.data?.videos, {
        id: meta.arg.upvoteId,
      })
      if (postIndex !== -1) {
        state.interviewVideoData.data.videos[postIndex].num_upvotes =
          payload.data.num_upvotes
        state.interviewVideoData.data.videos[postIndex].user_upvoted =
          payload.data.user_upvoted
        state.upvoteId = null
      }
    },
    [setUpVoteScenario.rejected]: (state) => {
      state.interviewDashboardData.isLoading = false
    },
    [getInterviewDashboardData.pending]: (state) => {
      state.interviewDashboardData.isLoading = true
    },
    [getInterviewDashboardData.fulfilled]: (state, { payload }) => {
      state.interviewDashboardData.isLoading = false
      state.interviewDashboardData.data = payload.data
    },
    [getInterviewDashboardData.rejected]: (state) => {
      state.interviewDashboardData.isLoading = false
    },
    [getInterviewBankData.pending]: (state) => {
      state.interviewBankData.isLoading = true
    },
    [getInterviewBankData.fulfilled]: (state, { payload }) => {
      state.interviewBankData.isLoading = false
      state.interviewBankData.data = payload.data
      if (payload.data && payload.data.themes && payload.data.themes.length > 0)
        state.selectedTheme = {
          id: payload.data.themes[0].id,
          name: payload.data.themes[0].name,
        }
    },
    [getInterviewBankData.rejected]: (state) => {
      state.interviewBankData.isLoading = false
    },
    [getInterviewVideos.pending]: (state, { meta }) => {
      if (meta.arg.page === 1) {
        state.interviewVideoData.isLoading = true
      } else {
        state.videoLoadMore = true
      }
      state.interviewVideoData.isError = false
      state.interviewVideoData.errorMessage = null
      state.interviewVideoData.isLocked = false
    },
    [getInterviewVideos.fulfilled]: (state, { payload, meta }) => {
      state.interviewVideoData.isLoading = false
      state.videoLoadMore = false
      state.interviewVideoData.pagination = payload.pagination
      if (meta.arg.page === 1) {
        state.interviewVideoData.data = payload.data
      } else {
        state.interviewVideoData.data = {
          ...payload.data,
          videos: [
            ...state.interviewVideoData.data.videos,
            ...payload.data.videos,
          ],
        }
      }
    },
    [getInterviewVideos.rejected]: (state, { payload }) => {
      state.videoLoadMore = false
      state.interviewVideoData.isLoading = false
      state.interviewVideoData.isError = true
      if (payload?.data?.message) {
        state.interviewVideoData.errorMessage = payload.data.message
      }
      if (payload?.status === 403) {
        state.interviewVideoData.isLocked = true
      }
    },
    [getInterviewsData.pending]: (state, action) => {
      state.status = 'loading'
      state.isLoading = true
    },
    [getInterviewsData.fulfilled]: (state, action) => {
      state.interviewsData = action.payload.data
      state.status = 'success'
      state.isLoading = false
    },
    [getInterviewsData.rejected]: (state, action) => {
      state.status = 'failed'
      state.isLoading = false
    },
    [startAttempt.pending]: (state, action) => {
      state.interviewAttemptData.isLoading = true
    },
    [startAttempt.fulfilled]: (state, { payload }) => {
      state.interviewAttemptData.data = payload.data
      state.interviewAttemptData.isLoading = false
      state.examTime = payload.data.attempt.scenario.reading_time
      state.interviewAttemptData.isRejected = false
      state.interviewAttemptData.errorMessage = ''
    },
    [startAttempt.rejected]: (state, action) => {
      state.interviewAttemptData.isLoading = false
      state.interviewAttemptData.isRejected = true
      if (action?.payload?.status === 403) {
        state.interviewAttemptData.errorMessage = action?.payload?.data?.message
      }
    },
    [submitVideoAnswer.pending]: (state) => {
      state.savedVideoAnswerData.isLoading = true
    },
    [submitVideoAnswer.fulfilled]: (state, action) => {
      state.savedVideoAnswerData.isLoading = false
      state.savedVideoAnswerData.data = action?.payload?.data?.video_response
      if (!action?.meta?.arg?.hideSuccessModal) {
        state.showPublishModal = true
      }
      state.savedVideoAnswerData.error = null
      state.savedVideoAnswerData.isError = false
    },
    [submitVideoAnswer.rejected]: (state, { payload }) => {
      if (payload?.message !== 'canceled') {
        state.savedVideoAnswerData.isError = true
        if (payload?.code === 'ECONNABORTED') {
          state.savedVideoAnswerData.error =
            'Something went wrong, click on save button to submit video again'
        } else {
          state.savedVideoAnswerData.error =
            payload?.response?.data?.errors?.video[0] ||
            'The video failed to upload.'
        }

        state.savedVideoAnswerData.isLoading = false
      }
    },
    [getReviewScenario.pending]: (state) => {
      state.reviewScenarioLoader = true
    },
    [getReviewScenario.fulfilled]: (state, { payload }) => {
      state.reviewScenarioLoader = false
      state.isStartedInterviewAttempt = true
      state.displaySideQuestion = true
      state.finishedInterviewQuestions = true
      state.savedVideoAnswerData.data = payload?.data?.video_response
      state.interviewAttemptData.data = payload?.data
      if (!payload?.data?.video_response?.video?.url) {
        state.savedVideoAnswerData.isError = true
        state.savedVideoAnswerData.error = 'You did not submit a video response'
      }
    },
    [getReviewScenario.rejected]: (state, { payload }) => {
      state.reviewScenarioLoader = false
      if (payload?.status === 404) {
        state.interviewAttemptData.isRejected = true
        state.interviewAttemptData.errorMessage =
          'Interview Bank Scenario not found'
      }
    },
    [publishVideo.pending]: (state, { meta }) => {
      state.publishVideoData.isLoading = true
      state.publishVideoData.id = meta.arg.id
    },
    [publishVideo.fulfilled]: (state, { payload, meta }) => {
      state.publishVideoData.isLoading = false
      state.publishVideoData.data = payload.data && payload.data
      if (!meta?.arg?.hideSuccessModal) {
        state.showPublishModal = true
      }
      if (
        meta?.arg?.hideSuccessModal &&
        state.interviewVideoData.data.videos?.length > 0
      ) {
        const videoIndex = findIndex(state.interviewVideoData.data.videos, {
          id: meta?.arg?.id,
        })
        if (videoIndex !== -1) {
          toast.success(
            !payload?.data?.user_published
              ? 'Video unpublish successfully!'
              : 'Video publish successfully!',
            {
              duration: 1000,
              position: 'top-center',
              style: {
                backdropFilter: 'blur(4px)',
                marginTop: '20px',
                width: '450px',
              },
            },
          )
          state.interviewVideoData.data.videos[videoIndex] = {
            ...state.interviewVideoData.data.videos[videoIndex],
            ...payload.data,
          }
        }
      }
      state.publishVideoData.id = null
    },
    [publishVideo.rejected]: (state) => {
      state.publishVideoData.isLoading = false
    },
    [deleteVideo.pending]: (state, { meta }) => {
      state.deleteVideoId = meta.arg.id
    },
    [deleteVideo.fulfilled]: (state, { payload, meta }) => {
      toast.success('Video deleted successfully!', {
        duration: 1000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '450px',
        },
      })
      state.interviewVideoData.data.videos =
        state.interviewVideoData.data.videos.filter(
          (mine) => parseInt(mine.id) !== parseInt(meta.arg.id),
        )
      state.deleteVideoId = null
    },
    [deleteVideo.rejected]: (state) => {
      state.deleteVideoId = false
    },
  },
  reducers: {
    clearReport: (state, { payload }) => {
      state.reportId = null
      state.showReport = false
      state.successMessage = null
    },
    setReportId: (state, { payload }) => {
      state.reportId = payload
    },
    setShowReport: (state, { payload }) => {
      state.showReport = payload
    },
    setVideoType: (state, { payload }) => {
      state.videoType = payload
    },
    clearVideoData: (state, { payload }) => {
      state.interviewVideoData = {
        isLoading: false,
        data: [],
        isError: false,
        errorMessage: null,
        isLocked: false,
        pagination: null,
      }
      state.videosPage = 1
      state.videoType = ''
    },
    setDeleteVideo: (state, { payload }) => {
      let temp = [...state.interviewVideoData.data.videos]
      const index = temp.findIndex((video) => video.id === payload.id)
      if (index !== -1) {
        temp.splice(index, 1)
      }
      state.interviewVideoData.data.videos = [...temp]
    },
    setVideoTiming: (state, { payload }) => {
      state.videoTiming += 1
    },
    cleareSaveVideoError: (state, { payload }) => {
      state.savedVideoAnswerData.error = null
      state.savedVideoAnswerData.isError = false
    },
    toggleFinishedInterviewQuestions: (state, { payload }) => {
      state.finishedInterviewQuestions = payload
    },
    resetCurQuestion: (state, { payload }) => {
      state.curQuestion = payload
    },
    setVideoPauseCountdown: (state, { payload }) => {
      state.videoPauseCountdown = payload
    },
    setSelectedTheme: (state, { payload }) => {
      state.selectedTheme = payload
    },
    setSelectedSubTheme: (state, { payload }) => {
      state.selectedSubTheme = payload
    },
    setRandomInterviewBank: (state) => {
      state.randomInterviewBank = !state.randomInterviewBank
    },
    toggleStartedAttempt: (state, { payload }) => {
      state.isStartedInterviewAttempt = payload
    },
    setDisplaySideQuestion: (state, { payload }) => {
      state.displaySideQuestion = payload
    },
    toggleFinishedAttempt: (state, { payload }) => {
      state.isFinishedInterviewAttempt = payload
    },
    setDisableInterviewSideBar: (state, { payload }) => {
      state.disableSidebar = payload
    },
    setExamTime: (state) => {
      state.examTime -= 1
    },
    resetExamTime: (state, { payload }) => {
      state.examTime = payload
    },
    setCurQuestion: (state) => {
      state.curQuestion += 1
    },
    setOpenRecordVideoPopup: (state, { payload }) => {
      state.openRecordVideoPopup = payload
    },
    setShowVideoRecordSection: (state, { payload }) => {
      state.showVideoRecordSection = payload
      state.isVideoRecordMode = true
    },
    clearSavedVideoAnswerData: (state) => {
      state.savedVideoAnswerData = {
        isLoading: false,
        data: [],
        error: null,
        isError: false,
        errorMessage: '',
      }
    },
    setTryAgainAttempt: (state) => {
      state.interviewAttemptData = {
        isLoading: false,
        data: [],
        isRejected: false,
      }
      state.isStartedInterviewAttempt = false
      state.isFinishedInterviewAttempt = false
      state.curQuestion = 0
      state.examTime = null
      state.disableSidebar = false
      state.openRecordVideoPopup = false
      state.showVideoRecordSection = false
      state.displaySideQuestion = false
      state.isVideoRecordMode = false
      state.publishVideoData = { isLoading: false, data: [] }
      state.isVideoStopped = false
      state.savedVideoAnswerData = {
        isLoading: false,
        data: [],
        error: null,
        isError: false,
        errorMessage: '',
      }
      state.videoTiming = 0
      state.finishedInterviewQuestions = false
      state.showVideoSolution = false
    },
    setIsVideoStopped: (state, { payload }) => {
      state.isVideoStopped = payload
    },
    setDisplaySidebar: (state, { payload }) => {
      state.displaySidebar = payload
    },
    setOpenVideoAnswers: (state, { payload }) => {
      state.openVideoAnswers = payload
    },
    setShowVideoSolution: (state, { payload }) => {
      state.showVideoSolution = payload
    },
    setShowUploadVideoAnswers: (state, { payload }) => {
      state.showUploadVideoAnswers = payload
    },
    setShowPublishModal: (state, { payload }) => {
      state.showPublishModal = payload
    },
    resetVideoTiming: (state) => {
      state.videoTiming = 0
    },
    setVideosPage: (state, { payload }) => {
      state.videosPage = payload
    },
  },
})

export const {
  clearReport,
  setReportId,
  setShowReport,
  setShowVideoSolution,
  setVideoType,
  clearVideoData,
  setDeleteVideo,
  cleareSaveVideoError,
  toggleFinishedInterviewQuestions,
  setVideoPauseCountdown,
  resetCurQuestion,
  setSelectedTheme,
  setSelectedSubTheme,
  setRandomInterviewBank,
  toggleStartedAttempt,
  toggleFinishedAttempt,
  setDisableInterviewSideBar,
  setCurQuestion,
  setOpenRecordVideoPopup,
  setExamTime,
  resetExamTime,
  setDisplaySideQuestion,
  setShowVideoRecordSection,
  setTryAgainAttempt,
  setIsVideoStopped,
  setDisplaySidebar,
  setOpenVideoAnswers,
  setShowUploadVideoAnswers,
  setShowPublishModal,
  setVideoTiming,
  resetVideoTiming,
  clearSavedVideoAnswerData,
  setVideosPage,
} = interviewGuideSlice.actions
export default interviewGuideSlice.reducer
