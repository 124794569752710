import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getPerformanceData = createAsyncThunk(
  'performance/getPerformanceData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `/performance-metrics/performance-over-time-by-day`,
        { params: data },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const getPerformanceByQuestionType = createAsyncThunk(
  'performance/getPerformanceByQuestionType',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `/performance-metrics/performance-by-question-type`,
        {
          params: {
            range: data.range,
          },
        },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const getTimingByQuestion = createAsyncThunk(
  'performance/getTimingByQuestion',
  async (data) => {
    return await axiosInterceptors.get(
      `/performance-metrics/timing-by-question-type`,
      {
        params: {
          range: data.range,
        },
      },
    )
  },
)
export const getPerformanceDataByFilter = createAsyncThunk(
  'performance/getPerformanceDataByFilter',
  async (data) => {
    return await axiosInterceptors.get(
      `/performance-metrics/performance-over-time`,
      {
        params: {
          range: data.range || undefined,
          days: data.days || undefined,
        },
      },
    )
  },
)
export const performanceSlice = createSlice({
  name: 'performance',
  initialState: {
    performanceData: { isLoading: false, data: [], isLocked: false },
    performanceByQuestionData: { isLoading: false, data: [], isLocked: false },
    timingByQuestionData: { isLoading: false, data: [] },
    selectedGraphTab: 'Overall',
    selectedRange: {
      id: 1,
      name: 'Most recent 50% of attempts',
      value: 'last50',
    },
    selectedPerformanceQuestion: {
      id: 1,
      name: 'Most recent 50% of attempts',
      value: 'last50',
    },
    selectedGraphDuration: {
      id: 1,
      value: 'month',
      name: 'Year',
      range: 'year',
    },
  },
  extraReducers: {
    [getPerformanceData.pending]: (state, action) => {
      state.performanceData.isLoading = true
    },
    [getPerformanceData.fulfilled]: (state, { payload }) => {
      state.performanceData.data = payload.data
      state.performanceData.isLoading = false
      state.performanceData.isLocked = false
    },
    [getPerformanceData.rejected]: (state, { payload }) => {
      state.performanceData.isLoading = false
      if (payload && payload.status === 403) {
        state.performanceData.isLocked = true
      }
    },
    [getPerformanceByQuestionType.pending]: (state, action) => {
      state.performanceByQuestionData.isLoading = true
    },
    [getPerformanceByQuestionType.fulfilled]: (state, { payload }) => {
      state.performanceByQuestionData.data = payload.data
      state.performanceByQuestionData.isLoading = false
      state.performanceByQuestionData.isLocked = false
    },
    [getPerformanceByQuestionType.rejected]: (state, { payload }) => {
      state.performanceByQuestionData.isLoading = false
      if (payload && payload.status === 403) {
        state.performanceByQuestionData.isLocked = true
      }
    },
    [getPerformanceDataByFilter.pending]: (state, action) => {
      state.performanceData.isLoading = true
    },
    [getPerformanceDataByFilter.fulfilled]: (state, { payload }) => {
      state.performanceData.data = payload.data
      state.performanceData.isLoading = false
    },
    [getPerformanceDataByFilter.rejected]: (state, action) => {
      state.performanceData.isLoading = false
    },
    [getTimingByQuestion.pending]: (state, action) => {
      state.timingByQuestionData.isLoading = true
    },
    [getTimingByQuestion.fulfilled]: (state, { payload }) => {
      state.timingByQuestionData.data = payload.data
      state.timingByQuestionData.isLoading = false
    },
    [getTimingByQuestion.rejected]: (state, action) => {
      state.timingByQuestionData.isLoading = false
    },
  },
  reducers: {
    clearPerformanceData: (state) => {
      state.performanceData = { isLoading: false, data: [], isLocked: false }
      state.selectedGraphDuration = {
        id: 1,
        value: 'month',
        name: 'Year',
        range: 'year',
      }
    },
    updateSelectedTab: (state, { payload }) => {
      state.selectedGraphTab = payload
    },
    updateSelectedGraphDuration: (state, { payload }) => {
      state.selectedGraphDuration = payload
    },
    updateSelectedRange: (state, { payload }) => {
      state.selectedRange = payload
    },
    updateSelectedPerformanceQuestion: (state, { payload }) => {
      state.selectedPerformanceQuestion = payload
    },
  },
})
export const {
  clearPerformanceData,
  updateSelectedTab,
  updateSelectedGraphDuration,
  updateSelectedPerformanceQuestion,
  updateSelectedRange,
} = performanceSlice.actions
export default performanceSlice.reducer
