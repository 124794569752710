const Success = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    fill="none"
    viewBox="0 0 64 64"
  >
    <circle cx="32" cy="32" r="32" fill="#F3F9ED"></circle>
    <path
      fill="#8AC34A"
      fillRule="evenodd"
      d="M40.53 26.47a.75.75 0 010 1.06l-10 10a.75.75 0 01-1.06 0l-5-5a.75.75 0 111.06-1.06L30 35.94l9.47-9.47a.75.75 0 011.06 0z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default Success
