import { Arrowleft, Loading } from 'components/icons'
import { Button } from 'components/shared'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getResourceData,
  retryQuizChapter,
  submitQuiz,
  updateQuestionsIndex,
} from 'redux/learn/resourceSlice'

const BookingQuizBottomArea = ({
  previousChapter,
  nextChapter,
  isQuestionCompleted,
  recoursesQuestion,
  questionSubmission,
}) => {
  const { resourceType, resourceSlug } = useParams()
  const dispatch = useDispatch()
  const sliderRef = useRef()

  const {
    tabDataIndex,
    bookDetails,
    questionsIndex,
    quizAnswers,
    submitLoader,
    retryLoader,
    optionClickable,
  } = useSelector((state) => ({
    tabDataIndex: state.resource.tabDataIndex,
    bookDetails: state.resource.bookDetails,
    questionsIndex: state.resource.questionsIndex,
    quizAnswers: state.resource.quizAnswers,
    submitLoader: state.resource.submitLoader,
    retryLoader: state.resource.retryLoader,
    optionClickable: state.examPortal.optionClickable,
  }))
  const questions = bookDetails?.chapterable?.questions
  const submission = bookDetails?.chapterable?.submission

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleQuestionNext = () => {
    if (questionsIndex + 1 < questions?.length) {
      dispatch(updateQuestionsIndex(questionsIndex + 1))
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleQuestionPrevious = () => {
    if (questionsIndex !== 0) {
      dispatch(updateQuestionsIndex(questionsIndex - 1))
    }
  }
  const getCurrentQuestionClass = ({ index }) => {
    if (index === questionsIndex) {
      return 'bg-red-500 border border-red-500 bg-opacity-10 dark:text-white'
    } else {
      return 'bg-gray-50 border-gray-300 dark:text-white dark:bg-dark-500 dark:border-dark-400'
    }
  }

  const getQuestionClassFromCorrectness = (id, correct_option) => {
    const userInputs = submission?.user_inputs?.find(
      (input) => input?.question_id === id,
    )
    if (userInputs?.input === correct_option) {
      return 'border-green-700 dark:bg-opacity-10 bg-green-300 dark:text-white S1'
    } else {
      return 'border-red-500 dark:bg-opacity-10 bg-red-100 dark:text-white S2'
    }
  }
  const getCurrentQuestionClassReview = () => {
    if (questionSubmission?.input === recoursesQuestion?.correct_option) {
      return 'bg-green-700 border-green-700 text-white dark:text-white S3'
    } else {
      return 'bg-red-500 border-red-500 text-white dark:text-white S4'
    }
  }

  const handleSubmitQuiz = ({ isCompleted }) => {
    dispatch(
      submitQuiz({
        chapterId: bookDetails?.id,
        quizId: bookDetails?.chapterable?.id,
        data: {
          is_completed: isCompleted,
          answers: quizAnswers?.map((answer) => ({
            quiz_chapter_question_id: answer?.quiz_chapter_question_id,
            input: answer?.input,
          })),
          times: quizAnswers?.map((answer) => ({
            quiz_chapter_question_id: answer?.quiz_chapter_question_id,
            time: answer?.time,
          })),
          is_user_read: isCompleted ? true : undefined,
        },
      }),
    )
  }

  const handleRetry = () => {
    dispatch(
      retryQuizChapter({
        chapterId: bookDetails?.id,
        quizId: bookDetails?.chapterable?.id,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(
          getResourceData({
            isFromRetry: true,
            // resourceType: resourceType.toLowerCase(),
            resourceSlug: resourceSlug,
          }),
        )
      })
  }

  const [isDown, setIsDown] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)

  const mouseDownHandler = (e) => {
    setIsDown(true)
    sliderRef?.current?.classList.add('active')
    setStartX(e.pageX - sliderRef?.current?.offsetLeft)
    setScrollLeft(sliderRef?.current?.scrollLeft)
  }
  const mouseLeaveHandler = () => {
    setIsDown(false)
    sliderRef?.current?.classList.remove('active')
  }

  const mouseUpHandler = () => {
    setIsDown(false)
    sliderRef?.current?.classList.remove('active')
  }

  const mouseMoveHandler = (e) => {
    if (!isDown) return
    const x = e.pageX - sliderRef?.current?.offsetLeft
    const walk = (x - startX) * 3 //scroll-fast
    sliderRef.current.scrollLeft = scrollLeft - walk
  }

  const questionNumber = () => {
    return (
      questions?.length > 0 &&
      questions?.map((question, index) => (
        <div
          key={index}
          style={{ minWidth: '36px' }}
          className={`flex items-center justify-center h-9 w-9 rounded border cursor-pointer ${
            submission?.is_completed
              ? index === questionsIndex
                ? getCurrentQuestionClassReview()
                : getQuestionClassFromCorrectness(
                    question?.id,
                    question?.correct_option,
                  )
              : getCurrentQuestionClass({
                  index,
                })
          } ${index === questionsIndex ? 'selected-question' : ''}`}
          onClick={() => {
            if (!submission?.is_completed) {
              handleSubmitQuiz({ isCompleted: false })
            }
            dispatch(updateQuestionsIndex(index))
          }}
        >
          {index + 1}
        </div>
      ))
    )
  }

  useEffect(() => {
    if (optionClickable) {
      document.onkeydown = (e) => {
        if (e.altKey && e.code === 'KeyN') {
          e.preventDefault()
          e.stopPropagation()
          handleQuestionNext()
        } else if (e.altKey && e.code === 'KeyP') {
          e.preventDefault()
          e.stopPropagation()
          handleQuestionPrevious()
        }
      }
    }
    return () => {
      document.onkeydown = null
    }
  }, [handleQuestionNext, handleQuestionPrevious, optionClickable])

  // scroll up to the top after question change
  useEffect(() => {
    const res = document?.querySelector('.selected-question')
    res?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    })
    const leftSide = document.getElementById('left-area')
    leftSide.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const rightSide = document.getElementById('right-area')
    rightSide.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const mainArea = document.getElementById('main-area')
    mainArea.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [questionsIndex])

  return (
    <div>
      <div className="pt-4 sm:pt-0 border-t sm:border-b border-gray-100 dark:border-dark-400 flex justify-between items-center bg-white dark:bg-dark-500 bottom-0 gap-2 sm:gap-4 w-full px-1 sm:px-6">
        <div className="relative">
          {tabDataIndex !== 0 && questionsIndex === 0 ? (
            <button
              onClick={() => {
                previousChapter && previousChapter()
              }}
              className="uppercase text-xs font-semibold text-gray-900 dark:text-white cursor-pointer flex items-center gap-2 sm:gap-4"
            >
              <Arrowleft className="fill-current h-4 w-4 flex-shrink-0" />
              <span>
                <kbd>P</kbd>revious chapter
              </span>
            </button>
          ) : (
            <button
              onClick={handleQuestionPrevious}
              className="uppercase text-xs font-semibold text-gray-900 dark:text-white cursor-pointer flex items-center gap-2 sm:gap-4"
            >
              <Arrowleft className="fill-current flex-shrink-0 h-4 w-4" />
              <span>
                <kbd>P</kbd>revious question
              </span>
            </button>
          )}
        </div>
        <div
          ref={sliderRef}
          onMouseDown={mouseDownHandler}
          onMouseLeave={mouseLeaveHandler}
          onMouseUp={mouseUpHandler}
          onMouseMove={mouseMoveHandler}
          className={`hidden question-index sm:flex items-center  pt-4 overflow-y-hidden overflow-scroll  select-none cursor-pointer gap-2`}
        >
          {questionNumber()}
        </div>
        <div className="relative">
          <div className="flex gap-4 items-center">
            <div>
              {isQuestionCompleted ? (
                retryLoader ? (
                  <>
                    <Loading className="text-red-500" />
                  </>
                ) : (
                  <button
                    onClick={() => {
                      handleRetry()
                    }}
                    className="text-red-500 font-semibold text-xs uppercase"
                  >
                    Retry quiz
                  </button>
                )
              ) : (
                <></>
              )}
            </div>
            <div>
              {questionsIndex + 1 < questions?.length ? (
                <button
                  onClick={() => {
                    if (!submission?.is_completed) {
                      handleSubmitQuiz({ isCompleted: false })
                    }
                    handleQuestionNext()
                  }}
                  className="mt-0.5 uppercase text-xs font-semibold text-gray-900 dark:text-white cursor-pointer flex items-center gap-2 sm:gap-4"
                >
                  <span>
                    <kbd>N</kbd>ext question
                  </span>
                  <Arrowleft className="transform rotate-180 fill-current h-4 w-4 flex-shrink-0" />
                </button>
              ) : (
                <>
                  {questions?.length > 0 && !isQuestionCompleted ? (
                    submitLoader ? (
                      <>
                        <Loading className="text-red-500" />
                      </>
                    ) : (
                      <button
                        onClick={() => {
                          handleSubmitQuiz({ isCompleted: true })
                        }}
                        className="text-red-500 font-semibold uppercase text-xs"
                      >
                        Check answers
                      </button>
                    )
                  ) : (
                    <button
                      onClick={() => nextChapter && nextChapter()}
                      className="mt-0.5 uppercase text-xs font-semibold text-gray-900 dark:text-white cursor-pointer flex items-center gap-2 sm:gap-4"
                    >
                      <span>
                        <kbd>N</kbd>ext Chapter
                      </span>
                      <Arrowleft className="transform rotate-180 fill-current h-4 w-4 flex-shrink-0" />
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          ref={sliderRef}
          onMouseDown={mouseDownHandler}
          onMouseLeave={mouseLeaveHandler}
          onMouseUp={mouseUpHandler}
          onMouseMove={mouseMoveHandler}
          className={`border-b py-4 px-4 sm:hidden question-index flex items-center  overflow-y-hidden overflow-scroll  select-none cursor-pointer gap-2`}
        >
          {questionNumber()}
        </div>
      </div>
    </div>
  )
}

export default BookingQuizBottomArea
