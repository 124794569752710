import React, { Fragment, useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import { Hexagon, RoundedBadge } from 'components/shared'
import { QuestionMark, Shape } from 'components/icons'
// import { Tab } from '@headlessui/react'
import {
  badgesColorByArea,
  badgeSmallColors,
  communityBadgesColors,
  rankColors,
} from 'components/shared/helper'

const chartData1 = [
  {
    date: '1995',
    score: 269,
    lineColor: '#F2473A',
  },
  {
    date: '1996',
    score: 141,
    lineColor: '#F2473A',
  },
  {
    date: '1997',
    score: 353,
    lineColor: '#F2473A',
  },
  {
    date: '1998',
    score: 548,
    lineColor: '#F2473A',
  },
  {
    date: '1999',
    score: 298,
    lineColor: '#F2473A',
  },
  {
    date: '2000',
    score: 267,
    lineColor: '#F2473A',
  },
  {
    date: '2001',
    score: 411,
    lineColor: '#F2473A',
  },
  {
    date: '2002',
    score: 462,
    lineColor: '#F2473A',
  },
  {
    date: '2003',
    score: 47,
    lineColor: '#F2473A',
  },
  {
    date: '2004',
    score: 445,
    lineColor: '#F2473A',
  },
  {
    date: '2005',
    score: 47,
    lineColor: '#F2473A',
  },
]

const Graph = ({
  chartData,
  targetScore,
  maxScore,
  currentBadge,
  nextBadge,
  nextRequirements,
}) => {
  useEffect(() => {
    am4core.addLicense(process.env.REACT_APP_AM4CHART_KEY)
    let chart = am4core.create('myJourney', am4charts.XYChart)
    chart.paddingRight = 30
    chart.data = chartData || chartData1
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'index'
    categoryAxis.renderer.line.strokeOpacity = 1
    categoryAxis.renderer.inside = true
    categoryAxis.renderer.line.strokeWidth = 1
    categoryAxis.renderer.labels.template.disabled = true
    categoryAxis.endLocation =
      chartData.length === 1
        ? 10
        : chartData.length === 10
        ? 1
        : 10 - chartData.length
    categoryAxis.renderer.minGridDistance = 10
    categoryAxis.renderer.grid.template.disabled = true
    categoryAxis.renderer.line.stroke = am4core.color('#E0E0E0')
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.title.text = 'Points'
    valueAxis.max = maxScore + 20
    valueAxis.min = 0
    valueAxis.title.fontWeight = 'bold'
    valueAxis.renderer.grid.template.disabled = true
    valueAxis.renderer.line.strokeOpacity = 1
    valueAxis.renderer.line.strokeWidth = 1
    valueAxis.renderer.line.stroke = am4core.color('#E0E0E0')
    if (
      (localStorage && localStorage.theme === 'Dark') ||
      (!('theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      valueAxis.title.fill = am4core.color('#FFFFFF')
      valueAxis.renderer.labels.template.fill = am4core.color('#FFFFFF')
    } else {
      valueAxis.title.fill = am4core.color('#000000')
      valueAxis.renderer.labels.template.fill = am4core.color('#000000')
    }

    let range = valueAxis.axisRanges.create()
    range.value = targetScore
    range.grid.strokeWidth = 1
    range.grid.strokeOpacity = 1
    range.grid.strokeDasharray = '6,3'
    range.label.inside = true
    range.label.text = 'Objective'
    range.label.fill = range.grid.stroke
    range.label.verticalCenter = 'bottom'
    if (
      (localStorage && localStorage.theme === 'Dark') ||
      (!('theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      range.label.fill = am4core.color('#FFFFFF')
      range.grid.stroke = am4core.color('#FFFFFF')
    } else {
      range.label.fill = am4core.color('#000000')
      range.grid.stroke = am4core.color('#494949')
    }

    let series = chart.series.push(new am4charts.LineSeries())
    series.dataFields.categoryX = 'index'
    series.dataFields.valueY = 'score'
    series.tensionX = 1
    series.propertyFields.fill = 'lineColor'
    series.stroke = am4core.color('#F2473A')
    series.fillOpacity = 0.1

    let series1Bullets1 = series.bullets.push(new am4charts.CircleBullet())
    series1Bullets1.tooltipHTML = `
   <span style="
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 20px;color: #FFFFFF;">{score} Points</span>`
    series.tooltip.getFillFromObject = false
    series.tooltip.background.fill = am4core.color('black')
    series1Bullets1.circle.fill = am4core.color('#F2473A')
    series1Bullets1.keepSelection = true
    return () => {
      chart?.dispose()
    }
  }, [localStorage.theme])

  return (
    <>
      <div
        id="myJourney"
        className="w-full mt-10"
        style={{ height: '266px' }}
      ></div>
      <h4 className="mt-10 flex justify-center items-center font-semibold dark:text-white">
        Rank
      </h4>
      <div className="hidden md:flex mt-6 my-4  items-center justify-between sm:overflow-y-auto">
        {currentBadge && (
          <div className="flex flex-col items-center justify-between">
            {currentBadge.shape === 'hexagon' ? (
              <Hexagon
                className=""
                color={
                  currentBadge.area === 'Community'
                    ? communityBadgesColors[currentBadge.slug] || 'gray'
                    : badgesColorByArea[currentBadge.area] || 'green'
                }
                smallIconColor={
                  badgeSmallColors[currentBadge.area_slug]
                    ? badgeSmallColors[currentBadge.area_slug]
                    : 'green'
                }
                smallIcon={currentBadge.small_icon}
                icon={
                  currentBadge &&
                  currentBadge.icon && (
                    <img
                      src={currentBadge.icon.url}
                      alt={currentBadge.icon.name}
                    />
                  )
                }
                tooltipText={
                  <div className="html-renderer-container">
                    <span className="text-white font-semibold text-base ">
                      {currentBadge && currentBadge.name}
                    </span>
                    <br />
                    <span className="mt-1 text-gray-500 text-sm font-normal">
                      {currentBadge && currentBadge.area}
                    </span>
                    <div className="mt-2">
                      {currentBadge &&
                        currentBadge.objectives &&
                        currentBadge.objectives.map((data, index) => (
                          <li key={index}>{data}</li>
                        ))}
                    </div>
                  </div>
                }
              />
            ) : (
              <div className="">
                <RoundedBadge
                  color={rankColors[currentBadge.slug.split('-')[0]]}
                  smallIconColor="green"
                  smallIcon={currentBadge.small_icon}
                  tooltipText={
                    <div className="html-renderer-container">
                      <span className="text-white font-semibold text-base ">
                        {currentBadge && currentBadge.name}
                      </span>
                      <br />
                      <span className="mt-1 text-gray-500 text-sm font-normal">
                        {currentBadge && currentBadge.area}
                      </span>
                      <div className="mt-2">
                        {currentBadge &&
                          currentBadge.objectives &&
                          currentBadge.objectives.map((data, index) => (
                            <li key={index}>{data}</li>
                          ))}
                      </div>
                    </div>
                  }
                  icon={
                    currentBadge &&
                    currentBadge.icon && (
                      <img
                        src={currentBadge.icon.url}
                        alt={currentBadge.icon.name}
                      />
                    )
                  }
                />
              </div>
            )}
            <p className="text-sm h-10 text-center font-normal w-28 text-gray-900 dark:text-white">
              {currentBadge && currentBadge.name}
            </p>
          </div>
        )}
        {((nextRequirements && nextRequirements.length > 0) || nextBadge) &&
          currentBadge && (
            <div className="border-b  mb-12 border-dashed border-gray-500 dark:border-white flex-grow"></div>
          )}
        {nextRequirements &&
          nextRequirements.length > 0 &&
          nextRequirements.map((data, id) => (
            <Fragment key={id}>
              <div className="flex flex-col items-center justify-between">
                <div
                  style={{ width: '120px' }}
                  className="flex items-center justify-center h-auto md:h-28"
                >
                  {data.achieved ? (
                    <div className="flex items-center justify-center h-5 w-5 bg-red-500 text-white rounded-full">
                      <Shape />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center h-5 w-5 border border-gray-500 dark:border-dark-300 rounded-full"></div>
                  )}
                </div>
                <p className="text-sm text-center  font-normal w-28 text-gray-900 dark:text-white">
                  {data.description}
                </p>
              </div>
              {id !== (nextRequirements && nextRequirements.length - 1) && (
                <div className="border-b  mb-12 border-dashed border-gray-500 dark:border-white flex-grow"></div>
              )}
            </Fragment>
          ))}
        <div
          className={`border-b  mb-12 border-dashed border-gray-500 dark:border-white flex-grow ${
            !nextBadge && 'hidden'
          }`}
        ></div>
        {nextBadge && (
          <div className="flex flex-col items-center justify-between">
            {nextBadge.shape === 'hexagon' ? (
              <Hexagon
                className=""
                // color={
                //   nextBadge.area === 'Community'
                //     ? communityBadgesColors[nextBadge.slug] || 'gray'
                //     : badgesColorByArea[nextBadge.area] || 'green'
                // }
                smallIconColor={
                  badgeSmallColors[nextBadge.area_slug]
                    ? badgeSmallColors[nextBadge.area_slug]
                    : 'green'
                }
                smallIcon={nextBadge.small_icon}
                isEmpty={true}
                icon={
                  <QuestionMark className="dark:text-white" />
                  // nextBadge &&
                  // nextBadge.icon && (
                  //   <img src={nextBadge.icon.url} alt={nextBadge.icon.name} />
                  // )
                }
                tooltipText={
                  <div className="html-renderer-container">
                    <span className="text-white font-semibold text-base ">
                      {nextBadge && nextBadge.name}
                    </span>
                    <br />
                    <span className="mt-1 text-gray-500 text-sm font-normal">
                      {nextBadge && nextBadge.area}
                    </span>
                    <div className="mt-2">
                      {nextBadge &&
                        nextBadge.objectives &&
                        nextBadge.objectives.map((data, index) => (
                          <li key={index}>{data}</li>
                        ))}
                    </div>
                  </div>
                }
              />
            ) : (
              <div className="mt-10">
                <RoundedBadge
                  color={rankColors[nextBadge.slug.split('-')[0]]}
                  smallIconColor="green"
                  smallIcon={nextBadge.small_icon}
                  tooltipText={
                    <div className="html-renderer-container">
                      <span className="text-white font-semibold text-base ">
                        {nextBadge && nextBadge.name}
                      </span>
                      <br />
                      <span className="mt-1 text-gray-500 text-sm font-normal">
                        {nextBadge && nextBadge.area}
                      </span>
                      <div className="mt-2">
                        {nextBadge &&
                          nextBadge.objectives &&
                          nextBadge.objectives.map((data, index) => (
                            <li key={index}>{data}</li>
                          ))}
                      </div>
                    </div>
                  }
                  icon={
                    nextBadge &&
                    nextBadge.icon && (
                      <img src={nextBadge.icon.url} alt={nextBadge.icon.name} />
                    )
                  }
                />
              </div>
            )}
            <p className="text-sm h-10 text-center font-normal w-28 text-gray-900 dark:text-white">
              {nextBadge && nextBadge.name}
            </p>
          </div>
        )}
      </div>
      <div className="flex flex-col md:hidden mt-6 items-center justify-between flex-wrap relative z-10">
        <div className="flex z-20 items-center justify-evenly mt-10 w-full">
          {currentBadge && currentBadge.shape === 'hexagon' ? (
            <Hexagon
              className=""
              color={
                currentBadge.area === 'Community'
                  ? communityBadgesColors[currentBadge.slug] || 'gray'
                  : badgesColorByArea[currentBadge.area] || 'green'
              }
              smallIconColor={
                badgeSmallColors[currentBadge.area_slug]
                  ? badgeSmallColors[currentBadge.area_slug]
                  : 'green'
              }
              smallIcon={currentBadge.small_icon}
              icon={
                currentBadge &&
                currentBadge.icon && (
                  <img
                    src={currentBadge.icon.url}
                    alt={currentBadge.icon.name}
                  />
                )
              }
              tooltipText={
                <div className="html-renderer-container">
                  <span className="text-white font-semibold text-base ">
                    {currentBadge && currentBadge.name}
                  </span>
                  <br />
                  <span className="mt-1 text-gray-500 text-sm font-normal">
                    {currentBadge && currentBadge.area}
                  </span>
                  <div className="mt-2">
                    {currentBadge &&
                      currentBadge.objectives &&
                      currentBadge.objectives.map((data, index) => (
                        <li key={index}>{data}</li>
                      ))}
                  </div>
                </div>
              }
            />
          ) : (
            currentBadge && (
              <div className="">
                <RoundedBadge
                  color={rankColors[currentBadge.slug.split('-')[0]]}
                  smallIconColor="green"
                  smallIcon={currentBadge.small_icon}
                  tooltipText={
                    <div className="html-renderer-container">
                      <span className="text-white font-semibold text-base ">
                        {currentBadge && currentBadge.name}
                      </span>
                      <br />
                      <span className="mt-1 text-gray-500 text-sm font-normal">
                        {currentBadge && currentBadge.area}
                      </span>
                      <div className="mt-2">
                        {currentBadge &&
                          currentBadge.objectives &&
                          currentBadge.objectives.map((data, index) => (
                            <li key={index}>{data}</li>
                          ))}
                      </div>
                    </div>
                  }
                  icon={
                    currentBadge &&
                    currentBadge.icon && (
                      <img
                        src={currentBadge.icon.url}
                        alt={currentBadge.icon.name}
                      />
                    )
                  }
                />
              </div>
            )
          )}
          <p className="text-sm top-full font-normal w-28 text-gray-900 dark:text-white">
            {currentBadge && currentBadge.name}
          </p>
        </div>
        {((nextRequirements && nextRequirements.length > 0) || nextBadge) &&
          currentBadge && (
            <div className="flex z-20 items-center justify-evenly w-full">
              <div
                style={{ width: '140px' }}
                className="flex items-center justify-center h-auto md:h-24"
              >
                <div className="border-l border-dashed border-gray-500 h-6 mb-4 dark:border-white"></div>
              </div>
              <div className="w-28"></div>
            </div>
          )}
        {nextRequirements &&
          nextRequirements.map((data, index) => (
            <Fragment key={index}>
              <div
                key={index}
                className="flex z-20 items-start justify-evenly  w-full"
              >
                <div
                  style={{ width: '140px' }}
                  className="flex items-center justify-center h-auto md:h-24"
                >
                  {data.achieved ? (
                    <div className="flex items-center justify-center h-5 w-5 bg-red-500 text-white rounded-full">
                      <Shape />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center h-5 w-5 border border-gray-500 dark:border-dark-300 rounded-full"></div>
                  )}
                </div>
                <p className="text-sm top-full text-center font-normal w-28 text-gray-900 dark:text-white">
                  {data.description}
                </p>
              </div>
              <div className="flex z-20 items-center justify-evenly w-full">
                <div
                  style={{ width: '140px' }}
                  className="flex items-center justify-center h-auto md:h-24"
                >
                  <div className="border-l border-dashed border-gray-900 h-6 mb-4 dark:border-white"></div>
                </div>
                <div className="w-28"></div>
              </div>
            </Fragment>
          ))}
        {nextBadge && (
          <div className="flex z-20 items-center justify-evenly  w-full">
            {nextBadge.shape === 'hexagon' ? (
              <Hexagon
                className=""
                // color={
                //   nextBadge.area === 'Community'
                //     ? communityBadgesColors[nextBadge.slug] || 'gray'
                //     : badgesColorByArea[nextBadge.area] || 'green'
                // }
                smallIconColor={
                  badgeSmallColors[nextBadge.area_slug]
                    ? badgeSmallColors[nextBadge.area_slug]
                    : 'green'
                }
                smallIcon={nextBadge.small_icon}
                isEmpty={true}
                icon={
                  <QuestionMark className="dark:text-white" />
                  // nextBadge &&
                  // nextBadge.icon && (
                  //   <img src={nextBadge.icon.url} alt={nextBadge.icon.name} />
                  // )
                }
                tooltipText={
                  <div className="html-renderer-container">
                    <span className="text-white font-semibold text-base ">
                      {nextBadge && nextBadge.name}
                    </span>
                    <br />
                    <span className="mt-1 text-gray-500 text-sm font-normal">
                      {nextBadge && nextBadge.area}
                    </span>
                    <div className="mt-2">
                      {nextBadge &&
                        nextBadge.objectives &&
                        nextBadge.objectives.map((data, index) => (
                          <li key={index}>{data}</li>
                        ))}
                    </div>
                  </div>
                }
              />
            ) : (
              <div className="mt-10">
                <RoundedBadge
                  color={rankColors[nextBadge.slug.split('-')[0]]}
                  smallIconColor="green"
                  smallIcon={nextBadge.small_icon}
                  tooltipText={
                    <div className="html-renderer-container">
                      <span className="text-white font-semibold text-base ">
                        {nextBadge && nextBadge.name}
                      </span>
                      <br />
                      <span className="mt-1 text-gray-500 text-sm font-normal">
                        {nextBadge && nextBadge.area}
                      </span>
                      <div className="mt-2">
                        {nextBadge &&
                          nextBadge.objectives &&
                          nextBadge.objectives.map((data, index) => (
                            <li key={index}>{data}</li>
                          ))}
                      </div>
                    </div>
                  }
                  icon={
                    nextBadge &&
                    nextBadge.icon && (
                      <img src={nextBadge.icon.url} alt={nextBadge.icon.name} />
                    )
                  }
                />
              </div>
            )}
            <p className="text-sm top-full font-normal w-28 text-gray-900 dark:text-white">
              {nextBadge && nextBadge.name}
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default Graph
