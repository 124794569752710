import React from 'react'

const SpeedoMeterIcon = ({ className }) => (
  <svg
    className={className}
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fill="currentColor"
      d="m12 16a3 3 0 0 1 -3-3c0-1.12.61-2.1 1.5-2.61l9.71-5.62-5.53 9.58c-.5.98-1.51 1.65-2.68 1.65m0-13c1.81 0 3.5.5 4.97 1.32l-2.1 1.21c-.87-.34-1.87-.53-2.87-.53a8 8 0 0 0 -8 8c0 2.21.89 4.21 2.34 5.65h.01c.39.39.39 1.02 0 1.41s-1.03.39-1.42.01c-1.81-1.81-2.93-4.31-2.93-7.07a10 10 0 0 1 10-10m10 10c0 2.76-1.12 5.26-2.93 7.07-.39.38-1.02.38-1.41-.01s-.39-1.02 0-1.41c1.45-1.45 2.34-3.44 2.34-5.65 0-1-.19-2-.54-2.9l1.21-2.1c.83 1.5 1.33 3.18 1.33 5z"
    />
  </svg>
)

export default SpeedoMeterIcon
