import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

export const getWorkshopData = createAsyncThunk(
  'workshop/getWorkshopData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptors.get(`event/workshop-resources`)
      return response
    } catch (error) {
      return rejectWithValue(error.response)
    }
  },
)

export const downloadWorkshopAllPDF = createAsyncThunk(
  'workshop/downloadWorkshopAllPDF',
  async ({ allUrl, onSuccess, stopLoader }) => {
    const res = allUrl.map(
      async (dataUrl) =>
        await axiosInterceptors
          .get(`event/pdf/${dataUrl.id}`, {
            responseType: 'arraybuffer',
          })
          .then(async (response) => {
            try {
              var newBlob = new Blob([response], {
                type: 'application/octet-stream',
              })

              const url = window.URL.createObjectURL(newBlob)
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', `${dataUrl.title}.pdf`)
              document.body.appendChild(link)
              link.click()
              stopLoader && stopLoader()
            } catch (error) {
              stopLoader && stopLoader()
            }
          }),
    )
    onSuccess && onSuccess()
    return res
  },
)

export const downloadWorkshopPDF = createAsyncThunk(
  'workshop/downloadWorkshopPDF',
  async ({ data, stopLoader }) => {
    return await axiosInterceptors
      .get(`event/pdf/${data.id}`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        try {
          var newBlob = new Blob([response], {
            type: 'application/octet-stream',
          })

          const url = window.URL.createObjectURL(newBlob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${data.title}.pdf`)
          document.body.appendChild(link)
          link.click()
          stopLoader && stopLoader()
        } catch (error) {
          stopLoader && stopLoader()
        }
      })
  },
)

export const uploadWorkshopFile = createAsyncThunk(
  'workshop/uploadWorkshopFile',
  async ({ workshopPDF, activitySheetId, onSuccess }, { rejectWithValue }) => {
    const formData = new FormData()
    formData.append('file', workshopPDF)
    formData.append('sheet_type', activitySheetId)
    try {
      const res = await axiosInterceptors.post(
        `event/activity-sheet/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const deleteActivitySheet = createAsyncThunk(
  'workshop/deleteActivitySheet',
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.delete(`event/activity-sheet/${id}`)
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getWorkshopStatus = createAsyncThunk(
  'workshop/getWorkshopStatus',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/bookings/get-workshop-status`,
      )
      return response
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

const workshopSlice = createSlice({
  name: 'workshop',
  initialState: {
    workshopData: { isLoading: false, data: [], isLocked: false },
    showUploadImage: false,
    workshopImage: [],
    fileUploadError: '',
    deleteFileError: '',
    activitySheet: '',
    uploadingLoader: false,
    downloadAllLoader: false,
    downloadingId: null,
    workshopStatus: { isLoading: false, data: null },
  },
  extraReducers: {
    [getWorkshopStatus.pending]: (state, { payload }) => {
      state.workshopStatus.isLoading = true
    },
    [getWorkshopStatus.fulfilled]: (state, { payload }) => {
      state.workshopStatus.isLoading = false
      state.workshopStatus.data = payload.data
    },
    [getWorkshopStatus.rejected]: (state, { payload }) => {
      state.workshopStatus.isLoading = false
    },
    [getWorkshopData.pending]: (state, action) => {
      state.workshopData.isLoading = true
    },
    [getWorkshopData.fulfilled]: (state, { payload }) => {
      const activityIndex1 = findIndex(payload.data.activity_sheets, {
        sheet_type: 'activity-sheet-1',
      })
      const activityIndex2 = findIndex(payload.data.activity_sheets, {
        sheet_type: 'activity-sheet-2',
      })
      state.workshopData = {
        data: {
          ...payload.data,
          activity_sheets: [
            {
              id:
                payload.data.activity_sheets &&
                payload.data.activity_sheets[activityIndex1]?.sheet_type ===
                  'activity-sheet-1' &&
                payload.data.activity_sheets[activityIndex1]?.id,
              title: 'AR Activity Sheet 1',
              sheet_type:
                payload.data.activity_sheets &&
                payload.data.activity_sheets[activityIndex1]?.sheet_type ===
                  'activity-sheet-1'
                  ? payload.data.activity_sheets[activityIndex1]?.sheet_type
                  : 'activity-sheet-1',
              file_url:
                payload.data.activity_sheets &&
                payload.data.activity_sheets[activityIndex1]?.sheet_type ===
                  'activity-sheet-1'
                  ? payload.data.activity_sheets[activityIndex1]?.file_url
                  : '',
              isFileUploaded:
                payload.data.activity_sheets &&
                payload.data.activity_sheets[activityIndex1]?.sheet_type ===
                  'activity-sheet-1' &&
                payload.data.activity_sheets[0]?.file_url
                  ? true
                  : false,
            },
            {
              id:
                payload.data.activity_sheets &&
                payload.data.activity_sheets[activityIndex2]?.sheet_type ===
                  'activity-sheet-2' &&
                payload.data.activity_sheets[activityIndex2]?.id,
              title: 'AR Activity Sheet 2',
              sheet_type:
                payload.data.activity_sheets &&
                payload.data.activity_sheets[activityIndex2]?.sheet_type ===
                  'activity-sheet-2'
                  ? payload.data.activity_sheets[activityIndex2]?.sheet_type
                  : 'activity-sheet-2',
              file_url:
                payload.data.activity_sheets &&
                payload.data.activity_sheets[activityIndex2]?.sheet_type ===
                  'activity-sheet-2'
                  ? payload.data.activity_sheets[activityIndex2]?.file_url
                  : '',
              isFileUploaded:
                payload.data.activity_sheets &&
                payload.data.activity_sheets[activityIndex2]?.sheet_type ===
                  'activity-sheet-2' &&
                payload.data.activity_sheets[activityIndex2]?.file_url
                  ? true
                  : false,
            },
          ],
        },
        isLoading: false,
        isLocked: false,
      }
    },
    [getWorkshopData.rejected]: (state, { payload }) => {
      state.workshopData.isLoading = false
      if (payload?.status === 403) {
        state.workshopData.isLocked = true
      }
    },
    [uploadWorkshopFile.pending]: (state, { payload }) => {
      state.uploadingLoader = true
    },
    [uploadWorkshopFile.fulfilled]: (state, { meta, payload }) => {
      const activitySheetIndex = findIndex(
        state.workshopData.data.activity_sheets,
        {
          sheet_type: meta.arg.activitySheetId,
        },
      )
      state.workshopData.data.activity_sheets[activitySheetIndex] = {
        ...state.workshopData.data.activity_sheets[activitySheetIndex],
        ...payload.data,
        isFileUploaded: true,
      }
      state.uploadingLoader = false
    },

    [uploadWorkshopFile.rejected]: (state, { payload }) => {
      state.uploadingLoader = false

      if (payload.errors) {
        state.fileUploadError = payload.errors.file[0]
      } else if (payload.message) {
        state.fileUploadError = payload.message
      }
    },
    [deleteActivitySheet.pending]: (state, { payload }) => {},
    [deleteActivitySheet.fulfilled]: (state, { meta, payload }) => {
      const activitySheetIndex = findIndex(
        state.workshopData.data.activity_sheets,
        {
          id: meta.arg.id,
        },
      )
      state.workshopData.data.activity_sheets[
        activitySheetIndex
      ].isFileUploaded = false
    },

    [deleteActivitySheet.rejected]: (state, { meta, payload }) => {
      if (payload.errors) {
        state.deleteFileError = payload.errors.file[0]
      } else if (payload.message) {
        state.deleteFileError = {
          id: meta.arg.id,
          error: payload.message,
        }
      }
    },
  },
  reducers: {
    setDownloadingId: (state, { payload }) => {
      state.downloadingId = payload
    },
    setDownloadAllLoader: (state, { payload }) => {
      state.downloadAllLoader = payload
    },
    setWorkshopStatus: (state, { payload }) => {
      state.workshopStatus.data = payload
    },
    toggleUploadImage: (state, { payload }) => {
      state.showUploadImage = payload
      state.deleteFileError = ''
      state.fileUploadError = ''
    },
    setWorkshopImage: (state, { payload }) => {
      state.workshopImage = payload
    },
    showActivitySheet: (state, { payload }) => {
      state.activitySheet = payload
    },
  },
})

export const {
  setDownloadingId,
  setDownloadAllLoader,
  toggleUploadImage,
  setWorkshopStatus,
  setWorkshopImage,
  showActivitySheet,
} = workshopSlice.actions

export default workshopSlice.reducer
