const Eye = ({ className, ...props }) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 22 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 14.25C7.46131 14.25 4.41108 12.2677 1.86829 8C4.41108 3.73232 7.46131 1.75 11 1.75C14.5387 1.75 17.5889 3.73232 20.1317 8C17.5889 12.2677 14.5387 14.25 11 14.25ZM11 0.25C6.63999 0.25 3.09715 2.81857 0.348826 7.62788C0.217058 7.85846 0.217058 8.14154 0.348826 8.37212C3.09715 13.1814 6.63999 15.75 11 15.75C15.36 15.75 18.9028 13.1814 21.6512 8.37212C21.7829 8.14154 21.7829 7.85846 21.6512 7.62788C18.9028 2.81857 15.36 0.25 11 0.25ZM9.75 8C9.75 7.30964 10.3096 6.75 11 6.75C11.6904 6.75 12.25 7.30964 12.25 8C12.25 8.69036 11.6904 9.25 11 9.25C10.3096 9.25 9.75 8.69036 9.75 8ZM11 5.25C9.48122 5.25 8.25 6.48122 8.25 8C8.25 9.51878 9.48122 10.75 11 10.75C12.5188 10.75 13.75 9.51878 13.75 8C13.75 6.48122 12.5188 5.25 11 5.25Z"
      fill="currentColor"
    />
  </svg>
)

export default Eye
