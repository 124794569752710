const WalkthroughVideoIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    className={`${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.75 8c0-.69.56-1.25 1.25-1.25h8c.69 0 1.25.56 1.25 1.25v8c0 .69-.56 1.25-1.25 1.25H5c-.69 0-1.25-.56-1.25-1.25V8zm12 7.213V16A2.75 2.75 0 0113 18.75H5A2.75 2.75 0 012.25 16V8A2.75 2.75 0 015 5.25h8A2.75 2.75 0 0115.75 8v.787l3.468-1.734a1.75 1.75 0 012.532 1.565v6.764a1.75 1.75 0 01-2.532 1.565l-3.468-1.734zm0-1.677l4.138 2.07a.249.249 0 00.33-.103.25.25 0 00.032-.121V8.618a.25.25 0 00-.362-.223l-4.138 2.069v3.072z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default WalkthroughVideoIcon
