const PercentageBadge = (props) => {
  return (
    // <Tooltip
    //   className="max-w-xs"
    //   text={
    //     props?.tooltip ||
    //     'Your weakness. We suggest you to train your question type skills more.'
    //   }
    // >
    <div
      className={`px-0.5 py-2 absolute -top-2 -right-5 h-6 w-10  rounded-full border border-white dark:border-dark-600 flex items-center justify-center text-sm font-normal text-white ${
        props?.percent < 90 ? 'group bg-orange-700' : 'bg-green-700'
      }`}
    >
      {props?.percent}%
    </div>
    // </Tooltip>
  )
}
export default PercentageBadge
