const Bookmark = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 12 14"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.66683 1.5C2.44582 1.5 2.23385 1.5878 2.07757 1.74408C1.92129 1.90036 1.8335 2.11232 1.8335 2.33333V12.0284L5.70954 9.2598C5.88339 9.13562 6.11693 9.13562 6.29078 9.2598L10.1668 12.0284V2.33333C10.1668 2.11232 10.079 1.90036 9.92275 1.74408C9.76647 1.5878 9.55451 1.5 9.3335 1.5H2.66683ZM1.37047 1.03697C1.71428 0.693154 2.1806 0.5 2.66683 0.5H9.3335C9.81973 0.5 10.286 0.693154 10.6299 1.03697C10.9737 1.38079 11.1668 1.8471 11.1668 2.33333V13C11.1668 13.1873 11.0622 13.3589 10.8956 13.4446C10.7291 13.5303 10.5286 13.5157 10.3762 13.4069L6.00016 10.2811L1.62412 13.4069C1.47171 13.5157 1.27124 13.5303 1.10471 13.4446C0.93817 13.3589 0.833496 13.1873 0.833496 13V2.33333C0.833496 1.8471 1.02665 1.38079 1.37047 1.03697Z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Bookmark
