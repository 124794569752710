const Trash = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.549 2.549a.167.167 0 01.118-.049h2.666a.167.167 0 01.167.167v1.5h-3v-1.5c0-.045.018-.087.049-.118zM5.5 4.167v-1.5A1.167 1.167 0 016.667 1.5h2.666A1.167 1.167 0 0110.5 2.667v1.5h2.833a.5.5 0 110 1h-.206l-.627 7.522a1.833 1.833 0 01-1.833 1.811H5.333A1.833 1.833 0 013.5 12.69l-.627-7.523h-.206a.5.5 0 010-1H5.5zm4.5 1H3.877l.621 7.458a.499.499 0 01.002.042.833.833 0 00.833.833h5.334a.833.833 0 00.833-.833c0-.014 0-.028.002-.042l.621-7.458H10zM6.667 6.833a.5.5 0 01.5.5v4a.5.5 0 11-1 0v-4a.5.5 0 01.5-.5zm3.166.5a.5.5 0 10-1 0v4a.5.5 0 101 0v-4z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Trash
