import { Fragment } from 'react'
import { Close, Loading } from 'components/icons'
import { Transition, Dialog } from '@headlessui/react'
import { Button } from 'components/shared'
const CommunityTermsModal = ({
  open,
  isLoading,
  onSubmit,
  onClose,
  agreement,
}) => {
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="bg-gray-700 bg-opacity-80 fixed max-w-full max-h-screen h-screen w-full inset-0 z-50  overflow-y-auto"
          onClose={() => onClose && onClose()}
        >
          <div className="min-h-screen h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-top"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                style={{ maxWidth: '762px' }}
                className="inline-block overflow-y-hidden max-w-full md:max-w-md  md:h-auto w-full p-5 mt-16 md:mt-12 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-dark-600 shadow-xl rounded-md  "
              >
                {onClose && (
                  <span
                    className="cursor-pointer float-right"
                    onClick={() => onClose()}
                  >
                    <Close className="fill-current dark:text-white" />
                  </span>
                )}
                <div className="px-1 py-11 sm:p-11  flex flex-col justify-between">
                  <Dialog.Title
                    as="h2"
                    className="mt-8 text-2xl  font-semibold leading-6 text-gray-900 dark:text-white"
                  >
                    Accept Terms and Conditions
                  </Dialog.Title>
                  <div className="html-renderer-container">
                    <p className="text-base font-normal text-gray-900 dark:text-white">
                      Thank you for participating in the MedEntry community.
                      Please remember that the purpose is to communicate with{' '}
                      <span className="font-semibold">other students</span>, not
                      with MedEntry. If you wish to communicate with MedEntry,
                      please click on the relevant links (eg. ‘Report’) or visit
                      our{' '}
                      <a
                        href={agreement?.user_website_address + '/contact-us'}
                        target="_blank"
                        rel="noreferrer"
                      >
                        contact us page
                      </a>
                      .
                    </p>
                    <p className="text-base font-normal text-gray-900 dark:text-white">
                      To ensure the best possible experience for all members,
                      please follow the guidelines below:
                    </p>
                    <ul className="text-gray-900 dark:text-white">
                      <li>
                        Remain respectful of MedEntry students and staff members
                        at all times
                      </li>
                      <li>
                        Before posting a question, conduct a search as your
                        query may have been answered previously
                      </li>
                      <li>Keep content relevant to the question or resource</li>
                      <li>
                        Note that illegal content, obscene language, attacks and
                        insults are prohibited
                      </li>
                      <li>
                        Respect the privacy of others and do not ask for their
                        personal details
                      </li>
                      <li>No spam, advertising or self-promotion is allowed</li>
                    </ul>
                    <p className="text-base font-normal text-gray-900 dark:text-white">
                      MedEntry reserves the right to suspend or terminate access
                      to any student who violates the above rules.
                    </p>
                  </div>
                  <div className="flex">
                    <Button
                      className="mt-10 flex items-center  font-semibold tracking-widest py-4 px-6"
                      onClick={() => onSubmit && onSubmit()}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <Loading className="mr-4" />
                          Loading
                        </>
                      ) : (
                        'I agree'
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default CommunityTermsModal
