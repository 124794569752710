import {
  Calculator as CalculatorIcon,
  Maximize,
  Minimize,
} from 'components/icons'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setDisabledCalculator,
  updateOptionClickable,
  updateShowCalculator,
} from 'redux/exam-portal/examPortalSlice'
import {
  setBookingResourceTab,
  setFullBookScreen,
  setIsClickedTab,
} from 'redux/learn/resourceSlice'

const BookingQuizTopArea = () => {
  const dispatch = useDispatch()

  const { questionsIndex, isFullBookScreen } = useSelector((state) => ({
    questionsIndex: state.resource.questionsIndex,
    isFullBookScreen: state.resource.isFullBookScreen,
  }))

  const openCalculator = () => {
    dispatch(setDisabledCalculator(false))
    dispatch(updateShowCalculator(true))
  }
  const handleMyNotes = () => {
    dispatch(setIsClickedTab(true))
    dispatch(setBookingResourceTab(1))
    setTimeout(() => {
      const section = document.querySelector('.notes-discussion')
      section?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }, 200)
  }

  const handleDiscussion = () => {
    dispatch(setIsClickedTab(true))
    dispatch(setBookingResourceTab(0))
    setTimeout(() => {
      const section = document.querySelector('.notes-discussion')
      section?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }, 200)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shortKeyEventHandlers = (e) => {
    if (e.altKey && e.code === 'KeyC') {
      e.preventDefault()
      e.stopPropagation()
      dispatch(setDisabledCalculator(false))
      dispatch(updateShowCalculator(true))
      dispatch(updateOptionClickable())
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', shortKeyEventHandlers)
    return () => document.removeEventListener('keydown', shortKeyEventHandlers)
  }, [shortKeyEventHandlers])

  return (
    <div
      className={`text-base sm:text-sm md:text-base flex flex-col sm:flex-row items-start sm:items-center justify-between px-2 sm:px-6 overflow-auto py-1 sm:py-2 text-gray-900 bg-gray-50 dark:bg-dark-500 dark:text-white`}
    >
      <div className="relative flex sm:justify-start items-center gap-4 sm:gap-2 md:gap-4  py-2 sm:py-0 w-full ">
        <h4 className="sm:mt-0 font-semibold">Question {questionsIndex + 1}</h4>
        <button
          onClick={openCalculator}
          className="text-xs font-semibold tracking-widest cursor-pointer p-0.5 tour-calculator"
        >
          <CalculatorIcon className="h-3 w-3" />
        </button>
      </div>
      <div className="flex items-center ">
        <button
          onClick={handleMyNotes}
          className={`ml-0 sm:ml-2 pr-6 text-sm  border-r-2 border-gray-300`}
        >
          My&nbsp;Notes
        </button>
        <button
          onClick={handleDiscussion}
          className={`px-6 text-sm  border-r-2 border-gray-300`}
        >
          Discussion
        </button>
        <div
          className="pt-1 pl-6 h-full cursor-pointer tour-full-screen"
          onClick={() => dispatch(setFullBookScreen(!isFullBookScreen))}
        >
          {isFullBookScreen ? <Minimize /> : <Maximize />}
        </div>
      </div>
    </div>
  )
}

export default BookingQuizTopArea
