import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'

export const getCaptchaKey = createAsyncThunk(
  'payment/getCaptchaKey',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/purchase/get-captcha-key
        `,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const addToCart = createAsyncThunk(
  'payment/addToCart',
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/purchase/add-product-to-cart`,
        {},
        {
          params: { product_id: id },
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const applyVoucherCode = createAsyncThunk(
  'payment/applyVoucherCode',
  async ({ values, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/purchase/apply-voucher-code`,
        values,
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError &&
        errorType?.payload?.errors &&
        onError(errorType?.payload?.errors)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const updateProductCart = createAsyncThunk(
  'payment/updateProductCart',
  async (
    { upgradingFromOrderId, upgradingToProductId, onSuccess },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/purchase/add-upgrade-to-cart`,
        {},
        {
          params: {
            upgrading_from_order_id: upgradingFromOrderId,
            upgrading_to_product_id: upgradingToProductId,
          },
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const getCartDetail = createAsyncThunk(
  'purchases/getCartDetail',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/purchase/get-cart-detail`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const getReviewServices = createAsyncThunk(
  'payment/getReviewServices',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/reviews/get-review-services`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const emptyCart = createAsyncThunk(
  'payment/emptyCart',
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/purchase/empty-cart
        `,
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getPaymentAuthorization = createAsyncThunk(
  'payment/getPaymentAuthorization',
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/purchase/get-authorisation`,
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const processPayment = createAsyncThunk(
  'payment/processPayment',
  async (
    { paymentCallbackURL, cartId, nonce, onSuccess, captcha, donation },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.post(`${paymentCallbackURL}`, {
        cart_id: cartId,
        payment_method_nonce: nonce,
        origin: 'olp',
        hasCapture: captcha ? 'xylophone' : 'trombone',
        'g-recaptcha-response': captcha ? captcha : undefined,
        make_donation: donation ? 'on' : '',
        donation: donation ? donation : undefined,
      })
      onSuccess && onSuccess(res)
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const submitApplicationReview = createAsyncThunk(
  'payment/submitApplicationReview',
  async (data, { rejectWithValue }) => {
    const { reviewData, application, onSuccess, onError } = data
    const formData = new FormData()
    formData.append('order_id', reviewData.order_id)
    formData.append('product_id', reviewData.product_id)
    formData.append('unique', reviewData.unique)
    formData.append('second_review', reviewData.second_review)
    formData.append('application', application)

    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/reviews/submit-application`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      onSuccess && onSuccess(res)
      return res
    } catch (err) {
      const errorType = rejectWithValue(err?.response?.data)
      onError && onError(errorType?.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getExtraTutoringHours = createAsyncThunk(
  'payment/getExtraTutoringHours',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/orders/request-extra-tutoring-hours`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const addTutoringHoursToCart = createAsyncThunk(
  'payment/addTutoringHoursToCart',
  async (
    { productId, hoursRequested, onSuccess, onError },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/purchase/add-tutoring-hours-to-cart`,
        {},
        {
          params: {
            product_id: productId,
            hours_requested: hoursRequested,
          },
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      const errorType = rejectWithValue(err?.response?.data)
      onError && onError(errorType?.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getCharityDetail = createAsyncThunk(
  'payment/getCharityDetail',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/purchase/get-charity-detail`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const processEftPayment = createAsyncThunk(
  'payment/processEftPayment',
  async ({ cartId, onSuccess, captcha, donation }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/checkout/process-eft-payment`,
        {
          cart_id: cartId,
          origin: 'olp',
          hasCapture: captcha ? 'xylophone' : 'trombone',
          'g-recaptcha-response': captcha ? captcha : undefined,
          make_donation: donation ? 'on' : undefined,
          donation: donation ? donation : undefined,
        },
      )
      onSuccess && onSuccess(res)
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const cancelSubscription = createAsyncThunk(
  'payment/cancelSubscription',
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/subscription/cancel-subscription`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const removeProductFromCart = createAsyncThunk(
  'payment/removeProductFromCart',
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/purchase/remove-product-from-cart`,
        { product_id: id },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    paymentToken: { id: null, isLoading: false, data: {} },
    getCartData: {
      isLoading: false,
      data: {},
      isError: false,
      error: null,
      id: null,
    },
    getCartDetails: {
      isLoading: false,
      data: {},
      isError: false,
    },
    purchaseComplete: {
      isLoading: false,
      data: {},
      isError: false,
      error: null,
    },
    disablePlaceOrder: false,
    transactionSuccessful: false,
    emptyCartId: null,
    applyingVoucher: { isLoading: false, errorMessage: null },
    hostedPageId: null,
    tutorRequestModal: false,
    tutorRequestData: null,
    requestTutorLoader: false,
    requestTutorTokenLoader: false,
    reviewServicesModal: false,
    captchaKeyData: null,
    reviewServicesData: null,
    donation: null,
    charityDetail: null,
    eftPurchaseLoader: false,
    cancelSubscriptionData: { isLoading: false, errorMessage: null },
    openReadMoreModal: false,
    removingProductId: null,
  },

  extraReducers: {
    [removeProductFromCart.pending]: (state, { meta }) => {
      state.removingProductId = meta.arg.id
    },
    [removeProductFromCart.fulfilled]: (state, { payload, meta }) => {
      state.removingProductId = null
    },
    [removeProductFromCart.rejected]: (state) => {
      state.removingProductId = null
    },
    [addTutoringHoursToCart.fulfilled]: (state, { payload }) => {
      state.emptyCartId = null
      state.getCartData.isLoading = false
      state.getCartData.data = payload.data
    },
    [addTutoringHoursToCart.pending]: (state, { meta }) => {
      state.requestTutorLoader = false
      state.emptyCartId = null
      state.getCartData.isLoading = true
      state.getCartData.id = meta.arg.upgradingToProductId
    },
    [addTutoringHoursToCart.rejected]: (state, { meta }) => {
      state.requestTutorLoader = false
      state.emptyCartId = null
      state.getCartData.isLoading = false
    },
    [getExtraTutoringHours.pending]: (state) => {},
    [getExtraTutoringHours.fulfilled]: (state, { payload }) => {
      state.tutorRequestData = payload.data
    },
    [getExtraTutoringHours.rejected]: (state) => {},
    [getReviewServices.pending]: (state) => {},
    [getReviewServices.fulfilled]: (state, { payload }) => {
      state.reviewServicesData = payload.data
    },
    [getReviewServices.rejected]: (state) => {},

    [processEftPayment.pending]: (state, { meta }) => {
      state.purchaseComplete.isLoading = true
    },
    [processEftPayment.fulfilled]: (state, { payload }) => {
      state.purchaseComplete.isLoading = false
      state.disablePlaceOrder = true
      state.purchaseComplete.data = payload.data
      state.transactionSuccessful = true
    },
    [processEftPayment.rejected]: (state, { payload }) => {
      state.purchaseComplete.isError = true
      state.purchaseComplete.error = payload
      state.purchaseComplete.isLoading = false
      state.disablePlaceOrder = true
    },
    [getCharityDetail.fulfilled]: (state, { payload }) => {
      state.charityDetail = payload
    },
    [getExtraTutoringHours.pending]: (state) => {},
    [getExtraTutoringHours.fulfilled]: (state, { payload }) => {
      state.tutorRequestData = payload.data
    },
    [getExtraTutoringHours.rejected]: (state) => {},
    [getCaptchaKey.fulfilled]: (state, { payload }) => {
      state.captchaKeyData = payload?.data
    },

    [applyVoucherCode.pending]: (state, { meta }) => {
      state.applyingVoucher.isLoading = true
    },
    [applyVoucherCode.fulfilled]: (state, { payload }) => {
      state.applyingVoucher.isLoading = false
      if (payload?.message) {
        toast.success(payload?.message, {
          duration: 4000,
          position: 'top-center',
          style: {
            backdropFilter: 'blur(4px)',
            marginTop: '20px',
            width: '350px',
          },
        })
      }
    },
    [applyVoucherCode.rejected]: (state, { payload }) => {
      state.applyingVoucher.isLoading = false
      state.applyingVoucher.errorMessage = payload?.message
    },
    [getCartDetail.pending]: (state, { meta }) => {
      state.getCartDetails.isLoading = true
    },
    [getCartDetail.fulfilled]: (state, { payload, meta }) => {
      state.getCartDetails.isLoading = false
      state.getCartDetails.data = payload.data
    },
    [getCartDetail.rejected]: (state) => {
      state.getCartDetails.isLoading = false
      state.getCartDetails.data = null
    },
    [emptyCart.pending]: (state, { meta }) => {
      state.emptyCartId = meta.arg.id
      state.requestTutorLoader = true
    },
    [emptyCart.fulfilled]: (state, { payload, meta }) => {
      state.emptyCartId = meta.arg.id
    },
    [emptyCart.rejected]: (state, action) => {
      state.emptyCartId = null
    },
    [addToCart.pending]: (state, { meta }) => {
      state.emptyCartId = null
      state.getCartData.isLoading = true
    },
    [addToCart.fulfilled]: (state, { payload }) => {
      state.emptyCartId = null
      state.getCartData.isLoading = false
      state.getCartData.data = payload.data
    },
    [addToCart.rejected]: (state, action) => {
      state.emptyCartId = null
      state.getCartData.isLoading = false
    },

    [addTutoringHoursToCart.pending]: (state, { meta }) => {},
    [addTutoringHoursToCart.fulfilled]: (state, { payload }) => {
      state.emptyCartId = null
      state.getCartData.isLoading = false
      state.getCartData.data = payload.data
    },
    [addTutoringHoursToCart.pending]: (state, { meta }) => {
      state.requestTutorLoader = false
      state.emptyCartId = null
      state.getCartData.isLoading = true
      state.getCartData.id = meta.arg.upgradingToProductId
    },
    [updateProductCart.fulfilled]: (state, { payload }) => {
      state.emptyCartId = null
      state.getCartData.isLoading = false
      state.getCartData.data = payload.data
    },
    [updateProductCart.rejected]: (state, { payload }) => {
      state.emptyCartId = null
      state.getCartData.isLoading = false

      state.getCartData.isError = true
      state.getCartData.error = payload
      toast.error(
        payload && payload.data && payload?.data?.error?.length > 0
          ? payload.data.error[0]
          : 'Something went wrong, Please try again later ',
        {
          duration: 3000,
          position: 'top-center',
          style: {
            backdropFilter: 'blur(4px)',
            marginTop: '20px',
            width: '450px',
          },
        },
      )
    },
    [getPaymentAuthorization.pending]: (state, { meta }) => {
      state.requestTutorTokenLoader = true
      state.paymentToken.isLoading = true
      state.paymentToken.id = meta.arg.productId
    },
    [getPaymentAuthorization.fulfilled]: (state, { payload }) => {
      state.requestTutorLoader = false
      state.requestTutorTokenLoader = false
      state.paymentToken.id = null
      state.paymentToken.isLoading = false
      state.paymentToken.data = payload.data
    },
    [getPaymentAuthorization.rejected]: (state, action) => {
      state.requestTutorLoader = false
      state.requestTutorTokenLoader = false
      state.paymentToken.isLoading = false
      state.paymentToken.id = null
    },
    [processPayment.pending]: (state, { meta }) => {
      state.purchaseComplete.isLoading = true
    },
    [processPayment.fulfilled]: (state, { payload }) => {
      state.purchaseComplete.isLoading = false
      state.disablePlaceOrder = true
      state.purchaseComplete.data = payload.data
      state.transactionSuccessful = true
    },
    [processPayment.rejected]: (state, { payload }) => {
      state.purchaseComplete.isError = true
      state.purchaseComplete.error = payload
      state.purchaseComplete.isLoading = false
      state.disablePlaceOrder = true
    },
    [cancelSubscription.pending]: (state) => {
      state.cancelSubscriptionData.isLoading = true
    },
    [cancelSubscription.fulfilled]: (state, { payload }) => {
      state.cancelSubscriptionData.isLoading = false
      if (payload?.message) {
        toast.success(payload?.message, {
          duration: 4000,
          position: 'top-center',
          style: {
            backdropFilter: 'blur(4px)',
            marginTop: '20px',
            width: '350px',
          },
        })
      }
    },
    [cancelSubscription.rejected]: (state, { payload }) => {
      state.cancelSubscriptionData.isLoading = false
      state.cancelSubscriptionData.errorMessage = payload
      toast.error(
        payload && payload && payload?.error?.length > 0
          ? payload.message
          : 'Something went wrong, Please try again later ',
        {
          duration: 3000,
          position: 'top-center',
          style: {
            backdropFilter: 'blur(4px)',
            marginTop: '20px',
            width: '450px',
          },
        },
      )
    },
  },
  reducers: {
    setOpenReadMoreModal: (state, { payload }) => {
      state.openReadMoreModal = payload
    },
    setTutorRequestModal: (state, { payload }) => {
      state.tutorRequestModal = payload
    },
    setReviewServicesModal: (state, { payload }) => {
      state.reviewServicesModal = payload
    },
    setDonation: (state, { payload }) => {
      state.donation = payload
    },
    setHostedPageId: (state, { payload }) => {
      state.hostedPageId = payload
    },
    clearPromoCodeError: (state, { payload }) => {
      state.applyingVoucher.errorMessage = null
    },
    setPurchaseLoading: (state, { payload }) => {
      state.purchaseComplete.isLoading = payload
    },
    clearTabData: (state, { payload }) => {
      state.transactionSuccessful = false
      state.purchaseComplete = {
        isLoading: false,
        data: {},
        isError: false,
        error: null,
      }
      state.disablePlaceOrder = false
    },
    clearPaymentModel: (state, { payload }) => {
      state.purchaseComplete = {
        isLoading: false,
        data: {},
        isError: false,
        error: null,
      }
      state.disablePlaceOrder = false
      state.transactionSuccessful = false
      state.hostedPageId = null
    },
  },
})

export const {
  setOpenReadMoreModal,
  setTutorRequestModal,
  clearTabData,
  setDonation,
  setHostedPageId,
  setReviewServicesModal,
  clearPromoCodeError,
  setPurchaseLoading,
  clearPaymentModel,
} = paymentSlice.actions

export default paymentSlice.reducer
