import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
Bugsnag.start({
  apiKey:
    process?.env?.REACT_APP_BUGSNAG_KEY || 'b17af366e01b9b85cd0094e1165a181c',
  plugins: [new BugsnagPluginReact()],
})
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
const userData = JSON.parse(localStorage.getItem('user-data'))

Bugsnag.setUser(
  userData?.id,
  userData?.email,
  userData?.first_name
    ? `${userData?.first_name} ${userData?.last_name}`
    : undefined,
)

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
