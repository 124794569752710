import React, { useEffect } from 'react'
import { RadioGroup } from '@headlessui/react'
import { Loading, Calculator as CalculatorIcon } from 'components/icons'
import { Button } from 'components/shared'
import HTMLRenderer from 'react-html-renderer'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearVideoPollResponse,
  getResourceData,
  setPollQuestionIndex,
  setPopOut,
  setSelectedPoll,
  submitVideoPollResponse,
} from 'redux/learn/resourceSlice'
import {
  setDisabledCalculator,
  updateShowCalculator,
} from 'redux/exam-portal/examPortalSlice'
import Popup from 'components/icons/Popup'
import PollExplanationVideo from './PollExplanationVideo'

const VideoPollMainArea = ({
  setIsPollModalOpen,
  setModalClosedId,
  chapterId,
  onClosePoll,
  setPlayAudio,
  viewAnswer,
  setViewAnswer,
  selectedAnswerId,
  setSelectedAnswerId,
}) => {
  const dispatch = useDispatch()
  const { resourceSlug } = useParams()
  const {
    popOut,
    pollQuestionIndex,
    submitVideoPollLoader,
    selectedPoll,
    retryVideoPollLoader,
    videoPollArray,
    optionClickable,
    isFullScreen,
  } = useSelector((state) => ({
    popOut: state.resource.popOut,
    pollQuestionIndex: state.resource.pollQuestionIndex,
    submitVideoPollLoader: state.resource.submitVideoPollLoader,
    selectedPoll: state.resource.selectedPoll,
    retryVideoPollLoader: state.resource.retryVideoPollLoader,
    videoPollArray: state.resource.videoPollArray,
    optionClickable: state.examPortal.optionClickable,
    isFullScreen: state.resource.isFullScreen,
  }))

  const selectedQuestion = selectedPoll?.questions[pollQuestionIndex]

  const answerUserResponse = selectedQuestion?.answers?.find(
    (data) => data?.user_response,
  )
  const showRetry = (answerUserResponse && !viewAnswer) || false

  const scrollToQuestion = () => {
    const res = document?.querySelector('.poll-question')
    res?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    })
  }

  const nextPoll = videoPollArray?.find((poll) => !poll?.isViewed)
  const handleSkip = () => {
    scrollToQuestion()
    setSelectedAnswerId(null)
    setViewAnswer(false)
    dispatch(setPollQuestionIndex(pollQuestionIndex + 1))
  }

  const handleModalNext = () => {
    setPlayAudio(false)
    dispatch(setPollQuestionIndex(0))
    dispatch(setSelectedPoll(nextPoll))
  }

  const handleModalClose = () => {
    setViewAnswer(false)
    setSelectedAnswerId(null)
    setIsPollModalOpen(false)
    dispatch(setSelectedPoll(null))
    setModalClosedId(selectedPoll?.id)
    dispatch(setPollQuestionIndex(0))
    onClosePoll && onClosePoll()
  }
  const handleCheckAnswer = () => {
    setViewAnswer(false)
    dispatch(
      submitVideoPollResponse({
        chapterId,
        questionId: selectedQuestion?.id,
        answerId: selectedAnswerId,
        selectedPoll,
      }),
    )
      .unwrap()
      .then(() => {
        setSelectedAnswerId(null)
        scrollToQuestion()
        setViewAnswer(true)
        dispatch(
          getResourceData({
            // resourceType: resourceType.toLowerCase(),
            resourceSlug: resourceSlug,
            isFromPoll: true,
          }),
        )
      })
  }

  const handleTryAgain = () => {
    dispatch(
      clearVideoPollResponse({
        chapterId,
        questionId: selectedQuestion?.id,
        userResponseId: answerUserResponse?.user_response?.id,
        answerId: selectedAnswerId,
        selectedPoll,
      }),
    )
      .unwrap()
      .then(() => {
        setSelectedAnswerId(null)
        scrollToQuestion()
        setViewAnswer(false)
        setSelectedAnswerId(null)
        dispatch(
          getResourceData({
            // resourceType: resourceType.toLowerCase(),
            resourceSlug: resourceSlug,
            isFromPoll: true,
          }),
        )
      })
  }

  const openCalculator = () => {
    dispatch(setDisabledCalculator(false))
    dispatch(updateShowCalculator(true))
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      const key = event.key.toLowerCase()
      const options = ['a', 'b', 'c', 'd', 'e']

      if (options.includes(key) && optionClickable && !event.altKey) {
        const index = options.indexOf(key)
        const optionElements = document.querySelectorAll('.radio-option')
        if (optionElements[index]) {
          optionElements[index].click()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [optionClickable])

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center px-10 py-2 sm:py-4  border-b border-gray-100 dark:border-dark-500">
        <div className="flex items-center gap-2   ">
          <h3 className="font-semibold ">Question</h3>
          <button
            onClick={openCalculator}
            className="flex  items-center justify-between text-xs text-gray-900 dark:text-white font-semibold tracking-widest cursor-pointer "
          >
            <CalculatorIcon className="h-3 w-3" />
          </button>
        </div>
        {!isFullScreen && (
          <button
            onClick={() => {
              dispatch(setPopOut(!popOut))
            }}
          >
            {popOut ? (
              <Popup className="dark:text-white transform rotate-180" />
            ) : (
              <Popup className="dark:text-white " />
            )}
          </button>
        )}
      </div>

      <div
        className={`px-2 sm:px-10 overflow-auto custom-scroll  ${
          popOut ? '' : 'flex-1'
        }`}
      >
        <div className="border-b dark:border-dark-500 poll-question html-renderer-container">
          <HTMLRenderer html={selectedQuestion?.question_html} />
        </div>
        <div className={`${showRetry ? 'relative' : ''}`}>
          {showRetry && (
            <div className="z-10 absolute flex w-full justify-center items-center mt-6">
              <div className="flex flex-col gap-3 justify-center items-center">
                <div className="font-semibold px-4 text-center">
                  You have already responded to this question.
                </div>
                <div className="flex gap-4">
                  <Button
                    onClick={() => {
                      setViewAnswer(true)
                    }}
                    className="px-4 py-2 font-semibold tracking-widest"
                  >
                    View Answer
                  </Button>
                  <Button
                    onClick={() => {
                      handleTryAgain()
                    }}
                    outlined
                    className={`px-4 py-2 font-semibold tracking-widest ${
                      retryVideoPollLoader
                        ? 'pointer-events-none opacity-25'
                        : ''
                    }`}
                  >
                    {retryVideoPollLoader ? <Loading /> : 'Try Again'}
                  </Button>
                </div>
              </div>
            </div>
          )}
          <div className={`${showRetry ? 'filter blur-md relative' : ''}`}>
            <RadioGroup
              value={
                answerUserResponse?.user_response
                  ? answerUserResponse?.user_response
                      ?.video_chapter_poll_answer_id
                  : selectedAnswerId
              }
              onChange={(id) => {
                !answerUserResponse?.user_response && setSelectedAnswerId(id)
              }}
            >
              {selectedQuestion?.answers?.length > 0 ? (
                selectedQuestion?.answers?.map((data, index) => (
                  <RadioGroup.Option
                    key={index}
                    value={data.id}
                    className="outline-none"
                  >
                    {({ checked }) => (
                      <div
                        className={`py-4 border-b dark:border-dark-500 radio-option`}
                      >
                        <div
                          className={`flex gap-2 justify-between items-center`}
                        >
                          <div
                            className={`flex items-start gap-2 ${
                              answerUserResponse?.user_response
                                ? ''
                                : 'cursor-pointer'
                            }`}
                          >
                            <div
                              style={{ minWidth: '20px' }}
                              className={` ${
                                answerUserResponse?.user_response
                                  ?.video_chapter_poll_answer_id === data?.id &&
                                checked
                                  ? ''
                                  : checked
                                  ? 'border-red-500 bg-red-500 cursor-pointer'
                                  : answerUserResponse?.user_response
                                  ? ''
                                  : 'cursor-pointer'
                              } border-gray-900 dark:border-dark-200 rounded-full border  h-5 w-5 flex 
                    justify-center items-center mt-0.5`}
                            >
                              {answerUserResponse?.user_response
                                ?.video_chapter_poll_answer_id === data?.id ? (
                                <div
                                  style={{ minWidth: '8px' }}
                                  className="bg-gray-400 dark:bg-dark-200 h-2 w-2 rounded-full"
                                ></div>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="flex gap-2 ">
                              {String.fromCharCode(64 + (index + 1))}.
                              <span className="text-gray-900 dark:text-white text-base font-normal">
                                <HTMLRenderer html={data?.answer} />
                              </span>
                            </div>
                          </div>
                          {answerUserResponse?.user_response && (
                            <div className="flex items-center gap-2">
                              <div
                                style={{ minWidth: '40px' }}
                                className={
                                  'overflow-hidden w-full text-xs flex items-center rounded-md flex-grow bg-gray-100 dark:bg-dark-400'
                                }
                              >
                                <div
                                  style={{
                                    width: `${data?.percentage_chosen}%`,
                                  }}
                                  className="relative shadow-none h-0.5 flex flex-col pr-2  rounded-r-md whitespace-nowrap text-white justify-center bg-red-500"
                                ></div>
                              </div>
                              <span className="text-gray-900 text-sm dark:text-white w-10">
                                {Math.round(data?.percentage_chosen * 100) /
                                  100}
                                %
                              </span>
                            </div>
                          )}
                        </div>
                        {answerUserResponse?.user_response && (
                          <div className="ml-6 mt-2 text-white text-xs flex text-center">
                            {answerUserResponse?.user_response
                              ?.video_chapter_poll_answer_id === data?.id &&
                            data?.is_correct ? (
                              <span className="px-2 py-1 rounded-lg bg-green-700 ">
                                Your Answer is Correct
                              </span>
                            ) : answerUserResponse?.user_response
                                ?.video_chapter_poll_answer_id === data?.id ? (
                              <span className="px-2 py-1 rounded-lg bg-red-500">
                                Your Answer
                              </span>
                            ) : data?.is_correct ? (
                              <span className="px-2 py-1 rounded-lg bg-green-700">
                                Correct Answer
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </RadioGroup.Option>
                ))
              ) : (
                <div className="mt-4">This question has no options.</div>
              )}
            </RadioGroup>

            {!showRetry && answerUserResponse?.user_response ? (
              selectedQuestion?.solution_formatted_video?.original_media
                ?.url ? (
                <div className="my-3">
                  <div className="font-semibold">Explanation Video</div>
                  <PollExplanationVideo
                    solutionFormattedVideo={
                      selectedQuestion?.solution_formatted_video
                    }
                  />
                </div>
              ) : selectedQuestion?.answer_explanation_html ? (
                <div className="my-3">
                  <div className="font-semibold">Explanation</div>
                  <div className="html-renderer-container">
                    <HTMLRenderer
                      html={selectedQuestion?.answer_explanation_html}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className="py-4 px-2 sm:px-10 border-t border-gray-100 dark:border-dark-500 space-x-2">
        {answerUserResponse?.user_response?.video_chapter_poll_answer_id ? (
          pollQuestionIndex + 1 < selectedPoll?.questions?.length && (
            <Button
              onClick={handleSkip}
              className="px-5 py-3 font-semibold tracking-widest"
            >
              Next
            </Button>
          )
        ) : (
          <Button
            disabled={submitVideoPollLoader || !selectedAnswerId}
            onClick={handleCheckAnswer}
            className={`px-5 py-3 font-semibold tracking-widest ${
              submitVideoPollLoader || !selectedAnswerId
                ? 'pointer-events-none opacity-25'
                : ''
            }`}
          >
            {submitVideoPollLoader ? <Loading /> : 'Check Answer'}
          </Button>
        )}
        {!answerUserResponse?.user_response?.video_chapter_poll_answer_id &&
          pollQuestionIndex + 1 < selectedPoll?.questions?.length && (
            <Button
              onClick={handleSkip}
              className="px-5 py-3 font-semibold tracking-widest"
              outlined
            >
              Skip
            </Button>
          )}
        {(answerUserResponse?.user_response?.video_chapter_poll_answer_id ||
          pollQuestionIndex + 1 === selectedPoll?.questions?.length) &&
          (nextPoll ? (
            <Button
              onClick={handleModalNext}
              className="px-5 py-3 font-semibold tracking-widest"
              outlined
            >
              Next Poll
            </Button>
          ) : (
            <Button
              onClick={handleModalClose}
              className="px-5 py-3 font-semibold tracking-widest"
              outlined
            >
              Close
            </Button>
          ))}
      </div>
    </div>
  )
}

export default VideoPollMainArea
