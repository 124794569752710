import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const setRetryAttempt = createAsyncThunk(
  'retryExam/retryAttempt',
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(`quiz/start-retry-attempt/${id}`)
      return res
    } catch (error) {
      throw rejectWithValue(error)
    }
  },
)

export const removeGuessedQuestionsFromRetry = createAsyncThunk(
  'retryExam/removeGuessedQuestionsFromRetry',
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `quiz/remove-guessed-questions-from-retry/${id}`,
      )
      return res
    } catch (error) {
      throw rejectWithValue(error)
    }
  },
)

export const retryAttemptSlice = createSlice({
  name: 'retryExam',
  initialState: {
    retryLoading: false,
    retryAttemptId: null,
    retryQuestionSetAttemptId: null,
    showRetryModal: false,
    retryGuessedData: null,
    removeGuessedLoader: false,
  },

  extraReducers: {
    [removeGuessedQuestionsFromRetry.pending]: (state, { meta }) => {
      state.removeGuessedLoader = true
    },
    [removeGuessedQuestionsFromRetry.fulfilled]: (state, { payload }) => {
      state.removeGuessedLoader = false
    },
    [removeGuessedQuestionsFromRetry.rejected]: (state, action) => {
      state.removeGuessedLoader = false
    },
    //
    [setRetryAttempt.pending]: (state, { meta }) => {
      state.retryLoading = true
      state.retryId = meta.arg.id
    },
    [setRetryAttempt.fulfilled]: (state, { payload }) => {
      state.retryLoading = false
      state.retryId = null
      state.retryQuestionSetAttemptId = payload.data.question_set_attempt_id
    },
    [setRetryAttempt.rejected]: (state, action) => {
      state.retryLoading = false
    },
  },
  reducers: {
    clearRetryData: (state) => {
      state.showRetryModal = false
      state.retryQuestionSetAttemptId = null
      state.retryGuessedData = null
    },
    setShowRetryModal: (state, { payload }) => {
      state.showRetryModal = payload
    },
    setRetryAttemptId: (state, { payload }) => {
      state.retryQuestionSetAttemptId = payload
    },
    setRetryGuessedData: (state, { payload }) => {
      state.retryGuessedData = payload
    },
  },
})
export const {
  clearRetryData,
  setShowRetryModal,
  setRetryAttemptId,
  setRetryGuessedData,
} = retryAttemptSlice.actions
export default retryAttemptSlice.reducer
