import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'

export const getDiscussionDataFirst = createAsyncThunk(
  'discussion/getDiscussionDataFirst',
  async (filter, { rejectWithValue }) => {
    try {
      const params = {
        sort: filter.sortBy?.value || 'recent',
        limit: 10,
        offset: 0,
        search: filter?.search ? filter?.search : undefined,
      }
      if (filter.topic && filter.topic.id !== -1) {
        params.forum_id = filter.topic.id
      }
      const res = await axiosInterceptors.get(
        `community/forum/discussions-list`,
        {
          params,
        },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const markDiscussionAgreement = createAsyncThunk(
  'discussion/markDiscussionAgreement',

  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        '/terms-conditions/accept?area=discussions',
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const getDiscussionData = createAsyncThunk(
  'discussion/getDiscussionData',
  async (filter, offset) => {
    const params = {
      sort: filter.sortBy?.value || 'recent',
      limit: 10,
      offset: filter.offset || 0,
      search: filter?.search ? filter?.search : undefined,
    }
    if (
      filter.discussionFilter.topic &&
      filter.discussionFilter.topic.id !== -1
    ) {
      params.forum_id = filter.discussionFilter.topic.id
    }
    return await axiosInterceptors.get(`community/forum/discussions-list`, {
      params,
    })
  },
)

export const getDiscussionTopic = createAsyncThunk(
  'discussion/getDiscussionTopic',
  async () => {
    return await axiosInterceptors.get(`community/forum/topic-areas`)
  },
)

export const createDiscussion = createAsyncThunk(
  'discussion/createDiscussion',
  async ({ data, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `community/forum/discussion`,
        {},
        {
          params: {
            forum_id: data.topic.id,
            name: data.subject,
            body: data.message,
          },
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const subscribeDiscussionFromList = createAsyncThunk(
  'discussion/subscribeDiscussionFromList',
  async (data) => {
    return await axiosInterceptors.put(`community/forum/subscribe/${data.id}`, {
      state: !data.subscribedByUser,
    })
  },
)
export const likeDescriptionPostFromList = createAsyncThunk(
  'discussion/likeDescriptionPostFromList',
  async (data) => {
    return await axiosInterceptors.put(
      `community/forum/upvote`,
      {
        state: !data.upvoteByUser,
      },
      {
        params: {
          type: data.type,
          type_id: data.id,
        },
      },
    )
  },
)
export const deleteDiscussion = createAsyncThunk(
  'discussion/deleteDiscussion',
  async ({ deleteId, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.delete(
        `community/forum/discussion/${deleteId}`,
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
const DiscussionSlice = createSlice({
  name: 'discussion',
  initialState: {
    discussionData: {
      isLoading: false,
      isLocked: false,
      likeLoadingId: null,
      subscribeLoadingId: null,
      data: [],
    },
    discussionTermsData: { isLoading: false, data: null },
    createDiscussionTopic: {
      topic: null,
    },
    discussionSortBy: [],
    discussionFilter: {
      sortBy: null,
      topic: null,
    },
    discussionOffset: 0,
    discussionDataLength: null,
    isOpen: false,
    viewMobileFilter: false,
    loadingSeeMore: false,
    deleteModal: false,
    deleteDiscussionLoading: false,
    discussionDebounce: null,
    searchDiscussion: null,
  },
  extraReducers: {
    [deleteDiscussion.pending]: (state, { meta }) => {
      state.deleteDiscussionLoading = meta?.arg?.deleteId
    },
    [deleteDiscussion.fulfilled]: (state, action) => {
      state.deleteDiscussionLoading = null
      state.deleteModal = false
    },
    [deleteDiscussion.rejected]: (state) => {
      toast.error('Something went wrong, please try again later', {
        duration: 3000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '400px',
        },
      })
      state.deleteDiscussionLoading = null
      state.deleteModal = false
    },
    [getDiscussionDataFirst.pending]: (state, action) => {
      state.discussionData = { isLoading: true }
      state.loadingSeeMore = true
    },
    [getDiscussionDataFirst.fulfilled]: (state, action) => {
      state.discussionData = {
        isLoading: false,
        data: action.payload.data.discussions,
        isLocked: false,
      }
      state.loadingSeeMore = false
      state.discussionDataLength = action.payload.data.total_discussions
      state.discussionOffset = 0
    },
    [getDiscussionDataFirst.rejected]: (state, { payload }) => {
      state.discussionData.isLoading = false
      if (payload?.status === 403) {
        state.discussionData.isLocked = true
      }
    },
    [getDiscussionData.pending]: (state, action) => {
      state.loadingSeeMore = true
    },
    [getDiscussionData.fulfilled]: (state, action) => {
      state.discussionData = {
        data: [
          ...state.discussionData.data,
          ...action.payload.data.discussions,
        ],
      }
      state.loadingSeeMore = false
    },
    [getDiscussionData.rejected]: (state, action) => {},
    [getDiscussionTopic.pending]: (state, action) => {},
    [getDiscussionTopic.fulfilled]: (state, action) => {
      state.discussionTopic = action.payload
      state.discussionTopic.data.topics = [
        {
          id: -1,
          name: 'All Topics',
        },
        ...state.discussionTopic.data.topics,
      ]
    },
    [getDiscussionTopic.rejected]: (state, action) => {},
    [subscribeDiscussionFromList.pending]: (state, action) => {
      state.discussionData.subscribeLoadingId = action.meta.arg.id
    },
    [subscribeDiscussionFromList.fulfilled]: (state, action) => {
      const postIndex = findIndex(state.discussionData.data, {
        id: action.meta.arg.id,
      })
      state.discussionData.data[postIndex].subscribed_by_user =
        action.payload.data.subscription_state
      state.discussionData.subscribeLoadingId = null
    },
    [subscribeDiscussionFromList.rejected]: (state, action) => {},

    [likeDescriptionPostFromList.pending]: (state, action) => {
      state.discussionData.likeLoadingId = action.meta.arg.id
    },
    [likeDescriptionPostFromList.fulfilled]: (state, action) => {
      const postIndex = findIndex(state.discussionData.data, {
        id: action.meta.arg.id,
      })
      state.discussionData.data[postIndex].num_upvotes =
        action.payload.data.num_upvotes
      state.discussionData.data[postIndex].upvoted_by_user =
        action.payload.data.upvote_state
      state.discussionData.likeLoadingId = null
    },
    [likeDescriptionPostFromList.rejected]: (state, action) => {},
    [createDiscussion.pending]: (state, action) => {
      state.createDiscussionLoading = true
    },
    [createDiscussion.fulfilled]: (state, action) => {
      state.createDiscussionLoading = false
      state.isOpen = false
      state.createDiscussionTopic.topic = null
    },
    [createDiscussion.rejected]: (state, { payload }) => {
      state.createDiscussionLoading = false
      if (payload?.status === 403) {
        state.discussionTermsData.data = payload.data.data
      }
    },
    [markDiscussionAgreement.pending]: (state, action) => {
      state.discussionTermsData.isLoading = true
    },
    [markDiscussionAgreement.fulfilled]: (state, action) => {
      state.discussionTermsData.isLoading = false
      state.discussionTermsData.data.discussion_terms_agreed = true
    },
    [markDiscussionAgreement.rejected]: (state, { payload }) => {
      state.discussionTermsData.isLoading = false
    },
  },

  reducers: {
    setSearchDiscussion: (state, { payload }) => {
      state.searchDiscussion = payload
    },
    updateDiscussionDebounce: (state, { payload }) => {
      state.discussionDebounce = payload
    },
    toggleDeleteModal: (state, { payload }) => {
      state.deleteModal = payload
    },
    closeAgreementModal: (state) => {
      if (state?.discussionTermsData?.data?.discussion_terms_agreed === false)
        state.discussionTermsData.data.discussion_terms_agreed = true
    },
    setDiscussionFilterSortBy: (state, action) => {
      state.discussionFilter.sortBy = action.payload
      state.discussionOffset = 0
      state.discussionData = []
    },
    setDiscussionFilterTopic: (state, { payload }) => {
      if (payload?.id !== state.discussionFilter?.topic?.id) {
        state.discussionFilter.topic = payload
        state.discussionOffset = 0
        state.discussionData = []
      }
    },
    setDiscussionFilterOffset: (state, action) => {
      state.discussionOffset += 10
    },
    resetDiscussionFilterOffset: (state, action) => {
      state.discussionOffset = 0
    },
    setCreateDiscussionTopic: (state, action) => {
      state.createDiscussionTopic.topic = action.payload
      state.discussionOffset = 0
    },
    setIsOpenDiscussion: (state, { payload }) => {
      state.isOpen = payload
      if (!payload) {
        state.createDiscussionTopic.topic = null
      }
    },
    setViewMobileFilter: (state, action) => {
      state.viewMobileFilter = action.payload
    },
  },
})

export const {
  setSearchDiscussion,
  updateDiscussionDebounce,
  toggleDeleteModal,
  setDiscussionFilterSortBy,
  setDiscussionFilterTopic,
  setDiscussionFilterOffset,
  setCreateDiscussionTopic,
  setIsOpenDiscussion,
  setViewMobileFilter,
  resetDiscussionFilterOffset,
  closeAgreementModal,
} = DiscussionSlice.actions

export default DiscussionSlice.reducer
