import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'
import { findIndex } from 'lodash'

export const getFilters = createAsyncThunk(
  'university/getFilters',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`university-courses/get-filters`)
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)
export const RequiredHpatScore = createAsyncThunk(
  'university/RequiredHpatScore',
  async ({ desiredTotal, rawLc }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `/hpat-leaving-certificate/calculate-required-hpat-score?desired_total=${desiredTotal}&raw_lc=${rawLc}`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err?.response?.data)
    }
  },
)
export const RequiredLeavingPoints = createAsyncThunk(
  'university/RequiredLeavingPoints',
  async ({ desiredTotal, hpatScore }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `/hpat-leaving-certificate/calculate-required-leaving-certificate-score?desired_total=${desiredTotal}&hpat_score=${hpatScore}`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err?.response?.data)
    }
  },
)
export const getUniversityCourses = createAsyncThunk(
  'university/getUniversityCourses',
  async (data, { rejectWithValue }) => {
    try {
      return await axiosInterceptors.get(`university-courses`, {
        params: data?.isUkStudent
          ? {
              search: data.search,
              course_type: data.courseType,
              admission_test: data.entryType,
              location: data.state,
              records_per_page: 200,
              page: data.page || 1,
              show_picked: data.showPicked,
            }
          : {
              search: data.search,
              course_type: data.courseType,
              entry_type: data.entryType,
              state: data.state,
              records_per_page: 200,
              page: data.page || 1,
              show_picked: data.showPicked,
            },
      })
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)
export const setUserInterestCourse = createAsyncThunk(
  'university/setUserInterestCourse',
  async (data) => {
    return await axiosInterceptors.put(
      `university-courses/user-interest-course/${data.id}`,
      {
        is_interested: data.isInterested,
      },
    )
  },
)

const universityAdmissionSlice = createSlice({
  name: 'university',
  initialState: {
    isLoading: false,
    filters: { loading: false, data: [], isLocked: false },
    searchText: null,
    pagination: null,
    courseType: null,
    state: null,
    entryType: null,
    checkboxLoading: { isLoading: false, id: null },
    universityCourses: [],
    coursesDebounce: null,
    showPickedCourses: false,
    feedbackCourseId: null,
    leavingCertificateCalculator: {
      isLoading: false,
      data: null,
      isRejected: false,
    },
  },
  extraReducers: {
    [RequiredHpatScore.pending]: (state) => {
      state.leavingCertificateCalculator.isLoading = true
      state.leavingCertificateCalculator.isRejected = false
    },
    [RequiredHpatScore.fulfilled]: (state, { payload }) => {
      state.leavingCertificateCalculator.isLoading = false
      state.leavingCertificateCalculator.isRejected = false
      state.leavingCertificateCalculator.data = payload?.data?.html
    },
    [RequiredHpatScore.rejected]: (state) => {
      state.leavingCertificateCalculator.isLoading = false
      state.leavingCertificateCalculator.isRejected = true
      state.leavingCertificateCalculator.data = null
    },
    [RequiredLeavingPoints.pending]: (state) => {
      state.leavingCertificateCalculator.isLoading = true
      state.leavingCertificateCalculator.isRejected = false
    },
    [RequiredLeavingPoints.fulfilled]: (state, { payload }) => {
      state.leavingCertificateCalculator.isLoading = false
      state.leavingCertificateCalculator.isRejected = false
      state.leavingCertificateCalculator.data = payload?.data?.html
    },
    [RequiredLeavingPoints.rejected]: (state) => {
      state.leavingCertificateCalculator.isLoading = false
      state.leavingCertificateCalculator.isRejected = true
      state.leavingCertificateCalculator.data = null
    },
    [setUserInterestCourse.pending]: (state) => {
      state.checkboxLoading.isLoading = true
    },
    [setUserInterestCourse.fulfilled]: (state, action) => {
      if (action.meta.arg.id) {
        const updateCourseIndex = findIndex(state.universityCourses, {
          id: action.meta.arg.id,
        })
        state.universityCourses[updateCourseIndex] = {
          ...state.universityCourses[updateCourseIndex],
          user_interest_course: action.payload.data.is_interested,
        }
      }
      state.checkboxLoading.isLoading = false
      state.checkboxLoading.id = null
    },
    [setUserInterestCourse.rejected]: (state, { payload }) => {
      state.checkboxLoading.isLoading = false
      state.checkboxLoading.id = null
    },
    [getFilters.pending]: (state, action) => {
      state.filters.loading = true
    },
    [getFilters.fulfilled]: (state, { payload }) => {
      state.filters.loading = false
      const { data } = payload
      state.filters.data = data?.filters
    },
    [getFilters.rejected]: (state, { payload }) => {
      state.filters.loading = false
      if (payload?.status === 403) {
        state.filters.isLocked = true
      }
    },
    [getUniversityCourses.pending]: (state, action) => {
      state.isLoading = true
    },
    [getUniversityCourses.fulfilled]: (state, action) => {
      state.isLoading = false
      const { data, pagination } = action.payload
      state.universityCourses = data.university_courses
      state.pagination = pagination
    },
    [getUniversityCourses.rejected]: (state, { payload }) => {
      state.isLoading = false
    },
  },
  reducers: {
    resetLeavingCertResult: (state) => {
      state.leavingCertificateCalculator = {
        isLoading: false,
        data: null,
        isRejected: false,
      }
    },
    updateFeedbackCourseId: (state, { payload }) => {
      state.feedbackCourseId = payload
    },
    updateSearchText: (state, { payload }) => {
      state.searchText = payload
    },
    updateCheckBoxLoading: (state, { payload }) => {
      state.checkboxLoading.id = payload
    },
    updateCourseType: (state, { payload }) => {
      state.courseType = payload
    },
    updateState: (state, { payload }) => {
      state.state = payload
    },
    updateEntryType: (state, { payload }) => {
      state.entryType = payload
    },
    updateCoursesDebounce: (state, { payload }) => {
      state.coursesDebounce = payload
    },
    updateShowPickedCourses: (state) => {
      state.showPickedCourses = !state.showPickedCourses
    },
  },
})

export const {
  resetLeavingCertResult,
  updateSearchText,
  updateCourseType,
  updateState,
  updateCoursesDebounce,
  updateEntryType,
  updateCheckBoxLoading,
  updateShowPickedCourses,
  updateFeedbackCourseId,
} = universityAdmissionSlice.actions

export default universityAdmissionSlice.reducer
