import { useEffect, useMemo, useState } from 'react'
import {
  Bell,
  ChevronDown,
  Logout,
  Recent,
  Search,
  Settings,
  User,
  Menu as MenuIcon,
  Close,
  Light,
  Moon,
  Book,
  Bookmark,
  Bookmarked,
  Loading,
  Calendar,
  Chat,
  Flag,
  Faq,
  About,
} from 'components/icons'
import moment from 'moment-timezone'
import { Button, Spinner } from 'components/shared'
import { debounce } from 'lodash'
import { useTour } from '@reactour/tour'
import { useSelector } from 'react-redux'
import { Popover, Menu, Transition } from '@headlessui/react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  toggleNavigation,
  toggleSearchBar,
  // toggleTheme,
  updateSearchText,
  updateSearchDebounce,
  getSearchData,
  getNotifications,
  markAsReadNotification,
  getTestDate,
  getRecentActivity,
  markAsReadAll,
  getUserAccessArea,
  getPlatformStatus,
  clearPlatformStatus,
  // setOpenMenu,
} from 'redux/dashboard/dashboardSlice'
import { NoRecord } from 'components/no-record'
import { Logo, SmallLogo } from './Logos'
import {
  // clearTourData,
  restartTour,
  setExploreTour,
} from 'redux/tour-steps/tourStepsSlice'
import { BadgeAwardGraph } from 'components/dashboard'
import {
  getDarkMode,
  getParticularSettings,
  getSettingInfo,
  saveSettingInfo,
} from 'redux/profile/settingSlice'
import { Modal } from 'components/form'
import { logoutUser } from 'redux/profile/personalInfoSlice'
import Help from 'components/icons/Help'
import { getActivityRedirectUrl, getContactUrl, isUcatPath } from '../helper'
import CartIcon from 'components/icons/CartIcon'
import WalkthroughVideoIcon from 'components/icons/WalkthroughVideoIcon'
import BookTour from 'components/icons/BookTour'

const profileMenu = [
  {
    title: 'Shop',
    path: '/profile/my-purchases',
    icon: <CartIcon className="fill-current dark:text-white" />,
  },
  {
    title: 'My Account',
    path: '/profile/personal-info',
    icon: <User className="fill-current dark:text-white" />,
  },
  {
    title: 'Orientation',
    path: '/orientation',
    icon: <Book className="fill-current dark:text-white" />,
  },
  {
    title: 'Settings',
    path: '/profile/settings',
    icon: <Settings className="fill-current dark:text-white" />,
  },
  {
    title: 'Log out',
    path: '/login',
    icon: <Logout className="fill-current dark:text-white" />,
  },
]

const themes = [
  { id: 1, name: 'Light', icon: <Light className="fill-current" /> },
  { id: 2, name: 'Dark', icon: <Moon className="fill-current" /> },
]
const getNotificationText = (data) => {
  switch (data?.type) {
    case 'StudyPlannerItemNowOverdue':
      return `Your scheduled calendar item is now overdue: ${data.data.calendar_item_name}`
    case 'StudyPlannerItemsDueInNext12HoursNotification':
      return `${data?.data?.title}: ${data?.data?.message}`
    case 'ForumDiscussionResponseSubmitted':
      return `New reply added to discussion topic: ${data.data.discussion_name}`
    case 'StudyPlannerDueInNextHourNotification':
      return `${data?.data?.title}: ${data?.data?.message}`
    case 'BadgeAwarded':
      return `New badge awarded: ${data?.data?.badge?.name}`
    case 'OnlineTutorResponseNotification':
      return data?.data?.title
    default:
      return `New response to your question: ${
        data?.data?.question_subject || ''
      }`
  }
}
const getUrlOfSkillTrainer = (name) => {
  switch (name) {
    case 'Eureka Trainer':
      return '/skill-trainers/eureka-trainer'
    case 'Arithmetic Trainer':
      return `${isUcatPath}/skill-trainers/arithmetic-trainer`
    case 'Graph Table Trainer':
      return `${isUcatPath}/skill-trainers/graph-table-trainer`
    case 'Keyword Scanning Trainer':
      return `${isUcatPath}/skill-trainers/keyword-scanning-trainer`
    case 'Numeric Keypad Trainer':
      return `${isUcatPath}/skill-trainers/numeric-keypad-trainer`
    case 'Speed Reading Trainer':
      return `${isUcatPath}/skill-trainers/speed-reading-trainer`
    case 'Syllogisms Trainer':
      return `${isUcatPath}/skill-trainers/syllogisms-trainer`
    case 'Venn Diagram Trainer':
      return `${isUcatPath}/skill-trainers/venn-diagram-trainer`
    case 'Emotions Word Game Trainer':
      return '/skill-trainers/emotions-word-game-trainer'
    default:
      return `${isUcatPath}/skill-trainers`
  }
}
const DashboardNavigation = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  // const getNotificationsData = () => {
  //   dispatch(getNotifications({}))
  // }
  const history = useHistory()
  const {
    sidebarExpanded,
    showSearchBar,
    showNavigation,
    tourStepsData,
    userData,
    globalSearchDebounce,
    searchText,
    notificationsData,
    tourPageName,
    openMenu,
    // darkmode,
    settingLoader,
    // monthDate,
    hideUcatTestDaysCount,
    settingIndex,
    ucatTestCountdownIndex,
    settingLoaderIndex,
    olpPath,
    settingTestDate,
    logoutLoading,
    recentActivity,
    markAsReadNotifications,
    settingInformation,
    userAccessArea,
    isSyllogismsPage,
    exploreTour,
    platformStatus,
  } = useSelector((state) => ({
    platformStatus: state.dashboard.platformStatus,
    exploreTour: state.tourSteps.exploreTour,
    sidebarExpanded: state.dashboard.sidebarExpanded,
    showSearchBar: state.dashboard.showSearchBar,
    showNavigation: state.dashboard.showNavigation,
    selectedTheme: state.dashboard.selectedTheme,
    tourStepsData: state.tourSteps.tourStepsData,
    userData: state.personalInfo.userData,
    logoutLoading: state.personalInfo.logoutLoading,
    globalSearchDebounce: state.dashboard.globalSearchDebounce,
    searchText: state.dashboard.searchText,
    notificationsData: state.dashboard.notificationsData,
    openMenu: state.dashboard.openMenu,
    tourPageName: state.tourSteps.tourPageName,
    // darkmode: state.settings.darkmode,
    settingLoader: state.settings.settingLoader,
    // monthDate: state.calendar.monthDate,
    hideUcatTestDaysCount: state.settings.hideUcatTestDaysCount,
    settingIndex: state.settings.settingIndex,
    ucatTestCountdownIndex: state.settings.ucatTestCountdownIndex,
    settingLoaderIndex: state.settings.settingLoaderIndex,
    olpPath: state.personalInfo.olpPath,
    settingTestDate: state.dashboard.settingTestDate,
    recentActivity: state.dashboard.recentActivity,
    markAsReadNotifications: state.dashboard.markAsReadNotifications,
    settingInformation: state.settings.settingInformation,
    userAccessArea: state.dashboard.userAccessArea,
    isSyllogismsPage: state.tourSteps.isSyllogismsPage,
  }))

  const isOrientationVideos =
    userAccessArea?.data?.includes('orientation-videos')

  const { setIsOpen, setSteps, setCurrentStep, afterOpen } = useTour()
  const userToken = localStorage.getItem('bearer_token')
  const [dateModal, setDateModal] = useState(false)
  let startDate = moment().startOf('week').format('YYYY-MM-DD')
  let endDate = moment().endOf('week').format('YYYY-MM-DD')
  const current = moment().startOf('day').format('YYYY-MM-DD')

  const testDate = settingTestDate?.setting?.student_value
    ? settingTestDate?.setting?.student_value
    : settingTestDate?.setting?.default_value
  const difference = moment(testDate, 'YYYY-MM-DD').diff(
    moment(current, 'YYYY-MM-DD'),
    'days',
  )
  const contactUrl = getContactUrl(userData?.website)

  useEffect(() => {
    let interval
    if (userToken) {
      dispatch(getNotifications({}))
      interval = setInterval(() => {
        const notificationsDate = localStorage.getItem('notifications-date')
        const parsedDate = moment(
          notificationsDate,
          'ddd MMM DD YYYY HH:mm:ss [GMT]Z',
        )
        const notificationsDiff = moment().diff(parsedDate, 'minutes')
        if (notificationsDiff >= 10) {
          dispatch(getNotifications({}))
        }
      }, 6000)
    }
    return () => clearInterval(interval)
  }, [dispatch, userToken])

  useEffect(() => {
    if (userToken && olpPath) {
      dispatch(getParticularSettings({ settingType: 'test_countdown_notice' }))
      dispatch(getTestDate())
    }
  }, [dispatch, endDate, startDate, userToken, olpPath])

  useEffect(() => {
    if (userToken && olpPath) {
      dispatch(getDarkMode())
      dispatch(getUserAccessArea())
    }
    return () => {
      setIsOpen(false)
      setCurrentStep(1)
    }
  }, [dispatch, userToken, olpPath])

  // Call getUserAccessArea every 15 minutes
  useEffect(() => {
    if (userToken && olpPath) {
      const interval = setInterval(() => {
        dispatch(getUserAccessArea())
      }, 900000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [dispatch, olpPath, userToken])

  useEffect(() => {
    if (userToken && olpPath && !platformStatus?.data) {
      dispatch(getPlatformStatus())
    }
  }, [dispatch, olpPath, platformStatus?.data, userToken])

  useEffect(() => {
    return () => {
      dispatch(clearPlatformStatus())
    }
  }, [dispatch])

  const onSearch = (query) => {
    dispatch(updateSearchText(query.target.value))
    if (!globalSearchDebounce) {
      dispatch(
        updateSearchDebounce(
          debounce(() => {
            if (query) {
              dispatch(
                getSearchData({
                  term: query.target.value,
                }),
              )
              history.replace(
                `/search${
                  query.target.value ? `?term=${query.target.value}` : ''
                }`,
              )
            }
          }, 600),
        ),
      )
    }
    if (query.target.value && query.target.value.length >= 3) {
      globalSearchDebounce && globalSearchDebounce()
    } else {
      history.replace(
        `/search${query.target.value ? `?term=${query.target.value}` : ''}`,
      )
    }
  }

  const tutorType = ['OnlineTutorResponseNotification']

  const getRedirectUrl = (response) => {
    const { data, type } = response
    if (type === 'StudyPlannerItemsDueInNext12HoursNotification') {
      return `${isUcatPath}/calendar`
    }
    if (type === 'OnlineTutorResponseNotification') {
      return '/bookmarks/my-online-queries'
    }
    if (
      type === 'StudyPlannerItemNowOverdue' ||
      type === 'StudyPlannerDueInNextHourNotification'
    ) {
      if (data?.location_breadcrumbs?.length > 0) {
        switch (data?.calendar_item_type) {
          case 'Exam':
            return `${isUcatPath}/qbank/full-exam-mocks`
          case 'Exam Retry':
            return `${isUcatPath}/qbank/history/full-exam`
          case 'Exam Review':
            return `${isUcatPath}/qbank/history/full-exam`
          case 'QBank':
            return `${isUcatPath}/qbank/practice/subtest`
          case 'Subtest Mock':
            return `${isUcatPath}/qbank/subtest-mocks`
          case 'Section Mock':
            return '/qbank/section-mocks'
          case 'Skills Trainer':
            return getUrlOfSkillTrainer(data?.calendar_item_name)
          case 'Official UCAT Practice Exam':
            return `${isUcatPath}/qbank/full-exam-mocks`
          case 'Module':
            return data?.location_breadcrumbs?.length > 0
              ? `${isUcatPath}/${data?.location_breadcrumbs[0]?.area}/module/${data?.location_breadcrumbs[0]?.id}`
              : `${isUcatPath}/learn`
          default:
            return `${isUcatPath}/calendar`
        }
      } else {
        return `${isUcatPath}/calendar`
      }
    } else if (type === 'ForumDiscussionResponseSubmitted') {
      return `/community/discussion/${data.discussion_id}`
    } else if (type === 'ResourceQuestionAnswerSubmitted') {
      const chapter = data?.location_breadcrumbs?.find(
        (data) => data.type === 'book_chapter',
      )
      const questionAttempt =
        data?.location_breadcrumbs?.length > 0 &&
        data?.location_breadcrumbs?.find(
          (location) => location?.type === 'question_attempt',
        )

      if (chapter) {
        const module = data?.location_breadcrumbs?.find(
          (data) => data?.type === 'module',
        )
        const subModule = data?.location_breadcrumbs?.find(
          (data) => data?.type === 'submodule',
        )
        const resource = data?.location_breadcrumbs?.find(
          (data) => data?.type === 'resource',
        )

        const resourceType = data.resource_type === 'Scorm' ? 'scorm' : 'book'
        const subModuleOrResource =
          subModule && module && module.data && module.data.area === 'learn'
            ? subModule.id
            : resource && resource.id
        const link =
          module && resource
            ? `${isUcatPath}/${module?.data?.area}/${resource.data.slug}/${
                chapter.data.ordering || 1
              }${!tutorType?.includes(type) ? '?resourceType=discussion' : ''}`
            : '#'
        return link
      } else if (questionAttempt) {
        const questionAttempt =
          data?.location_breadcrumbs?.length > 0 &&
          data?.location_breadcrumbs?.find(
            (location) => location?.type === 'question_attempt',
          )

        const quizAttemptable =
          data?.location_breadcrumbs?.length > 0 &&
          data?.location_breadcrumbs?.find(
            (location) => location?.type === 'quiz_attemptable',
          )
        if (data?.location_breadcrumbs?.length > 0 && questionAttempt) {
          const sectionId = questionAttempt?.data?.question_section_id
          const attemptId = questionAttempt?.data?.question_set_attempt_id
          const questionAttemptId = questionAttempt?.id
          const attemptType =
            quizAttemptable?.data?.type === 'subtestmock'
              ? 'subtest-exam'
              : quizAttemptable?.data?.type === 'exam'
              ? 'full-exam'
              : 'practice-review'
          return `${isUcatPath}/qbank/history/exam-review/${attemptType}/${sectionId}/${attemptId}?question_id=${questionAttemptId}&resourceType=discussion`
        } else {
          return '#'
        }
      }
    } else {
      const module = data?.location_breadcrumbs?.find(
        (data) => data?.type === 'module',
      )
      const subModule = data?.location_breadcrumbs?.find(
        (data) => data?.type === 'submodule',
      )
      const resource = data?.location_breadcrumbs?.find(
        (data) => data?.type === 'resource',
      )
      const chapter = data?.location_breadcrumbs?.find(
        (data) => data.type === 'book_chapter',
      )
      // const section = data.location_breadcrumbs.find(
      //   (data) => data.type === 'section',
      // )
      // const questionAttempt = data.location_breadcrumbs.find(
      //   (data) => data.type === 'question_attempt',
      // )
      // const subtestAttempt = data.location_breadcrumbs.find(
      //   (data) => data.data.type === 'subtestmock',
      // )
      const resourceType = data.resource_type === 'Scorm' ? 'scorm' : 'book'
      const subModuleOrResource =
        subModule && module && module.data && module.data.area === 'learn'
          ? subModule.id
          : resource && resource.id
      const link =
        module && resource
          ? `${isUcatPath}/${module?.data?.area}/${resource.data.slug}/${
              chapter.data.ordering || 1
            }${
              !tutorType?.includes(type) ? '?resourceType=tutor-question' : ''
            }`
          : '#'
      return link
    }
  }

  const tabletView = window.matchMedia('(max-width: 768px)')

  const [matches, setMatches] = useState(tabletView.matches)
  useEffect(() => {
    const handler = (e) => setMatches(e.matches)
    tabletView.addListener(handler)
    return () => tabletView.removeListener(handler)
  })
  const [badgesData, setBadgesData] = useState()
  const logout = async () => {
    const downloadAppInteraction = JSON.parse(
      localStorage.getItem('downloadAppLastInteraction'),
    )
    await localStorage.clear()
    downloadAppInteraction &&
      localStorage.setItem('downloadAppLastInteraction', downloadAppInteraction)
    await sessionStorage.clear()
    window.location.reload(false)
  }

  const activityDate = localStorage.getItem('recent-activity-date')
  const activityData = JSON.parse(localStorage.getItem('recent-activity-data'))
  const activityDiff = moment().diff(activityDate, 'minutes')
  // Tour Explore step
  const exploreTourSteps = useMemo(
    () => [
      {
        selector: '.tour-icon',
        content: (
          <div className="">
            <div className="px-6 pt-4 text-gray-900 dark:text-white font-normal mt-2 flex items-start gap-2 ">
              You can view the page tour again at any time by clicking here
            </div>
            <div className="px-4 mt-4 ml-2">
              <Button
                onClick={() => {
                  setIsOpen(false)
                  dispatch(setExploreTour(false))
                }}
                className={`text-sm flex items-center font-semibold px-4 py-2 btn-primary text-white tracking-widest`}
              >
                Ok
              </Button>
            </div>
          </div>
        ),
      },
    ],
    [dispatch, setIsOpen],
  )
  useEffect(() => {
    if (dateModal) {
      dispatch(getSettingInfo())
    }
  }, [dateModal, dispatch])

  useEffect(() => {
    if (exploreTour) {
      const timer = setTimeout(() => {
        afterOpen()
        setSteps(exploreTourSteps)
        setIsOpen(true)
        setCurrentStep(0)
        clearTimeout(timer)
      }, 1000)
    }
  }, [
    afterOpen,
    exploreTour,
    exploreTourSteps,
    setCurrentStep,
    setIsOpen,
    setSteps,
  ])

  return (
    <>
      <Modal
        open={dateModal}
        title="Would you like to switch off this alert?"
        buttonText="Yes"
        closeButtonText="No"
        onSuccess={() => {
          dispatch(
            saveSettingInfo({
              data: {
                key: 'test_countdown_notice',
                value: true,
                ucatTestCountDown: true,
                subSettingIndex: ucatTestCountdownIndex,
                settingIndex: settingIndex,
              },
              onSuccess: () => {
                setDateModal(false)
              },
            }),
          )
        }}
        onClose={() => setDateModal(false)}
        isLoading={settingLoaderIndex}
        initialLoading={settingInformation.isLoading}
      />
      <div
        className={`sticky flex items-center dark:bg-dark-600 bg-white border-b border-l dark:border-dark-600 h-14 px-4 sm:px-6 md:z-40 z-50 top-0 cursor-pointer ${
          sidebarExpanded ? 'md:ml-64 right-0' : 'md:ml-12  right-0'
        }`}
      >
        <div
          className={`mr-3 sm:mr-4 md:hidden ${
            !showNavigation && matches && 'tour-sidebar'
          }`}
          onClick={() => dispatch(toggleNavigation())}
        >
          {showNavigation ? (
            <Close className="fill-current dark:text-white" />
          ) : (
            <MenuIcon className="fill-current dark:text-white" />
          )}
        </div>
        <Link to="/" className="hidden xs:block md:hidden mr-auto">
          <Logo />
        </Link>
        <Link to="/" className=" xs:hidden mr-auto">
          <SmallLogo />
        </Link>
        <div className="ml-2" onClick={() => dispatch(toggleSearchBar())}>
          <Search
            className={`fill-current dark:text-white block sm:hidden ${
              matches && 'tour-nav-search'
            }`}
          />
        </div>
        <div
          className={`${showSearchBar ? 'absolute flex ' : 'hidden'} ${
            !matches && 'tour-nav-search'
          } sm:relative z-10  top-14 left-1/2 transform -translate-x-1/2 sm:left-auto  sm:translate-x-0 w-64 h-16 bg-white sm:bg-gray-100 dark:bg-dark-500 sm:top-0 sm:flex items-center sm:w-64 rounded-b-md sm:h-8 sm:rounded-md px-2 shadow-md sm:shadow-none`}
        >
          <Search className="fill-current dark:text-white mr-3" />
          <input
            className="bg-transparent dark:text-dark-300 w-64 h-8 rounded-md border-0 outline-none"
            placeholder="Search"
            onChange={onSearch}
            value={searchText || ''}
          />
        </div>
        <Popover className="md:block ml-4 mr-2 sm:mr-3 mt-1 md:mt-0 ">
          {({ open, close }) => (
            <>
              <Popover.Button className="md:flex items-center tour-nav-recent">
                <span
                  onClick={() => {
                    if (!activityDate || !activityData || activityDiff > 5) {
                      dispatch(getRecentActivity())
                    }
                  }}
                >
                  <Recent
                    className={`${
                      open
                        ? 'fill-current text-red-500 dark:text-red-500'
                        : 'fill-current  dark:text-white '
                    }`}
                  />
                </span>
              </Popover.Button>
              <Popover.Panel className="absolute z-50 left-1/2 transform -translate-x-1/2  mt-5 md:mt-4 bg-white dark:bg-dark-600 top-9 md:top-10 md:left-auto w-72 rounded-b-md shadow-md">
                <div>
                  {recentActivity?.isLoading ? (
                    <div className="my-10">
                      <Spinner />
                    </div>
                  ) : activityData?.activities?.length > 0 ? (
                    activityData?.activities?.map((recent, recentIndex) => (
                      <Link
                        to={getActivityRedirectUrl(recent, olpPath)}
                        key={recentIndex}
                        onClick={() => close()}
                        className="px-4 py-3 block text-sm text-gray-900 dark:text-white"
                      >
                        {recent.name}
                      </Link>
                    ))
                  ) : (
                    <div className="px-4 py-3 block text-sm text-gray-900 dark:text-white">
                      No recent activity found
                    </div>
                  )}
                </div>
              </Popover.Panel>
            </>
          )}
        </Popover>
        <div className="md:ml-auto flex items-center md:overflow-hidden">
          {testDate ? (
            !hideUcatTestDaysCount &&
            difference >= 0 && (
              <div
                className="hidden lg:flex items-center gap-2 mr-4"
                onClick={() => setDateModal(true)}
              >
                <div className="flex items-center justify-center p-3 h-8 w-8 bg-red-100 dark:bg-red-100 dark:bg-opacity-10 rounded-full">
                  <Flag className="fill-current text-red-500" />
                </div>
                <div className="flex flex-col mt-1">
                  <div className="text-sm font-semibold leading-3 dark:text-white">
                    {difference}
                  </div>
                  <div className="text-xs text-gray-500 w-max">
                    days until the {olpPath === 'hpat' ? 'HPAT' : 'UCAT'}
                  </div>
                </div>
              </div>
            )
          ) : (
            <></>
          )}
          <div className="flex space-x-4 items-center px-2 sm:px-4  mr-2 sm:mr-3">
            {/* help */}
            <div className="">
              <Popover className="">
                {({ open, close }) => (
                  <>
                    <Popover.Button className="flex items-center md:w-max tour-icon">
                      <Faq
                        className={`${
                          open ? 'text-red-500' : 'text-black dark:text-white '
                        } faq-icon `}
                      />
                    </Popover.Button>

                    <Popover.Panel className="absolute shadow-md z-50 top-14 right-0 sm:right-20 md:right-32 bg-white dark:bg-dark-600 w-52 rounded-b-md py-2 overflow-x-hidden overflow-y-auto">
                      <div className="hover:bg-gray-100 dark:hover:bg-dark-500">
                        <button
                          onClick={() => {
                            const startedTour =
                              localStorage.getItem('started-tour')
                            if (!startedTour) {
                              localStorage.setItem('started-tour', 'true')
                            }
                            if (showNavigation) {
                              dispatch(toggleNavigation())
                            }
                            setIsOpen(false)
                            if (isSyllogismsPage) {
                              dispatch(
                                restartTour({
                                  page: 'syllogisms',
                                }),
                              )
                            }
                            dispatch(
                              restartTour({
                                page: tourPageName,
                              }),
                            )
                          }}
                          className={`flex items-center py-2 px-4 text-sm text-gray-900 dark:text-white w-full ${
                            !tourPageName &&
                            'pointer-events-none opacity-25 cursor-not-allowed'
                          }`}
                        >
                          <BookTour className="h-5 w-5" />
                          <span className="ml-2">Page Tour</span>
                          {tourStepsData.isLoading && (
                            <Loading className="ml-2" />
                          )}
                        </button>
                      </div>
                      <div
                        className="hover:bg-gray-100 dark:hover:bg-dark-500"
                        onClick={() => close && close()}
                      >
                        <Link
                          to="/faq"
                          className="flex items-center px-4 py-2 text-sm text-gray-900 dark:text-white"
                        >
                          <Help />
                          <span className="ml-3">FAQ</span>
                        </Link>
                      </div>
                      {olpPath !== 'hpat' && isOrientationVideos && (
                        <div
                          className="hover:bg-gray-100 dark:hover:bg-dark-500"
                          onClick={() => close && close()}
                        >
                          <Link
                            to="/walkthrough-videos"
                            className="flex gap-2 items-center px-4 py-2 text-sm text-gray-900 dark:text-white"
                          >
                            <WalkthroughVideoIcon className="h-5 w-5" />
                            <span>Platform Tutorials</span>
                          </Link>
                        </div>
                      )}
                      <div
                        className="hover:bg-gray-100 dark:hover:bg-dark-500"
                        onClick={() => close && close()}
                      >
                        <a
                          className="flex items-center px-4 py-2 text-sm text-gray-900 dark:text-white"
                          href={contactUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <About />
                          <span className="ml-3">Contact MedEntry</span>
                        </a>
                      </div>
                    </Popover.Panel>
                  </>
                )}
              </Popover>
            </div>
            {/* bookmark */}
            <Link to="/bookmarks/bookmarks" className="tour-nav-bookmarks">
              <div className="">
                {location.pathname !== '/bookmarks/bookmarks' ? (
                  <Bookmark className="fill-current dark:text-white" />
                ) : (
                  <Bookmarked className="fill-current text-red-500" />
                )}
              </div>
            </Link>
            {badgesData && <BadgeAwardGraph data={badgesData} />}
            {/* notification */}
            <Popover>
              {({ open, close }) => (
                <>
                  <Popover.Button className="flex items-center tour-nav-notifications">
                    <span
                      className="relative w-full"
                      // onClick={() => !open && getNotificationsData()}
                    >
                      {notificationsData.data &&
                      notificationsData.data.notifications &&
                      notificationsData.data.notifications.find(
                        (notification) => !notification.read_at,
                      ) ? (
                        <>
                          <Bell className="fill-current text-red-500 dark:text-red-500" />
                          <span className="absolute flex top-0 right-1">
                            <span className="animate-ping absolute  h-1 w-1 rounded-full bg-red-600 "></span>
                            <span className="relative items-center justify-center inline-flex rounded-full h-1 w-1 bg-red-500"></span>
                          </span>
                        </>
                      ) : (
                        <Bell className="fill-current dark:text-white" />
                      )}
                    </span>
                  </Popover.Button>
                  <Popover.Panel className="absolute shadow-md z-50 top-14 right-0 sm:right-20 md:right-28 bg-white dark:bg-dark-700 sm:w-96 w-72 max-h-96 rounded-b-md py-2 overflow-x-hidden overflow-y-auto">
                    {notificationsData.isLoading ? (
                      <Spinner />
                    ) : notificationsData.data &&
                      notificationsData.data.notifications &&
                      notificationsData.data.notifications.length > 0 ? (
                      <>
                        <div className="text-gray-500 dark:text-dark-200 p-4 pb-2  flex items-center justify-between">
                          New
                          {notificationsData.data &&
                          notificationsData.data.notifications.filter(
                            (notification) => !notification.read_at,
                          ).length > 0 ? (
                            <button
                              onClick={() => {
                                if (!markAsReadNotifications?.isLoading)
                                  dispatch(markAsReadAll())
                              }}
                              disabled={markAsReadNotifications?.isLoading}
                              className={`inline-flex justify-center py-2 font-semibold text-sm text-red-500 cursor-pointer`}
                            >
                              {markAsReadNotifications?.isLoading ? (
                                <div className="h-5">
                                  <Loading />
                                </div>
                              ) : (
                                'Mark all as read'
                              )}
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                        {notificationsData.data &&
                        notificationsData.data.notifications.filter(
                          (notification) => !notification.read_at,
                        ).length > 0 ? (
                          notificationsData.data &&
                          notificationsData.data.notifications
                            .filter((notification) => !notification.read_at)
                            .map((data, notificationIndex) => (
                              <div
                                key={notificationIndex}
                                onClick={() => {
                                  dispatch(
                                    markAsReadNotification({
                                      notificationId: data.id,
                                    }),
                                  )
                                  if (data?.type === 'BadgeAwarded') {
                                    setBadgesData(data?.data?.badge)
                                  } else {
                                    history.push(getRedirectUrl(data))
                                    close && close()
                                  }
                                }}
                                className="gap-4 flex px-4 cursor-pointer bg-gray-50 dark:bg-dark-600 py-4 border-b border-gray-100 dark:border-dark-300"
                              >
                                <div className="w-3">
                                  {data.type ===
                                  'StudyPlannerItemNowOverdue' ? (
                                    <Calendar className="h-3 w-3 fill-current dark:text-white flex-grow" />
                                  ) : data.type ===
                                    'ForumDiscussionResponseSubmitted' ? (
                                    <Chat className="h-3 w-3 fill-current dark:text-white" />
                                  ) : (
                                    <Book className="h-3 w-3 fill-current dark:text-white" />
                                  )}
                                </div>
                                <div className="">
                                  <p className="text-gray-900 dark:text-white">
                                    {getNotificationText(data)}
                                    {/* {data.type === 'StudyPlannerItemNowOverdue'
                                    ? `Your scheduled calendar item is now overdue: ${data.data.calendar_item_name}`
                                    : data?.type === 'BadgeAwarded'
                                    ? `New badge awarded: ${data?.data?.badge?.name}`
                                    : data.type ===
                                      'ForumDiscussionResponseSubmitted'
                                    ? `New reply added to discussion topic: ${data.data.discussion_name}`
                                    : `New response to your question: ${data.data.question_subject}`} */}
                                  </p>
                                  <div className="text-sm text-gray-500 dark:text-dark-200">
                                    {moment(data.updated_at).fromNow()}
                                  </div>
                                </div>
                              </div>
                            ))
                        ) : (
                          <p className="px-4 text-base font-normal text-gray-900 dark:text-white">
                            No notifications found
                          </p>
                        )}
                        <div className="text-gray-500 dark:text-dark-200 p-4 pb-2">
                          Earlier
                        </div>
                        {notificationsData.data &&
                        notificationsData.data.notifications.filter(
                          (notification) => notification.read_at,
                        ).length > 0 ? (
                          notificationsData.data &&
                          notificationsData.data.notifications
                            .filter((notification) => notification.read_at)
                            .map((data, notificationIndex) => (
                              <div
                                key={notificationIndex}
                                onClick={() => {
                                  if (data?.type === 'BadgeAwarded') {
                                    setBadgesData(data?.data?.badge)
                                  } else {
                                    history.push(getRedirectUrl(data))
                                    close && close()
                                  }
                                }}
                                className="gap-4 flex items-start px-4 py-4 border-b border-gray-100 dark:border-dark-300 cursor-pointer"
                              >
                                <div className="w-3">
                                  {data.type ===
                                    'StudyPlannerItemsDueInNext12HoursNotification' ||
                                  data.type === 'StudyPlannerItemNowOverdue' ? (
                                    <Calendar className="h-3 w-3 fill-current dark:text-white flex-grow" />
                                  ) : data.type ===
                                      'ForumDiscussionResponseSubmitted' ||
                                    data?.type ===
                                      'ResourceQuestionAnswerSubmitted' ? (
                                    <Chat className="h-3 w-3 fill-current dark:text-white" />
                                  ) : (
                                    <Book className="h-3 w-3 fill-current dark:text-white" />
                                  )}
                                </div>
                                <div>
                                  <div className="text-gray-900 dark:text-white">
                                    {getNotificationText(data)}
                                    {/* {data.type === 'StudyPlannerItemNowOverdue'
                                    ? `Your scheduled calendar item is now overdue: ${data.data.calendar_item_name}`
                                    : data?.type === 'BadgeAwarded'
                                    ? `New badge awarded: ${data?.data?.badge?.name}`
                                    : data.type ===
                                      'ForumDiscussionResponseSubmitted'
                                    ? `New reply added to discussion topic: ${data.data.discussion_name}`
                                    : `New response to your question: ${data.data.question_subject}`} */}
                                  </div>
                                  <div className="text-sm text-gray-500 dark:text-dark-200">
                                    {moment(data.updated_at).fromNow()}
                                  </div>
                                </div>
                              </div>
                            ))
                        ) : (
                          <p className="px-4 text-base font-normal text-gray-900 dark:text-white">
                            No notifications found
                          </p>
                        )}
                      </>
                    ) : (
                      <div className="mt-3">
                        <NoRecord
                          icon={<Bell className="h-5 w-5" />}
                          title="No notifications found"
                        />
                      </div>
                    )}
                  </Popover.Panel>
                </>
              )}
            </Popover>
          </div>
          <Menu>
            {({ open }) => (
              <>
                <Menu.Button className="flex items-center md:w-max tour-nav-profile-menu">
                  {userData && userData.avatar_url ? (
                    <img
                      src={userData && userData.avatar_url}
                      className="rounded-full  h-8 w-8 mr-2 object-contain"
                      alt="Profile"
                    />
                  ) : (
                    <div className="rounded-full flex items-center justify-center bg-gray-200 dark:bg-dark-700 h-8 w-8 mr-2">
                      <SmallLogo />
                    </div>
                  )}

                  <div className="hidden md:block text-left">
                    <div className="text-gray-900 dark:text-white text-sm">
                      {userData
                        ? userData.first_name || userData.last_name
                          ? `${userData.first_name ? userData.first_name : ''}${
                              userData.last_name
                                ? userData.first_name
                                  ? ' ' + userData.last_name
                                  : userData.last_name
                                : ''
                            }`
                          : userData.first_name || userData.last_name
                          ? `${userData.firstname ? userData.firstname : ''}${
                              userData.lastname
                                ? userData.firstname
                                  ? ' ' + userData.lastname
                                  : userData.lastname
                                : ''
                            }`
                          : ''
                        : 'Not logged in'}
                    </div>
                    <div className="text-gray-500 dark:text-dark-200 text-xs">
                      Student
                    </div>
                  </div>
                  <ChevronDown
                    className={`ml-4 dark:text-white text-gray-900 hidden md:block ${
                      (openMenu || open) && 'transform rotate-180'
                    }`}
                  />
                </Menu.Button>
                <Transition
                  show={openMenu || open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Menu.Items
                    static
                    className="absolute shadow-md z-50 top-14 right-4 bg-white dark:bg-dark-600 w-64 rounded-b-md py-2"
                  >
                    <div className="flex items-center justify-between py-4 px-3">
                      {themes?.map((theme, themeIndex) => (
                        <button
                          key={themeIndex}
                          className={` ${
                            theme.name === 'Dark' && 'tour-dark-mode'
                          }
                        ${
                          theme.name === localStorage.theme
                            ? 'text-gray-900 dark:text-white'
                            : 'text-gray-500 dark:text-dark-300'
                        }  flex items-center `}
                          onClick={(e) => {
                            if (
                              !settingLoader &&
                              theme?.name !== localStorage.theme
                            )
                              dispatch(
                                saveSettingInfo({
                                  data: {
                                    key: 'dark_mode',
                                    value: theme.name === 'Dark' ? true : false,
                                    isThemeChange: true,
                                  },
                                }),
                              )
                          }}
                        >
                          <div
                            className={`${
                              theme.name === localStorage.theme
                                ? 'text-red-500'
                                : ''
                            }
                           h-6 w-6 flex items-center justify-center`}
                          >
                            {theme.icon}
                          </div>
                          <span className="ml-2">{theme.name}</span>
                        </button>
                      ))}
                    </div>
                    <div className="border-t border-gray-100 dark:border-dark-500 mb-4" />
                    {/* <Menu.Item>
                      <button
                        onClick={() => {
                          const startedTour =
                            localStorage.getItem('started-tour')
                          if (!startedTour) {
                            localStorage.setItem('started-tour', 'true')
                          }
                          if (showNavigation) {
                            dispatch(toggleNavigation())
                          }
                          setIsOpen(false)
                          dispatch(
                            restartTour({
                              page: tourPageName,
                            }),
                          )
                        }}
                        className={`flex items-center px-4 py-2 text-sm text-gray-900 dark:text-white 
                    ${
                      !tourPageName &&
                      'pointer-events-none opacity-25 cursor-not-allowed'
                    }`}
                      >
                        <Book />
                        <span className="ml-3">Start Tour</span>
                        {tourStepsData.isLoading && (
                          <Loading className="ml-2" />
                        )}
                      </button>
                    </Menu.Item> */}
                    {profileMenu.map((item, itemIndex) =>
                      item.title === 'Log out' ? (
                        <div
                          key={itemIndex}
                          onClick={(e) => {
                            e.preventDefault()
                            if (!logoutLoading) {
                              dispatch(
                                logoutUser({
                                  onSuccess: () => {
                                    logout()
                                  },
                                  onError: () => {
                                    logout()
                                  },
                                }),
                              )
                            }
                          }}
                        >
                          <div className="flex items-center px-4 py-2 text-sm text-gray-900 dark:text-white">
                            {logoutLoading ? <Loading /> : item.icon}
                            <span className="ml-3">{item.title}</span>
                          </div>
                        </div>
                      ) : (
                        <Menu.Item key={itemIndex}>
                          <Link
                            to={item.path}
                            className="flex items-center px-4 py-2 text-sm text-gray-900 dark:text-white"
                          >
                            {item.icon}
                            <span className="ml-3">{item.title}</span>
                          </Link>
                        </Menu.Item>
                      ),
                    )}
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    </>
  )
}

export default DashboardNavigation
