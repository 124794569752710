import { createAsyncThunk } from '@reduxjs/toolkit'
import moment from 'moment'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'

export const calendarSchedule = createAsyncThunk(
  'calendar/calendarSchedule',
  async (data, { rejectWithValue }) => {
    const endData = moment(data.endDate).add(1, 'day').format('YYYY-MM-DD')
    try {
      const res = await axiosInterceptors.get('/calendar/schedule', {
        params: { start_date: data.startDate, end_date: endData },
      })
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const calendarPlaceableResources = createAsyncThunk(
  'calendar/calendarPlaceableResources',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('/calendar/placeable-resources')
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const createPersonalEvent = createAsyncThunk(
  'calendar/createPersonalEvent',
  async (data) => {
    return await axiosInterceptors.post('/calendar/personal-event', {
      name: data.eventSubject,
      start_time: moment(data.date + data.startTime, 'YYYY-MM-DD HH:mm').format(
        'YYYY-MM-DD HH:mm:ss',
      ),
      duration_minutes: moment(data.endTime, 'HH:mm:ss').diff(
        moment(data.startTime, 'HH:mm:ss'),
        'minutes',
      ),
    })
  },
)

export const createCalendarQuizItem = createAsyncThunk(
  'calendar/createCalendarQuizItem',
  async (
    {
      typeId,
      type,
      include_review,
      startTime,
      date,
      review_duration,
      review_after_attempt,
      review_start_time,
      review_start_date,
      retries,
      onError,
    },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.post(`calendar/calendar-quiz-item`, {
        type_id: typeId,
        type: type,
        include_review: include_review,
        start_time: moment(date + startTime, 'YYYY-MM-DD HH:mm').format(
          'YYYY-MM-DD HH:mm:ss',
        ),
        review_duration: include_review ? review_duration : undefined,
        review_after_attempt: include_review ? review_after_attempt : undefined,
        review_start_time:
          include_review && !review_after_attempt
            ? moment(
                review_start_date + review_start_time,
                'YYYY-MM-DD HH:mm',
              ).format('YYYY-MM-DD HH:mm:ss')
            : undefined,
        retries:
          retries?.length > 0
            ? retries
                ?.filter((retry) => retry.retry_start_time)
                ?.map((retry) => ({
                  retry_start_time: retry.retry_start_time
                    ? moment(
                        retry.retry_date + retry.retry_start_time,
                        'YYYY-MM-DD HH:mm',
                      ).format('YYYY-MM-DD HH:mm:ss')
                    : null,
                  retry_end_time: retry.retry_end_time
                    ? moment(
                        retry.retry_date + retry.retry_end_time,
                        'YYYY-MM-DD HH:mm',
                      ).format('YYYY-MM-DD HH:mm:ss')
                    : null,
                }))
            : [],
      })

      return res
    } catch (err) {
      toast.error(err.response?.data?.message, {
        duration: 3000,
        style: { maxWidth: 'auto' },
      })
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload.errors)
    }
  },
)

export const rescheduleCalendarQuizItem = createAsyncThunk(
  'calendar/rescheduleCalendarQuizItem',
  async (
    {
      typeId,
      type,
      include_review,
      startTime,
      date,
      review_duration,
      review_after_attempt,
      review_start_time,
      review_start_date,
      retries,
      onError,
    },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.patch(
        `calendar/reschedule-quiz-calendar-item/${typeId}`,
        {
          include_review: include_review,
          start_time: moment(date + startTime, 'YYYY-MM-DD HH:mm').format(
            'YYYY-MM-DD HH:mm:ss',
          ),
          review_duration: include_review ? review_duration : undefined,
          review_after_attempt: include_review
            ? review_after_attempt
            : undefined,
          review_start_time:
            include_review && !review_after_attempt
              ? moment(
                  review_start_date + review_start_time,
                  'YYYY-MM-DD HH:mm',
                ).format('YYYY-MM-DD HH:mm:ss')
              : undefined,
          retries:
            retries?.length > 0
              ? retries
                  ?.filter((retry) => retry.retry_start_time)
                  ?.map((retry) => ({
                    retry_start_time: retry.retry_start_time
                      ? moment(
                          retry.retry_date + retry.retry_start_time,
                          'YYYY-MM-DD HH:mm',
                        ).format('YYYY-MM-DD HH:mm:ss')
                      : null,
                    retry_end_time: retry.retry_end_time
                      ? moment(
                          retry.retry_date + retry.retry_end_time,
                          'YYYY-MM-DD HH:mm',
                        ).format('YYYY-MM-DD HH:mm:ss')
                      : null,
                  }))
              : [],
        },
      )

      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload.errors)
    }
  },
)

export const createCalendarItem = createAsyncThunk(
  'calendar/createCalendarItem',
  async (data, { rejectWithValue }) => {
    try {
      return await axiosInterceptors.post('/calendar/calendar-item', {
        start_time: data.startTime,
        type: data.type,
        type_id: data.typeId,
      })
    } catch (err) {
      toast.error(err.response?.data?.message, {
        duration: 3000,
        style: { maxWidth: 'auto' },
      })
      data.onError && data.onError()
      throw rejectWithValue(err.response)
    }
  },
)

export const rescheduleCalendarItem = createAsyncThunk(
  'calendar/rescheduleCalendarItem',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.patch(
        `/calendar/reschedule-calendar-item/${data.id}`,
        {
          new_start_time: data.newStartTime,
          override_duration_minutes: moment(data.endTime, 'HH:mm').diff(
            moment(data.startTime, 'HH:mm'),
            'minutes' || null,
          ),
        },
      )
      data?.onSuccess && data?.onSuccess()
      return res
    } catch (err) {
      toast.error(
        err.response?.data?.message ||
          'Something went wrong, please try again shortly.',
        {
          duration: 3000,
          style: { maxWidth: 'auto' },
        },
      )
      data.onError && data.onError()
      throw rejectWithValue(err.response)
    }
  },
)

export const deleteCalendarItem = createAsyncThunk(
  'calendar/deleteCalendarItem',
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.delete(
        `/calendar/calendar-item/${id}`,
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const clearCalendar = createAsyncThunk(
  'calendar/clearCalendar',
  async ({ startDate, endDate, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.patch('/calendar/clear-calendar', {
        start_date: startDate,
        end_date: endDate,
      })
      onSuccess && onSuccess()
      return res
    } catch (error) {
      onError && onError()
      throw rejectWithValue(error)
    }
  },
)

export const saveCalendarInfo = createAsyncThunk(
  'calendar/saveCalendarInfo',
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(`account/settings`, {
        settings: [{ key: data.key, value: data.value }],
      })
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
