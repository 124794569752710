import React from 'react'
import TimePicker from 'rc-time-picker'
import './TimePicker.scss'
import moment from 'moment'
import { Clock } from 'components/icons'
import { useField } from 'formik'
const TimePickerCalendar = ({
  hideLabel,
  name,
  label,
  helpText,
  focus,
  className,
  disabledHours,
  disabled,
  value,
  onChange,
  ...props
}) => {
  const [inputProps, { error, touched }, { setValue }] = useField(name)
  return (
    <>
      {!hideLabel && (
        <div className="text-sm text-gray-700 dark:text-dark-100 -mb-1">
          {label}
        </div>
      )}
      <div className="relative">
        <Clock className="top-4 z-50 right-3 absolute h-6 w-6 fill-current dark:text-white" />
        <TimePicker
          // onChange={handleValueChange}
          inputProps={{
            ...inputProps,
            ...props,
          }}
          value={value ? moment(value, 'HH:mm:ss') : null}
          showSecond={false}
          disabled={disabled}
          disabledHours={disabledHours}
          allowEmpty
          onChange={(event) => {
            onChange && onChange(event)
            setValue(moment(event._d).format('HH:mm'))
          }}
          className="w-full"
        />
      </div>
      {helpText && <div className="mt-1 text-sm text-gray-700">{helpText}</div>}
      {error && touched && (
        <div>
          <span className="text-xs font-semibold text-red-500">{error}</span>
        </div>
      )}
    </>
  )
}
export default TimePickerCalendar
