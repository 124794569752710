import { useField } from 'formik'
import PropTypes from 'prop-types'

const MInputFile = ({
  hideLabel,
  name,
  label,
  helpText,
  focus,
  className,
  id,
  ...props
}) => {
  const [inputProps, { error, touched }, { setValue }] = useField(name)
  return (
    <>
      <div className="mb-6">
        <label htmlFor={id}>{label}</label>
        <input
          type="file"
          accept="image/*"
          id={id}
          className="absolute opacity-0 w-0"
          {...inputProps}
          {...props}
        />
        {helpText && (
          <div className="mt-1 text-sm text-gray-700">{helpText}</div>
        )}
        {error && touched && (
          <div>
            <span className="text-xs font-semibold text-red-500">{error}</span>
          </div>
        )}
      </div>
    </>
  )
}

MInputFile.propTypes = {
  hideLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
    PropTypes.func,
  ]),
  helpText: PropTypes.string,
  error: PropTypes.string,
}

export default MInputFile
