import React, { useRef, useEffect } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Settings, SettingsModal } from 'components/icons'
import CaptionIcon from 'components/icons/CaptionIcon'
import SpeedoMeterIcon from 'components/icons/SpeedoMeterIcon'
import { useDispatch, useSelector } from 'react-redux'
import {
  setIsPollQualityOpen,
  setIsPollSettingOpen,
} from 'redux/learn/resourceSlice'
import { useTour } from '@reactour/tour'

const PollVideoSetting = ({
  subtitlesEnabled,
  handleToggleSubtitles,
  playbackSpeed,
  setPlaybackSpeed,
  mediaArray,
}) => {
  const { isOpen } = useTour()

  const dispatch = useDispatch()
  const { isPollSettingOpen } = useSelector((state) => ({
    isPollSettingOpen: state.resource.isPollSettingOpen,
  }))

  const buttonRef = useRef(null)
  const popoverRef = useRef(null)

  const speedArray = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]

  const handleDecreaseSpeed = () => {
    const currentIndex = speedArray.indexOf(playbackSpeed)
    if (currentIndex > 0) {
      const newSpeed = speedArray[currentIndex - 1]
      setPlaybackSpeed(newSpeed)
    }
  }

  const handleIncreaseSpeed = () => {
    const currentIndex = speedArray.indexOf(playbackSpeed)
    if (currentIndex < speedArray.length - 1) {
      const newSpeed = speedArray[currentIndex + 1]
      setPlaybackSpeed(newSpeed)
    }
  }

  const isMinSpeed = speedArray[0] === playbackSpeed
  const isMaxSpeed = speedArray[speedArray.length - 1] === playbackSpeed

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        !isOpen
      ) {
        dispatch(setIsPollSettingOpen(false))
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [dispatch, isOpen])

  return (
    <div className="relative">
      <Popover className="h-full flex items-center">
        {({ open }) => (
          <>
            <Popover.Button>
              <span
                ref={buttonRef}
                className="relative flex items-center h-full"
                onClick={() => {
                  dispatch(setIsPollSettingOpen(!isPollSettingOpen))
                }}
              >
                <Settings className="h-5 w-5 text-white" />
              </span>
            </Popover.Button>
            <Transition show={isPollSettingOpen}>
              {isPollSettingOpen && (
                <Popover.Panel
                  ref={popoverRef}
                  className="absolute z-10 bg-white dark:bg-dark-600 shadow-lg rounded-md  py-1"
                  style={{
                    minWidth: '7rem',
                    top: '-6.8rem',
                    left: '-5.7rem',
                  }}
                >
                  <div className="tour-speed-change px-4 flex justify-between items-center gap-2 dark:text-white">
                    <SpeedoMeterIcon className="h-5 w-5" />
                    <div className="flex py-1 items-center">
                      <button
                        className={`font-semibold ${
                          isMinSpeed ? 'opacity-25' : ''
                        }`}
                        onClick={handleDecreaseSpeed}
                      >
                        -
                      </button>
                      <div className="text-xs w-8 text-center">
                        {playbackSpeed}x
                      </div>
                      <button
                        className={`font-semibold ${
                          isMaxSpeed ? 'opacity-25' : ''
                        }`}
                        onClick={handleIncreaseSpeed}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="tour-closed-caption px-4 dark:text-white">
                    <button
                      className={` w-full py-1 flex gap-2 items-center ${
                        handleToggleSubtitles
                          ? ''
                          : 'pointer-events-none opacity-25'
                      }`}
                      onClick={() =>
                        handleToggleSubtitles && handleToggleSubtitles()
                      }
                    >
                      <CaptionIcon />
                      <span className="text-xs">
                        {subtitlesEnabled ? 'On' : 'Off'}
                      </span>
                    </button>
                  </div>
                  <div className="px-4 dark:text-white">
                    <button
                      className={`${
                        mediaArray && mediaArray?.length > 0
                          ? ''
                          : 'pointer-events-none opacity-25'
                      } w-full py-1 ml-1 flex gap-2 items-center`}
                      onClick={(e) => {
                        e.preventDefault()
                        dispatch(setIsPollSettingOpen(false))
                        dispatch(setIsPollQualityOpen(true))
                      }}
                    >
                      <SettingsModal className="fill-current" />
                      <span className="text-xs">Quality</span>
                    </button>
                  </div>
                </Popover.Panel>
              )}
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default PollVideoSetting
