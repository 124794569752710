import PropTypes from 'prop-types'
// import { useState } from 'react'
import { Switch as HeadlessuiSwitch } from '@headlessui/react'

const Switch = ({
  hideLabel,
  name,
  label,
  helpText,
  className,
  enabled,
  labelHeight,
  labelTextColor,
  ...props
}) => {
  // const [enabled, setEnabled] = useState(false)

  return (
    <>
      <div className="flex items-center mb-2">
        <HeadlessuiSwitch
          checked={enabled}
          onChange={() => {}}
          className={`${
            enabled
              ? 'bg-red-500'
              : 'bg-gray-200 dark:bg-dark-600 dark:border-dark-200'
          } relative border border-transparent inline-flex items-center h-6 rounded-full w-10 mr-4 ${className}`}
          {...props}
        >
          <span className="sr-only">Enable notifications</span>
          <span
            className={`${
              enabled
                ? 'translate-x-4 dark:bg-white'
                : 'translate-x-0.5 dark:bg-dark-200'
            } inline-block w-5 h-5 transform bg-white  rounded-full`}
          />
        </HeadlessuiSwitch>
        {!hideLabel && (
          <div
            className={`${
              labelTextColor ? labelTextColor : 'text-gray-900 dark:text-white'
            } ${labelHeight}`}
          >
            {label}
          </div>
        )}
      </div>
      {helpText && (
        <div className="mt-1 text-sm text-gray-700 dark:text-dark-100">
          {helpText}
        </div>
      )}
    </>
  )
}

Switch.propTypes = {
  hideLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
}

export default Switch
