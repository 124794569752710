import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

export const getFaqData = createAsyncThunk('faq/getFaqData', async () => {
  return await axiosInterceptors.get('/faq')
})

export const setFaqRating = createAsyncThunk(
  'faq/setFaqRaing',
  async (data) => {
    return await axiosInterceptors.post(`faq/${data.id}/save-rating`, {
      rating: data.rating,
    })
  },
)
export const setNumHits = createAsyncThunk('faq/setumHits', async (data) => {
  return await axiosInterceptors.post(`faq/${data.id}/save-views`)
})
const faqSlice = createSlice({
  name: 'faq',
  initialState: {
    faqList: [],
    showFaqMenu: false,
    searchString: '',
    faqSearchResult: null,
    ratingFaq: [],
    loadingFaq: false,
    disableRating: false,
  },
  extraReducers: {
    [getFaqData.pending]: (state, action) => {
      state.loadingFaq = true
    },
    [getFaqData.fulfilled]: (state, action) => {
      state.faqList = action.payload
      state.loadingFaq = false
    },
    [getFaqData.rejected]: (state, action) => {
      state.loadingFaq = true
    },
    [setFaqRating.pending]: (state, action) => {
      state.disableRating = true
    },
    [setFaqRating.fulfilled]: (state, action) => {
      state.disableRating = false
    },
    [setFaqRating.rejected]: (state, action) => {
      state.disableRating = true
    },
  },
  reducers: {
    toggleFaqMenu: (state) => {
      state.showFaqMenu = !state.showFaqMenu
    },
    updateSearchString: (state, action) => {
      state.searchString = action.payload
    },
    updateFaqSearchResult: (state, action) => {
      state.faqSearchResult = action.payload
    },
    setRatingFaq: (state, action) => {
      const categories = state.faqList.data.categories
      const tabIndex = findIndex(categories, { id: action.payload.tabId })
      const qnaIndex = findIndex(categories[tabIndex].faqs, {
        id: action.payload.qnaId,
      })
      categories[tabIndex].faqs[qnaIndex].ratings[0] = {
        rating: action.payload.rating,
      }
    },
  },
})

export const {
  toggleFaqMenu,
  updateSearchString,
  updateFaqSearchResult,
  setRatingFaq,
} = faqSlice.actions

export default faqSlice.reducer
