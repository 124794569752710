const Scanner = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="current"
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      fill="current"
      fillRule="evenodd"
      d="M6 4.75A1.25 1.25 0 004.75 6v1a.75.75 0 01-1.5 0V6A2.75 2.75 0 016 3.25h2a.75.75 0 010 1.5H6zm-2 11.5a.75.75 0 01.75.75v1A1.25 1.25 0 006 19.25h2a.75.75 0 010 1.5H6A2.75 2.75 0 013.25 18v-1a.75.75 0 01.75-.75zm12-13a.75.75 0 000 1.5h2A1.25 1.25 0 0119.25 6v1a.75.75 0 001.5 0V6A2.75 2.75 0 0018 3.25h-2zm4 13a.75.75 0 01.75.75v1A2.75 2.75 0 0118 20.75h-2a.75.75 0 010-1.5h2A1.25 1.25 0 0019.25 18v-1a.75.75 0 01.75-.75zm-15-5a.75.75 0 000 1.5h14a.75.75 0 000-1.5H5z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default Scanner
