const Redo = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 10"
    fill="current"
    // stroke="currentColor"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.6458 0.979943C7.84106 0.784681 8.15764 0.784681 8.3529 0.979943L11.0196 3.64661C11.2148 3.84187 11.2148 4.15845 11.0196 4.35372L8.3529 7.02038C8.15764 7.21565 7.84106 7.21565 7.6458 7.02038C7.45053 6.82512 7.45053 6.50854 7.6458 6.31328L9.45891 4.50016H3.33268C2.75805 4.50016 2.20695 4.72844 1.80062 5.13476C1.39429 5.54109 1.16602 6.09219 1.16602 6.66683C1.16602 7.24147 1.39429 7.79257 1.80062 8.19889C2.20695 8.60522 2.75805 8.8335 3.33268 8.8335H3.99935C4.27549 8.8335 4.49935 9.05735 4.49935 9.3335C4.49935 9.60964 4.27549 9.8335 3.99935 9.8335H3.33268C2.49283 9.8335 1.68738 9.49987 1.09351 8.906C0.499645 8.31214 0.166016 7.50668 0.166016 6.66683C0.166016 5.82698 0.499645 5.02152 1.09351 4.42766C1.68738 3.83379 2.49283 3.50016 3.33268 3.50016H9.45891L7.6458 1.68705C7.45053 1.49179 7.45053 1.1752 7.6458 0.979943Z"
      fill="current"
    />
  </svg>
)

export default Redo
