import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'
import moment from 'moment'

export const performanceGraphData = createAsyncThunk(
  'performanceGraph/performanceGraphData',
  async (data) => {
    return await axiosInterceptors.get(
      `trainer/${data.trainer}/evolution-graph`,
      {
        params: {
          num_attempts: data.numAttempts,
        },
      },
    )
  },
)

const performanceGraphSlice = createSlice({
  name: 'performanceGraph',
  initialState: {
    graphData: [],
    loadingGraph: false,
    maxScore: 1000,
    targetScore: 0,
  },
  extraReducers: {
    [performanceGraphData.pending]: (state, action) => {
      state.graphData = []
      state.loadingGraph = true
    },
    [performanceGraphData.fulfilled]: (state, { payload }) => {
      state.loadingGraph = false
      state.graphData = payload.data.scores.map((data, index) => ({
        index,
        date: new Date(`${moment.utc(data.date)}`),
        displayDate: moment.utc(data.date).format('DD MMM, YYYY'),
        visits: data.score,
        accuracy: (data.additional_info && data.additional_info[0]) || '',
        speed: (data.additional_info && data.additional_info[1]) || '',
        lineColor: '#F2473A',
        bonuses:
          (data.bonuses &&
            data.bonuses.length > 0 &&
            data.bonuses
              .map((bonus) => {
                return `<span style="font-weight: 400;"><span style="color: #8AC34A;">+${bonus.points} points </span> for ${bonus.reason}</span>`
              })
              .join('<br />')) ||
          '',
      }))
      state.maxScore = payload.data.maximum_score
      state.targetScore = payload.data.target_score
    },
    [performanceGraphData.rejected]: (state, action) => {
      state.loadingGraph = false
    },
  },
})

export default performanceGraphSlice.reducer
