const Person = ({ className }) => (
  <svg
    viewBox="0 0 9 12"
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
    className={`h-4 w-4 ${className}`}
  >
    {/* <circle cx="24" cy="24" r="24" fill="#F2F2F2" /> */}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.62817 3.64104C2.62817 2.62851 3.44898 1.8077 4.4615 1.8077C5.47402 1.8077 6.29484 2.62851 6.29484 3.64104C6.29484 4.65356 5.47402 5.47437 4.4615 5.47437C3.44898 5.47437 2.62817 4.65356 2.62817 3.64104ZM4.4615 0.961548C2.98166 0.961548 1.78201 2.1612 1.78201 3.64104C1.78201 5.12088 2.98166 6.32052 4.4615 6.32052C5.94134 6.32052 7.14099 5.12088 7.14099 3.64104C7.14099 2.1612 5.94134 0.961548 4.4615 0.961548ZM2.03693 9.1139C2.38075 8.77009 2.84707 8.57693 3.3333 8.57693H5.58971C6.07594 8.57693 6.54225 8.77009 6.88607 9.1139C7.22989 9.45772 7.42304 9.92404 7.42304 10.4103V11.5385C7.42304 11.7721 7.61246 11.9615 7.84612 11.9615C8.07978 11.9615 8.26919 11.7721 8.26919 11.5385V10.4103C8.26919 9.69962 7.98689 9.01808 7.48439 8.51558C6.98189 8.01308 6.30035 7.73078 5.58971 7.73078H3.3333C2.62265 7.73078 1.94111 8.01308 1.43861 8.51558C0.936111 9.01809 0.653809 9.69962 0.653809 10.4103V11.5385C0.653809 11.7721 0.843227 11.9615 1.07689 11.9615C1.31054 11.9615 1.49996 11.7721 1.49996 11.5385V10.4103C1.49996 9.92404 1.69312 9.45772 2.03693 9.1139Z"
      fill="currentColor"
    />
  </svg>
)

export default Person
