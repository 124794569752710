import { Tab } from '@headlessui/react'
import { Book } from 'components/icons'
import { getMinutesWithHours, isUcatPath } from 'components/shared/helper'
import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toggleFaqMenu } from 'redux/faq/faqSlice'
import { setBookDetails, updateTabIndex } from 'redux/learn/resourceSlice'

const ChapterList = ({
  tabIndex,
  tabDataIndex,
  isUniversity,
  showFaqMenu,
  tab,
  className,
  scrollToTopNextChapter,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { moduleArea, resourceType, resourceSlug, moduleId, bookId, subMenu } =
    useParams()
  return (
    <Tab key={tabIndex} as={Fragment}>
      {({ selected, close, open }) => (
        <div
          className={`py-4 px-6 ${
            tabDataIndex === tabIndex
              ? 'bg-gray-100 dark:bg-dark-500 border-r border-red-500'
              : 'bg-white dark:bg-dark-600 md:border-text-100'
          }`}
        >
          <button
            className={`w-full text-left focus:outline-none
             ${tab?.is_indented ? 'pl-6' : ''}`}
            onClick={() => {
              dispatch(setBookDetails(tab))
              scrollToTopNextChapter()
              dispatch(updateTabIndex(tabIndex))
              if (isUniversity) {
                history.push(
                  `/university-admission/${subMenu}/${moduleArea}/${resourceType}/${resourceSlug}/${
                    tabIndex + 1
                  }`,
                )
              } else {
                history.push(
                  `${isUcatPath}/${moduleArea}/${resourceSlug}/${tabIndex + 1}`,
                )
              }
              if (showFaqMenu) {
                dispatch(toggleFaqMenu())
              }
            }}
          >
            <div className="flex">
              {!tab.user_read ? (
                tab?.icon?.url ? (
                  <img
                    src={tab?.icon?.url}
                    alt=""
                    className={`filter h-5 w-5 mr-3 dark:invert-75 `}
                  />
                ) : (
                  <Book className="h-5 w-5 dark:text-white mr-3" />
                )
              ) : (
                <div className="w-4 h-4 mr-3">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-secondary text-red-600 rounded-full "
                    checked={tab.user_read}
                    readOnly
                  />
                </div>
              )}
              <div>
                <div className="capitalize text-sm text-gray-700 dark:text-dark-100">
                  {tab?.chapterable_type === 'guide'
                    ? 'Reading'
                    : tab?.chapterable_type}
                  :
                </div>
                <div className="text-sm dark:text-white">{tab?.name}</div>
                <div className="text-gray-500 dark:text-dark-300 text-sm">
                  {tab.recommended_minutes && tab.recommended_minutes === 0
                    ? '0 mins'
                    : getMinutesWithHours(tab.recommended_minutes)}
                </div>
              </div>
            </div>
          </button>
        </div>
      )}
    </Tab>
  )
}

export default ChapterList
