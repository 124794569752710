import { Book, Lock } from 'components/icons'
import React from 'react'
import { isUcatPath } from 'components/shared/helper'
import { Link } from 'react-router-dom'

const SubModuleContainer = ({
  resource,
  typeOfBook,
  id,
  moduleId,
  isAdditionalResources,
}) => {
  return (
    <>
      {resource && (
        <div
          className={`tour-module border dark:border-dark-400 h-full rounded  ${
            resource?.user_cannot_access
              ? 'text-gray-500 cursor-default dark:border-dark-300'
              : 'hover:border-red-500 dark:hover:border-red-500 cursor-pointer'
          }`}
        >
          <Link
            to={
              resource.user_cannot_access
                ? '#'
                : `${isUcatPath}/${typeOfBook}/${resource.slug}/${1}`
            }
          >
            <div className="flex flex-col justify-center items-center gap-1 py-8">
              <div className="text-red-500 mb-5">
                {resource?.user_cannot_access ? (
                  <div>
                    <Lock className="h-6 w-6 text-gray-500" />
                  </div>
                ) : !isAdditionalResources ? (
                  resource?.icon?.url ? (
                    <img
                      src={resource?.icon?.url}
                      alt=""
                      className="h-6 w-full rounded-full"
                    />
                  ) : (
                    <Book className="h-6 w-6 dark:text-red-500" />
                  )
                ) : (
                  <></>
                )}
              </div>
              <div className="font-semibold px-2 text-center">
                {resource?.name}
              </div>
              <div className="text-gray-400 text-sm">{resource?.minutes}</div>
              <div
                className={`${
                  resource?.user_completion_percentage !== 0
                    ? 'text-green-700'
                    : 'text-gray-400'
                }  text-sm`}
              >
                {resource?.user_completion_percentage !== null &&
                resource?.user_completion_percentage !== undefined
                  ? resource?.user_completion_percentage + ' %'
                  : ''}
              </div>
            </div>
          </Link>
        </div>
      )}
    </>
  )
}

export default SubModuleContainer
