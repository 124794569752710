import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

export const startEurekaAttempt = createAsyncThunk(
  'eureka/startEurekaAttempt',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(`trainer/eureka/start-attempt`)

      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const submitEurekaAttempt = createAsyncThunk(
  'eureka/submitEurekaAttempt',
  async ({ eureka_question_attempts, attemptableId }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `trainer/eureka/${attemptableId}/submit-attempt`,
        { eureka_question_attempts },
      )

      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

const eurekaSlice = createSlice({
  name: 'eureka',
  initialState: {
    eurekaData: { isLoading: false, rejected: false, data: {} },
    eurekaResult: { isLoading: false, rejected: false, data: {} },
    isStartEurekaQuiz: false,
    currentQuestionIndex: 0,
    answerOfEureka: [],
    isSubmitGame: false,
    questionTimeTaken: 0,
    recommendedSeconds: null,
    eurekaExtraTime: 0,
    submitEurekaQuiz: false,
    selectedQuestionId: false,
  },
  extraReducers: {
    [startEurekaAttempt.pending]: (state, { payload }) => {
      state.eurekaData.isLoading = true
    },
    [startEurekaAttempt.fulfilled]: (state, { payload }) => {
      state.eurekaData = { isLoading: false, data: payload.data }
      state.isStartEurekaQuiz = true
      state.recommendedSeconds = payload.data?.attempt?.recommended_seconds
      state.answerOfEureka = payload.data?.attempt?.questions?.map(
        (question) => ({
          id: question?.id,
          chosen_option: null,
          time_taken: 0,
        }),
      )
    },
    [startEurekaAttempt.rejected]: (state, { payload }) => {
      state.eurekaData.isLoading = false
      state.eurekaData.rejected = true
    },
    [submitEurekaAttempt.pending]: (state, { payload }) => {
      state.eurekaResult.isLoading = true
      state.isSubmitGame = true
    },
    [submitEurekaAttempt.fulfilled]: (state, { payload }) => {
      state.eurekaResult = { isLoading: false, data: payload.data }
    },
    [submitEurekaAttempt.rejected]: (state, { payload }) => {
      state.eurekaResult.isLoading = false
      state.eurekaResult.rejected = true
    },
  },

  reducers: {
    setSelectedQuestionId: (state, { payload }) => {
      state.selectedQuestionId = payload
    },
    setSelectedQuestion: (state, { payload }) => {
      state.currentQuestionIndex = payload
    },
    setIsStartEurekaQuiz: (state, { payload }) => {
      state.isStartEurekaQuiz = payload
    },
    setAnswerOfEureka: (state, { payload }) => {
      const currentIndex = findIndex(state.answerOfEureka, {
        id: payload?.id,
      })
      if (currentIndex !== -1) {
        state.answerOfEureka[currentIndex].chosen_option =
          payload?.chosen_option
      }
    },
    skipAnswerOfEureka: (state, { payload }) => {
      const currentIndex = findIndex(state.answerOfEureka, {
        id: payload?.id,
      })
      if (currentIndex !== -1) {
        state.answerOfEureka[currentIndex].chosen_option = null
      }
    },
    setRecommendedSeconds: (state) => {
      if (state.recommendedSeconds > 0) state.recommendedSeconds -= 1
    },
    setEurekaExtraTime: (state, { payload }) => {
      state.eurekaExtraTime += 1
    },
    updateTimeTaken: (state, { payload }) => {
      state.questionTimeTaken = payload
    },
    setTryAgainEureka: (state) => {
      state.eurekaData = { isLoading: false, rejected: false, data: {} }
      state.eurekaResult = { isLoading: false, rejected: false, data: {} }
      state.isStartEurekaQuiz = false
      state.currentQuestionIndex = 0
      state.answerOfEureka = []
      state.isSubmitGame = false
      state.questionTimeTaken = 0
      state.recommendedSeconds = null
      state.eurekaExtraTime = 0
      state.submitEurekaQuiz = false
    },
    setSubmitEurekaQuiz: (state, { payload }) => {
      state.submitEurekaQuiz = payload
    },
    setTimeTaken: (state, { payload }) => {
      const currentIndex = findIndex(state.answerOfEureka, {
        id: payload?.id,
      })
      if (currentIndex !== -1) {
        state.answerOfEureka[currentIndex].time_taken =
          payload?.questionTimeTaken
      }
    },
  },
})

export const {
  setSelectedQuestionId,
  setSelectedQuestion,
  setIsStartEurekaQuiz,
  setAnswerOfEureka,
  skipAnswerOfEureka,
  setRecommendedSeconds,
  setEurekaExtraTime,
  updateTimeTaken,
  setTimeTaken,
  setTryAgainEureka,
  setSubmitEurekaQuiz,
} = eurekaSlice.actions

export default eurekaSlice.reducer
