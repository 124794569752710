import { createSlice } from '@reduxjs/toolkit'

export const scratchpadSlice = createSlice({
  name: 'scratchpad',
  initialState: {
    past: [],
    present: '',
    future: [],
    cancelAutoSave: null,
    virtualClipboard: null,
    saveSratchpadeText: null,
  },
  reducers: {
    setCancelAutoSave: (state, { payload }) => {
      state.cancelAutoSave = payload
    },
    setVirtualClipboard: (state, { payload }) => {
      state.virtualClipboard = payload
    },
    setSaveSratchpadeText: (state, { payload }) => {
      state.saveSratchpadeText = payload
    },
    handleInit: (state, { payload }) => {
      state.present = payload
    },
    handleAdd: (state, { payload }) => {
      state.past = [...state.past, payload]
      state.present = payload
      state.future = []
    },
    handleUndo: (state, { payload }) => {
      const tempPast = [...state.past]
      const tempFuture = tempPast.pop()
      return {
        past: tempPast,
        present: tempPast[tempPast.length - 1],
        future: [tempFuture, ...state.future],
      }
    },
    handleRedo: (state, { payload }) => {
      const next = state.future[0]
      const newFuture = state.future.slice(1)
      state.past = [...state.past, next]
      state.present = next
      state.future = newFuture
    },
  },
})

export const {
  handleInit,
  handleAdd,
  handleUndo,
  handleRedo,
  setCancelAutoSave,
  setVirtualClipboard,
  setSaveSratchpadeText,
} = scratchpadSlice.actions

export default scratchpadSlice.reducer
