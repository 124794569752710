import { RadioGroup } from '@headlessui/react'
import { Pause } from 'components/icons'
import HTMLRenderer from 'components/shared/helper/HTMLRenderer'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAnswerOfQuestion,
  setTimeTaken,
  updateTimeTaken,
} from 'redux/learn/resourceSlice'

const BookingQuizMainArea = ({ recoursesQuestion, optionsArray }) => {
  const dispatch = useDispatch()
  const {
    isFullBookScreen,
    quizAnswers,
    questionsIndex,
    questionTimeTaken,
    showIntroductionModal,
    optionClickable,
  } = useSelector((state) => ({
    isFullBookScreen: state.resource.isFullBookScreen,
    quizAnswers: state.resource.quizAnswers,
    questionsIndex: state.resource.questionsIndex,
    questionTimeTaken: state.resource.questionTimeTaken,
    showIntroductionModal: state.resource.showIntroductionModal,
    optionClickable: state.examPortal.optionClickable,
  }))

  const tabletView = window.matchMedia('(max-width: 768px)')
  const mobileView = window.matchMedia('(max-width: 640px)')

  const [mobileMatches, setMobileMatches] = useState(mobileView.matches)
  useEffect(() => {
    const handler = (e) => setMobileMatches(e.matches)
    mobileView.addListener(handler)
    return () => mobileView.removeListener(handler)
  })

  const [matches, setMatches] = useState(tabletView.matches)

  const [landscapMode, setLandscapMode] = useState(false)

  useEffect(() => {
    window.addEventListener(
      'orientationchange',
      function () {
        if (window.orientation === 90 || window.orientation === -90) {
          setLandscapMode(true)
        } else {
          setLandscapMode(false)
        }
      },
      false,
    )
  }, [])

  useEffect(() => {
    if (window.orientation === 90 || window.orientation === -90) {
      setLandscapMode(true)
    } else {
      setLandscapMode(false)
    }
  }, [])

  const isHoverableDevice = window.matchMedia(
    '(hover: hover) and (pointer: fine)',
  )
  const [touchable, setTouchable] = useState(isHoverableDevice?.matches)
  useEffect(() => {
    const handler = (e) => setTouchable(e.matches)
    isHoverableDevice.addListener(handler)
    return () => isHoverableDevice.removeListener(handler)
  }, [isHoverableDevice])

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (!showIntroductionModal) dispatch(setTimeTaken())
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  }, [dispatch, showIntroductionModal])

  useEffect(() => {
    const handleKeyDown = (event) => {
      const key = event.key.toLowerCase()
      const options = ['a', 'b', 'c', 'd', 'e']

      if (options.includes(key) && optionClickable && !event.altKey) {
        const index = options.indexOf(key)
        const optionElements = document.querySelectorAll('.radio-option')
        if (optionElements[index]) {
          optionElements[index].click()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [optionClickable])

  const [multipleChoiceWidth, setMultiplechoiceWidth] = useState('')
  const mouseMoveHandler = function (e, x, y, leftWidth) {
    const resizer = document.getElementById('dragMe')

    const leftArea = document.getElementById('left-area')

    const dx = e.clientX - x
    const newLeftWidth =
      ((leftWidth + dx) * 100) /
      resizer.parentNode.getBoundingClientRect().width
    leftArea.style.width =
      newLeftWidth >= 40 && newLeftWidth <= 70 && `${newLeftWidth}%`
    setMultiplechoiceWidth(leftArea.style.width)
  }
  const stopResize = (e) => {
    document.onmousemove = null
    document.onmouseup = null
  }
  const mouseDown = (e) => {
    const leftSide = document.getElementById('left-area')
    let x = e.clientX
    let y = e.clientY
    let leftWidth = leftSide.getBoundingClientRect().width
    document.onmousemove = (e) => mouseMoveHandler(e, x, y, leftWidth)
    // document.addEventListener('mousemove', mouseMoveHandler())
    document.onmouseup = stopResize
  }

  useEffect(() => {
    const leftSide = document.getElementById('left-area')
    if (leftSide?.style?.height || leftSide?.style?.width) {
      leftSide.style.width = multipleChoiceWidth
    }
  }, [multipleChoiceWidth])

  return (
    <div
      style={{
        height: mobileMatches
          ? landscapMode
            ? 'calc(100vh - 120px)'
            : isFullBookScreen
            ? 'calc(100vh - 190px)'
            : 'calc(100vh - 378px)'
          : matches
          ? landscapMode || isFullBookScreen
            ? 'calc(100vh - 350px)'
            : 'calc(100vh - 303px)'
          : landscapMode || isFullBookScreen
          ? 'calc(100vh - 256px)'
          : 'calc(100vh - 365px)',
      }}
      className="w-full overflow-auto custom-scroll sm:flex sm:items-stretch sm:min-h-full s"
    >
      <div
        id="left-area"
        className={` ${
          !matches ? 'overflow-auto' : ''
        } px-6 py-2 custom-scroll ${matches ? 'w-full' : 'w-full md:w-3/5 '}`}
      >
        <div className="html-renderer-container relative">
          <HTMLRenderer
            preventInteraction={true}
            html={recoursesQuestion?.question_html}
          />
        </div>
      </div>
      {(touchable || !matches) && (
        <div
          id="dragMe"
          className={`${!matches && touchable && 'tour-slider'}`}
        >
          <div
            className={`h-full relative items-center border-l-2 dark:border-dark-500 hidden md:flex flex-col`}
          >
            <button
              style={{ cursor: 'col-resize' }}
              onMouseDown={mouseDown}
              className={`absolute mt-52  ${!touchable && 'hidden'}
                `}
            >
              <Pause className="h-6 w-6 text-gray-600 dark:text-dark-500" />
            </button>
          </div>
        </div>
      )}
      <div
        id="right-area"
        className={`${!matches ? 'overflow-auto' : ''}  custom-scroll ${
          !touchable ? 'w-full' : 'w-full md:w-2/5 flex-auto'
        }`}
      >
        {recoursesQuestion?.stem_html ? (
          <div className="html-renderer-container relative px-6 py-2 border-b dark:border-dark-500">
            <HTMLRenderer
              preventInteraction={true}
              html={recoursesQuestion?.stem_html}
            />
          </div>
        ) : (
          <></>
        )}
        <div className={`${recoursesQuestion?.stem_html ? '' : 'mt-3'}`}>
          <RadioGroup
            value={quizAnswers?.length > 0 && quizAnswers[questionsIndex].input}
            onChange={(input) => {
              dispatch(
                setAnswerOfQuestion({ id: recoursesQuestion?.id, input }),
              )
            }}
          >
            {optionsArray?.length > 0 &&
              optionsArray?.map((data, index) => (
                <RadioGroup.Option
                  key={index}
                  value={data.inputKey}
                  className="outline-none"
                >
                  {({ checked }) => (
                    <div
                      className={`px-6 py-3 flex flex-wrap gap-2 justify-between items-center ${
                        optionsArray?.length !== index + 1
                          ? 'border-b dark:border-dark-500'
                          : ''
                      } radio-option cursor-pointer`}
                    >
                      <div className={`flex items-center gap-2`}>
                        <div
                          style={{ minWidth: '20px' }}
                          className={` border-2  rounded-full h-5 w-5 flex justify-center items-center mt-0.5 ${
                            checked
                              ? 'border-red-500'
                              : 'border-gray-500 dark:border-dark-200'
                          }`}
                        >
                          {checked ? (
                            <div
                              style={{ minWidth: '4px', paddingTop: '1px' }}
                              className="bg-red-500 h-2 w-2 rounded-full"
                            ></div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="flex items-center gap-2 mt-0.5">
                          {String.fromCharCode(64 + (index + 1))}.{' '}
                          <span className="text-gray-900 dark:text-white text-base font-normal">
                            <HTMLRenderer
                              preventInteraction={true}
                              html={data?.html}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
          </RadioGroup>
        </div>
      </div>
    </div>
  )
}

export default BookingQuizMainArea
