import { createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import {
  getAttempt,
  reportStageReached,
  syncProgress,
  finishAttempt,
  switchToUntimedMode,
} from './hpatPortalAction'
export const HpatPortalSlice = createSlice({
  name: 'hpatExamPortal',
  initialState: {
    attempt: null,
    stage: { type: 'loading' },
    isLoading: false,
    stageSubData: {},
    countDown: { time_remaining: null },
    bookmarkQuestion: [],
    isDisableNextButton: [],
    reviewType: null,
    finishLoader: false,
    examPortalError: { data: null, isError: false, errorMessage: '' },
    showReviewScreen: false,
    isHpatView: true,
    isCrossSelected: false,
    isRecap: false,
    isDividerScreen: false,
    showScrollView: false,
    proceedAnyway: false,
    continueSectionLoading: false,
    isInstruction: '',
    modal: null,
    switchToUntimedLoading: false,
    matches: false,
    highlight: null,
    hpatServerErrorTimeOut: false,
    hpatServerError: false,
    isFlagChange: false,
  },
  extraReducers: {
    [finishAttempt.pending]: (state, action) => {
      state.finishLoader = true
    },
    [finishAttempt.fulfilled]: (state, action) => {
      state.finishLoader = false
    },
    [finishAttempt.rejected]: (state, action) => {
      state.finishLoader = false
    },
    [switchToUntimedMode.pending]: (state, action) => {
      state.switchToUntimedLoading = true
    },
    [switchToUntimedMode.fulfilled]: (state, { payload }) => {
      state.attempt.simulated_conditions = payload?.data?.simulated_conditions
      state.switchToUntimedLoading = false
    },
    [switchToUntimedMode.rejected]: (state, action) => {
      state.switchToUntimedLoading = false
      state.finishLoader = false
    },
    [reportStageReached.fulfilled]: (state, action) => {
      state?.attempt?.stages.forEach((stage) => {
        state.stage.has_been_reached =
          action.payload.data &&
          action.payload.data[stage.identifier] &&
          action.payload.data[stage.identifier].has_been_reached
        state.stage.is_current =
          action.payload.data &&
          action.payload.data[stage.identifier] &&
          action.payload.data[stage.identifier].is_current
      })
    },
    [syncProgress.fulfilled]: (state, action) => {
      state.hpatServerError = false
      const updatedQuestions =
        action.meta &&
        action.meta.arg &&
        action.meta.arg.questionAttempts
          ?.filter((data) => data.isUpdated)
          .map((data) => {
            return data.id
          })
      if (state?.stage?.stage?.question_attempts)
        state.stage.stage.question_attempts =
          state?.stage?.stage?.question_attempts?.map((data) => {
            if (updatedQuestions.includes(data.id)) {
              return { ...data, isUpdated: false }
            } else {
              return data
            }
          })
      if (state.isFlagChange) {
        state.isFlagChange = false
      }
    },
    [syncProgress.rejected]: (state, { payload }) => {
      const isNetworkError = payload?.isAxiosError && !payload?.response
      const isStatus500Error =
        payload?.response && payload?.response?.status === 500
      if (isNetworkError || isStatus500Error) {
        state.hpatServerError = true
        if (!state.firstServerErrorTime) {
          state.firstServerErrorTime = Date.now()
        }
      }
    },
    [getAttempt.pending]: (state) => {
      state.isLoading = true
    },
    [getAttempt.fulfilled]: (state, action) => {
      state.isInstruction = action?.payload?.data?.stages[0]?.has_been_reached
        ? ''
        : 'instruction1'
      state.isLoading = false
      state.attempt = action.payload.data
      const currentStage =
        action?.payload?.data?.stages?.find((stage) => stage.is_current) ||
        action?.payload?.data?.stages[0]
      state.countDown = {
        time_remaining: currentStage?.time_remaining,
      }
      if (currentStage?.stage?.type === 'questions') {
        state.isDisableNextButton =
          currentStage.stage.question_attempts?.map((item) => ({
            id: item.id,
            questionComplete: true, //to enable scroll -> questionComplete: item.question_completely_viewed,
          })) || []
        state.bookmarkQuestion = currentStage.stage.question_attempts?.map(
          (item) => ({
            id: item.question?.id,
            bookmark:
              item.question &&
              item.question.bookmarks &&
              item.question.bookmarks.length !== 0,
          }),
        )
        //
        if (action.meta.arg.question_id) {
          const questionIndex = currentStage.stage.question_attempts.findIndex(
            (attempt) =>
              parseInt(action.meta.arg.question_id) === parseInt(attempt.id) ||
              parseInt(action.meta.arg.question_id) ===
                parseInt(attempt.question_id),
          )
          state.stage = currentStage
          state.stageSubData = {
            ...state.stageSubData,
            currentQuestionAttempt:
              currentStage.stage.question_attempts[
                questionIndex !== -1 ? questionIndex : 0
              ],
            isRecap: false,
          }
        } else {
          state.stage = currentStage
          const divederIndex = findIndex(currentStage?.stage?.divider_screens, {
            before_question_id:
              currentStage.stage.question_attempts[0]?.question_id,
          })
          if (divederIndex !== -1) {
            state.isDividerScreen = true
            state.stageSubData = {
              ...state.stageSubData,
              currentQuestionAttempt:
                currentStage?.stage?.divider_screens[divederIndex],
              isRecap: false,
            }
          } else {
            state.stageSubData = {
              ...state.stageSubData,
              currentQuestionAttempt: currentStage.stage.question_attempts[0],
              isRecap: false,
            }
          }
        }
      } else {
        state.stage = currentStage
      }
    },
    [getAttempt.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.examPortalError = {
        data: payload?.data,
        isError: true,
        errorMessage: payload?.message,
      }
    },
  },
  reducers: {
    setHpatServerErrorTimeOut: (state, { payload }) => {
      state.hpatServerErrorTimeOut = payload
    },
    clearHpatServerError: (state) => {
      state.hpatServerError = false
      state.hpatServerErrorTimeOut = false
    },
    clearHpatExamError: (state, { payload }) => {
      state.examPortalError = { data: null, isError: false, errorMassage: '' }
    },
    setHighlight: (state, { payload }) => {
      state.highlight = payload
    },
    setMatches: (state, { payload }) => {
      state.matches = payload
    },
    clearHpatPortal: (state) => {
      state.attempt = null
      state.stage = { type: 'loading' }
      state.isLoading = false
      state.stageSubData = {}
      state.countDown = { time_remaining: null }
      state.bookmarkQuestion = []
      state.isDisableNextButton = []
      state.reviewType = null
      state.finishLoader = false
      state.examPortalError = { data: null, isError: false, errorMessage: '' }
      state.showReviewScreen = false
      state.isHpatView = true
      state.isCrossSelected = false
      state.isRecap = false
      state.isDividerScreen = false
      state.showScrollView = false
      state.proceedAnyway = false
      state.continueSectionLoading = false
      state.isInstruction = ''
      state.modal = null
      state.switchToUntimedLoading = false
    },
    setInstruction: (state, { payload }) => {
      state.isInstruction = payload
    },
    updateTimeLimitWarningShown: (state, { payload }) => {
      state.stage.time_limit_warning_shown = payload
    },
    setProceedAnyway: (state, { payload }) => {
      state.proceedAnyway = payload
    },
    setContinueSectionLoading: (state, { payload }) => {
      state.continueSectionLoading = payload
    },
    resetExamPortalError: (state) => {
      state.isRecap = false
      state.isDividerScreen = false
      state.examPortalError = { data: null, isError: false, errorMessage: '' }
    },
    updateNextStage: (state, { payload }) => {
      const currentStageIndex = findIndex(state?.attempt?.stages, {
        id: state?.stage?.id,
      })

      const otherStagesArray = state?.attempt?.stages?.filter(
        (attempt, index) => index > currentStageIndex,
      )
      const nextStage = otherStagesArray?.find((stage) => !stage?.is_complete)

      state.isRecap = false
      state.stage = nextStage

      const divederIndex = findIndex(nextStage?.stage?.divider_screens, {
        before_question_id: nextStage.stage.question_attempts[0]?.question_id,
      })
      if (divederIndex !== -1) {
        state.isDividerScreen = true
        state.stageSubData = {
          ...state.stageSubData,
          currentQuestionAttempt:
            nextStage?.stage?.divider_screens[divederIndex],
          isRecap: false,
        }
      } else {
        state.stageSubData = {
          ...state.stageSubData,
          currentQuestionAttempt: nextStage.stage.question_attempts[0],
          isRecap: false,
        }
      }
      state.continueSectionLoading = false
    },
    setIsDividerScreen: (state, { payload }) => {
      state.isDividerScreen = payload
    },
    setIsRecap: (state, { payload }) => {
      state.isRecap = payload
    },
    setIsCrossSelected: (state) => {
      state.isCrossSelected = !state.isCrossSelected
    },
    updateQuestionAttempt: (state, { payload }) => {
      if (state.proceedAnyway) {
        state.proceedAnyway = false
      }
      state.stageSubData = {
        ...state.stageSubData,
        currentQuestionAttempt: payload.questionAttempt,
        isRecap: false,
      }
    },
    setHpatView: (state, { payload }) => {
      state.isHpatView = payload
    },
    updateUnitHtml: (state, { payload }) => {
      if (payload.id) {
        for (let i = 0; i < state.stage.stage.question_attempts.length; i++) {
          if (
            state.stage.stage.question_attempts[i]?.question?.questionable
              ?.unit &&
            state.stage.stage.question_attempts[i]?.question?.questionable?.unit
              ?.id === payload?.unitId
          ) {
            state.stage.stage.question_attempts[
              i
            ].question.questionable.unit.html = payload.content
          }
        }
        state.stage.stage.question_attempts[
          payload.id - 1
        ].question.questionable.unit.html = payload.content
        state.stageSubData.currentQuestionAttempt.question.questionable.unit.html =
          payload.content
      }
    },
    updateOptionCross: (state, { payload }) => {
      const option =
        state.stageSubData.currentQuestionAttempt.question.questionable.options[
          payload.index
        ]
      state.stage.stage.question_attempts[
        payload.id - 1
      ].question.questionable.options[payload.index] = {
        ...option,
        isCorssed: option?.isCorssed ? false : true,
      }
      if (option) {
        state.stageSubData.currentQuestionAttempt.question.questionable.options[
          payload.index
        ] = {
          ...option,
          isCorssed: option?.isCorssed ? false : true,
        }
      }
    },
    updateQuestionTimeTaken: (state, { payload }) => {
      const questionAttemptUpdateIndex = findIndex(
        state.stage.stage.question_attempts,
        {
          question_id: parseInt(payload?.id),
        },
      )

      if (questionAttemptUpdateIndex !== -1)
        state.stage.stage.question_attempts[
          questionAttemptUpdateIndex === 0
            ? questionAttemptUpdateIndex
            : questionAttemptUpdateIndex - 1
        ] = {
          ...state?.stage?.stage?.question_attempts[
            questionAttemptUpdateIndex === 0
              ? questionAttemptUpdateIndex
              : questionAttemptUpdateIndex - 1
          ],
          time_spent: (payload.timeTaken || 0) + 1,
        }
    },
    updateCountDown: (state) => {
      state.stage.time_spent = state.stage.time_spent + 1
      state.stage.time_remaining = state.stage.time_remaining - 1
      state.countDown = {
        time_remaining: state.stage.time_remaining,
      }
      if (
        state.stageSubData &&
        state.stageSubData.currentQuestionAttempt &&
        state.stageSubData.currentQuestionAttempt.id
      ) {
        state.stageSubData.currentQuestionAttempt = {
          ...state.stageSubData.currentQuestionAttempt,
          time_spent:
            (state.stageSubData.currentQuestionAttempt?.time_spent || 0) + 1,
        }
        const questionAttemptUpdateIndex = findIndex(
          state.stage.stage.question_attempts,
          { id: state?.stageSubData?.currentQuestionAttempt?.id },
        )
        if (questionAttemptUpdateIndex !== -1) {
          state.stage.stage.question_attempts[questionAttemptUpdateIndex] = {
            ...state?.stage?.stage?.question_attempts[
              questionAttemptUpdateIndex
            ],
            time_spent:
              (state?.stage?.stage?.question_attempts[
                questionAttemptUpdateIndex
              ]?.time_spent || 0) + 1,
            isUpdated: true,
          }
        }
      }
    },
    updateTimeSpentReportingIssues: (state) => {
      state.stage.time_spent_reporting_issues =
        state.stage?.time_spent_reporting_issues + 1
    },
    setShowScrollView: (state, { payload }) => {
      state.showScrollView = payload
    },
    updateModal: (state, { payload }) => {
      state.modal = payload
    },
    handleAnswerInput: (state, { payload }) => {
      state.stageSubData.currentQuestionAttempt = {
        ...state.stageSubData.currentQuestionAttempt,
        serialised_input: payload.serialisedInput,
      }
      const questionAttemptUpdateIndex = findIndex(
        state.stage.stage.question_attempts,
        {
          id: state.stageSubData.currentQuestionAttempt.id,
        },
      )
      if (questionAttemptUpdateIndex >= 0) {
        state.stage.stage.question_attempts[questionAttemptUpdateIndex] = {
          ...state.stage.stage.question_attempts[questionAttemptUpdateIndex],
          serialised_input: payload.serialisedInput,
          is_awaiting_input: payload.isAwaitingInput,
          isUpdated: true,
        }
      }
    },
    updateFlaggedQuestion: (state, { payload }) => {
      state.stage.stage.question_attempts =
        state.stage.stage.question_attempts.map((attempt) => {
          if (payload.id === attempt.id) {
            return { ...attempt, flagged: !attempt.flagged, isUpdated: true }
          } else {
            return attempt
          }
        })

      if (
        state.stageSubData &&
        state.stageSubData.currentQuestionAttempt?.id === payload.id
      ) {
        state.stageSubData.currentQuestionAttempt.flagged =
          !state.stageSubData.currentQuestionAttempt.flagged
      }
      state.isFlagChange = true
    },
  },
})
export const {
  updateFlaggedQuestion,
  updateTimeSpentReportingIssues,
  clearHpatServerError,
  setHpatServerErrorTimeOut,
  clearHpatExamError,
  setHighlight,
  setMatches,
  setInstruction,
  setProceedAnyway,
  resetExamPortalError,
  updateNextStage,
  updateQuestionAttempt,
  setHpatView,
  updateUnitHtml,
  updateCountDown,
  updateOptionCross,
  setIsCrossSelected,
  handleAnswerInput,
  setIsRecap,
  updateQuestionTimeTaken,
  setIsDividerScreen,
  setShowScrollView,
  setContinueSectionLoading,
  updateModal,
  updateTimeLimitWarningShown,
  clearHpatPortal,
} = HpatPortalSlice.actions
export default HpatPortalSlice.reducer
