import React from 'react'

const FullScreenIcon = ({ className }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.41009 1.41073C1.56637 1.25445 1.77833 1.16666 1.99935 1.16666H3.33268C3.60882 1.16666 3.83268 0.942799 3.83268 0.666656C3.83268 0.390514 3.60882 0.166656 3.33268 0.166656H1.99935C1.51312 0.166656 1.0468 0.359811 0.702986 0.703627C0.35917 1.04744 0.166016 1.51376 0.166016 1.99999V3.33332C0.166016 3.60947 0.389873 3.83332 0.666016 3.83332C0.942158 3.83332 1.16602 3.60947 1.16602 3.33332V1.99999C1.16602 1.77898 1.25381 1.56701 1.41009 1.41073ZM1.16602 8.66666C1.16602 8.39051 0.942158 8.16666 0.666016 8.16666C0.389873 8.16666 0.166016 8.39051 0.166016 8.66666V9.99999C0.166016 10.4862 0.35917 10.9525 0.702986 11.2964C1.0468 11.6402 1.51312 11.8333 1.99935 11.8333H3.33268C3.60882 11.8333 3.83268 11.6095 3.83268 11.3333C3.83268 11.0572 3.60882 10.8333 3.33268 10.8333H1.99935C1.77834 10.8333 1.56637 10.7455 1.41009 10.5892C1.25381 10.433 1.16602 10.221 1.16602 9.99999V8.66666ZM8.16602 0.666656C8.16602 0.390514 8.38987 0.166656 8.66601 0.166656H9.99935C10.4856 0.166656 10.9519 0.359811 11.2957 0.703627C11.6395 1.04744 11.8327 1.51376 11.8327 1.99999V3.33332C11.8327 3.60947 11.6088 3.83332 11.3327 3.83332C11.0565 3.83332 10.8327 3.60947 10.8327 3.33332V1.99999C10.8327 1.77898 10.7449 1.56701 10.5886 1.41073C10.4323 1.25445 10.2204 1.16666 9.99935 1.16666H8.66601C8.38987 1.16666 8.16602 0.942799 8.16602 0.666656ZM11.8327 8.66666C11.8327 8.39051 11.6088 8.16666 11.3327 8.16666C11.0565 8.16666 10.8327 8.39051 10.8327 8.66666V9.99999C10.8327 10.221 10.7449 10.433 10.5886 10.5892C10.4323 10.7455 10.2204 10.8333 9.99935 10.8333H8.66601C8.38987 10.8333 8.16602 11.0572 8.16602 11.3333C8.16602 11.6095 8.38987 11.8333 8.66601 11.8333H9.99935C10.4856 11.8333 10.9519 11.6402 11.2957 11.2964C11.6395 10.9525 11.8327 10.4862 11.8327 9.99999V8.66666Z"
      fill="white"
    />
  </svg>
)

export default FullScreenIcon
