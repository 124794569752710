const Facebook = ({ className, ...props }) => (
  <svg
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={`h-4 w-4 ${className}`}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.28951 1.62276C4.00839 0.903868 4.98342 0.5 6.00008 0.5H8.00008C8.27622 0.5 8.50008 0.723858 8.50008 1V3.66667C8.50008 3.94281 8.27622 4.16667 8.00008 4.16667H6.00008C5.95588 4.16667 5.91349 4.18423 5.88223 4.21548C5.85097 4.24674 5.83341 4.28913 5.83341 4.33333V5.16667H8.00008C8.15405 5.16667 8.29943 5.2376 8.39418 5.35896C8.48894 5.48032 8.5225 5.63856 8.48515 5.78793L7.81849 8.4546C7.76284 8.67718 7.56285 8.83333 7.33341 8.83333H5.83341V13C5.83341 13.2761 5.60956 13.5 5.33341 13.5H2.66675C2.39061 13.5 2.16675 13.2761 2.16675 13V8.83333H0.666748C0.390606 8.83333 0.166748 8.60948 0.166748 8.33333V5.66667C0.166748 5.39052 0.390606 5.16667 0.666748 5.16667H2.16675V4.33333C2.16675 3.31667 2.57062 2.34165 3.28951 1.62276ZM6.00008 1.5C5.24863 1.5 4.52797 1.79851 3.99661 2.32986C3.46526 2.86122 3.16675 3.58189 3.16675 4.33333V5.66667C3.16675 5.94281 2.94289 6.16667 2.66675 6.16667H1.16675V7.83333H2.66675C2.94289 7.83333 3.16675 8.05719 3.16675 8.33333V12.5H4.83341V8.33333C4.83341 8.05719 5.05727 7.83333 5.33341 7.83333H6.94303L7.35969 6.16667H5.33341C5.05727 6.16667 4.83341 5.94281 4.83341 5.66667V4.33333C4.83341 4.02391 4.95633 3.72717 5.17512 3.50838C5.39392 3.28958 5.69066 3.16667 6.00008 3.16667H7.50008V1.5H6.00008Z"
      fill="currentColor"
    />
  </svg>
)

export default Facebook
