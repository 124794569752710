import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

export const getResourceData = createAsyncThunk(
  'resource/getResourceData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `resource/book/${data.resourceSlug}`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err)
    }
  },
)

export const createBookmark = createAsyncThunk(
  'learn/createBookmark',
  async (data) => {
    return await axiosInterceptors.post(`bookmark`, {
      type: data.type,
      type_id: data.typeId,
    })
  },
)

export const deleteBookmark = createAsyncThunk(
  'learn/deleteBookmark',
  async (data) => {
    return await axiosInterceptors.delete(`bookmark/${data.bookmarkId}`, {
      type_id: data.typeId,
    })
  },
)

export const getScormContent = createAsyncThunk(
  'resource/getScormContent',
  async (data) => {
    return await axiosInterceptors.get(
      `resource/scorm-content/${data.id}/content/${data.indexFile}`,
    )
  },
)

export const markChapterRead = createAsyncThunk(
  'resource/markChapterRead',
  async (data) => {
    return await axiosInterceptors.post(
      `resource/book/chapter/${data.chapterId}/mark-read`,
    )
  },
)

export const submitVideoPollResponse = createAsyncThunk(
  `resource/submitVideoPollResponse`,
  async ({ chapterId, answerId, questionId }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `resource/book/chapter/${chapterId}/submit-video-poll-response/${questionId}`,
        { video_chapter_poll_answer_id: answerId },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const clearVideoPollResponse = createAsyncThunk(
  `resource/clearVideoPollResponse`,
  async ({ chapterId, questionId, userResponseId }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.delete(
        `resource/book/chapter/${chapterId}/clear-video-poll-response/${userResponseId}`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const submitQuiz = createAsyncThunk(
  'resource/submitQuiz',
  async ({ quizId, chapterId, data }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `/resource/book/chapter/${chapterId}/save-quiz-chapter-responses/${quizId}`,
        data,
      )
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const retryQuizChapter = createAsyncThunk(
  'resource/retryQuizChapter',
  async ({ quizId, chapterId }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `/resource/book/chapter/${chapterId}/retry-quiz-chapter/${quizId}`,
      )
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const addTranscriptNote = createAsyncThunk(
  `resource/addTranscriptNote`,
  async (
    { data, chapterId, onSuccess, onError, chapterableId },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.post(
        `bookmark/transcript-notes/${chapterId}/${chapterableId}`,
        data,
      )

      onSuccess && onSuccess()
      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const updateTranscriptNote = createAsyncThunk(
  `resource/updateTranscriptNote`,
  async (
    { data, chapterId, onSuccess, onError, chapterableId, transcriptId },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.put(
        `resource/book/chapter/${chapterId}/update-transcript-notes/${chapterableId}/${transcriptId}`,
        data,
      )

      onSuccess && onSuccess()
      return res
    } catch (err) {
      const errorType = rejectWithValue(err.response.data)
      onError && onError(errorType.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const deleteTranscriptNotes = createAsyncThunk(
  'resource/deleteTranscriptNotes',
  async ({ chapterId, noteId }) => {
    return await axiosInterceptors.delete(
      `resource/book/chapter/${chapterId}/delete-transcript-notes/${noteId}`,
    )
  },
)

export const markVideoViewed = createAsyncThunk(
  'resource/markVideoViewed',
  async ({ videoId, chapterId }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `resource/book/chapter/${chapterId}/mark-video-viewed/${videoId}`,
      )
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const getNotesForVideo = createAsyncThunk(
  'resource/getNotesForVideo',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`/bookmark/notes`, {
        params: {
          book_chapter_id: data?.book_chapter_id,
          type: 'transcript-position',
          include_all: 1,
        },
      })
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

const isVideoAutoPlay = sessionStorage.getItem('video-auto-play')
const videoVolume = sessionStorage.getItem(`video-volume`)
const resourceSlice = createSlice({
  name: 'resource',
  initialState: {
    resourceData: { isLoading: false, data: [] },
    tabDataIndex: 0,
    scormIndexFile: '',
    bookmarkLoading: false,
    pollQuestionIndex: 0,
    submitVideoPollLoader: false,
    selectedPoll: null,
    selectedVideoLink: null,
    bookDetails: null,
    resourceQuestions: null,
    questionsIndex: 0,
    isFullBookScreen: false,
    bookingResourceTab: 0,
    isClickedTab: false,
    quizAnswers: [],
    questionTimeTaken: 0,
    submitLoader: false,
    retryLoader: false,
    retryVideoPollLoader: false,
    videoNoteList: [],
    transcriptData: [],
    playedSeconds: 0,
    playedPIPSeconds: 0,
    isPIPActive: false,
    isSettingOpen: false,
    isPollSettingOpen: false,
    isQualityOpen: false,
    isPollQualityOpen: false,
    selectedVideoMedia: null,
    selectedPollVideoMedia: null,
    changedDuration: null,
    changedPollDuration: null,
    showTranscriptNoteModal: false,
    selectedNote: null,
    addTranscriptLoader: false,
    deleteId: null,
    deleteLoader: false,
    showVideoModal: false,
    isVideoFinished: false,
    showIntroductionModal: false,
    videoAutoPlay: isVideoAutoPlay ? JSON.parse(isVideoAutoPlay) : true,
    startAutoPlay: false,
    volume: JSON.parse(videoVolume) || 1.0,
    playbackSpeed: 1,
    markedVideoLoader: false,
    autoQuality: false,
    videoPollArray: [],
    subtitlesEnabled: true,
    isFullScreen: false,
    popOut: false,
    transcriptNotes: null,
    isNetworkError: false,
  },

  extraReducers: {
    [getNotesForVideo.pending]: (state) => {},
    [getNotesForVideo.fulfilled]: (state, { payload }) => {
      const data =
        payload?.data?.notes && payload?.data?.notes?.length > 0
          ? payload?.data?.notes
          : []

      const newArray = data
        .filter((e) => e?.resource_type === 'TranscriptPosition')
        .flatMap(
          (e) =>
            e?.location_breadcrumbs?.filter(
              (a) => a?.type === 'transcript_position',
            ) || [],
        )

      state.transcriptNotes = { notes: newArray }
    },
    [getNotesForVideo.rejected]: (state, action) => {},
    [markVideoViewed.pending]: (state, { payload, meta }) => {
      state.markedVideoLoader = true
    },
    [markVideoViewed.fulfilled]: (state, { payload }) => {
      state.markedVideoLoader = false
      const postIndex = findIndex(state.resourceData?.data?.book?.chapters, {
        id: payload.data?.chapter_id,
      })
      if (
        postIndex !== -1 &&
        state.resourceData?.data?.book?.chapters[postIndex]?.user_read !==
          undefined &&
        state.bookDetails?.user_read !== undefined
      ) {
        state.resourceData.data.book.chapters[postIndex].user_read =
          payload?.data?.user_read
        state.bookDetails.user_read = payload?.data?.user_read
      }
    },
    [markVideoViewed.rejected]: (state, { payload }) => {
      state.markedVideoLoader = false
    },

    [updateTranscriptNote.pending]: (state, { payload }) => {
      state.addTranscriptLoader = true
    },
    [updateTranscriptNote.fulfilled]: (state, { payload, meta }) => {
      state.addTranscriptLoader = false
      const currentTranscriptIndex = findIndex(
        state.bookDetails.chapterable.transcript_notes,
        {
          id: meta?.arg?.transcriptId,
        },
      )
      if (currentTranscriptIndex !== -1) {
        state.bookDetails.chapterable.transcript_notes[currentTranscriptIndex] =
          payload?.data?.transcript_note

        const currentIndex = findIndex(state.resourceData.data.book.chapters, {
          id: meta?.arg?.chapterId,
        })
        if (currentIndex !== -1) {
          state.resourceData.data.book.chapters[
            currentIndex
          ].chapterable.transcript_notes =
            state.bookDetails.chapterable.transcript_notes
        }
      }
      // state.bookDetails.chapterable.transcript_notes = [
      //   ...state.bookDetails.chapterable.transcript_notes,
      //   payload?.data?.transcript_note,
      // ]
    },
    [updateTranscriptNote.rejected]: (state, { payload }) => {
      state.addTranscriptLoader = false
    },
    [deleteTranscriptNotes.pending]: (state, { payload }) => {
      state.deleteLoader = true
    },
    [deleteTranscriptNotes.fulfilled]: (state, { payload, meta }) => {
      state.deleteId = null
      state.deleteLoader = false
      state.bookDetails.chapterable.transcript_notes =
        state.bookDetails?.chapterable?.transcript_notes?.filter(
          (note) => note?.id !== meta?.arg?.noteId,
        )

      const currentIndex = findIndex(state.resourceData.data.book.chapters, {
        id: meta?.arg?.chapterId,
      })
      if (currentIndex !== -1) {
        state.resourceData.data.book.chapters[
          currentIndex
        ].chapterable.transcript_notes =
          state.bookDetails.chapterable.transcript_notes
      }
    },
    [deleteTranscriptNotes.rejected]: (state, { payload }) => {
      state.deleteLoader = false
    },
    [addTranscriptNote.pending]: (state, { payload }) => {
      state.addTranscriptLoader = true
    },
    [addTranscriptNote.fulfilled]: (state, { payload, meta }) => {
      state.addTranscriptLoader = false
      const transcript = payload?.data?.note?.location_breadcrumbs?.filter(
        (a) => a?.type === 'transcript_position',
      )
      state.transcriptNotes.notes = [
        ...(state.transcriptNotes.notes || []),
        ...transcript,
      ]
    },
    [addTranscriptNote.rejected]: (state, { payload }) => {
      state.addTranscriptLoader = false
    },
    [retryQuizChapter.pending]: (state, action) => {
      state.retryLoader = true
    },
    [retryQuizChapter.fulfilled]: (state, { meta, payload }) => {
      state.retryLoader = false
      const submission = payload?.data?.submission

      state.bookDetails.chapterable.submission = submission
      state.questionsIndex = 0
      const currentIndex = findIndex(state.resourceData.data.book.chapters, {
        id: meta?.arg?.chapterId,
      })
      if (currentIndex !== -1) {
        state.resourceData.data.book.chapters[
          currentIndex
        ].chapterable.submission = submission
      }
    },
    [retryQuizChapter.rejected]: (state, action) => {
      state.retryLoader = false
    },
    // --------
    [submitQuiz.pending]: (state, action) => {
      state.submitLoader = true
    },
    [submitQuiz.fulfilled]: (state, { meta, payload }) => {
      state.submitLoader = false
      const submission = payload?.data?.submission

      const currentIndex = findIndex(state.resourceData?.data?.book?.chapters, {
        id: meta?.arg?.chapterId,
      })
      if (currentIndex !== -1) {
        if (submission?.is_completed) {
          state.bookDetails.chapterable.submission = submission
          state.questionsIndex = 0
          state.resourceData.data.book.chapters[currentIndex].user_read = true
          state.resourceData.data.book.chapters[
            currentIndex
          ].chapterable.submission = submission
        }
      }
    },
    [submitQuiz.rejected]: (state, action) => {
      state.submitLoader = false
    },
    //---------------
    [submitVideoPollResponse.pending]: (state, action) => {
      state.submitVideoPollLoader = true
    },
    [submitVideoPollResponse.fulfilled]: (state, { meta, payload }) => {
      const pollQuestionIndex = state.selectedPoll?.questions?.findIndex(
        (item) => item?.id === meta?.arg?.questionId,
      )
      const answerIndex = state.selectedPoll?.questions[
        pollQuestionIndex
      ]?.answers?.findIndex(
        (item) =>
          item?.id === payload?.data?.response?.video_chapter_poll_answer_id,
      )
      if (
        answerIndex !== -1 &&
        pollQuestionIndex !== -1 &&
        state.selectedPoll?.questions?.length > 0
      ) {
        state.selectedPoll.questions[pollQuestionIndex].answers[
          answerIndex
        ].user_response = payload?.data?.response
      }
    },
    [submitVideoPollResponse.rejected]: (state, action) => {
      state.submitVideoPollLoader = false
    },
    //
    [clearVideoPollResponse.pending]: (state, action) => {
      state.retryVideoPollLoader = true
    },
    [clearVideoPollResponse.fulfilled]: (state, { meta, payload }) => {
      state.retryVideoPollLoader = false
      const pollQuestionIndex = state.selectedPoll?.questions?.findIndex(
        (item) => item?.id === meta?.arg?.questionId,
      )
      const answerIndex = state.selectedPoll?.questions[
        pollQuestionIndex
      ]?.answers?.findIndex(
        (item) => item?.user_response?.id === meta?.arg?.userResponseId,
      )
      if (
        answerIndex !== -1 &&
        pollQuestionIndex !== -1 &&
        state.selectedPoll?.questions?.length > 0
      ) {
        state.selectedPoll.questions[pollQuestionIndex].answers[
          answerIndex
        ].user_response = null
      }
    },
    [clearVideoPollResponse.rejected]: (state, action) => {
      state.retryVideoPollLoader = false
    },
    [getResourceData.pending]: (state, action) => {
      state.isNetworkError = false
      if (!action?.meta?.arg?.isFromPoll) {
        state.resourceData.isLoading = true
      }
    },
    [getResourceData.fulfilled]: (state, { meta, payload }) => {
      state.resourceData = {
        isLoading: false,
        data: payload.data,
      }
      state.bookDetails = payload.data?.book?.chapters[state.tabDataIndex]
      const resource = payload.data?.book?.chapters[state.tabDataIndex]
      if (
        resource?.chapterable_type === 'quiz' &&
        resource?.chapterable?.questions?.length > 0
      ) {
        state.quizAnswers = resource.chapterable?.questions?.map(
          (question) => ({
            quiz_chapter_question_id: question?.id,
            input: resource?.chapterable?.submission
              ? resource?.chapterable?.submission?.user_inputs?.find(
                  (input) => input?.question_id === question?.id,
                )?.input
              : null,
            time: resource?.chapterable?.submission
              ? resource?.chapterable?.submission?.user_inputs?.find(
                  (input) => input?.question_id === question?.id,
                )?.time_taken
              : 0,
          }),
        )
        state.questionsIndex = 0
        state.resourceQuestions =
          payload.data?.book?.chapters[
            state.tabDataIndex
          ]?.chapterable?.questions[0]
      } else if (resource?.chapterable_type === 'video') {
        state.videoPollArray =
          resource?.chapterable?.polls?.length > 0
            ? resource?.chapterable?.polls?.map((poll) => ({
                ...poll,
                isViewed: meta?.arg?.isFromPoll ? true : false,
              }))
            : []
      }
      state.submitVideoPollLoader = false
    },
    [getResourceData.rejected]: (state, { payload }) => {
      state.isNetworkError = payload?.isAxiosError && !payload?.response
      state.resourceData.isLoading = false
    },
    [getScormContent.pending]: (state, { payload }) => {},
    [getScormContent.fulfilled]: (state, { payload }) => {
      state.scormIndexFile = payload
    },
    [getScormContent.rejected]: (state, { payload }) => {},
    [markChapterRead.pending]: (state, action) => {},
    [markChapterRead.fulfilled]: (state, action) => {},
    [markChapterRead.rejected]: (state, action) => {},
    [deleteBookmark.pending]: (state) => {
      state.bookmarkLoading = true
    },
    [deleteBookmark.rejected]: (state) => {
      state.bookmarkLoading = false
    },
    [deleteBookmark.fulfilled]: (state, { meta, payload }) => {
      if (meta.arg.type !== 'scorm') {
        const updateIndex = findIndex(
          state.resourceData?.data?.book?.chapters,
          {
            id: meta.arg.typeId,
          },
        )
        if (updateIndex !== -1) {
          state.resourceData.data.book.chapters[updateIndex].user_bookmark =
            null
        }
      } else {
        state.resourceData.data.scorm.user_bookmark = null
      }
      state.bookmarkLoading = false
    },
    [createBookmark.pending]: (state) => {
      state.bookmarkLoading = true
    },
    [createBookmark.rejected]: (state) => {
      state.bookmarkLoading = false
    },
    [createBookmark.fulfilled]: (state, { meta, payload }) => {
      if (meta.arg.type !== 'scorm') {
        const updateIndex = findIndex(
          state.resourceData?.data?.book?.chapters,
          {
            id: payload.data.bookmark.resource_id,
          },
        )
        if (updateIndex !== -1) {
          state.resourceData.data.book.chapters[updateIndex].user_bookmark =
            payload.data.bookmark
        }
      } else {
        state.resourceData.data.scorm.user_bookmark = payload.data.bookmark
      }
      state.bookmarkLoading = false
    },
  },
  reducers: {
    updateDiscussionNumber: (state, { payload }) => {
      const postIndex = findIndex(state?.resourceData?.data?.book?.chapters, {
        id: parseInt(payload.bookChapterId),
      })
      if (postIndex !== -1 && payload.numResourceQuestions) {
        state.resourceData.data.book.chapters[
          postIndex
        ].num_resource_questions = payload.numResourceQuestions
      }
    },
    setPopOut: (state, { payload }) => {
      state.popOut = payload
    },
    setIsFullScreen: (state, { payload }) => {
      state.isFullScreen = payload
    },
    setSubtitlesEnabled: (state, { payload }) => {
      state.subtitlesEnabled = payload
    },
    setAutoQuality: (state, { payload }) => {
      state.autoQuality = payload
    },

    setVideoAutoPlay: (state, { payload }) => {
      state.videoAutoPlay = payload
      sessionStorage.setItem('video-auto-play', payload)
    },
    setShowIntroductionModal: (state, { payload }) => {
      state.showIntroductionModal = payload
    },
    setIsVideoFinished: (state, { payload }) => {
      state.isVideoFinished = payload
    },
    setShowVideoModal: (state, { payload }) => {
      state.showVideoModal = payload
    },
    setDeleteId: (state, { payload }) => {
      state.deleteId = payload
    },
    setSelectedNote: (state, { payload }) => {
      state.selectedNote = payload
    },
    setTranscriptNoteModal: (state, { payload }) => {
      state.showTranscriptNoteModal = payload
    },
    setChangedDuration: (state, { payload }) => {
      state.changedDuration = payload
    },
    setChangedPollDuration: (state, { payload }) => {
      state.changedPollDuration = payload
    },
    setSelectedVideoMedia: (state, { payload }) => {
      state.selectedVideoMedia = payload
    },
    setSelectedPollVideoMedia: (state, { payload }) => {
      state.selectedPollVideoMedia = payload
    },
    setIsQualityOpen: (state, { payload }) => {
      state.isQualityOpen = payload
    },
    setIsPollQualityOpen: (state, { payload }) => {
      state.isPollQualityOpen = payload
    },
    setIsSettingOpen: (state, { payload }) => {
      state.isSettingOpen = payload
    },
    setIsPollSettingOpen: (state, { payload }) => {
      state.isPollSettingOpen = payload
    },
    setIsPIPActive: (state, { payload }) => {
      state.isPIPActive = payload
    },
    setPlayedPIPSeconds: (state, { payload }) => {
      state.playedPIPSeconds = payload
    },
    setPlayedSeconds: (state, { payload }) => {
      state.playedSeconds = payload
    },
    setTranscriptData: (state, { payload }) => {
      state.transcriptData = payload
    },
    setVideoNoteList: (state, { payload }) => {
      state.videoNoteList = payload
    },
    clearLearQuiz: (state, { payload }) => {
      state.isFullBookScreen = false
      state.bookingResourceTab = 0
      state.isClickedTab = false
    },
    setIsClickedTab: (state, { payload }) => {
      state.isClickedTab = payload
    },
    setBookingResourceTab: (state, { payload }) => {
      state.bookingResourceTab = payload
    },
    setFullBookScreen: (state, { payload }) => {
      state.isFullBookScreen = payload
    },
    updateQuestionsIndex: (state, { payload }) => {
      state.questionsIndex = payload
    },
    setBookDetails: (state, { payload }) => {
      if (state.isPIPActive) {
        state.isPIPActive = false
      }
      state.isVideoFinished = false
      state.playedSeconds = 0
      state.bookDetails = payload
      if (payload?.chapterable_type === 'quiz') {
        // if (payload.chapterable?.intro_html) {
        //   setTimeout(() => {
        //     state.showIntroductionModal = true
        //   }, 1000)
        // }
        state.quizAnswers = payload.chapterable?.questions?.map((question) => ({
          quiz_chapter_question_id: question?.id,
          input: payload?.chapterable?.submission
            ? payload?.chapterable?.submission?.user_inputs?.find(
                (input) => input?.question_id === question?.id,
              )?.input
            : null,
          time: payload?.chapterable?.submission
            ? payload?.chapterable?.submission?.user_inputs?.find(
                (input) => input?.question_id === question?.id,
              )?.time_taken
            : 0,
        }))
        state.questionsIndex = 0
      } else if (payload?.chapterable_type === 'video') {
        state.videoPollArray =
          payload?.chapterable?.polls?.length > 0
            ? state.bookDetails?.chapterable?.polls?.map((poll) => ({
                ...poll,
                isViewed: false,
              }))
            : []
      }
    },
    resetPollView: (state) => {
      state.videoPollArray =
        state.videoPollArray?.length > 0
          ? state.bookDetails?.chapterable?.polls?.map((poll) => ({
              ...poll,
              isViewed: false,
            }))
          : []
    },
    setSelectedVideoLink: (state, { payload }) => {
      state.selectedVideoLink = payload
    },

    setSelectedPoll: (state, { payload }) => {
      state.selectedPoll = payload
      const pollIndex = findIndex(state?.videoPollArray, {
        id: payload?.id,
      })
      if (pollIndex !== -1) {
        state.videoPollArray[pollIndex].isViewed = true
      }
    },
    setPollQuestionIndex: (state, { payload }) => {
      state.pollQuestionIndex = payload
    },
    updateTabIndex: (state, { payload }) => {
      state.tabDataIndex = payload
      // state.bookDetails = state?.resourceData.data?.book?.chapters[payload]
    },
    updateChapterIndex: (state, { payload }) => {
      state.tabDataIndex = parseInt(payload.chapterIndex) - 1 || 0
      state.bookDetails =
        state?.resourceData.data?.book?.chapters[state.tabDataIndex]
      if (state.bookDetails?.chapterable_type === 'quiz') {
        state.quizAnswers = state.bookDetails.chapterable?.questions?.map(
          (question) => ({
            quiz_chapter_question_id: question?.id,
            input: state.bookDetails?.chapterable?.submission
              ? state.bookDetails?.chapterable?.submission?.user_inputs?.find(
                  (input) => input?.question_id === question?.id,
                )?.input
              : null,
            time: state.bookDetails?.chapterable?.submission
              ? state.bookDetails?.chapterable?.submission?.user_inputs?.find(
                  (input) => input?.question_id === question?.id,
                )?.time_taken
              : 0,
          }),
        )
      } else if (state.bookDetails?.chapterable_type === 'video') {
        state.videoPollArray =
          state.bookDetails?.chapterable?.polls?.length > 0
            ? state.bookDetails?.chapterable?.polls?.map((poll) => ({
                ...poll,
                isViewed: false,
              }))
            : []
      }
    },
    setMarkAsRead: (state, { payload }) => {
      const postIndex = findIndex(state.resourceData.data.book.chapters, {
        id: payload.chapterId,
      })
      state.resourceData.data.book.chapters[postIndex].user_read = true
    },
    updatePrevIndex: (state, { payload }) => {
      state.tabDataIndex = payload
      state.bookDetails = state?.resourceData.data?.book?.chapters[payload]
    },
    clearResourceData: (state, action) => {
      state.resourceData = { isLoading: false, data: [] }
      state.tabDataIndex = 0
    },
    setAnswerOfQuestion: (state, { payload }) => {
      const currentIndex = findIndex(state.quizAnswers, {
        quiz_chapter_question_id: payload?.id,
      })
      if (currentIndex !== -1) {
        state.quizAnswers[currentIndex].input = payload?.input
      }
    },
    setTimeTaken: (state, { payload }) => {
      state.quizAnswers[state.questionsIndex].time += 1
    },
    updateTimeTaken: (state, { payload }) => {
      state.questionTimeTaken = payload
    },
    clearVideoChapterData: (state) => {
      state.isVideoFinished = false
      state.playedSeconds = 0
    },
    setStartAutoPlay: (state, { payload }) => {
      state.startAutoPlay = payload
    },
    setVolume: (state, { payload }) => {
      state.volume = payload
      sessionStorage.setItem('video-volume', payload)
    },
    setPlaybackSpeed: (state, { payload }) => {
      state.playbackSpeed = payload
    },
  },
})

export const {
  resetPollView,
  updateDiscussionNumber,
  setPopOut,
  setIsFullScreen,
  setSubtitlesEnabled,
  setSelectedVideoLink,
  setAutoQuality,
  setPlaybackSpeed,
  setVolume,
  setStartAutoPlay,
  clearVideoChapterData,
  setVideoAutoPlay,
  setShowIntroductionModal,
  setIsVideoFinished,
  setShowVideoModal,
  setDeleteId,
  setSelectedNote,
  setTranscriptNoteModal,
  setChangedDuration,
  setChangedPollDuration,
  setSelectedVideoMedia,
  setSelectedPollVideoMedia,
  setIsPollQualityOpen,
  setIsQualityOpen,
  setIsPollSettingOpen,
  setIsSettingOpen,
  setIsPIPActive,
  setPlayedPIPSeconds,
  setPlayedSeconds,
  setTranscriptData,
  setTimeTaken,
  clearLearQuiz,
  setIsClickedTab,
  setBookingResourceTab,
  setFullBookScreen,
  updateQuestionsIndex,
  setBookDetails,
  setPollQuestionIndex,
  setMarkAsRead,
  updateTabIndex,
  updatePrevIndex,
  clearResourceData,
  updateChapterIndex,
  setSelectedPoll,
  setAnswerOfQuestion,
  updateTimeTaken,
  setVideoNoteList,
} = resourceSlice.actions

export default resourceSlice.reducer
