const UpArrow = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0203 9.68721C10.825 9.88247 10.5084 9.88247 10.3132 9.68721L8.00004 7.3741L5.68693 9.68721C5.49167 9.88247 5.17508 9.88247 4.97982 9.68721C4.78456 9.49195 4.78456 9.17537 4.97982 8.98011L7.64649 6.31344C7.84175 6.11818 8.15833 6.11818 8.35359 6.31344L11.0203 8.98011C11.2155 9.17537 11.2155 9.49195 11.0203 9.68721Z"
      fill="currentColor"
    />
  </svg>
)
export default UpArrow
