import { FreeTrial, Spinner } from 'components/shared'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getProgressData } from 'redux/dashboard/dashboardSlice'
import Graph from './Graph'

const MyProgress = () => {
  const dispatch = useDispatch()
  const { progressData } = useSelector((state) => ({
    progressData: state.dashboard.progressData,
  }))
  useEffect(() => {
    dispatch(getProgressData())
  }, [dispatch])
  return (
    <>
      {progressData && progressData.isLocked ? (
        <div className="h-max my-12 text-center">
          <FreeTrial />
        </div>
      ) : progressData.isLoading ? (
        <div className="py-3">
          <Spinner />
        </div>
      ) : (
        <Graph />
      )}
    </>
  )
}

export default MyProgress
