import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex, shuffle } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'
// import toast from 'react-hot-toast'

export const startGraphTableTrainer = createAsyncThunk(
  'graphTableTrainer/startGraphTableTrainer',
  async ({ difficulty, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `trainer/graph-table/start-attempt`,
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      onError && onError()
      throw rejectWithValue(err.response.data)
    }
  },
)

export const submitGraphTableTrainer = createAsyncThunk(
  'graphTableTrainer/submitGraphTableTrainer',
  async (data) => {
    const time_spent_on_skipped = data?.timeSpentOnSkip?.reduce(
      (partialSum, a) => partialSum + a,
      0,
    )
    return await axiosInterceptors.post(
      `trainer/graph-table/${data.attemptId}/submit-attempt`,
      {
        num_questions_answered_correctly: data.questionAnsweredCorrectly,
        num_incorrect_selection_attempts: data.incorrectSelectionAttempts,
        time_taken: data.timeTaken,
        num_skipped: data.num_skipped,
        time_spent_on_skipped,
      },
      { headers: { notToRedirect404: true } },
    )
  },
)

const graphTableTrainerSlice = createSlice({
  name: 'graphTable',
  initialState: {
    graphTableData: { isRejected: false },
    selectedQue: {},
    ansOfGraphTable: [],
    curQuestion: 0,
    subQuestion: 0,
    graphTableResult: [],
    scoreEvolutionGraphData: [],
    userLevelInformation: [],
    displaySideQuestion: false,
    disableGraphTableSideBar: false,
    graphTableLoading: false,
    timeTaken: 0,
    countDownTime: 3,
    correctAnsCount: 0,
    graphTableSubmitLoading: false,
    graphTableExamTime: null,
    graphTableDefaultTime: 20,
    observationTimePerQuestion: null,
    selectedFigure1Cells: [],
    selectedFigure2Cells: [],
    examTimePerQuestion: null,
    recommendedSecondsObservation: null,
    hideAfterObservation: false,
    isShowIncorrectLabel: false,
    numQuestionsAnsweredCorrectly: 0,
    numIncorrectSelectionAttempts: 0,
    totalTimeTaken: 0,
    showSolution: false,
    graphTableOptions: [],
    countSkipQuestion: 0,
  },
  extraReducers: {
    [startGraphTableTrainer.pending]: (state, action) => {
      state.graphTableLoading = true
      state.graphTableData.isRejected = false
    },
    [startGraphTableTrainer.fulfilled]: (state, { payload }) => {
      state.graphTableData = payload.data
      state.graphTableOptions = payload.data.attempt.questions.map((question) =>
        shuffle([...question.incorrect_options, question.correct_answer]),
      )
      state.selectedQue = payload.data.attempt.questions[0]
      state.recommendedSecondsObservation =
        payload.data.attempt.observation_time_per_question || 0
      state.observationTimePerQuestion =
        payload.data.attempt.observation_time_per_question || 0
      state.hideAfterObservation =
        payload.data.attempt.hide_after_observation || false
      state.graphTableExamTime =
        payload.data.attempt.attempt_time_per_question ||
        state.graphTableDefaultTime
      state.examTimePerQuestion = payload.data.attempt.attempt_time_per_question
    },
    [startGraphTableTrainer.rejected]: (state, action) => {
      state.graphTableData.isRejected = true
      state.isStartedGraphTableTraining = false
      state.graphTableLoading = false
    },
    [submitGraphTableTrainer.pending]: (state) => {
      state.graphTableSubmitLoading = true
      state.graphTableData.isRejected = false
    },
    [submitGraphTableTrainer.fulfilled]: (state, { payload }) => {
      state.graphTableResult = payload.data
      state.graphTableScore = payload.data.score
      state.scoreEvolutionGraphData = payload.data.score_evolution_graph
      state.userLevelInformation = payload.data.user_level_information
      state.graphTableExamTime = null
      state.graphTableSubmitLoading = false
      state.disableGraphTableSideBar = false
    },
    [submitGraphTableTrainer.rejected]: (state) => {
      state.graphTableSubmitLoading = false
      state.graphTableData.isRejected = true
    },
  },
  reducers: {
    setSelectedQue: (state, { payload }) => {
      state.selectedQue = payload
      state.subQuestion = 0
      state.curQuestion += 1
      state.isShowIncorrectLabel = false
      state.selectedFigure1Cells = []
      state.selectedFigure2Cells = []
    },
    setIsRejected: (state, { payload }) => {
      state.graphTableData.isRejected = payload
    },
    updateCountDownTime: (state) => {
      if (state.countDownTime === 0) {
        state.graphTableLoading = false
      } else {
        state.countDownTime = state.countDownTime - 1
      }
    },
    setCurQuestion: (state, { payload }) => {
      state.curQuestion += 1
      state.isShowIncorrectLabel = false
    },

    setDisplaySideQuestion: (state, { payload }) => {
      state.displaySideQuestion = payload
    },
    setGraphTableExamTime: (state, { payload }) => {
      state.graphTableExamTime -= 1
    },
    setObservationExamTime: (state) => {
      if (state.recommendedSecondsObservation > 0)
        state.recommendedSecondsObservation -= 1
    },
    updateObservationExamTime: (state) => {
      state.recommendedSecondsObservation = state.observationTimePerQuestion
      state.graphTableExamTime = state.examTimePerQuestion
    },
    updateSelectedFigure2Cells: (state, { payload }) => {
      if (payload?.type === 'pie') {
        state.selectedFigure2Cells = state.selectedFigure2Cells.includes(
          payload.value,
        )
          ? state.selectedFigure2Cells.filter((data) => data !== payload.value)
          : [...state.selectedFigure2Cells, payload.value]
      } else if (!payload) {
        state.selectedFigure2Cells = []
      } else {
        const dataExist = state.selectedFigure2Cells.find(
          (data) =>
            data.column === payload.column &&
            data.value === payload.value &&
            data.row === payload.row,
        )

        if (dataExist) {
          state.selectedFigure2Cells = state.selectedFigure2Cells.filter(
            (data) =>
              !(
                data.value === payload.value &&
                data.column === payload.column &&
                data.row === payload.row
              ),
          )
        } else {
          state.selectedFigure2Cells = [...state.selectedFigure2Cells, payload]
        }
      }
    },
    updateSelectedFigure1Cells: (state, { payload }) => {
      const dataExist = state.selectedFigure1Cells.find(
        (data) =>
          data.column === payload.column &&
          data.value === payload.value &&
          data.row === payload.row,
      )
      if (dataExist) {
        state.selectedFigure1Cells = state.selectedFigure1Cells.filter(
          (data) =>
            !(
              data.value === payload.value &&
              data.column === payload.column &&
              data.row === payload.row
            ),
        )
      } else {
        state.selectedFigure1Cells = [...state.selectedFigure1Cells, payload]
      }
    },
    setTryAgainGraphTable: (state, { payload }) => {
      state.graphTableData = {}
      state.ansOfGraphTable = []
      state.curQuestion = 0
      state.subQuestion = 0
      state.graphTableResult = []
      state.graphTablePassage = null
      state.displaySideQuestion = false
      state.graphTableLoading = false
      state.countDownTime = 3
      state.isShowIncorrectLabel = false
      state.numQuestionsAnsweredCorrectly = 0
      state.numIncorrectSelectionAttempts = 0
      state.recommendedSecondsObservation = null
      state.graphTableExamTime = null
      state.graphTableDefaultTime = 20
      state.observationTimePerQuestion = null
      state.selectedFigure1Cells = []
      state.examTimePerQuestion = null
      state.timeTaken = 0
      state.totalTimeTaken = 0
      state.showSolution = false
      state.countSkipQuestion = 0
    },
    setCountSkipQuestion: (state, { payload }) => {
      state.countSkipQuestion = payload
    },
    updateTimeTaken: (state, { payload }) => {
      state.timeTaken += 1
    },
    resetTimeTaken: (state, { payload }) => {
      state.timeTaken = 0
    },
    setAnswerOfGraphTable: (state, { payload }) => {
      state.ansOfGraphTable[state.curQuestion] = payload.response
    },
    setDisableGraphTableSideBar: (state, { payload }) => {
      state.disableGraphTableSideBar = payload
    },
    skipCurQuestion: (state, { payload }) => {
      const postIndex = findIndex(state.ansOfGraphTable, {
        questionId: payload.questionId,
      })
      state.isShowIncorrectLabel = false
      state.ansOfGraphTable[postIndex].response = ''
      state.ansOfGraphTable[postIndex].time = payload.time
      state.curQuestion += 1
    },
    setIsShowIncorrectLabel: (state, { payload }) => {
      state.isShowIncorrectLabel = payload
    },
    updateSubQuestion: (state, { payload }) => {
      if (state.subQuestion === 0) {
        state.recommendedSecondsObservation = null
        state.graphTableExamTime = state.examTimePerQuestion
      } else {
        state.recommendedSecondsObservation = state.observationTimePerQuestion
        state.graphTableExamTime = null
      }
      state.subQuestion = payload ? payload : state.subQuestion + 1
    },
    updateIncorrectSelectionAttempt: (state) => {
      state.numIncorrectSelectionAttempts += 1
    },
    updateQuestionAnsweredCorrectly: (state) => {
      state.numQuestionsAnsweredCorrectly += 1
      state.showSolution = false
    },
    updateTotalTimeTaken: (state) => {
      state.totalTimeTaken += 1
    },
    setShowSolution: (state, { payload }) => {
      state.showSolution = payload
    },
  },
})

export const {
  setCountSkipQuestion,
  setSelectedQue,
  setCurQuestion,
  setAnswerOfGraphTable,
  setDisplaySideQuestion,
  setTryAgainGraphTable,
  setGraphTableExamTime,
  setObservationExamTime,
  skipCurQuestion,
  setIsRejected,
  updateTimeTaken,
  resetTimeTaken,
  updateCountDownTime,
  setDisableGraphTableSideBar,
  updateObservationExamTime,
  updateSelectedFigure1Cells,
  setIsShowIncorrectLabel,
  updateSubQuestion,
  updateIncorrectSelectionAttempt,
  updateQuestionAnsweredCorrectly,
  updateSelectedFigure2Cells,
  updateTotalTimeTaken,
  setShowSolution,
} = graphTableTrainerSlice.actions

export default graphTableTrainerSlice.reducer
