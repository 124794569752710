const Flag = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 12 13"
    className={`h-3 w-3 ${className}`}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833344 1.33331C0.833344 1.05717 1.0572 0.833313 1.33334 0.833313H10.6667C10.8689 0.833313 11.0512 0.955134 11.1286 1.14197C11.206 1.32881 11.1632 1.54387 11.0202 1.68687L8.37378 4.33331L11.0202 6.97976C11.1632 7.12276 11.206 7.33782 11.1286 7.52465C11.0512 7.71149 10.8689 7.83331 10.6667 7.83331H1.83334V12C1.83334 12.2761 1.60949 12.5 1.33334 12.5C1.0572 12.5 0.833344 12.2761 0.833344 12V1.33331ZM1.83334 6.83331H9.45957L7.31312 4.68687C7.11786 4.4916 7.11786 4.17502 7.31312 3.97976L9.45957 1.83331H1.83334V6.83331Z"
      fill="currentColor"
    />
  </svg>
)

export default Flag
