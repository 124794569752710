const Calculator = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 18 20"
    className={`h-5 w-5 ${className}`}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.75 3C1.75 2.30964 2.30964 1.75 3 1.75H15C15.6904 1.75 16.25 2.30964 16.25 3V17C16.25 17.6904 15.6904 18.25 15 18.25H3C2.30964 18.25 1.75 17.6904 1.75 17V3ZM3 0.25C1.48122 0.25 0.25 1.48122 0.25 3V17C0.25 18.5188 1.48122 19.75 3 19.75H15C16.5188 19.75 17.75 18.5188 17.75 17V3C17.75 1.48122 16.5188 0.25 15 0.25H3ZM5.75 6C5.75 5.86193 5.86193 5.75 6 5.75H12C12.1381 5.75 12.25 5.86193 12.25 6V7C12.25 7.13807 12.1381 7.25 12 7.25H6C5.86193 7.25 5.75 7.13807 5.75 7V6ZM6 4.25C5.0335 4.25 4.25 5.0335 4.25 6V7C4.25 7.9665 5.0335 8.75 6 8.75H12C12.9665 8.75 13.75 7.9665 13.75 7V6C13.75 5.0335 12.9665 4.25 12 4.25H6ZM5 11.25C5.41421 11.25 5.75 11.5858 5.75 12V12.01C5.75 12.4242 5.41421 12.76 5 12.76C4.58579 12.76 4.25 12.4242 4.25 12.01V12C4.25 11.5858 4.58579 11.25 5 11.25ZM9.75 12C9.75 11.5858 9.41421 11.25 9 11.25C8.58579 11.25 8.25 11.5858 8.25 12V12.01C8.25 12.4242 8.58579 12.76 9 12.76C9.41421 12.76 9.75 12.4242 9.75 12.01V12ZM13 11.25C13.4142 11.25 13.75 11.5858 13.75 12V12.01C13.75 12.4242 13.4142 12.76 13 12.76C12.5858 12.76 12.25 12.4242 12.25 12.01V12C12.25 11.5858 12.5858 11.25 13 11.25ZM5.75 15C5.75 14.5858 5.41421 14.25 5 14.25C4.58579 14.25 4.25 14.5858 4.25 15V15.01C4.25 15.4242 4.58579 15.76 5 15.76C5.41421 15.76 5.75 15.4242 5.75 15.01V15ZM9 14.25C9.41421 14.25 9.75 14.5858 9.75 15V15.01C9.75 15.4242 9.41421 15.76 9 15.76C8.58579 15.76 8.25 15.4242 8.25 15.01V15C8.25 14.5858 8.58579 14.25 9 14.25ZM13.75 15C13.75 14.5858 13.4142 14.25 13 14.25C12.5858 14.25 12.25 14.5858 12.25 15V15.01C12.25 15.4242 12.5858 15.76 13 15.76C13.4142 15.76 13.75 15.4242 13.75 15.01V15Z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Calculator
