const University = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="current"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="current"
      fillRule="evenodd"
      d="M8.186 2.87a.5.5 0 00-.372 0L1.148 5.535a.5.5 0 000 .928l2.352.941v3.262c0 .815.636 1.455 1.448 1.861.835.418 1.933.639 3.052.639 1.118 0 2.216-.221 3.052-.639.812-.406 1.448-1.046 1.448-1.861V7.405l1.667-.666V10a.5.5 0 101 0V6a.5.5 0 00-.315-.464L8.186 2.869zm3.639 3.728a.471.471 0 00-.021.009L8 8.128 4.196 6.607a.498.498 0 00-.021-.009L2.68 6 8 3.872 13.32 6l-1.495.598zM4.5 7.805l3.314 1.326a.5.5 0 00.372 0L11.5 7.805v2.862c0 .245-.207.623-.895.967-.665.332-1.602.533-2.605.533s-1.94-.2-2.605-.533c-.688-.344-.895-.722-.895-.967V7.805z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default University
