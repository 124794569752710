import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

export const getReviewQuestionsData = createAsyncThunk(
  'reviewQuestions/getReviewQuestionsData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `history/review/attempted-questions`,
        {
          params: {
            filter_by: 'all',
            question_set_attempt_id: data.id,
            page: data.page,
            section_id: data.sectionId ? data.sectionId : null,
          },
        },
      )
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const setReviewQuestionsBookmark = createAsyncThunk(
  'reviewQuestions/setReviewQuestionsBookmark',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(`bookmark`, {
        type: 'question',
        type_id: data.questionSetAttemptId,
      })
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const deleteBookmarkQuestion = createAsyncThunk(
  'reviewQuestions/deleteBookmarkQuestion',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.delete(
        `bookmark/${data.bookmarkId}`,
        {
          type_id: data.typeId,
        },
      )
      return res
    } catch (error) {
      throw rejectWithValue(error)
    }
  },
)

export const setReviewQuestionsRating = createAsyncThunk(
  'reviewQuestions/setReviewQuestionsRating',
  async (data) => {
    return await axiosInterceptors.put(`question/save-rating/${data.id}`, {
      user_rating: data.rating,
    })
  },
)

export const setReviewQuestionsReason = createAsyncThunk(
  'reviewQuestions/setReviewQuestionsReason',
  async (data) => {
    return await axiosInterceptors.put(`history/error-log-reason/${data.id}`, {
      error_log_reason_id: data.reasonId,
    })
  },
)

export const getReviewQuestionsBySection = createAsyncThunk(
  'reviewQuestions/getReviewQuestionsBySection',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`history/error-log`, {
        params: {
          section_id: data.id,
        },
      })
      return res
    } catch (error) {
      throw rejectWithValue(error)
    }
  },
)

export const getQuestionNotes = createAsyncThunk(
  'reviewQuestions/getQuestionNotes',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`history/error-log/${data.id}`)
      return res
    } catch (error) {
      throw rejectWithValue(error)
    }
  },
)

export const markQuestionReviewed = createAsyncThunk(
  'reviewQuestions/markQuestionReviewed',
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(
        `history/mark-question-reviewed/${id}`,
      )
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const reviewQuestionsSlice = createSlice({
  name: 'reviewQuestions',
  initialState: {
    reviewQuestionsData: {
      isLoading: false,
      isLoadingMore: false,
      data: [],
      error: null,
    },
    questionReviewPage: false,
    questionAttempts: { data: [] },
    selectedReviewQuestions: null,
    showSidebar: false,
    bookmarkLoading: false,
    selectedFilter: [],
    pageNumber: 1,
    invalidQuestionId: false,
    attemptableData: null,
    markQuestionReviewedId: null,
    ucatView: false,
    showNotesAndDiscussion: {
      openTab: false,
      showNotes: false,
      showDiscussion: false,
    },
    isFullScreen: false,
    lastViewedQuestionBySections: [],
  },

  extraReducers: {
    [getReviewQuestionsData.pending]: (state, action) => {
      if (state.pageNumber === 1) {
        state.reviewQuestionsData.isLoading = true
      }
      state.reviewQuestionsData.error = null
      state.selectedReviewQuestions = null
    },
    [getReviewQuestionsData.fulfilled]: (state, action) => {
      state.attemptableData = action.payload?.data?.attemptable
      if (!action.meta.arg.page || action.meta.arg.page === 1) {
        state.questionAttempts = {
          data: action.payload.data.question_attempts.map((item, index) => ({
            ...item,
            questionIndex: index,
            reviewed: item.reviewed_at ? true : false,
          })),
          ...action.payload.data,
          pagination: action.payload.pagination,
        }

        if (action.meta.arg.questionId) {
          const findQuestionIndex =
            action.payload.data.question_attempts.findIndex(
              (attempt) =>
                attempt.id === parseInt(action.meta.arg.questionId) ||
                attempt.question_id === parseInt(action.meta.arg.questionId),
            )
          if (findQuestionIndex !== -1) {
            state.selectedReviewQuestions = {
              questionIndex: findQuestionIndex,
              ...action.payload.data.question_attempts[findQuestionIndex],
              reviewed: action.payload.data.question_attempts[findQuestionIndex]
                .reviewed_at
                ? true
                : false,
            }
          } else {
            state.invalidQuestionId = true
          }
        } else {
          state.selectedReviewQuestions = {
            questionIndex: 0,
            ...action.payload?.data?.question_attempts[0],
            reviewed: action.payload?.data?.question_attempts[0]?.reviewed_at
              ? true
              : false,
          }
        }
        state.reviewQuestionsData = {
          isLoading: false,
          data: action.payload.data.question_attempts.map((item, index) => ({
            ...item,
            questionIndex: index,
            reviewed: item.reviewed_at ? true : false,
          })),
          pagination: action.payload.pagination,
        }
      } else {
        state.reviewQuestionsData = {
          isLoading: false,
          data: [
            ...state.reviewQuestionsData.data,
            ...action.payload.data.question_attempts,
          ].map((item, index) => ({
            ...item,
            questionIndex: index,
            reviewed: item.reviewed_at ? true : false,
          })),
          pagination: action.payload.pagination,
        }
      }
      state.subtestSection = action.payload.data.all_sections.map(
        (section) => ({
          id: section.id,
          name: section.display_name,
        }),
      )

      const postIndex = findIndex(action.payload.data.all_sections, {
        id: action.meta.arg.sectionId,
      })
      if (action.meta.arg.sectionId) {
        if (postIndex !== -1) {
          state.selectedSection = {
            id: action.payload.data.all_sections[postIndex].id,
            name: action.payload.data.all_sections[postIndex].display_name,
          }
        } else {
          state.selectedSection = {
            id: action.payload.data.all_sections[0].id,
            name: action.payload.data.all_sections[0].display_name,
          }
        }
      } else {
        state.selectedSection = {
          id: action.payload.data.all_sections[0].id,
          name: action.payload.data.all_sections[0].display_name,
        }
      }
      state.selectedFilter = []
      state.reviewQuestionsData.isLoading = false
      let isMatchQuestion = state?.reviewQuestionsData?.data?.find((e) =>
        state?.lastViewedQuestionBySections?.find(
          (a) =>
            a?.id === e?.question_info?.section_id &&
            a?.questionIndex === e?.questionIndex &&
            a?.question_set_attempt_id === parseInt(action?.meta?.arg?.id),
        ),
      )
      if (isMatchQuestion) {
        state.selectedReviewQuestions = isMatchQuestion
      } else {
        const isMatchReviewExam = state?.lastViewedQuestionBySections?.find(
          (data) =>
            data?.question_set_attempt_id === parseInt(action?.meta?.arg?.id),
        )
        if (!isMatchReviewExam) {
          state.lastViewedQuestionBySections = []
        }
      }
    },
    [getReviewQuestionsData.rejected]: (state, action) => {
      state.reviewQuestionsData.isLoading = false
      state.reviewQuestionsData.error = action.payload?.data?.message
    },
    [setReviewQuestionsBookmark.pending]: (state) => {
      state.bookmarkLoading = true
    },
    [setReviewQuestionsBookmark.fulfilled]: (state, { payload, meta }) => {
      const postIndex = findIndex(state.reviewQuestionsData.data, {
        question_id: meta.arg.questionSetAttemptId,
      })
      if (payload.data.bookmark) {
        state.reviewQuestionsData.data[postIndex].user_bookmark =
          payload.data.bookmark

        state.selectedReviewQuestions.user_bookmark = payload.data.bookmark
      } else {
        state.reviewQuestionsData.data[postIndex].user_bookmark = null
        state.selectedReviewQuestions.user_bookmark = null
      }
      state.bookmarkLoading = false
    },

    [setReviewQuestionsBookmark.rejected]: (state, { payload }) => {
      state.bookmarkLoading = false
    },
    //
    [deleteBookmarkQuestion.pending]: (state) => {
      state.bookmarkLoading = true
    },
    [deleteBookmarkQuestion.fulfilled]: (state, { payload, meta }) => {
      const postIndex = findIndex(state.reviewQuestionsData.data, {
        question_id: meta.arg.typeId,
      })

      state.reviewQuestionsData.data[postIndex].user_bookmark = null
      state.selectedReviewQuestions.user_bookmark = null

      state.bookmarkLoading = false
    },

    [deleteBookmarkQuestion.rejected]: (state, { payload }) => {
      state.bookmarkLoading = false
    },
    [setReviewQuestionsRating.pending]: (state) => {
      state.disableRating = true
    },
    [setReviewQuestionsRating.fulfilled]: (state, { payload, meta }) => {
      const postIndex = findIndex(state.reviewQuestionsData.data, {
        question_id: meta.arg.id,
      })
      const questionIndex = findIndex(state.questionAttempts.data, {
        question_id: meta.arg.id,
      })
      state.reviewQuestionsData.data[postIndex].user_solution_rating =
        payload.data.solution_ratings
      state.questionAttempts.data[questionIndex].user_solution_rating =
        payload.data.solution_ratings
      state.selectedReviewQuestions.user_solution_rating =
        payload.data.solution_ratings
      state.disableRating = false
    },

    [setReviewQuestionsRating.rejected]: (state, { payload }) => {
      state.disableRating = false
    },
    [getReviewQuestionsBySection.pending]: (state, action) => {},
    [getReviewQuestionsBySection.fulfilled]: (state, action) => {
      state.reviewTypeQuestions = {
        data: action.payload.data.reasons.map((errorType) => {
          return {
            id: errorType.id,
            reason: errorType.reason,
            ...errorType,
          }
        }),
        num_unspecified: action.payload.data.num_unspecified,
      }
    },
    [getReviewQuestionsBySection.rejected]: (state, { payload }) => {},

    [setReviewQuestionsReason.pending]: (state, action) => {
      state.updateReasonLoader = true
    },
    [setReviewQuestionsReason.fulfilled]: (state, { payload }) => {
      state.updateReasonLoader = false
      const postIndex = findIndex(state.reviewQuestionsData.data, {
        id: payload.data.question_attempt_id,
      })
      const selectedIndex = findIndex(state.reviewTypeQuestions.data, {
        id: payload.data.error_log_reason_id,
      })
      if (selectedIndex !== -1) {
        const updatedTypeReason = {
          ...payload.data,
          reason: state.reviewTypeQuestions.data[selectedIndex].reason,
        }

        state.questionAttempts.data[postIndex].error_type = updatedTypeReason
        state.reviewQuestionsData.data[postIndex].error_type = updatedTypeReason
        state.selectedReviewQuestions = {
          ...state.selectedReviewQuestions,
          error_type: updatedTypeReason,
        }
      }
    },
    [setReviewQuestionsReason.rejected]: (state, { payload }) => {
      state.updateReasonLoader = false
    },
    [markQuestionReviewed.pending]: (state, { meta }) => {
      state.markQuestionReviewedId = meta.arg.id
    },
    [markQuestionReviewed.fulfilled]: (state, { payload, meta }) => {
      state.markQuestionReviewedId = null
      const questionIndex = findIndex(state.questionAttempts?.data, {
        id: payload?.data?.question_attempt_id,
      })

      const questionFilterIndex = findIndex(state.reviewQuestionsData?.data, {
        id: payload?.data?.question_attempt_id,
      })
      if (
        questionFilterIndex !== -1 &&
        state.reviewQuestionsData.data[questionFilterIndex]
      ) {
        state.reviewQuestionsData.data[questionFilterIndex].reviewed_at =
          payload?.data?.reviewed_at
      }
      if (questionIndex !== -1 && state.questionAttempts.data[questionIndex]) {
        state.questionAttempts.data[questionIndex].reviewed_at =
          payload?.data?.reviewed_at
      }
      if (state.selectedReviewQuestions) {
        state.selectedReviewQuestions.reviewed_at = payload?.data?.reviewed_at
      }
    },
    [markQuestionReviewed.rejected]: (state, { payload, meta }) => {
      state.markQuestionReviewedId = null
      const questionIndex = findIndex(state?.questionAttempts?.data, {
        id: meta.arg.id,
      })

      const questionFilterIndex = findIndex(state.reviewQuestionsData?.data, {
        id: payload?.data?.question_attempt_id,
      })
      if (
        questionFilterIndex !== -1 &&
        state.reviewQuestionsData?.data[questionFilterIndex] &&
        state.reviewQuestionsData?.data[questionFilterIndex]?.reviewed_at ===
          null
      ) {
        state.reviewQuestionsData.data[questionFilterIndex].reviewed = false
      }
      if (
        questionIndex !== -1 &&
        state.questionAttempts?.data[questionIndex] &&
        state.questionAttempts?.data[questionIndex]?.reviewed_at === null
      ) {
        state.questionAttempts.data[questionIndex].reviewed = false
      }
    },
  },
  reducers: {
    updateQuestionDiscussionNumber: (state, { payload }) => {
      const questionIndex = findIndex(state?.questionAttempts?.data, {
        question_id: payload.bookChapterId,
      })
      const reviewQuestionIndex = findIndex(state?.reviewQuestionsData?.data, {
        question_id: payload.bookChapterId,
      })

      if (questionIndex !== -1 && state.questionAttempts.data[questionIndex]) {
        state.questionAttempts.data[questionIndex].num_resource_questions =
          payload?.numResourceQuestions
      }
      if (
        reviewQuestionIndex !== -1 &&
        state?.reviewQuestionsData?.data[reviewQuestionIndex]
      ) {
        state.reviewQuestionsData.data[
          reviewQuestionIndex
        ].num_resource_questions = payload?.numResourceQuestions
      }
      state.selectedReviewQuestions.num_resource_questions =
        payload?.num_resource_questions
    },
    setFullscreen: (state, { payload }) => {
      state.isFullScreen = payload
    },
    updateReviewedQuestion: (state, { payload }) => {
      const questionIndex = findIndex(state?.questionAttempts?.data, {
        id: payload.id,
      })
      const questionFilterIndex = findIndex(state.reviewQuestionsData?.data, {
        id: payload?.data?.question_attempt_id,
      })
      if (
        questionFilterIndex !== -1 &&
        state.reviewQuestionsData.data[questionFilterIndex]
      ) {
        state.reviewQuestionsData.data[questionFilterIndex].reviewed = true
      }
      if (questionIndex !== -1 && state.questionAttempts.data[questionIndex]) {
        state.questionAttempts.data[questionIndex].reviewed = true
      }
      if (state.selectedReviewQuestions) {
        state.selectedReviewQuestions = {
          ...state.selectedReviewQuestions,
          reviewed: true,
        }
      }
    },
    setQuestionReviewPage: (state, { payload }) => {
      state.questionReviewPage = payload
    },
    clearShowNotesAndDiscussion: (state) => {
      state.showNotesAndDiscussion = {
        openTab: false,
        showNotes: false,
        showDiscussion: false,
      }
    },
    setInvalidQuestionId: (state, { payload }) => {
      state.invalidQuestionId = payload
    },
    setshowNotesAndDiscussion: (state, { payload }) => {
      if (payload.openTab) {
        state.showNotesAndDiscussion.openTab = payload.openTab
      }
      state.showNotesAndDiscussion.showNotes = payload.showNotes
      state.showNotesAndDiscussion.showDiscussion = payload.showDiscussion
    },
    toggleUcatView: (state, { payload }) => {
      state.ucatView = payload
    },
    setPageNumber: (state, { payload }) => {
      state.pageNumber = state.pageNumber + 1
    },
    updateSelectedReviewQuestions: (state, { payload }) => {
      state.selectedReviewQuestions = payload
      let checkSectionExist = state?.lastViewedQuestionBySections?.findIndex(
        (e) => e?.id === payload?.question_info?.section_id,
      )
      if (checkSectionExist !== -1) {
        state.lastViewedQuestionBySections[checkSectionExist] = {
          ...state.lastViewedQuestionBySections[checkSectionExist],
          questionIndex: payload?.questionIndex,
        }
      } else {
        state.lastViewedQuestionBySections = [
          {
            questionIndex: payload?.questionIndex,
            id: payload?.question_info?.section_id,
            question_set_attempt_id: payload?.question_set_attempt_id,
          },
          ...state.lastViewedQuestionBySections,
        ]
      }
    },
    toggleSidebar: (state, { payload }) => {
      state.showSidebar = payload
    },
    setSubtestSection: (state, { payload }) => {
      const { value, onSuccess } = payload
      state.selectedSection = value
      onSuccess && onSuccess()
    },
    setFilter: (state, { payload }) => {
      state.selectedFilter = payload
      let valAll = payload?.length === 0
      let valOverTime =
        payload?.length > 0 ? payload?.find((e) => e?.id === 1) : null
      let valFlagged =
        payload?.length > 0 ? payload?.find((e) => e?.id === 3) : null
      let valIncorrect =
        payload?.length > 0 ? payload?.find((e) => e?.id === 2) : null
      let valNotReviewed =
        payload?.length > 0 ? payload?.find((e) => e?.id === 4) : null
      let temparr = state.questionAttempts.data
      if (valAll) {
        temparr = state.questionAttempts.data
      }
      if (valIncorrect) {
        temparr = temparr?.filter((data) => data.correctness < 1)
      }
      if (valOverTime) {
        temparr = temparr?.filter(
          (data) => data?.student_time > data?.average_time,
        )
      }
      if (valFlagged) {
        temparr = temparr?.filter((data) => data?.flagged)
      }
      if (valNotReviewed) {
        temparr = temparr?.filter((data) => !data?.reviewed_at)
      }
      state.reviewQuestionsData.data = temparr

      let isMatchQuestion =
        temparr?.length > 0 &&
        temparr?.find((e) =>
          state?.lastViewedQuestionBySections?.find(
            (a) =>
              a?.id === e?.question_info?.section_id &&
              a?.questionIndex === e?.questionIndex,
          ),
        )
      if (isMatchQuestion) {
        state.selectedReviewQuestions = isMatchQuestion
      } else {
        state.selectedReviewQuestions = temparr?.length > 0 && {
          questionIndex: state.reviewQuestionsData.data[0].questionIndex,
          ...state.reviewQuestionsData.data[0],
        }
      }
    },
    resetStoreSectionByQuestions: (state) => {
      state.lastViewedQuestionBySections = []
    },
    addNewCreatedReviewTypeQuestion: (state, { payload }) => {
      state.reviewTypeQuestions.data.push(payload)
    },
    clearFilter: (state) => {
      state.selectedFilter = []
    },
  },
})
export const {
  updateQuestionDiscussionNumber,
  clearFilter,
  addNewCreatedReviewTypeQuestion,
  resetStoreSectionByQuestions,
  setFullscreen,
  updateReviewedQuestion,
  setQuestionReviewPage,
  clearShowNotesAndDiscussion,
  setInvalidQuestionId,
  setshowNotesAndDiscussion,
  toggleUcatView,
  updateSelectedReviewQuestions,
  toggleSidebar,
  setSubtestSection,
  setFilter,
  setPageNumber,
} = reviewQuestionsSlice.actions
export default reviewQuestionsSlice.reducer
