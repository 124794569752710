import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'

export const getTutorData = createAsyncThunk(
  'tutoring/getTutorData',
  async ({ type }, { rejectWithValue }) => {
    try {
      const response = await await axiosInterceptors.get(`tutoring`, {
        params: { type },
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const requestTutorAssignment = createAsyncThunk(
  'tutoring/requestTutorAssignment',
  async ({ type }, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptors.post(
        `tutoring/request-expedition`,
        { type },
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const tutoringSlice = createSlice({
  name: 'tutoring',
  initialState: {
    tutorData: { isLoading: true, data: null },
    tutorError: null,
    isRequestTutor: false,
    requestLoader: false,
  },
  extraReducers: {
    [getTutorData.pending]: (state, { payload }) => {
      state.tutorData.isLoading = true
      state.tutorData.data = null
      state.tutorError = null
    },
    [getTutorData.fulfilled]: (state, { payload }) => {
      state.tutorData.isLoading = false
      state.tutorData.data = payload?.data
    },
    [getTutorData.rejected]: (state, { payload }) => {
      state.tutorError = payload
      state.tutorData.isLoading = false
    },
    [requestTutorAssignment.pending]: (state, { payload }) => {
      state.requestLoader = true
    },
    [requestTutorAssignment.fulfilled]: (state, { payload }) => {
      state.requestLoader = false
      state.isRequestTutor = false

      if (state.tutorData.data.expedition === null) {
        state.tutorData.data.expedition = payload?.data?.expedition
      }
    },
    [requestTutorAssignment.rejected]: (state, { payload }) => {
      state.requestLoader = payload
      state.requestLoader = false
      state.isRequestTutor = false
      toast.error(payload?.message || 'Something went wrong', {
        duration: 3000,
        style: { maxWidth: 'auto' },
      })
    },
  },
  reducers: {
    setRequestTutor: (state, { payload }) => {
      state.isRequestTutor = payload
    },
  },
})

export const { setRequestTutor } = tutoringSlice.actions

export default tutoringSlice.reducer
