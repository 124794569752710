const ChevronRight = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.313 11.02a.5.5 0 010-.707L8.626 8 6.313 5.687a.5.5 0 01.707-.707l2.667 2.666a.5.5 0 010 .708L7.02 11.02a.5.5 0 01-.707 0z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default ChevronRight
