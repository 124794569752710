import React, { useRef, useEffect } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Settings } from 'components/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAutoQuality,
  setChangedDuration,
  setIsQualityOpen,
  setSelectedVideoMedia,
} from 'redux/learn/resourceSlice'

const VideoQualityList = ({ playerRef, mediaArray }) => {
  const dispatch = useDispatch()
  const { isQualityOpen, selectedVideoMedia, playedSeconds, autoQuality } =
    useSelector((state) => ({
      isQualityOpen: state.resource.isQualityOpen,
      selectedVideoMedia: state.resource.selectedVideoMedia,
      playedSeconds: state.resource.playedSeconds,
      autoQuality: state.resource.autoQuality,
    }))

  const buttonRef = useRef(null)

  const popoverRef = useRef(null)

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        dispatch(setIsQualityOpen(false))
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [dispatch])

  const selectedQuality = mediaArray?.find(
    (data) => data?.media?.id === selectedVideoMedia?.id,
  )

  return (
    <div className="relative">
      <Popover className="h-full flex items-center">
        {({ open }) => (
          <>
            <Popover.Button>
              <span
                ref={buttonRef}
                className="relative flex items-center h-full"
                onClick={() => {
                  dispatch(setIsQualityOpen(false))
                }}
              >
                <Settings className="h-5 w-5 text-white" />
              </span>
            </Popover.Button>
            <Transition show={isQualityOpen}>
              <Popover.Panel
                ref={popoverRef}
                className="absolute z-10 bg-white dark:bg-dark-600 shadow-lg rounded-md py-2"
                style={{
                  minWidth: '7rem',
                  bottom: '1.8rem',
                  left: '-5.7rem',
                }}
              >
                <div>
                  {mediaArray?.length > 1 && (
                    <button
                      className={`w-full px-2 py-1 flex gap-1 items-center mr-6 dark:text-white ${
                        autoQuality ? 'bg-gray-300 dark:bg-dark-300 ' : ''
                      }`}
                      onClick={() => {
                        dispatch(setAutoQuality(true))
                        dispatch(setChangedDuration(playedSeconds))
                        setTimeout(() => {
                          dispatch(setIsQualityOpen(false))
                        }, 500)
                      }}
                    >
                      <span className="text-sm">Auto</span>
                      {autoQuality && selectedQuality?.quality && (
                        <span className="text-xs">
                          ({selectedQuality?.quality})
                        </span>
                      )}
                    </button>
                  )}

                  {mediaArray?.map((data, index) => (
                    <button
                      key={index}
                      className={`dark:text-white w-full px-2 py-1 flex gap-1 items-center mr-6 ${
                        !autoQuality &&
                        data?.media?.id === selectedVideoMedia?.id
                          ? 'bg-gray-300 dark:bg-dark-300'
                          : ''
                      }`}
                      onClick={() => {
                        dispatch(setAutoQuality(false))
                        dispatch(setSelectedVideoMedia(data?.media))
                        dispatch(setChangedDuration(playedSeconds))

                        setTimeout(() => {
                          dispatch(setIsQualityOpen(false))
                        }, 500)
                      }}
                    >
                      <span className="text-sm">{data.quality}</span>
                      {data?.optionalQuality ? (
                        <span className="text-xs font-semibold text-gray-600 dark:text-dark-100 -mt-3">
                          {data.optionalQuality}
                        </span>
                      ) : (
                        <></>
                      )}
                    </button>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default VideoQualityList
