import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getClassRecordings = createAsyncThunk(
  'workshop/getClassRecordings',
  async ({ type }, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptors.get(`event/class-recordings`, {
        params: { type: type || 'classes' },
      })
      return response
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

const classRecordingsSlice = createSlice({
  name: 'classRecordings',
  initialState: {
    classRecordingsData: {
      isLoading: false,
      data: [],
      isError: false,
      errorData: null,
      isLocked: false,
      errorMessage: null,
    },
    playingVideoId: null,
  },
  extraReducers: {
    [getClassRecordings.pending]: (state, { payload }) => {
      state.classRecordingsData.isLoading = true
      state.classRecordingsData.errorData = null
      if (state.classRecordingsData.isLocked) {
        state.classRecordingsData.isLocked = false
      }
    },
    [getClassRecordings.fulfilled]: (state, { payload }) => {
      state.classRecordingsData = {
        isLoading: false,
        data: payload.data,
        isLocked: false,
      }
    },
    [getClassRecordings.rejected]: (state, { payload }) => {
      if (payload?.status === 404) {
        state.classRecordingsData.isError = true
        state.classRecordingsData.errorMessage =
          payload?.data?.message ||
          'Something went wrong, please try again shortly'
      }
      if (payload?.status === 403) {
        state.classRecordingsData.errorData = payload?.data?.data
        state.classRecordingsData.isLocked = true
      }
      state.classRecordingsData.isLoading = false
    },
  },
  reducers: {},
})

export default classRecordingsSlice.reducer
