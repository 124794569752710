const PlayingIcon = ({ className }) => (
  <svg
    width="9"
    height="12"
    viewBox="0 0 9 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
  >
    <path
      d="M7.91798 6.27722L0.851985 10.9879C0.801788 11.0213 0.743471 11.0404 0.683245 11.0433C0.623019 11.0462 0.563139 11.0327 0.509984 11.0042C0.456828 10.9758 0.412387 10.9334 0.381393 10.8817C0.3504 10.83 0.334016 10.7708 0.333984 10.7105V1.28922C0.334016 1.22892 0.3504 1.16976 0.381393 1.11804C0.412387 1.06633 0.456828 1.02398 0.509984 0.995522C0.563139 0.967063 0.623019 0.953554 0.683245 0.956436C0.743471 0.959317 0.801788 0.978479 0.851985 1.01188L7.91798 5.72255C7.96364 5.75299 8.00107 5.79423 8.02696 5.84261C8.05285 5.89099 8.06639 5.94501 8.06639 5.99988C8.06639 6.05475 8.05285 6.10877 8.02696 6.15715C8.00107 6.20553 7.96364 6.24677 7.91798 6.27722V6.27722Z"
      fill="currentColor"
    />
  </svg>
)

export default PlayingIcon
