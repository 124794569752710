const CommunityArrow = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={`h-4 w-4 ${className}`}
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.28709 2.30691C7.19866 2.27024 7.10169 2.25 7 2.25C6.89831 2.25 6.80134 2.27024 6.71291 2.30691C6.62445 2.34351 6.54158 2.39776 6.46967 2.46967L2.46967 6.46967C2.17678 6.76256 2.17678 7.23744 2.46967 7.53033C2.76256 7.82322 3.23744 7.82322 3.53033 7.53033L6.25 4.81066V11.707C6.25 12.1212 6.58579 12.457 7 12.457C7.41421 12.457 7.75 12.1212 7.75 11.707V4.81066L10.4697 7.53033C10.7626 7.82322 11.2374 7.82322 11.5303 7.53033C11.8232 7.23744 11.8232 6.76256 11.5303 6.46967L7.53033 2.46967C7.45842 2.39776 7.37555 2.34351 7.28709 2.30691ZM16.4697 5.46967C16.7626 5.17678 17.2374 5.17678 17.5303 5.46967L21.5303 9.46967C21.6676 9.60696 21.7406 9.78424 21.7491 9.96402C21.7497 9.97601 21.75 9.988 21.75 10C21.75 10.1017 21.7298 10.1987 21.6931 10.2871C21.6565 10.3755 21.6022 10.4584 21.5303 10.5303L17.5303 14.5303C17.2374 14.8232 16.7626 14.8232 16.4697 14.5303C16.1768 14.2374 16.1768 13.7626 16.4697 13.4697L19.1893 10.75H18C15.2815 10.75 12.6744 11.8299 10.7522 13.7522C8.82991 15.6744 7.75 18.2815 7.75 21C7.75 21.4142 7.41421 21.75 7 21.75C6.58579 21.75 6.25 21.4142 6.25 21C6.25 17.8837 7.48794 14.895 9.6915 12.6915C11.895 10.4879 14.8837 9.25 18 9.25H19.1893L16.4697 6.53033C16.1768 6.23744 16.1768 5.76256 16.4697 5.46967Z"
      fill="currentColor"
    />
  </svg>
)

export default CommunityArrow
