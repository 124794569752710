import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'
import toast from 'react-hot-toast'
export const startSyllogisms = createAsyncThunk(
  'syllogisms/startSyllogisms',
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `trainer/syllogisms/start-attempt`,
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const uploadDiagram = createAsyncThunk(
  'syllogisms/uploadDiagram',
  async ({ diagramImage, id, type, onSuccess }, { rejectWithValue }) => {
    const formData = new FormData()
    formData.append('diagram_image', diagramImage)
    formData.append('type', type)
    try {
      const res = await axiosInterceptors.post(
        `/trainer/syllogisms/${id}/diagram-upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const submitSyllogismsAns = createAsyncThunk(
  'syllogisms/submitSyllogismsAns',
  async (
    { attemptId, questionAttempts, timeTaken, times, onSuccess, onError },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.post(
        `trainer/syllogisms/${attemptId}/submit-attempt`,
        {
          responses: questionAttempts,
          time_taken: timeTaken,
          times,
        },
        { headers: { notToRedirect404: true } },
      )
      onSuccess && onSuccess()
      return res
    } catch (error) {
      onError && onError()
      throw rejectWithValue(error.response.data)
    }
  },
)
export const getViewDiagrams = createAsyncThunk(
  'syllogisms/getViewDiagrams',
  async ({ syllogismsId, page }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `/trainer/syllogisms/${syllogismsId}/diagrams`,
        {
          params: {
            page,
          },
        },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const upvoteSyllogismsDiagram = createAsyncThunk(
  'syllogisms/upvoteSyllogismsDiagram',
  async ({ diagramId, state }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(
        `/trainer/syllogisms/diagram/${diagramId}/set-upvote-state?state=${state}`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const reportSyllogismsIssue = createAsyncThunk(
  'syllogisms/reportSyllogismsIssue',
  async (data, { rejectWithValue }) => {
    const { id, issue, question_number, onSuccess, onError } = data

    try {
      const res = await axiosInterceptors.post(
        `trainer/syllogisms/${id}/report-issue`,
        { issue, question_number },
      )
      onSuccess && onSuccess(res)
      return res
    } catch (err) {
      const errorType = rejectWithValue(err?.response?.data)
      onError && onError(errorType?.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const studentDiagramReport = createAsyncThunk(
  'syllogisms/studentDiagramReport',
  async (data, { rejectWithValue }) => {
    const { reason, type_id, onSuccess, onError } = data

    try {
      const res = await axiosInterceptors.post(`content-report`, {
        reason,
        type: 'user_syllogism_diagram',
        type_id,
      })
      onSuccess && onSuccess(res)
      return res
    } catch (err) {
      const errorType = rejectWithValue(err?.response?.data)
      onError && onError(errorType?.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

const syllogismsSlice = createSlice({
  name: 'syllogisms',
  initialState: {
    syllogismsData: { isRejected: false },
    selectedQue: {},
    ansOfSyllogisms: [],
    curQuestion: 0,
    syllogismsResult: [],
    scoreEvolutionGraphData: [],
    userLevelInformation: [],
    displaySideQuestion: false,
    disableSyllogismsSideBar: false,
    syllogismsLoading: false,
    syllogismsExamTime: null,
    timeTaken: 0,
    isShowProvideDiagram: false,
    isShowViewDiagrams: false,
    viewDiagramsData: { isLoading: false, data: [], pagination: null },
    studentDiagramPage: 1,
    diagramPageLoader: false,
    syllogismsSubmitLoading: false,
    diagramImage: [],
    diagramUploadLoading: false,
    upvoteLoading: false,
    extraSyllogismsTime: 0,
    timeOfSyllogismsQuestion: [],
    reportQuestionId: null,
    reportLoader: false,
    reportDiagramId: null,
  },
  extraReducers: {
    [studentDiagramReport.pending]: (state) => {
      state.reportLoader = true
    },
    [studentDiagramReport.fulfilled]: (state) => {
      state.reportLoader = false
      state.reportDiagramId = null
    },
    [studentDiagramReport.rejected]: (state) => {
      state.reportLoader = false
    },
    //
    [reportSyllogismsIssue.pending]: (state) => {
      state.reportLoader = true
    },
    [reportSyllogismsIssue.fulfilled]: (state) => {
      state.reportLoader = false
      state.reportQuestionId = null
    },
    [reportSyllogismsIssue.rejected]: (state) => {
      state.reportLoader = false
    },
    [upvoteSyllogismsDiagram.pending]: (state) => {
      state.upvoteLoading = true
    },
    [upvoteSyllogismsDiagram.fulfilled]: (state, { payload }) => {
      const upvoteUpdateIndex =
        state.viewDiagramsData.data.student_diagrams.findIndex(
          (data) => data.id === payload.data.diagram_id,
        )
      state.upvoteLoading = false
      // if (upvoteUpdateIndex) {
      state.viewDiagramsData.data.student_diagrams[
        upvoteUpdateIndex
      ].num_upvotes = payload.data.num_upvotes
      state.viewDiagramsData.data.student_diagrams[
        upvoteUpdateIndex
      ].user_upvoted = payload.data.user_upvoted
      // }
    },
    [upvoteSyllogismsDiagram.rejected]: (state) => {
      state.upvoteLoading = false
    },
    [getViewDiagrams.pending]: (state, { meta }) => {
      if (meta.arg.page === 1) {
        state.viewDiagramsData.isLoading = true
      } else {
        state.diagramPageLoader = true
      }
    },
    [getViewDiagrams.fulfilled]: (state, { payload, meta }) => {
      state.viewDiagramsData.isLoading = false
      state.diagramPageLoader = false
      if (meta.arg.page === 1) {
        state.viewDiagramsData.data = payload.data
      } else {
        state.viewDiagramsData.data.student_diagrams = [
          ...state.viewDiagramsData?.data?.student_diagrams,
          ...payload?.data?.student_diagrams,
        ]
      }
      state.viewDiagramsData.pagination = payload.pagination
    },
    [getViewDiagrams.rejected]: (state) => {
      state.viewDiagramsData.isLoading = false
    },
    [startSyllogisms.pending]: (state, action) => {
      state.syllogismsLoading = true
      state.syllogismsData.isRejected = false
    },
    [startSyllogisms.fulfilled]: (state, { payload }) => {
      state.syllogismsData = payload.data
      state.selectedQue = payload.data.attempt.statements[0]
      state.ansOfSyllogisms = payload.data.attempt.statements.map((data) => {
        return null
      })
      state.syllogismsExamTime = payload.data.attempt.recommended_seconds || 30
      state.syllogismsLoading = false
    },
    [startSyllogisms.rejected]: (state, action) => {
      state.syllogismsData.isRejected = true
      state.syllogismsLoading = false
    },
    [submitSyllogismsAns.pending]: (state, action) => {
      state.syllogismsSubmitLoading = true
    },
    [uploadDiagram.pending]: (state) => {
      state.diagramUploadLoading = true
    },
    [uploadDiagram.fulfilled]: (state) => {
      toast.success('Diagram uploaded successfully', {
        duration: 4000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '350px',
        },
      })
      state.diagramImage = []
      state.diagramUploadLoading = false
    },
    [uploadDiagram.rejected]: (state) => {
      state.diagramUploadLoading = false
    },
    [submitSyllogismsAns.fulfilled]: (state, { payload }) => {
      state.syllogismsResult = payload.data
      // if (payload.data.badge_awarded) {
      //   toast(
      //     <>
      //       <div className="flex flex-col flex-wrap items-center justify-center">
      //         <img
      //           src={payload.data.badge_awarded.default_image_url}
      //           alt="badge"
      //         />
      //         <p className="text-base text-center text-gray-900 dark:text-white">
      //           Congratulations!, You have earned{' '}
      //           <span className="font-semibold">
      //             {payload.data.badge_awarded.name}
      //           </span>{' '}
      //           Badge.
      //         </p>
      //       </div>
      //     </>,
      //     {
      //       duration: 4000,
      //       position: 'top-center',
      //       style: {
      //         backdropFilter: 'blur(4px)',
      //         marginTop: '20px',
      //         width: '400px',
      //       },
      //     },
      //   )
      // }
      state.syllogismsScore = payload.data.score
      state.scoreEvolutionGraphData = payload.data.score_evolution_graph
      state.userLevelInformation = payload.data.user_level_information
      // state.correctAnsCount = payload.data.questions.filter(
      //   (value) => value.is_correct,
      // ).length
      state.syllogismsExamTime = null
      state.disableSyllogismsSideBar = false
      state.syllogismsSubmitLoading = false
    },
    [submitSyllogismsAns.rejected]: (state, action) => {
      state.syllogismsSubmitLoading = false
      state.syllogismsData.isRejected = true
    },
  },
  reducers: {
    setReportDiagramId: (state, { payload }) => {
      state.reportDiagramId = payload
    },
    setReportQuestionId: (state, { payload }) => {
      state.reportQuestionId = payload
    },
    setStudentDiagramPage: (state, { payload }) => {
      state.studentDiagramPage = payload
    },
    setSelectedQue: (state, { payload }) => {
      state.selectedQue = payload
    },
    setIsRejected: (state, { payload }) => {
      state.syllogismsData.isRejected = payload
    },
    updateCountDownTime: (state) => {
      if (state.countDownTime === 0) {
        state.syllogismsLoading = false
      } else {
        state.countDownTime = state.countDownTime - 1
      }
    },
    toggleProvideDiagramModal: (state) => {
      state.isShowProvideDiagram = !state.isShowProvideDiagram
    },
    toggleViewDiagramModal: (state, { payload }) => {
      state.isShowViewDiagrams = payload
    },
    setCurQuestion: (state, { payload }) => {
      state.curQuestion += 1
    },
    setSyllogismsExamTime: (state, { payload }) => {
      state.syllogismsExamTime -= 1
    },

    setExtraSyllogismsTime: (state, { payload }) => {
      state.extraSyllogismsTime += 1
    },
    setDisplaySideQuestion: (state, { payload }) => {
      state.displaySideQuestion = payload
    },
    setTryAgainSyllogisms: (state, { payload }) => {
      state.syllogismsData = {}
      state.ansOfSyllogisms = []
      state.curQuestion = 0
      state.syllogismsResult = []
      state.displaySideQuestion = false
      state.scoreEvolutionGraphData = []
      state.userLevelInformation = []
      state.timeTaken = 0
      state.extraSyllogismsTime = 0
      state.syllogismsData.isRejected = false
      state.isShowViewDiagrams = false
      state.studentDiagramPage = 1
      state.timeOfSyllogismsQuestion = []
    },
    setDisableSyllogismsSideBar: (state, { payload }) => {
      state.disableSyllogismsSideBar = payload
    },
    updateTimeTaken: (state, { payload }) => {
      state.timeTaken += 1
    },
    resetTimeTaken: (state, { payload }) => {
      state.timeTaken = 0
    },
    setAnswerOfSyllogisms: (state, { payload }) => {
      state.ansOfSyllogisms[state.curQuestion] = payload
    },
    setTimeOfSyllogisms: (state, { payload }) => {
      state.timeOfSyllogismsQuestion[state.curQuestion] = payload
    },
    setDiagramImage: (state, { payload }) => {
      state.diagramImage = payload
    },
    skipCurQuestion: (state, { payload }) => {
      const postIndex = findIndex(state.ansOfSyllogisms, {
        questionId: payload.questionId,
      })
      state.ansOfSyllogisms[postIndex].response = ''
      state.ansOfSyllogisms[postIndex].time = payload.time
      state.curQuestion += 1
    },
  },
})

export const {
  setReportDiagramId,
  setReportQuestionId,
  setStudentDiagramPage,
  setExtraSyllogismsTime,
  setSelectedQue,
  setSyllogismsExamTime,
  setCurQuestion,
  setAnswerOfSyllogisms,
  setDisplaySideQuestion,
  setTryAgainSyllogisms,
  setDisableSyllogismsSideBar,
  skipCurQuestion,
  setIsRejected,
  updateTimeTaken,
  toggleProvideDiagramModal,
  resetTimeTaken,
  updateCountDownTime,
  setDiagramImage,
  toggleViewDiagramModal,
  setTimeOfSyllogisms,
} = syllogismsSlice.actions

export default syllogismsSlice.reducer
