const Calendar = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    // stroke="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.667 1.5a.5.5 0 01.5.5v.833H12c1.012 0 1.833.821 1.833 1.834v8c0 1.012-.82 1.833-1.833 1.833H4a1.833 1.833 0 01-1.833-1.833v-8c0-1.013.82-1.834 1.833-1.834h.833V2a.5.5 0 011 0v.833h4.334V2a.5.5 0 01.5-.5zm2.166 3.167v2.166H3.167V4.667c0-.46.373-.834.833-.834h.833v.834a.5.5 0 001 0v-.834h4.334v.834a.5.5 0 101 0v-.834H12c.46 0 .833.373.833.834zm0 3.166H3.167v4.834c0 .46.373.833.833.833h8c.46 0 .833-.373.833-.833V7.833zM5.333 9.5a.5.5 0 00-.5.5v1.333a.5.5 0 00.5.5h1.334a.5.5 0 00.5-.5V10a.5.5 0 00-.5-.5H5.333zm.5 1.333V10.5h.334v.333h-.334z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default Calendar
