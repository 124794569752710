const Play = ({ className }) => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={`h-3 w-3 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.422603 0.230271C0.58073 0.14191 0.774282 0.145983 0.928553 0.240919L9.59522 5.57425C9.74309 5.66525 9.83317 5.82645 9.83317 6.00008C9.83317 6.17371 9.74309 6.33491 9.59522 6.42591L0.928553 11.7592C0.774282 11.8542 0.58073 11.8583 0.422603 11.7699C0.264475 11.6815 0.166504 11.5146 0.166504 11.3334V0.666748C0.166504 0.485607 0.264475 0.318632 0.422603 0.230271ZM1.1665 1.56153V10.4386L8.37915 6.00008L1.1665 1.56153Z"
      fill="currentColor"
    />
  </svg>
)

export default Play
