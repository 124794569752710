const CheckedList = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 19 17"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.03033 2.03033C6.32322 1.73744 6.32322 1.26256 6.03033 0.96967C5.73744 0.676777 5.26256 0.676777 4.96967 0.96967L3 2.93934L2.03033 1.96967C1.73744 1.67678 1.26256 1.67678 0.96967 1.96967C0.676777 2.26256 0.676777 2.73744 0.96967 3.03033L2.46967 4.53033C2.76256 4.82322 3.23744 4.82322 3.53033 4.53033L6.03033 2.03033ZM6.03033 8.03033C6.32322 7.73744 6.32322 7.26256 6.03033 6.96967C5.73744 6.67678 5.26256 6.67678 4.96967 6.96967L3 8.93934L2.03033 7.96967C1.73744 7.67678 1.26256 7.67678 0.96967 7.96967C0.676777 8.26256 0.676777 8.73744 0.96967 9.03033L2.46967 10.5303C2.76256 10.8232 3.23744 10.8232 3.53033 10.5303L6.03033 8.03033ZM6.03033 12.9697C6.32322 13.2626 6.32322 13.7374 6.03033 14.0303L3.53033 16.5303C3.23744 16.8232 2.76256 16.8232 2.46967 16.5303L0.96967 15.0303C0.676777 14.7374 0.676777 14.2626 0.96967 13.9697C1.26256 13.6768 1.73744 13.6768 2.03033 13.9697L3 14.9393L4.96967 12.9697C5.26256 12.6768 5.73744 12.6768 6.03033 12.9697ZM9 2.25C8.58579 2.25 8.25 2.58579 8.25 3C8.25 3.41421 8.58579 3.75 9 3.75H18C18.4142 3.75 18.75 3.41421 18.75 3C18.75 2.58579 18.4142 2.25 18 2.25H9ZM8.25 9C8.25 8.58579 8.58579 8.25 9 8.25H18C18.4142 8.25 18.75 8.58579 18.75 9C18.75 9.41421 18.4142 9.75 18 9.75H9C8.58579 9.75 8.25 9.41421 8.25 9ZM9 14.25C8.58579 14.25 8.25 14.5858 8.25 15C8.25 15.4142 8.58579 15.75 9 15.75H18C18.4142 15.75 18.75 15.4142 18.75 15C18.75 14.5858 18.4142 14.25 18 14.25H9Z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default CheckedList
