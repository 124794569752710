const List = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.833 4a.5.5 0 10-1 0v.007a.5.5 0 001 0V4zM6 3.5a.5.5 0 000 1h7.333a.5.5 0 100-1H6zm0 4a.5.5 0 000 1h7.333a.5.5 0 100-1H6zM5.5 12a.5.5 0 01.5-.5h7.333a.5.5 0 110 1H6a.5.5 0 01-.5-.5zM3.333 7.5a.5.5 0 01.5.5v.007a.5.5 0 11-1 0V8a.5.5 0 01.5-.5zm.5 4.5a.5.5 0 00-1 0v.007a.5.5 0 001 0V12z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default List
