const Graph = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="current"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="current"
      fillRule="evenodd"
      d="M7.04 1.672a1.1 1.1 0 00-.486-.026 6.5 6.5 0 107.8 7.8.5.5 0 00.013-.113A1.167 1.167 0 0013.2 8.167H8.667a.833.833 0 01-.834-.834V2.667a1.1 1.1 0 00-.794-.995zm-2.807 2.32a5.5 5.5 0 012.509-1.363.1.1 0 01.091.075v4.63a1.833 1.833 0 001.834 1.833H13.2a.166.166 0 01.162.127 5.5 5.5 0 11-9.129-5.303zm5.933-2.13a.5.5 0 00-.666.471v3A1.167 1.167 0 0010.667 6.5h3a.5.5 0 00.471-.666 6.5 6.5 0 00-3.972-3.972zm.334 3.471V3.09a5.499 5.499 0 012.41 2.41h-2.243a.167.167 0 01-.167-.167z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default Graph
