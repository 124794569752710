import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'
// import toast from 'react-hot-toast'
export const startSpeedReading = createAsyncThunk(
  'speedReading/startSpeedReading',
  async (data) => {
    return await axiosInterceptors.post(`trainer/speed-reading/start-attempt`)
  },
)

export const submitSpeedReadingAns = createAsyncThunk(
  'speedReading/submitSpeedReadingAns',
  async (
    { id, ucatViewUsed, questionAttempts, onSuccess, onError },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.post(
        `trainer/speed-reading/${id}/submit-attempt`,
        {
          speed_reading_trainer_attempt_id: id,
          ucat_view_used: ucatViewUsed,
          question_attempts: questionAttempts.map((data) => ({
            question_id: data.questionId,
            response: data.response,
            time_taken: data.time ? data.time : 0,
          })),
        },
        { headers: { notToRedirect404: true } },
      )
      onSuccess && onSuccess()
      return res
    } catch (error) {
      onError && onError()
      throw rejectWithValue(error.response.data)
    }
  },
)

const speedReadingSlice = createSlice({
  name: 'speedReading',
  initialState: {
    speedReadingData: { isRejected: false },
    selectedQue: {},
    ansOfSpeedReading: [],
    curQuestion: 0,
    speedReadingResult: [],
    scoreEvolutionGraphData: [],
    userLevelInformation: [],
    speedReadingPassage: null,
    displaySideQuestion: false,
    disableSpeedReadingSideBar: false,
    speedReadingLoading: false,
    timeTaken: 0,
    countDownTime: 3,
    selectedQuestionId: null,
  },
  extraReducers: {
    [startSpeedReading.pending]: (state, action) => {
      state.speedReadingLoading = true
      state.speedReadingData.isRejected = false
    },
    [startSpeedReading.fulfilled]: (state, { payload }) => {
      state.speedReadingData = payload.data
      state.selectedQue = payload.data.attempt.passage.questions[0]
      const passage = payload.data.attempt.passage.passage
        .split('\n')
        .map((paragraph) => paragraph.split(' '))
      state.speedReadingPassage = passage
      let count = 0
      passage.forEach((paragraph) => paragraph.forEach((word) => (count += 1)))
      state.wordCount = count + 1
      state.simultaneousWords = payload.data.attempt.words_to_display

      state.ansOfSpeedReading = payload.data.attempt.passage.questions.map(
        (question) => ({
          questionId: question.id,
          response: '',
          time: 0,
        }),
      )
    },
    [startSpeedReading.rejected]: (state, action) => {
      state.speedReadingData.isRejected = true
      state.speedReadingLoading = false
    },
    [submitSpeedReadingAns.pending]: (state, action) => {
      state.submitAttemptLoader = true
    },
    [submitSpeedReadingAns.fulfilled]: (state, { payload }) => {
      state.submitAttemptLoader = false
      state.badgeAwarded = payload.data.badge_awarded
      state.speedReadingResult = payload.data.question_attempts.map((data) => {
        return {
          ...data,
          options:
            state.speedReadingData.attempt.passage.questions.filter(
              (question) => question.id === data.question_id,
            ).length > 0 &&
            state.speedReadingData.attempt.passage.questions.filter(
              (question) => question.id === data.question_id,
            )[0],
        }
      })
      state.speedReadingScore = payload.data
      state.disableSpeedReadingSideBar = false
      state.scoreEvolutionGraphData = payload.data.score_evolution_graph
      state.userLevelInformation = payload.data.user_level_information
      state.correctAnsCount = payload.data.question_attempts.filter(
        (value) => value.is_correct,
      ).length
    },
    [submitSpeedReadingAns.rejected]: (state, action) => {
      state.submitAttemptLoader = false
      state.speedReadingData.isRejected = true
      state.displaySideQuestion = false
    },
  },
  reducers: {
    setSelectedQue: (state, { payload }) => {
      state.speedReadingData = payload
    },
    setIsRejected: (state, { payload }) => {
      state.speedReadingData.isRejected = payload
    },
    setSelectedQuestionId: (state, { payload }) => {
      state.selectedQuestionId = payload
    },
    updateCountDownTime: (state) => {
      if (state.countDownTime === 0) {
        state.speedReadingLoading = false
      } else {
        state.countDownTime = state.countDownTime - 1
      }
    },
    setCurQuestion: (state, { payload }) => {
      state.curQuestion += 1
      const postIndex = findIndex(state.ansOfSpeedReading, {
        questionId: payload.questionId,
      })
      state.ansOfSpeedReading[postIndex].time = payload.time
    },

    setDisplaySideQuestion: (state, { payload }) => {
      state.displaySideQuestion = payload
    },
    setTryAgainSpeedTrainer: (state, { payload }) => {
      state.speedReadingData = {}
      state.ansOfSpeedReading = []
      state.curQuestion = 0
      state.speedReadingResult = []
      state.speedReadingPassage = null
      state.displaySideQuestion = false
      state.countDownTime = 3
      state.speedReadingLoading = false
      state.speedReadingData.isRejected = false
      state.timeTaken = 0
    },
    setDisableSpeedReadingSideBar: (state, { payload }) => {
      state.disableSpeedReadingSideBar = payload
    },
    updateTimeTaken: (state, { payload }) => {
      state.timeTaken += 1
    },
    resetTimeTaken: (state, { payload }) => {
      state.timeTaken = 0
    },
    setAnswerOfSpeedReading: (state, { payload }) => {
      const postIndex = findIndex(state.ansOfSpeedReading, {
        questionId: payload.questionId,
      })
      if (state.ansOfSpeedReading.length <= 0) {
        state.ansOfSpeedReading = [
          {
            questionId: payload.questionId,
            response: payload.response,
          },
          ...state.ansOfSpeedReading,
        ]
      } else {
        if (
          state.ansOfSpeedReading[postIndex] &&
          state.ansOfSpeedReading[postIndex].questionId === payload.questionId
        ) {
          state.ansOfSpeedReading[postIndex].response = payload.response
        } else {
          state.ansOfSpeedReading = [
            {
              questionId: payload.questionId,
              response: payload.response,
            },
            ...state.ansOfSpeedReading,
          ]
        }
      }
    },
    skipCurQuestion: (state, { payload }) => {
      const postIndex = findIndex(state.ansOfSpeedReading, {
        questionId: payload.questionId,
      })
      state.ansOfSpeedReading[postIndex].response = ''
      state.ansOfSpeedReading[postIndex].time = payload.time
      state.curQuestion += 1
    },
  },
})

export const {
  setSelectedQue,
  setCurQuestion,
  setSelectedQuestionId,
  setAnswerOfSpeedReading,
  setDisplaySideQuestion,
  setTryAgainSpeedTrainer,
  setDisableSpeedReadingSideBar,
  skipCurQuestion,
  setIsRejected,
  updateTimeTaken,
  resetTimeTaken,
  updateCountDownTime,
} = speedReadingSlice.actions

export default speedReadingSlice.reducer
