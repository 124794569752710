const AddNote = ({ className }) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={`h-3 w-3 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66732 1.5C2.20708 1.5 1.83398 1.8731 1.83398 2.33333V11.6667C1.83398 12.1269 2.20708 12.5 2.66732 12.5H4.33398C4.61013 12.5 4.83398 12.7239 4.83398 13C4.83398 13.2761 4.61013 13.5 4.33398 13.5H2.66732C1.6548 13.5 0.833984 12.6792 0.833984 11.6667V2.33333C0.833984 1.32081 1.6548 0.5 2.66732 0.5H9.33398C10.3465 0.5 11.1673 1.32081 11.1673 2.33333V7C11.1673 7.27614 10.9435 7.5 10.6673 7.5C10.3912 7.5 10.1673 7.27614 10.1673 7V2.33333C10.1673 1.8731 9.79422 1.5 9.33398 1.5H2.66732ZM3.50065 3.66667C3.50065 3.39052 3.72451 3.16667 4.00065 3.16667H8.00065C8.27679 3.16667 8.50065 3.39052 8.50065 3.66667C8.50065 3.94281 8.27679 4.16667 8.00065 4.16667H4.00065C3.72451 4.16667 3.50065 3.94281 3.50065 3.66667ZM4.00065 5.83333C3.72451 5.83333 3.50065 6.05719 3.50065 6.33333C3.50065 6.60948 3.72451 6.83333 4.00065 6.83333H8.00065C8.27679 6.83333 8.50065 6.60948 8.50065 6.33333C8.50065 6.05719 8.27679 5.83333 8.00065 5.83333H4.00065ZM3.50065 9C3.50065 8.72386 3.72451 8.5 4.00065 8.5H5.33398C5.61013 8.5 5.83398 8.72386 5.83398 9C5.83398 9.27614 5.61013 9.5 5.33398 9.5H4.00065C3.72451 9.5 3.50065 9.27614 3.50065 9ZM9.16732 9C9.16732 8.72386 8.94346 8.5 8.66732 8.5C8.39118 8.5 8.16732 8.72386 8.16732 9V10.5H6.66732C6.39118 10.5 6.16732 10.7239 6.16732 11C6.16732 11.2761 6.39118 11.5 6.66732 11.5H8.16732V13C8.16732 13.2761 8.39118 13.5 8.66732 13.5C8.94346 13.5 9.16732 13.2761 9.16732 13V11.5H10.6673C10.9435 11.5 11.1673 11.2761 11.1673 11C11.1673 10.7239 10.9435 10.5 10.6673 10.5H9.16732V9Z"
      fill="currentColor"
    />
  </svg>
)

export default AddNote
