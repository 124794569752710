const Hexagon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 14"
    fill="current"
    // stroke="currentColor"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.60085 1.63367L1.60779 4.01087C1.60348 4.01343 1.59914 4.01593 1.59477 4.01836C1.46485 4.09051 1.35659 4.19608 1.28121 4.32414C1.20584 4.45217 1.16607 4.59801 1.16602 4.74657C1.16602 4.74661 1.16602 4.74653 1.16602 4.74657V9.10435C1.16615 9.25283 1.20596 9.39858 1.28131 9.52652C1.3567 9.6545 1.46491 9.76001 1.59477 9.83212C1.60373 9.8371 1.61255 9.84236 1.62119 9.84788L5.6067 12.394C5.72745 12.4585 5.86231 12.4923 5.99935 12.4923C6.13639 12.4923 6.27124 12.4585 6.392 12.394L10.3775 9.84788C10.3861 9.84236 10.395 9.8371 10.4039 9.83212C10.5338 9.75997 10.6421 9.6544 10.7175 9.52634C10.7929 9.39832 10.8326 9.25247 10.8327 9.10391C10.8327 9.10387 10.8327 9.10395 10.8327 9.10391V4.74284C10.8338 4.59551 10.7958 4.45052 10.7226 4.32264C10.6494 4.19477 10.5436 4.08859 10.416 4.01493C10.3847 3.99686 10.356 3.9758 10.33 3.95226L6.4365 1.63366C6.30842 1.5636 6.16474 1.52686 6.01868 1.52686C5.87262 1.52686 5.72893 1.5636 5.60085 1.63367ZM11.0328 3.22233C11.0055 3.19595 10.9749 3.17238 10.9412 3.15231L6.94117 0.77031C6.937 0.767823 6.93279 0.765397 6.92854 0.763033C6.6503 0.608148 6.33713 0.526855 6.01868 0.526855C5.70024 0.526855 5.38706 0.608148 5.10882 0.763033C5.1046 0.765387 5.1004 0.767803 5.09624 0.770278L1.10218 3.14808C0.819521 3.30669 0.583916 3.53743 0.419438 3.81684C0.253589 4.09857 0.166093 4.41953 0.166016 4.74646V9.10457C0.166256 9.43134 0.25383 9.75248 0.419671 10.034C0.5827 10.3108 0.815541 10.5398 1.09478 10.6982L5.08284 13.2459C5.09148 13.2515 5.1003 13.2567 5.10927 13.2617C5.38156 13.4129 5.68788 13.4923 5.99935 13.4923C6.31081 13.4923 6.61714 13.4129 6.88943 13.2617C6.8984 13.2567 6.90721 13.2515 6.91586 13.2459L10.9039 10.6982C11.1833 10.5398 11.4162 10.3106 11.5793 10.0336C11.7451 9.75191 11.8326 9.43095 11.8327 9.10403V4.74828C11.8347 4.42484 11.7512 4.1066 11.5905 3.82586C11.4524 3.58457 11.2615 3.37838 11.0328 3.22233Z"
      fill="current"
    />
  </svg>
)

export default Hexagon
