import { createSlice } from '@reduxjs/toolkit'
import { findIndex, remove } from 'lodash'
import moment from 'moment'
import {
  calendarSchedule,
  calendarPlaceableResources,
  createPersonalEvent,
  createCalendarItem,
  deleteCalendarItem,
  clearCalendar,
  rescheduleCalendarItem,
  createCalendarQuizItem,
  rescheduleCalendarQuizItem,
} from './calendarAction'
const isDark = () =>
  (localStorage && localStorage.theme === 'Dark') ||
  (!('theme' in localStorage) &&
    window.matchMedia('(prefers-color-scheme: dark)').matches)
const eventBgColor = {
  Exam: isDark ? '#F2473A10' : '#FEEDEB',
  Module: isDark ? '#8AC34A10' : '#F3F9ED',
  QBank: isDark ? '#FF910210' : '#FFF4E6',
  'Skills Trainer': isDark ? '#E7469410' : '#FCE8F2',
  'Official UCAT Practice Exam': isDark ? '#16BDCA10' : '#E8FBFC',
  'Subtest Mock': isDark ? '#2196F310' : '#E9F5FE',
  'Exam Review': isDark ? '#9419B310' : '#FAE5FF',
  'Exam Retry': isDark ? '#FFC10710' : '#FFF9E6',
  Personal: isDark ? '#E0E0E010' : '#f9f9f9',
}

const eventBorderColor = {
  Exam: '#F2473A',
  Module: '#8AC34A',
  QBank: '#FF9102',
  'Skills Trainer': '#E74694',
  'Official UCAT Practice Exam': '#16BDCA',
  'Subtest Mock': '#2196F3',
  'Exam Review': '#9419B3',
  'Exam Retry': '#FFC107',
  Personal: '#E0E0E0',
}
const CalendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    calendarLoading: false,
    isLocked: false,
    placeableResources: [],
    changeCalendarData: 0,
    calendarData: [],
    monthDate: {
      startDate: null,
      endDate: null,
    },
    resourceTypes: [],
    resourceType: 'All Resource Types',
    selectedResources: [],
    showAll: {},
    expandedItem: false,
    testDate: null,
    calendarEvent: null,
    resourceSearch: '',
    isOpenModel: false,
    initialView: false,
    scrollTime: '00:00:00',
    addEventLoading: false,
    editEventLoading: false,
    customAddEventLoading: false,
    editPopup: false,
    calendarTourEvent: false,
    clearAllCalenderEvent: false,
    resourcesLoader: false,
    showAddCalendarModal: false,
    addExamData: null,
    createLoader: false,
    viewMode: 'dayGridMonth',
  },
  extraReducers: {
    [rescheduleCalendarQuizItem.pending]: (state, action) => {
      state.createLoader = true
    },
    [rescheduleCalendarQuizItem.fulfilled]: (state, { payload }) => {
      state.createLoader = false
    },
    [rescheduleCalendarQuizItem.rejected]: (state, action) => {
      state.createLoader = false
    },
    [createCalendarQuizItem.pending]: (state, action) => {
      state.createLoader = true
    },
    [createCalendarQuizItem.fulfilled]: (state, { payload }) => {
      state.createLoader = false
    },
    [createCalendarQuizItem.rejected]: (state, action) => {
      state.createLoader = false
    },

    [calendarSchedule.pending]: (state, action) => {
      state.calendarLoading = true
    },
    [calendarSchedule.fulfilled]: (state, action) => {
      const mode = action?.payload?.data?.view_mode
      state.viewMode =
        mode === 'weekly'
          ? 'timeGridWeek'
          : mode === 'monthly'
          ? 'dayGridMonth'
          : 'dayGridMonth'
      const isHpat = process.env.REACT_APP_OLP_TYPE
      state.calendarLoading = false
      state.isLocked = false
      state.calendarData = action?.payload?.data?.data?.map((item) => ({
        id: item.id,
        type: item.type,
        title: item.name,
        start: moment.utc(item.time).format('YYYY-MM-DD HH:mm'),
        isCompleted: item?.is_completed,
        end: moment
          .utc(item.time)
          .add(item.duration_minutes, 'minutes')
          .format('YYYY-MM-DD HH:mm'),
        location_breadcrumbs: item?.location_breadcrumbs,
        backgroundColor: eventBgColor[item.type],
        borderColor: eventBorderColor[item.type],
        display: 'background',
      }))
      if (action?.payload?.data?.test_date) {
        state.calendarData = [
          ...state.calendarData,
          {
            id: 1,
            title: isHpat === 'hpat' ? 'My HPAT Test Day' : 'My UCAT Test Day',
            start: moment
              .utc(action?.payload?.data?.test_date)
              .format('YYYY-MM-DD 00:00'),
            end: moment
              .utc(action?.payload?.data?.test_date)
              .format('YYYY-MM-DD 24:00'),
            disable: true,
            backgroundColor: 'rgb(33, 150, 243, 0.1)',
            borderColor: '#2196F3',
            className: [
              'dark:bg-blue-500',
              'dark:bg-opacity-10',
              'text-black',
              'pointer-events-none',
              'select-none',
            ],
          },
        ]
      }

      state.testDate = action?.payload?.data?.test_date
      state.calendarTourEvent = true
      // state.changeCalendarData = Math.random()
    },
    [calendarSchedule.rejected]: (state, { payload }) => {
      state.calendarLoading = false
      if (payload && payload.status === 403) {
        state.isLocked = true
      }
    },
    [calendarPlaceableResources.pending]: (state, action) => {
      state.resourcesLoader = true
    },
    [calendarPlaceableResources.fulfilled]: (state, action) => {
      state.resourcesLoader = false
      state.isLocked = false
      state.selectedResources = state.placeableResources =
        action.payload.data.data
      state.resourceTypes = [
        'All Resource Types',
        ...Object.keys(action.payload.data.data),
      ]
      Object.keys(action.payload.data.data).forEach((item) => {
        state.showAll[item] = false
      })
    },
    [calendarPlaceableResources.rejected]: (state, { payload }) => {
      state.resourcesLoader = false
      if (payload && payload.status === 403) {
        state.isLocked = true
      }
    },
    [createPersonalEvent.pending]: (state, action) => {
      state.addEventLoading = true
    },
    [createPersonalEvent.fulfilled]: (state, action) => {
      state.addEventLoading = false
      state.calendarData = [
        {
          title: action.payload.data.data.name,
          start: new Date(action.payload.data.data.time),
          end: moment(action.payload.data.data.time)
            .add(action.payload.data.data.duration_minutes, 'minutes')
            .toISOString(),
          id: action.payload.data.data.id,
        },
        ...state.calendarData,
      ]
      // state.changeCalendarData = Math.random()
    },
    [createPersonalEvent.rejected]: (state, action) => {
      state.addEventLoading = false
    },

    [createCalendarItem.pending]: (state, action) => {
      state.customAddEventLoading = true
    },
    [createCalendarItem.fulfilled]: (state, action) => {
      state.customAddEventLoading = false
      state.calendarData = [
        ...state.calendarData,
        {
          title: action.payload.data.data.name,
          type: action.payload.data.data.type,
          isCompleted: action.payload.data?.data?.is_completed,
          start: moment
            .utc(action.payload.data.data.time)
            .format('YYYY-MM-DD HH:mm:ss'),
          id: action.payload.data.data.id,
          end: moment
            .utc(action.payload.data.data.time)
            .add(action.payload.data.data.duration_minutes, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss'),
          location_breadcrumbs: action.payload.data.data.location_breadcrumbs,
          backgroundColor: eventBgColor[action.payload.data.data.type],
          borderColor: eventBorderColor[action.payload.data.data.type],
          display: 'background',
        },
      ]
      // state.changeCalendarData = Math.random()
      const selectedResourcesIndex = findIndex(
        state.selectedResources[action.meta.arg.selectedResourcesType],
        {
          type_id: action.payload.data.data.type_id,
        },
      )
      state.selectedResources[action.meta.arg.selectedResourcesType][
        selectedResourcesIndex
      ].maximum_uses -= 1
      if (
        state.selectedResources[action.meta.arg.selectedResourcesType][
          selectedResourcesIndex
        ].maximum_uses === 0
      ) {
        remove(
          state.selectedResources[action.meta.arg.selectedResourcesType],
          (item) => item.type_id === Number(action.payload.data.data.type_id),
        )
      }
    },
    [createCalendarItem.rejected]: (state, action) => {
      state.customAddEventLoading = false
    },

    [deleteCalendarItem.fulfilled]: (state, action) => {
      remove(
        state.calendarData,
        (item) => item.id === Number(action.meta.arg.id),
      )
    },
    [clearCalendar.pending]: (state, action) => {
      state.clearCalendarLoading = true
    },
    [clearCalendar.fulfilled]: (state, action) => {
      state.clearCalendarLoading = false
      state.calendarData = []
    },
    [clearCalendar.rejected]: (state, action) => {
      state.clearCalendarLoading = false
    },

    [rescheduleCalendarItem.pending]: (state) => {
      state.editEventLoading = true
    },
    [rescheduleCalendarItem.fulfilled]: (state, action) => {
      state.editEventLoading = false
      state.calendarEvent = null
      const postIndex = findIndex(state.calendarData, {
        id: action.payload?.data?.data?.id,
      })
      if (postIndex !== -1) {
        state.calendarData[postIndex].start = new Date(
          action.payload.data.data.time,
        )
        state.calendarData[postIndex].end = moment(
          action.payload.data.data.time,
        )
          .add(action.payload.data.data.override_duration_minutes, 'minutes')
          .toISOString()
      }
    },

    [rescheduleCalendarItem.rejected]: (state) => {
      state.editEventLoading = false
    },
  },
  reducers: {
    setAddExamData: (state, { payload }) => {
      state.addExamData = payload
    },
    setShowAddCalendarModal: (state, { payload }) => {
      state.showAddCalendarModal = payload
    },
    setClearAllCalenderEvent: (state, { payload }) => {
      state.clearAllCalenderEvent = payload
    },
    setMonthDate: (state, action) => {
      state.monthDate = {
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      }
    },
    loadMore: (state, { payload }) => {
      state.showAll[payload.key] = !state.showAll[payload.key]
    },
    setCalendarEvent: (state, { payload }) => {
      state.calendarEvent = payload
    },
    setResourceType: (state, { payload }) => {
      state.resourceType = payload
      if (payload === 'All Resource Types') {
        state.selectedResources = state.placeableResources
        Object.keys(state.showAll).forEach((item) => {
          state.showAll[item] = false
        })
      } else {
        state.selectedResources = {
          [payload]: [...state.placeableResources[payload]],
        }
        state.showAll[payload] = true
      }
    },
    setResourceSearch: (state, { payload }) => {
      state.resourceSearch = payload
      const temp = {}
      Object.keys(state.selectedResources).forEach((resource) => {
        temp[resource] = state.placeableResources[resource].filter(
          (item) => item.name.toLowerCase().indexOf(payload.toLowerCase()) > -1,
        )
      })
      if (!payload && state.resourceType === 'All Resource Types') {
        Object.keys(state.showAll).forEach((item) => {
          state.showAll[item] = false
        })
      } else {
        Object.keys(state.showAll).forEach((item) => {
          state.showAll[item] = true
        })
      }
      state.selectedResources = { ...temp }
    },
    setModelOpen: (state, { payload }) => {
      state.isOpenModel = payload
    },
    toggleEditPopup: (state, { payload }) => {
      state.editPopup = payload
    },
    setInitialView: (state, { payload }) => {
      state.initialView = payload
    },
    setScrollTime: (state, { payload }) => {
      state.scrollTime = payload
    },
  },
})

export const {
  setAddExamData,
  setShowAddCalendarModal,
  setClearAllCalenderEvent,
  setMonthDate,
  loadMore,
  setCalendarEvent,
  setResourceType,
  setResourceSearch,
  setModelOpen,
  setInitialView,
  setScrollTime,
  toggleEditPopup,
} = CalendarSlice.actions

export default CalendarSlice.reducer
