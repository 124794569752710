import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getTourStepsData = createAsyncThunk(
  'tourSteps/getTourStepsData',
  async ({ page, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `tour/get-remaining-tour-steps/${page}`,
      )
      onSuccess && onSuccess()
      return res
    } catch (error) {
      throw rejectWithValue(error.response.data)
    }
  },
)

export const restartTour = createAsyncThunk(
  'tourSteps/restartTour',
  async ({ page, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`tour/restart/${page}`)
      onSuccess && onSuccess()
      return res
    } catch (error) {
      throw rejectWithValue(error.response.data)
    }
  },
)

export const markTourStepViewed = createAsyncThunk(
  'tourSteps/markTourStepViewed',
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(
        `tour/mark-tour-step-viewed/${id}`,
      )
      onSuccess && onSuccess()
      return res
    } catch (error) {}
  },
)

export const markTourSkipped = createAsyncThunk(
  'tourSteps/markTourSkipped',
  async ({ page, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(`tour/mark-tour-skipped/${page}`)
      onSuccess && onSuccess()
      return res
    } catch (error) {
      throw rejectWithValue(error)
    }
  },
)

export const getSyllogismsTourStepsData = createAsyncThunk(
  'tourSteps/getSyllogismsTourStepsData',
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `tour/get-remaining-tour-steps/syllogisms`,
      )
      onSuccess && onSuccess()
      return res
    } catch (error) {
      throw rejectWithValue(error.response.data)
    }
  },
)

export const tourStepsSlice = createSlice({
  name: 'tourSteps',
  initialState: {
    tourStepsData: { isLoading: false, data: [] },
    showStartTourDialog: false,
    tourPageName: '',
    restartTour: false,
    isLearnTourCall: false,
    tourVideoData: null,
    syllogismsTourSteps: { isLoading: false, data: null },
    isSyllogismsPage: false,
    hasSyllogismsVideoTour: false,
    exploreTour: false,
    showTourVideoModal: false,
  },
  extraReducers: {
    [getSyllogismsTourStepsData.pending]: (state, action) => {
      state.syllogismsTourSteps.isLoading = true
      state.isSyllogismsPage = true
    },
    [getSyllogismsTourStepsData.fulfilled]: (state, { payload }) => {
      if (payload?.data?.tour) {
        state.hasSyllogismsVideoTour = true
      }
      state.syllogismsTourSteps.isLoading = false
      state.syllogismsTourSteps.data = payload.data
    },
    [getSyllogismsTourStepsData.rejected]: (state, action) => {
      state.syllogismsTourSteps.isLoading = false
    },
    [getTourStepsData.pending]: (state, action) => {
      state.tourStepsData.isLoading = true
    },
    [getTourStepsData.fulfilled]: (state, { meta, payload }) => {
      state.tourStepsData.isLoading = false
      state.tourStepsData.data = payload.data
      state.restartTour = false
      if (meta.arg.page === 'learn') {
        state.isLearnTourCall = true
      } else {
        state.isLearnTourCall = false
      }
    },
    [getTourStepsData.rejected]: (state, action) => {
      state.tourStepsData.isLoading = false
    },
    [restartTour.pending]: (state, { meta }) => {
      if (meta.arg.page === 'syllogisms') {
        state.syllogismsTourSteps.isLoading = true
      } else {
        state.tourStepsData.isLoading = true
      }
    },
    [restartTour.fulfilled]: (state, { meta, payload }) => {
      state.tourStepsData.isLoading = false
      state.restartTour = true
      if (meta.arg.page === 'syllogisms') {
        if (payload?.data?.tour) {
          state.hasSyllogismsVideoTour = true
        }
        state.syllogismsTourSteps.isLoading = false
        state.syllogismsTourSteps.data = payload.data
      } else {
        state.tourStepsData.data = payload.data
      }
    },
    [restartTour.rejected]: (state, action) => {
      state.tourStepsData.isLoading = false
    },
  },
  reducers: {
    setExploreTour: (state, { payload }) => {
      state.exploreTour = payload
    },
    setTourVideoData: (state, { payload }) => {
      state.showTourVideoModal = payload ? true : false
      state.tourVideoData = payload
    },
    setShowTourVideoModal: (state, { payload }) => {
      state.showTourVideoModal = payload
    },
    toggleShowStartTourDialog: (state, { payload }) => {
      state.showStartTourDialog = payload
    },
    setTourPageName: (state, { payload }) => {
      state.tourPageName = payload
    },
    skipTourData: (state, { payload }) => {
      state.tourStepsData = { isLoading: false, data: [] }
      state.restartTour = false
    },
    clearTourData: (state, { payload }) => {
      state.tourStepsData = { isLoading: false, data: [] }
      state.restartTour = false
      state.tourPageName = ''
      state.syllogismsTourSteps = { isLoading: false, data: null }
      state.isSyllogismsPage = false
      state.hasSyllogismsVideoTour = false
    },
    clearViewedSteps: (state, { payload }) => {
      let viewStepsIds = payload && payload?.map((user) => user.id)
      if (
        state.hasSyllogismsVideoTour &&
        state.syllogismsTourSteps?.data?.tour?.steps?.length > 0
      ) {
        state.hasSyllogismsVideoTour = false
        state.syllogismsTourSteps.data.tour.steps =
          state.syllogismsTourSteps?.data?.tour?.steps?.filter((item) => {
            return viewStepsIds.indexOf(item.id) === -1
          })
      } else {
        if (
          state.tourStepsData?.data?.tour?.steps &&
          state.tourStepsData?.data?.tour?.steps?.length > 0
        ) {
          state.tourStepsData.data.tour.steps =
            state.tourStepsData?.data?.tour?.steps?.filter((item) => {
              return viewStepsIds.indexOf(item.id) === -1
            })
        }
        state.restartTour = false
      }
    },
  },
})

export const {
  setShowTourVideoModal,
  setExploreTour,
  toggleShowStartTourDialog,
  skipTourData,
  setTourPageName,
  clearTourData,
  clearViewedSteps,
  setTourVideoData,
} = tourStepsSlice.actions

export default tourStepsSlice.reducer
