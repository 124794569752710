import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const startWordGameAttempt = createAsyncThunk(
  'emotionsWordGame/startWordGameAttempt',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `trainer/word-game/start-attempt`,
      )

      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const submitWordGameAttempt = createAsyncThunk(
  'emotionsWordGame/submitWordGameAttempt',
  async ({ wordAttempts, attemptableId }, { rejectWithValue }) => {
    const word_attempts = wordAttempts.map((attempt) => ({
      id: attempt.id,
      chosen_option: attempt.userAnswer,
      time_taken: attempt.time_taken ? attempt.time_taken : 0,
    }))
    try {
      const res = await axiosInterceptors.post(
        `trainer/word-game/${attemptableId}/submit-attempt`,
        { word_attempts },
      )

      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

const emotionsWordGameSlice = createSlice({
  name: 'emotionsWordGame',
  initialState: {
    wordGameData: { isLoading: false, rejected: false, data: {} },
    wordGameResult: { isLoading: false, rejected: false, data: {} },
    currentQuestionIndex: 0,
    isAnswerSelected: false,
    selectedAnswers: [],
    nextQuestionCountDown: null,
    isSubmitGame: false,
    isStartWordGame: false,
    questionTimeTaken: 0,
  },
  extraReducers: {
    [startWordGameAttempt.pending]: (state, { payload }) => {
      state.wordGameData.isLoading = true
    },
    [startWordGameAttempt.fulfilled]: (state, { payload }) => {
      state.wordGameData = { isLoading: false, data: payload.data }
      state.isStartWordGame = true
    },
    [startWordGameAttempt.rejected]: (state, { payload }) => {
      state.wordGameData.isLoading = false
      state.wordGameData.rejected = true
    },
    [submitWordGameAttempt.pending]: (state, { payload }) => {
      state.wordGameResult.isLoading = true
      state.isSubmitGame = true
    },
    [submitWordGameAttempt.fulfilled]: (state, { payload }) => {
      state.wordGameResult = { isLoading: false, data: payload.data }
    },
    [submitWordGameAttempt.rejected]: (state, { payload }) => {
      state.wordGameResult.isLoading = false
      state.wordGameResult.rejected = true
      state.isSubmitGame = false
    },
  },

  reducers: {
    setQuestionTimeTaken: (state, { payload }) => {
      state.questionTimeTaken = payload
    },
    setCurrentQuestion: (state, { payload }) => {
      state.currentQuestionIndex += 1
    },
    setIsAnswerSelected: (state, { payload }) => {
      state.isAnswerSelected = payload
    },
    setSelectedAnswers: (state, { payload }) => {
      state.selectedAnswers = [...state.selectedAnswers, payload]
    },
    setNextQuestionCountDown: (state, { payload }) => {
      state.nextQuestionCountDown = payload
    },
    setIsSubmitGame: (state, { payload }) => {
      state.isSubmitGame = payload
    },
    resetGame: (state, { payload }) => {
      state.currentQuestionIndex = 0
      state.isStartWordGame = false
      state.isSubmitGame = false
      state.isAnswerSelected = false
      state.wordGameData = { isLoading: false, rejected: false, data: {} }
      state.wordGameResult = { isLoading: false, rejected: false, data: {} }
      state.selectedAnswers = []
      state.questionTimeTaken = 0
    },
    setIsStartWordGame: (state, { payload }) => {
      state.isStartWordGame = payload
    },
  },
})

export const {
  setQuestionTimeTaken,
  setCurrentQuestion,
  setIsAnswerSelected,
  setSelectedAnswers,
  setNextQuestionCountDown,
  setIsSubmitGame,
  resetGame,
  setIsStartWordGame,
} = emotionsWordGameSlice.actions

export default emotionsWordGameSlice.reducer
