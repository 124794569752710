const Lock = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.468 3.135a2.167 2.167 0 013.699 1.532v2.166H5.833V4.667c0-.575.229-1.126.635-1.532zM4.833 6.833V4.667a3.167 3.167 0 116.334 0v2.166h.166c1.013 0 1.834.821 1.834 1.834v4c0 1.012-.821 1.833-1.834 1.833H4.667a1.833 1.833 0 01-1.834-1.833v-4c0-1.013.821-1.834 1.834-1.834h.166zm5.834 1h.666c.46 0 .834.373.834.834v4c0 .46-.373.833-.834.833H4.667a.833.833 0 01-.834-.833v-4c0-.46.373-.834.834-.834h6zM8 10.5a.167.167 0 100 .333.167.167 0 000-.333zm-1.167.167a1.167 1.167 0 112.334 0 1.167 1.167 0 01-2.334 0z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Lock
