const CartIcon = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 3C3.25 2.58579 3.58579 2.25 4 2.25H6C6.41421 2.25 6.75 2.58579 6.75 3V4.30166L20.0534 5.25191C20.261 5.26673 20.4531 5.36714 20.5837 5.52911C20.7144 5.69108 20.7719 5.90006 20.7425 6.10607L19.7425 13.1061C19.6897 13.4756 19.3732 13.75 19 13.75H6.75V16.25H17C17.7293 16.25 18.4288 16.5397 18.9445 17.0555C19.4603 17.5712 19.75 18.2707 19.75 19C19.75 19.7293 19.4603 20.4288 18.9445 20.9445C18.4288 21.4603 17.7293 21.75 17 21.75C16.2707 21.75 15.5712 21.4603 15.0555 20.9445C14.5397 20.4288 14.25 19.7293 14.25 19C14.25 18.5614 14.3548 18.1335 14.5505 17.75H8.44949C8.64521 18.1335 8.75 18.5614 8.75 19C8.75 19.7293 8.46027 20.4288 7.94454 20.9445C7.42882 21.4603 6.72935 21.75 6 21.75C5.27065 21.75 4.57118 21.4603 4.05546 20.9445C3.53973 20.4288 3.25 19.7293 3.25 19C3.25 18.2707 3.53973 17.5712 4.05546 17.0555C4.39024 16.7207 4.80245 16.4811 5.25 16.3542V13V5.00775C5.24996 5.00311 5.24996 4.99846 5.25 4.9938V3.75H4C3.58579 3.75 3.25 3.41421 3.25 3ZM6.75 5.80548V12.25H18.3495L19.1437 6.69075L6.75 5.80548ZM16.1161 18.1161C16.3505 17.8817 16.6685 17.75 17 17.75C17.3315 17.75 17.6495 17.8817 17.8839 18.1161C18.1183 18.3505 18.25 18.6685 18.25 19C18.25 19.3315 18.1183 19.6495 17.8839 19.8839C17.6495 20.1183 17.3315 20.25 17 20.25C16.6685 20.25 16.3505 20.1183 16.1161 19.8839C15.8817 19.6495 15.75 19.3315 15.75 19C15.75 18.6685 15.8817 18.3505 16.1161 18.1161ZM5.11612 18.1161C5.35054 17.8817 5.66848 17.75 6 17.75C6.33152 17.75 6.64946 17.8817 6.88388 18.1161C7.1183 18.3505 7.25 18.6685 7.25 19C7.25 19.3315 7.1183 19.6495 6.88388 19.8839C6.64946 20.1183 6.33152 20.25 6 20.25C5.66848 20.25 5.35054 20.1183 5.11612 19.8839C4.8817 19.6495 4.75 19.3315 4.75 19C4.75 18.6685 4.8817 18.3505 5.11612 18.1161Z"
      fill="currentColor"
    />
  </svg>
)

export default CartIcon
