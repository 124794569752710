import { ThumbUp, Chat, Loading } from 'components/icons'
import { useState, Fragment, useEffect } from 'react'
import {
  AskQuestion,
  Button,
  Reply,
  Spinner,
  SmallLogo,
} from 'components/shared'
import { useDispatch, useSelector } from 'react-redux'
import {
  getResourceQuestions,
  setSelectedQuestion,
  setSelectedSortBy,
  setUpVoteResource,
  updateQuestionPageNumber,
  updateResourceDebounce,
  updateSearchLoader,
  updateSearchResourceQuestion,
  setSelectedFilterQuestions,
  setDiscussionSubmission,
  clearResourceQuestionsData,
} from 'redux/learn/resourceQuestionsSlice'
import toast from 'react-hot-toast'
import debounce from 'lodash.debounce'
import moment from 'moment-timezone'
import SuccessModal from './SuccessModal'
import { resourceName } from 'components/shared/helper'

const QuestionsAns = ({
  openAskQuestionPage,
  setOpenAskQuestion,
  setOpenReplyPage,
  openReplyPage,
  bookChapterId,
  questionTypeId,
  bookId,
  resourceType,
  numResourceQuestions,
}) => {
  const chapters = [
    { id: bookChapterId, name: 'Current Chapter', typeId: questionTypeId },
    { id: bookId, name: `Current ${resourceName}`, typeId: 'book_id' },
  ]

  const sortByFilter = [
    { id: 1, name: 'Recommended', sortByType: 'recommended' },
    { id: 3, name: 'Most Recent', sortByType: 'recent' },
    { id: 2, name: 'Oldest', sortByType: 'oldest' },
  ]
  const filterResourceQuestions = [
    { id: 1, name: 'All', filterQuestion: null },
    { id: 2, name: 'Mine', filterQuestion: 'mine' },
    { id: 2, name: 'Following', filterQuestion: 'following' },
  ]

  const [selectedChaptersFilter, setSelectedChaptersFilter] = useState(
    chapters[0],
  )

  const dispatch = useDispatch()
  const {
    resourceQuestionsData,
    questionPageNumber,
    upVoteLoadingId,
    searchResourceQuestion,
    resourceDebounce,
    selectedSortBy,
    selectedFilterQuestions,
    userData,
    isDiscussionSubmission,
  } = useSelector((state) => ({
    resourceQuestionsData: state.resourceQuestions.resourceQuestionsData,
    questionPageNumber: state.resourceQuestions.questionPageNumber,
    upVoteLoadingId: state.resourceQuestions.upVoteLoadingId,
    searchResourceQuestion: state.resourceQuestions.searchResourceQuestion,
    resourceDebounce: state.resourceQuestions.resourceDebounce,
    selectedSortBy: state.resourceQuestions.selectedSortBy,
    selectedFilterQuestions: state.resourceQuestions.selectedFilterQuestions,
    userData: state.personalInfo.userData,
    isDiscussionSubmission: state.resourceQuestions.isDiscussionSubmission,
  }))

  const controller = new AbortController()

  useEffect(() => {
    if (numResourceQuestions !== undefined && numResourceQuestions === 0) {
      return
    }
    const timer = setTimeout(() => {
      dispatch(
        getResourceQuestions({
          bookChapterId: bookChapterId,
          questionTypeId: selectedChaptersFilter.typeId,
          page: questionPageNumber,
          sortBy: selectedSortBy.sortByType,
          filterResourceQuestions: selectedFilterQuestions.filterQuestion,
          resourceQuestionsSearchText: searchResourceQuestion,
          abortController: controller,
        }),
      )
      clearTimeout(timer)
    }, 500)
    return () => {
      controller.abort()
    }
  }, [
    dispatch,
    bookChapterId,
    questionPageNumber,
    selectedChaptersFilter,
    selectedSortBy,
    selectedFilterQuestions,
    searchResourceQuestion,
  ])

  useEffect(() => {
    return () => {
      dispatch(updateQuestionPageNumber(1))
      dispatch(
        setSelectedSortBy({
          id: 1,
          name: 'Recommended',
          sortByType: 'recommended',
        }),
      )
      dispatch(
        setSelectedFilterQuestions({
          id: 1,
          name: 'All',
          filterQuestion: null,
        }),
      )
    }
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(clearResourceQuestionsData())
    }
  }, [dispatch])

  const questionSearchFilter = (text) => {
    dispatch(updateSearchLoader(true))
    if (!resourceDebounce) {
      dispatch(
        updateResourceDebounce(
          debounce(() => {
            if (text.target.value) {
              dispatch(updateQuestionPageNumber(1))
              dispatch(updateSearchResourceQuestion(text.target.value))
              dispatch(updateSearchLoader(false))
            } else {
              dispatch(updateSearchResourceQuestion(''))
            }
          }, 1000),
        ),
      )
    }

    resourceDebounce && resourceDebounce()
  }
  if (!!openAskQuestionPage) {
    return (
      <AskQuestion
        bookChapterId={bookChapterId}
        typeOfQuestion={resourceType}
        setOpenAskQuestion={setOpenAskQuestion}
      />
    )
  }

  if (openReplyPage) {
    return <Reply setOpenReplyPage={setOpenReplyPage} />
  } else {
    return (
      <>
        <div>
          {isDiscussionSubmission && (
            <SuccessModal
              open={isDiscussionSubmission}
              onClose={() => {
                dispatch(setDiscussionSubmission(false))
              }}
              content={
                <div className="flex flex-col gap-4 items-center">
                  <div className="h-14 w-14 rounded-full bg-blue-200 flex justify-center items-center">
                    <ThumbUp className="w-8 h-8 text-blue-500" />
                  </div>
                  <div className="text-center text-base font-normal text-gray-900 dark:text-white">
                    Thank you for your submission to the MedEntry community.
                    This discussion has now been published, and you will receive
                    a notification if and when a response is submitted.
                  </div>
                </div>
              }
            />
          )}
          {/* re-implement in future if there are more comments */}
          {/* <div className="bg-gray-100 dark:bg-dark-500 flex items-center rounded-md px-4 mt-6  ">
          <Search className=" mr-4 h-6 w-6 dark:text-white" />
          <input
            className="bg-transparent dark:border-dark-400 dark:text-white w-full rounded-md border-0 outline-none  h-12 text-base"
            placeholder={
              resourceType === 'question'
                ? 'Search all discussions about this question'
                : 'Search all discussions about this resource'
            }
            onChange={questionSearchFilter}
            onPaste={questionSearchFilter}
          />
        </div> */}
          {resourceQuestionsData && resourceQuestionsData.isLoading ? (
            <div className="my-6">
              <Spinner />
            </div>
          ) : (
            <>
              {/* re-implement in future if there are more comments */}
              {/* <div className="sm:flex space-y-4 space-x-0 sm:space-y-0 sm:space-x-4 mt-6 ">
                {resourceType !== 'question' && (
                  <div className="relative w-full">
                    <Dropdown
                      value={selectedChaptersFilter}
                      onChange={(value) => {
                        dispatch(updateQuestionPageNumber(1))
                        setSelectedChaptersFilter(value)
                      }}
                      options={chapters}
                      optionLabelField="name"
                    />
                  </div>
                )}
                <div className="relative w-full">
                  <Dropdown
                    value={selectedSortBy}
                    options={sortByFilter}
                    onChange={(value) => {
                      dispatch(updateQuestionPageNumber(1))
                      setSelectedChaptersFilter(chapters[0])
                      dispatch(setSelectedSortBy(value))
                    }}
                    optionLabelField="name"
                  />
                </div>
                <div className="relative w-full">
                  <Dropdown
                    value={selectedFilterQuestions}
                    options={filterResourceQuestions}
                    onChange={(value) => {
                      dispatch(updateQuestionPageNumber(1))
                      dispatch(setSelectedFilterQuestions(value))
                    }}
                    optionLabelField="name"
                  />
                </div>
              </div> */}
              <>
                <div className="sm:flex mt-10 sm:justify-between sm:items-center">
                  <h3 className="text-xl leading-6 font-semibold text-gray-900 dark:text-white">
                    {resourceQuestionsData?.pagination?.total ? (
                      <>
                        {resourceQuestionsData?.pagination?.total}{' '}
                        {resourceQuestionsData?.pagination?.total > 1
                          ? 'discussions'
                          : 'discussion'}{' '}
                        {resourceType === 'question'
                          ? 'about this question'
                          : 'in this Chapter'}
                      </>
                    ) : (
                      'There are no discussions'
                    )}
                  </h3>
                  <div className=" ">
                    <Button
                      className="flex-none sm:mt-0 mt-6 sm:w-auto w-full py-4 px-16 font-semibold tracking-widest"
                      onClick={() => {
                        setOpenAskQuestion(true)
                        if (!resourceQuestionsData?.pagination?.total) {
                          dispatch(
                            getResourceQuestions({
                              bookChapterId: bookChapterId,
                              questionTypeId: selectedChaptersFilter.typeId,
                              page: questionPageNumber,
                              sortBy: selectedSortBy.sortByType,
                              filterResourceQuestions:
                                selectedFilterQuestions.filterQuestion,
                              resourceQuestionsSearchText:
                                searchResourceQuestion,
                              abortController: controller,
                            }),
                          )
                        }
                      }}
                      outlined
                    >
                      Start Discussion
                    </Button>
                  </div>
                </div>

                <div className="border border-gray-100 dark:border-dark-500 mt-10 notification-discussion" />
                {resourceQuestionsData?.data?.questions?.length > 0 &&
                  resourceQuestionsData?.data?.questions?.map(
                    (data, dataIndex) => (
                      <div
                        key={dataIndex}
                        className="border-b border-gray-100 dark:border-dark-500"
                      >
                        <div className="pt-6 pb-3.5 sm:py-6 flex w-full ">
                          <div>
                            {data?.user?.avatar_url ? (
                              <img
                                style={{ minHeight: '40px', minWidth: '40px' }}
                                className=" rounded-full h-10 w-10"
                                src={data?.user?.avatar_url}
                                alt={data?.user?.first_name}
                              />
                            ) : (
                              <SmallLogo className="h-8 w-8 " />
                            )}
                          </div>
                          <div className="flex flex-col ml-3 text-gray-900 dark:text-white w-full">
                            <p className="font-semibold leading-5 text-base">
                              {data.question_subject}
                            </p>
                            <span className="break-all font-noraml mt-2 leading-6 text-gray-900 dark:text-white text-base sm:w-4/5">
                              {data?.question_text?.length > 70
                                ? `${data?.question_text?.substring(0, 70)}...`
                                : data?.question_text}
                            </span>
                            <span className="mt-4 sm:flex leading-5 items-center justify-between text-gray-500 dark:text-dark-300 text-sm font-normal">
                              {data?.user?.first_name}
                              {data?.user?.first_name && ' / '}
                              {data.type_breadcrumbs.join(', ')}
                              {data.type_breadcrumbs && ' / '}
                              {userData?.timezone
                                ? moment(data.created_at)
                                    ?.tz(userData?.timezone)
                                    ?.fromNow()
                                : moment?.utc(data?.created_at)?.fromNow()}
                              <div className="mt-3.5 sm:mt-0 justify-end flex items-center text-gray-900 dark:text-white">
                                <div
                                  className={`flex items-center cursor-pointer ${
                                    upVoteLoadingId === data.id &&
                                    'opacity-25 pointer-events-none'
                                  }`}
                                  onClick={() => {
                                    dispatch(
                                      setUpVoteResource({
                                        data: {
                                          questionsType: 'question',
                                          state: data.is_user_upvoted ? 0 : 1,
                                          questionId: data.id,
                                        },
                                        onError: (errorsMassage) => {
                                          toast.error(errorsMassage.message, {
                                            duration: 3000,
                                            position: 'top-center',
                                            style: {
                                              backdropFilter: 'blur(4px)',
                                              marginTop: '20px',
                                              width: '350px',
                                            },
                                          })
                                        },
                                      }),
                                    )
                                  }}
                                >
                                  <p className="text-sm leading-5">
                                    {data.num_upvotes ? data.num_upvotes : 0}
                                  </p>
                                  <ThumbUp
                                    className={`ml-2  ${
                                      data.is_user_upvoted
                                        ? 'text-red-600'
                                        : 'dark:text-white'
                                    }`}
                                  />
                                </div>
                                <div
                                  onClick={() => {
                                    dispatch(setSelectedQuestion(data))
                                    setOpenReplyPage(true)
                                  }}
                                  className="flex items-center ml-6 hover:text-red-600 cursor-pointer"
                                >
                                  <p className="text-sm leading-5">
                                    {data.num_answers}
                                  </p>
                                  <button>
                                    <Chat className="ml-2 fill-current dark:text-white" />
                                  </button>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    ),
                  )}
                {resourceQuestionsData?.pagination?.page_number <
                  resourceQuestionsData?.pagination?.last_page_number && (
                  <div className="flex justify-center mb-4">
                    <Button
                      onClick={() =>
                        dispatch(
                          updateQuestionPageNumber(questionPageNumber + 1),
                        )
                      }
                      className={`flex-none mt-10 sm:w-auto w-full py-4 px-16 uppercase font-semibold  tracking-widest hover:text-white ${
                        resourceQuestionsData.loadingSeeMore &&
                        'pointer-events-none'
                      }`}
                      outlined
                    >
                      {resourceQuestionsData.loadingSeeMore ? (
                        <Loading className="fill-current" />
                      ) : (
                        'See More'
                      )}
                    </Button>
                  </div>
                )}
              </>
            </>
          )}
        </div>
      </>
    )
  }
}
export default QuestionsAns
