const Camera = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={`h-6 w-6 ${className}`}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.82322 4.82322C8.87011 4.77634 8.9337 4.75 9 4.75H15C15.0663 4.75 15.1299 4.77634 15.1768 4.82322C15.2237 4.87011 15.25 4.9337 15.25 5C15.25 5.72935 15.5397 6.42882 16.0555 6.94454C16.5712 7.46027 17.2707 7.75 18 7.75H19C19.3315 7.75 19.6495 7.8817 19.8839 8.11612C20.1183 8.35054 20.25 8.66848 20.25 9V18C20.25 18.3315 20.1183 18.6495 19.8839 18.8839C19.6495 19.1183 19.3315 19.25 19 19.25H5C4.66848 19.25 4.35054 19.1183 4.11612 18.8839C3.8817 18.6495 3.75 18.3315 3.75 18V9C3.75 8.66848 3.8817 8.35054 4.11612 8.11612C4.35054 7.8817 4.66848 7.75 5 7.75H6C6.72935 7.75 7.42882 7.46027 7.94454 6.94454C8.46027 6.42882 8.75 5.72935 8.75 5C8.75 4.9337 8.77634 4.87011 8.82322 4.82322ZM3.05546 7.05546C3.57118 6.53973 4.27065 6.25 5 6.25H6C6.33152 6.25 6.64946 6.1183 6.88388 5.88388C7.1183 5.64946 7.25 5.33152 7.25 5C7.25 4.53587 7.43437 4.09075 7.76256 3.76256C8.09075 3.43438 8.53587 3.25 9 3.25H15C15.4641 3.25 15.9092 3.43438 16.2374 3.76256C16.5656 4.09075 16.75 4.53587 16.75 5C16.75 5.33152 16.8817 5.64946 17.1161 5.88388C17.3505 6.1183 17.6685 6.25 18 6.25H19C19.7293 6.25 20.4288 6.53973 20.9445 7.05546C21.4603 7.57118 21.75 8.27065 21.75 9V18C21.75 18.7293 21.4603 19.4288 20.9445 19.9445C20.4288 20.4603 19.7293 20.75 19 20.75H5C4.27065 20.75 3.57118 20.4603 3.05546 19.9445C2.53973 19.4288 2.25 18.7293 2.25 18V9C2.25 8.27065 2.53973 7.57118 3.05546 7.05546ZM9.75 13C9.75 11.7574 10.7574 10.75 12 10.75C13.2426 10.75 14.25 11.7574 14.25 13C14.25 14.2426 13.2426 15.25 12 15.25C10.7574 15.25 9.75 14.2426 9.75 13ZM12 9.25C9.92893 9.25 8.25 10.9289 8.25 13C8.25 15.0711 9.92893 16.75 12 16.75C14.0711 16.75 15.75 15.0711 15.75 13C15.75 10.9289 14.0711 9.25 12 9.25Z"
      fill="white"
    />
  </svg>
)

export default Camera
