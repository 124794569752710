const Platinum = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    className={`${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.993 1.252a.75.75 0 01.672.418l2.912 5.9 6.51.943a.75.75 0 01.415 1.28l-4.717 4.591 1.112 6.484a.75.75 0 01-1.089.79L12 18.599l-5.823 3.06a.75.75 0 01-1.088-.79L6.2 14.384 1.484 9.792a.75.75 0 01.415-1.28l6.51-.943L11.32 1.67a.75.75 0 01.673-.418zm0 2.445L9.58 8.587a.75.75 0 01-.565.41L3.62 9.78l3.91 3.806a.75.75 0 01.215.664l-.922 5.375 4.827-2.538a.75.75 0 01.699 0l4.812 2.536-.922-5.373a.75.75 0 01.216-.664l3.91-3.806-5.395-.782a.75.75 0 01-.565-.41l-2.413-4.89z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Platinum
