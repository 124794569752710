import { UpArrow } from 'components/icons'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import DashboardNavigation from './DashboardNavigation'
import DashboardSidebar from './DashboardSidebar'
import DownloadApp from 'components/DownloadApp'
import TagManager from 'react-gtm-module'
import AchievementPopover from '../AchievementPopover'
import { setShowCelebrationPopup } from 'redux/profile/personalInfoSlice'
const DashboardLayout = ({ children }) => {
  const history = useHistory()
  const [isVisible, setIsVisible] = useState(false)
  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)

    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [])
  const {
    sidebarExpanded,
    searchText,
    isFullScreen,
    isFullBookScreen,
    userData,
  } = useSelector((state) => ({
    sidebarExpanded: state.dashboard.sidebarExpanded,
    searchText: state.dashboard.searchText,
    isFullScreen: state.reviewQuestions.isFullScreen,
    isFullBookScreen: state.resource.isFullBookScreen,
    userData: state.personalInfo.userData,
  }))

  const { showCelebrationPopup } = useSelector((state) => ({
    showCelebrationPopup: state.personalInfo.showCelebrationPopup,
  }))

  const { pathname, search } = useLocation()

  const searchParamsText = new URLSearchParams(search).get('term')
  const fullScreen = isFullScreen || isFullBookScreen

  const cohortGTMMap = {
    AU: 'GTM-N55L5MP',
    NZ: 'GTM-PG6QKDH',
    UK: 'GTM-KZZRFWF',
    IE: 'GTM-TKX9XHP',
  }

  const gtmId = userData?.website && cohortGTMMap[userData.website]

  useEffect(() => {
    if (gtmId && userData) {
      TagManager.initialize({ gtmId })
    }

    return () => {
      TagManager.dataLayer({ dataLayer: { event: 'cleanup' } })
    }
  }, [gtmId, userData])

  useEffect(() => {
    let timer
    if (searchText && !searchParamsText) {
      timer = setTimeout(() => {
        history.push('/search')
      }, 100)
    } else {
      clearTimeout(timer)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [history, searchParamsText, searchText])

  const dispatch = useDispatch()
  const [achievement, setAchievement] = useState(null)

  // Function to check local storage for achievement data
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkLocalStorageForAchievements = () => {
    const achievementData = localStorage.getItem('celebration')
    if (achievementData) {
      const parsedData = JSON.parse(achievementData)
      if (parsedData && !achievement) {
        setAchievement(parsedData)
        dispatch(setShowCelebrationPopup(true))
      }
    }
  }

  useEffect(() => {
    checkLocalStorageForAchievements()
  }, [checkLocalStorageForAchievements])

  if (
    pathname.split('/')[1] === 'attempt' ||
    pathname.split('/')[1] === 'login' ||
    pathname.split('/')[1] === 'olp-access' ||
    pathname.split('/')[1] === 'ucat-calculator' ||
    pathname.split('/')[1] === 'unsubscribe'
  ) {
    return children
  } else
    return (
      <div className="dashboard">
        <AchievementPopover
          show={showCelebrationPopup}
          onClose={() => {
            dispatch(setShowCelebrationPopup(false))
            localStorage.removeItem('celebration')
            setTimeout(() => {
              setAchievement(null)
            }, 400)
          }}
          achievementData={achievement}
        />
        {!fullScreen && <DashboardSidebar />}
        {!fullScreen && <DashboardNavigation />}
        {!fullScreen && <DownloadApp />}
        <main
          className={`${
            !fullScreen ? (sidebarExpanded ? 'md:ml-64' : 'md:ml-12') : 'ml-0'
          }`}
        >
          <div className={` ${!fullScreen && 'p-4 md:p-8'}  `}>{children}</div>
          <div className="fixed bottom-4 right-2 z-50 flex gap-2">
            <div
              onClick={scrollToTop}
              className={`${isVisible ? 'opacity-100' : 'hidden'}
                right-10 bottom-4 bg-white dark:bg-dark-600 z-20  h-12 w-12 border border-gray-800 dark:border-white rounded-full flex items-center justify-center  cursor-pointer`}
            >
              <UpArrow className="fill-current dark:text-white " />
            </div>
          </div>
        </main>
      </div>
    )
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
}

export default DashboardLayout
