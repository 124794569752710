import { HexagonalOuter, HexagonalInner, Person } from 'components/icons'
import { Tooltip } from 'components/form'

const colors = {
  red: {
    outerColor: 'text-red-100 dark:text-opacity-10',
    innerColor: 'text-red-500 dark:text-red-500 dark:text-opacity-20',
    border: '#F2473A',
  },
  blue: {
    outerColor: 'text-blue-300 dark:text-opacity-10',
    innerColor: 'text-blue-500 dark:text-opacity-20 dark:text-blue-500',
    border: '#2196F3',
  },
  green: {
    outerColor: 'text-green-300 dark:text-opacity-10',
    innerColor: 'text-green-700 dark:text-opacity-20 dark:text-green-700',
    border: '#8AC34A',
  },
  gray: {
    outerColor: 'text-gray-100 dark:text-opacity-10',
    innerColor: 'text-gray-500 dark:text-opacity-20',
    border: '#E0E0E0',
  },
  yellow: {
    outerColor: 'text-yellow-300 dark:text-opacity-10',
    innerColor: 'text-yellow-700 dark:text-opacity-20 dark:text-yellow-700',
    border: '#FFC107',
  },
  orange: {
    outerColor: 'text-orange-300 dark:text-opacity-10',
    innerColor: 'text-orange-700 dark:text-opacity-20 dark:text-orange-700',
    border: '#FF9102',
  },
  bronze: {
    outerColor: 'text-bronze-300 dark:text-opacity-10',
    innerColor: 'text-bronze-700 dark:text-opacity-20 dark:text-bronze-700',
    border: '#A1887F',
  },
}
const smallIconColors = {
  red: {
    class: 'bg-red-100 border-red-500',
  },
  green: {
    class: 'bg-green-300 border-green-700',
  },
  blue: {
    class: 'bg-blue-300 border-blue-500 dark:bg-blue-500  dark:bg-opacity-10',
  },
  orange: {
    class: 'bg-orange-300 border-orange-700',
  },
  gray: {
    class: '',
  },
}
const Hexagon = ({
  color,
  children,
  outlined,
  outlineNone,
  className,
  icon,
  title,
  side,
  smallIcon,
  tooltipText,
  smallIconColor,
  isEmpty,
  minWidth,
  ...props
}) => {
  return (
    <Tooltip
      text={<span className="break-words">{tooltipText}</span>}
      // side={side}
      style={{ display: tooltipText ? '' : 'none' }}
      avoidCollisions={false}
    >
      <div
        style={{ minWidth: minWidth ? minWidth : '140px' }}
        className={`flex flex-col items-center ${className}`}
      >
        <div className="flex items-center justify-center relative w-24 h-24 z-0">
          {smallIcon ? (
            <div className="absolute flex items-center justify-center h-9 w-9 -bottom-1 right-1.5 border border-white bg-white  z-30 rounded-full">
              <div
                className={`h-8 w-8 flex items-center justify-center rounded-full border 
              ${smallIconColors[smallIconColor].class}
              `}
              >
                <img
                  src={smallIcon?.url}
                  className="h-3 w-3"
                  alt={smallIcon?.name}
                />
              </div>
            </div>
          ) : (
            <div className="absolute flex items-center justify-center h-9 w-9 -bottom-1 right-1.5 border border-white bg-white  z-30 rounded-full">
              <div className="h-8 w-8 flex items-center justify-center rounded-full border bg-green-300 border-green-700">
                <Person className="h-3 w-3 fill-current" />
              </div>
            </div>
          )}
          <div className="flex absolute">
            <HexagonalOuter
              className={`h-24 w-24 fill-current ${
                colors[color || 'gray'].outerColor
              }`}
              border={colors[color || 'gray'].border}
            />
          </div>
          {!isEmpty ? (
            <div className="flex absolute z-20">
              <HexagonalInner
                className={`h-12 w-14 fill-current ${
                  colors[color || 'gray'].innerColor
                }`}
              />
            </div>
          ) : (
            ''
          )}
          <div className="flex absolute z-20">{icon}</div>
        </div>
        <p className="text-sm text-gray-900 dark:text-white font-normal mt-4">
          {title}
        </p>
      </div>
    </Tooltip>
  )
}

export default Hexagon
