import React, { useState, useRef, useCallback } from 'react'
import { useEffect } from 'react'
import ReactPlayer from 'react-player'
import 'rc-slider/assets/index.css'
import screenfull from 'screenfull'
import PlayingIcon from 'components/icons/PlayingIcon'
import { Close, Loading } from 'components/icons'
import { useDispatch, useSelector } from 'react-redux'
import VideoPoll from './VideoPoll'
import {
  clearVideoChapterData,
  markVideoViewed,
  resetPollView,
  setChangedDuration,
  setIsFullScreen,
  setIsPIPActive,
  setIsVideoFinished,
  setPlaybackSpeed,
  setPlayedSeconds,
  setSelectedNote,
  setSelectedPoll,
  setSelectedVideoLink,
  setSelectedVideoMedia,
  setStartAutoPlay,
  setSubtitlesEnabled,
  setTranscriptData,
  setTranscriptNoteModal,
  setVideoAutoPlay,
  setVideoNoteList,
  setVolume,
} from 'redux/learn/resourceSlice'
import VideoSlider from 'components/booking/VideoSlider'
import { Switch } from 'components/form'
import { ReactInternetSpeedMeter } from 'react-internet-meter'
import { useLocation } from 'react-router-dom'
import { parseVTT } from 'components/shared/helper'

const LearnVideoPlayer = ({
  chapterableVideo,
  chapterId,
  playerRef,
  tabDataIndex,
  nextChapter,
  videoMarkRead,
}) => {
  const dispatch = useDispatch()
  const {
    resourceData,
    selectedPoll,
    bookDetails,
    videoNoteList,
    playedSeconds,
    playedPIPSeconds,
    isPIPActive,
    selectedVideoMedia,
    isVideoFinished,
    videoAutoPlay,
    startAutoPlay,
    volume,
    playbackSpeed,
    changedDuration,
    autoQuality,
    selectedVideoLink,
    videoPollArray,
    subtitlesEnabled,
    isFullScreen,
  } = useSelector((state) => ({
    resourceData: state.resource.resourceData,
    selectedPoll: state.resource.selectedPoll,
    bookDetails: state.resource.bookDetails,
    videoNoteList: state.resource.videoNoteList,
    playedSeconds: state.resource.playedSeconds,
    playedPIPSeconds: state.resource.playedPIPSeconds,
    isPIPActive: state.resource.isPIPActive,
    selectedVideoMedia: state.resource.selectedVideoMedia,
    isVideoFinished: state.resource.isVideoFinished,
    videoAutoPlay: state.resource.videoAutoPlay,
    startAutoPlay: state.resource.startAutoPlay,
    volume: state.resource.volume,
    playbackSpeed: state.resource.playbackSpeed,
    changedDuration: state.resource.changedDuration,
    autoQuality: state.resource.autoQuality,
    selectedVideoLink: state.resource.selectedVideoLink,
    videoPollArray: state.resource.videoPollArray,
    subtitlesEnabled: state.resource.subtitlesEnabled,
    isFullScreen: state.resource.isFullScreen,
  }))
  const videoRef = useRef(null)
  const [transcriptArray, setTranscriptArray] = useState([])
  // const [subtitlesEnabled, setSubtitlesEnabled] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [captionLabel, setCaptionLabel] = useState('')
  const [isMuted, setIsMuted] = useState(false)
  const [showController, setShowController] = useState(true)
  const [showPIPController, setShowPIPController] = useState(false)
  const [currentSubtitleIndex, setCurrentSubtitleIndex] = useState(null)
  const [isPollModalOpen, setIsPollModalOpen] = useState(false)
  const [isLinkOpen, setIsLinkOpen] = useState(false)

  const [countDownTime, setCountDownTime] = useState(3)
  const [isReady, setIsReady] = React.useState(false)
  const { search } = useLocation()

  const timeStamp = new URLSearchParams(search)?.get('timeStamp')
  const onReady = useCallback(() => {
    if (!isReady && timeStamp) {
      playerRef.current.seekTo(timeStamp, 'seconds')
      setIsReady(true)
    }
  }, [isReady, playerRef, timeStamp])

  useEffect(() => {
    return () => {
      dispatch(clearVideoChapterData())
    }
  }, [dispatch])

  // Handle volume change
  const handleVolumeChange = (newVolume) => {
    if (newVolume === 0) {
      setIsMuted(true)
    } else {
      setIsMuted(false)
    }
    dispatch(setVolume(newVolume))
  }
  const handleToggleFullScreen = () => {
    if (!isPIPActive && screenfull.isEnabled && videoRef.current) {
      screenfull.toggle(videoRef.current)
      dispatch(setIsFullScreen(!isFullScreen))
    }
  }

  const handleToggleVolume = () => {
    setIsMuted(!isMuted)
  }
  const videoUrl = selectedVideoMedia?.url
  // 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
  // 'https://d3c33hcgiwev3.cloudfront.net/_YrrkFfyEeW6YAqJwi1uhQ.processed/full/540p/index.webm?Expires=1688688000&Signature=S4zJAaXIsoRS-BM17l4jT5mODhIeTTDnM5N7ekCrDvwDyFzWC3os2JXukxzLWmiDLHklAAZcFgymzHgTic32XTpd2tQoKI~z0lNuRpgVn~FUisF2HQAeP0WN3P9HXw04BBCASTuIFVYNtkMdSzSGGqyIKumWD8czROUwxib~6k4_&Key-Pair-Id=APKAJLTNE6QMUY6HBC5A'

  const mediaArray = [
    {
      quality: 'Original',
      media: bookDetails?.chapterable?.original_media,
      qualityNumber: 7,
    },
    {
      quality: '2160p',
      media: bookDetails?.chapterable?.quality_6_media,
      qualityNumber: 6,
      optionalQuality: '4K',
    },
    {
      quality: '1440p',
      media: bookDetails?.chapterable?.quality_5_media,
      qualityNumber: 5,
      optionalQuality: 'HD',
    },
    {
      quality: '1080p',
      media: bookDetails?.chapterable?.quality_4_media,
      qualityNumber: 4,
      optionalQuality: 'HD',
    },
    {
      quality: '720p',
      media: bookDetails?.chapterable?.quality_3_media,
      qualityNumber: 3,
    },
    {
      quality: '480p',
      media: bookDetails?.chapterable?.quality_2_media,
      qualityNumber: 2,
    },
    {
      quality: '360p',
      media: bookDetails?.chapterable?.quality_1_media,
      qualityNumber: 1,
    },
  ].filter((item) => item.media !== null)

  useEffect(() => {
    const check720P = checkQualityAvailable(4)
    if (check720P?.media && bookDetails?.chapterable) {
      dispatch(setSelectedVideoMedia(check720P?.media))
    }
  }, [bookDetails?.chapterable, chapterableVideo?.original_media, dispatch])

  const [modalClosedId, setModalClosedId] = useState(null)
  const resetModalClosedId = () => {
    setTimeout(() => {
      setModalClosedId(null)
      dispatch(resetPollView())
    }, 5000)
  }

  useEffect(() => {
    if (modalClosedId !== null) {
      resetModalClosedId()
    }
  }, [modalClosedId])

  // Show Link button
  useEffect(() => {
    let timer
    if (isLinkOpen) {
      timer = setTimeout(() => {
        dispatch(setSelectedVideoLink(null))
        setIsLinkOpen(false)
      }, 5000)
    } else {
      clearTimeout(timer)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [dispatch, isLinkOpen])

  const handleProgress = ({ playedSeconds }) => {
    try {
      if (!isVideoFinished) {
        const threshold = 1 // Adjust this threshold as needed

        const currentLink = bookDetails?.chapterable?.video_chapter_links?.find(
          (link) => Math.abs(playedSeconds - link.video_timestamp) <= threshold,
        )
        if (currentLink && modalClosedId !== currentLink?.id && !isPIPPlaying) {
          setIsLinkOpen(false)
          dispatch(setSelectedVideoLink(currentLink))
          setIsLinkOpen(true)
        }

        const currentCueIndex = transcriptArray.findIndex(
          (cue) => cue.start <= playedSeconds && cue.end >= playedSeconds,
        )
        setCurrentSubtitleIndex(currentCueIndex)
        if (currentCueIndex !== -1) {
          const label = transcriptArray[currentCueIndex].text
          setCaptionLabel(label)
        } else {
          setCaptionLabel('')
        }
        if (changedDuration) {
          dispatch(setPlayedSeconds(changedDuration))
          playerRef.current.seekTo(parseInt(changedDuration))
          dispatch(setChangedDuration(0))
        } else {
          dispatch(setPlayedSeconds(playedSeconds))
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getCurrentSubtitle = (currentTime) => {
    const currentCue = videoNoteList.find(
      (cue) => cue.start <= currentTime && cue.end > currentTime,
    )
    return currentCue ? currentCue : ''
  }

  const handleAddNote = () => {
    const currentTime = playerRef.current.getCurrentTime()
    const currentSubtitle = getCurrentSubtitle(currentTime)
    dispatch(setTranscriptNoteModal(true))
    dispatch(setSelectedNote(currentSubtitle))
  }

  // Transcript

  const loadTranscriptData = (duration) => {
    setDuration(duration)
    const subtitleArray = chapterableVideo?.transcript?.vtt
      ? parseVTT(chapterableVideo?.transcript?.vtt)
      : []

    if (subtitleArray?.length > 0) {
      setTranscriptArray(subtitleArray)
    }
  }

  useEffect(() => {
    if (
      bookDetails?.chapterable?.transcript?.transcribed_paragraphs?.length > 0
    ) {
      const noteArray =
        bookDetails?.chapterable?.transcript?.transcribed_paragraphs
          ?.flat()
          ?.map((item, index) => {
            if (item) {
              return {
                start: parseFloat(item.start_time),
                end: parseFloat(item.end_time),
                text: item.text,
              }
            }
            return null // or handle this case as needed
          })
          .filter((item) => item !== null)

      if (noteArray?.length > 0) {
        dispatch(setVideoNoteList(noteArray))
      }

      const transcriptArray =
        bookDetails?.chapterable?.transcript?.transcribed_paragraphs?.map(
          (paragraphs) => {
            let startTime = parseFloat(paragraphs[0]?.start_time) || 0
            let endTime =
              parseFloat(paragraphs[paragraphs.length - 1]?.end_time) || 0

            const textArray = paragraphs.map((item) => {
              const start = parseFloat(item.start_time) || 0
              const end = parseFloat(item.end_time) || 0
              const text = item.text || ''
              endTime = Math.max(endTime, end) // Update the end time if needed
              return { start, end, text }
            })

            return { start: startTime, end: endTime, text: textArray }
          },
        )

      if (transcriptArray?.length > 0) {
        dispatch(setTranscriptData(transcriptArray))
      }
    }
  }, [bookDetails?.chapterable?.transcript?.transcribed_paragraphs, dispatch])

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying)
    setIsPausePIP(false)
  }

  const handleToggleSubtitles = () => {
    dispatch(setSubtitlesEnabled(!subtitlesEnabled))
  }

  const handleMouseMove = () => {
    setShowController(true)
  }

  const handleMouseLeave = () => {
    setShowController(false)
  }

  const handlePIPMouseMove = () => {
    setShowPIPController(true)
  }

  const handlePIPMouseLeave = () => {
    setShowPIPController(false)
  }

  // -----------------------------------
  const pipVideoRef = useRef(null)
  const [isPIPPlaying, setIsPIPPlaying] = useState(false)
  const [isPIPPlayingClick, setIsPIPPlayingClick] = useState(false)
  const [isPausePIP, setIsPausePIP] = useState(false)

  const [isVisible, setIsVisible] = useState(false)
  const [exitPIP, setExitPIP] = useState(false)

  const handlePIPPlayPause = () => {
    setIsPIPPlaying(!isPIPPlaying)
    setIsPausePIP(true)
  }

  const handleTogglePIP = () => {
    dispatch(setIsPIPActive(!isPIPActive))
    setIsPlaying(false)
  }

  useEffect(() => {
    if (exitPIP && isVisible) {
      setIsPlaying(true)
      setExitPIP(false)
    }
  }, [exitPIP, isVisible])

  useEffect(() => {
    if (isPIPPlayingClick && pipVideoRef?.current?.player) {
      setIsPlaying(false)
      setIsPIPPlaying(true)
      pipVideoRef?.current?.seekTo(parseFloat(playedSeconds))
    }
  }, [isPIPPlayingClick, playedSeconds])

  const marks = {} // Initialize an empty object for marks
  bookDetails?.chapterable?.polls?.forEach((poll) => {
    // const value = parseFloat(poll.video_timestamp)
    // const pollTime = isNaN(value) ? duration : Math.min(value, duration)

    marks[duration - 0.5] = {
      label: (duration - 0.5).toString(),
    }
  })

  useEffect(() => {
    const exitFullscreenHandler = () => {
      if (!document.fullscreenElement && !document.webkitIsFullScreen) {
        // Fullscreen mode has been exited
        // Your code to handle the exit can go here
        dispatch(setIsFullScreen(false))
      }
    }

    document.addEventListener('fullscreenchange', exitFullscreenHandler)
    document.addEventListener('webkitfullscreenchange', exitFullscreenHandler)

    return () => {
      // Cleanup: Remove the event listeners when the component unmounts
      document.removeEventListener('fullscreenchange', exitFullscreenHandler)
      document.removeEventListener(
        'webkitfullscreenchange',
        exitFullscreenHandler,
      )
    }
  }, [dispatch])

  const [bufferLoading, setBufferLoading] = useState(false)

  const handleBuffer = () => {
    setBufferLoading(true)
  }

  const handleBufferEnd = () => {
    setBufferLoading(false)
  }

  const [checkSpeed, SetCheckSpeed] = React.useState(0)

  const handleQualityChange = (media) => {
    if (media?.id !== selectedVideoMedia?.id) {
      dispatch(setSelectedVideoMedia(media))
      dispatch(setChangedDuration(playedSeconds))
    }
  }

  const checkQualityAvailable = (qualityNumber) => {
    const media = mediaArray
      .filter((data) => data.qualityNumber < qualityNumber)
      .sort((a, b) => b.qualityNumber - a.qualityNumber)
      .shift()

    return media || mediaArray[mediaArray?.length - 1] || mediaArray[0]
  }

  useEffect(() => {
    const checkInternetSpeed = async () => {
      try {
        const networkSpeed = parseFloat(checkSpeed)
        if (networkSpeed < 5) {
          const check360P = checkQualityAvailable(2)
          if (check360P?.media) {
            handleQualityChange(check360P?.media)
          }
        }
        //  else if (networkSpeed > 3 && networkSpeed < 5) {
        //   const check480P = checkQualityAvailable(2)
        //   if (check480P?.media) {
        //     handleQualityChange(check480P?.media)
        //   }
        // }
        else if (networkSpeed > 5 && networkSpeed < 25) {
          const check720P = checkQualityAvailable(4)
          if (check720P?.media) {
            handleQualityChange(check720P?.media)
          }
        }
        // else if (networkSpeed > 20 && networkSpeed < 30) {
        //   const check1080P = checkQualityAvailable(4)
        //   if (check1080P) {
        //     handleQualityChange(check1080P?.media)
        //   }
        // }
        else if (networkSpeed > 25) {
          const check1440P = checkQualityAvailable(6)
          if (check1440P) {
            handleQualityChange(check1440P?.media)
          }
        }
        // else if (networkSpeed > 30) {
        //   const check2160P = checkQualityAvailable(6)
        //   if (check2160P) {
        //     handleQualityChange(check2160P?.media)
        //   }
        // }
      } catch (error) {
        console.error('Error checking internet speed', error)
      }
    }

    if (autoQuality && isPlaying) {
      checkInternetSpeed()
    }
  }, [
    autoQuality,
    dispatch,
    mediaArray,
    selectedVideoMedia,
    checkSpeed,
    isPlaying,
  ])

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: isPIPActive ? 1 : 0.4,
    }

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting)
      })
    }

    const observer = new IntersectionObserver(handleIntersection, options)

    if (videoRef.current) {
      observer.observe(videoRef.current)
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current)
      }
    }
  }, [isPIPActive, videoRef])

  useEffect(() => {
    if (isVisible) {
      if (isPIPActive) {
        dispatch(setIsPIPActive(false))
        setIsPIPPlayingClick(false)
      }
    } else {
      if (!isPIPActive && !isPIPPlaying && isPlaying && !isVideoFinished) {
        dispatch(setIsPIPActive(true))
      }
    }
  }, [
    dispatch,
    isPIPActive,
    isPIPPlaying,
    isPausePIP,
    isPlaying,
    isVideoFinished,
    isVisible,
    playedPIPSeconds,
    playerRef,
  ])

  const chapters = resourceData?.data?.book?.chapters
  const isLastChapter = tabDataIndex === chapters?.length - 1
  const nextChapterData =
    chapters && !isLastChapter && chapters[tabDataIndex + 1]

  useEffect(() => {
    if (
      !isPollModalOpen &&
      isVideoFinished &&
      videoAutoPlay &&
      countDownTime === 0
    ) {
      dispatch(setStartAutoPlay(true))
      setCountDownTime(3)
      nextChapter && nextChapter()
    }
  }, [
    countDownTime,
    dispatch,
    isPollModalOpen,
    isVideoFinished,
    nextChapter,
    videoAutoPlay,
  ])

  // autoplay video if videoAutoPlay is true
  useEffect(() => {
    if (
      !isVideoFinished &&
      videoAutoPlay &&
      startAutoPlay &&
      playedSeconds === 0
    ) {
      dispatch(setStartAutoPlay(false))
      setIsPlaying(true)
    }
  }, [
    countDownTime,
    dispatch,
    isVideoFinished,
    playedSeconds,
    startAutoPlay,
    videoAutoPlay,
  ])

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (
        isVideoFinished &&
        videoAutoPlay &&
        !isPollModalOpen &&
        countDownTime > 0
      ) {
        setCountDownTime((time) => time - 1)
      }
    }, 1000)
    return () => {
      clearInterval(countdownInterval)
    }
  }, [countDownTime, dispatch, isPollModalOpen, isVideoFinished, videoAutoPlay])

  return (
    <div>
      {autoQuality && isPlaying && (
        <ReactInternetSpeedMeter
          outputType="" // "alert"/"modal"/"empty"
          customClassName={null}
          pingInterval={5000} // milliseconds
          txtMainHeading="Opps..."
          thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
          threshold={50}
          imageUrl="https://masterucat.medentry.edu.au/favicon.png"
          downloadSize="1561257" //bytes
          callbackFunctionOnNetworkTest={(data) => SetCheckSpeed(data)}
        />
      )}

      <div className="relative h-full px-6">
        <div
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          className="relative"
          onClick={() => {
            !isVideoFinished && !isPollModalOpen && handlePlayPause()
          }}
          ref={!isPIPActive ? videoRef : undefined}
          onContextMenu={(e) => e.preventDefault()}
        >
          {isPollModalOpen && (
            <VideoPoll
              selectedPoll={selectedPoll}
              setIsPollModalOpen={setIsPollModalOpen}
              setModalClosedId={setModalClosedId}
              chapterId={chapterId}
              onClosePoll={() => {
                dispatch(setIsVideoFinished(true))
                setIsPlaying(false)
                if (!bookDetails?.user_read) {
                  dispatch(
                    markVideoViewed({
                      chapterId: bookDetails?.id,
                      videoId: bookDetails?.chapterable?.id,
                    }),
                  )
                }
              }}
            />
          )}

          <div className={`flex justify-center h-full`}>
            <div
              className={`video-player flex items-center flex-col h-full bg-gray-900 ${
                isPollModalOpen ? 'pointer-events-none opacity-40' : ''
              } ${isVideoFinished ? 'pointer-events-none' : ''} ${
                isFullScreen || isPIPActive ? '' : 'sm:w-11/12'
              }  ${isFullScreen ? 'justify-center h-full' : ''}`}
              style={
                isPIPActive
                  ? {
                      position: 'fixed',
                      bottom: '10px',
                      right: '10px',
                      zIndex: 9999,
                      width: '350px',
                      height: 'auto',
                    }
                  : {}
              }
              onMouseMove={() => {
                isPIPActive && handlePIPMouseMove()
              }}
              onMouseLeave={() => {
                isPIPActive && handlePIPMouseLeave()
              }}
            >
              <ReactPlayer
                onBuffer={handleBuffer}
                onBufferEnd={handleBufferEnd}
                ref={playerRef}
                url={videoUrl}
                loop={false}
                controls={false}
                onProgress={handleProgress}
                onDuration={loadTranscriptData}
                playbackRate={playbackSpeed}
                playing={isPlaying}
                onDoubleClick={() => {
                  handleToggleFullScreen()
                }}
                onEnded={() => {
                  if (videoPollArray?.find((poll) => !poll?.isViewed)) {
                    const currentPoll = videoPollArray?.find(
                      (poll) => !poll?.isViewed,
                    )
                    if (
                      currentPoll &&
                      modalClosedId !== currentPoll?.id &&
                      !isPIPPlaying
                    ) {
                      dispatch(setSelectedPoll(currentPoll))
                      setIsPlaying(false)
                      setIsPollModalOpen(true)
                    } else {
                      setIsPollModalOpen(false)
                    }
                  } else {
                    if (!isVideoFinished) {
                      dispatch(setIsVideoFinished(true))
                      setIsPlaying(false)
                      if (!bookDetails?.user_read) {
                        dispatch(
                          markVideoViewed({
                            chapterId: bookDetails?.id,
                            videoId: bookDetails?.chapterable?.id,
                          }),
                        )
                      }
                    }
                  }

                  if (isPIPActive) {
                    dispatch(setIsPIPActive(false))
                    setIsPIPPlayingClick(false)
                  }
                }}
                config={{
                  file: {
                    attributes: {
                      crossOrigin: 'true',
                      disablePictureInPicture: true,
                    },
                  },
                }}
                width="100%"
                height={isFullScreen ? '100%' : '100%'}
                volume={volume}
                muted={isMuted}
                onReady={onReady}
              />
              {isLinkOpen && selectedVideoLink && (
                <div className="absolute top-2 right-10">
                  <a
                    onClick={() => {
                      setTimeout(() => {
                        setIsPlaying(false)
                      }, 10)
                    }}
                    href={selectedVideoLink?.url || '#'}
                    className="text-xs font-semibold tracking-widest text-white px-4 py-2 rounded-md bg-red-500 hover:bg-red-700 uppercase"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: 'text-white !important' }}
                  >
                    {selectedVideoLink?.action_text}
                  </a>
                </div>
              )}
              {selectedVideoMedia &&
                !isVideoFinished &&
                !isPIPActive &&
                !isPollModalOpen && (
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    {!isPlaying && (
                      <div
                        onClick={handlePlayPause}
                        className="bg-red-500 h-16 w-16 rounded-full flex items-center justify-center cursor-pointer"
                      >
                        <PlayingIcon className="h-6 w-6 text-white ml-1" />
                      </div>
                    )}
                  </div>
                )}
              {/* Video Finished */}
              {bufferLoading && isPlaying && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <Loading className="h-10 w-10 text-gray-300 bg-black opacity-25 rounded-full p-1" />
                </div>
              )}
              {/* Caption */}
              {playedSeconds > 0 && subtitlesEnabled && captionLabel && (
                <div
                  className={`absolute bottom-11 left-0 w-full text-center font-semibold text-white ${
                    isPIPActive
                      ? 'text-xs'
                      : isFullScreen
                      ? 'text-xl 3xl:text-4xl'
                      : 'text-base sm:text-lg md:text-base lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-3xl'
                  }`}
                >
                  <span className="bg-black bg-opacity-50 w-auto px-1">
                    {transcriptArray[currentSubtitleIndex]?.text}
                  </span>
                </div>
              )}
              {/* Controls */}
              {selectedVideoMedia && (
                <VideoSlider
                  playerRef={playerRef}
                  marks={marks}
                  isFullScreen={isFullScreen}
                  duration={duration}
                  handlePlayPause={handlePlayPause}
                  isPlaying={isPlaying}
                  volume={volume}
                  handleToggleVolume={handleToggleVolume}
                  handleVolumeChange={handleVolumeChange}
                  handleToggleFullScreen={handleToggleFullScreen}
                  handleToggleSubtitles={handleToggleSubtitles}
                  playbackSpeed={playbackSpeed}
                  subtitlesEnabled={subtitlesEnabled}
                  setPlaybackSpeed={(speed) => {
                    dispatch(setPlaybackSpeed(speed))
                  }}
                  handleAddNote={handleAddNote}
                  transcriptArray={transcriptArray}
                  currentSubtitleIndex={currentSubtitleIndex}
                  mediaArray={mediaArray}
                  isPIPActive={isPIPActive}
                  isMuted={isMuted}
                  playedSeconds={playedSeconds}
                />
              )}
              {isPIPActive && showPIPController && (
                <button
                  className="text-white absolute top-1 right-1 bg-black rounded bg-opacity-25"
                  onClick={handleTogglePIP}
                >
                  <Close className="text-white h-5 w-5" />
                </button>
              )}
            </div>
          </div>

          {isVideoFinished && (
            <div className="absolute w-full h-full bg-black bg-opacity-70 top-0">
              <div className="flex justify-center items-center h-full flex-col gap-4">
                <div
                  onClick={() => {
                    if (!videoAutoPlay || !countDownTime) {
                      dispatch(setStartAutoPlay(true))
                      nextChapter && nextChapter()
                    }
                  }}
                  className="bg-red-500 h-11 w-11 rounded-full flex items-center justify-center cursor-pointer flex-shrink-0"
                >
                  {videoAutoPlay && countDownTime ? (
                    <span className="font-semibold text-white">
                      {countDownTime}
                    </span>
                  ) : (
                    <span>
                      <PlayingIcon className="h-3 w-3 text-white ml-0.5" />
                    </span>
                  )}
                </div>
                <div className="text-sm text-gray-200">Up Next</div>
                <div className="font-semibold text-sm sm:text-xl text-white">
                  {!isLastChapter
                    ? nextChapterData?.name
                    : 'You have completed this module'}
                </div>
                <button
                  onClick={() => {
                    dispatch(setIsVideoFinished(false))
                    playerRef.current.seekTo(parseInt(0))
                    setIsPlaying(true)
                  }}
                  className="text-sm text-red-500 font-semibold tracking-widest uppercase text-"
                >
                  Replay
                </button>
                <div className="text-white ">
                  <Switch
                    className=""
                    label="Autoplay"
                    name="random"
                    onChange={(e) => {
                      if (e) {
                        setCountDownTime(3)
                      } else {
                        setCountDownTime(0)
                      }
                      dispatch(setVideoAutoPlay(e))
                    }}
                    labelHeight="text-xl"
                    labelTextColor="text-white"
                    enabled={videoAutoPlay}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {isPIPActive ? (
          <div
            className="flex items-center justify-center text-white font-bold text-xl bg-black bg-opacity-70"
            style={{
              width: '90%',
              height: '400px',
            }}
          >
            Picture in Picture mode is on
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default LearnVideoPlayer
