import { useField } from 'formik'
import PropTypes from 'prop-types'

const MRadio = ({
  hideLabel,
  name,
  label,
  helpText,
  focus,
  className,
  ...props
}) => {
  const [inputProps, { error }, { setValue }] = useField(name)
  return (
    <>
      <div className="">
        {!hideLabel && (
          <div className="text-sm text-gray-700 dark:text-dark-100">
            {label}
          </div>
        )}
        <input
          className={`p-2 focus:ring-0 focus:ring-offset-0  border rounded-md border-gray-500 dark:border-dark-400 hover:border-gray-700  text-gray-900 dark:text-white w-full ${
            error && 'border-red-600 dark:bg-dark-500'
          } ${className}`}
          {...inputProps}
          {...props}
          onChange={(event) => setValue(event.target.value)}
        />
        {helpText && (
          <div className="mt-1 text-sm text-gray-700">{helpText}</div>
        )}
      </div>
    </>
  )
}

MRadio.propTypes = {
  hideLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
  error: PropTypes.string,
}

export default MRadio
