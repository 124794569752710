import { useState, useEffect } from 'react'
import { Edit, Delete, Dairy, Loading, Play } from 'components/icons'
import { Button, Spinner } from 'components/shared'
import { Dropdown, MTextarea, Modal } from 'components/form'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import {
  createNote,
  deleteNote,
  getNotesData,
  setNoteDeleteId,
  setSelectedRecent,
  updateNote,
  updatePageNumber,
} from 'redux/learn/notesSlice'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { DeletePopUp } from '../popup'
import { NoRecord } from 'components/no-record'
import { formatTimestamp, resourceName } from 'components/shared/helper'
import {
  deleteTranscriptNotes,
  setDeleteId,
  setIsVideoFinished,
  setPlayedSeconds,
  setSelectedNote,
  setTranscriptNoteModal,
} from 'redux/learn/resourceSlice'

const noteSchema = Yup.object({
  content: Yup.string()
    .required('Note is required')
    .max(2000, `The content may not be greater than 2000 characters.`),
})

const updateNoteSchema = Yup.object({
  updateContent: Yup.string()
    .required('Note is required')
    .max(2000, `The content may not be greater than 2000 characters.`),
})

const Notes = ({
  noteId,
  noteType,
  noteSlug,
  bookId,
  questionId,
  playerRef,
  noNotesMessage,
}) => {
  const current = [
    { id: noteId, name: 'Current Chapter', slug: noteSlug },
    { id: bookId, name: `Current ${resourceName}`, slug: 'book_id' },
  ]
  const recent = [
    { id: 1, name: 'Sort by Most Recent', sortBy: 'recent' },
    { id: 2, name: 'Sort by Oldest', sortBy: 'oldest' },
  ]

  let [textareaOpen, setTextareaClose] = useState(null)
  const [selectedCurrent, setSelectedCurrent] = useState(current[0])
  const dispatch = useDispatch()
  const {
    notesData,
    createNoteLoader,
    deleteNotePopup,
    deleteNoteLoader,
    updateNoteLoader,
    notesPageNumber,
    selectedRecent,
    bookDetails,
    deleteId,
    deleteLoader,
  } = useSelector((state) => ({
    notesData: state.notes.notesData,
    createNoteLoader: state.notes.createNoteLoader,
    deleteNotePopup: state.notes.deleteNotePopup,
    deleteNoteLoader: state.notes.deleteNoteLoader,
    updateNoteLoader: state.notes.updateNoteLoader,
    notesPageNumber: state.notes.notesPageNumber,
    selectedRecent: state.notes.selectedRecent,
    bookDetails: state.resource.bookDetails,
    deleteId: state.resource.deleteId,
    deleteLoader: state.resource.deleteLoader,
  }))

  const controller = new AbortController()

  useEffect(() => {
    dispatch(
      getNotesData({
        noteSlug: selectedCurrent.slug,
        id: questionId ? noteId : selectedCurrent.id,
        sortBy: selectedRecent.sortBy,
        page: notesPageNumber,
        abortController: controller,
      }),
    )
    return () => {
      controller.abort()
    }
  }, [
    dispatch,
    questionId,
    noteId,
    selectedCurrent,
    selectedRecent,
    notesPageNumber,
  ])

  useEffect(() => {
    return () => {
      dispatch(updatePageNumber(1))
    }
  }, [dispatch])

  function openTextarea(id) {
    setTextareaClose(id)
  }
  function closeTextarea() {
    setTextareaClose(null)
  }

  const transcriptNotes = bookDetails?.chapterable?.transcript_notes || []

  return (
    <div>
      <Modal
        open={deleteId ? true : false}
        title="Delete Note?"
        content={
          <div className="mt-4 text-base font-normal text-gray-900 dark:text-white">
            Are you sure you want to delete this Note?
          </div>
        }
        buttonText="Yes"
        closeButtonText="No"
        onSuccess={() =>
          dispatch(
            deleteTranscriptNotes({
              chapterId: bookDetails?.id,
              noteId: deleteId,
            }),
          )
            .unwrap()
            .then(() => {
              toast.success('Transcript Note deleted successfully', {
                duration: 4000,
                position: 'top-center',
                style: {
                  backdropFilter: 'blur(4px)',
                  marginTop: '20px',
                  width: '350px',
                },
              })
            })
        }
        onClose={() => {
          dispatch(setDeleteId(null))
        }}
        isLoading={deleteLoader}
      />
      <div>
        <Formik
          initialValues={{
            content: '',
          }}
          validationSchema={noteSchema}
          onSubmit={(values, { resetForm, setErrors, setFieldError }) => {
            dispatch(
              createNote({
                data: {
                  type: noteType,
                  type_id: noteId,
                  content: values.content,
                },
                onSuccess: () => {
                  resetForm()
                },
                onError: (errorsMassage) => {
                  toast.error(errorsMassage.message, {
                    duration: 3000,
                    position: 'top-center',
                    style: {
                      backdropFilter: 'blur(4px)',
                      marginTop: '20px',
                      width: '350px',
                    },
                  })
                  Object.keys(errorsMassage.errors).forEach((error) =>
                    setFieldError(error, errorsMassage[error]),
                  )
                },
              }),
            )
          }}
        >
          {({ values, handleSubmit, setFieldValue, setFieldError }) => (
            <Form onSubmit={handleSubmit}>
              <div className="mt-6">
                <MTextarea
                  label="Note"
                  name="content"
                  rows="4"
                  cols="40"
                  placeholder="e.g. Example goes here"
                  className="mt-1 border-text-500 w-full  border border-gray-500 dark:border-dark-400 dark:bg-dark-500 dark:text-white rounded-md px-3 py-4"
                />
                <div className="mt-6 ">
                  <Button
                    type="submit"
                    className={`px-6 py-4 sm:w-auto w-full font-semibold tracking-widest ${
                      createNoteLoader && 'pointer-events-none'
                    }`}
                  >
                    {createNoteLoader ? (
                      <Loading className="text-white" />
                    ) : (
                      'Add Note'
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        {notesData?.data?.notes?.length > 0 && (
          <div className=" sm:flex mt-10 w-full space-y-2 space-x-0 sm:space-y-0 sm:space-x-4 ">
            {selectedCurrent && selectedCurrent.name && (
              <div className="relative w-full">
                {noteType !== 'scorm' && noteType !== 'question' && (
                  <Dropdown
                    value={selectedCurrent}
                    onChange={(value) => {
                      dispatch(updatePageNumber(1))
                      setSelectedCurrent(value)
                    }}
                    options={current}
                    optionLabelField="name"
                  />
                )}
              </div>
            )}
            <div className="relative w-full">
              <Dropdown
                value={selectedRecent}
                onChange={(value) => {
                  dispatch(updatePageNumber(1))
                  dispatch(setSelectedRecent(value))
                }}
                options={recent}
                optionLabelField="name"
              />
            </div>
          </div>
        )}
      </div>
      {notesData && notesData.isLoading ? (
        <div className="my-4">
          <Spinner />
        </div>
      ) : (
        <div className="w-full mt-4">
          {notesData?.data?.notes?.length > 0
            ? notesData?.data?.notes?.map((note, noteIndex) => {
                const transcriptNote = note?.location_breadcrumbs?.find(
                  (breadcrumb) => breadcrumb?.type === 'transcript_position',
                )
                return (
                  <div
                    key={noteIndex}
                    className={`border-t dark:border-dark-500 py-4 dark:text-white`}
                  >
                    <div className="">
                      <div className=" dark:border-dark-500 border-gray-200 w-full border-text-300">
                        <div className="flex">
                          <div className="items-start">
                            <div className="mt-1">
                              <Dairy className="dark:text-white" />
                            </div>
                          </div>
                          <div className=" ml-4 w-full">
                            <div className="text-base font-semibold text-gray-900 dark:text-white">
                              {note?.location_breadcrumbs
                                ?.filter((data) => data.name)
                                ?.map((data) => data.name)
                                .join(' / ')}
                            </div>
                            <div className="text-base mt-2 text-gray-900 dark:text-white">
                              <div
                                className={
                                  textareaOpen === note.id ? 'hidden' : ''
                                }
                              >
                                {note.content}
                              </div>
                            </div>
                            <span className="bg-red-500 bg-opacity-20 ">
                              {transcriptNote?.data?.transcript_text}
                            </span>
                            <Formik
                              initialValues={{
                                updateContent: note.content,
                              }}
                              validationSchema={updateNoteSchema}
                              onSubmit={(values, { setFieldError }) => {
                                dispatch(
                                  updateNote({
                                    data: {
                                      type: noteType,
                                      noteId: textareaOpen,
                                      content: values.updateContent,
                                    },
                                    onSuccess: () => {
                                      closeTextarea()
                                    },
                                    onError: (errorsMassage) => {
                                      toast.error(errorsMassage.message, {
                                        duration: 3000,
                                        position: 'top-center',
                                        style: {
                                          backdropFilter: 'blur(4px)',
                                          marginTop: '20px',
                                          width: '350px',
                                        },
                                      })
                                      Object.keys(errorsMassage.errors).forEach(
                                        (error) =>
                                          setFieldError(
                                            error,
                                            errorsMassage[error],
                                          ),
                                      )
                                    },
                                  }),
                                )
                              }}
                            >
                              {({
                                values,
                                handleSubmit,
                                setFieldValue,
                                setFieldError,
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                  <div
                                    className={
                                      textareaOpen !== note.id
                                        ? 'hidden'
                                        : 'mt-4'
                                    }
                                  >
                                    <MTextarea
                                      name="updateContent"
                                      type="text"
                                      rows="4"
                                      cols="40"
                                      value={values.updateContent}
                                      className="mt-1  w-full  border border-gray-500 dark:border-dark-400 dark:bg-dark-500 dark:text-white rounded-md px-3 py-4"
                                    ></MTextarea>
                                    <div className="flex mt-4  float-left">
                                      <button
                                        type="submit"
                                        className={`pr-4 text-red-400 font-semibold text-xs ${
                                          updateNoteLoader &&
                                          'pointer-events-none'
                                        }`}
                                      >
                                        {updateNoteLoader ? (
                                          <Loading />
                                        ) : (
                                          'SAVE'
                                        )}
                                      </button>

                                      <button
                                        type="button"
                                        onClick={closeTextarea}
                                        className="text-red-400 font-semibold text-xs"
                                      >
                                        CANCEL
                                      </button>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                            {/*  */}
                            <div
                              className={`flex  items-center ${
                                note?.resource_type === 'TranscriptPosition'
                                  ? 'justify-between'
                                  : 'justify-end'
                              }`}
                            >
                              {note?.resource_type === 'TranscriptPosition' ? (
                                <div className="mt-2 flex gap-2 dark:text-white">
                                  <div
                                    onClick={() => {
                                      playerRef.current.seekTo(
                                        parseFloat(
                                          transcriptNote?.data
                                            ?.transcript_start_time,
                                        ),
                                      )
                                      dispatch(setIsVideoFinished(false))
                                      dispatch(
                                        setPlayedSeconds(
                                          transcriptNote?.data
                                            ?.transcript_start_time,
                                        ),
                                      )
                                      const res =
                                        document?.querySelector('.video-player')
                                      res?.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'start',
                                        inline: 'start',
                                      })
                                    }}
                                    className="flex items-center gap-2 cursor-pointer"
                                  >
                                    <Play />
                                    <span className="text-red-400 font-semibold text-xs uppercase tracking-wider ">
                                      Video Clip
                                    </span>
                                  </div>
                                  <span>
                                    {' '}
                                    {formatTimestamp(
                                      transcriptNote?.data
                                        ?.transcript_start_time,
                                    )}{' '}
                                    :{' '}
                                    {formatTimestamp(
                                      transcriptNote?.data?.transcript_end_time,
                                    )}
                                  </span>
                                </div>
                              ) : (
                                ''
                              )}
                              <div
                                className={`${'flex float-right mt-3  items-center'}`}
                              >
                                <div
                                  className={
                                    textareaOpen === note.id
                                      ? 'hidden'
                                      : 'sm:mr-3 mr-2'
                                  }
                                >
                                  <button
                                    onClick={() => {
                                      if (
                                        note.resource_type ===
                                        'TranscriptPosition'
                                      ) {
                                        dispatch(
                                          setSelectedNote({
                                            type: note.resource_type,
                                            id: note?.id,
                                            note: note?.content,
                                            start:
                                              transcriptNote?.data
                                                ?.transcript_start_time,
                                            end: transcriptNote?.data
                                              ?.transcript_end_time,
                                            text: transcriptNote?.data
                                              ?.transcript_text,
                                            isEdit: true,
                                          }),
                                        )
                                        dispatch(setTranscriptNoteModal(true))
                                      } else {
                                        openTextarea(note.id)
                                      }
                                    }}
                                  >
                                    <Edit className="dark:text-white" />
                                  </button>
                                </div>
                                <div className="relative">
                                  <button
                                    onClick={() => {
                                      dispatch(
                                        setNoteDeleteId({
                                          id: note.id,
                                          show: true,
                                        }),
                                      )
                                    }}
                                  >
                                    <Delete className="dark:text-white" />
                                  </button>
                                  {deleteNotePopup.show &&
                                    deleteNotePopup.id === note.id && (
                                      <DeletePopUp
                                        deleteNotePopup={deleteNotePopup}
                                        deleteNoteLoader={deleteNoteLoader}
                                        closeModel={() =>
                                          dispatch(
                                            setNoteDeleteId({
                                              id: null,
                                              show: false,
                                            }),
                                          )
                                        }
                                        deleteNote={() =>
                                          dispatch(
                                            deleteNote({
                                              noteId: deleteNotePopup.id,
                                            }),
                                          )
                                        }
                                      />
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            : transcriptNotes?.length === 0 && (
                <div className="my-6">
                  <NoRecord
                    title="0 Notes"
                    subTitle={
                      noNotesMessage ||
                      'You have not recorded any notes for this resource yet'
                    }
                  />
                </div>
              )}
          {notesData?.pagination?.page_number !==
            notesData?.pagination?.last_page_number && (
            <div className="flex justify-center items-center pb-4">
              <Button
                className={`py-4 px-6 font-semibold tracking-widest ${
                  notesData.loadingSeeMore && 'pointer-events-none'
                }`}
                onClick={() => dispatch(updatePageNumber(notesPageNumber + 1))}
                outlined
              >
                {notesData.loadingSeeMore ? (
                  <Loading className=""></Loading>
                ) : (
                  'See More'
                )}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Notes
