import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getTermsAndConditions = createAsyncThunk(
  'termsAndConditions/getTermsAndConditions',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('terms-conditions/site')
      return res
    } catch (error) {
      throw rejectWithValue(error.response.data)
    }
  },
)

export const markSitewideAgreement = createAsyncThunk(
  'termsAndConditions/markSitewideAgreement',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        '/terms-conditions/accept?area=sitewide',
      )
      return res
    } catch (error) {
      throw rejectWithValue(error)
    }
  },
)

export const markUniSpecificAgreement = createAsyncThunk(
  'termsAndConditions/markUniSpecificAgreement',
  async ({ area, subarea }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        '/terms-conditions/accept',
        {},
        { params: { area, subarea } },
      )
      return res
    } catch (error) {
      throw rejectWithValue(error)
    }
  },
)
export const termsAndConditionsSlice = createSlice({
  name: 'termsAndConditions',
  initialState: {
    termsAndConditionsData: { isLoading: false, data: [] },
    sitewideLoading: false,
    userAgreedTerms: false,
    uniSpecificLoading: false,
  },
  extraReducers: {
    [getTermsAndConditions.pending]: (state, action) => {
      state.termsAndConditionsData.isLoading = true
    },
    [getTermsAndConditions.fulfilled]: (state, { meta, payload }) => {
      state.termsAndConditionsData.isLoading = false
      state.termsAndConditionsData.data = payload.data
      if (payload?.data?.requires_agreement === false) {
        sessionStorage.setItem('isAgreed', true)
      }
    },
    [getTermsAndConditions.rejected]: (state, action) => {
      state.termsAndConditionsData.isLoading = false
    },
    [markSitewideAgreement.pending]: (state, action) => {
      state.sitewideLoading = true
    },
    [markSitewideAgreement.fulfilled]: (state, { meta, payload }) => {
      state.sitewideLoading = false
      state.termsAndConditionsData.data.requires_agreement = false
      sessionStorage.setItem('isAgreed', true)
    },
    [markSitewideAgreement.rejected]: (state, action) => {
      state.sitewideLoading = false
    },
    [markUniSpecificAgreement.pending]: (state, action) => {
      state.uniSpecificLoading = true
    },
    [markUniSpecificAgreement.fulfilled]: (state, { meta, payload }) => {
      state.uniSpecificLoading = false
      state.userAgreedTerms = false
    },
    [markUniSpecificAgreement.rejected]: (state, action) => {
      state.uniSpecificLoading = false
    },
  },
  reducers: {
    setUserAgreedTerms: (state, { payload }) => {
      state.userAgreedTerms = payload
    },
  },
})

export const { setUserAgreedTerms } = termsAndConditionsSlice.actions

export default termsAndConditionsSlice.reducer
