const ChevronLeft = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.687 4.98a.5.5 0 010 .707L7.374 8l2.313 2.313a.5.5 0 11-.707.707L6.313 8.354a.5.5 0 010-.708L8.98 4.98a.5.5 0 01.707 0z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default ChevronLeft
