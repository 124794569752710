import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const startNumericKeypad = createAsyncThunk(
  'numericKeypad/startNumericKeypad',
  async ({ difficulty, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `trainer/numeric-keypad/start-attempt`,
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const submitNumericKeypad = createAsyncThunk(
  'numericKeypad/submitNumericKeypad',
  async (
    { id, time_taken, num_keystrokes_correct, num_keystrokes_total, onSuccess },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.post(
        `trainer/numeric-keypad/${id}/submit-attempt`,
        {
          time_taken: time_taken,
          num_keystrokes_correct: num_keystrokes_correct,
          num_keystrokes_total: num_keystrokes_total,
        },
        { headers: { notToRedirect404: true } },
      )
      onSuccess && onSuccess()
      return res
    } catch (error) {
      throw rejectWithValue(error.response.data)
    }
  },
)

export const numericKeypadSlice = createSlice({
  name: 'numericKeypad',
  initialState: {
    isLoadingNumericData: false,
    numericKeypadData: [],
    disableSubmitStatus: false,
    numericKeypadResult: [],
    isStartedNumericKeypad: false,
    inputNumeric: '',
    inputArray: [],
    specificNumber: [],
    defaultTime: 30,
    numericKeypadExamTime: null,
    isFinishedNumericKeypad: false,
    countNumber: 0,
    disableNumericSideBar: false,
    numericExtraTime: 0,
    numericSubmitLoader: false,
    numericDataRejected: false,
    isSubmitted: false,
  },
  extraReducers: {
    [startNumericKeypad.pending]: (state, action) => {
      state.isLoadingNumericData = true
    },
    [startNumericKeypad.fulfilled]: (state, { payload }) => {
      state.numericKeypadExamTime =
        payload.data.attempt.recommended_seconds || state.defaultTime
      state.defaultTime = payload.data.attempt.recommended_seconds
      state.numericKeypadData = payload.data.attempt
      state.isLoadingNumericData = false
    },
    [startNumericKeypad.rejected]: (state, action) => {
      state.isLoadingNumericData = false
      state.numericDataRejected = true
    },
    [submitNumericKeypad.pending]: (state, action) => {
      state.numericSubmitLoader = true
      state.isSubmitted = true
    },
    [submitNumericKeypad.fulfilled]: (state, { payload }) => {
      state.numericSubmitLoader = false
      state.numericKeypadResult = payload.data
      state.disableNumericSideBar = false
    },
    [submitNumericKeypad.rejected]: (state, action) => {
      state.numericSubmitLoader = false
      state.numericDataRejected = true
      state.isStartedNumericKeypad = false
      state.isSubmitted = false
    },
  },
  reducers: {
    setDisableSubmitStatus: (state, { payload }) => {
      state.disableSubmitStatus = payload
    },
    toggleStartedNumericKeypad: (state, { payload }) => {
      state.isStartedNumericKeypad = payload
    },
    toggleIsFinishedNumericKeypad: (state, { payload }) => {
      state.isFinishedNumericKeypad = payload
    },
    updateInputNumber: (state, { payload }) => {
      state.disableSubmitStatus = false
      state.inputArray = [...state.inputArray, payload]
    },
    updateCountNumber: (state, { payload }) => {
      state.countNumber += 1
    },
    updateSpecificCountNumber: (state, { payload }) => {
      state.countNumber = payload
    },
    updateSpecificNumber: (state, { payload }) => {
      state.specificNumber = payload.split('')
      state.inputNumeric = payload
    },
    setNumericKeypadExamTime: (state, { payload }) => {
      state.numericKeypadExamTime -= 1
    },
    setTryAgainNumericKeypad: (state, { payload }) => {
      state.numericKeypadData = []
      state.isStartedNumericKeypad = false
      state.inputNumeric = ''
      state.inputArray = []
      state.specificNumber = []
      state.numericKeypadExamTime = null
      state.isFinishedNumericKeypad = false
      state.countNumber = 0
      state.disableNumericSideBar = false
      state.numericExtraTime = 0
      state.numericSubmitLoader = false
      state.numericDataRejected = false
      state.numericKeypadResult = []
      state.isSubmitted = false
    },
    setNumericExtraTime: (state, { payload }) => {
      state.numericExtraTime += 1
    },
    setDisableNumericSideBar: (state, { payload }) => {
      state.disableNumericSideBar = payload
    },
  },
})

export const {
  setDisableSubmitStatus,
  setNumericExtraTime,
  toggleStartedNumericKeypad,
  updateInputNumber,
  updateSpecificNumber,
  setNumericKeypadExamTime,
  toggleIsFinishedNumericKeypad,
  setTryAgainNumericKeypad,
  updateCountNumber,
  updateSpecificCountNumber,
  setDisableNumericSideBar,
} = numericKeypadSlice.actions

export default numericKeypadSlice.reducer
