import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'

export const getEmailUnsubscribeOptions = createAsyncThunk(
  'unsubscribeEmail/getEmailUnsubscribeOptions',
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptors.post(
        `account/settings/email-unsubscribe-options`,
        {
          token,
        },
      )
      return response
    } catch (error) {
      return rejectWithValue({ error: error?.response?.data })
    }
  },
)

export const saveEmailUnsubscribe = createAsyncThunk(
  'unsubscribeEmail/saveEmailUnsubscribe',
  async ({ data, token, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `account/settings/email-unsubscribe-option`,
        {
          token,
          setting: { key: data.key, value: data.value },
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

const unsubscribeEmailSlice = createSlice({
  name: 'unsubscribeEmail',
  initialState: {
    emailUnsubscribeOptions: { isLoading: false, data: null },
    unsubscribeOptionsError: null,
    unsubscribeLoader: false,
    unsubscribeIndex: null,
    unsubscribeError: null,
  },
  extraReducers: {
    [getEmailUnsubscribeOptions.pending]: (state, action) => {
      state.emailUnsubscribeOptions.isLoading = true
      state.unsubscribeError = null
      state.unsubscribeOptionsError = null
    },
    [getEmailUnsubscribeOptions.fulfilled]: (state, { payload }) => {
      state.emailUnsubscribeOptions.isLoading = false
      state.emailUnsubscribeOptions.data = payload?.data
    },
    [getEmailUnsubscribeOptions.rejected]: (state, { payload }) => {
      state.emailUnsubscribeOptions.isLoading = false
      state.unsubscribeOptionsError = payload
    },
    [saveEmailUnsubscribe.pending]: (state, action) => {
      state.unsubscribeIndex = action.meta.arg.data.subSettingIndex
    },
    [saveEmailUnsubscribe.fulfilled]: (state, { payload, meta }) => {
      const { subSettingIndex } = meta.arg.data

      state.emailUnsubscribeOptions.data.email_settings[subSettingIndex] =
        payload.data.setting
      state.unsubscribeIndex = null
      if (payload?.message) {
        toast.success('Saved', {
          duration: 2000,
          position: 'top-center',
          style: {
            backdropFilter: 'blur(4px)',
            marginTop: '20px',
            width: '350px',
          },
        })
      }
    },
    [saveEmailUnsubscribe.rejected]: (state, action) => {
      state.unsubscribeIndex = null
      state.unsubscribeError = {
        subIndex: action.meta.arg.data.subSettingIndex,
        error: action.payload.message,
      }
    },
  },
  reducers: {},
})

export const {} = unsubscribeEmailSlice.actions

export default unsubscribeEmailSlice.reducer
