import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getSubtestReviewData = createAsyncThunk(
  'subtestReview/getExamReviewData',
  async (data, { rejectWithValue }) => {
    try {
      return await axiosInterceptors.get(`history/review/${data.id}`)
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const subtestReviewSlice = createSlice({
  name: 'subtestReview',
  initialState: {
    subtestReviewData: { isLoading: false, data: [], error: '' },
    openUntimedModal: false,
  },

  extraReducers: {
    [getSubtestReviewData.pending]: (state, action) => {
      state.subtestReviewData = { isLoading: true }
    },
    [getSubtestReviewData.fulfilled]: (state, action) => {
      state.subtestReviewData = {
        isLoading: false,
        data: action.payload.data && action.payload.data.result_info,
      }
    },
    [getSubtestReviewData.rejected]: (state, action) => {
      state.subtestReviewData = {
        isLoading: false,
        error: action?.payload?.data?.message,
      }
    },
  },
  reducers: {
    setOpenUntimedModal: (state, { payload }) => {
      state.openUntimedModal = payload
    },
  },
})
export const { setOpenUntimedModal } = subtestReviewSlice.actions
export default subtestReviewSlice.reducer
