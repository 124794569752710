import { Tooltip } from 'components/form'
import { Warning } from 'components/icons'
import NoRecords from 'components/no-record/NoRecord'

const FallbackComponent = ({ error }) => {
  return (
    <NoRecords
      icon={
        <div>
          <Warning className="h-5 w-5" />
        </div>
      }
      title="Something went wrong!"
    />
  )
}

export default FallbackComponent
