import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const leaderBoardRanking = createAsyncThunk(
  'leaderBoard/leaderBoardRanking',
  async (data) => {
    return await axiosInterceptors.get(`trainer/${data.trainer}/leaderboard`)
  },
)

const leaderBoardSlice = createSlice({
  name: 'leaderBoard',
  initialState: {
    leaderBoardData: [],
    loadingLeaderBoard: false,
  },
  extraReducers: {
    [leaderBoardRanking.pending]: (state, action) => {
      state.loadingLeaderBoard = true
      state.leaderBoardData = []
    },
    [leaderBoardRanking.fulfilled]: (state, { payload }) => {
      state.loadingLeaderBoard = false

      state.leaderBoardData = payload.data
    },
    [leaderBoardRanking.rejected]: (state, action) => {
      state.loadingLeaderBoard = false
    },
  },
})

export default leaderBoardSlice.reducer
