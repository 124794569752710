import moment from 'moment'
import { Calendar } from 'components/icons'
import Datetime from 'react-datetime'
import './DatePicker.css'
import { useField } from 'formik'

const renderInput = (props, openCalendar, closeCalendar) => {
  return (
    <div className="relative">
      <input {...props} />
      <Calendar className="top-4 right-3 absolute h-6 w-6 fill-current dark:text-white" />
    </div>
  )
}

const DatePicker = ({
  name,
  label,
  hideLabel,
  className,
  helpText,
  disabled,
  isValidDate,
  onChange,
  hasCustomOnChange,
  ...props
}) => {
  const [inputProps, { error, touched }, { setValue }] = useField(name)
  return (
    <>
      {!hideLabel && (
        <div className="text-sm text-gray-700 dark:text-dark-100">{label}</div>
      )}
      <Datetime
        onChange={(event) => {
          if (hasCustomOnChange) {
            onChange(event)
          } else {
            onChange && onChange(moment(event._d).format('YYYY-MM-DD'))
            setValue(moment(event._d).format('YYYY-MM-DD'))
          }
        }}
        timeFormat={false}
        closeOnSelect={true}
        isValidDate={isValidDate}
        inputProps={{
          className: `w-full rounded-md h-12 mt-1 dark:bg-dark-600 dark:text-white dark:border-dark-400 ${
            className ? className : ''
          }`,
          ...inputProps,
          ...props,
          disabled,
          autoComplete: 'off',
        }}
        renderInput={renderInput}
      />
      {helpText && <div className="mt-1 text-sm text-gray-700">{helpText}</div>}
      {error && touched && (
        <div>
          <span className="text-xs font-semibold text-red-500">{error}</span>
        </div>
      )}
    </>
  )
}

export default DatePicker
