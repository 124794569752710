import { Tab } from '@headlessui/react'
import {
  List,
  ChevronLeft,
  Arrowleft,
  Bookmark,
  Shape,
  Loading,
  Bookmarked,
  Warning,
} from 'components/icons'
import { DeletePopUp, FreeTrial, Summary } from 'components/shared'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { toggleFaqMenu } from 'redux/faq/faqSlice'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { Spinner } from 'components/shared'
import {
  allowedVideoFormats,
  isUcatPath,
  isValidSelector,
  modifiedAnchorTag,
} from 'components/shared/helper'
import { NoRecord } from 'components/no-record'
import {
  clearResourceData,
  getResourceData,
  markChapterRead,
  updatePrevIndex,
  setMarkAsRead,
  updateTabIndex,
  updateChapterIndex,
  createBookmark,
  deleteBookmark,
  updateQuestionsIndex,
  setBookDetails,
  setTranscriptNoteModal,
  setSelectedNote,
  setShowIntroductionModal,
  setIsSettingOpen,
} from 'redux/learn/resourceSlice'
import { find, findIndex } from 'lodash'
import { markBookChapterOpened } from 'redux/learn/learnSlice'
import Button from 'components/shared/Button'
import { getInterviewsData } from 'redux/interviews/interviewGuideSlice'
import {
  clearTourData,
  getTourStepsData,
  setTourPageName,
  setTourVideoData,
} from 'redux/tour-steps/tourStepsSlice'
import { useTour } from '@reactour/tour'
import { toggleNavigation } from 'redux/dashboard/dashboardSlice'
import Parser, { domToReact } from 'html-react-parser'
import PostModal from 'pages/community/PostModal'
import style from 'style-to-object'
import TermsConditionModal from './TermsConditionModal'
import { getUniversitySpecificData } from 'redux/interviews/universitySpecificSlice'
import {
  markUniSpecificAgreement,
  setUserAgreedTerms,
} from 'redux/terms-and-conditions/termsAndConditionsSlice'
import HTMLRenderer from 'components/shared/helper/HTMLRenderer'
import LearnVideoPlayer from './LearnVideoPlayer'
import LearnQuiz from './LearnQuiz'
import Calculator from 'components/exam-portal/Calculator'
import {
  setDisabledCalculator,
  updateOptionClickable,
  updateShowCalculator,
} from 'redux/exam-portal/examPortalSlice'
import NotesAndDiscussion from 'components/booking/NotesAndDiscussion'
import BookingActivity from 'components/booking/BookingActivity'
import AddTranscriptNotes from 'components/booking/AddTranscriptNotes'
import ChapterList from 'components/booking/ChapterList'

const BookChapter = ({ isUniversity }) => {
  const { search } = useLocation()
  const bookRef = useRef(null)

  const { setIsOpen, setSteps, afterOpen, currentStep, isOpen } = useTour()
  const history = useHistory()
  const { moduleArea, resourceType, resourceSlug, subMenu, chapterIndex } =
    useParams()
  const dispatch = useDispatch()
  const {
    showFaqMenu,
    resourceData,
    tabDataIndex,
    bookmarkLoading,
    tourStepsData,
    showNavigation,
    userData,
    universityData,
    userAgreedTerms,
    uniSpecificLoading,
    userAccessArea,
    bookDetails,
    showCalculator,
    showTranscriptNoteModal,
    popOut,
    isFullBookScreen,
    isLearnTourCall,
    isNetworkError,
  } = useSelector((state) => ({
    showFaqMenu: state.faq.showFaqMenu,
    resourceData: state.resource.resourceData,
    tabDataIndex: state.resource.tabDataIndex,
    bookmarkLoading: state.resource.bookmarkLoading,
    tourStepsData: state.tourSteps.tourStepsData,
    showNavigation: state.dashboard.showNavigation,
    userData: state.personalInfo.userData,
    universityData: state.universitySpecific.universityData,
    userAgreedTerms: state.termsAndConditions.userAgreedTerms,
    uniSpecificLoading: state.termsAndConditions.uniSpecificLoading,
    bookDetails: state.resource.bookDetails,
    showTranscriptNoteModal: state.resource.showTranscriptNoteModal,
    userAccessArea: state.dashboard.userAccessArea,
    showCalculator: state.examPortal.showCalculator,
    popOut: state.resource.popOut,
    isFullBookScreen: state.resource.isFullBookScreen,
    isLearnTourCall: state.tourSteps.isLearnTourCall,
    isNetworkError: state.resource.isNetworkError,
  }))

  const playerRef = useRef(null)
  const chapterRef = useRef(null)
  const tab = new URLSearchParams(search)?.get('resourceType')

  const tabletView = window.matchMedia('(max-width: 768px)')
  const [matches, setMatches] = useState(tabletView.matches)
  const [postModal, setPostModal] = useState({ visible: false, data: null })
  const [deleteNote, setDeleteNote] = useState(false)
  const universityDataModule = universityData?.data.find(
    (o) =>
      o?.resource?.resourceable_id === Number(resourceData?.data?.book?.id),
  )
  const tutoringDiscussionAccessCheck = bookDetails?.discussable_with_tutor

  const tabData = [
    {
      title: 'Discussions',
      className: 'tour-qa',
      key: 'discussion',
    },
    {
      title: 'My Notes',
      className: 'tour-notes',
      key: 'notes',
    },
    {
      title: 'Add discussion note',
      className: '',
      key: 'tutor-question',
      hidden: !tutoringDiscussionAccessCheck?.can_display_button,
    },
    {
      title: 'Report issue',
      key: 'report-issue',
    },
  ]
  const tabIndex = tabData?.findIndex((data) => data?.key === tab)
  const [resourceTab, setResourceTab] = useState(tabIndex !== -1 ? tabIndex : 0)

  useEffect(() => {
    if (
      !bookDetails?.chapterable?.submission?.is_completed &&
      bookDetails?.chapterable?.intro_html
    ) {
      dispatch(setShowIntroductionModal(true))
    }
  }, [
    bookDetails?.chapterable?.intro_html,
    bookDetails?.chapterable?.submission?.is_completed,
    dispatch,
  ])

  useEffect(() => {
    if (!isLearnTourCall) {
      dispatch(
        getTourStepsData({
          page: 'learn',
          onSuccess: () => dispatch(setTourPageName('learn')),
        }),
      )
    } else {
      dispatch(setTourPageName('learn'))
    }
  }, [dispatch, isLearnTourCall])

  useEffect(() => {
    return () => {
      dispatch(clearTourData())
      dispatch(setTourVideoData(null))
    }
  }, [dispatch])

  const [tourWithInterval, setTourWithInterval] = useState(true)
  useEffect(() => {
    if (
      resourceData?.data?.book?.chapters?.length > 0 &&
      tourStepsData?.data?.tour?.steps?.length > 0 &&
      tourStepsData.data.tour.steps.filter(
        (step) =>
          isValidSelector(step.selector) &&
          document.querySelectorAll(step.selector).length,
      ).length > 0
    ) {
      const learnTourData =
        tourStepsData?.data?.tour?.steps?.filter(
          (step) =>
            isValidSelector(step.selector) &&
            document.querySelectorAll(step.selector).length,
        ) || []

      const tourArray = [
        '.tour-transcript',
        '.tour-qa',
        '.tour-notes',
        '.tour-discuss-with-tutor',
        'tour-closed-caption',
        '.tour-speed-change',
      ]

      const findBookmarkIndex = findIndex(learnTourData, {
        selector: '.tour-bookmark',
      })
      const findCaptionIndex = findIndex(learnTourData, {
        selector: '.tour-closed-caption',
      })

      const captionIndex = findIndex(tourStepsData?.data?.tour?.steps, {
        selector: '.tour-closed-caption',
      })
      const speedIndex = findIndex(tourStepsData?.data?.tour?.steps, {
        selector: '.tour-speed-change',
      })
      if (
        (findBookmarkIndex !== -1 && findBookmarkIndex === currentStep) ||
        (findCaptionIndex !== -1 && findCaptionIndex === currentStep)
      ) {
        window.scrollTo(0, 0)
      }

      if (showNavigation) {
        dispatch(toggleNavigation())
      }
      if (
        bookDetails?.chapterable_type === 'video' &&
        (captionIndex !== -1 || speedIndex !== -1)
      ) {
        dispatch(setIsSettingOpen(true))
      }
      let addInterval =
        learnTourData?.length > 0
          ? tourArray?.includes(learnTourData[0].selector)
          : false

      if (addInterval && tourWithInterval) {
        const timer = setTimeout(() => {
          setTourWithInterval(false)
          setSteps(
            tourStepsData?.data?.tour?.steps?.filter(
              (step) =>
                isValidSelector(step.selector) &&
                document.querySelectorAll(step.selector).length,
            ),
          )
          clearTimeout(timer)
        }, 500)
      } else {
        setSteps(
          tourStepsData?.data?.tour?.steps?.filter(
            (step) =>
              isValidSelector(step.selector) &&
              document.querySelectorAll(step.selector).length,
          ),
        )
      }
      setIsOpen(true)
      afterOpen()
    }
    return () => {
      setIsOpen(false)
    }
  }, [
    afterOpen,
    dispatch,
    resourceData?.data,
    setIsOpen,
    setSteps,
    showFaqMenu,
    showNavigation,
    tourStepsData.data,
    currentStep,
    bookDetails?.chapterable_type,
    tourWithInterval,
  ])

  useEffect(() => {
    if (moduleArea === 'learn') {
    } else {
      dispatch(
        getInterviewsData({
          interviewSlug:
            userData?.cohort === 'UK' ? 'interviewsuk' : 'interviews',
        }),
      )
    }
    if (moduleArea === 'unispecificresources') {
      dispatch(getUniversitySpecificData())
    }
    dispatch(
      getResourceData({
        // resourceType: resourceType?.toLowerCase(),
        resourceSlug: resourceSlug,
      }),
    )
    return () => {
      dispatch(clearResourceData())
    }
  }, [dispatch, isUniversity, moduleArea, resourceSlug, userData?.cohort])

  useEffect(() => {
    dispatch(updateChapterIndex({ chapterIndex }))
  }, [dispatch, chapterIndex])

  useEffect(() => {
    if (bookDetails?.id) {
      dispatch(markBookChapterOpened({ chapterId: bookDetails?.id }))
    }
  }, [bookDetails?.id, dispatch])

  useEffect(() => {
    const handler = (e) => setMatches(e.matches)
    tabletView.addListener(handler)
    return () => tabletView.removeListener(handler)
  })

  const pausePreviousVideo = (id) => {
    const videos = document.querySelectorAll('video')
    videos.forEach((video) => {
      if (video?.id !== id) {
        video.pause && video.pause()
      }
    })
  }
  useEffect(() => {
    return () => {
      dispatch(updateShowCalculator(false))
      dispatch(setDisabledCalculator(true))
    }
  }, [dispatch])

  //terms & condition for unispecific
  useEffect(() => {
    if (
      universityDataModule?.resource?.terms_and_conditions_area ===
        'unispecific' &&
      universityDataModule?.resource?.user_agreed_to_terms_and_conditions ===
        false
    )
      dispatch(setUserAgreedTerms(true))
  }, [
    dispatch,
    universityDataModule?.resource?.terms_and_conditions_area,
    universityDataModule?.resource?.user_agreed_to_terms_and_conditions,
  ])

  // scroll to top after changing chapter
  const scrollToTopNextChapter = () => {
    chapterRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const previousChapter = () => {
    scrollToTopNextChapter()
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    if (tabDataIndex !== 0 && resourceData.data?.book?.chapters?.length > 0) {
      dispatch(
        setBookDetails(resourceData.data?.book?.chapters[tabDataIndex - 1]),
      )
    }

    const previousChapterIndex = parseInt(chapterIndex) - 1
    dispatch(updatePrevIndex(tabDataIndex - 1))
    if (isUniversity) {
      history.push(
        `/university-admission/${subMenu}/${moduleArea}/${resourceType}/${resourceSlug}/${previousChapterIndex}`,
      )
    } else {
      history.push(
        `${isUcatPath}/${moduleArea}/${resourceSlug}/${previousChapterIndex}`,
      )
    }
  }

  const nextChapter = ({ disableUserRead, user_read, id }) => {
    if (
      tabDataIndex + 1 !== resourceData.data?.book?.chapters?.length &&
      resourceData.data?.book?.chapters?.length > 0
    ) {
      dispatch(
        setBookDetails(resourceData.data?.book?.chapters[tabDataIndex + 1]),
      )
    }
    scrollToTopNextChapter()

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    if (!disableUserRead && !user_read) {
      dispatch(
        markChapterRead({
          chapterId: id,
        }),
      )
      dispatch(
        setMarkAsRead({
          chapterId: id,
        }),
      )
    }

    dispatch(updateTabIndex(tabIndex + 1))
    dispatch(updateQuestionsIndex(0))
    const nextChapterIndex = chapterIndex ? parseInt(chapterIndex) + 1 : 2
    if (isUniversity) {
      history.push(
        `/university-admission/${subMenu}/${moduleArea}/${resourceType}/${resourceSlug}/${nextChapterIndex}`,
      )
    } else {
      history.push(
        `${isUcatPath}/${moduleArea}/${resourceSlug}/${nextChapterIndex}`,
      )
    }
  }

  const videoMarkRead = ({ user_read, id }) => {
    !user_read &&
      dispatch(
        markChapterRead({
          chapterId: id,
        }),
      )
  }

  function applyFilterToTabGroup(text) {
    if (!typeof text === 'string' || !text || text.indexOf('{%') < 0) {
      return text
    }
    let matches = text?.match(/\{%:([^}]*)\}(.*?)\{%\}/gs)
    let tabPanels = matches?.map((data, id) => {
      return (
        <Tab.Panel key={id} className="w-full html-renderer-container">
          {Parser(
            data
              ?.match(/\{%:([^}]*)\}(.*?)\{%\}/s)[2]
              ?.replace('{@imagemodal:}', '')
              ?.replace('{@}', ''),
            {
              replace: (domNode) => {
                if (domNode.attribs && domNode.attribs.style) {
                  try {
                    style(domNode.attribs.style)
                  } catch (error) {
                    delete domNode.attribs.style
                    return domToReact(domNode)
                  }
                }
                if (domNode.name === 'a') {
                  const urlExtension = domNode.attribs.href.match(
                    /\.([0-9a-z]+)(?:[?#]|$)/i,
                  )
                  if (allowedVideoFormats?.includes(urlExtension[1]))
                    return (
                      <video
                        id={Math.floor(Math.random() * 101)}
                        onPlay={(e) => {
                          pausePreviousVideo(e?.target?.id)
                        }}
                        className="w-full"
                        controls
                        controlsList="nodownload"
                        onContextMenu={(e) => e.preventDefault()}
                      >
                        <source src={domNode.attribs.href} type="video/mp4" />
                        <source
                          src={domNode.attribs.href}
                          type="video/ogg"
                        ></source>
                      </video>
                    )
                }
                if (domNode?.name === 'img') {
                  domNode.attribs.class =
                    'cursor-pointer transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-105'
                  domNode.attribs.onClick = () =>
                    setPostModal({
                      visible: true,
                      data: {
                        imgUrl: domNode?.attribs?.src,
                      },
                    })
                }
              },
            },
          )}
        </Tab.Panel>
      )
    })
    matches = matches?.map((data, id) => {
      return (
        <Tab key={id} as={Fragment}>
          {({ selected }) => (
            <button
              className={`focus:outline-none pb-2 mr-4 border-b border-transparent text-sm
        ${
          selected
            ? 'text-gray-900 dark:text-white border-red-500 font-semibold'
            : 'text-gray-700 dark:text-dark-100'
        }`}
            >
              <HTMLRenderer
                preventInteraction={true}
                hideVideoDownload
                html={data?.match(/\{%:(.*)\}/i)[1]}
              />
            </button>
          )}
        </Tab>
      )
    })
    return (
      <Tab.Group>
        <Tab.List className="border-b my-8 border-gray-100 dark:border-dark-500">
          {matches}
        </Tab.List>
        <Tab.Panels className="mt-2">{tabPanels}</Tab.Panels>
      </Tab.Group>
    )
  }
  function filterTabs(string) {
    if (!typeof string || !string || string.indexOf('{%tabs:}') < 0) {
      return false
    }
    let bufferToReplace = [],
      bufferReplaceWith = [],
      lastIndexEnd = 0,
      numCyclesPerformed = 0

    while (numCyclesPerformed < string?.split('{%tabs:}')?.length - 1) {
      numCyclesPerformed += 1
      let currentIndex = string.indexOf('{%tabs:}', lastIndexEnd)
      let currentIndexEnd = string.indexOf('{%endtabs}', currentIndex)
      if (currentIndex === -1) {
        break
      }

      if (currentIndexEnd === -1) {
        break
      }
      let toReplace = string.substr(
        currentIndex,
        currentIndexEnd - currentIndex + 10,
      )

      let replaceWith = applyFilterToTabGroup(
        toReplace.replace('{%tabs:}', '').replace('{%endtabs}', ''),
      )
      lastIndexEnd = currentIndexEnd

      bufferToReplace?.push(toReplace)
      bufferReplaceWith?.push(replaceWith)
    }
    return bufferReplaceWith
  }

  const closeCalculator = () => {
    dispatch(updateShowCalculator(false))
    dispatch(setDisabledCalculator(true))
    dispatch(updateOptionClickable(true))
  }

  const moduleData = resourceData?.data?.my_location?.find(
    (data) => data?.type === 'module',
  )

  const bookStyle = {
    maxHeight: isOpen
      ? ''
      : isFullBookScreen
      ? '100%'
      : matches
      ? 'calc(100vh - 88px)'
      : 'calc(100vh - 108px)',
  }

  const chapterStyle = {
    height: isOpen
      ? ''
      : isFullBookScreen
      ? '100%'
      : matches
      ? 'calc(100vh - 132px)'
      : 'calc(100vh - 200px)',
  }

  if (deleteNote === true) {
    return <DeletePopUp setDeleteNote={setDeleteNote} Deletenote={deleteNote} />
  } else {
    return (
      <div
        className="bg-white dark:bg-dark-600 rounded-sm relative overflow-hidden"
        style={bookStyle}
        ref={bookRef}
      >
        {showTranscriptNoteModal && (
          <AddTranscriptNotes
            open={showTranscriptNoteModal}
            onClose={() => {
              dispatch(setSelectedNote(null))
              dispatch(setTranscriptNoteModal(false))
            }}
            area="learning"
            chapterId={resourceData?.data?.book?.chapters[tabDataIndex]?.id}
            learningJournalEntry={
              resourceData?.data?.book?.chapters[tabDataIndex]
                ?.user_learning_journal_entry
            }
            bookType={moduleArea}
            moduleName={`Module ${moduleData?.data?.number}: ${moduleData?.name}`}
            bookName={resourceData.data?.book?.name}
            chapterName={resourceData?.data?.book?.chapters[tabDataIndex]?.name}
          />
        )}

        {!popOut && showCalculator && (
          <Calculator
            closeCalculator={closeCalculator}
            divXWidth={bookRef?.current?.offsetWidth}
            divYHeight={bookRef?.current?.offsetHeight + 12}
          />
        )}

        {postModal.visible && (
          <PostModal
            postModal={postModal}
            setPostModal={setPostModal}
            imgsrc=""
          />
        )}
        <TermsConditionModal
          open={userAgreedTerms}
          isLoading={uniSpecificLoading}
          onSubmit={() =>
            dispatch(
              markUniSpecificAgreement({
                area: 'unispecific',
                subarea: universityDataModule?.resource?.id,
              }),
            )
          }
          onClose={() => dispatch(setUserAgreedTerms(false))}
        />

        {resourceData && resourceData.isLoading ? (
          <div className="py-6">
            <Spinner />
          </div>
        ) : (
          resourceData &&
          resourceData.data && (
            <>
              {!isFullBookScreen && (
                <div className="flex px-6 sm:pt-6 pt-4">
                  {moduleArea !== 'uniadmissions' &&
                    moduleArea !== 'uncategorised' &&
                    (moduleArea === 'learn' ? (
                      <>
                        <Link
                          to={`${isUcatPath}/${moduleArea}`}
                          className="flex "
                        >
                          <div className="sm:hidden mt-0.5 mr-3 dark:text-gray-900">
                            <ChevronLeft />
                          </div>
                          <p className=" hidden sm:block text-sm dark:text-white">
                            Learn
                          </p>
                        </Link>
                        {moduleData?.id ? (
                          <span className="ml-1 flex gap-1">
                            <span className="hidden sm:block text-gray-400 dark:text-dark-300 text-sm">
                              /
                            </span>
                            <Link
                              to={`${isUcatPath}/learn/module/${moduleData?.id}`}
                            >
                              <p className=" text-sm dark:text-white">
                                Module {moduleData?.data?.number}:{' '}
                                {moduleData?.name}
                              </p>
                            </Link>
                          </span>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : moduleArea === 'unispecificresources' ? (
                      <>
                        <Link to={`/interviews`} className="flex ">
                          <p className=" hidden sm:block text-sm dark:text-white">
                            Interviews&nbsp;/&nbsp;
                          </p>
                        </Link>
                        <Link
                          to={`/interviews/university-specific-questions`}
                          className="flex "
                        >
                          <div className="sm:hidden mt-0.5 mr-3">
                            <ChevronLeft className="dark:text-white" />
                          </div>
                          <p className=" hidden sm:block text-sm dark:text-white">
                            University Specific Questions
                          </p>
                        </Link>
                      </>
                    ) : moduleArea === 'interviews' ||
                      moduleArea === 'interviewsuk' ? (
                      <>
                        <Link to={`/interviews`} className="flex ">
                          <p className=" hidden sm:block text-sm dark:text-white">
                            Interviews&nbsp;/&nbsp;
                          </p>
                        </Link>
                        <Link
                          to={`/interviews/interview-guide`}
                          className="flex "
                        >
                          <div className="sm:hidden mt-0.5 mr-3">
                            <ChevronLeft className="dark:text-white" />
                          </div>
                          <p className=" hidden sm:block text-sm dark:text-white">
                            Interview Guide
                          </p>
                        </Link>
                        {moduleData?.name && (
                          <Link
                            to={`${isUcatPath}/${moduleData?.area}/module/${moduleData?.id}`}
                          >
                            <p className=" text-sm  dark:text-white">
                              &nbsp;/&nbsp;{moduleData?.name}
                            </p>
                          </Link>
                        )}
                      </>
                    ) : (
                      ''
                    ))}
                  {moduleArea !== 'uniadmissions' &&
                    moduleArea !== 'uncategorised' &&
                    moduleArea !== 'learn-old' &&
                    resourceData?.data?.book?.name && (
                      <p className="hidden sm:block text-sm text-gray-400 dark:text-dark-300">
                        &nbsp;/&nbsp;{resourceData.data?.book?.name}
                      </p>
                    )}
                </div>
              )}
              {!isFullBookScreen && (
                <h2 className="hidden md:block font-semibold text-2xl text-gray-900 dark:text-white ml-6 pt-4">
                  {resourceData.data?.book?.name}
                </h2>
              )}
              <div
                className={`flex w-full h-full custom-scroll ${
                  isOpen ? '' : 'overflow-hidden'
                }`}
                style={chapterStyle}
              >
                <div
                  onClick={() => dispatch(toggleFaqMenu())}
                  style={{ left: showFaqMenu ? '232px' : '0px' }}
                  className={`fixed md:hidden bottom-4 bg-white dark:bg-dark-600 z-20  h-12 w-12 border border-gray-800 dark:border-white rounded-full flex items-center justify-center cursor-pointer ${
                    matches ? 'tour-chapters' : ''
                  }`}
                >
                  <List className="dark:text-white" />
                </div>
                <Tab.Group>
                  {bookDetails?.chapterable_type === 'quiz' ? (
                    <></>
                  ) : (
                    <>
                      {resourceData.data?.book?.chapters?.length > 0 ? (
                        <div
                          id="closeSidebar1"
                          onMouseDown={(e) => {
                            if (e.target.id === 'closeSidebar1') {
                              dispatch(toggleFaqMenu())
                            }
                          }}
                          className={` ${
                            resourceData.data.is_locked
                              ? 'pointer-events-none opacity-25'
                              : ''
                          } ${
                            showFaqMenu
                              ? 'fixed  bg-gray-900  bg-opacity-40 h-screen'
                              : 'hidden'
                          } md:block left-0 top-8 z-10 mt-6 md:top-0 md:left-0 transform w-screen md:min-h-0 md:relative md:w-1/4 bg-white dark:bg-gray-700 dark:bg-opacity-60 border-r dark:border-dark-500`}
                        >
                          <Tab.List
                            className={`custom-scroll w-64 md:w-full md:h-full  bg-white dark:bg-dark-600 flex flex-col dark:border-dark-500 sm:min-h-full mb-12 overflow-auto h-full tour-learn-sidebar ${
                              !matches ? 'tour-chapters' : ''
                            }`}
                          >
                            <div className="text-base text-gray-900 dark:text-white bg-white dark:bg-dark-600 my-3.5 ml-5 mr-3.5 font-semibold md:hidden  ">
                              <p>{resourceData.data?.book?.name}</p>
                            </div>
                            {resourceData.data?.book?.chapters &&
                              resourceData.data?.book?.chapters?.map(
                                (tab, tabIndex) => (
                                  <Fragment key={tabIndex}>
                                    <ChapterList
                                      tabIndex={tabIndex}
                                      tabDataIndex={tabDataIndex}
                                      isUniversity={isUniversity}
                                      showFaqMenu={showFaqMenu}
                                      tab={tab}
                                      scrollToTopNextChapter={() =>
                                        scrollToTopNextChapter()
                                      }
                                    />
                                  </Fragment>
                                ),
                              )}
                            <div className="mb-10"></div>
                          </Tab.List>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  <div
                    id="main-area"
                    ref={chapterRef}
                    className={`w-full overflow-auto custom-scroll pb-6 ${
                      isFullBookScreen ? '' : 'mt-6'
                    } ${
                      bookDetails?.chapterable_type === 'quiz'
                        ? 'w-full'
                        : resourceData.data?.book?.chapters?.length > 0
                        ? 'md:w-3/4'
                        : ''
                    }`}
                  >
                    {resourceData && resourceData.data.is_locked ? (
                      <FreeTrial />
                    ) : resourceData.data.book ? (
                      tabDataIndex !==
                      resourceData.data?.book?.chapters.length ? (
                        <Tab.Panels>
                          {resourceData.data.book.chapters &&
                            resourceData.data.book.chapters.map(
                              (tab, tabIndex) => (
                                <div
                                  key={tabIndex}
                                  className={
                                    tabIndex === tabDataIndex
                                      ? 'visible'
                                      : 'hidden'
                                  }
                                >
                                  <div>
                                    <div>
                                      {!isFullBookScreen && (
                                        <div
                                          className={`px-6 font-semibold text-gray-900 dark:text-white text-sm sm:text-xl flex items-center justify-between ${
                                            bookmarkLoading
                                              ? 'pointer-events-none'
                                              : ''
                                          }`}
                                        >
                                          <div
                                            onClick={() => {
                                              if (
                                                tab.user_bookmark &&
                                                tab.user_bookmark.id
                                              ) {
                                                dispatch(
                                                  deleteBookmark({
                                                    bookmarkId:
                                                      tab.user_bookmark.id,
                                                    typeId: tab.id,
                                                  }),
                                                )
                                              } else {
                                                dispatch(
                                                  createBookmark({
                                                    type: 'chapter',
                                                    typeId: tab.id,
                                                  }),
                                                )
                                              }
                                            }}
                                            className="flex items-center justify-center cursor-pointer"
                                          >
                                            {tab.name}
                                            <div
                                              className={`px-2.5 py-1 ml-4 flex items-center justify-center ${
                                                tabDataIndex === tabIndex
                                                  ? 'tour-bookmark'
                                                  : ''
                                              }`}
                                            >
                                              {bookmarkLoading ? (
                                                <Loading />
                                              ) : tab.user_bookmark &&
                                                tab.user_bookmark.id ? (
                                                <Bookmarked className="text-red-500" />
                                              ) : (
                                                <Bookmark />
                                              )}
                                            </div>
                                          </div>
                                          {tab.user_read ? (
                                            <div
                                              className={`text-sm font-normal text-gray-900 dark:text-white flex items-center justify-center`}
                                            >
                                              <Shape className="text-green-700 mr-4" />{' '}
                                              Read
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      )}
                                      <div
                                        className={`disable-copy dark:text-white ${
                                          isFullBookScreen ? '' : 'pt-4'
                                        }`}
                                      >
                                        {tab?.chapterable_type === 'guide' ? (
                                          <div className="px-6 html-renderer-container">
                                            {tab?.chapterable?.content && (
                                              <HTMLRenderer
                                                hideVideoDownload
                                                preventInteraction={true}
                                                componentOverrides={{
                                                  video: (Comp) => (props) =>
                                                    (
                                                      <Comp
                                                        {...props}
                                                        controlsList="nodownload"
                                                        onContextMenu={(e) =>
                                                          e.preventDefault()
                                                        }
                                                      />
                                                    ),
                                                  a: modifiedAnchorTag,
                                                }}
                                                html={tab?.chapterable?.content
                                                  .replace(/(\r)/gm, '')
                                                  ?.replace(
                                                    /\{%tabs:\}(.*?)\{%endtabs\}/gs,
                                                    '',
                                                  )}
                                              />
                                            )}
                                            {filterTabs(
                                              tab?.chapterable?.content?.replace(
                                                /(\r)/gm,
                                                '',
                                              ),
                                            )}
                                          </div>
                                        ) : tab?.chapterable_type ===
                                            'activity' &&
                                          bookDetails?.id === tab?.id ? (
                                          <div>
                                            <BookingActivity
                                              activity={tab?.chapterable}
                                              activityTabData={filterTabs(
                                                tab?.chapterable?.content?.replace(
                                                  /(\r)/gm,
                                                  '',
                                                ),
                                              )}
                                            />
                                          </div>
                                        ) : tab?.chapterable_type === 'video' &&
                                          bookDetails?.id === tab?.id ? (
                                          <>
                                            <LearnVideoPlayer
                                              playerRef={playerRef}
                                              chapterableVideo={
                                                tab?.chapterable
                                              }
                                              chapterId={tab?.id}
                                              tabDataIndex={tabDataIndex}
                                              videoMarkRead={() => {
                                                videoMarkRead({
                                                  user_read: tab.user_read,
                                                  id: tab.id,
                                                })
                                              }}
                                              nextChapter={() =>
                                                nextChapter({
                                                  disableUserRead: true,
                                                  id: tab.id,
                                                })
                                              }
                                            />
                                          </>
                                        ) : tab?.chapterable_type === 'quiz' &&
                                          bookDetails?.id === tab?.id ? (
                                          <>
                                            <LearnQuiz
                                              setResourceTab={setResourceTab}
                                              previousChapter={previousChapter}
                                              nextChapter={() =>
                                                nextChapter({
                                                  disableUserRead: true,
                                                  id: tab.id,
                                                })
                                              }
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      {tab?.chapterable_type !== 'quiz' && (
                                        <div
                                          className={`flex px-6 ${
                                            tabDataIndex !== 0
                                              ? 'justify-between'
                                              : 'justify-end'
                                          } items-center mt-10 mb-12 text-xs`}
                                        >
                                          {tabDataIndex !== 0 && (
                                            <button
                                              className="tracking-widest flex justify-center items-center font-bold dark:text-white"
                                              onClick={() => {
                                                previousChapter()
                                              }}
                                            >
                                              <span className="pr-4">
                                                <Arrowleft className="dark:text-white" />
                                              </span>
                                              PREVIOUS CHAPTER
                                            </button>
                                          )}
                                          {bookDetails?.chapterable_type !==
                                            'video' && (
                                            <Button
                                              className={`px-6 py-4 tracking-widest font-semibold ${
                                                tabDataIndex === tabIndex
                                                  ? 'tour-mark-as-read'
                                                  : ''
                                              }`}
                                              onClick={() => {
                                                nextChapter({
                                                  user_read: tab.user_read,
                                                  id: tab.id,
                                                })
                                              }}
                                              outlined
                                            >
                                              {tab?.chapterable_type ===
                                              'activity'
                                                ? 'MARK AS COMPLETED'
                                                : 'MARK AS READ'}
                                            </Button>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    <>
                                      {tabDataIndex === tabIndex ? (
                                        <NotesAndDiscussion
                                          tabData={tabData}
                                          resourceTab={resourceTab}
                                          setResourceTab={setResourceTab}
                                          tabDataIndex={tabDataIndex}
                                          tabIndex={tabIndex}
                                          tabId={tab.id}
                                          playerRef={playerRef}
                                          moduleName={moduleData?.name}
                                          bookName={
                                            resourceData.data?.book?.name
                                          }
                                          chapterName={
                                            resourceData?.data?.book?.chapters[
                                              tabDataIndex
                                            ]?.name
                                          }
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  </div>
                                </div>
                              ),
                            )}
                        </Tab.Panels>
                      ) : (
                        <Tab.Panels>
                          <Summary resourceData={resourceData} />
                        </Tab.Panels>
                      )
                    ) : isNetworkError ? (
                      <NoRecord
                        icon={<Warning className="h-5 w-5" />}
                        subTitle={'Please check your connection and try again'}
                      />
                    ) : (
                      <div>
                        <NoRecord title="0 Guide" subTitle="No guide found" />
                      </div>
                    )}
                  </div>
                </Tab.Group>
              </div>
            </>
          )
        )}
      </div>
    )
  }
}
export default BookChapter
