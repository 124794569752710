const ThumbUp = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.667 3.167A.833.833 0 007.833 4v.667a3.167 3.167 0 01-2.666 3.127v3.54a1.5 1.5 0 001.5 1.5h4.666a.5.5 0 01.043.001c.115.01.263-.032.419-.188.16-.16.303-.418.383-.754l.654-3.27a.834.834 0 00-.832-.79h-2a.5.5 0 01-.5-.5V4a.833.833 0 00-.833-.833zM5.027 13.22A1.164 1.164 0 014 13.833H2.667A1.167 1.167 0 011.5 12.667V8a1.167 1.167 0 011.167-1.167h2a2.167 2.167 0 002.166-2.166V4A1.833 1.833 0 0110.5 4v2.833H12a1.834 1.834 0 011.833 1.834.497.497 0 01-.01.098l-.666 3.333-.004.016c-.112.478-.33.92-.651 1.24-.32.32-.735.51-1.188.48H6.667a2.5 2.5 0 01-1.64-.614zm-.86-5.387h-1.5A.167.167 0 002.5 8v4.667a.166.166 0 00.167.166H4a.167.167 0 00.167-.166V7.833z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default ThumbUp
