const Register = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 19 18"
    className={`h-5 w-5 ${className}`}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 0.25C2.27065 0.25 1.57118 0.539731 1.05546 1.05546C0.539731 1.57118 0.25 2.27065 0.25 3V15C0.25 15.7293 0.539731 16.4288 1.05546 16.9445C1.57118 17.4603 2.27065 17.75 3 17.75H10C10.7293 17.75 11.4288 17.4603 11.9445 16.9445C12.4603 16.4288 12.75 15.7293 12.75 15V13C12.75 12.5858 12.4142 12.25 12 12.25C11.5858 12.25 11.25 12.5858 11.25 13V15C11.25 15.3315 11.1183 15.6495 10.8839 15.8839C10.6495 16.1183 10.3315 16.25 10 16.25H3C2.66848 16.25 2.35054 16.1183 2.11612 15.8839C1.8817 15.6495 1.75 15.3315 1.75 15V3C1.75 2.66848 1.8817 2.35054 2.11612 2.11612C2.35054 1.8817 2.66848 1.75 3 1.75H10C10.3315 1.75 10.6495 1.8817 10.8839 2.11612C11.1183 2.35054 11.25 2.66848 11.25 3V5C11.25 5.41421 11.5858 5.75 12 5.75C12.4142 5.75 12.75 5.41421 12.75 5V3C12.75 2.27065 12.4603 1.57118 11.9445 1.05546C11.4288 0.539731 10.7293 0.25 10 0.25H3ZM8.53033 6.53033C8.82322 6.23744 8.82322 5.76256 8.53033 5.46967C8.23744 5.17678 7.76256 5.17678 7.46967 5.46967L4.46967 8.46967C4.17678 8.76256 4.17678 9.23744 4.46967 9.53033L7.46967 12.5303C7.76256 12.8232 8.23744 12.8232 8.53033 12.5303C8.82322 12.2374 8.82322 11.7626 8.53033 11.4697L6.81066 9.75H18C18.4142 9.75 18.75 9.41421 18.75 9C18.75 8.58579 18.4142 8.25 18 8.25H6.81066L8.53033 6.53033Z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Register
