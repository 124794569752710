const Icalendar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <g clipPath="url(#clip0_2003:61046)">
      <path
        fill="#fff"
        stroke="#A6A6A6"
        d="M31.5 10.51v-.5c0-.382 0-.763-.002-1.144m.002 1.644l-.002-1.644m.002 1.644h.002m-.002 0h.002m-.003-1.644a40.882 40.882 0 00-.015-.954h0c-.02-.693-.06-1.372-.178-2.028h0a6.572 6.572 0 00-.612-1.86m.805 4.842l-.805-4.842m0 0a6.257 6.257 0 00-1.144-1.576m1.144 1.576L29.55 2.448m1.951 8.062v11.48l-.001 1.143c-.003.322-.006.64-.015.955h0c-.019.694-.06 1.371-.177 2.027l.194-15.605zm-3.526-9.205h0a6.547 6.547 0 00-1.859-.611h0C25.46.576 24.781.535 24.09.516h0a51.98 51.98 0 00-.956-.015L21.99.5H10.01l17.965.805zm0 0a6.23 6.23 0 011.575 1.143m-1.575-1.143l1.575 1.143m-6.414 29.05c-.381.002-.762.002-1.145.002h-11.98c-.381 0-.762 0-1.142-.002-.322-.002-.64-.005-.956-.014h0c-.693-.02-1.371-.06-2.028-.178h0a6.544 6.544 0 01-1.858-.61v-.001a6.176 6.176 0 01-1.575-1.144h0a6.22 6.22 0 01-1.144-1.575h0a6.558 6.558 0 01-.613-1.86h0C.577 25.46.537 24.781.518 24.09h0a41.486 41.486 0 01-.015-.955v-.002C.5 22.752.5 22.372.5 21.99V10.01c0-.382 0-.762.003-1.142v-.002c.001-.318.006-.638.015-.954V7.91c.017-.691.058-1.37.176-2.026a6.62 6.62 0 01.612-1.86h0a6.195 6.195 0 012.72-2.72c.59-.299 1.197-.492 1.858-.61h0C6.54.576 7.219.535 7.912.516h0c.316-.009.635-.013.954-.015l14.272 31.497m-.002-.5l.002.5m-.002-.5s0 0 0 0l.002.5m-.002-.5c.318 0 .637-.005.954-.014h0c.693-.02 1.371-.06 2.028-.178h0a6.59 6.59 0 001.858-.61m-4.838 1.302c.321-.001.644-.005.966-.014.7-.02 1.409-.061 2.102-.186a7.09 7.09 0 001.997-.657m-.227-.446l.227.446m-.227-.446s0 0 0 0l.227.446m-.227-.446a6.223 6.223 0 001.576-1.145h0c.462-.46.847-.992 1.143-1.574m-2.492 3.165a6.727 6.727 0 002.938-2.938m-.446-.227l.446.227m-.446-.227s0 0 0 0l.446.227m-.446-.227c.301-.59.493-1.198.613-1.86l.492.088a7.118 7.118 0 01-.66 1.999M.019 7.898h0z"
      ></path>
      <path
        fill="#000"
        d="M12.773 26.18V13.465h-.534L9 15.61v.543l3.202-2.097h.038v12.125h.533v-.002zM15.937 13.465v.487h6.984v.038l-5.608 12.19h.6l5.561-12.2v-.515h-7.537z"
      ></path>
      <path
        fill="red"
        d="M7.338 9.605V7.021h.01l1.02 2.32h.34l1.017-2.32h.012v2.584h.34V6.183h-.36L8.545 8.925h-.01L7.36 6.185H7v3.42h.338zM11.918 6.936c-.714 0-1.112.52-1.112 1.223v.258c0 .708.394 1.23 1.112 1.23.717 0 1.109-.522 1.109-1.23V8.16c0-.704-.396-1.223-1.109-1.223zm0 .323c.466 0 .74.349.74.918v.221c0 .572-.274.92-.74.92-.47 0-.742-.35-.742-.92v-.22c0-.57.274-.919.742-.919zM13.717 9.605h.369V7.997c0-.376.206-.723.687-.723.412 0 .676.248.676.696v1.633h.368V7.915c0-.638-.404-.982-.945-.982-.432 0-.678.226-.775.402h-.011v-.359h-.369v2.63zM17.514 6.933c-.652 0-1.05.51-1.05 1.222v.268c0 .726.368 1.222 1.05 1.222.377 0 .647-.19.788-.45h.009v.41h.349V6h-.37v1.354h-.009a.863.863 0 00-.767-.42zm.034.33c.466 0 .752.366.752.908v.244c0 .565-.278.903-.748.903-.416 0-.719-.304-.719-.905v-.238c-.001-.614.308-.912.715-.912zM20.941 9.264h.01v.341h.359V7.78c0-.54-.396-.843-.943-.843-.609 0-.923.318-.954.775h.349c.03-.278.228-.457.59-.457.373 0 .59.2.59.568v.283h-.715c-.6.002-.916.293-.916.748 0 .481.349.795.859.795.4-.001.645-.173.771-.384zm-.685.066c-.296 0-.564-.159-.564-.484 0-.256.163-.44.547-.44h.702v.324c0 .353-.293.6-.685.6zM24.026 6.979h-.394l-.75 2.197h-.013l-.75-2.197h-.41l.98 2.668-.05.16c-.077.272-.21.43-.52.43-.056 0-.15-.007-.192-.016v.314c.066.01.163.02.251.02.511 0 .687-.35.825-.73l.075-.196.948-2.65z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_2003:61046">
        <path fill="#fff" d="M0 0H32V32H0z"></path>
      </clipPath>
    </defs>
  </svg>
)
export default Icalendar
