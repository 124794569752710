const Book = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.502 3.95a.501.501 0 00-.002.06v8.648a.497.497 0 00.448.506.498.498 0 00.31-.068 5.5 5.5 0 015.485 0 .498.498 0 00.514 0 5.5 5.5 0 015.486 0 .493.493 0 00.309.068.498.498 0 00.448-.506V4.01a.518.518 0 00-.068-.261.496.496 0 00-.19-.187A6.5 6.5 0 008 3.43a6.5 6.5 0 00-6.294.165.497.497 0 00-.204.355zm11.998.347a5.5 5.5 0 00-5 0v7.566a6.5 6.5 0 015 0V4.297zm-6 0v7.566a6.5 6.5 0 00-5 0V4.297a5.5 5.5 0 015 0z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Book
