import { configureStore } from '@reduxjs/toolkit'
import dashboardSlice from './dashboard/dashboardSlice'
import faqSlice from './faq/faqSlice'
import profileSlice from './profile/profileSlice'
import myFavoriteSlice from './my-favorite/myFavoriteSlice'
import { authSlice } from './authLogin/authSlice'
import qbankSlice from './qbank/qbankSlice'
import skillTrainerSlice from './skill-trainer/skillTrainerSlice'
import communitySlice from './community/communitySlice'
import discussionSlice from './community/discussionSlice'
import discussionDescSlice from './community/discussionDescSlice'
import learnSlice from './learn/learnSlice'
import resourceSlice from './learn/resourceSlice'
import interviewGuideSlice from './interviews/interviewGuideSlice'
import bookmarkSlice from './bookmark/bookmarkSlice'
import calendarSlice from './calendar/calendarSlice'
import keywordScanningSlice from './skill-trainer/keywordScanningSlice'
import speedReadingSlice from './skill-trainer/speedReadingSlice'
import vennDiagramSlice from './skill-trainer/vennDiagramSlice'
import performanceGraphSlice from './skill-trainer/performanceGraphSlice'
import leaderBoardSlice from './skill-trainer/leaderBoardSlice'
import numericKeypadSlice from './skill-trainer/numericKeypadSlice'
import examPortalSlice from './exam-portal/examPortalSlice'
import floatingMenuSlice from './exam-portal/floatingMenuSlice'
import scratchpadSlice from './exam-portal/scratchpadSlice'
import personalInfoSlice from './profile/personalInfoSlice'
import settingSlice from './profile/settingSlice'
import changePasswordSlice from './profile/changePasswordSlice'
import workshopSlice from './live-events/workshopSlice'
import badgesSlice from './profile/badgesSlice'
import contactReasonSlice from './contact-reason/contactReasonSlice'
import tourStepsSlice from './tour-steps/tourStepsSlice'
import subtestMocksSlice from './analytics/history/subtestMocksSlice'
import fullExamMocksSlice from './analytics/history/fullExamMocksSlice'
import practiceDataSlice from './analytics/history/practiceDataSlice'
import subtestReviewSlice from './analytics/history/subtestReviewSlice'
import fullExamReviewSlice from './analytics/history/fullExamReviewSlice'
import reviewQuestionsSlice from './analytics/history/reviewQuestionsSlice'
import errorLogSlice from './analytics/history/errorLogSlice'
import errorReviewSlice from './analytics/error-log/errorReviewSlice'
import extraExperiencesSlice from './university-admissions/extraExperiencesSlice'
import weeklyQuestionSlice from './community/weeklyQuestionSlice'
import memeSlice from './community/memeSlice'
import universityAdmissionSlice from './university-admission/universityAdmissionSlice'
import classRecordingsSlice from './live-events/classRecordingsSlice'
import myJourneySlice from './my-journey/myJourneySlice'
import notesSlice from './learn/notesSlice'
import resourceQuestionsSlice from './learn/resourceQuestionsSlice'
import arithmeticSlice from './skill-trainer/arithmeticSlice'
import abstractReasoningSlice from './skill-trainer/abstractReasoningSlice'
import syllogismsSlice from './skill-trainer/syllogismsTrainerSlice'
import qbankSubtestSlice from './qbank/qbankSubtestSlice'
import qbankFullExamSlice from './qbank/qbankFullExamSlice'
import graphTableTrainerSlice from './skill-trainer/graphTableTrainerSlice'
import purchasesSlice from './profile/purchasesSlice'
import universitySpecificSlice from './interviews/universitySpecificSlice'
import groupSlice from './profile/groupSlice'
import bookingSlice from './live-events/bookingSlice'
import performanceSlice from './analytics/performance/performanceSlice'
import retryAttemptSlice from './analytics/history/retryAttemptSlice'
import paymentSlice from './profile/paymentSlice'
import resetAttemptSlice from './profile/resetAttemptSlice'
import termsAndConditionsSlice from './terms-and-conditions/termsAndConditionsSlice'
import HpatPortalSlice from './hpat-portal/hpatPortalSlice'
import emotionsWordGameSlice from './hpat-skill-trainers/emotionsWordGameSlice'
import eurekaSlice from './hpat-skill-trainers/eurekaSlice'
import tutorSlice from './tutor/tutorSlice'
import tutoringSlice from './tutoring/tutoringSlice'
import unsubscribeEmailSlice from './profile/unsubscribeEmailSlice'
import orientationSlice from './orientation/orientationSlice'
import personalisedBookingSlice from './interviews/personalisedBookingSlice'
import walkthroughSlice from './walkthrough/walkthroughSlice'
import chatbotSlice from './chat/chatbotSlice'
import bondTrainerSlice from './skill-trainer/bondTrainerSlice'
export default configureStore({
  reducer: {
    hpatPortal: HpatPortalSlice,
    dashboard: dashboardSlice,
    faq: faqSlice,
    profile: profileSlice,
    practice: qbankSlice,
    myFavorite: myFavoriteSlice,
    auth: authSlice.reducer,
    skillTrainer: skillTrainerSlice,
    community: communitySlice,
    discussion: discussionSlice,
    discussionDesc: discussionDescSlice,
    learn: learnSlice,
    resource: resourceSlice,
    interviews: interviewGuideSlice,
    bookmark: bookmarkSlice,
    calendar: calendarSlice,
    keywordScanning: keywordScanningSlice,
    speedReading: speedReadingSlice,
    vennDiagram: vennDiagramSlice,
    performanceGraph: performanceGraphSlice,
    leaderBoard: leaderBoardSlice,
    numericKeypad: numericKeypadSlice,
    examPortal: examPortalSlice,
    floatingMenu: floatingMenuSlice,
    scratchpad: scratchpadSlice,
    personalInfo: personalInfoSlice,
    settings: settingSlice,
    workshop: workshopSlice,
    changePassword: changePasswordSlice,
    badges: badgesSlice,
    contactReason: contactReasonSlice,
    tourSteps: tourStepsSlice,
    subtestMocks: subtestMocksSlice,
    fullExamMocks: fullExamMocksSlice,
    practiceData: practiceDataSlice,
    subtestReview: subtestReviewSlice,
    fullExamReview: fullExamReviewSlice,
    errorLogData: errorLogSlice,
    errorReview: errorReviewSlice,
    extraExperiences: extraExperiencesSlice,
    weeklyQuestion: weeklyQuestionSlice,
    memes: memeSlice,
    university: universityAdmissionSlice,
    reviewQuestions: reviewQuestionsSlice,
    classRecordings: classRecordingsSlice,
    myJourney: myJourneySlice,
    notes: notesSlice,
    resourceQuestions: resourceQuestionsSlice,
    arithmetic: arithmeticSlice,
    abstractReasoning: abstractReasoningSlice,
    syllogisms: syllogismsSlice,
    qbankSubtest: qbankSubtestSlice,
    qbankFullExam: qbankFullExamSlice,
    graphTable: graphTableTrainerSlice,
    purchases: purchasesSlice,
    universitySpecific: universitySpecificSlice,
    group: groupSlice,
    booking: bookingSlice,
    performance: performanceSlice,
    retryExam: retryAttemptSlice,
    payment: paymentSlice,
    resetAttempt: resetAttemptSlice,
    termsAndConditions: termsAndConditionsSlice,
    emotionsWordGame: emotionsWordGameSlice,
    eureka: eurekaSlice,
    tutor: tutorSlice,
    tutoring: tutoringSlice,
    unsubscribeEmail: unsubscribeEmailSlice,
    orientation: orientationSlice,
    personalisedBooking: personalisedBookingSlice,
    walkthrough: walkthroughSlice,
    chatbot: chatbotSlice,
    bondTrainer: bondTrainerSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
