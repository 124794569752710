const Syllogisms = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="current"
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      fill="current"
      fillRule="evenodd"
      d="M12 3.75a3.25 3.25 0 100 6.5 3.25 3.25 0 000-6.5zM7.25 7a4.75 4.75 0 119.5 0 4.75 4.75 0 01-9.5 0zm-.75 6.75a3.25 3.25 0 100 6.5 3.25 3.25 0 000-6.5zM1.75 17a4.75 4.75 0 119.5 0 4.75 4.75 0 01-9.5 0zm12.5 0a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0zm3.25-4.75a4.75 4.75 0 100 9.5 4.75 4.75 0 000-9.5z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default Syllogisms
