import { Fragment } from 'react'
import { Loading } from 'components/icons'
import { Transition, Dialog } from '@headlessui/react'
import { Button } from 'components/shared'
import { Link } from 'react-router-dom'
const TermsConditionModal = ({ open, isLoading, onSubmit, onClose }) => {
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="bg-gray-700 backdrop-filter backdrop-blur-sm bg-opacity-80 fixed max-w-full max-h-screen h-screen w-full inset-0 z-50  overflow-y-auto"
          onClose={() => {}}
        >
          <div className="min-h-screen h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-top"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                style={{ maxWidth: '762px' }}
                className="inline-block overflow-y-hidden max-w-full md:max-w-md  md:h-auto w-full p-5 mt-16 md:mt-12 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-dark-600 shadow-2xl rounded-md  "
              >
                <div className="px-1 py-11 sm:p-11  flex flex-col justify-between">
                  <Dialog.Title
                    as="h2"
                    className="mt-8 text-2xl  font-semibold leading-6 text-gray-900 dark:text-white"
                  >
                    Accept Terms and Conditions
                  </Dialog.Title>
                  <div className="html-renderer-container">
                    <p className="text-base font-normal text-gray-900 dark:text-white">
                      This is in addition to the Terms and Conditions agreed to
                      when you first logged in to the MedEntry Online Learning
                      Platform.
                    </p>
                    <p className="text-base font-normal text-gray-900 dark:text-white">
                      The purchase of MedEntry product is for a single licence
                      for the exclusive and sole use of the purchaser only. The
                      content should not be shared by any means to any other
                      ineligible / unauthorised party. Any activity that does
                      not comply by these terms will be deemed a breach of the
                      Copyright Act 1968 (Cth) or other applicable laws.
                    </p>
                    <p className="text-base font-semibold text-gray-900 dark:text-white">
                      Any reproduction, ‘screenshotting’, modification,
                      distribution, transmission, republication, display,
                      re-distribution, on-selling, auctioning, republication,
                      sharing, use for private coaching/tuition/performance
                      either during or after your enrolment or any other such
                      purpose is strictly prohibited and will be considered a
                      breach of copyright.
                    </p>
                    <p className="text-base font-semibold text-gray-900 dark:text-white">
                      Any infringement of these rules will result in:
                    </p>
                    <ul className="text-gray-900 dark:text-white">
                      <li>
                        Immediate termination of access to MedEntry’s resources.
                      </li>
                      <li>
                        Forfeiture of payment made with no entitlement to
                        refunds.
                      </li>
                      <li>
                        Criminal prosecution. Infringement of copyrighted works
                        constitutes a breach of the Copyright Act and will
                        result in legal action.
                      </li>
                    </ul>
                  </div>
                  <div className="flex w-full justify-between">
                    <Button
                      className="mt-10 flex items-center  font-semibold tracking-widest py-4 px-6"
                      onClick={() => onSubmit && onSubmit()}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <Loading className="mr-4" />
                          Loading
                        </>
                      ) : (
                        'I agree'
                      )}
                    </Button>
                    <Link to="/interviews/university-specific-questions">
                      <Button
                        className="mt-10 flex items-center  font-semibold tracking-widest py-4 px-6"
                        outlined
                      >
                        I decline
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default TermsConditionModal
