import { Dialog, Transition } from '@headlessui/react'
import { Close } from 'components/icons'
import { Fragment } from 'react'

const PostModal = ({ postModal, setPostModal }) => {
  const closeModel = () => {
    setPostModal({ ...PostModal, visible: false })
  }

  return (
    <Transition appear show={postModal.visible} as={Fragment}>
      <Dialog
        as="div"
        className="bg-gray-700 bg-opacity-80 fixed max-w-full max-h-screen h-screen w-full inset-0 z-50  overflow-y-auto"
        onClose={closeModel}
      >
        <div className="min-h-screen h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          <span className="inline-block h-screen align-top" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block overflow-y-auto min-h-screen  md:h-auto w-full  overflow-hidden text-left transition-all transform">
              <span
                className="cursor-pointer float-right mt-5"
                onClick={closeModel}
              >
                <Close className="fill-current text-white h-8 w-8" />
              </span>

              <div className="mt-20 p-5 flex place-content-center">
                <img alt="meme-img" src={postModal?.data?.imgUrl}></img>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default PostModal
