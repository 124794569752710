import { Tooltip } from 'components/form'
import { formatTimestamp } from 'components/shared/helper'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getNotesForVideo,
  setIsVideoFinished,
  setNotes,
  setPlayedSeconds,
  setSelectedNote,
  setTranscriptNoteModal,
} from 'redux/learn/resourceSlice'

const VideoTranscript = ({ playerRef }) => {
  const dispatch = useDispatch()
  const {
    transcriptData,
    playedSeconds,
    playedPIPSeconds,
    isPIPActive,
    videoNoteList,
    selectedNote,
    bookDetails,
    transcriptNotes,
  } = useSelector((state) => ({
    transcriptData: state.resource.transcriptData,
    playedSeconds: state.resource.playedSeconds,
    playedPIPSeconds: state.resource.playedPIPSeconds,
    isPIPActive: state.resource.isPIPActive,
    videoNoteList: state.resource.videoNoteList,
    selectedNote: state.resource.selectedNote,
    bookDetails: state.resource.bookDetails,
    transcriptNotes: state.resource.transcriptNotes,
  }))

  const [selectedIndex, setSelectedIndex] = useState(null)
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(null)
  const [hoverParagraphIndex, setHoverParagraphIndex] = useState(null)
  const [hoverIndex, setHoverIndex] = useState(null)

  useEffect(() => {
    dispatch(getNotesForVideo({ book_chapter_id: bookDetails?.id }))
  }, [bookDetails?.id, dispatch])

  const handleTextOnClick = ({ index, segmentIndex }) => {
    setSelectedIndex(index)
    setSelectedParagraphIndex(segmentIndex)
  }

  const handleTextMouseMove = ({ index, segmentIndex }) => {
    setHoverIndex(segmentIndex)
    setHoverParagraphIndex(index)
  }

  const handleTextMouseLeave = () => {
    setHoverIndex(null)
    setHoverParagraphIndex(null)
  }

  const highlightClass = ({ segment }) => {
    if (
      isPIPActive &&
      playedPIPSeconds >= segment?.start &&
      playedPIPSeconds <= segment?.end
    ) {
      return 'bg-blue-500 bg-opacity-20'
    } else if (
      playedSeconds >= segment?.start &&
      playedSeconds <= segment?.end
    ) {
      return 'bg-blue-500 bg-opacity-20'
    } else {
      return ''
    }
  }

  const getCurrentSubtitle = (currentTime) => {
    const currentCue = videoNoteList.find(
      (cue) => cue.start <= currentTime && cue.end > currentTime,
    )
    return currentCue ? currentCue : null
  }

  const addNoteOnSelectedText = (time) => {
    const currentSubtitle = getCurrentSubtitle(time)

    dispatch(setSelectedNote(currentSubtitle))
    setSelectedIndex(null)
  }

  const addNoteButtonRef = useRef(null)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        selectedIndex &&
        addNoteButtonRef.current &&
        !addNoteButtonRef.current.contains(e.target)
      ) {
        setSelectedIndex(null)
        setSelectedParagraphIndex(null)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside, true)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside, true)
    }
  }, [selectedIndex])

  const isAddedNote = (segment) => {
    const note =
      transcriptNotes?.notes?.length > 0 &&
      transcriptNotes?.notes?.find(
        (note) =>
          note?.data?.transcript_start_time === parseInt(segment?.start) &&
          note?.data?.transcript_end_time === parseInt(segment?.end),
      )
    if (note) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="py-4 dark:text-white disable-copy">
      {transcriptData?.length > 0 ? (
        transcriptData?.map((paragraph, index) => (
          <div key={index}>
            <div className="py-2 cursor-pointer">
              {paragraph?.text?.length > 0 &&
                paragraph?.text?.map((segment, segmentIndex) => (
                  <span key={segmentIndex} className="relative">
                    {selectedIndex === index &&
                      selectedParagraphIndex === segmentIndex && (
                        <button
                          ref={addNoteButtonRef}
                          className="absolute top-0 -mt-9 px-2 py-1 bg-dark-500 dark:bg-dark-400 text-white rounded"
                          onClick={() => {
                            dispatch(setTranscriptNoteModal(true))
                            addNoteOnSelectedText(segment?.start)
                          }}
                        >
                          Add Note
                        </button>
                      )}
                    {selectedIndex === index &&
                    selectedParagraphIndex === segmentIndex ? (
                      <span
                        // key={wordIndex}
                        className={`${
                          selectedIndex !== index &&
                          selectedParagraphIndex !== segmentIndex &&
                          highlightClass({ segment })
                        } ${
                          selectedIndex === index &&
                          selectedParagraphIndex === segmentIndex
                            ? 'bg-red-500 bg-opacity-20'
                            : ''
                        }`}
                      >
                        {segment.text}{' '}
                      </span>
                    ) : (
                      <Tooltip
                        text={`${formatTimestamp(segment.start)}`}
                        side="left"
                        className={`segment-${segmentIndex}`}
                      >
                        <span
                          className={`${highlightClass({
                            segment,
                          })} ${
                            isAddedNote(segment)
                              ? 'bg-red-500 bg-opacity-50'
                              : ''
                          } ${
                            hoverParagraphIndex === index &&
                            hoverIndex === segmentIndex &&
                            selectedIndex !== segmentIndex
                              ? 'bg-blue-500 bg-opacity-40'
                              : ''
                          }
                          `}
                          onMouseMove={() =>
                            handleTextMouseMove({ index, segmentIndex })
                          }
                          onMouseLeave={handleTextMouseLeave}
                          onClick={() => {
                            if (isAddedNote(segment)) {
                              playerRef.current.seekTo(parseInt(segment.start))
                              dispatch(setIsVideoFinished(false))
                              // setIsPlaying(false)
                              const res =
                                document?.querySelector('.video-player')
                              res?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center',
                                inline: 'center',
                              })
                            } else {
                              handleTextOnClick({ index, segmentIndex })
                            }
                          }}
                        >
                          {segment.text}{' '}
                          {/* {segmentIndex === 0 ? ' ' : ' '} */}
                          {/* {word}  */}
                        </span>
                      </Tooltip>
                    )}
                    {/* ))} */}
                  </span>
                ))}
            </div>
          </div>
        ))
      ) : (
        <>Transcript coming soon</>
      )}
    </div>
  )
}

export default VideoTranscript
