const Chat = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.215 2.549a.167.167 0 01.118-.049h6a.167.167 0 01.167.167v5.46L12.354 6.98A.5.5 0 0012 6.833H7.333a.167.167 0 01-.166-.166v-4c0-.045.017-.087.048-.118zM7.333 1.5a1.167 1.167 0 00-1.166 1.167v4a1.167 1.167 0 001.166 1.166h4.46l1.853 1.854a.5.5 0 00.854-.354V2.667A1.167 1.167 0 0013.333 1.5h-6zM2.55 7.215a.167.167 0 01.118-.048H4a.5.5 0 000-1H2.667A1.167 1.167 0 001.5 7.333V14a.5.5 0 00.854.354L4.207 12.5h4.46a1.167 1.167 0 001.166-1.167V10a.5.5 0 10-1 0v1.333a.167.167 0 01-.166.167H4a.5.5 0 00-.354.146L2.5 12.793v-5.46c0-.044.018-.086.049-.118z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Chat
