const ChevronDown = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.98 6.313a.5.5 0 01.707 0L8 8.626l2.313-2.313a.5.5 0 11.707.707L8.353 9.687a.5.5 0 01-.707 0L4.98 7.02a.5.5 0 010-.707z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default ChevronDown
