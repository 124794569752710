import { Arrowleft, Loading } from 'components/icons'
import { MInput, MTextarea } from 'components/form'
import { Button } from 'components/shared'
import { useDispatch, useSelector } from 'react-redux'
import {
  createResourceQuestion,
  markResourceAgreement,
  closeAgreementModal,
  setDiscussionSubmission,
} from 'redux/learn/resourceQuestionsSlice'
import toast from 'react-hot-toast'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { CommunityTermsModal } from 'components/dashboard'
import { updateDiscussionNumber } from 'redux/learn/resourceSlice'
import { updateQuestionDiscussionNumber } from 'redux/analytics/history/reviewQuestionsSlice'
const resourceQuestionSchema = Yup.object({
  question_subject: Yup.string()
    .required('Subject is required')
    .max(
      100,
      `The subject must be less than 100 character.
        `,
    ),
})

const AskQuestion = ({ setOpenAskQuestion, typeOfQuestion, bookChapterId }) => {
  const dispatch = useDispatch()
  const { createQuestionLoader, resourceAgreementData } = useSelector(
    (state) => ({
      createQuestionLoader: state.resourceQuestions.createQuestionLoader,
      resourceAgreementData: state.resourceQuestions.resourceAgreementData,
    }),
  )

  return (
    <div>
      <CommunityTermsModal
        open={resourceAgreementData?.data?.discussion_terms_agreed === false}
        agreement={resourceAgreementData?.data}
        isLoading={resourceAgreementData?.isLoading}
        onSubmit={() => dispatch(markResourceAgreement())}
        onClose={() => dispatch(closeAgreementModal())}
      />
      <button className="flex mt-7" onClick={() => setOpenAskQuestion(false)}>
        <div className="mr-4 mt-0.5">
          <Arrowleft className="fill-current dark:text-white" />
        </div>
        <div>
          <p className="text-xs text-gray-900 dark:text-white font-semibold">
            BACK TO ALL QUESTIONS
          </p>
        </div>
      </button>
      <Formik
        initialValues={{
          question_subject: '',
          question_text: '',
        }}
        validationSchema={resourceQuestionSchema}
        onSubmit={(values, { resetForm, setFieldError }) => {
          dispatch(
            createResourceQuestion({
              data: {
                type: typeOfQuestion,
                type_id: bookChapterId,
                question_subject: values.question_subject,
                question_text: values.question_text,
              },
              onSuccess: (res) => {
                if (typeOfQuestion === 'chapter') {
                  dispatch(
                    updateDiscussionNumber({
                      bookChapterId,
                      numResourceQuestions: res?.num_discussions_now,
                    }),
                  )
                } else if (typeOfQuestion === 'question') {
                  dispatch(
                    updateQuestionDiscussionNumber({
                      bookChapterId,
                      numResourceQuestions: res?.num_discussions_now,
                    }),
                  )
                }

                resetForm()
                setOpenAskQuestion(false)
                dispatch(setDiscussionSubmission(true))
              },
              onError: (errorsMassage) => {
                toast.error(errorsMassage.message, {
                  duration: 3000,
                  position: 'top-center',
                  style: {
                    backdropFilter: 'blur(4px)',
                    marginTop: '20px',
                    width: '350px',
                  },
                })
                Object.keys(errorsMassage.errors).forEach((error) =>
                  setFieldError(error, errorsMassage[error]),
                )
              },
            }),
          )
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div>
              <p className="text-gray-900 dark:text-white text-2xl font-semibold mt-6">
                Ask a Question
              </p>
              <div className="w-full mt-6">
                <MInput
                  name="question_subject"
                  type="text"
                  label="Subject"
                  placeholder="e.g. Example goes here"
                  className="border border-gray-500  w-full rounded-md py-3 px-4"
                />
              </div>
              <div>
                <MTextarea
                  label=" Details (optional)"
                  name="question_text"
                  type="text"
                  rows="4"
                  cols="40"
                  className="  w-full  border rounded-md px-3 py-4 mt-1 border-gray-500 dark:border-dark-400 dark:bg-dark-500 dark:text-white"
                  placeholder="e.g. Example goes here"
                ></MTextarea>
                <div className="my-6">
                  <Button
                    type="submit"
                    className={`py-4 px-6 uppercase tracking-widest ${
                      createQuestionLoader && 'pointer-events-none'
                    }`}
                  >
                    {createQuestionLoader ? (
                      <Loading className="text-white" />
                    ) : (
                      ' Publish'
                    )}
                  </Button>
                </div>
              </div>
            </div>{' '}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AskQuestion
