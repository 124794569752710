const Tablefilter = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#A0A0A0"
      fillRule="evenodd"
      d="M6.167 8a.5.5 0 01.5-.5h6a.5.5 0 110 1h-6a.5.5 0 01-.5-.5zm0 4a.5.5 0 01.5-.5h4.666a.5.5 0 010 1H6.667a.5.5 0 01-.5-.5zm.5 3.5a.5.5 0 000 1h4.666a.5.5 0 000-1H6.667zm6.98-1.854a.5.5 0 01.707 0l1.146 1.147V8a.5.5 0 111 0v6.793l1.146-1.147a.5.5 0 01.707.708l-2 2a.498.498 0 01-.707 0l-2-2a.5.5 0 010-.708z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default Tablefilter
