const Question = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 2.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 10.833a.5.5 0 01.5.5v.007a.5.5 0 01-1 0v-.007a.5.5 0 01.5-.5zm.054-6.685a2.233 2.233 0 00-1.786.883.5.5 0 10.797.605A1.233 1.233 0 118.493 7.53a1.5 1.5 0 00-.992 1.497.5.5 0 00.998-.056.5.5 0 01.334-.5l.012-.005a2.233 2.233 0 00-.791-4.32z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Question
