import { useState, Fragment, useEffect } from 'react'
import { Button, Spinner, SmallLogo } from 'components/shared'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { getMyTutorResource } from 'redux/tutor/tutorSlice'
import TutorReply from './TutorReply'
import TutorAskQuestion from './TutorAskQuestion'
import { Tooltip } from 'components/form'

const TutorQuestion = ({
  setOpenReplyPage,
  openReplyPage,
  tutorType,
  tutorId,
}) => {
  const dispatch = useDispatch()
  const { userData, myTutorResource, bookDetails } = useSelector((state) => ({
    userData: state.personalInfo.userData,
    myTutorResource: state.tutor.myTutorResource,
    bookDetails: state.resource.bookDetails,
  }))
  const tutoringDiscussionAccessCheck = bookDetails?.discussable_with_tutor

  const [openTutorAskQuestion, setOpenTutorAskQuestion] = useState(false)

  useEffect(() => {
    dispatch(getMyTutorResource({ type: tutorType, id: tutorId }))
  }, [dispatch, tutorId, tutorType])

  if (!!openTutorAskQuestion) {
    return (
      <TutorAskQuestion
        tutorId={tutorId}
        tutorType={tutorType}
        setOpenAskQuestion={setOpenTutorAskQuestion}
      />
    )
  }

  if (openReplyPage) {
    return <TutorReply setOpenReplyPage={setOpenReplyPage} />
  } else {
    return (
      <div>
        {myTutorResource?.isLoading ? (
          <div className="my-6">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="sm:flex mt-10 sm:justify-between sm:items-center">
              <h3 className="text-xl leading-6 font-semibold text-gray-900 dark:text-white">
                {myTutorResource?.data?.resource_discussions.length !== 0 ? (
                  <>
                    {myTutorResource?.data?.resource_discussions.length}{' '}
                    {myTutorResource?.data?.resource_discussions.length > 1
                      ? 'Discussions'
                      : 'Discussion'}{' '}
                  </>
                ) : (
                  ''
                )}
              </h3>
              <div className=" ">
                <Tooltip
                  text={tutoringDiscussionAccessCheck?.discussion_request_title}
                >
                  <div>
                    <Button
                      className="sm:mt-0 mt-6 sm:w-auto w-full py-4 px-6 font-semibold tracking-widest"
                      onClick={() => setOpenTutorAskQuestion(true)}
                      outlined
                    >
                      Add discussion note
                    </Button>
                  </div>
                </Tooltip>
              </div>
            </div>

            {myTutorResource?.data?.resource_discussions?.length > 0 ? (
              <div className="border border-gray-100 dark:border-dark-500 mt-10" />
            ) : (
              ''
            )}
            {myTutorResource?.data?.resource_discussions?.map(
              (data, dataIndex) => (
                <div
                  key={dataIndex}
                  className="border-b border-gray-100 dark:border-dark-500"
                >
                  <div className="py-4 flex w-full ">
                    <div className="mt-2">
                      {data?.user?.avatar_url ? (
                        <img
                          style={{ minHeight: '40px', minWidth: '40px' }}
                          className="rounded-full h-10 w-10"
                          src={data?.user?.avatar_url}
                          alt={data?.user?.first_name}
                        />
                      ) : (
                        <SmallLogo className="h-8 w-8 " />
                      )}
                    </div>
                    <div className="flex flex-col ml-3 text-gray-900 dark:text-white w-full">
                      <p className="font-semibold leading-5 text-base">
                        {data?.question_subject}
                      </p>
                      <span className="break-all font-noraml mt-2 leading-6 text-gray-900 dark:text-white text-base sm:w-4/5">
                        {data?.question_asked?.length > 70
                          ? `${data?.question_asked?.substring(0, 70)}...`
                          : data?.question_asked}
                      </span>
                      <span className="mt-1 sm:flex leading-5 items-center justify-between text-gray-500 dark:text-dark-300 text-sm font-normal">
                        {data?.student?.first_name}
                        {data?.student?.first_name && ' / '}
                        {data?.type_breadcrumbs?.join(', ')}
                        {data?.type_breadcrumbs && ' / '}
                        {userData?.timezone
                          ? moment(data?.created_at)
                              ?.tz(userData?.timezone)
                              ?.fromNow()
                          : moment?.utc(data?.created_at)?.fromNow()}
                      </span>
                    </div>
                  </div>
                </div>
              ),
            )}
          </>
        )}
      </div>
    )
  }
}
export default TutorQuestion
