import { QuestionMark } from 'components/icons'
import { FreeTrial, Hexagon, RoundedBadge, Spinner } from 'components/shared'
import {
  rankColors,
  badgeSmallColors,
  badgesColorByArea,
  communityBadgesColors,
} from 'components/shared/helper'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getBadgesData } from 'redux/dashboard/dashboardSlice'

const Achievements = () => {
  const dispatch = useDispatch()
  const { userBadgesData } = useSelector((state) => ({
    userBadgesData: state.dashboard.userBadgesData,
  }))
  useEffect(() => {
    dispatch(getBadgesData())
  }, [dispatch])
  const getBadge = (data, index) => {
    return (
      <div key={index}>
        {data?.badge?.shape === 'circle' ? (
          <RoundedBadge
            minWidth="auto"
            color={rankColors[data.badge.slug.split('-')[0]]}
            smallIconColor="green"
            smallIcon={data.badge?.small_icon}
            tooltipText={
              <div className="html-renderer-container">
                <span className="text-white font-semibold text-base ">
                  {data.badge && data.badge.name}
                </span>
                <br />
                <span className="mt-1 text-gray-500 text-sm font-normal">
                  {data.badge && data.badge?.area}
                </span>
                <div className="mt-2">
                  {data.badge &&
                    data.badge.objectives &&
                    data.badge.objectives.map((data, index) => (
                      <li key={index}>{data}</li>
                    ))}
                </div>
              </div>
            }
            icon={
              data.badge &&
              data.badge.icon && (
                <img src={data.badge.icon.url} alt={data.badge.icon.name} />
              )
            }
          />
        ) : (
          <Hexagon
            minWidth="auto"
            key={index}
            color={
              data.badge?.area === 'Community'
                ? communityBadgesColors[data.badge.slug] || 'gray'
                : badgesColorByArea[data.badge?.area] || 'green'
            }
            smallIconColor={
              badgeSmallColors[data.badge?.area_slug]
                ? badgeSmallColors[data.badge?.area_slug]
                : 'green'
            }
            smallIcon={data.badge?.small_icon}
            icon={
              data.badge &&
              data.badge.icon && (
                <img src={data.badge.icon.url} alt={data.badge.icon.name} />
              )
            }
            tooltipText={
              <div className="html-renderer-container">
                <span className="text-white font-semibold text-base ">
                  {data.badge && data.badge.name}
                </span>
                <br />
                <span className="mt-1 text-gray-500 text-sm font-normal">
                  {data.badge && data.badge.area}
                </span>
                <div className="mt-2">
                  {data.badge &&
                    data.badge.objectives &&
                    data.badge.objectives.map((data, index) => (
                      <li key={index}>{data}</li>
                    ))}
                </div>
              </div>
            }
          />
        )}
      </div>
    )
  }
  const getShadowClassName = () => {
    if (localStorage.theme === 'Dark') {
      return 'dark-shadow-dashboard'
    } else if (
      !('theme' in localStorage) &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      return 'dark-shadow-dashboard'
    } else {
      return 'light-shadow-dashboard'
    }
  }
  return (
    <>
      {userBadgesData && userBadgesData.isLocked ? (
        <div className="flex flex-col items-center justify-center h-full py-3">
          <FreeTrial flexRow text="To unlock" />
        </div>
      ) : userBadgesData.isLoading ? (
        <div className="py-3">
          <Spinner />
        </div>
      ) : userBadgesData.data &&
        userBadgesData.data.recent_badges &&
        userBadgesData.data.recent_badges.length > 0 ? (
        <>
          <p className="text-sm font-normal text-gray-700 dark:text-dark-100 mt-2">
            Check out your most recently acquired badges{' '}
          </p>
          <div className="flex items-center relative overflow-hidden">
            <div className="hidden md:flex w-full h-28 flex-wrap justify-around mr-14  items-center mt-6">
              {[...Array(4)].map((badge, index) => {
                const data = userBadgesData.data.recent_badges[index]
                if (userBadgesData.data.recent_badges[index]) {
                  return getBadge(data, index)
                } else {
                  return (
                    <Hexagon
                      key={index}
                      icon={<QuestionMark className="dark:text-white" />}
                      isEmpty={true}
                    />
                  )
                }
              })}
            </div>
            <div
              className={`${getShadowClassName()} absolute z-20 -right-5 w-24 h-full`}
            ></div>
            <div className="absolute  bg-white dark:bg-dark-600 z-20 -right-10">
              <Hexagon
                minWidth="auto"
                icon={<QuestionMark className="dark:text-white" />}
                className="mt-6"
                isEmpty={true}
              />
            </div>
          </div>
          <div className="flex items-center relative overflow-hidden">
            <div className="hidden sm:flex md:hidden w-full h-28 flex-wrap justify-between mr-14  items-center mt-6">
              {[...Array(6)].map((badge, index) => {
                const data = userBadgesData.data.recent_badges[index]
                if (userBadgesData.data.recent_badges[index]) {
                  return getBadge(data, index)
                } else {
                  return (
                    <Hexagon
                      key={index}
                      minWidth="auto"
                      icon={<QuestionMark className="dark:text-white" />}
                      isEmpty={true}
                    />
                  )
                }
              })}
            </div>
            <div
              className={`${getShadowClassName()} absolute z-20 -right-5 w-24 h-full`}
            ></div>
            <div className="absolute bg-white dark:bg-dark-600 z-20 -right-12">
              <Hexagon
                minWidth="auto"
                className="mt-6"
                isEmpty={true}
                icon={<QuestionMark className="dark:text-white" />}
              />
            </div>
          </div>
          <div className="flex items-center relative overflow-hidden">
            <div className="sm:hidden flex md:hidden w-full h-28 flex-wrap justify-between items-center mt-6">
              {[...Array(2)].map((badge, index) => {
                const data = userBadgesData.data.recent_badges[index]
                if (userBadgesData.data.recent_badges[index]) {
                  return getBadge(data, index)
                } else {
                  return (
                    <Hexagon
                      key={index}
                      minWidth="auto"
                      icon={<QuestionMark className="dark:text-white" />}
                      isEmpty={true}
                    />
                  )
                }
              })}
              <div
                className={`${getShadowClassName()} absolute z-20 -right-5 w-24 h-full`}
              ></div>
              <Hexagon
                minWidth="auto"
                className=""
                icon={<QuestionMark className="dark:text-white" />}
                isEmpty={true}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <p className="text-sm font-normal text-gray-700 dark:text-dark-100 mt-2">
            You do not have any badges yet
          </p>
          <div className="flex items-center relative overflow-hidden">
            <div className="flex w-full h-28 flex-wrap justify-around mr-20  items-center mt-6">
              {[...Array(6)].map((data, id) => (
                <div key={id} className="-ml-6">
                  <Hexagon
                    icon={<QuestionMark className="dark:text-white" />}
                    isEmpty={true}
                  />
                </div>
              ))}
              <div
                className={`${getShadowClassName()} absolute z-20 -right-5 w-24 h-full`}
              ></div>
              <div className="absolute bg-white dark:bg-dark-600 z-20 -right-16">
                <Hexagon
                  className=""
                  icon={<QuestionMark className="dark:text-white" />}
                  isEmpty={true}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default Achievements
