import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getSkilltrainerScores = createAsyncThunk(
  'skillTrainer/getSkilltrainerScores',
  async () => {
    try {
      const res = await axiosInterceptors.get('/trainer')
      return res
    } catch (err) {}
  },
)
export const skillTrainerSlice = createSlice({
  name: 'skillTrainer',
  initialState: {
    showVennDiagramSidebar: false,
    showAbstractReasoningSidebar: false,
    showSpeedReadingSidebar: false,
    showKeywordScanningSidebar: false,
    showGraphTableSidebar: false,
    showArithmeticSidebar: false,
    isStartedSpeedReadingTraining: false,
    isStartedGraphTableTraining: false,
    isStartedSyllogismsTraining: false,
    isFinishedSpeedReadingQuestion: false,
    isFinishedGraphTableQuestion: false,
    isStartedVennDiagramTraining: false,
    isFinishedVennDiagramQuestion: false,
    isStartedAbstractReasoningTraining: false,
    isFinishedAbstractReasoningQuestion: false,
    isFinishedSyllogismsQuestion: false,
    isOpenLeaderBoard: false,
    isOpenPerformanceGraph: false,
    disabled: false,
    selectedDifficulty: 'Easy',
    fontSizeSlider: 21,
    isUcatView: false,
    isOpenReviewPassage: false,
    isOpenTipsAndTricks: false,
    isOpenTrainerConfigurator: false,
    selectedPeriod: { id: 1, value: 10, name: 'Last 10 attempts' },
    configurations: { fontSize: 21, isUcatView: false },
    skillTrainerScores: { isLoading: false, data: [] },
  },
  extraReducers: {
    [getSkilltrainerScores.pending]: (state, { payload }) => {
      state.skillTrainerScores.isLoading = true
    },
    [getSkilltrainerScores.fulfilled]: (state, { payload }) => {
      state.skillTrainerScores.isLoading = false
      state.skillTrainerScores.data = payload?.data?.scores
    },
    [getSkilltrainerScores.rejected]: (state, { payload }) => {
      state.skillTrainerScores.isLoading = false
    },
  },
  reducers: {
    setConfigurations: (state, { payload }) => {
      state.configurations = payload
    },
    toggleVennDiagramSidebar: (state) => {
      state.showVennDiagramSidebar = !state.showVennDiagramSidebar
    },
    toggleGraphTableSidebar: (state) => {
      state.showGraphTableSidebar = !state.showGraphTableSidebar
    },
    toggleAbstractReasoningSidebar: (state) => {
      state.showAbstractReasoningSidebar = !state.showAbstractReasoningSidebar
    },
    toggleSpeedReadingSidebar: (state) => {
      state.showSpeedReadingSidebar = !state.showSpeedReadingSidebar
    },
    openSpeedReadingSidebar: (state, { payload }) => {
      state.showSpeedReadingSidebar = payload
    },
    toggleKeywordScanningSidebar: (state) => {
      state.showKeywordScanningSidebar = !state.showKeywordScanningSidebar
    },
    toggleArithmeticSidebar: (state) => {
      state.showArithmeticSidebar = !state.showArithmeticSidebar
    },
    toggleStartedSpeedReading: (state, { payload }) => {
      state.isStartedSpeedReadingTraining = payload
    },
    toggleStartedVennDiagram: (state, { payload }) => {
      state.isStartedVennDiagramTraining = payload
    },
    toggleStartedAbstractReasoning: (state, { payload }) => {
      state.isStartedAbstractReasoningTraining = payload
    },
    toggleStartedSyllogisms: (state, { payload }) => {
      state.isStartedSyllogismsTraining = payload
    },
    toggleStartedGraphTable: (state, { payload }) => {
      state.isStartedGraphTableTraining = payload
    },
    toggleLeaderBoard: (state) => {
      state.isOpenLeaderBoard = !state.isOpenLeaderBoard
    },
    togglePerformanceGraph: (state) => {
      state.isOpenPerformanceGraph = !state.isOpenPerformanceGraph
    },
    toggleFinishedSpeedReading: (state) => {
      state.isFinishedSpeedReadingQuestion =
        !state.isFinishedSpeedReadingQuestion
    },
    toggleFinishedVennDiagram: (state) => {
      state.isFinishedVennDiagramQuestion = !state.isFinishedVennDiagramQuestion
    },
    toggleFinishedAbstractReasoning: (state) => {
      state.isFinishedAbstractReasoningQuestion =
        !state.isFinishedAbstractReasoningQuestion
    },
    toggleFinishedSyllogisms: (state) => {
      state.isFinishedSyllogismsQuestion = !state.isFinishedSyllogismsQuestion
    },
    toggleFinishedGraphTable: (state) => {
      state.isFinishedGraphTableQuestion = !state.isFinishedGraphTableQuestion
    },
    toggleReviewPassage: (state, { payload }) => {
      state.isOpenReviewPassage = payload
    },
    toggleTipsAndTricks: (state, { payload }) => {
      state.isOpenTipsAndTricks = payload
    },

    toggleTrainerConfigurator: (state, { payload }) => {
      state.isOpenTrainerConfigurator = payload
        ? payload
        : !state.isOpenTrainerConfigurator
    },
    setDisable: (state, { payload }) => {
      state.disabled = payload
    },

    setSelectedDifficulty: (state, { payload }) => {
      state.selectedDifficulty = payload
    },
    setFontSizeSlider: (state, { payload }) => {
      state.fontSizeSlider = parseInt(payload)
    },
    setSelectedPeriod: (state, { payload }) => {
      state.selectedPeriod = payload
    },
    setUcatView: (state, { payload }) => {
      state.isUcatView = payload
    },
    updateCompletedAttemptCount: (state, { payload }) => {
      const updateIndex = state.skillTrainerScores?.data?.findIndex(
        (data) => data.trainer === payload,
      )
      if (updateIndex >= 0) {
        state.skillTrainerScores.data[updateIndex].attempts_completed += 1
      }
    },
    resetSkillTrainerSideBar: (state) => {
      state.isUcatView = false
      state.fontSizeSlider = 21
      state.selectedDifficulty = 'easy'
      state.isStartedSpeedReadingTraining = false
      state.isFinishedSpeedReadingQuestion = false
      state.isStartedSyllogismsTraining = false
      state.isFinishedSyllogismsQuestion = false
      state.isStartedVennDiagramTraining = false
      state.isFinishedVennDiagramQuestion = false
      state.isStartedAbstractReasoningTraining = false
      state.isFinishedAbstractReasoningQuestion = false
      state.isFinishedAbstractReasoningTraining = false
      state.isStartedGraphTableTraining = false
      state.isFinishedGraphTableQuestion = false
      state.configurations = { fontSize: 21, isUcatView: false }
    },
  },
})

export const {
  openSpeedReadingSidebar,
  setConfigurations,
  toggleVennDiagramSidebar,
  toggleAbstractReasoningSidebar,
  toggleSpeedReadingSidebar,
  toggleKeywordScanningSidebar,
  toggleArithmeticSidebar,
  toggleStartedVennDiagram,
  toggleStartedAbstractReasoning,
  toggleStartedSpeedReading,
  toggleStartedSyllogisms,
  toggleStartedGraphTable,
  toggleFinishedGraphTable,
  toggleFinishedSpeedReading,
  toggleFinishedSyllogisms,
  toggleLeaderBoard,
  togglePerformanceGraph,
  toggleReviewPassage,
  toggleTipsAndTricks,
  toggleFinishedVennDiagram,
  toggleFinishedAbstractReasoning,
  toggleTrainerConfigurator,
  setDisable,
  setSelectedDifficulty,
  setFontSizeSlider,
  setSelectedPeriod,
  setUcatView,
  resetSkillTrainerSideBar,
  updateCompletedAttemptCount,
  toggleGraphTableSidebar,
} = skillTrainerSlice.actions

export default skillTrainerSlice.reducer
