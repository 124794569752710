const Pause = ({ className }) => (
  <svg
    width="16"
    height="24"
    viewBox="0 0 16 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={`h-4 w-4 ${className}`}
  >
    {/* <circle cx="24" cy="24" r="24" fill="#F2F2F2" /> */}
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="23"
      rx="3.5"
      fill="white"
      stroke="currentColor"
    />
    <rect x="6" y="9" width="1" height="6" rx="0.5" fill="currentColor" />
    <rect x="9" y="9" width="1" height="6" rx="0.5" fill="currentColor" />
  </svg>
)

export default Pause
