import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'
// import toast from 'react-hot-toast'
export const startVennDiagram = createAsyncThunk(
  'vennDiagram/startVennDiagram',
  async ({ onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `trainer/venn-diagram/start-attempt`,
      )
      onSuccess && onSuccess()
      return res
    } catch (error) {
      onError && onError()
      throw rejectWithValue(error.response.data)
    }
  },
)

export const submitVennDiagramAns = createAsyncThunk(
  'vennDiagram/submitVennDiagramAns',
  async (
    {
      attemptId,
      timeTaken,
      questionAttempts,
      questionId,
      response,
      onSuccess,
      onError,
    },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.post(
        `trainer/venn-diagram/${attemptId}/submit-attempt`,
        {
          time_taken: timeTaken,
          question_attempts: questionAttempts.map((data) => ({
            question_id: data.questionId,
            response: data.response,
            time_taken: data.time,
          })),
        },
        { headers: { notToRedirect404: true } },
      )
      onSuccess && onSuccess()
      return res
    } catch (error) {
      onError && onError()
      throw rejectWithValue(error.response.data)
    }
  },
)

const vennDiagramSlice = createSlice({
  name: 'vennDiagram',
  initialState: {
    vennDiagramData: { isRejected: false },
    selectedQue: {},
    ansOfVennDiagram: [],
    curQuestion: 0,
    vennDiagramResult: [],
    scoreEvolutionGraphData: [],
    userLevelInformation: [],
    displaySideQuestion: false,
    disableVennDiagramSideBar: false,
    vennDiagramLoading: false,
    timeTaken: 0,
    countDownTime: 3,
    correctAnsCount: 0,
    vennDiagramSubmitLoading: false,
    vennDiagramExamTime: null,
    vennDiagramExtraTime: 0,
    vennDiagramDefaultTime: 30,
  },
  extraReducers: {
    [startVennDiagram.pending]: (state, action) => {
      state.vennDiagramLoading = true
      state.vennDiagramData.isRejected = false
      state.vennDiagramData.error = null
    },
    [startVennDiagram.fulfilled]: (state, { payload }) => {
      state.vennDiagramData = payload.data
      state.selectedQue = payload.data.attempt.venn_diagram.questions[0]
      state.vennDiagramExamTime =
        payload.data.attempt.recommended_seconds || state.vennDiagramDefaultTime
      state.ansOfVennDiagram = payload.data.attempt.venn_diagram.questions.map(
        (question) => ({
          questionId: question.id,
          response: '',
          time: 0,
        }),
      )
    },
    [startVennDiagram.rejected]: (state, { payload }) => {
      state.vennDiagramData.error = payload
      state.vennDiagramData.isRejected = true
      state.isStartedVennDiagramTraining = false
      state.vennDiagramLoading = false
    },
    [submitVennDiagramAns.pending]: (state) => {
      state.vennDiagramSubmitLoading = true
    },
    [submitVennDiagramAns.fulfilled]: (state, { payload }) => {
      state.vennDiagramResult = payload.data
      // toast(
      //   <>
      //     <div className="flex flex-col items-center justify-center">
      //       <img src={badgeResponse.default_image_url} alt="badge" />
      //       <p className="text-base text-center text-gray-900 dark:text-white w-max">
      //         Congratulations!, You have earned {badgeResponse.name} Badge...
      //       </p>
      //     </div>
      //   </>,
      //   {
      //     duration: 4000,
      //     position: 'top-center',
      //     style: {
      //       backdropFilter: 'blur(4px)',
      //       marginTop: '20px',
      //       width: '350px',
      //     },
      //   },
      // )
      state.vennDiagramScore = payload.data.score
      state.vennDiagramExamTime = null
      state.scoreEvolutionGraphData = payload.data.score_evolution_graph
      state.userLevelInformation = payload.data.user_level_information
      state.correctAnsCount = payload.data.questions.filter((question) =>
        state.ansOfVennDiagram.some(
          (ans) => question.correct_answer === ans.response,
        ),
      ).length
      state.disableVennDiagramSideBar = false
      state.vennDiagramSubmitLoading = false
    },
    [submitVennDiagramAns.rejected]: (state) => {
      state.vennDiagramData.isRejected = true
      state.vennDiagramSubmitLoading = false
      state.vennDiagramLoading = false
    },
  },
  reducers: {
    setSelectedQue: (state, { payload }) => {
      state.selectedQue = payload
    },
    setIsRejected: (state, { payload }) => {
      state.vennDiagramData.isRejected = payload
    },
    updateCountDownTime: (state) => {
      if (state.countDownTime === 0) {
        state.vennDiagramLoading = false
      } else {
        state.countDownTime = state.countDownTime - 1
      }
    },
    setCurQuestion: (state, { payload }) => {
      state.curQuestion += 1
    },

    setDisplaySideQuestion: (state, { payload }) => {
      state.displaySideQuestion = payload
    },
    setVennDiagramExamTime: (state, { payload }) => {
      state.vennDiagramExamTime -= 1
    },
    setVennDiagramExtraTime: (state, { payload }) => {
      state.vennDiagramExtraTime += 1
    },
    setTryAgainVennDiagram: (state, { payload }) => {
      state.ansOfVennDiagram = []
      state.curQuestion = 0
      state.vennDiagramResult = []
      state.displaySideQuestion = false
      state.vennDiagramLoading = false
      state.countDownTime = 3
      state.vennDiagramExtraTime = 0
      state.vennDiagramData = { isRejected: false, error: null }
    },
    // setDisableSpeedReadingSideBar: (state, { payload }) => {
    //   state.disableSpeedReadingSideBar = payload
    // },
    updateTimeTaken: (state, { payload }) => {
      state.timeTaken += 1
    },
    resetTimeTaken: (state, { payload }) => {
      state.timeTaken = 0
    },
    setAnswerOfVennDiagram: (state, { payload }) => {
      const postIndex = findIndex(state.ansOfVennDiagram, {
        questionId: payload.questionId,
      })
      if (state.ansOfVennDiagram.length <= 0) {
        state.ansOfVennDiagram = [
          {
            questionId: payload.questionId,
            response: payload.response,
          },
          ...state.ansOfVennDiagram,
        ]
      } else {
        if (
          state.ansOfVennDiagram[postIndex] &&
          state.ansOfVennDiagram[postIndex].questionId === payload.questionId
        ) {
          state.ansOfVennDiagram[postIndex].response = payload.response
        } else {
          state.ansOfVennDiagram = [
            {
              questionId: payload.questionId,
              response: payload.response,
            },
            ...state.ansOfVennDiagram,
          ]
        }
      }
    },
    skipCurQuestion: (state, { payload }) => {
      const postIndex = findIndex(state.ansOfVennDiagram, {
        questionId: payload.questionId,
      })
      state.ansOfVennDiagram[postIndex].response = ''
      state.ansOfVennDiagram[postIndex].time = payload.time
      state.curQuestion += 1
    },
    nextCurQuestion: (state, { payload }) => {
      const postIndex = findIndex(state.ansOfVennDiagram, {
        questionId: payload.questionId,
      })
      state.ansOfVennDiagram[postIndex].time = payload.time
    },
    setDisableVennDiagramSideBar: (state, { payload }) => {
      state.disableVennDiagramSideBar = payload
    },
  },
})

export const {
  nextCurQuestion,
  setVennDiagramExtraTime,
  setSelectedQue,
  setCurQuestion,
  setAnswerOfVennDiagram,
  setDisplaySideQuestion,
  setTryAgainVennDiagram,
  // setDisableSpeedReadingSideBar,
  setVennDiagramExamTime,
  skipCurQuestion,
  setIsRejected,
  updateTimeTaken,
  resetTimeTaken,
  updateCountDownTime,
  setDisableVennDiagramSideBar,
} = vennDiagramSlice.actions

export default vennDiagramSlice.reducer
