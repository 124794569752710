const LogicalReasoning = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="currentColor"
    viewBox="0 0 20 20"
    className={`${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.75 2C1.75 1.86193 1.86193 1.75 2 1.75H6C6.13807 1.75 6.25 1.86193 6.25 2V6C6.25 6.13807 6.13807 6.25 6 6.25H2C1.86193 6.25 1.75 6.13807 1.75 6V2ZM2 0.25C1.0335 0.25 0.25 1.0335 0.25 2V6C0.25 6.9665 1.0335 7.75 2 7.75H6C6.9665 7.75 7.75 6.9665 7.75 6V2C7.75 1.0335 6.9665 0.25 6 0.25H2ZM13.75 14C13.75 13.8619 13.8619 13.75 14 13.75H18C18.1381 13.75 18.25 13.8619 18.25 14V18C18.25 18.1381 18.1381 18.25 18 18.25H14C13.8619 18.25 13.75 18.1381 13.75 18V14ZM14 12.25C13.0335 12.25 12.25 13.0335 12.25 14V18C12.25 18.9665 13.0335 19.75 14 19.75H18C18.9665 19.75 19.75 18.9665 19.75 18V14C19.75 13.0335 18.9665 12.25 18 12.25H14ZM14.5303 0.46967C14.8232 0.762563 14.8232 1.23744 14.5303 1.53033L12.8107 3.25H17C17.7293 3.25 18.4288 3.53973 18.9445 4.05546C19.4603 4.57118 19.75 5.27065 19.75 6V9C19.75 9.41421 19.4142 9.75 19 9.75C18.5858 9.75 18.25 9.41421 18.25 9V6C18.25 5.66848 18.1183 5.35054 17.8839 5.11612C17.6495 4.8817 17.3315 4.75 17 4.75H12.8107L14.5303 6.46967C14.8232 6.76256 14.8232 7.23744 14.5303 7.53033C14.2374 7.82322 13.7626 7.82322 13.4697 7.53033L10.4697 4.53033C10.1768 4.23744 10.1768 3.76256 10.4697 3.46967L13.4697 0.46967C13.7626 0.176777 14.2374 0.176777 14.5303 0.46967ZM1.75 11C1.75 10.5858 1.41421 10.25 1 10.25C0.585786 10.25 0.25 10.5858 0.25 11V14C0.25 14.7293 0.539731 15.4288 1.05546 15.9445C1.57118 16.4603 2.27065 16.75 3 16.75H7.18934L5.46967 18.4697C5.17678 18.7626 5.17678 19.2374 5.46967 19.5303C5.76256 19.8232 6.23744 19.8232 6.53033 19.5303L9.53033 16.5303C9.82322 16.2374 9.82322 15.7626 9.53033 15.4697L6.53033 12.4697C6.23744 12.1768 5.76256 12.1768 5.46967 12.4697C5.17678 12.7626 5.17678 13.2374 5.46967 13.5303L7.18934 15.25H3C2.66848 15.25 2.35054 15.1183 2.11612 14.8839C1.8817 14.6495 1.75 14.3315 1.75 14V11Z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default LogicalReasoning
