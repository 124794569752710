import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { find } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'

const questionType =
  process.env.REACT_APP_OLP_TYPE === 'hpat' ? 'All Sections' : 'All Subtests'

export const bookmarkType = createAsyncThunk(
  'bookmark/bookmarkType',
  async (data) => {
    const {
      filterType,
      filterTopicSubtest,
      filterModuleCategory,
      bookmarkPageNumber,
      onSuccess,
    } = data
    const params = {
      page: bookmarkPageNumber,
    }
    if (filterType && filterType.type !== 'All Types') {
      params.type = filterType.type
      if (
        filterTopicSubtest &&
        filterTopicSubtest.filterValue !== 'All Topics' &&
        filterTopicSubtest.filterValue !== questionType
      ) {
        if (filterType.type === 'question') {
          params.question_subtest = filterTopicSubtest.filterValue
        }
        if (filterType.type === 'guide') {
          params.guide_topic = filterTopicSubtest.filterValue
        }
        if (filterModuleCategory) {
          if (filterType.type === 'question') {
            params.question_top_level_category = filterModuleCategory.id
          }
          if (filterType.type === 'guide') {
            params.guide_module = filterModuleCategory.id
          }
        }
      }
    }
    const res = await axiosInterceptors.get('/bookmark', {
      params,
    })
    onSuccess && onSuccess()
    return {
      data: res,
      isNew: bookmarkPageNumber === 1,
    }
  },
)
export const notesType = createAsyncThunk(
  'bookmark/notesType',
  async (data) => {
    const {
      search,
      filterType,
      filterTopicSubtest,
      filterModuleCategory,
      notesPageNumber,
    } = data
    const params = {
      search: search,
      page: notesPageNumber,
    }
    if (filterType && filterType.type !== 'All Types') {
      params.type = filterType.type
      if (
        filterTopicSubtest &&
        filterTopicSubtest.filterValue !== 'All Topics' &&
        filterTopicSubtest.filterValue !== questionType
      ) {
        if (filterType.type === 'question') {
          params.question_subtest = filterTopicSubtest.filterValue
        }
        if (filterType.type === 'guide') {
          params.guide_topic = filterTopicSubtest.filterValue
        }
        if (filterModuleCategory) {
          if (filterType.type === 'question') {
            params.question_top_level_category = filterModuleCategory.id
          }
          if (filterType.type === 'guide') {
            params.guide_module = filterModuleCategory.id
          }
        }
      }
    }
    const res = await axiosInterceptors.get('/bookmark/notes', {
      params,
    })
    data?.onSuccess && data?.onSuccess()
    return {
      data: res,
      isNew: notesPageNumber === 1,
    }
  },
)

export const bookmarkFilter = createAsyncThunk(
  'bookmark/getCommunityData',
  async () => {
    return await axiosInterceptors.get('/bookmark/filters')
  },
)
export const updateNote = createAsyncThunk(
  `bookmark/updateNote`,
  async ({ data, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.patch(
        `bookmark/note/${data.noteId}`,
        {
          content: data.content,
          type: data.type,
        },
      )

      onSuccess && onSuccess()
      return res
    } catch (err) {
      onError && onError(err.response)
      throw rejectWithValue(err.response.data)
    }
  },
)
export const deleteNote = createAsyncThunk(
  'bookmark/deleteNote',
  async ({ noteId }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.delete(`bookmark/note/${noteId}`)
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const deleteBookmark = createAsyncThunk(
  'bookmark/deleteBookmark',
  async ({ bookmarkId, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.delete(`bookmark/${bookmarkId}`)
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const getMyDiscussion = createAsyncThunk(
  'bookmark/getMyDiscussion',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`bookmark/discussions`)
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

const BookmarkSlice = createSlice({
  name: 'bookmark',
  initialState: {
    isLoading: false,
    filtersType: [],
    filtersTopicSubtest: [],
    filtersModuleCategory: [],
    bookmarkFilterData: [],
    filterType: { type: 'All Types' },
    filterTopicSubtest: null,
    searchNotes: null,
    notesDebounce: null,
    filterModuleCategory: null,
    isOpen: false,
    notesData: { isLoading: false, loadingSeeMore: false, data: [] },
    bookmarkData: { isLoading: false, loadingSeeMore: false, data: [] },
    notesPageNumber: 1,
    bookmarkPageNumber: 1,
    displayFilter: false,
    updateNoteLoading: false,
    deleteModal: false,
    deleteNoteLoading: false,
    deleteBookmarkLoading: null,
    myDiscussion: { data: null, isLoading: false },
  },
  extraReducers: {
    [getMyDiscussion.pending]: (state, { meta }) => {
      state.myDiscussion.isLoading = true
    },
    [getMyDiscussion.fulfilled]: (state, { payload }) => {
      state.myDiscussion.isLoading = false
      state.myDiscussion.data = payload?.data
    },
    [getMyDiscussion.rejected]: (state) => {
      state.myDiscussion.isLoading = true
    },
    //
    [deleteBookmark.pending]: (state, { meta }) => {
      state.deleteBookmarkLoader = meta?.arg?.bookmarkId
    },
    [deleteBookmark.fulfilled]: (state, action) => {
      state.deleteBookmarkLoader = null
      state.bookmarkData.data.data.bookmarks =
        state?.bookmarkData.data?.data?.bookmarks?.filter(
          (bookmark) => bookmark?.id !== action?.meta?.arg?.bookmarkId,
        )
      state.deleteModal = false
    },
    [deleteBookmark.rejected]: (state) => {
      state.deleteBookmarkLoader = null
    },
    [deleteNote.pending]: (state) => {
      state.deleteNoteLoading = true
    },
    [deleteNote.fulfilled]: (state, action) => {
      state.deleteNoteLoading = false
      state.notesData.data.notes = state?.notesData?.data?.notes?.filter(
        (note) => note?.id !== action?.meta?.arg?.noteId,
      )
      state.deleteModal = false
    },
    [deleteNote.rejected]: (state) => {
      state.deleteNoteLoading = false
    },
    [bookmarkFilter.pending]: (state, action) => {
      state.isLoading = true
    },
    [bookmarkFilter.fulfilled]: (state, action) => {
      state.bookmarkFilterData = action.payload.data
      state.filtersType = [
        {
          type: 'All Types',
        },
        ...state.bookmarkFilterData.filters,
      ]
      state.isLoading = false
    },
    [bookmarkFilter.rejected]: (state, action) => {
      state.isLoading = true
    },
    [bookmarkType.pending]: (state, action) => {
      if (action.meta.arg.bookmarkPageNumber === 1) {
        state.bookmarkData.isLoading = true
      } else {
        state.bookmarkData.loadingSeeMore = true
      }
    },
    [bookmarkType.fulfilled]: (state, action) => {
      const { data, isNew } = action.payload
      if (isNew) {
        state.bookmarkData.data = data
      } else {
        state.bookmarkData.data.data.bookmarks = [
          ...state.bookmarkData.data.data.bookmarks,
          ...data.data.bookmarks,
        ]
        state.bookmarkData.data.pagination = data.pagination
      }
      state.bookmarkPageNumber = data?.pagination?.page_number
      state.bookmarkData.loadingSeeMore = false
      state.bookmarkData.isLoading = false
    },
    [bookmarkType.rejected]: (state, action) => {
      state.bookmarkData.loadingSeeMore = false
      state.bookmarkData.isLoading = false
    },
    [updateNote.pending]: (state) => {
      state.updateNoteLoading = true
    },
    [updateNote.fulfilled]: (state, { payload }) => {
      state.updateNoteLoading = false
      const updateIndex = state?.notesData?.data?.notes?.findIndex(
        (data) => data?.id === payload?.data?.note?.id,
      )
      if (updateIndex >= 0) {
        state.notesData.data.notes[updateIndex] = payload?.data?.note
      }
    },
    [updateNote.rejected]: (state) => {
      state.updateNoteLoading = false
    },
    [notesType.pending]: (state, action) => {
      if (action.meta.arg.notesPageNumber === 1) {
        state.notesData.isLoading = true
      } else {
        state.notesData.loadingSeeMore = true
      }
    },
    [notesType.fulfilled]: (state, action) => {
      const { data, isNew } = action.payload
      if (isNew) {
        state.notesData.data = data?.data
      } else {
        state.notesData.data.notes = [
          ...state.notesData?.data?.notes,
          ...data.data.notes,
        ]
      }
      state.notesData.pagination = data?.pagination
      state.notesPageNumber = data?.pagination?.page_number
      state.notesData.isLoading = false
      state.notesData.loadingSeeMore = false
    },
    [notesType.rejected]: (state, action) => {
      state.notesData.isLoading = false
      state.notesData.loadingSeeMore = false
    },
  },
  reducers: {
    updateNotesDebounce: (state, { payload }) => {
      state.notesDebounce = payload
    },
    setSearchNotes: (state, { payload }) => {
      state.searchNotes = payload
    },
    setFilterType: (state, action) => {
      state.filterType = action.payload
      state.filtersModuleCategory = []
      state.notesPageNumber = 1
      switch (action.payload.type) {
        case 'question':
          state.filtersTopicSubtest =
            [
              {
                filterName: questionType,
                filterValue: questionType,
              },
              ...find(state.filtersType, {
                type: action.payload.type,
              }).subtests.map((item) => ({
                filterName: item.name,
                filterValue: item.id,
                subFilters: item.top_level_categories,
              })),
            ] || []
          state.filterTopicSubtest = {
            filterName: questionType,
            filterValue: questionType,
          }
          break
        case 'guide':
          state.filtersTopicSubtest =
            [
              {
                filterName: 'All Topics',
                filterValue: 'All Topics',
              },
              ...find(state.filtersType, {
                type: action.payload.type,
              }).topics.map((item) => ({
                filterName: item.topic,
                filterValue: item.topic,
                subFilters: item.modules,
              })),
            ] || []
          state.filterTopicSubtest = {
            filterName: 'All Topics',
            filterValue: 'All Topics',
          }
          break
        default:
          state.filtersTopicSubtest = []
          state.filterTopicSubtest = null
          state.filterModuleCategory = null
          break
      }
    },
    setfilterTopicSubtest: (state, action) => {
      state.filterTopicSubtest = action.payload
      state.filtersModuleCategory =
        find(state.filtersTopicSubtest, {
          filterValue: action.payload.filterValue,
        }).subFilters || []
      state.notesPageNumber = 1

      switch (state.filterType.type) {
        case 'question':
          state.filtersModuleCategory = [
            {
              id: 0,
              name: 'All Category',
            },
            ...(find(state.filtersTopicSubtest, {
              filterValue: action.payload.filterValue,
            }).subFilters || []),
          ]
          state.filterModuleCategory = 0
          if (action.payload.filterName === questionType) {
            state.filtersModuleCategory = []
          }
          break
        case 'guide':
          state.filtersModuleCategory = [
            {
              id: 0,
              name: 'All Modules',
            },
            ...(find(state.filtersTopicSubtest, {
              filterValue: action.payload.filterValue,
            }).subFilters || []),
          ]
          if (action.payload.filterName === 'All Topics') {
            state.filtersModuleCategory = []
          }
          state.filterModuleCategory = 0
          break
        default:
          state.filtersModuleCategory = []
          state.filterModuleCategory = 0
          return
      }
    },
    setfilterModuleCategory: (state, action) => {
      state.filterModuleCategory = action.payload
      state.notesPageNumber = 1
    },
    clearAllFilter: (state, action) => {
      state.filtersType = [
        {
          type: 'All Types',
        },
        ...state.bookmarkFilterData.filters,
      ]
      state.filterType.type = 'All Types'
      state.filtersTopicSubtest = []
      state.filterTopicSubtest = null
      state.filterModuleCategory = null
      state.filtersModuleCategory = []
      state.notesPageNumber = 1
      state.bookmarkPageNumber = 1
      state.bookmarkData.data = []
    },
    updatePage: (state, { payload }) => {
      state.notesPageNumber = payload + 1
    },
    updateBookmarkPage: (state, { payload }) => {
      state.bookmarkPageNumber = payload + 1
    },
    setDisplayFilter: (state, { payload }) => {
      state.displayFilter = payload
    },
    setIsOpenBookmarkModel: (state, { payload }) => {
      state.isOpen = payload
    },
    clearBookmarkData: (state) => {
      state.bookmarkData = { isLoading: false, data: [] }
      state.bookmarkPageNumber = 1
    },
    toggleDeleteModal: (state, { payload }) => {
      state.deleteModal = payload
    },
  },
})

export const {
  updateNotesDebounce,
  setSearchNotes,
  setFilterType,
  setfilterTopicSubtest,
  setfilterModuleCategory,
  clearAllFilter,
  updatePage,
  setDisplayFilter,
  setIsOpenBookmarkModel,
  updateBookmarkPage,
  clearBookmarkData,
  toggleDeleteModal,
} = BookmarkSlice.actions

export default BookmarkSlice.reducer
