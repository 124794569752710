const Arrow = ({ className }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5ZM9.35355 6.02022C9.54882 5.82496 9.54882 5.50838 9.35355 5.31311C9.15829 5.11785 8.84171 5.11785 8.64645 5.31311L6.33333 7.62623L5.35355 6.64645C5.15829 6.45118 4.84171 6.45118 4.64645 6.64645C4.45118 6.84171 4.45118 7.15829 4.64645 7.35355L5.97978 8.68689C6.17504 8.88215 6.49162 8.88215 6.68689 8.68689L9.35355 6.02022Z"
      fill="#F2473A"
    />
  </svg>
)
export default Arrow
