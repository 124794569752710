import { useField } from 'formik'
import PropTypes from 'prop-types'

const MTextarea = ({
  hideLabel,
  name,
  label,
  helpText,
  className,
  ...props
}) => {
  const [inputProps, { error, touched }, { setValue }] = useField(name)
  return (
    <>
      <div className="">
        {!hideLabel && (
          <div className="text-sm text-gray-700 dark:text-dark-100">
            {label}
          </div>
        )}
        <textarea
          className={`px-4 py-3 border rounded-md border-gray-500 hover:border-gray-700 outline-none text-gray-800 dark:border-dark-400 dark:bg-dark-600 dark:text-white w-full ${
            error && touched && 'border-red-600'
          } ${className}`}
          {...inputProps}
          {...props}
          onChange={(event) => setValue(event.target.value)}
        ></textarea>
        {helpText && (
          <div className="mt-1 text-sm text-gray-700 dark:text-dark-100">
            {helpText}
          </div>
        )}
        {error && touched && (
          <div>
            <span className="text-xs font-semibold text-red-500">{error}</span>
          </div>
        )}
      </div>
    </>
  )
}

MTextarea.propTypes = {
  hideLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helpText: PropTypes.string,
  error: PropTypes.string,
}

export default MTextarea
