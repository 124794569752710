const File = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="current"
    viewBox="0 0 12 14"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="current"
      fillRule="evenodd"
      d="M2.66659 1.5C2.44557 1.5 2.23361 1.5878 2.07733 1.74408C1.92105 1.90036 1.83325 2.11232 1.83325 2.33333V11.6667C1.83325 11.8877 1.92105 12.0996 2.07733 12.2559C2.23361 12.4122 2.44557 12.5 2.66659 12.5H9.33325C9.55427 12.5 9.76623 12.4122 9.92251 12.2559C10.0788 12.0996 10.1666 11.8877 10.1666 11.6667V4.83333H7.99992C7.6905 4.83333 7.39375 4.71042 7.17496 4.49163C6.95617 4.27283 6.83325 3.97609 6.83325 3.66667V1.5H2.66659ZM7.83325 2.20711L9.45948 3.83333H7.99992C7.95572 3.83333 7.91332 3.81577 7.88207 3.78452C7.85081 3.75326 7.83325 3.71087 7.83325 3.66667V2.20711ZM1.37022 1.03697C1.71404 0.693154 2.18036 0.5 2.66659 0.5H7.33325C7.46586 0.5 7.59304 0.552678 7.68681 0.646447L11.0201 3.97978C11.1139 4.07355 11.1666 4.20073 11.1666 4.33333V11.6667C11.1666 12.1529 10.9734 12.6192 10.6296 12.963C10.2858 13.3068 9.81948 13.5 9.33325 13.5H2.66659C2.18035 13.5 1.71404 13.3068 1.37022 12.963C1.02641 12.6192 0.833252 12.1529 0.833252 11.6667V2.33333C0.833252 1.8471 1.02641 1.38079 1.37022 1.03697ZM3.99992 4.5C3.72378 4.5 3.49992 4.72386 3.49992 5C3.49992 5.27614 3.72378 5.5 3.99992 5.5H4.66659C4.94273 5.5 5.16659 5.27614 5.16659 5C5.16659 4.72386 4.94273 4.5 4.66659 4.5H3.99992ZM3.49992 7.66667C3.49992 7.39052 3.72378 7.16667 3.99992 7.16667H7.99992C8.27606 7.16667 8.49992 7.39052 8.49992 7.66667C8.49992 7.94281 8.27606 8.16667 7.99992 8.16667H3.99992C3.72378 8.16667 3.49992 7.94281 3.49992 7.66667ZM3.99992 9.83333C3.72378 9.83333 3.49992 10.0572 3.49992 10.3333C3.49992 10.6095 3.72378 10.8333 3.99992 10.8333H7.99992C8.27606 10.8333 8.49992 10.6095 8.49992 10.3333C8.49992 10.0572 8.27606 9.83333 7.99992 9.83333H3.99992Z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default File
