const Keypad = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="current"
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      fill="current"
      fillRule="evenodd"
      d="M4.75 5c0-.69.56-1.25 1.25-1.25h12c.69 0 1.25.56 1.25 1.25v14c0 .69-.56 1.25-1.25 1.25H6c-.69 0-1.25-.56-1.25-1.25V5zM6 2.25A2.75 2.75 0 003.25 5v14A2.75 2.75 0 006 21.75h12A2.75 2.75 0 0020.75 19V5A2.75 2.75 0 0018 2.25H6zM8.75 8A.25.25 0 019 7.75h6a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H9A.25.25 0 018.75 9V8zM9 6.25A1.75 1.75 0 007.25 8v1c0 .966.784 1.75 1.75 1.75h6A1.75 1.75 0 0016.75 9V8A1.75 1.75 0 0015 6.25H9zm-1 7a.75.75 0 01.75.75v.01a.75.75 0 01-1.5 0V14a.75.75 0 01.75-.75zm4.75.75a.75.75 0 00-1.5 0v.01a.75.75 0 001.5 0V14zm3.25-.75a.75.75 0 01.75.75v.01a.75.75 0 01-1.5 0V14a.75.75 0 01.75-.75zM8.75 17a.75.75 0 00-1.5 0v.01a.75.75 0 001.5 0V17zm3.25-.75a.75.75 0 01.75.75v.01a.75.75 0 01-1.5 0V17a.75.75 0 01.75-.75zm4.75.75a.75.75 0 00-1.5 0v.01a.75.75 0 001.5 0V17z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default Keypad
