const Loading = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    className={`h-4 w-4 ${className}`}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.75 1C10.75 0.585786 10.4142 0.25 10 0.25C9.58579 0.25 9.25 0.585786 9.25 1V4C9.25 4.41421 9.58579 4.75 10 4.75C10.4142 4.75 10.75 4.41421 10.75 4V1ZM16.9303 4.13034C17.2232 3.83744 17.2232 3.36257 16.9303 3.06968C16.6374 2.77678 16.1626 2.77678 15.8697 3.06968L13.7197 5.21968C13.4268 5.51257 13.4268 5.98744 13.7197 6.28034C14.0126 6.57323 14.4874 6.57323 14.7803 6.28034L16.9303 4.13034ZM15.25 10C15.25 9.58579 15.5858 9.25 16 9.25H19C19.4142 9.25 19.75 9.58579 19.75 10C19.75 10.4142 19.4142 10.75 19 10.75H16C15.5858 10.75 15.25 10.4142 15.25 10ZM14.7803 13.7197C14.4874 13.4268 14.0126 13.4268 13.7197 13.7197C13.4268 14.0126 13.4268 14.4874 13.7197 14.7803L15.8697 16.9303C16.1626 17.2232 16.6374 17.2232 16.9303 16.9303C17.2232 16.6374 17.2232 16.1626 16.9303 15.8697L14.7803 13.7197ZM10 15.25C10.4142 15.25 10.75 15.5858 10.75 16V19C10.75 19.4142 10.4142 19.75 10 19.75C9.58579 19.75 9.25 19.4142 9.25 19V16C9.25 15.5858 9.58579 15.25 10 15.25ZM6.28031 14.7803C6.5732 14.4874 6.5732 14.0126 6.28031 13.7197C5.98741 13.4268 5.51254 13.4268 5.21965 13.7197L3.06965 15.8697C2.77675 16.1626 2.77675 16.6374 3.06965 16.9303C3.36254 17.2232 3.83741 17.2232 4.13031 16.9303L6.28031 14.7803ZM0.25 10C0.25 9.58579 0.585786 9.25 1 9.25H4C4.41421 9.25 4.75 9.58579 4.75 10C4.75 10.4142 4.41421 10.75 4 10.75H1C0.585786 10.75 0.25 10.4142 0.25 10ZM4.13031 3.06968C3.83741 2.77678 3.36254 2.77678 3.06965 3.06968C2.77675 3.36257 2.77675 3.83744 3.06965 4.13034L5.21965 6.28034C5.51254 6.57323 5.98741 6.57323 6.28031 6.28034C6.5732 5.98744 6.5732 5.51257 6.28031 5.21968L4.13031 3.06968Z"
      clipRule="evenodd"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 10 10"
        to="360 10 10"
        dur="1.5s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
)

export default Loading
