import PropTypes from 'prop-types'

const TextArea = ({ hideLabel, name, label, helpText, ...props }) => {
  return (
    <div className="mb-6">
      {!hideLabel && <div className="text-sm text-gray-700 mb-1">{label}</div>}
      <textarea
        className="px-4 py-3 border rounded-md border-gray-500 hover:border-gray-700 outline-none text-gray-800 w-full"
        {...props}
      ></textarea>
      {helpText && <div className="mt-1 text-sm text-gray-700">{helpText}</div>}
    </div>
  )
}

TextArea.propTypes = {
  hideLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
}

export default TextArea
