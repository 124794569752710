import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { useState } from 'react'
const Tooltip = ({ children, text, arrowClass, ...props }) => {
  const [open, setOpen] = useState(false)
  return (
    <TooltipPrimitive.Root delayDuration={0} open={open}>
      <TooltipPrimitive.Trigger
        onClick={() => setOpen(true)}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        asChild
      >
        {children}
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Content
        {...props}
        className={`bg-gray-900 max-w-xs w-auto sm:max-w-prose dark:bg-dark-400 text-white text-sm px-4 py-2.5 rounded-md ${
          props.className && props.className
        }`}
        sideOffset={5}
      >
        {text}
        <TooltipPrimitive.Arrow
          className={`${arrowClass} fill-current text-gray-900 dark:text-dark-400`}
        />
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Root>
  )
}

export default Tooltip
