const Moon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.12042 1.56565C5.43486 1.67691 4.77386 1.91747 4.1736 2.2773C3.31964 2.78922 2.62065 3.52335 2.1512 4.40139C1.68175 5.27942 1.45945 6.26842 1.50801 7.26289C1.55657 8.25736 1.87418 9.21999 2.42694 10.0481C2.97971 10.8762 3.7469 11.5388 4.64668 11.965C5.54646 12.3913 6.54509 12.5654 7.53603 12.4686C8.52697 12.3718 9.47305 12.0079 10.2734 11.4156C10.7711 11.0472 11.2008 10.5991 11.5465 10.0918C10.6967 10.2297 9.82032 10.167 8.98938 9.90116C7.76454 9.50924 6.71576 8.70051 6.02534 7.61555C5.33492 6.53058 5.04653 5.23799 5.21028 3.96244C5.32135 3.09726 5.63568 2.27704 6.12042 1.56565ZM7.07308 0.499962H7.26202C7.46759 0.499962 7.65222 0.625788 7.72739 0.817125C7.80256 1.00846 7.75296 1.22631 7.60237 1.36624C6.83157 2.08247 6.33612 3.04615 6.20214 4.08978C6.06816 5.1334 6.30412 6.19098 6.86901 7.07868C7.43389 7.96638 8.29199 8.62807 9.29413 8.94873C10.2963 9.26938 11.3791 9.22873 12.3544 8.83384C12.5412 8.75818 12.7553 8.80215 12.8972 8.94536C13.0391 9.08858 13.0811 9.30301 13.0037 9.48918C12.5521 10.5758 11.8141 11.5194 10.8683 12.2194C9.92243 12.9194 8.80433 13.3495 7.63322 13.4639C6.46211 13.5782 5.28192 13.3725 4.21854 12.8688C3.15516 12.365 2.24848 11.582 1.59521 10.6033C0.941943 9.6246 0.566594 8.48694 0.509202 7.31166C0.45181 6.13638 0.714528 4.96756 1.26933 3.92989C1.82413 2.89221 2.65021 2.0246 3.65945 1.4196C4.66868 0.814605 5.82322 0.494913 6.9999 0.494629C7.02447 0.494623 7.04907 0.496425 7.07308 0.499962Z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Moon
