const Bluebook = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 28 28"
    className={`h-6 w-6 ${className}`}
  >
    <g filter="url(#filter0_d)">
      <path
        fill="#2196F3"
        fillRule="evenodd"
        d="M4.254 5.925a.752.752 0 00-.004.09v12.972a.745.745 0 00.377.664.745.745 0 00.759-.008 8.25 8.25 0 018.228 0 .746.746 0 00.772 0 8.25 8.25 0 018.228 0 .747.747 0 00.996-.206.748.748 0 00.14-.45V6.014c0-.029 0-.058-.003-.087a.746.746 0 00-.385-.584A9.75 9.75 0 0014 5.145a9.75 9.75 0 00-9.362.198.751.751 0 00-.384.582zm17.996.52a8.25 8.25 0 00-7.5 0v11.35a9.75 9.75 0 017.5 0V6.444zm-9 0v11.35a9.75 9.75 0 00-7.5 0V6.444a8.25 8.25 0 017.5 0z"
        clipRule="evenodd"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="-2"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="4"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
    </defs>
  </svg>
)
export default Bluebook
