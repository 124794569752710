const User = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.833 4.667a2.167 2.167 0 114.334 0 2.167 2.167 0 01-4.334 0zM8 1.5a3.167 3.167 0 100 6.333A3.167 3.167 0 008 1.5zm-2.865 9.635a2.167 2.167 0 011.532-.635h2.666a2.167 2.167 0 012.167 2.167V14a.5.5 0 001 0v-1.333A3.167 3.167 0 009.333 9.5H6.667A3.167 3.167 0 003.5 12.667V14a.5.5 0 001 0v-1.333c0-.575.228-1.126.635-1.532z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default User
