const QuestionMark = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    width="9"
    height="14"
    viewBox="0 0 9 14"
    className={`h-4 w-4 ${className}`}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.31884 2.30221C2.75037 1.93233 3.35392 1.71149 3.99996 1.71149H4.84612C5.49215 1.71149 6.09571 1.93233 6.52724 2.30221C6.95574 2.6695 7.17304 3.14475 7.17304 3.61534C7.17304 3.62732 7.17338 3.63931 7.17406 3.65127C7.19742 4.0633 7.08628 4.47176 6.85736 4.81514C6.62844 5.15852 6.29415 5.41822 5.90483 5.55512C5.88481 5.56216 5.86516 5.5702 5.84594 5.5792C5.17414 5.89418 4.63415 6.47258 4.27768 7.1855C3.92076 7.89934 3.75361 8.73357 3.789 9.56539C3.80389 9.91556 4.09984 10.1874 4.45001 10.1725C4.80018 10.1576 5.07198 9.86162 5.05708 9.51145C5.03015 8.87828 5.15938 8.26019 5.41292 7.75312C5.66121 7.25654 6.00265 6.91573 6.36012 6.74021C6.99434 6.509 7.53862 6.08139 7.91342 5.51919C8.29165 4.95184 8.47698 4.27788 8.44221 3.59722C8.43664 2.72819 8.03082 1.91932 7.35324 1.33854C6.67397 0.75631 5.77097 0.442261 4.84612 0.442261H3.99996C3.0751 0.442261 2.1721 0.75631 1.49284 1.33854C0.810546 1.92336 0.403809 2.73944 0.403809 3.61534C0.403809 3.96583 0.687936 4.24995 1.03842 4.24995C1.38891 4.24995 1.67304 3.96583 1.67304 3.61534C1.67304 3.14475 1.89034 2.6695 2.31884 2.30221ZM5.05765 12.923C5.05765 12.5725 4.77353 12.2884 4.42304 12.2884C4.07255 12.2884 3.78842 12.5725 3.78842 12.923V12.9315C3.78842 13.282 4.07255 13.5661 4.42304 13.5661C4.77353 13.5661 5.05765 13.282 5.05765 12.9315V12.923Z"
      fill="currentColor"
    />
  </svg>
)

export default QuestionMark
