import { Fragment, useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import { Tab } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import { updateSelectedTab } from 'redux/dashboard/dashboardSlice'

const Graph = () => {
  const dispatch = useDispatch()
  const { progressData, selectedGraphTab, olpPath } = useSelector((state) => ({
    progressData: state.dashboard.progressData,
    selectedGraphTab: state.dashboard.selectedGraphTab,
    olpPath: state.personalInfo.olpPath,
  }))
  useEffect(() => {
    am4core.addLicense(process.env.REACT_APP_AM4CHART_KEY)
    let chart = am4core.create('chartdiv', am4charts.XYChart)
    chart.paddingRight = 30
    let performanceData
    if (selectedGraphTab === 'Overall') {
      performanceData = progressData?.data?.performance?.find(
        (data) => !data?.section,
      )
    } else {
      performanceData = progressData?.data?.performance?.find(
        (data) => data?.section?.short_name === selectedGraphTab,
      )
    }
    chart.data =
      performanceData &&
      performanceData.performances &&
      performanceData.performances.map((data) => {
        if (data.num_questions && data.num_questions > 0)
          return {
            start: new Date(data.start_date),
            end: new Date(data.end_date),
            visits: Math.round(
              (data.num_questions_correct * 100) / data.num_questions,
            ),
            lineColor: 'rgba(242, 71, 58, 0.12)',
          }
      })
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.baseInterval = {
      timeUnit:
        (performanceData &&
          performanceData.data &&
          performanceData.data.performances &&
          performanceData.data.performances[0] &&
          performanceData.data.performances[0].period) ||
        'week',
      count: 1,
    }
    dateAxis.title.text = 'Date'
    dateAxis.title.fontWeight = 'bold'
    dateAxis.tooltipDateFormat = 'HH:mm, DD MMMM'
    dateAxis.renderer.grid.template.disabled = true
    dateAxis.renderer.line.strokeOpacity = 1
    dateAxis.renderer.line.strokeWidth = 1
    dateAxis.dateFormatter.dateFormat = 'ww'
    dateAxis.renderer.line.stroke = am4core.color('#E0E0E0')
    if (
      (localStorage && localStorage.theme === 'Dark') ||
      (!('theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      dateAxis.title.fill = am4core.color('#FFFFFF')
      dateAxis.renderer.labels.template.fill = am4core.color('#FFFFFF')
    } else {
      dateAxis.title.fill = am4core.color('#000000')
      dateAxis.renderer.labels.template.fill = am4core.color('#000000')
    }
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.strictMinMax = true
    valueAxis.title.text = '% of Correct Answers'
    valueAxis.title.fontWeight = 'bold'
    valueAxis.renderer.grid.template.disabled = true
    valueAxis.renderer.line.strokeOpacity = 0.5
    valueAxis.renderer.line.strokeWidth = 1
    valueAxis.min = 0
    valueAxis.max = 105
    valueAxis.renderer.line.stroke = am4core.color('#E0E0E0')
    if (
      (localStorage && localStorage.theme === 'Dark') ||
      (!('theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      valueAxis.title.fill = am4core.color('#FFFFFF')
      valueAxis.renderer.labels.template.fill = am4core.color('#FFFFFF')
    } else {
      valueAxis.title.fill = am4core.color('#000000')
      valueAxis.renderer.labels.template.fill = am4core.color('#000000')
    }
    let series = chart.series.push(new am4charts.LineSeries())
    // series.dataFields.openDateX = 'start'
    series.dataFields.dateX = 'start'
    series.dataFields.valueY = 'visits'
    series.tensionX = 0.2
    series.propertyFields.fill = 'lineColor'
    series.stroke = am4core.color('#F2473A')
    series.fillOpacity = 0.4
    dateAxis.keepSelection = true
    let series1Bullets1 = series.bullets.push(new am4charts.CircleBullet())
    series1Bullets1.tooltipHTML = `
   <span style="font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 20px;color: #FFFFFF;">{visits}% Correct Answers</span>`
    series.tooltip.getFillFromObject = false
    series.tooltip.background.fill = am4core.color('black')
    series1Bullets1.circle.fill = am4core.color('#F2473A')
    series.tooltip.background.fill = am4core.color('black')
    series1Bullets1.circle.fill = am4core.color('#F2473A')
    series1Bullets1.keepSelection = true
    return () => {
      chart?.dispose()
    }
  }, [selectedGraphTab, localStorage.theme])
  const { best_section, worst_section } = progressData.data
  const graphTabs =
    olpPath === 'hpat'
      ? [
          {
            id: 'S1',
            title: 'S1',
          },
          {
            id: 'S2',
            title: 'S2',
          },
          {
            id: 'S3',
            title: 'S3',
          },
          {
            id: 'Overall',
            title: 'Overall',
          },
        ]
      : [
          {
            id: 'VR',
            title: 'VR',
          },
          {
            id: 'DM',
            title: 'DM',
          },
          {
            id: 'QR',
            title: 'QR',
          },
          {
            id: 'SJ',
            title: 'SJ',
          },
          {
            id: 'Overall',
            title: 'Overall',
          },
        ]
  useEffect(() => {
    if (olpPath === 'hpat' && selectedGraphTab === 'VR') {
      dispatch(updateSelectedTab('S1'))
    }
  }, [dispatch, olpPath, selectedGraphTab])
  return (
    <>
      <div className="relative ml-auto left-0  w-full mt-6">
        <Tab.Group>
          <Tab.List className="border-b border-gray-100 dark:border-dark-500">
            {graphTabs.map((tab, index) => (
              <Tab key={index} as={Fragment}>
                <button
                  onClick={() => {
                    dispatch(updateSelectedTab(tab.id))
                  }}
                  className={`pb-2 mr-4 border-b border-transparent text-sm
                          ${
                            selectedGraphTab === tab.id
                              ? 'text-gray-900 border-red-500 dark:text-white'
                              : 'text-gray-700 dark:text-dark-100'
                          }`}
                >
                  {tab.title}
                </button>
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
      </div>
      <div
        id="chartdiv"
        style={{ width: '100%', height: '353px', marginTop: '24px' }}
      ></div>
      {(best_section || worst_section) && (
        <p className="text-base text-gray-700 dark:text-dark-100 font-normal mt-4">
          You are{' '}
          {best_section ? `the strongest in ${best_section.display_name}` : ''}
          {best_section && worst_section ? ' and ' : ''}
          {worst_section ? `the weakest in ${worst_section.display_name}` : ''}.
        </p>
      )}
    </>
  )
}

export default Graph
