import { Link } from 'react-router-dom'
import { Bluebook, Lock } from 'components/icons'
import { getMinutesWithHours, isUcatPath } from 'components/shared/helper'

const ModuleCard = ({
  link,
  modualeNumber,
  moduleName,
  moduleMinutes,
  modulePercentageCompleted,
  category,
  userCannotAccess,
  icon,
  showRecommendedTime,
}) => {
  return (
    <div
      // style={{ minWidth: '320px' }}
      className={`my-2 px-2 w-full sm:w-3/6 md:w-3/6 lg:w-2/6 lg:my-3 lg:px-3 ${
        userCannotAccess ? 'pointer-events-none opacity-25' : ''
      }`}
    >
      <Link to={`${isUcatPath}/${category}/module/${link}`}>
        <div>
          <div className="border dark:border-dark-400 rounded-lg px-6 py-6  hover:border-red-500 dark:hover:border-red-500">
            <div className="flex w-full ">
              <div>
                {userCannotAccess ? (
                  <div
                    className={`rounded-full  flex items-center justify-center bg-gray-300 dark:bg-gray-500 dark:bg-opacity-10  h-16 w-16`}
                  >
                    <Lock className="h-6 w-6 dark:text-white" />
                  </div>
                ) : (
                  <div
                    className={`rounded-full  flex items-center justify-center bg-blue-300 dark:bg-blue-500 dark:bg-opacity-10  h-16 w-16`}
                  >
                    {icon && icon.url ? (
                      <img src={icon?.url} alt={icon?.name} />
                    ) : (
                      <Bluebook />
                    )}
                  </div>
                )}
              </div>
              <div className="h-20 ml-4 mt-1">
                <h1 className="text-sm text-gray-700 dark:text-dark-100 text-left ">
                  Module {modualeNumber}
                </h1>
                <p className="font-semibold mt-1 text-gray-900 dark:text-white text-base text-left h-12 multiline-overflow ">
                  {moduleName}
                </p>
                {showRecommendedTime && (
                  <h1 className="text-sm mt-1 text-gray-500 dark:text-dark-300 text-left">
                    {moduleMinutes && moduleMinutes !== 0
                      ? getMinutesWithHours(moduleMinutes)
                      : '0 mins'}
                  </h1>
                )}
              </div>
            </div>
            <div
              className={`flex relative items-center ${
                showRecommendedTime ? 'mt-8' : ''
              }`}
            >
              <div className="overflow-hidden h-0.5 text-xs flex w-5/6 rounded bg-gray-300 dark:bg-dark-400">
                <div
                  style={{
                    width: `${modulePercentageCompleted}%`,
                  }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-700"
                ></div>
              </div>
              <div className="text-right sm:w-1/6 w-1/4">
                <h1
                  className={`${
                    modulePercentageCompleted === 100
                      ? 'text-green-700 text-sm'
                      : 'text-gray-500 text-sm'
                  }`}
                >
                  {modulePercentageCompleted}%
                </h1>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default ModuleCard
