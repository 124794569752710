import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const setNewPassword = createAsyncThunk(
  'changePassword/setNewPassword',
  async ({ data, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(`account/change-password`, {
        current_password: data.current_password,
        new_password: data.new_password,
      })
      onSuccess && onSuccess()
      return res
    } catch (err) {
      const changePasswordError = rejectWithValue(err.response.data)
      onError && onError(changePasswordError.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)
const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState: {
    newPasswordLoader: false,
    errorMessage: '',
  },
  extraReducers: {
    [setNewPassword.pending]: (state, action) => {
      state.newPasswordLoader = true
    },
    [setNewPassword.fulfilled]: (state, { payload }) => {
      state.newPasswordLoader = false
    },
    [setNewPassword.rejected]: (state, action) => {
      state.newPasswordLoader = false
    },
  },
})

export default changePasswordSlice.reducer
