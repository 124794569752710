const Archieve = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="current"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="current"
      fillRule="evenodd"
      d="M3.333 3.167a.833.833 0 000 1.666h9.334a.833.833 0 000-1.666H3.333zM1.5 4c0 .84.564 1.547 1.333 1.764V12a1.833 1.833 0 001.834 1.833h6.666A1.833 1.833 0 0013.167 12V5.764a1.834 1.834 0 00-.5-3.597H3.333C2.321 2.167 1.5 2.987 1.5 4zm10.667 8V5.833H3.833V12a.833.833 0 00.834.833h6.666a.833.833 0 00.834-.833zm-5.5-4.5a.5.5 0 100 1h2.666a.5.5 0 100-1H6.667z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Archieve
