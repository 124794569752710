const Math = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="current"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fill="current"
      fillRule="evenodd"
      d="M8 1.5a.5.5 0 01.5.5v5.5H14a.5.5 0 010 1H8.5V14a.5.5 0 01-1 0V8.5H2a.5.5 0 010-1h5.5V2a.5.5 0 01.5-.5zm3.354 1.146a.5.5 0 00-.708.708l.647.646-.647.646a.5.5 0 00.708.708L12 4.707l.646.647a.5.5 0 00.708-.708L12.707 4l.647-.646a.5.5 0 00-.708-.708L12 3.293l-.646-.647zm-6.854.02a.5.5 0 10-1 0V3.5h-.833a.5.5 0 100 1H3.5v.833a.5.5 0 101 0V4.5h.833a.5.5 0 100-1H4.5v-.833zm7.5 7.5a.5.5 0 100 1h.007a.5.5 0 000-1H12zm-.5 3.167a.5.5 0 01.5-.5h.007a.5.5 0 010 1H12a.5.5 0 01-.5-.5zM2.667 11.5a.5.5 0 000 1h2.666a.5.5 0 000-1H2.667z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Math
