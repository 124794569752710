const SmileEmoji = ({ className }) => (
  <svg
    className={`h-4 w-4 ${className}`}
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10ZM10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM7 7.25C6.58579 7.25 6.25 7.58579 6.25 8C6.25 8.41421 6.58579 8.75 7 8.75H7.01C7.42421 8.75 7.76 8.41421 7.76 8C7.76 7.58579 7.42421 7.25 7.01 7.25H7ZM12.25 8C12.25 7.58579 12.5858 7.25 13 7.25H13.01C13.4242 7.25 13.76 7.58579 13.76 8C13.76 8.41421 13.4242 8.75 13.01 8.75H13C12.5858 8.75 12.25 8.41421 12.25 8ZM8.03571 12.4751C7.74583 12.1792 7.27098 12.1744 6.97511 12.4643C6.67924 12.7542 6.6744 13.229 6.96429 13.5249C7.36 13.9288 7.83232 14.2496 8.35358 14.4686C8.87485 14.6877 9.43458 14.8005 10 14.8005C10.5654 14.8005 11.1252 14.6877 11.6464 14.4686C12.1677 14.2496 12.64 13.9288 13.0357 13.5249C13.3256 13.229 13.3208 12.7542 13.0249 12.4643C12.729 12.1744 12.2542 12.1792 11.9643 12.4751C11.7082 12.7364 11.4026 12.944 11.0653 13.0858C10.728 13.2275 10.3659 13.3005 10 13.3005C9.63414 13.3005 9.27196 13.2275 8.93467 13.0858C8.59738 12.944 8.29176 12.7364 8.03571 12.4751Z"
      fill="currentColor"
    />
  </svg>
)

export default SmileEmoji
