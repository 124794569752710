import { Loading } from 'components/icons'

const Spinner = ({ style, className }) => {
  return (
    <div
      className="flex flex-col mt-4 justify-center items-center h-full w-full"
      style={style}
    >
      <Loading className="text-red-600" />
      <span className={`mt-4  ${className ? '' : 'dark:text-white'}`}>
        Loading ...
      </span>
    </div>
  )
}

export default Spinner
