import BookingQuizBottomArea from 'components/booking/BookingQuizBottomArea'
import BookingQuizMainArea from 'components/booking/BookingQuizMainArea'
import BookingQuizReview from 'components/booking/BookingQuizReview'
import BookingQuizTopArea from 'components/booking/BookingQuizTopArea'
import QuizIntroductionModal from 'components/booking/QuizIntroductionModal'
import { Warning } from 'components/icons'
import { NoRecord } from 'components/no-record'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearLearQuiz } from 'redux/learn/resourceSlice'
const LearnQuiz = ({ previousChapter, nextChapter }) => {
  const dispatch = useDispatch()
  const { bookDetails, questionsIndex } = useSelector((state) => ({
    bookDetails: state.resource.bookDetails,
    questionsIndex: state.resource.questionsIndex,
  }))

  const recoursesQuestion =
    bookDetails?.chapterable?.questions?.length > 0 &&
    bookDetails?.chapterable?.questions[questionsIndex]

  useEffect(() => {
    return () => {
      dispatch(clearLearQuiz())
    }
  }, [dispatch])

  const optionsArray = []

  // Define option keys
  const optionKeys = ['a', 'b', 'c', 'd', 'e']
  // Iterate over option keys dynamically
  optionKeys.forEach((key) => {
    const optionLabel = key.toUpperCase()
    const optionHtml =
      recoursesQuestion && recoursesQuestion[`option_${key}_html`]
    const percentageChosen =
      recoursesQuestion[`option_${key}_percentage_chosen`]
    if (optionHtml) {
      optionsArray.push({
        label: optionLabel,
        html: optionHtml,
        percentageChosen: percentageChosen,
        isCorrect: recoursesQuestion?.correct_option === key,
        inputKey: key,
      })
    }
  })

  const questionSubmission =
    bookDetails?.chapterable?.submission?.user_inputs?.find(
      (input) => input.question_id === recoursesQuestion?.id,
    )

  const isQuestionCompleted = bookDetails?.chapterable?.submission?.is_completed
  return (
    <div className="">
      {/* Introduction Modal */}
      <QuizIntroductionModal />
      {bookDetails?.chapterable?.questions?.length > 0 ? (
        <>
          {/* Header */}
          <BookingQuizTopArea />
          {/* Main content */}
          {!isQuestionCompleted ? (
            <BookingQuizMainArea
              recoursesQuestion={recoursesQuestion}
              optionsArray={optionsArray}
            />
          ) : (
            <BookingQuizReview
              recoursesQuestion={recoursesQuestion}
              optionsArray={optionsArray}
              questionSubmission={questionSubmission}
            />
          )}
        </>
      ) : (
        <div>
          <NoRecord
            icon={<Warning className="h-5 w-5 dark:text-white" />}
            title="There are no questions in this quiz"
          />
        </div>
      )}
      {/* Footer */}
      <BookingQuizBottomArea
        previousChapter={previousChapter}
        nextChapter={nextChapter}
        isQuestionCompleted={isQuestionCompleted}
        recoursesQuestion={recoursesQuestion}
        questionSubmission={questionSubmission}
      />
    </div>
  )
}
export default LearnQuiz
