import PropTypes from 'prop-types'

const Input = ({ hideLabel, name, label, helpText, ...props }) => {
  return (
    <div className="mb-6">
      {!hideLabel && (
        <div className="text-sm text-gray-700 dark:text-dark-100 mb-1">
          {label}
        </div>
      )}
      <input
        {...props}
        className="px-4 py-3 border rounded-md border-gray-500 dark:border-dark-400 dark:bg-dark-500 hover:border-gray-700 outline-none text-gray-900 dark:text-white w-full"
      />
      {helpText && (
        <div className="mt-1 text-sm text-gray-700 dark:text-dark-200">
          {helpText}
        </div>
      )}
    </div>
  )
}

Input.propTypes = {
  hideLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
}

export default Input
