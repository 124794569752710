import { createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getAttempt = createAsyncThunk(
  'hpatPortal/getAttempt',
  async ({ attemptId, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `question-set-attempt/attempt/${attemptId}`,
      )
      onSuccess && onSuccess()
      return res
    } catch (error) {
      onError && onError()
      throw rejectWithValue(error.response)
    }
  },
)

export const syncProgress = createAsyncThunk(
  'hpatPortal/syncProgress',
  async (
    { questionAttempts, attemptId, onSuccess, isManual },
    { rejectWithValue },
  ) => {
    const progress = questionAttempts
      .filter((data) => (isManual ? data : data.isUpdated))
      .map((questionAttempt) => {
        return {
          question_attempt_id: questionAttempt.id,
          serialised_input: questionAttempt.serialised_input,
          time_spent: questionAttempt.time_spent || 0,
          flagged: questionAttempt.flagged,
        }
      })
    if (progress && progress.length > 0) {
      try {
        const res = await axiosInterceptors.put(
          `question-set-attempt/attempt/${attemptId}/progress`,
          {
            question_attempt_updates: progress,
          },
        )
        onSuccess && onSuccess()
        return res
      } catch (error) {
        throw rejectWithValue(error)
      }
    }
  },
)

export const finishAttempt = createAsyncThunk(
  'hpatPortal/finishAttempt',
  async ({ id, questionAttempts, onSuccess }) => {
    const progress = questionAttempts.map((questionAttempt) => {
      return {
        question_attempt_id: questionAttempt.id,
        serialised_input: questionAttempt.serialised_input,
        time_spent: questionAttempt.time_spent || 0,
        flagged: questionAttempt.flagged,
      }
    })
    try {
      const res = await axiosInterceptors.put(
        `/question-set-attempt/attempt/${id}/finish`,
        {
          question_attempt_updates: progress,
        },
      )
      onSuccess && onSuccess()
      return res
    } catch (error) {}
  },
)

export const reportStageReached = createAsyncThunk(
  'hpatPortal/reportStageReached',
  async (data) => {
    return await axiosInterceptors.put(
      `question-set-attempt/attempt/${data.attemptId}/record-stage-reached`,
      {
        identifier: data.identifier,
      },
    )
  },
)
export const stageTimeSpent = createAsyncThunk(
  'hpatPortal/stageTimeSpent',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(
        `/question-set-attempt/attempt/${data.attemptId}/record-stages-time-spent`,
        {
          stage_progressions: [
            {
              stage_identifier: data.identifier,
              time_spent: data.timeSpent,
              time_spent_reporting_issues:
                data.time_spent_reporting_issues || 0,
            },
          ],
        },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const switchToUntimedMode = createAsyncThunk(
  'hpatPortal/switchToUntimedMode',
  async ({ id, simulatedConditions }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(
        `/question-set-attempt/attempt/${id}/switch-simulated-modes`,
        {
          simulated_conditions: simulatedConditions,
        },
      )
      return res
    } catch (error) {
      throw rejectWithValue(error.response.data)
    }
  },
)
