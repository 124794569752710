import PropTypes from 'prop-types'

const Select = ({ hideLabel, name, label, helpText, options, ...props }) => {
  return (
    <div className="mb-6">
      {!hideLabel && (
        <div className="text-sm text-gray-700 dark:text-dark-100 mb-1">
          {label}
        </div>
      )}
      <select
        className="bg-white dark:bg-dark-500 px-4 py-3 border rounded-md border-gray-500 dark:border-dark-400 hover:border-gray-700 outline-none text-gray-900 dark:text-white w-full"
        {...props}
      >
        {options &&
          options.map((option, optionIndex) => (
            <option key={optionIndex} value={option.value}>
              {option.name}
            </option>
          ))}
      </select>
      {helpText && (
        <div className="mt-1 text-sm text-gray-700 dark:text-dark-200">
          {helpText}
        </div>
      )}
    </div>
  )
}

Select.propTypes = {
  hideLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
  options: PropTypes.array.isRequired,
}

export default Select
