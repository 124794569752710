import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { remove } from 'lodash'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'

export const getQbankData = createAsyncThunk(
  'practice/getQbankData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`qbank`)
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const startQbankAttempt = createAsyncThunk(
  'practice/startQbankAttempt',
  async (
    {
      sectionNumber,
      qbankCategoriesIds,
      numQuestions,
      timed,
      onError,
      isUseAi,
      isRetry,
    },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.post(`qbank/start-attempt`, {
        section_number: sectionNumber ? sectionNumber : undefined,
        qbank_categories:
          qbankCategoriesIds[0] !== 0 && qbankCategoriesIds[0] !== -1
            ? qbankCategoriesIds
            : undefined,
        num_questions: numQuestions,
        timed: timed,
        use_ai: isUseAi,
        is_retry: isRetry,
      })
      return res
    } catch (error) {
      const errorType = rejectWithValue(error.response.data)
      onError && onError(errorType.payload)
      throw rejectWithValue(error.response.data)
    }
  },
)
export const startAttempt = createAsyncThunk(
  'practice/startAttempt',
  async ({ id, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(`/quiz/start-attempt/${id}`)
      onSuccess && onSuccess(res)
      return res
    } catch (error) {
      onError && onError()
      throw rejectWithValue(error.response.data)
    }
  },
)
export const startQbankGotoQuestion = createAsyncThunk(
  'practice/startQbankGotoQuestion',
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(`/qbank/go-to-question/${id}`)
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)
export const qbankSlice = createSlice({
  name: 'practice',
  initialState: {
    showPracticeModal: false,
    qbankData: null,
    qbankDataLoader: false,
    showCalculatorModal: false,
    questionSet: 0,
    question: null,
    attemptId: null,
    qbankAttemptId: null,
    isAttemptLoading: false,
    isAttemptId: null,
    qbankAttemptData: {
      sectionNumber: null,
      qbankCategoriesIds: [],
      numQuestions: '',
      timed: true,
    },
    isUseAi: false,
    isRetry: false,
    validationOnStart: '',
    goToQuestion: {
      isLoading: false,
      isRejected: false,
      isLocked: false,
      data: null,
    },
    isKeepPracticing: false,
  },
  extraReducers: {
    [startAttempt.pending]: (state, action) => {
      state.isAttemptId = action.meta.arg.id
      state.isAttemptLoading = true
    },
    [startAttempt.fulfilled]: (state, { payload }) => {
      state.isAttemptLoading = false
      if (payload.data && payload.data.question_set_attempt_id) {
        state.attemptId = payload.data.question_set_attempt_id
      }
      state.isAttemptId = null
    },
    [startAttempt.rejected]: (state, { payload }) => {
      state.isAttemptLoading = false
      toast.error(payload?.message, {
        duration: 3000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '350px',
        },
      })
    },
    [startQbankAttempt.pending]: (state, action) => {
      state.isAttemptLoading = true
    },
    [startQbankAttempt.fulfilled]: (state, { payload }) => {
      state.isAttemptLoading = false
      if (payload?.data && payload?.data?.question_set_attempt_id) {
        state.qbankAttemptId = payload?.data?.question_set_attempt_id
      }
    },
    [startQbankAttempt.rejected]: (state, { payload }) => {
      state.isAttemptLoading = false
      state.validationOnStart = payload?.errors?.num_questions?.toString()
    },
    [getQbankData.pending]: (state, action) => {
      state.qbankDataLoader = true
    },
    [getQbankData.fulfilled]: (state, { payload }) => {
      state.qbankDataLoader = false
      state.qbankData = payload.data
      if (!state.isKeepPracticing) {
        state.isUseAi = payload?.data?.ai_system_used_last_attempt || false
      }
      state.isKeepPracticing = false
    },
    [getQbankData.rejected]: (state, action) => {
      state.qbankDataLoader = false
    },
    [startQbankGotoQuestion.pending]: (state) => {
      state.goToQuestion.isLoading = true
      if (state?.goToQuestion?.isRejected) {
        state.goToQuestion.isRejected = false
      }
      if (state?.goToQuestion?.isLocked) {
        state.goToQuestion.isLocked = false
      }
    },
    [startQbankGotoQuestion.fulfilled]: (state, { payload }) => {
      state.goToQuestion.isLoading = false
      state.goToQuestion.data = payload?.data
    },
    [startQbankGotoQuestion.rejected]: (state, { payload }) => {
      state.goToQuestion.isLoading = false
      state.goToQuestion.data = { message: payload?.data?.message }
      if (payload?.status === 403) {
        state.goToQuestion.isLocked = true
      } else {
        state.goToQuestion.isRejected = true
      }
    },
  },
  reducers: {
    setKeepPracticing: (state, { payload }) => {
      state.isKeepPracticing = payload
    },
    togglePracticeMenu: (state) => {
      state.showPracticeModal = !state.showPracticeModal
    },
    clearGotoQuestionData: (state) => {
      state.goToQuestion = {
        isLoading: false,
        isRejected: false,
        isLocked: false,
        data: null,
      }
    },
    setAttemptId: (state, { payload }) => {
      state.attemptId = payload
    },
    setQbankAttemptId: (state, { payload }) => {
      state.qbankAttemptId = payload
    },
    resetQuestionSet: (state) => {
      state.qbankAttemptData.numQuestions = 1
    },
    clearQuestionSet: (state) => {
      state.question = null
      state.questionSet = 0
      state.qbankAttemptData.numQuestions = ''
      state.qbankAttemptData.timed = true
    },
    setQuestionSet: (state, action) => {
      const {
        question,
        questionSetSize,
        maxUntimedQuestion,
        minUntimedQuestion,
        timed,
      } = action.payload

      if (Number(question) * questionSetSize >= 0) {
        state.question = parseInt(question)
        state.questionSet =
          action.payload.question * action.payload.questionSetSize
        state.qbankAttemptData.numQuestions =
          parseInt(question) * questionSetSize
        state.qbankAttemptData.timed = timed
        if (Number(question) * questionSetSize <= maxUntimedQuestion) {
          state.validationOnStart = ''
        } else {
          state.validationOnStart =
            'Max questions limit is ' + maxUntimedQuestion
        }
        if (Number(question) < minUntimedQuestion) {
          state.validationOnStart =
            'The number of questions must be at least ' + minUntimedQuestion
        }
      } else {
        state.validationOnStart = 'Invalid question set'
      }
    },
    setQuestionSetTimed: (state, { payload }) => {
      state.qbankAttemptData.timed = payload.timed
    },
    updateQbankAtteptSubtest: (state, { payload }) => {
      state.qbankAttemptData = {
        ...state.qbankAttemptData,
        ...payload,
      }
      state.qbankAttemptData.qbankCategoriesIds = []
      state.qbankAttemptData.numQuestions = null
    },
    updateqbankAttemptData: (state, { payload }) => {
      state.qbankAttemptData.qbankCategoriesIds = payload
    },
    updateQbankAtteptQuestions: (state, { payload }) => {
      state.qbankAttemptData.numQuestions = payload
      state.validationOnStart = ''
    },
    addMultipleCategory: (state, { payload }) => {
      const index = state.qbankAttemptData.qbankCategoriesIds.indexOf(-1)
      if (index > -1) {
        state.qbankAttemptData.qbankCategoriesIds.splice(index, 1)
      }
      state.qbankAttemptData.qbankCategoriesIds.push(payload)
    },
    removeMultipleCategory: (state, { payload }) => {
      remove(state.qbankAttemptData.qbankCategoriesIds, (n) => n === payload)
    },
    setValidationOnStart: (state, { payload }) => {
      state.validationOnStart = payload.message
    },
    clearQbankAttemptData: (state, { payload }) => {
      state.qbankAttemptData = {
        sectionNumber: null,
        qbankCategoriesIds: [],
        numQuestions: '',
        timed: true,
      }
    },
    setShowCalculatorModal: (state, { payload }) => {
      state.showCalculatorModal = payload
    },
    setUseAi: (state, { payload }) => {
      state.isUseAi = payload
      if (payload && state.isRetry) {
        state.isRetry = false
      }
    },
    setIsRetry: (state, { payload }) => {
      if (payload && state.isUseAi) {
        state.isUseAi = false
      }
      state.isRetry = payload
    },
  },
})

export const {
  setIsRetry,
  setKeepPracticing,
  clearQuestionSet,
  togglePracticeMenu,
  updateQbankAtteptSubtest,
  setQuestionSet,
  updateqbankAttemptData,
  addMultipleCategory,
  removeMultipleCategory,
  setValidationOnStart,
  updateQbankAtteptQuestions,
  setQuestionSetTimed,
  setAttemptId,
  setQbankAttemptId,
  resetQuestionSet,
  clearGotoQuestionData,
  clearQbankAttemptData,
  setShowCalculatorModal,
  setUseAi,
} = qbankSlice.actions

export default qbankSlice.reducer
