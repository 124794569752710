const Leave = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="currentColor"
    viewBox="0 0 14 14"
    className={`${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.33333 0.166016C1.8471 0.166016 1.38079 0.35917 1.03697 0.702986C0.693154 1.0468 0.5 1.51312 0.5 1.99935V9.99935C0.5 10.4856 0.693154 10.9519 1.03697 11.2957C1.38079 11.6395 1.8471 11.8327 2.33333 11.8327H7C7.48623 11.8327 7.95255 11.6395 8.29636 11.2957C8.64018 10.9519 8.83333 10.4856 8.83333 9.99935V8.66602C8.83333 8.38987 8.60948 8.16602 8.33333 8.16602C8.05719 8.16602 7.83333 8.38987 7.83333 8.66602V9.99935C7.83333 10.2204 7.74554 10.4323 7.58926 10.5886C7.43298 10.7449 7.22101 10.8327 7 10.8327H2.33333C2.11232 10.8327 1.90036 10.7449 1.74408 10.5886C1.5878 10.4323 1.5 10.2204 1.5 9.99935V1.99935C1.5 1.77833 1.5878 1.56637 1.74408 1.41009C1.90036 1.25381 2.11232 1.16602 2.33333 1.16602H7C7.22101 1.16602 7.43298 1.25381 7.58926 1.41009C7.74554 1.56637 7.83333 1.77834 7.83333 1.99935V3.33268C7.83333 3.60882 8.05719 3.83268 8.33333 3.83268C8.60948 3.83268 8.83333 3.60882 8.83333 3.33268V1.99935C8.83333 1.51312 8.64018 1.0468 8.29636 0.702986C7.95255 0.35917 7.48623 0.166016 7 0.166016H2.33333ZM11.3536 3.6458C11.1583 3.45053 10.8417 3.45053 10.6464 3.6458C10.4512 3.84106 10.4512 4.15764 10.6464 4.3529L11.7929 5.49935H3.66667C3.39052 5.49935 3.16667 5.72321 3.16667 5.99935C3.16667 6.27549 3.39052 6.49935 3.66667 6.49935H11.7929L10.6464 7.64579C10.4512 7.84106 10.4512 8.15764 10.6464 8.3529C10.8417 8.54816 11.1583 8.54816 11.3536 8.3529L13.3536 6.3529C13.5488 6.15764 13.5488 5.84106 13.3536 5.64579L11.3536 3.6458Z"
      fill="currentColor"
    />
  </svg>
)
export default Leave
