const Star = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={`h-4 w-4 ${className}`}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.995.835a.5.5 0 01.449.278l1.94 3.933 4.34.63a.5.5 0 01.277.852L11.857 9.59l.741 4.323a.5.5 0 01-.726.527L8 12.399l-3.882 2.04a.5.5 0 01-.725-.527l.741-4.323L.99 6.53a.5.5 0 01.277-.854l4.34-.629 1.941-3.933a.5.5 0 01.448-.278zm0 1.63l-1.609 3.26a.5.5 0 01-.376.273l-3.596.521L5.02 9.056a.5.5 0 01.144.443l-.615 3.584 3.218-1.692a.5.5 0 01.466 0l3.208 1.69-.614-3.582a.5.5 0 01.144-.443l2.606-2.537L9.981 6a.5.5 0 01-.377-.274l-1.609-3.26z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Star
