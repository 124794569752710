import { initializeApp } from 'firebase/app'
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyARKDiKOvBMRbdy-kU8AjAllozWLAidBlo',
  authDomain: 'medentry-reactnative.firebaseapp.com',
  projectId: 'medentry-reactnative',
  storageBucket: 'medentry-reactnative.appspot.com',
  messagingSenderId: '279843026099',
  appId: '1:279843026099:web:a27eaaada1ab47afd62cc6',
  measurementId: 'G-JNLYQP5JXK',
}
let firebaseApp, messaging

isSupported()
  .then((value) => {
    if (value) {
      firebaseApp = initializeApp(firebaseConfig)
      messaging = getMessaging(firebaseApp)
    }
  })
  .catch((error) => {
    // console.log(error)
  })

export const fetchToken = async () => {
  try {
    const isBrowserSupported = await isSupported()
    if (isBrowserSupported) {
      if (messaging) {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_NOTIFICATION_TOKEN,
        })
        return token
      }
    }
  } catch (error) {
    return
  }
}

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload)
//     })
//   })
