const EyeOff = ({ className, ...props }) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 22 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.46967 0.46967C1.76256 0.176777 2.23744 0.176777 2.53033 0.46967L16.8172 14.7565C16.8654 14.7939 16.9096 14.8378 16.9485 14.8879L20.5303 18.4697C20.8232 18.7626 20.8232 19.2374 20.5303 19.5303C20.2374 19.8232 19.7626 19.8232 19.4697 19.5303L16.2465 16.3071C14.6369 17.2661 12.8851 17.75 11 17.75C6.63999 17.75 3.09715 15.1814 0.348826 10.3721C0.217043 10.1415 0.217059 9.8584 0.348868 9.62781C1.57252 7.48708 2.95233 5.7879 4.50004 4.5607L1.46967 1.53033C1.17678 1.23744 1.17678 0.762563 1.46967 0.46967ZM5.56876 5.62942C4.24108 6.64772 3.00402 8.09491 1.86833 10.0001C4.41111 14.2677 7.46133 16.25 11 16.25C12.4819 16.25 13.8599 15.9039 15.1466 15.2073L12.3414 12.402C11.9342 12.6299 11.4719 12.7526 10.997 12.7524C10.2675 12.7522 9.56807 12.4621 9.05245 11.9461C8.53683 11.4302 8.2473 10.7305 8.24756 10.001C8.24773 9.52679 8.37037 9.06523 8.59795 8.65861L5.56876 5.62942ZM9.76067 9.82134L11.1786 11.2393C11.119 11.248 11.0585 11.2524 10.9975 11.2524C10.6659 11.2523 10.3479 11.1204 10.1135 10.8859C9.87907 10.6513 9.74744 10.3332 9.74756 10.0016C9.74758 9.94089 9.75201 9.88067 9.76067 9.82134ZM8.56927 4.08607C9.35876 3.86023 10.1762 3.74708 10.9973 3.74999L11 3.75C14.5387 3.75 17.5889 5.73229 20.1317 9.99994C19.4404 11.1597 18.7114 12.1511 17.9462 12.9789C17.6651 13.2831 17.6838 13.7576 17.9879 14.0388C18.2921 14.3199 18.7666 14.3012 19.0478 13.9971C19.984 12.9841 20.8503 11.773 21.6511 10.3722C21.7829 10.1416 21.783 9.85849 21.6512 9.62788C18.9031 4.81906 15.3607 2.25052 11.0013 2.25C10.0393 2.24672 9.08165 2.37933 8.15672 2.64392C7.75849 2.75784 7.528 3.17303 7.64192 3.57127C7.75585 3.96951 8.17104 4.2 8.56927 4.08607Z"
      fill="currentColor"
    />
  </svg>
)

export default EyeOff
