import { createSlice } from '@reduxjs/toolkit'

export const floatingMenuSlice = createSlice({
  name: 'floatingMenu',
  initialState: {
    initialFontSize: 1,
    zoomPlusCount: 0,
    zoomMinusCount: 0,
    isZoomNegative: true,
    showStaffTools: false,
    isLoadingSS: false,
    showFloatingMenu: false,
    collapseInstructions: true,
    collapseSection: true,
  },
  reducers: {
    setIsLoadingSS: (state, { payload }) => {
      state.isLoadingSS = payload
    },
    toggleStaffTools: (state) => {
      state.showStaffTools = !state.showStaffTools
      if (state.isLoadingSS) {
        state.isLoadingSS = false
      }
    },
    updateInstructions: (state) => {
      state.collapseInstructions = !state.collapseInstructions
    },
    updateSection: (state) => {
      state.collapseSection = !state.collapseSection
    },
    toggleShowFloatingMenu: (state) => {
      state.showFloatingMenu = !state.showFloatingMenu
    },
    zoomOut: (state, { payload }) => {
      state.initialFontSize =
        state.initialFontSize - (5 / 100) * state.initialFontSize
      state.zoomMinusCount = state.zoomMinusCount + 1
      state.zoomPlusCount =
        state.zoomPlusCount > 0 ? state.zoomPlusCount - 1 : state.zoomPlusCount

      // setting isZoomNegative true when user goes below 1rem
      if (state.initialFontSize < 1) {
        state.isZoomNegative = true
      } else {
        state.isZoomNegative = false
        state.zoomMinusCount = 0
      }
    },
    zoomIn: (state, { payload }) => {
      state.initialFontSize =
        (5 / 100) * state.initialFontSize + state.initialFontSize
      state.zoomPlusCount = state.zoomPlusCount + 1
      state.zoomMinusCount =
        state.zoomMinusCount > 0
          ? state.zoomMinusCount - 1
          : state.zoomMinusCount

      // setting isZoomNegative true when user goes below 1rem
      if (state.initialFontSize < 1) {
        state.isZoomNegative = true
        state.zoomPlusCount = 0
      } else {
        state.isZoomNegative = false
      }
    },
  },
})

export const {
  zoomOut,
  zoomIn,
  toggleStaffTools,
  setIsLoadingSS,
  toggleShowFloatingMenu,
  updateInstructions,
  updateSection,
} = floatingMenuSlice.actions

export default floatingMenuSlice.reducer
