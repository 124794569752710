import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'

export const getSettingInfo = createAsyncThunk(
  'settings/getSettingInfo',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptors.get(`account/settings`)
      return response
    } catch (error) {
      return rejectWithValue({ error: error.message })
    }
  },
)
export const getDarkMode = createAsyncThunk(
  'settings/getDarkMode',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptors.get(`account/setting/dark_mode`)
      return response
    } catch (error) {
      return rejectWithValue({ error: error.message })
    }
  },
)

export const saveSettingInfo = createAsyncThunk(
  'settings/saveSettingInfo',
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(`account/settings`, {
        settings: [{ key: data.key, value: data.value }],
      })
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err)
    }
  },
)

export const getParticularSettings = createAsyncThunk(
  'settings/getParticularSettings',
  async ({ settingType }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`/account/setting/${settingType}`)

      return res
    } catch (err) {
      throw rejectWithValue(err?.response?.data)
    }
  },
)

const settingSlice = createSlice({
  name: 'settings',
  initialState: {
    settingInformation: { isLoading: false, data: [] },
    darkmode: false,
    settingLoaderIndex: null,
    settingError: null,
    settingLoader: false,
    hideUcatTestDaysCount: false,
    settingIndex: null,
    ucatTestCountdownIndex: null,
  },
  extraReducers: {
    [getParticularSettings.pending]: (state) => {},
    [getParticularSettings.fulfilled]: (state, { payload }) => {
      state.hideUcatTestDaysCount =
        payload?.data?.setting?.student_value !== null
          ? payload?.data?.setting?.student_value
          : payload?.data?.setting?.default_value
    },
    [getParticularSettings.rejected]: (state, { payload }) => {},
    [getDarkMode.fulfilled]: (state, { payload }) => {
      const isDarkMode =
        payload?.data?.setting?.student_value !== null
          ? payload?.data?.setting?.student_value
          : payload?.data?.setting?.default_value
      localStorage.theme = isDarkMode ? 'Dark' : 'Light'
      state.darkmode = isDarkMode ? 'Dark' : 'Light'
    },

    [getSettingInfo.pending]: (state, action) => {
      state.settingInformation.isLoading = true
    },
    [getSettingInfo.fulfilled]: (state, { payload }) => {
      state.settingInformation = {
        ...payload,
        isLoading: false,
      }

      state.settingIndex = payload?.data?.findIndex((setting) =>
        setting?.settings?.some((e) => e?.key === 'test_countdown_notice'),
      )
      if (state?.settingIndex) {
        state.ucatTestCountdownIndex = payload?.data[
          state?.settingIndex
        ]?.settings?.findIndex(
          (setting) => setting?.key === 'test_countdown_notice',
        )
        state.hideUcatTestDaysCount =
          payload?.data[state?.settingIndex]?.settings[
            state.ucatTestCountdownIndex
          ]?.student_value
      }
    },
    [getSettingInfo.rejected]: (state, action) => {
      state.settingInformation.isLoading = false
    },
    [saveSettingInfo.pending]: (state, action) => {
      state.settingLoader = true
      state.settingLoaderIndex = action.meta.arg.data.subSettingIndex
      state.settingError = null
    },
    [saveSettingInfo.fulfilled]: (state, { payload, meta }) => {
      state.settingLoader = false

      const {
        settingIndex,
        subSettingIndex,
        isThemeChange,
        ucatTestCountDown,
        value,
        key,
      } = meta.arg.data

      if (isThemeChange) {
        state.darkmode = value
        if (value) {
          document.documentElement.classList.add('dark')
          localStorage.theme = 'Dark'
        } else {
          document.documentElement.classList.remove('dark')
          localStorage.theme = 'Light'
        }
      } else if (ucatTestCountDown) {
        state.hideUcatTestDaysCount = !state.hideUcatTestDaysCount
        state.settingInformation.data[settingIndex].settings[
          subSettingIndex
        ].has_student_value_set = payload.data.updates[0].updated
        state.settingInformation.data[settingIndex].settings[
          subSettingIndex
        ].student_value = payload.data.updates[0].new_value
        state.settingLoaderIndex = null
      } else {
        if (key === 'test_countdown_notice') {
          state.hideUcatTestDaysCount = !state.hideUcatTestDaysCount
        }
        if (settingIndex !== -1) {
          state.settingInformation.data[settingIndex].settings[
            subSettingIndex
          ].has_student_value_set = payload.data.updates[0].updated
          state.settingInformation.data[settingIndex].settings[
            subSettingIndex
          ].student_value = payload.data.updates[0].new_value
        }

        state.settingLoaderIndex = null
      }
    },
    [saveSettingInfo.rejected]: (state, { payload, meta }) => {
      if (payload?.response?.status === 500) {
        toast.error('Something went wrong, please try again shortly.', {
          duration: 4000,
          position: 'top-center',
          style: {
            backdropFilter: 'blur(4px)',
            marginTop: '20px',
            width: '350px',
          },
        })
      }
      const settingError = payload?.response?.data
      state.settingLoader = false
      state.settingError = {
        index: meta.arg.data.settingIndex,
        subIndex: meta.arg.data.subSettingIndex,
        error: settingError?.errors
          ? settingError.errors['settings.0']?.[0] || settingError.message
          : '',
      }
      state.settingLoaderIndex = null
    },
    // 4115
  },
  reducers: {
    removeError: (state) => {
      state.settingError = null
    },
  },
})

export const { removeError } = settingSlice.actions

export default settingSlice.reducer
