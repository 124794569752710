import { Fragment } from 'react'
import { Chat, Close, Loading, Play } from 'components/icons'
import { Transition, Dialog } from '@headlessui/react'
import { Button } from 'components/shared'
import { Form, Formik } from 'formik'
import { MTextarea } from 'components/form'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { formatTimestamp } from 'components/shared/helper'
import * as Yup from 'yup'
import {
  addTranscriptNote,
  setSelectedNote,
  setTranscriptNoteModal,
  updateTranscriptNote,
} from 'redux/learn/resourceSlice'
import toast from 'react-hot-toast'
import { updateNote } from 'redux/learn/notesSlice'

const noteSchema = Yup.object({
  note: Yup.string()
    .required('Note is required')
    .max(2000, `The note may not be greater than 2000 characters.`),
})

const AddTranscriptNotes = ({
  open,
  disabled,
  onClose,
  area,
  chapterId,
  learningJournalEntry,
  bookType,
  moduleName,
  bookName,
  chapterName,
  updateTranscript,
}) => {
  const dispatch = useDispatch()

  const {
    selectedNote,
    addTranscriptLoader,
    bookDetails,
    updateNoteLoading,
    updateNoteLoader,
  } = useSelector((state) => ({
    selectedNote: state.resource.selectedNote,
    addTranscriptLoader: state.resource.addTranscriptLoader,
    bookDetails: state.resource.bookDetails,
    updateNoteLoading: state.bookmark.updateNoteLoading,
    updateNoteLoader: state.notes.updateNoteLoader,
  }))

  const handleSubmit = (values) => {
    if (selectedNote?.isEdit) {
      updateTranscript
        ? updateTranscript({ content: values?.note })
        : dispatch(
            updateNote({
              data: {
                type: selectedNote?.type,
                noteId: selectedNote?.id,
                content: values?.note,
                transcript_start_time: parseInt(selectedNote?.start),
                transcript_end_time: parseInt(selectedNote?.end),
                transcript_text: selectedNote?.text,
              },
              onSuccess: () => {
                dispatch(setTranscriptNoteModal(false))
                toast.success('Transcript note updated successfully', {
                  duration: 3000,
                  position: 'top-center',
                  style: {
                    backdropFilter: 'blur(4px)',
                    marginTop: '20px',
                    width: '350px',
                  },
                })
                dispatch(setSelectedNote(null))
              },
              onError: (errorsMassage) => {
                toast.error(errorsMassage.message || 'Something went wrong', {
                  duration: 3000,
                  position: 'top-center',
                  style: {
                    backdropFilter: 'blur(4px)',
                    marginTop: '20px',
                    width: '350px',
                  },
                })
              },
            }),
          )
    } else {
      dispatch(
        addTranscriptNote({
          chapterId,
          chapterableId: bookDetails?.chapterable?.id,
          data: {
            transcript_start_time: parseInt(selectedNote?.start),
            transcript_end_time: parseInt(selectedNote?.end),
            transcript_text: selectedNote?.text,
            content: values?.note,
          },
          onSuccess: () => {
            dispatch(setTranscriptNoteModal(false))
            toast.success('Transcript note created successfully', {
              duration: 3000,
              position: 'top-center',
              style: {
                backdropFilter: 'blur(4px)',
                marginTop: '20px',
                width: '350px',
              },
            })
            dispatch(setSelectedNote(null))
          },
          onError: (errorsMassage) => {
            toast.error(errorsMassage.message, {
              duration: 3000,
              position: 'top-center',
              style: {
                backdropFilter: 'blur(4px)',
                marginTop: '20px',
                width: '350px',
              },
            })
          },
        }),
      )
    }
  }

  const loading = updateNoteLoader || updateNoteLoading || addTranscriptLoader

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="bg-gray-700 bg-opacity-70 fixed max-w-full max-h-screen h-screen w-full inset-0 z-50  overflow-y-auto"
          onClose={() => {
            onClose()
          }}
        >
          <div className="min-h-screen h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                style={{ maxWidth: '530px' }}
                className="mt-16 md:mt-10 mb-16 p-6 inline-block max-w-full md:max-w-md  md:h-auto w-full text-left align-middle transition-all transform bg-white dark:bg-dark-600 shadow-xl rounded-md"
              >
                <div className="flex justify-between">
                  <Dialog.Title
                    as="h2"
                    className="text-2xl font-semibold leading-6 text-gray-900 dark:text-white"
                  >
                    {selectedNote?.isEdit ? 'Edit' : 'Add'} Transcript Note
                  </Dialog.Title>

                  <span
                    tabIndex={1}
                    className="cursor-pointer float-right"
                    onClick={() => onClose && onClose()}
                  >
                    <Close className="fill-current dark:text-white" />
                  </span>
                </div>
                <div className="py-4 capitalize text-gray-400">
                  {bookType ? bookType : ''}
                  {moduleName ? ' / ' + moduleName : ''}
                  {bookName ? ' / ' + bookName : ''}
                  {chapterName ? ' / ' + chapterName : ''}
                </div>
                <div className="mb-2">
                  <span className="bg-red-500 bg-opacity-20 dark:text-white">
                    {selectedNote?.text}
                  </span>
                  <div className="mt-2 flex items-center gap-2 dark:text-white">
                    <Play />
                    <span className="text-red-400 font-semibold text-xs uppercase tracking-wider">
                      Video Clip
                    </span>
                    {selectedNote?.start ? (
                      <span>
                        {formatTimestamp(selectedNote?.start)} -{' '}
                        {formatTimestamp(selectedNote?.end)}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="flex flex-col justify-between items-start">
                  <>
                    <div className="dark:text-white w-full">
                      <Formik
                        initialValues={{
                          note: selectedNote?.note || '',
                        }}
                        validationSchema={noteSchema}
                        onSubmit={(values) => {
                          handleSubmit(values)
                        }}
                      >
                        {({ handleSubmit }) => (
                          <Form onSubmit={handleSubmit}>
                            <>
                              <div className="flex flex-col gap-5">
                                <MTextarea
                                  label={
                                    <div className="font-semibold mb-2 text-base">
                                      Note
                                    </div>
                                  }
                                  name="note"
                                  placeholder=""
                                  rows={2}
                                />
                              </div>
                              <div className="flex items-center gap-2 mt-6">
                                <Button
                                  type="submit"
                                  className={`font-semibold tracking-widest py-4 px-6 ${
                                    loading
                                      ? 'pointer-events-none opacity-25'
                                      : ''
                                  }`}
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <Loading className="dark:text-white" />
                                  ) : selectedNote?.isEdit ? (
                                    'Edit'
                                  ) : (
                                    'Add'
                                  )}
                                </Button>

                                <Button
                                  type="button"
                                  className="font-semibold tracking-widest py-4 px-6"
                                  outlined
                                  onClick={() => onClose && onClose()}
                                >
                                  CLOSE
                                </Button>
                              </div>
                            </>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
export default AddTranscriptNotes
