const RoundSquare = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    className={`h-4 w-4 ${className}`}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.75 7.5C1.75 4.32436 4.32436 1.75 7.5 1.75C10.5919 1.75 13.1138 4.19035 13.2447 7.25H10C8.48122 7.25 7.25 8.48122 7.25 10V13.2447C4.19035 13.1138 1.75 10.5919 1.75 7.5ZM7.25 14.7458C3.36169 14.614 0.25 11.4204 0.25 7.5C0.25 3.49594 3.49594 0.25 7.5 0.25C11.4204 0.25 14.614 3.36169 14.7458 7.25H17C18.5188 7.25 19.75 8.48122 19.75 10V17C19.75 18.5188 18.5188 19.75 17 19.75H10C8.48122 19.75 7.25 18.5188 7.25 17V14.7458ZM14.6426 8.75H17C17.6904 8.75 18.25 9.30964 18.25 10V17C18.25 17.6904 17.6904 18.25 17 18.25H10C9.30964 18.25 8.75 17.6904 8.75 17V14.6426C11.7523 14.1209 14.1209 11.7523 14.6426 8.75ZM13.1137 8.75C12.6322 10.9222 10.9222 12.6322 8.75 13.1137V10C8.75 9.30964 9.30964 8.75 10 8.75H13.1137Z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default RoundSquare
