const Home = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="current"
    // stroke="currentColor"
    className={`h-4 w-4 ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.64646 1.64645C7.84172 1.45118 8.15831 1.45118 8.35357 1.64645L14.3536 7.64645C14.4966 7.78945 14.5393 8.0045 14.462 8.19134C14.3846 8.37818 14.2022 8.5 14 8.5H13.1667V12.6667C13.1667 13.1529 12.9735 13.6192 12.6297 13.963C12.2859 14.3068 11.8196 14.5 11.3333 14.5H10H6.00001H4.66668C4.18045 14.5 3.71414 14.3068 3.37032 13.963C3.0265 13.6192 2.83335 13.1529 2.83335 12.6667V8.5H2.00001C1.79778 8.5 1.61547 8.37818 1.53807 8.19134C1.46068 8.0045 1.50346 7.78945 1.64646 7.64645L7.64646 1.64645ZM6.50001 13.5H9.50002V10C9.50002 9.77899 9.41222 9.56703 9.25594 9.41074C9.09966 9.25446 8.88769 9.16667 8.66668 9.16667H7.33335C7.11233 9.16667 6.90037 9.25446 6.74409 9.41074C6.58781 9.56703 6.50001 9.77899 6.50001 10V13.5ZM10.5 13.5V10C10.5 9.51377 10.3069 9.04745 9.96304 8.70364C9.61923 8.35982 9.15291 8.16667 8.66668 8.16667H7.33335C6.84712 8.16667 6.3808 8.35982 6.03699 8.70364C5.69317 9.04745 5.50001 9.51377 5.50001 10V13.5H4.66668C4.44567 13.5 4.23371 13.4122 4.07743 13.2559C3.92115 13.0996 3.83335 12.8877 3.83335 12.6667V8C3.83335 7.72386 3.60949 7.5 3.33335 7.5H3.20712L8.00001 2.70711L12.7929 7.5H12.6667C12.3905 7.5 12.1667 7.72386 12.1667 8V12.6667C12.1667 12.8877 12.0789 13.0996 11.9226 13.2559C11.7663 13.4122 11.5544 13.5 11.3333 13.5H10.5Z"
      fill="current"
    />
  </svg>
)

export default Home
