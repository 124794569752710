const RoundedBadgeInner = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="current"
    viewBox="0 0 48 48"
    className={`h-4 w-4 ${className}`}
  >
    <circle cx="24" cy="24" r="23.6923" fill="current" />
  </svg>
)

export default RoundedBadgeInner
